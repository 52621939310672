<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Группа прав
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active">Редактирование</a>
            <router-link
              v-if="routeRightGroupHistory"
              :to="routeRightGroupHistory"
            >
              <a class="tabs__item">История изменений</a>
            </router-link>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.name)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.name]"
                    :caption="titlesFields[fieldsEntity.name]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.name)"
                    :error="errorsFormEdit[fieldsEntity.name]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.is_auto)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_auto]"
                    :caption="titlesFields[fieldsEntity.is_auto]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.is_auto)"
                    :error="errorsFormEdit[fieldsEntity.is_auto]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.permissions)" class="row">
                  <div class="accordion">
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInCamerasTab) || !!formEdit[fieldsEntity.camera_group_ids].length}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 0, !showAccordionItem[0])"
                      >
                        Камеры
                      </div>

                      <div v-show="showAccordionItem[0]" class="accordion__content">
                        <SmartVSelect
                          v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.camera_group_ids)"
                          v-model="formEdit[fieldsEntity.camera_group_ids]"
                          :caption="titlesFields[fieldsEntity.camera_group_ids]"
                          :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.camera_group_ids)"
                          :error="errorsFormEdit[fieldsEntity.camera_group_ids]"
                          :initial-options="initialOptionsCameraGroups"
                          :multiple="true"
                          :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
                          class="accordion__ability-actions"
                        />
                        <div class="accordion__title">
                          Камеры
                        </div>
                        <div class="accordion__little-description">
                          Настройки прав для управления камерами.
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:create" class="" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:read" class="" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:update" class="" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_permission:read" class="accordion__ability-actions" caption="Чтение прав на камеру" />
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="log_camera_watch_reason:read" class="accordion__ability-actions" caption="Чтение логов просмотров" />

                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.live_reason" class="accordion__ability-actions" caption="Разрешение на просмотр трансляции" />
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.dvr_reason" class="accordion__ability-actions" caption="Разрешение на просмотр архива" />
                        <hr class="accordion__content-divider">
                        <fieldset
                          style="border: 5px solid #dee2e6;
                          border-radius: 10px;
                          padding: 17px 10px;
                          margin-top: 15px"
                        >
                          <legend
                            style="font: 16px 'Roboto-Medium'; text-align: center"
                          >
                            Действия с камерой
                          </legend>
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:reset_salt" class="accordion__ability-actions" caption="Сброс соли" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:activate" class="accordion__ability-actions" caption="Включение/выключение камеры" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:blocking" class="accordion__ability-actions" caption="Блокировка камеры" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.sound:update" class="accordion__ability-actions" caption="Управление звуком" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:restart_stream" class="accordion__ability-actions" caption="Перезапуск потока" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_setup:setup" class="accordion__ability-actions" caption="Управление камерой" />
                        </fieldset>
                        <fieldset
                          style="border: 5px solid #dee2e6;
                          border-radius: 10px;
                          padding: 17px 10px;
                          margin-top: 15px"
                        >
                          <legend
                            style="font: 16px 'Roboto-Medium'; text-align: center"
                          >
                            Редактирование полей
                          </legend>
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.location:update" class="accordion__ability-actions" caption="Редактирование координат и адреса" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.gang_id:update" class="accordion__ability-actions" caption="Редактирование компании" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.title:update" class="accordion__ability-actions" caption="Редактирование заголовка" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.tariff:update" class="accordion__ability-actions" caption="Редактирование тарифа" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.marker:update" class="accordion__ability-actions" caption="Редактирование маркера" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.timezone:update" class="accordion__ability-actions" caption="Редактирование таймзоны" />
                        </fieldset>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Параметры для встраивания
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.embed:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.embed:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Параметры для контролируемых камер
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.is_managed:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.is_managed:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Параметры для привязки к серверу
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.server_id:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.server_id:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Параметры инвентарных данных
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.inventory:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.inventory:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Параметры для настройки потоков
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.streams:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.streams:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Параметры для управления группами камер
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.group:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.group:update" caption="Изменение" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInCameraGroupsTab) || !!formEdit[fieldsEntity.camera_group_type_ids].length}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 1, !showAccordionItem[1])"
                      >
                        Группы камер
                      </div>
                      <div v-show="showAccordionItem[1]" class="accordion__content">
                        <SmartVSelect
                          v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.camera_group_type_ids)"
                          v-model="formEdit[fieldsEntity.camera_group_type_ids]"
                          :caption="titlesFields[fieldsEntity.camera_group_type_ids]"
                          :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.camera_group_type_ids)"
                          :error="errorsFormEdit[fieldsEntity.camera_group_type_ids]"
                          :initial-options="initialOptionsCameraGroupTypes"
                          :multiple="true"
                          :settings-remote-search="settingsSelects[fieldsEntity.camera_group_type_ids]"
                          class="accordion__ability-actions"
                        />
                        <div class="accordion__title">
                          Группы камер
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_permission:read" class="accordion__ability-actions" caption="Чтение прав на группу камер" />
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Типы групп камер
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_type:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_type:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_type:update" caption="Изменение" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInUsersTab)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 2, !showAccordionItem[2])"
                      >
                        Пользователи
                      </div>
                      <div v-show="showAccordionItem[2]" class="accordion__content">
                        <div class="accordion__title">
                          Клиенты
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Администраторы
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Группы прав администраторов
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="right_group:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="right_group:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="right_group:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:reset_salt" class="accordion__ability-actions" caption="Сброс соли у клиента" />
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:reset_salt" class="accordion__ability-actions" caption="Сброс соли у администратора" />
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="log_auth:read" class="accordion__ability-actions" caption="Чтение логов авторизации" />
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="log_view:read" class="accordion__ability-actions" caption="Чтение логов просмотра страниц" />
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInTariffTab)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 3, !showAccordionItem[3])"
                      >
                        Тарифы
                      </div>
                      <div v-show="showAccordionItem[3]" class="accordion__content">
                        <div class="accordion__title">
                          Тарифы
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="tariff:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="tariff:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="tariff:update" caption="Изменение" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInServersTab)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 4, !showAccordionItem[4])"
                      >
                        Серверы и кластеры
                      </div>
                      <div v-show="showAccordionItem[4]" class="accordion__content">
                        <div class="accordion__title">
                          Серверы
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="server:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="server:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="server:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Кластеры
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="cluster:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="cluster:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="cluster:update" caption="Изменение" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInAnalyticsTab)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 5, !showAccordionItem[5])"
                      >
                        Аналитика
                      </div>
                      <div v-show="showAccordionItem[5]" class="accordion__content">
                        <div class="accordion__title">
                          Серверы видеоаналитики
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_server:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_server:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_server:update" caption="Изменение" />
                        </div>
                        <div class="accordion__title">
                          Кластеры видеоаналитики
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_cluster:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_cluster:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_cluster:update" caption="Изменение" />
                        </div>
                        <div class="accordion__title">
                          Настройка аналитики
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInGangTab)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 6, !showAccordionItem[6])"
                      >
                        Компании
                      </div>
                      <div v-show="showAccordionItem[6]" class="accordion__content">
                        <div class="accordion__title">
                          Компании
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="gang:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="gang:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="gang:update" caption="Изменение" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInPacsTab)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 7, !showAccordionItem[7])"
                      >
                        СКУД
                      </div>
                      <div v-show="showAccordionItem[7]" class="accordion__content">
                        <div class="accordion__title">
                          Сотрудники
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="employee:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="employee:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="employee:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" class="accordion__ability-actions" checkbox-value="device_permission:read" caption="Просмотр прав у сотрудников" />
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Группы доступа
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_access_group:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_access_group:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_access_group:update" caption="Изменение" />
                        </div>
                        <hr class="accordion__content-divider">
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" class="accordion__ability-actions" checkbox-value="device_access_group_permission:read" caption="Просмотр прав у групп доступа" />
                        <hr class="accordion__content-divider">
                        <div class="accordion__title">
                          Устройства доступа
                        </div>
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device:create" caption="Создание" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device:read" caption="Чтение" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device:update" caption="Изменение" />
                        </div>
                        <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_gang:update" class="accordion__ability-actions" caption="Редактирование компании" />
                      </div>
                    </div>
                    <div class="accordion__item">
                      <div
                        :class="{'accordion__header_marked': checkPermissionsInTab(permissionsForButtons)}"
                        class="accordion__header"
                        @click="$set(showAccordionItem, 8, !showAccordionItem[8])"
                      >
                        Множественные действия
                      </div>
                      <div v-show="showAccordionItem[8]" class="accordion__content">
                        <div class="accordion__title" />
                        <div class="accordion__ability-actions accordion__ability-actions_one-row">
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:multi_edit" caption="Множественное редактирование" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:download_csv" caption="Скачивание CSV" />
                          <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:multi_download" caption="Множественное скачивание" />
                        </div>
                        <hr class="accordion__content-divider">
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="errorsFormEdit[fieldsEntity.permissions]" class="row error">
                  {{ errorsFormEdit[fieldsEntity.permissions] }}
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.RIGHT_GROUP)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.RIGHT_GROUP)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.RIGHT_GROUP)"
                        type="button"
                        @click="deleteRightGroup()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.RIGHT_GROUP)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.RIGHT_GROUP, fieldsEntity.users_count)">
                  <strong>
                    <router-link :to="routeAdminsInRightGroup">
                      Посмотреть все группы по данному типу ({{ sourceData.entityInfo[fieldsEntity.users_count] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p v-if="routeCameraGroupsInRightGroup">
                  <strong>
                    <router-link :to="routeCameraGroupsInRightGroup">
                      Посмотреть все группы камер в группе прав ({{ sourceData.entityInfo[fieldsEntity.camera_groups_count] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p v-if="routeCameraGroupTypesInRightGroup">
                  <strong>
                    <router-link :to="routeCameraGroupTypesInRightGroup">
                      Посмотреть все типы групп камер в группе прав ({{ sourceData.entityInfo[fieldsEntity.camera_group_types_count] }})
                    </router-link>
                  </strong>
                </p>
              </div>
            </div>
          </form>
          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ROUTE_ADMINS,
  ROUTE_CAMERA_GROUP_TYPES,
  ROUTE_CAMERA_GROUPS,
  ROUTE_RIGHT_GROUPS,
  ROUTE_VIEW_DEVICE_HISTORY, ROUTE_VIEW_RIGHT_GROUP_HISTORY
} from "@/router/names.js";
import {FILTERS_ADMIN} from "@/store/users/admins/index.js";
import {rightGroupTabCheckMixin} from "@/components/rightGroups/mixin.js";
import {ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT, FIELDS_CAMERA_GROUP, FILTERS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT, FIELDS_CAMERA_GROUP_TYPE, FILTERS_CAMERA_GROUP_TYPE} from "@/store/cameraGroupTypes/index.js";
import {
  ACTION_DELETE_RIGHT_GROUP,
  ACTION_EDIT_RIGHT_GROUP_WITH_ALL,
  ACTION_LOAD_RIGHT_GROUP_FOR_EDIT,
  EXTRAS_RIGHT_GROUP,
  FIELDS_RIGHT_GROUP,
  TITLES_FIELDS_RIGHT_GROUP
} from "@/store/rightGroups/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования группы прав.
 */
export default {
  mixins: [editDataEntityMixin, rightGroupTabCheckMixin],
  data() {
    const rightGroupId = Number(this.$route.params.rightGroupId),
          paramsRouteAdminsInRightGroup = new TableQueryParams({filters: [new FilterData(FILTERS_ADMIN.right_group_id, "=", [rightGroupId])]}),
          routeAdminsInRightGroup = {name: ROUTE_ADMINS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteAdminsInRightGroup.stringify()}};

    return {
      routeBack: {name: ROUTE_RIGHT_GROUPS},
      fieldsEntity: FIELDS_RIGHT_GROUP,
      titlesFields: TITLES_FIELDS_RIGHT_GROUP,
      entityId: rightGroupId,
      nameActionLoadDataForEdit: `rightGroups/${ACTION_LOAD_RIGHT_GROUP_FOR_EDIT}`,
      nameActionEdit: `rightGroups/${ACTION_EDIT_RIGHT_GROUP_WITH_ALL}`,
      deletedField: FIELDS_RIGHT_GROUP.is_deleted,

      routeAdminsInRightGroup: routeAdminsInRightGroup,
      // Следующие маршруты можно построить после загрузки информации.
      routeCameraGroupsInRightGroup: null,
      routeCameraGroupTypesInRightGroup: null,
      routeRightGroupHistory: null,
      initialOptionsCameraGroups: [],
      initialOptionsCameraGroupTypes: [],
      settingsSelects: {
        [FIELDS_RIGHT_GROUP.camera_group_ids]: {
          action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP.id,
          labelField: FIELDS_CAMERA_GROUP.name
        },
        [FIELDS_RIGHT_GROUP.camera_group_type_ids]: {
          action: `cameraGroupTypes/${ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP_TYPE.id,
          labelField: FIELDS_CAMERA_GROUP_TYPE.name
        },
      },
      showAccordionItem: [],
      isChecked: true
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.CAMERA_GROUP) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.RIGHT_GROUP, FIELDS_RIGHT_GROUP.camera_groups_count)) {
        const paramsRouteCameraGroupsInRightGroup = new TableQueryParams({
          filters: [new FilterData(FILTERS_CAMERA_GROUP.id, "in", [this.formEdit[FIELDS_RIGHT_GROUP.camera_group_ids]])]
        });
        this.routeCameraGroupsInRightGroup = {
          name: ROUTE_CAMERA_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCameraGroupsInRightGroup.stringify()}
        };
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.CAMERA_GROUP_TYPE) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.RIGHT_GROUP, FIELDS_RIGHT_GROUP.camera_group_types_count)) {
        const paramsRouteRightGroupHistory = new TableQueryParams({
          filters: [
            new FilterData(FIELDS_RIGHT_GROUP.id,  "=", [this.entityId]),
          ]});
        this.routeRightGroupHistory = {
          name: ROUTE_VIEW_RIGHT_GROUP_HISTORY,
          query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteRightGroupHistory.stringify()},
          params: {rightGroupId: this.entityId}};

        const paramsRouteCameraGroupTypesInRightGroup = new TableQueryParams({
          filters: [new FilterData(FILTERS_CAMERA_GROUP_TYPE.id, "in", [this.formEdit[FIELDS_RIGHT_GROUP.camera_group_type_ids]])]
        });
        this.routeCameraGroupTypesInRightGroup = {
          name: ROUTE_CAMERA_GROUP_TYPES, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCameraGroupTypesInRightGroup.stringify()}
        };
      }
      this.initialOptionsCameraGroups = this.sourceData.extraInfo[EXTRAS_RIGHT_GROUP.camera_group];
      this.initialOptionsCameraGroupTypes = this.sourceData.extraInfo[EXTRAS_RIGHT_GROUP.camera_group_type];
    },
    /**
     * Отправка запроса на удаление текущей группы прав.
     */
    deleteRightGroup() {
      this.$camsdals.confirm("Хотите удалить эту группу прав?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`rightGroups/${ACTION_DELETE_RIGHT_GROUP}`, {rightGroupIds: [this.entityId]});
          this.$camsdals.alert("Группа прав удалена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении группы прав");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
