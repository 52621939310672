<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.host]"
        :caption="titlesFields[fieldsEntity.host]"
        :error="errorsFormEdit[fieldsEntity.host]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.port]"
        :caption="titlesFields[fieldsEntity.port]"
        :error="errorsFormEdit[fieldsEntity.port]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.soap_url]"
        :caption="titlesFields[fieldsEntity.soap_url]"
        :error="errorsFormEdit[fieldsEntity.soap_url]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_auth_enabled]"
        :caption="titlesFields[fieldsEntity.is_auth_enabled]"
        :error="errorsFormEdit[fieldsEntity.is_auth_enabled]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_token_required]"
        :caption="titlesFields[fieldsEntity.is_token_required]"
        :error="errorsFormEdit[fieldsEntity.is_token_required]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.login]"
        :caption="titlesFields[fieldsEntity.login]"
        :error="errorsFormEdit[fieldsEntity.login]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.password]"
        :caption="titlesFields[fieldsEntity.password]"
        :error="errorsFormEdit[fieldsEntity.password]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_BOLID_SERVER} from "@/router/names.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {
  ACTION_CREATE_BOLID_SERVER,
  FIELDS_BOLID_SERVER,
  TITLES_FIELDS_BOLID_SERVER
} from "@/store/integrations/bolid/servers/index.js";

/**
 * Компонент диалога создания сервера BOLID.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `bolidServers/${ACTION_CREATE_BOLID_SERVER}`,
      fieldsEntity: FIELDS_BOLID_SERVER,
      titlesFields: TITLES_FIELDS_BOLID_SERVER,
      formEdit: {
        [FIELDS_BOLID_SERVER.host]: "",
        [FIELDS_BOLID_SERVER.port]: "",
        [FIELDS_BOLID_SERVER.soap_url]: "",
        [FIELDS_BOLID_SERVER.is_auth_enabled]: false,
        [FIELDS_BOLID_SERVER.is_token_required]: false,
        [FIELDS_BOLID_SERVER.login]: "",
        [FIELDS_BOLID_SERVER.password]: "",
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование сервера BOLID.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_BOLID_SERVER, params: {serverId: newEntity[FIELDS_BOLID_SERVER.id]}};
    },
  },
};
</script>
