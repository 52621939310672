/**
 * Отдельный vuex модуль для механизма сохранения параметров таблиц.
 *
 * Внешние вызовы начинать с "keeperParamsTable/".
 */

export const ACTION_SAVE_PARAMS_FOR_TABLE = "SAVE_PARAMS_FOR_TABLE";
export const ACTION_DELETE_PARAMS_FROM_TABLE = "DELETE_PARAMS_FROM_TABLE";

const MUTATION_SAVE_PARAMS = "SAVE_PARAMS";
const MUTATION_DELETE_PARAMS = "DELETE_PARAMS";

export const GETTER_PARAMS_TABLE = "PARAMS_TABLE";
export const GETTER_ALL_PARAMS_TABLE = "ALL_PARAMS_TABLE";

export default {
  namespaced: true,
  state: {
    tables: {}
  },
  mutations: {
    /**
     * Сохранение параметров определенной таблицы.
     *
     * @param {Object} state
     * @param {String} nameTable
     * @param {String} nameParamsTable
     * @param {String} queryParam
     */
    [MUTATION_SAVE_PARAMS](state, {nameTable, nameParamsTable, queryParam}) {
      (state.tables[nameTable] || (state.tables[nameTable] = {}))[nameParamsTable] = queryParam;
    },
    /**
     * Удаление параметров таблицы.
     *
     * @param {Object} state
     * @param {String} nameTable
     * @param {String} nameParamsTable
     */
    [MUTATION_DELETE_PARAMS](state, {nameTable, nameParamsTable}) {
      delete state.tables[nameTable][nameParamsTable];
    },
  },
  actions: {
    /**
     * Сохранение параметров определенной таблицы с заданным названием.
     *
     * @param {Function} commit
     * @param {String} nameTable
     * @param {String} nameParamsTable
     * @param {String} queryParam
     */
    [ACTION_SAVE_PARAMS_FOR_TABLE]({commit}, {nameTable, nameParamsTable, queryParam}) {
      commit(MUTATION_SAVE_PARAMS, {nameTable, nameParamsTable, queryParam});
    },
    /**
     * Удаление набора параметров таблицы по его названию.
     *
     * @param {Function} commit
     * @param {String} nameTable
     * @param {String} nameParamsTable
     * @param {String} queryParam
     */
    [ACTION_DELETE_PARAMS_FROM_TABLE]({commit}, {nameTable, nameParamsTable}) {
      commit(MUTATION_DELETE_PARAMS, {nameTable, nameParamsTable});
    },
  },
  getters: {
    /**
     * Извлечение одного сохраненного набора параметров таблицы по его названию.
     *
     * @param {Object} state
     * @param {String} nameTable
     * @param {String} nameParamsTable
     * @return {Function}
     */
    [GETTER_PARAMS_TABLE]: state => (nameTable, nameParamsTable) => {
      return state.tables[nameTable][nameParamsTable];
    },
    /**
     * Извлечение всех сохраненных параметров для таблицы.
     *
     * @param {Object} state
     * @param {String} nameTable
     * @return {Function}
     */
    [GETTER_ALL_PARAMS_TABLE]: state => nameTable => {
      return state.tables[nameTable] || {};
    },
  }
};
