/**
 * Отдельный vuex модуль для взаимодействия по camera_group_types-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для camera groups. Вызов действия начинать с "cameraGroupTypes/"
export const ACTION_LOAD_INFO_CAMERA_GROUP_TYPES = "LOAD_INFO_CAMERA_GROUP_TYPES";
export const ACTION_LOAD_CAMERA_GROUP_TYPES = "LOAD_CAMERA_GROUP_TYPES";
export const ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_TABLE = "LOAD_CAMERA_GROUP_TYPES_FOR_TABLE";
export const ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT = "LOAD_CAMERA_GROUP_TYPES_FOR_SELECT";
export const ACTION_LOAD_CAMERA_GROUP_TYPE_FOR_EDIT = "LOAD_CAMERA_GROUP_TYPE_FOR_EDIT";
export const ACTION_CREATE_CAMERA_GROUP_TYPE = "CREATE_CAMERA_GROUP_TYPE";
export const ACTION_EDIT_CAMERA_GROUP_TYPE = "EDIT_CAMERA_GROUP_TYPE";
export const ACTION_DELETE_CAMERA_GROUP_TYPES = "DELETE_CAMERA_GROUP_TYPES";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CAMERA_GROUP_TYPE = Object.freeze({
  id: "id",
  name: "name",
  is_deleted: "is_deleted",
  is_unique: "is_unique",
  camera_groups_count: "camera_groups_count",
  camera_group_ids: "camera_group_ids"
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CAMERA_GROUP_TYPE = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей типа группы камер.
 */
export const TITLES_FIELDS_CAMERA_GROUP_TYPE = {
  [FIELDS_CAMERA_GROUP_TYPE.id]: "ID",
  [FIELDS_CAMERA_GROUP_TYPE.name]: "Имя",
  [FIELDS_CAMERA_GROUP_TYPE.is_deleted]: "Удалена",
  [FIELDS_CAMERA_GROUP_TYPE.is_unique]: "Уникальность камеры в рамках типа",
  [FIELDS_CAMERA_GROUP_TYPE.camera_groups_count]: "Количество групп камер",
  [FIELDS_CAMERA_GROUP_TYPE.camera_group_ids]: "Группы камер",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_CAMERA_GROUP_TYPE = {
  [FIELDS_CAMERA_GROUP_TYPE.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA_GROUP_TYPE.name]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA_GROUP_TYPE.is_unique]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA_GROUP_TYPE.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA_GROUP_TYPE.camera_group_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с типами групп камер.
 */
export const EXTRAS_CAMERA_GROUP_TYPE = Object.freeze({
  camera_group: "camera_group",
});

const EXTRAS_BY_FIELDS_CAMERA_GROUP_TYPE = Object.freeze({
  [FIELDS_CAMERA_GROUP_TYPE.camera_group]: [EXTRAS_CAMERA_GROUP_TYPE.camera_group_ids],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для типов групп камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CAMERA_GROUP_TYPES]() {
      const response = await this.getters.privateAjax.post("/v0/camera_group_types/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка типов групп камер.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERA_GROUP_TYPES](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CAMERA_GROUP_TYPE, fields)));
      try {
        const response = await this.getters.privateAjax.post("/v0/camera_group_types/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка типов групп камер для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CAMERA_GROUP_TYPES, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_TABLE});
    },
    /**
     * Загрузка списка типов групп камер для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных групп камер.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_CAMERA_GROUP_TYPE.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_CAMERA_GROUP_TYPES, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного типа группы камер для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} cameraGroupTypeId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERA_GROUP_TYPE_FOR_EDIT]({dispatch}, [cameraGroupTypeId, fields]) {
      const filter = makeFilterApi(FILTERS_CAMERA_GROUP_TYPE.id, "=", cameraGroupTypeId),
        responseData = await dispatch(ACTION_LOAD_CAMERA_GROUP_TYPES, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового типа группы камер.
     *
     * @param {Object} context
     * @param {Object} cameraGroupTypeInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_CAMERA_GROUP_TYPE](context, cameraGroupTypeInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/camera_group_types/create/", cameraGroupTypeInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего типа группы камер.
     *
     * @param {Object} context
     * @param {Object} cameraGroupTypeInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_GROUP_TYPE](context, cameraGroupTypeInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/camera_group_types/edit/", cameraGroupTypeInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление типа группы камер.
     *
     * @param {Object} context
     * @param {Array} cameraGroupTypeIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CAMERA_GROUP_TYPES](context, {cameraGroupTypeIds}) {
      return this.getters.privateAjax.post("/v0/camera_group_types/delete/", {ids: cameraGroupTypeIds});
    },
  },
};
