/**
 * Отдельный vuex модуль для взаимодействия по right_groups-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";

// actions для right groups. Вызов действия начинать с "rightGroups/"
export const ACTION_LOAD_INFO_RIGHT_GROUPS = "LOAD_INFO_RIGHT_GROUPS";
export const ACTION_LOAD_RIGHT_GROUPS = "LOAD_RIGHT_GROUPS";
export const ACTION_LOAD_RIGHT_GROUPS_FOR_TABLE = "LOAD_RIGHT_GROUPS_FOR_TABLE";
export const ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT = "LOAD_RIGHT_GROUPS_FOR_SELECT";
export const ACTION_LOAD_RIGHT_GROUP_FOR_EDIT = "LOAD_RIGHT_GROUP_FOR_EDIT";
export const ACTION_CREATE_RIGHT_GROUP = "CREATE_RIGHT_GROUP";
export const ACTION_EDIT_RIGHT_GROUP = "EDIT_RIGHT_GROUP";
export const ACTION_SET_PERMISSIONS_RIGHT_GROUP = "SET_PERMISSIONS_RIGHT_GROUP";
export const ACTION_SET_CAMERA_GROUP_RIGHT_GROUP = "SET_CAMERA_GROUP_RIGHT_GROUP";
export const ACTION_SET_CAMERA_GROUP_TYPE_RIGHT_GROUP = "SET_CAMERA_GROUP_TYPE_RIGHT_GROUP";
export const ACTION_EDIT_RIGHT_GROUP_WITH_ALL = "EDIT_RIGHT_GROUP_WITH_ALL";
export const ACTION_DELETE_RIGHT_GROUP = "DELETE_RIGHT_GROUP";
export const ACTION_LOAD_RIGHT_GROUPS_FOR_MULTI_EDIT = "LOAD_RIGHT_GROUPS_FOR_MULTI_EDIT";
export const ACTION_LOAD_RIGHT_GROUP_FOR_EDIT_PERMISSIONS = "LOAD_RIGHT_GROUP_FOR_EDIT_PERMISSIONS";
export const ACTION_DELETE_CAMERA_GROUPS_RIGHT_GROUP = "DELETE_CAMERA_GROUPS_RIGHT_GROUP";
export const ACTION_LOAD_INFO_RIGHT_GROUP_HISTORY = "LOAD_INFO_RIGHT_GROUP_HISTORY";
export const ACTION_LOAD_RIGHT_GROUP_HISTORY = "LOAD_RIGHT_GROUP_HISTORY";
export const ACTION_LOAD_RIGHT_GROUP_HISTORY_FOR_TABLE = "LOAD_RIGHT_GROUP_HISTORY_FOR_TABLE";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_RIGHT_GROUP = Object.freeze({
  id: "id",
  name: "name",
  is_auto: "is_auto",
  is_deleted: "is_deleted",
  camera_group_ids: "camera_group_ids",
  camera_groups_count: "camera_groups_count",
  user_ids: "user_ids",
  users_count: "users_count",
  permissions: "permissions",
  permissions_count: "permissions_count",
  camera_group_type_ids: "camera_group_type_ids",
  camera_group_types_count: "camera_group_types_count",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_RIGHT_GROUP = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей групп прав.
 */
export const TITLES_FIELDS_RIGHT_GROUP = {
  [FIELDS_RIGHT_GROUP.id]: "ID",
  [FIELDS_RIGHT_GROUP.name]: "Имя",
  [FIELDS_RIGHT_GROUP.is_auto]: "Авто",
  [FIELDS_RIGHT_GROUP.is_deleted]: "Удален",
  [FIELDS_RIGHT_GROUP.camera_group_ids]: "Группы камер",
  [FIELDS_RIGHT_GROUP.camera_groups_count]: "Количество групп камер",
  [FIELDS_RIGHT_GROUP.user_ids]: "Пользователи",
  [FIELDS_RIGHT_GROUP.users_count]: "Количество пользователей",
  [FIELDS_RIGHT_GROUP.permissions]: "Права",
  [FIELDS_RIGHT_GROUP.permissions_count]: "Количество прав",
  [FIELDS_RIGHT_GROUP.camera_group_type_ids]: "Типы групп камер",
  [FIELDS_RIGHT_GROUP.camera_group_types_count]: "Количество типов групп камер",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_RIGHT_GROUP = {
  [FIELDS_RIGHT_GROUP.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_RIGHT_GROUP.name]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_RIGHT_GROUP.is_auto]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_RIGHT_GROUP.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_RIGHT_GROUP.camera_group_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_RIGHT_GROUP.user_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_RIGHT_GROUP.permissions]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_RIGHT_GROUP.camera_group_type_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с группами прав.
 */
export const EXTRAS_RIGHT_GROUP = Object.freeze({
  camera_group: "camera_group",
  camera_group_type: "camera_group_type",
  user: "user",
});

const EXTRAS_BY_FIELDS_RIGHT_GROUP = Object.freeze({
  [FIELDS_RIGHT_GROUP.camera_group_ids]: [EXTRAS_RIGHT_GROUP.camera_group],
  [FIELDS_RIGHT_GROUP.camera_group_type_ids]: [EXTRAS_RIGHT_GROUP.camera_group_type],
  [FIELDS_RIGHT_GROUP.user_ids]: [EXTRAS_RIGHT_GROUP.user],
});
// Поля для истории изменений групп прав
export const FIELDS_RIGHT_GROUP_HISTORY = Object.freeze({
  id: "id",
  hpk: "hpk",
  is_deleted: "is_deleted",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent",
  name:"name",
  is_auto:"is_auto",

});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_RIGHT_GROUP_HISTORY = Object.freeze({
  is_deleted: "is_deleted",
  data_change_event_date: "data_change_event_date",
  data_change_event_user_id: "data_change_event_user_id",
});
export const TITLES_FIELDS_RIGHT_GROUP_HISTORY = {
  [FIELDS_RIGHT_GROUP_HISTORY.id]: "id",
  [FIELDS_RIGHT_GROUP_HISTORY.hpk]: "hpk",
  [FIELDS_RIGHT_GROUP_HISTORY.is_deleted]: "Удален",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_date]: "Дата события",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_user_agent]: "user agent",
  [FIELDS_RIGHT_GROUP_HISTORY.history_create_date]: "Дата создания в системе",
  [FIELDS_RIGHT_GROUP_HISTORY.name]: "Заголовок",
  [FIELDS_RIGHT_GROUP_HISTORY.is_auto]: "Авто?",
};


export const EXTRAS_RIGHT_GROUP_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_RIGHT_GROUP_HISTORY = Object.freeze({
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_user_id]: [EXTRAS_RIGHT_GROUP_HISTORY.user],
});

export const TYPES_FIELDS_RIGHT_GROUP_HISTORY = {
  [FIELDS_RIGHT_GROUP_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_RIGHT_GROUP_HISTORY.is_auto]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_RIGHT_GROUP_HISTORY.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};



export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для групп прав.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_RIGHT_GROUPS]() {
      const response = await this.getters.privateAjax.post("/v0/right_groups/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders, response.data.permissions];
    },
    /**
     * Загрузка списка групп прав.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUPS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_RIGHT_GROUP, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка групп прав для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUPS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_RIGHT_GROUPS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_RIGHT_GROUPS_FOR_TABLE});
    },
    /**
     * Загрузка списка групп прав для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных групп прав.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_RIGHT_GROUP.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_RIGHT_GROUPS, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка списка группы прав для обработки их в рамках множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUPS_FOR_MULTI_EDIT]({dispatch}, {filters = [], search = ""}) {
      const responseData = await dispatch(ACTION_LOAD_RIGHT_GROUPS, {page: 1, pageSize: 1000, fields: [FIELDS_RIGHT_GROUP.id], filters, search});
      return _.map(responseData.results, FIELDS_RIGHT_GROUP.id);
    },
    /**
     * Загрузка одной группы прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} rightGroupId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUP_FOR_EDIT]({dispatch}, [rightGroupId, fields]) {
      const filter = makeFilterApi(FILTERS_RIGHT_GROUP.id, "=", rightGroupId),
        responseData = await dispatch(ACTION_LOAD_RIGHT_GROUPS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Загрузка одной группы прав для редактирования прав доступа.
     *
     * @param {Function} dispatch
     * @param {Number} rightGroupId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUP_FOR_EDIT_PERMISSIONS]({dispatch}, [rightGroupId, fields]) {
      const filter = makeFilterApi(FILTERS_RIGHT_GROUP.id, "=", rightGroupId),
        responseData = await dispatch(ACTION_LOAD_RIGHT_GROUPS, {page: 1, pageSize: 1, fields:[FIELDS_RIGHT_GROUP.permissions], filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание новой группы прав.
     *
     * @param {Object} context
     * @param {Object} rightGroupInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_RIGHT_GROUP](context, rightGroupInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/create/", rightGroupInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей группы прав.
     *
     * @param {Object} context
     * @param {Object} rightGroupInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_RIGHT_GROUP](context, rightGroupInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/edit/", rightGroupInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Назначений конкретных прав у группы прав.
     *
     * @param {Object} context
     * @param {Number} rightGroupId
     * @param {Array} permissions
     * @return {Promise}
     */
    async [ACTION_SET_PERMISSIONS_RIGHT_GROUP](context, {rightGroupId, permissions}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/permissions/set/", {
          right_group_id: rightGroupId,
          permissions
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Назначение групп камер группе прав.
     *
     * @param {Object} context
     * @param {Number} rightGroupId
     * @param {Array} cameraGroupIds
     * @return {Promise}
     */
    async [ACTION_SET_CAMERA_GROUP_RIGHT_GROUP](context, {rightGroupId, cameraGroupIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/camera_groups/set/", {
          right_group_id: rightGroupId,
          camera_group_ids: cameraGroupIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление группы камер.
     *
     * @param {Object} context
     * @param {Array} rightGroupIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CAMERA_GROUPS_RIGHT_GROUP](context, {rightGroupId, cameraGroupIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/camera_groups/delete/", {
          right_group_id: rightGroupId,
          camera_group_ids: cameraGroupIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Назначение типов групп камер группе прав.
     *
     * @param {Object} context
     * @param {Number} rightGroupId
     * @param {Array} cameraGroupTypeIds
     * @return {Promise}
     */
    async [ACTION_SET_CAMERA_GROUP_TYPE_RIGHT_GROUP](context, {rightGroupId, cameraGroupTypeIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/camera_group_types/set/", {
          right_group_id: rightGroupId,
          camera_group_type_ids: cameraGroupTypeIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей группы прав вместе с установкой в нее прав, групп камер и типов групп камер.
     *
     * @param {Function} dispatch
     * @param {Object} rightGroupInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_RIGHT_GROUP_WITH_ALL]({dispatch}, rightGroupInfo) {
      await dispatch(ACTION_SET_CAMERA_GROUP_RIGHT_GROUP, {
        rightGroupId: rightGroupInfo[FIELDS_RIGHT_GROUP.id],
        cameraGroupIds: rightGroupInfo[FIELDS_RIGHT_GROUP.camera_group_ids],
      });
      await dispatch(ACTION_SET_CAMERA_GROUP_TYPE_RIGHT_GROUP, {
        rightGroupId: rightGroupInfo[FIELDS_RIGHT_GROUP.id],
        cameraGroupTypeIds: rightGroupInfo[FIELDS_RIGHT_GROUP.camera_group_type_ids],
      });
      await dispatch(ACTION_SET_PERMISSIONS_RIGHT_GROUP, {
        rightGroupId: rightGroupInfo[FIELDS_RIGHT_GROUP.id],
        permissions: rightGroupInfo[FIELDS_RIGHT_GROUP.permissions],
      });
      return dispatch(ACTION_EDIT_RIGHT_GROUP, rightGroupInfo);
    },
    /**
     * Удаление группы прав.
     *
     * @param {Object} context
     * @param {Array} rightGroupIds
     * @return {Promise}
     */
    async [ACTION_DELETE_RIGHT_GROUP](context, {rightGroupIds}) {
      return this.getters.privateAjax.post("/v0/right_groups/delete/", {ids: rightGroupIds});
    },
    /**
     * Загрузка служебной информации.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_RIGHT_GROUP_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/right_groups/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUP_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_RIGHT_GROUP_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/right_groups/history/", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_RIGHT_GROUP_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_RIGHT_GROUP_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_RIGHT_GROUP_HISTORY_FOR_TABLE});
    },
  },
};

