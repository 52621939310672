<template>
  <div class="settings_access">
    <template v-if="modeAddCamera">
      <div class="row">
        <SmartVSelect
          v-model="selectedCameraId"
          :settings-remote-search="settingsSelectCameras"
          class="settings_access__select"
          placeholder="Выберите камеру"
        />
      </div>

      <div class="row">
        <div class="col">
          <div class="buttons-group buttons-group_stretch">
            <CamsButton priority="primary" type="button" @click="addCamera()">
              Сохранить
            </CamsButton>
            <CamsButton type="button" @click="switchOffModeAddCamera()">
              Отмена
            </CamsButton>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!modeAddCamera">
      <div class="row">
        <div class="col">
          <CamsButton
            v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)"
            priority="primary"
            type="button"
            @click="switchOnModeAddCamera()"
          >
            Добавить камеру
          </CamsButton>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="input input_medium input_stretch">
            <input
              v-model="search"
              :placeholder="'Фильтр по камерам'"
              class="input"
              type="text"
              @input="debouncedLoadCameras()"
            >
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col">
        <SpinnerLoading v-if="isLoading" size="s" />

        <div v-else-if="pageInfo.count > 0" class="access-container">
          <div class="access-container__header">
            <div>Камера</div>
          </div>

          <div class="access-container__content access-list">
            <div
              v-for="(cameraDirectionInfo, cameraDirectionInfoIndex) in camerasDirectionInfo"
              :key="cameraDirectionInfoIndex"
              class="access-list__item"
            >
              <div>{{ cameraDirectionInfo.cameraNumber }}</div>
              <div v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)" class="buttons-group">
                <CamsButton
                  class="settings_access__delete-row"
                  icon-type="only"
                  type="button"
                  @click="deleteCamera(cameraDirectionInfo.cameraNumber)"
                >
                  <svg style="width: 20px; height: 20px">
                    <use xlink:href="@/assets/img/icons.svg#close" />
                  </svg>
                </CamsButton>
              </div>
            </div>
          </div>
          <span>Найдено: {{ pageInfo.count }}</span>
        </div>

        <div v-else>
          <p>Камер не найдено.</p>
        </div>
      </div>
    </div>

    <div class="dialog-actions">
      <CamsButton priority="primary" type="button" @click="closeDialog()">
        Готово
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_CAMERAS_FOR_SELECT, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {
  ACTION_ADD_BOLID_DEVICES_TO_CAMERAS_BY_FORM, ACTION_DELETE_BOLID_DEVICES_TO_CAMERAS,
  ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS_FOR_EDIT, FIELDS_BOLID_DEVICE_TO_CAMERA,
} from "@/store/integrations/bolid/devices/index.js";

/**
 * Компонент диалога установки камер для устройств.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Идентификатор устройства.
     */
    deviceId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {

      isLoading: false,
      modeAddCamera: false,
      camerasDirectionInfo: [],
      search: "",
      pageInfo: {
        count: 0,
      },
      selectedCameraId: null,
      showDevicesItem: [],
      settingsSelectCameras: {
        action: `cameras/${ACTION_LOAD_CAMERAS_FOR_SELECT}`,
        valueField: FIELDS_CAMERA.number,
        labelField: [FIELDS_CAMERA.number, FIELDS_CAMERA.title, FIELDS_CAMERA.address]
      },
    };
  },
  created() {
    this.debouncedLoadCameras = _.debounce(this.loadCameras, 350);
    this.loadCameras();
  },
  methods: {
    /**
     * Переход к режиму добавления камер устанавливает начальные настройки для формы добавления камер.
     */
    switchOnModeAddCamera() {
      this.modeAddCamera = true;
    },
    /**
     * Выход из режима добавления камер обновляет список имеющихся камер.
     */
    switchOffModeAddCamera() {
      this.modeAddCamera = false;
      this.search = "";
      this.loadCameras();
    },
    /**
     * Загрузка информации по камерам устройства для редактирования.
     *
     * @param {Number} currentPage
     */
    async loadCameras(currentPage = 1) {
      this.currentPage = this.currentPage === currentPage ? this.currentPage : currentPage;
      this.isLoading = true;
      try {
        const responseData = await this.$store.dispatch(`bolidDevices/${ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS_FOR_EDIT}`, {
          deviceId: this.deviceId,
          search: this.search,
          page: currentPage,
        });
        this.pageInfo.count = responseData.count;

        this.camerasDirectionInfo = responseData.results.map((rawCameraDirectionInfo) => {
          return {
            cameraNumber: rawCameraDirectionInfo[FIELDS_BOLID_DEVICE_TO_CAMERA.camera_number],

          };
        });

      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Обработка формы для добавления камер и оформление запроса по заполненным данным.
     */
    async addCamera() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`bolidDevices/${ACTION_ADD_BOLID_DEVICES_TO_CAMERAS_BY_FORM}`, {
          deviceId: this.deviceId,
          cameraNumber: this.selectedCameraId,
        });
        this.switchOffModeAddCamera();
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.isLoading = false;
    },
    /**
     * Редактирование найденных камер устройства.
     * API предполагает последовательное удаление записи о направлении камеры и добавление той же камеры с нужным направлением.
     *
     * @param {String} cameraNumber
     */
    async updateDirection(cameraNumber) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`bolidDevices/${ACTION_DELETE_BOLID_DEVICES_TO_CAMERAS}`, {deviceId: this.deviceId, cameraNumbers: [cameraNumber]});
        await this.$store.dispatch(`bolidDevices/${ACTION_ADD_BOLID_DEVICES_TO_CAMERAS_BY_FORM}`, {
          deviceId: this.deviceId,
          cameraNumber: [[cameraNumber]],
        });
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.loadCameras(this.currentPage);
      this.isLoading = false;
    },
    /**
     * Удаление камеры устройства.
     *
     * @param {string} cameraNumber
     */
    deleteCamera(cameraNumber) {
      this.$camsdals.confirm("Хотите удалить эту камеру?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`bolidDevices/${ACTION_DELETE_BOLID_DEVICES_TO_CAMERAS}`, {deviceId: this.deviceId, cameraNumbers: cameraNumber});
        } catch (error) {
          this.$camsdals.alert(`Произошла ошибка: ${error}`);
        }
        this.loadCameras();
        this.isLoading = false;
      });
    },
  },
};
</script>
