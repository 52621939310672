/**
 *
 * Поля по истории в проекте.
 */
export const FIELDS_HISTORY = Object.freeze({
  close_event_id: "close_event_id",
  event_id: "event_id",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent",
  data_close_event_id:"data_close_event_id",
  data_close_event_date:"data_close_event_date",
  data_close_event_action:"data_close_event_action",
  data_close_event_comment:"data_close_event_comment",
  data_close_event_user_id:"data_close_event_user_id",
  data_close_event_ip:"data_close_event_ip",
  data_close_event_port:"data_close_event_port",
  data_close_event_front_id:"data_close_event_front_id",
  data_close_event_http_host:"data_close_event_http_host",
  data_close_event_path:"data_close_event_path",
  data_close_event_user_agent:"data_close_event_user_agent",
});
export const FIELDS_HISTORY_SERVERS = Object.freeze({
  history_create_date : "history_create_date",
  data_change_event_action : "data_change_event_action",
  data_change_event_comment : "data_change_event_comment",
  data_change_event_date : "data_change_event_date",
  data_change_event_front_id : "data_change_event_front_id",
  data_change_event_http_host : "data_change_event_http_host",
  data_change_event_id : "data_change_event_id",
  data_change_event_ip : "data_change_event_ip",
  data_change_event_path : "data_change_event_path",
  data_change_event_port : "data_change_event_port",
  data_change_event_user_agent : "data_change_event_user_agent",
  data_change_event_user_id : "data_change_event_user_id",
});
export const TITLES_HISTORY_SERVERS = Object.freeze({
  history_create_date : "Дата",
  data_change_event_action : "Действие",
  data_change_event_comment : "Комментарий",
  data_change_event_date : "Время события",
  data_change_event_front_id : "FRONT_ID",
  data_change_event_http_host : "HOST",
  data_change_event_id : "ID изменения состояния",
  data_change_event_ip : "IP-адрес пользователя",
  data_change_event_path : "Метод API",
  data_change_event_port : "Порт",
  data_change_event_user_agent : "USER AGENT",
  data_change_event_user_id : "Пользователь",




});
export const TITLES_HISTORY = Object.freeze({
  [FIELDS_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_HISTORY.data_change_event_date]: "Время события",
  [FIELDS_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_HISTORY.data_change_event_user_agent]: "user agent",
  [FIELDS_HISTORY.data_close_event_id]: "ID закрывающего события",
  [FIELDS_HISTORY.data_close_event_date]: "Время закрывающего события",
  [FIELDS_HISTORY.data_close_event_action]: "Действие закрывающего привязку события",
  [FIELDS_HISTORY.data_close_event_comment]: "Комментарий закрывающего привязку события",
  [FIELDS_HISTORY.data_close_event_user_id]: "ID пользователя закрывающего привязку события",
  [FIELDS_HISTORY.data_close_event_ip]: "IP-адрес, с которого пришли изменения на закрытие привязки",
  [FIELDS_HISTORY.data_close_event_port]: "Порт, с которого пришли изменения на закрытие привязки",
  [FIELDS_HISTORY.data_close_event_front_id]: "FRONT_ID с которого пришли изменения на закрытие привязки",
  [FIELDS_HISTORY.data_close_event_http_host]: "HTTP-host закрывающего привязку события",
  [FIELDS_HISTORY.data_close_event_path]: "Путь к запрашиваемому ресурсу закрывающего события",
  [FIELDS_HISTORY.data_close_event_user_agent]: "User-agent закрывающего привязку события",
  [FIELDS_HISTORY.close_event_id]: "ID закрывающего события",
  [FIELDS_HISTORY.event_id]: "ID события",
});
