<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.camera_number]"
        :caption="titlesFields[fieldsEntity.camera_number]"
        :error="errorsFormEdit[fieldsEntity.camera_number]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.push_domain]"
        :caption="titlesFields[fieldsEntity.push_domain]"
        :error="errorsFormEdit[fieldsEntity.push_domain]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.face_recognition]"
        :caption="titlesFields[fieldsEntity.face_recognition]"
        :error="errorsFormEdit[fieldsEntity.face_recognition]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_THERMAL_VISION} from "@/router/names.js";
import {ACTION_CREATE_THERMAL_VISION, FIELDS_THERMAL_VISION, TITLES_FIELDS_THERMAL_VISION} from "@/store/analytics/thermalVisions/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания настройки аналитики тепловизора.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `analytics/thermalVisions/${ACTION_CREATE_THERMAL_VISION}`,
      fieldsEntity: FIELDS_THERMAL_VISION,
      titlesFields: TITLES_FIELDS_THERMAL_VISION,
      formEdit: {
        [FIELDS_THERMAL_VISION.camera_number]: "",
        [FIELDS_THERMAL_VISION.push_domain]: "",
        [FIELDS_THERMAL_VISION.face_recognition]: false,
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование настройки аналитики тепловизора.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_THERMAL_VISION, params: {cameraNumber: newEntity[FIELDS_THERMAL_VISION.camera_number]}};
    },
  },
};
</script>
