<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <a class="tabs__item tabs__item_active" href="#">Компании</a>
      </div>
    </div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateGang"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {ACTION_LOAD_GANGS_FOR_TABLE, ACTION_LOAD_INFO_GANGS, FIELDS_GANG, TITLES_FIELDS_GANG, TYPES_FIELDS_GANG,} from "@/store/gangs/index.js";
import {ROUTE_EDIT_GANG, ROUTE_GANGS} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateGangDialog from "@/components/gangs/CreateGangDialog.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по компаниям.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      currentRoute: ROUTE_GANGS,
      rawFields: FIELDS_GANG,
      columnCaptions: TITLES_FIELDS_GANG,
      nameActionLoadDataForTable: `gangs/${ACTION_LOAD_GANGS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `gangs/${ACTION_LOAD_INFO_GANGS}`,
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_GANG.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_GANG.name:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rowData[FIELDS_GANG.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_GANG[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания компании.
     */
    openDialogCreateGang() {
      this.$camsdals.open(CreateGangDialog, {}, {dialogTitle: "Создание компании"});
    },
  },
};
</script>
