<template>
  <div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :key-field="rawFields.number"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_CAMERAS,
  ROUTE_MARKERS,
  ROUTE_HISTORY,
  ROUTE_EDIT_CAMERA,
  ROUTE_CAMERA_GROUPS,
  ROUTE_EDIT_GANG,
  ROUTE_FIRMWARES, ROUTE_CAMERA_HISTORY
} from "@/router/names.js";
import {
  ACTION_LOAD_CAMERAS_HISTORY_FOR_TABLE,
  ACTION_LOAD_INFO_CAMERAS_HISTORY,
  EXTRAS_CAMERA_HISTORY,
  FIELDS_CAMERA_HISTORY, FIELDS_PERIOD_INACTIVITY,
  FILTERS_CAMERA_HISTORY,
  TITLES_FIELDS_CAMERA_HISTORY, TYPES_FIELDS_CAMERA_HISTORY,

} from "@/store/cameras/index.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {FIELDS_CAMERA_GROUP, FILTERS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {FIELDS_GANG} from "@/store/gangs/index.js";
import {FIELDS_ADMIN} from "@/store/users/admins/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по истории камер.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      cameraNumber: this.$route.params.cameraNumber,
      currentRoute: ROUTE_CAMERA_HISTORY,
      rawFields: FIELDS_CAMERA_HISTORY,
      columnCaptions: TITLES_FIELDS_CAMERA_HISTORY,
      nameActionLoadDataForTable: `cameras/${ACTION_LOAD_CAMERAS_HISTORY_FOR_TABLE}`,
      nameActionLoadInfoForTable: `cameras/${ACTION_LOAD_INFO_CAMERAS_HISTORY}`,
      defaultFieldsTableForView: [
        FIELDS_CAMERA_HISTORY.history_create_date,
        FIELDS_CAMERA_HISTORY.number,
        FIELDS_CAMERA_HISTORY.title,
        FIELDS_CAMERA_HISTORY.camera_groups_ids,
        FIELDS_CAMERA_HISTORY.data_change_event_user_id,
        FIELDS_CAMERA_HISTORY.data_change_event_ip,
        FIELDS_CAMERA_HISTORY.data_change_event_action,
        FIELDS_CAMERA_HISTORY.data_change_event_path,
        FIELDS_CAMERA_HISTORY.data_change_event_front_id,
      ],
      defaultFilters: [
        new FilterData(FILTERS_CAMERA_HISTORY.history_create_date, ">=", [defaultFilterByDate]),
        new FilterData(FILTERS_CAMERA_HISTORY.data_change_event_user_id, "=", [1], true),
        new FilterData(FILTERS_CAMERA_HISTORY.data_change_event_user_id, "=", [14], true ),
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.user]) {
        storedExtraInfo.admins = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.user].forEach((adminInfo) => {
          storedExtraInfo.admins[adminInfo[FIELDS_ADMIN.id]] = {
            id: adminInfo[FIELDS_ADMIN.id],
            name: adminInfo[FIELDS_ADMIN.username],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.tariff]) {
        storedExtraInfo.tariffs = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.tariff].forEach((tariffInfo) => {
          storedExtraInfo.tariffs[tariffInfo[FIELDS_TARIFF.id]] = {
            id: tariffInfo[FIELDS_TARIFF.id],
            name: tariffInfo[FIELDS_TARIFF.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.server]) {
        storedExtraInfo.servers = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.server].forEach((serverInfo) => {
          storedExtraInfo.servers[serverInfo[FIELDS_SERVER.id]] = {
            id: serverInfo[FIELDS_SERVER.id],
            name: serverInfo[FIELDS_SERVER.domain],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.camera_group]) {
        storedExtraInfo.cameraGroups = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.camera_group].forEach((cameraGroupInfo) => {
          storedExtraInfo.cameraGroups[cameraGroupInfo[FIELDS_CAMERA_GROUP.id]] = {
            id: cameraGroupInfo[FIELDS_CAMERA_GROUP.id],
            name: cameraGroupInfo[FIELDS_CAMERA_GROUP.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.inactivity_period]) {
        storedExtraInfo.inactivityPeriods = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.inactivity_period].forEach((inactivityPeriodInfo) => {
          storedExtraInfo.inactivityPeriods[inactivityPeriodInfo[FIELDS_PERIOD_INACTIVITY.id]] = {
            id: inactivityPeriodInfo[FIELDS_PERIOD_INACTIVITY.id],
            name: inactivityPeriodInfo[FIELDS_PERIOD_INACTIVITY.start],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.record_disable_period]) {
        storedExtraInfo.disablePeriods = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.record_disable_period].forEach((recordDisablePeriodInfo) => {
          storedExtraInfo.disablePeriods[recordDisablePeriodInfo[FIELDS_PERIOD_INACTIVITY.id]] = {
            id: recordDisablePeriodInfo[FIELDS_PERIOD_INACTIVITY.id],
            name: recordDisablePeriodInfo[FIELDS_PERIOD_INACTIVITY.start],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA_HISTORY.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_CAMERA_HISTORY.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};
      let params;
      switch (nameField) {
      case FIELDS_CAMERA_HISTORY.number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: rawTranslation}};
        // К маршруту редактирования камеры подмешиваются параметры фильтрации таблицы для ссылок обратного перехода.
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA_HISTORY.tariff_id:
        readyTranslation = _.get(storedExtraInfo.tariffs[rawTranslation], "name", "Нет тарифа");
        break;
      case FIELDS_CAMERA_HISTORY.data_change_event_user_id:
        readyTranslation = _.get(storedExtraInfo.admins[rawTranslation], "name", "-");
        break;
      case FIELDS_CAMERA_HISTORY.server_id:
        readyTranslation = _.get(storedExtraInfo.servers[rawTranslation], "name", "Нет сервера");
        break;
      case FIELDS_CAMERA_HISTORY.camera_groups_ids:
        readyTranslation = _.chain(storedExtraInfo.cameraGroups)
          .pick(rawTranslation)
          .mapValues("name")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA_GROUP.camera_number, "=", [rowData[FIELDS_CAMERA_HISTORY.number]])]});
        readyParams = {name: ROUTE_CAMERA_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_CAMERA_HISTORY.record_disable_period_id:
        readyTranslation = this.$options.filters.localDateTime(
          _.get(storedExtraInfo.disablePeriods[rawTranslation], "name", "")
        );
        break;
      case FIELDS_CAMERA_HISTORY.inactivity_period_id:
        readyTranslation = this.$options.filters.localDateTime(
          _.get(storedExtraInfo.inactivityPeriods[rawTranslation], "name", "")
        );
        break;
      case FIELDS_CAMERA_HISTORY.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CAMERA_HISTORY[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
