<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_LOG_AUTHS}" class="tabs__item">
          Логи авторизаций
        </router-link>
        <router-link :to="{name: routes.ROUTE_LOG_VIEWS}" class="tabs__item">
          Логи просмотров видео
        </router-link>
        <router-link :to="{name: routes.ROUTE_LOG_CAMERA_WATCH_REASONS}" class="tabs__item">
          Логи запросов доступа к камере
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Логи UCAMSGO
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
      :default-filters="defaultFilters"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {ROUTE_LOG_AUTHS, ROUTE_LOG_CAMERA_WATCH_REASONS, ROUTE_LOG_UCAMSGO, ROUTE_LOG_VIEWS} from "@/router/names.js";
import {
  ACTION_LOAD_INFO_LOG_UCAMSGO, ACTION_LOAD_LOG_UCAMSGO_FOR_TABLE,
  FIELDS_LOG_UCAMSGO, FILTERS_LOG_UCAMSGO,
  TITLES_FIELDS_LOG_UCAMSGO,
  TYPES_FIELDS_LOG_UCAMSGO
} from "@/store/log/index.js";
import {FilterData} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с логами сервиса ucamsgo.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      routes: {
        ROUTE_LOG_VIEWS,
        ROUTE_LOG_CAMERA_WATCH_REASONS,
        ROUTE_LOG_AUTHS,
      },
      currentRoute: ROUTE_LOG_UCAMSGO,
      rawFields: FIELDS_LOG_UCAMSGO,
      columnCaptions: TITLES_FIELDS_LOG_UCAMSGO,
      nameActionLoadDataForTable: `log/${ACTION_LOAD_LOG_UCAMSGO_FOR_TABLE}`,
      nameActionLoadInfoForTable: `log/${ACTION_LOAD_INFO_LOG_UCAMSGO}`,
      defaultFilters: [
        new FilterData(FILTERS_LOG_UCAMSGO.date_created, ">=", [defaultFilterByDate])
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_LOG_UCAMSGO[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
