<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <a class="tabs__item tabs__item_active" href="#">Тарифы</a>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateTariff"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :deleted-field="rawFields.is_deleted"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_INFO_TARIFFS,
  ACTION_LOAD_TARIFFS_FOR_TABLE,
  FIELDS_TARIFF,
  TITLES_FIELDS_TARIFF,
  TYPES_FIELDS_TARIFF
} from "@/store/tariffs/index.js";
import {ROUTE_EDIT_TARIFF, ROUTE_TARIFFS} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateTariffDialog from "@/components/tariffs/CreateTariffDialog.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по тарифам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      currentRoute: ROUTE_TARIFFS,
      rawFields: FIELDS_TARIFF,
      columnCaptions: TITLES_FIELDS_TARIFF,
      nameActionLoadDataForTable: `tariffs/${ACTION_LOAD_TARIFFS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `tariffs/${ACTION_LOAD_INFO_TARIFFS}`,
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_TARIFF.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_TARIFF, params: {tariffId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_TARIFF.name:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_TARIFF, params: {tariffId: rowData[FIELDS_TARIFF.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_TARIFF[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания тарифа.
     */
    openDialogCreateTariff() {
      this.$camsdals.open(
        CreateTariffDialog,
        {},
        {dialogTitle: "Создание тарифа"},
        {},
      );
    },
  },
};
</script>
