/**
 * Отдельный vuex модуль для взаимодействия по cameras-API
 */

import {
  DEFAULT_PAGE_SIZE_FOR_MAP,
  DEFAULT_PAGE_SIZE_FOR_SELECT,
  DEFAULT_PAGE_SIZE_FOR_TABLE,
  SORT_DIRECTIONS,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi, TYPES_VALUES_FOR_LOOKUPS} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";


// actions для cameras. Вызов действия начинать с "cameras/"
export const ACTION_LOAD_CAMERA_FOR_PLAYER = "LOAD_CAMERA_FOR_PLAYER";
export const ACTION_LOAD_CAMERA = "LOAD_CAMERA";
export const ACTION_LOAD_INFO_CAMERAS = "LOAD_INFO_CAMERAS";
export const ACTION_LOAD_INFO_CAMERAS_HISTORY = "LOAD_INFO_CAMERAS_HISTORY";
export const ACTION_LOAD_CAMERAS = "LOAD_CAMERAS";
export const ACTION_LOAD_CAMERAS_HISTORY = "LOAD_CAMERAS_HISTORY";
export const ACTION_LOAD_CAMERAS_FOR_TABLE = "LOAD_CAMERAS_FOR_TABLE";
export const ACTION_LOAD_CAMERAS_HISTORY_FOR_TABLE = "LOAD_CAMERAS_HISTORY_FOR_TABLE";
export const ACTION_LOAD_CAMERAS_FOR_SELECT = "LOAD_CAMERAS_FOR_SELECT";
export const ACTION_LOAD_CAMERAS_FOR_MULTI_EDIT = "LOAD_CAMERAS_FOR_MULTI_EDIT";
export const ACTION_LOAD_CAMERAS_MIGRATION_FOR_MULTI_EDIT = "LOAD_CAMERAS_MIGRATION_FOR_MULTI_EDIT";
export const ACTION_LOAD_CAMERA_FOR_EDIT = "LOAD_CAMERA_FOR_EDIT";
export const ACTION_LOAD_CAMERAS_FOR_MAP = "LOAD_CAMERAS_FOR_MAP";
export const ACTION_LOAD_COUNT_CAMERAS_FOR_MAP = "LOAD_COUNT_CAMERAS_FOR_MAP";
export const ACTION_CREATE_CAMERA = "CREATE_CAMERA";
export const ACTION_EDIT_CAMERA = "EDIT_CAMERA";
export const ACTION_EDIT_CAMERA_WITH_GROUPS = "EDIT_CAMERA_WITH_GROUPS";
export const ACTION_EDIT_CAMERA_INVENTORY = "EDIT_CAMERA_INVENTORY";
export const ACTION_SET_CAMERA_GROUPS = "SET_CAMERA_GROUPS";
export const ACTION_ADD_CAMERA_GROUPS = "ADD_CAMERA_GROUPS";
export const ACTION_DELETE_CAMERA_GROUPS = "DELETE_CAMERA_GROUPS";
export const ACTION_ACTIVATE_CAMERAS = "ACTIVATE_CAMERAS";
export const ACTION_DEACTIVATE_CAMERAS = "DEACTIVATE_CAMERAS";
export const ACTION_DELETE_CAMERAS = "DELETE_CAMERAS";
export const ACTION_BLOCKING_CAMERAS = "BLOCKING_CAMERAS";
export const ACTION_CREATE_MIGRATION_CAMERAS = "CREATE_MIGRATION_CAMERAS";
export const ACTION_CANCEL_MIGRATION_CAMERAS = "CANCEL_MIGRATION_CAMERAS";
export const ACTION_REQUEST_TOKEN = "REQUEST_TOKEN";
export const ACTION_RESTART_STREAM = "RESTART_STREAM";
export const ACTION_DISABLE_RECORD = "DISABLE_STREAM";
export const ACTION_ENABLE_RECORD = "ENABLE_STREAM";
export const ACTION_LOAD_INFO_CAMERAS_MIGRATION = "LOAD_INFO_CAMERAS_MIGRATION";
export const ACTION_LOAD_CAMERAS_MIGRATION = "LOAD__LOAD_CAMERAS_MIGRATION";
export const ACTION_LOAD_CAMERAS_MIGRATION_FOR_TABLE = "LOAD_CAMERAS_MIGRATION_FOR_TABLE";
export const ACTION_CONFIRM_MIGRATION_CAMERAS = "CONFIRM_MIGRATION_CAMERAS";
export const ACTION_PTZ_CAMERA = "PTZ_CAMERA";

// Flussonic
export const ACTION_LOAD_RECORDING_STATUS = "LOAD_RECORDING_STATUS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CAMERA = Object.freeze({
  number: "number",
  address: "address",
  title: "title",
  timezone: "timezone",
  longitude: "longitude",
  latitude: "latitude",
  is_embed: "is_embed",
  delete_date: "delete_date",
  is_sounding: "is_sounding",
  is_managed: "is_managed",
  create_date: "create_date",
  is_deleted: "is_deleted",
  tariff_id: "tariff_id",
  server_id: "server_id",
  blocking_lvl: "blocking_lvl",
  blocking_end_date: "blocking_end_date",
  inactivity_period_id: "inactivity_period_id",
  camera_migration_task_id: "camera_migration_task_id",
  camera_migration_task_status: "camera_migration_task_status",
  camera_migration_task_server_id: "camera_migration_task_server_id",
  inventory_model: "inventory_model",
  inventory_serial_number: "inventory_serial_number",
  inventory_mac: "inventory_mac",
  inventory_address_full: "inventory_address_full",
  inventory_address_region: "inventory_address_region",
  inventory_address_area: "inventory_address_area",
  inventory_address_city: "inventory_address_city",
  inventory_address_city_area: "inventory_address_city_area",
  inventory_address_city_district: "inventory_address_city_district",
  inventory_address_settlement: "inventory_address_settlement",
  inventory_address_street: "inventory_address_street",
  inventory_address_house_type: "inventory_address_house_type",
  inventory_address_house: "inventory_address_house",
  inventory_is_ptz: "inventory_is_ptz",
  screenshot_url: "screenshot_url",
  ip: "ip",
  embed_hosts: "embed_hosts",
  embed_hosts_count: "embed_hosts_count",
  analytics: "analytics",
  analytics_count: "analytics_count",
  streams: "streams",
  streams_count: "streams_count",
  is_public: "is_public",
  camera_groups_count: "camera_groups_count",
  camera_group_ids: "camera_group_ids",
  dadata_qc: "dadata_qc",
  dadata_qc_geo: "dadata_qc_geo",
  dadata_success: "dadata_success",
  dadata_error: "dadata_error",
  stats_alive: "stats_alive",
  stats_last_alive: "stats_last_alive",
  stats_date: "stats_date",
  stats_bitrate: "stats_bitrate",
  stats_client_count: "stats_client_count",
  stats_source_error: "stats_source_error",
  gang_id: "gang_id",
  marker_id: "marker_id",
  record_disable_period_id: "record_disable_period_id",
  last_direct_screen_success: "last_direct_screen_success",
  last_direct_screen_success_date: "last_direct_screen_success_date",
  is_llhls_enabled: "is_llhls_enabled",
  storage: "storage",
  camera_server_vendor_name: "camera_server_vendor_name",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CAMERA = Object.freeze({
  number: "number",
  server_id: "server_id",
  is_deleted: "is_deleted",
  camera_group_id: "camera_group_id",
  longitude: "longitude",
  latitude: "latitude",
  inactivity_period_id: "inactivity_period_id",
  gang_id: "gang_id"
});

/**
 * Это исключительная константа для подмены полей сортировки.
 * Для камер есть поля заголовка и адреса по которым необходима альтернативная сортировка.
 * Применяется при загрузке камер, если используется сортировка по одному из этих полей то в API попадает его альтернативное название.
 */
export const REPLACE_SORT_CAMERA = Object.freeze({
  [FIELDS_CAMERA.address]: "address_natural",
  [FIELDS_CAMERA.title]: "title_natural",
});

/**
 * Стандартные названия для полей камеры.
 */
export const TITLES_FIELDS_CAMERA = {
  [FIELDS_CAMERA.number]: "Номер камеры",
  [FIELDS_CAMERA.address]: "Адрес",
  [FIELDS_CAMERA.title]: "Заголовок",
  [FIELDS_CAMERA.timezone]: "Часовой пояс",
  [FIELDS_CAMERA.longitude]: "Долгота",
  [FIELDS_CAMERA.latitude]: "Широта",
  [FIELDS_CAMERA.is_embed]: "Встраивание на сайт",
  [FIELDS_CAMERA.delete_date]: "Дата удаления",
  [FIELDS_CAMERA.is_sounding]: "Наличие звука",
  [FIELDS_CAMERA.inventory_is_ptz]: "Ptz",
  [FIELDS_CAMERA.is_managed]: "Контролируемая камера",
  [FIELDS_CAMERA.create_date]: "Дата создания",
  [FIELDS_CAMERA.is_deleted]: "Удалена",
  [FIELDS_CAMERA.tariff_id]: "Тариф",
  [FIELDS_CAMERA.server_id]: "Сервер",
  [FIELDS_CAMERA.blocking_lvl]: "Уровень блокировки",
  [FIELDS_CAMERA.blocking_end_date]: "Дата окончания блокировки",
  [FIELDS_CAMERA.inactivity_period_id]: "ID Периода неактивности",
  [FIELDS_CAMERA.camera_migration_task_id]: "ID задачи переноса камеры",
  [FIELDS_CAMERA.camera_migration_task_status]: "Статус задачи переноса",
  [FIELDS_CAMERA.camera_migration_task_server_id]: "ID сервера на который переносим камеру",
  [FIELDS_CAMERA.inventory_model]: "Инвентарные данные: модель",
  [FIELDS_CAMERA.inventory_serial_number]: "Инвентарные данные: Серийный номер",
  [FIELDS_CAMERA.inventory_mac]: "Инвентарные данные: MAC адрес",
  [FIELDS_CAMERA.inventory_address_full]: "Инвентарные данные: полный адрес",
  [FIELDS_CAMERA.inventory_address_region]: "Инвентарные данные: регион",
  [FIELDS_CAMERA.inventory_address_area]: "Инвентарные данные: район в регионе",
  [FIELDS_CAMERA.inventory_address_city]: "Инвентарные данные: город",
  [FIELDS_CAMERA.inventory_address_city_area]: "Инвентарные данные: административный округ",
  [FIELDS_CAMERA.inventory_address_city_district]: "Инвентарные данные: район города",
  [FIELDS_CAMERA.inventory_address_settlement]: "Инвентарные данные: населенный пункт",
  [FIELDS_CAMERA.inventory_address_street]: "Инвентарные данные: улица",
  [FIELDS_CAMERA.inventory_address_house_type]: "Инвентарные данные: тип дома",
  [FIELDS_CAMERA.inventory_address_house]: "Инвентарные данные: дом",
  [FIELDS_CAMERA.embed_hosts]: "Хосты встраивания",
  [FIELDS_CAMERA.embed_hosts_count]: "Количество хостов встраивания",
  [FIELDS_CAMERA.analytics]: "Включенные аналитики",
  [FIELDS_CAMERA.analytics_count]: "Количество аналитик",
  [FIELDS_CAMERA.screenshot_url]: "URL скриншота",
  [FIELDS_CAMERA.ip]: "IP адрес",
  [FIELDS_CAMERA.streams]: "Потоки",
  [FIELDS_CAMERA.streams_count]: "Кол-во потоков",
  [FIELDS_CAMERA.is_public]: "Публичная",
  [FIELDS_CAMERA.camera_groups_count]: "Кол-во групп камер",
  [FIELDS_CAMERA.camera_group_ids]: "Список групп камер",
  [FIELDS_CAMERA.dadata_qc]: "DaData: Код проверки адреса",
  [FIELDS_CAMERA.dadata_qc_geo]: "DaData: Код точности координат",
  [FIELDS_CAMERA.dadata_success]: "DaData: Успешность запроса",
  [FIELDS_CAMERA.dadata_error]: "DaData: Идентификатор ошибки",
  [FIELDS_CAMERA.stats_alive]: "Жива ли камера",
  [FIELDS_CAMERA.stats_last_alive]: "Когда был доступен поток",
  [FIELDS_CAMERA.stats_date]: "Когда получена статистика",
  [FIELDS_CAMERA.stats_bitrate]: "Битрейт",
  [FIELDS_CAMERA.stats_client_count]: "Количество клиентов",
  [FIELDS_CAMERA.stats_source_error]: "Ошибка источника",
  [FIELDS_CAMERA.gang_id]: "Компания",
  [FIELDS_CAMERA.marker_id]: "Маркер",
  [FIELDS_CAMERA.record_disable_period_id]: "ID периода отключения архива",
  [FIELDS_CAMERA.last_direct_screen_success]: "Снятие прямого скриншота с камеры",
  [FIELDS_CAMERA.is_llhls_enabled]: "Опция LLHLS",
  [FIELDS_CAMERA.last_direct_screen_success_date]: "Дата снятия прямого скриншота",
  [FIELDS_CAMERA.storage]: "Путь к хранилищу",
  [FIELDS_CAMERA.camera_server_vendor_name]: "Наименование вендора сервера",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_CAMERA = {
  [FIELDS_CAMERA.number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA.is_embed]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.delete_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA.is_sounding]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.inventory_is_ptz]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.is_managed]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.is_public]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.streams]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_CAMERA.camera_group_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_CAMERA.dadata_success]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.blocking_end_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA.analytics]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_CAMERA.stats_alive]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA.stats_last_alive]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA.stats_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA.gang_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA.last_direct_screen_success]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с камерами.
 */
export const EXTRAS_CAMERA = Object.freeze({
  server: "server",
  inactivity_period: "inactivity_period",
  tariff: "tariff",
  camera_group: "camera_group",
  camera_group_type: "camera_group_type",
  gang: "gang",
  marker: "marker",
  record_disable_period: "record_disable_period",
});

const EXTRAS_BY_FIELDS_CAMERA = Object.freeze({
  [FIELDS_CAMERA.server_id]: [EXTRAS_CAMERA.server],
  [FIELDS_CAMERA.tariff_id]: [EXTRAS_CAMERA.tariff],
  [FIELDS_CAMERA.camera_group_ids]: [EXTRAS_CAMERA.camera_group, EXTRAS_CAMERA.camera_group_type],
  [FIELDS_CAMERA.inactivity_period_id]: [EXTRAS_CAMERA.inactivity_period],
  [FIELDS_CAMERA.gang_id]: [EXTRAS_CAMERA.gang],
  [FIELDS_CAMERA.marker_id]: [EXTRAS_CAMERA.marker],
  [FIELDS_CAMERA.record_disable_period_id]: [EXTRAS_CAMERA.record_disable_period],
});

/**
 * Перечесление полей и дополнительных фильтров для них.
 */
const CUSTOM_TYPES_VALUES_FOR_LOOKUPS_BY_FIELD = Object.freeze({
  [FIELDS_CAMERA.delete_date]: [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME_BETWEEN],
  [FIELDS_CAMERA.create_date]: [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME_BETWEEN],
  [FIELDS_CAMERA.blocking_end_date]: [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME_BETWEEN],
});

/**
 * Поля по периодам неактивности.
 */
export const FIELDS_PERIOD_INACTIVITY = Object.freeze({
  id: "id",
  start: "start",
  end: "end",
  comment: "comment",
  reason: "reason",
  todo: "todo",
});

/**
 * Перечисление типов запрашиваемых токенов для работы с видео с камеры и для блокировки.
 */
export const TOKEN_TYPES = Object.freeze({
  L: "L", // Прямая трансляция.
  R: "R", // Архив.
  D: "D", // Скачивание.
});

/**
 * Перечисление названий для токенов.
 */
export const TITLES_TOKEN_TYPES = Object.freeze({
  [TOKEN_TYPES.L]: "Трансляция (L)",
  [TOKEN_TYPES.R]: "Архив (R)",
  [TOKEN_TYPES.D]: "Скачивание (D)",
});


//Поля для истории камер. В будущем надо будет вынести в отдельный стор
export const FIELDS_CAMERA_HISTORY = Object.freeze({
  address:"address",
  analytics:"analytics",
  analytics_count:"analytics_count",
  blocking_end_date:"blocking_end_date",
  blocking_lvl:"blocking_lvl",
  camera_groups_ids:"camera_groups_ids",
  camera_migration_task_id:"camera_migration_task_id",
  camera_migration_task_server_id:"camera_migration_task_server_id",
  camera_migration_task_status:"camera_migration_task_status",
  delete_date:"delete_date",
  embed_hosts:"embed_hosts",
  embed_hosts_count:"embed_hosts_count",
  gang_id:"gang_id",
  inactivity_period_id:"inactivity_period_id",
  ip:"ip",
  is_deleted:"is_deleted",
  is_embed:"is_embed",
  is_managed:"is_managed",
  is_sounding:"is_sounding",
  latitude:"latitude",
  longitude:"longitude",
  marker_id:"marker_id",
  number: "number",
  record_disable_period_id:"record_disable_period_id",
  screenshot_url:"screenshot_url",
  server_id:"server_id",
  storage: "storage",
  streams:"streams",
  streams_count:"streams_count",
  tariff_id:"tariff_id",
  timezone:"timezone",
  title:"title",
  history_create_date:"history_create_date",
  data_change_event_action: "data_change_event_action",
  data_change_event_comment: "data_change_event_comment",
  data_change_event_front_id: "data_change_event_front_id",
  data_change_event_http_host: "data_change_event_http_host",
  data_change_event_id:"data_change_event_id",
  data_change_event_ip: "data_change_event_ip",
  data_change_event_path: "data_change_event_path",
  data_change_event_port: "data_change_event_port",
  data_change_event_user_agent: "data_change_event_user_agent",
  data_change_event_user_id:"data_change_event_user_id",
});

export const FILTERS_CAMERA_HISTORY = Object.freeze(
  {
    number: "number",
    history_create_date:"history_create_date",
    data_change_event_user_id:"data_change_event_user_id",
  }
);
export const TYPES_FIELDS_CAMERA_HISTORY = {
  [FIELDS_CAMERA_HISTORY.number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA_HISTORY.history_create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA_HISTORY.is_sounding]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA_HISTORY.is_managed]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA_HISTORY.create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA_HISTORY.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAMERA_HISTORY.is_public]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};
export const TITLES_FIELDS_CAMERA_HISTORY = {
  [FIELDS_CAMERA_HISTORY.number]: "Номер камеры",
  [FIELDS_CAMERA_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_CAMERA_HISTORY.address]: " Адрес",
  [FIELDS_CAMERA_HISTORY.title]: "Заголовок",
  [FIELDS_CAMERA_HISTORY.timezone]: "Часовой пояс",
  [FIELDS_CAMERA_HISTORY.longitude]: "Долгота",
  [FIELDS_CAMERA_HISTORY.latitude]: "Широта",
  [FIELDS_CAMERA_HISTORY.is_embed]: "Встраивание",
  [FIELDS_CAMERA_HISTORY.delete_date]: "Дата удаления",
  [FIELDS_CAMERA_HISTORY.is_sounding]: "Наличие звука",
  [FIELDS_CAMERA_HISTORY.is_managed]: "Контролируемая камера",
  [FIELDS_CAMERA_HISTORY.history_create_date]: "Дата события",
  [FIELDS_CAMERA_HISTORY.is_deleted]: "Удалена",
  [FIELDS_CAMERA_HISTORY.tariff_id]: "Тариф",
  [FIELDS_CAMERA_HISTORY.server_id]: "Сервер",
  [FIELDS_CAMERA_HISTORY.marker_id]: "Маркер",
  [FIELDS_CAMERA_HISTORY.gang_id]: "Компания",
  [FIELDS_CAMERA_HISTORY.blocking_lvl]: "Уровень блокировки",
  [FIELDS_CAMERA_HISTORY.blocking_end_date]: "Дата окончания блокировки",
  [FIELDS_CAMERA_HISTORY.inactivity_period_id]: "Период неактивности",
  [FIELDS_CAMERA_HISTORY.camera_migration_task_id]: "ID задачи переноса камеры",
  [FIELDS_CAMERA_HISTORY.camera_migration_task_status]: "Статус задачи переноса",
  [FIELDS_CAMERA_HISTORY.camera_migration_task_server_id]: "ID сервера на который переносим камеру",
  [FIELDS_CAMERA_HISTORY.embed_hosts]: "Хосты встраивания",
  [FIELDS_CAMERA_HISTORY.embed_hosts_count]: "Количество хостов встраивания",
  [FIELDS_CAMERA_HISTORY.analytics]: "Включенные аналитики",
  [FIELDS_CAMERA_HISTORY.analytics_count]: "Количество аналитик",
  [FIELDS_CAMERA_HISTORY.screenshot_url]: "URL скриншота",
  [FIELDS_CAMERA_HISTORY.ip]: "IP-адрес",
  [FIELDS_CAMERA_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_CAMERA_HISTORY.streams]: "Потоки",
  [FIELDS_CAMERA_HISTORY.streams_count]: "Кол-во потоков",
  [FIELDS_CAMERA_HISTORY.camera_groups_ids]: "Группы камер",
  [FIELDS_CAMERA_HISTORY.record_disable_period_id]: "Дата отключения камеры",
  [FIELDS_CAMERA_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_CAMERA_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_CAMERA_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_CAMERA_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_CAMERA_HISTORY.data_change_event_front_id]: "Front ID",
  [FIELDS_CAMERA_HISTORY.data_change_event_http_host]: "Хост",
  [FIELDS_CAMERA_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_CAMERA_HISTORY.data_change_event_user_agent]: "User agent",
  [FIELDS_CAMERA_HISTORY.storage]: "Путь к хранилищу",

};
export const EXTRAS_CAMERA_HISTORY = Object.freeze({
  data_change_event: "data_change_event",
  camera_group: "camera_group",
  user: "user",
  gang: "gang",
  server: "server",
  tariff: "tariff",
  inactivity_period: "inactivity_period",
  record_disable_period: "record_disable_period",
  camera_migration_task: "camera_migration_task",
  camera_migration_task_server: "camera_migration_task_server",

});

const EXTRAS_BY_FIELDS_CAMERA_HISTORY = Object.freeze({
  [FIELDS_CAMERA_HISTORY.data_change_event_id]: [EXTRAS_CAMERA_HISTORY.data_change_event],
  [FIELDS_CAMERA_HISTORY.data_change_event_user_id]: [EXTRAS_CAMERA_HISTORY.user],
  [FIELDS_CAMERA_HISTORY.server_id]: [EXTRAS_CAMERA_HISTORY.server],
  [FIELDS_CAMERA_HISTORY.tariff_id]: [EXTRAS_CAMERA_HISTORY.tariff],
  [FIELDS_CAMERA_HISTORY.camera_groups_ids]: [EXTRAS_CAMERA_HISTORY.camera_group],
  [FIELDS_CAMERA_HISTORY.inactivity_period_id]: [EXTRAS_CAMERA_HISTORY.inactivity_period],
  [FIELDS_CAMERA_HISTORY.gang_id]: [EXTRAS_CAMERA_HISTORY.gang],
  [FIELDS_CAMERA_HISTORY.marker_id]: [EXTRAS_CAMERA_HISTORY.marker],
  [FIELDS_CAMERA_HISTORY.record_disable_period_id]: [EXTRAS_CAMERA_HISTORY.record_disable_period],

});
// Поля для таблицы с миграциями камер
export const FIELDS_CAMERA_MIGRATION = Object.freeze({
  id:"id",
  create_date:"create_date",
  status_date:"status_date",
  status:"status",
  camera_number:"camera_number",
  server_id:"server_id",
  stats:"stats",
  confirmable:"confirmable",
});
export const TITLES_FIELDS_CAMERA_MIGRATION = Object.freeze({
  [FIELDS_CAMERA_MIGRATION.stats]:"Статистика",
  [FIELDS_CAMERA_MIGRATION.id]:"id",
  [FIELDS_CAMERA_MIGRATION.create_date]:"Дата начала",
  [FIELDS_CAMERA_MIGRATION.status_date]:"Дата изменения статуса",
  [FIELDS_CAMERA_MIGRATION.status]:"Статус",
  [FIELDS_CAMERA_MIGRATION.camera_number]:"Номер камеры",
  [FIELDS_CAMERA_MIGRATION.server_id]:"Сервер назначения",
  [FIELDS_CAMERA_MIGRATION.confirmable]:"Требует подтверждения",
});
export const FILTERS_CAMERA_MIGRATION = Object.freeze({
  id: "id",
  camera_number: "camera_number",
  server_id: "server_id",
  status: "status",
});
export const EXTRAS_CAMERA_MIGRATION = Object.freeze({
  camera: "camera",
  server: "server",
});
export const EXTRAS_BY_FIELDS_CAMERA_MIGRATION = Object.freeze({
  [FIELDS_CAMERA_MIGRATION.server_id]:  [EXTRAS_CAMERA_MIGRATION.server],
  [FIELDS_CAMERA_MIGRATION.camera_number]:  [EXTRAS_CAMERA_MIGRATION.camera],
});
export const TYPES_FIELDS_CAMERA_MIGRATION = {
  [FIELDS_CAMERA_MIGRATION.create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA_MIGRATION.status_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAMERA_MIGRATION.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA_MIGRATION.server_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAMERA_MIGRATION.confirmable]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CAMERAS]() {
      const response = await this.getters.privateAjax.post("/v0/cameras/info/");
      return [FilterInfo.createFromDataApi(response.data.filters, CUSTOM_TYPES_VALUES_FOR_LOOKUPS_BY_FIELD), response.data.orders];
    },
    /**
     * Загрузка списка камер.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CAMERA, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });

      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка камер для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CAMERAS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CAMERAS_FOR_TABLE});
    },
    /**
     * Загрузка списка камер для отображения в селекте.
     * Зафиксирован фильтр для поиска по не удаленным камерам.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @param {Number} gangId
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search, gangId = null}) {
      const defaultFilterForSelect = gangId
          ? [
            makeFilterApi(FILTERS_CAMERA.is_deleted, "=", false),
            makeFilterApi(FILTERS_CAMERA.gang_id, "=", gangId)
          ]
          :  [makeFilterApi(FILTERS_CAMERA.is_deleted, "=", false)],
        responseData = await dispatch(ACTION_LOAD_CAMERAS, {
          pageSize,
          fields,
          filters: defaultFilterForSelect,
          search,

        });
      return responseData.results;
    },
    /**
     * Загрузка списка камер для обработки их в рамках множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_FOR_MULTI_EDIT]({dispatch}, {filters = [], search = ""}) {
      const responseData = await dispatch(ACTION_LOAD_CAMERAS, {page: 1, pageSize: 1000, fields: [FIELDS_CAMERA.number], filters, search});
      return _.map(responseData.results, FIELDS_CAMERA.number);
    },

    async [ACTION_LOAD_CAMERAS_MIGRATION_FOR_MULTI_EDIT]({dispatch}, {filters = [], search = ""}) {
      const responseData = await dispatch(ACTION_LOAD_CAMERAS_MIGRATION, {page: 1, pageSize: 1000, fields: [FIELDS_CAMERA_MIGRATION.camera_number], filters, search});
      return _.map(responseData.results, FIELDS_CAMERA_MIGRATION.camera_number);
    },
    /**
     * Загрузка одной камеры для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERA_FOR_EDIT]({dispatch}, [cameraNumber, fields]) {
      const filter = makeFilterApi(FILTERS_CAMERA.number, "=", cameraNumber),
        responseData = await dispatch(ACTION_LOAD_CAMERAS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Загрузка камер по координатам для карты.
     *
     * todo не учитывается долгота +-180
     *
     * @param {Function} dispatch
     * @param {Number} north
     * @param {Number} west
     * @param {Number} south
     * @param {Number} east
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_FOR_MAP]({dispatch}, {north, east, south, west}) {
      const filters = [
          makeFilterApi(FILTERS_CAMERA.is_deleted, "=", false),
          makeFilterApi(FILTERS_CAMERA.latitude, "<=", _.round(north, 9)),
          makeFilterApi(FILTERS_CAMERA.longitude, "<=", _.round(east, 9)),
          makeFilterApi(FILTERS_CAMERA.latitude, ">=", _.round(south, 9)),
          makeFilterApi(FILTERS_CAMERA.longitude, ">=", _.round(west, 9)),
        ],
        fields = [
          FIELDS_CAMERA.number,
          FIELDS_CAMERA.address,
          FIELDS_CAMERA.title,
          FIELDS_CAMERA.timezone,
          FIELDS_CAMERA.longitude,
          FIELDS_CAMERA.latitude,
          FIELDS_CAMERA.is_sounding,
          FIELDS_CAMERA.is_public,
          FIELDS_CAMERA.inactivity_period_id,
          FIELDS_CAMERA.server_id,
          FIELDS_CAMERA.streams_count,
          FIELDS_CAMERA.marker_id,
        ];

      return dispatch(ACTION_LOAD_CAMERAS, {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE_FOR_MAP,
        fields: fields,
        filters: filters
      });
    },
    /**
     * Загрузка списка камер по координатам для карты.
     *
     * todo не учитывается долгота +-180
     *
     * @param {Function} dispatch
     * @param {Number} north
     * @param {Number} west
     * @param {Number} south
     * @param {Number} east
     * @return {Promise}
     */
    async [ACTION_LOAD_COUNT_CAMERAS_FOR_MAP]({dispatch}, {north, east, south, west}) {
      const filters = [
        makeFilterApi(FILTERS_CAMERA.is_deleted, "=", false),
        makeFilterApi(FILTERS_CAMERA.latitude, "<=", _.round(north, 9)),
        makeFilterApi(FILTERS_CAMERA.longitude, "<=", _.round(east, 9)),
        makeFilterApi(FILTERS_CAMERA.latitude, ">=", _.round(south, 9)),
        makeFilterApi(FILTERS_CAMERA.longitude, ">=", _.round(west, 9)),
      ];

      const responseData = await dispatch(ACTION_LOAD_CAMERAS, {
        page: 1,
        pageSize: 1,
        filters: filters
      });
      return responseData.count;
    },
    /**
     * Создание новой камеры.
     *
     * @param {Object} context
     * @param {Object} cameraInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_CAMERA](context, cameraInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/create/", cameraInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей камеры.
     *
     * @param {Object} context
     * @param {Object} cameraInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA](context, cameraInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/edit/", cameraInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей камеры с редактированием группы.
     * В рамках редактирования допускается изменение групп, но это изменение по API должно проходить отдельными запросами,
     * поэтому в случае успешного удаления и добавления групп происходит сохранение основных параметров,
     * и возвращается уже промис последнего запроса - общего редактирования.
     *
     * @param {Function} dispatch
     * @param {Object} cameraInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_WITH_GROUPS]({dispatch}, cameraInfo) {
      const [cameraGroupsForDelete, cameraGroupsForAdd] = cameraInfo[FIELDS_CAMERA.camera_group_ids];
      if (!_.isEmpty(cameraGroupsForDelete)) {
        await dispatch(ACTION_DELETE_CAMERA_GROUPS, {
          cameraNumber: cameraInfo[FIELDS_CAMERA.number],
          cameraGroupIds: cameraGroupsForDelete,
        });
      }
      if (!_.isEmpty(cameraGroupsForAdd)) {
        await dispatch(ACTION_ADD_CAMERA_GROUPS, {
          cameraNumber: cameraInfo[FIELDS_CAMERA.number],
          cameraGroupIds: cameraGroupsForAdd,
        });
      }
      return dispatch(ACTION_EDIT_CAMERA, cameraInfo);
    },
    /**
     * Редактирование инвентарных данных существующей камеры.
     *
     * Инвентарные данные изменияются вне API редактирования общих данных камеры
     * и имена полей не совпадают как при получении информации по камере.
     * Поэтому в функции декларируется редактирование информации по камере, а внутри уже поля преобразуются для API.
     *
     * @param {Object} context
     * @param {Object} cameraInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_INVENTORY](context, cameraInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/inventory/edit/", {
          "camera_number": cameraInfo[FIELDS_CAMERA.number],
          "model": cameraInfo[FIELDS_CAMERA.inventory_model],
          "serial_number": cameraInfo[FIELDS_CAMERA.inventory_serial_number],
          "mac": cameraInfo[FIELDS_CAMERA.inventory_mac],
          "address_full": cameraInfo[FIELDS_CAMERA.inventory_address_full],
          "address_region": cameraInfo[FIELDS_CAMERA.inventory_address_region],
          "address_area": cameraInfo[FIELDS_CAMERA.inventory_address_area],
          "address_city": cameraInfo[FIELDS_CAMERA.inventory_address_city],
          "address_city_area": cameraInfo[FIELDS_CAMERA.inventory_address_city_area],
          "address_city_district": cameraInfo[FIELDS_CAMERA.inventory_address_city_district],
          "address_settlement": cameraInfo[FIELDS_CAMERA.inventory_address_settlement],
          "address_street": cameraInfo[FIELDS_CAMERA.inventory_address_street],
          "address_house_type": cameraInfo[FIELDS_CAMERA.inventory_address_house_type],
          "address_house": cameraInfo[FIELDS_CAMERA.inventory_address_house],
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Установка у существующей камеры необходимых групп.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array} cameraGroupIds
     * @return {Promise}
     */
    async [ACTION_SET_CAMERA_GROUPS](context, {cameraNumber, cameraGroupIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/camera_groups/set/", {
          camera_number: cameraNumber,
          camera_group_ids: cameraGroupIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Добавление камеры к необходимым группам.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array} cameraGroupIds
     * @return {Promise}
     */
    async [ACTION_ADD_CAMERA_GROUPS](context, {cameraNumber, cameraGroupIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/camera_groups/add/", {
          camera_number: cameraNumber,
          camera_group_ids: cameraGroupIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление камеры из необходимых групп.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array} cameraGroupIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CAMERA_GROUPS](context, {cameraNumber, cameraGroupIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/camera_groups/delete/", {
          camera_number: cameraNumber,
          camera_group_ids: cameraGroupIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Включение камеры.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_ACTIVATE_CAMERAS](context, {camerasNumbers}) {
      return this.getters.privateAjax.post("/v0/cameras/activate/", {camera_numbers: camerasNumbers});
    },
    /**
     * Выключение камеры.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @param {String} comment
     * @param {String} reason
     * @param {String} todo
     * @return {Promise}
     */
    async [ACTION_DEACTIVATE_CAMERAS](context, {camerasNumbers, comment = "", reason = "", todo = ""}) {
      return this.getters.privateAjax.post("/v0/cameras/deactivate/", {
        camera_numbers: camerasNumbers,
        comment,
        reason,
        todo
      });
    },
    /**
     * Удаление камер.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_CAMERAS](context, {camerasNumbers}) {
      return this.getters.privateAjax.post("/v0/cameras/delete/", {camera_numbers: camerasNumbers});
    },
    /**
     * Блокировка/Разблокировка камер.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @param {String} blockingLevel
     * @param {String} blockingEndDate
     * @return {Promise}
     */
    async [ACTION_BLOCKING_CAMERAS](context, {camerasNumbers, blockingLevel = null, blockingEndDate = null}) {
      return this.getters.privateAjax.post("/v0/cameras/blocking/", {
        camera_numbers: camerasNumbers,
        blocking_lvl: blockingLevel,
        blocking_end_date: blockingEndDate
      });
    },
    /**
     * Создание миграции камер.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} serverId
     * @param {Number} clusterId
     * @param {Boolean} force
     * @param {Boolean} confirmable
     * @return {Promise}
     */
    async [ACTION_CREATE_MIGRATION_CAMERAS](context, {cameraNumber, serverId, clusterId, force,confirmable}) {
      return this.getters.privateAjax.post("/v0/cameras/migration/create/", {
        camera_number: cameraNumber,
        server_id: serverId,
        cluster_id: clusterId,
        force,
        confirmable
      });
    },
    /**
     * Создание миграции камер.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_CANCEL_MIGRATION_CAMERAS](context, {cameraNumber}) {
      return this.getters.privateAjax.post("/v0/cameras/migration/cancel/", {camera_number: cameraNumber});
    },
    /**
     * Подтверждение миграции камер.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_CONFIRM_MIGRATION_CAMERAS](context, {cameraNumber}) {
      return this.getters.privateAjax.post("/v0/cameras/migration/confirm/", {camera_number: cameraNumber});
    },
    /**
     * Отправка запроса с причиной для получения токена на просмотр/скачивание видео с камеры.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @param {String} tokenType
     * @param {String} reason
     * @param {Number} duration
     * @param {Number} start
     * @return {Promise}
     */
    async [ACTION_REQUEST_TOKEN](context, {camerasNumbers, tokenType, reason = "", duration = 20, start = 1}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/tokens/", {
          camera_numbers: camerasNumbers,
          token_type: tokenType,
          reason: reason,
          duration: duration,
          start: start,
        });
        return response.data.results;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Отправка запроса для рестарта потока камеры.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_RESTART_STREAM](context, cameraNumber) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/restart_stream/", {camera_number: cameraNumber});
        return response.data.results;
      } catch (error) {
        throw  error.response.data.error;
      }
    },
    /**
     * Отправка запроса для остановки записи архива камеры.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_DISABLE_RECORD](context, cameraNumber) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/record/disable", {camera_number: cameraNumber});
        return response.data.results;
      } catch (error) {
        throw  error.response.data.error;
      }
    },
    /**
     * Отправка запроса для восстановления записи архива камеры.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_ENABLE_RECORD](context, cameraNumber) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/record/enable", {camera_number: cameraNumber});
        return response.data.results;
      } catch (error) {
        throw  error.response.data.error;
      }
    },
    /**
     * Отправка запроса к серверу flussonic для уточнения доступных участков видео и преобразование их в пары значений (начало-конец фрагмента).
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {String} domain
     * @param {String} token
     * @param {Number} unixFrom
     * @param {Number} unixTo
     * @return {Promise<Array<Array<Date>>>}
     */
    async [ACTION_LOAD_RECORDING_STATUS](context, {cameraNumber, domain, token, unixFrom = 0, unixTo = Math.trunc(Date.now() / 1000)}) {
      try {
        const response = await fetch(`${this.getters.protocolVideoOverHTTP}://${domain}/${cameraNumber}/recording_status.json?request=ranges&from=${unixFrom}&to=${unixTo}&token=${token}`, {
            referrerPolicy: "no-referrer" // https://git.ufanet.ru/video/camsng-frontend-shared/-/issues/1
          }),
          responseData = await response.json();

        return responseData[0].ranges.map(({duration, from}) => {
          return [
            new Date(from * 1000),
            new Date((from + duration) * 1000),
          ];
        });
      } catch (error) {
        return [];
      }
    },
    // Методы для загрузки информации по миграциям камер, начинается с /v0/cameras/migrations
    /**
     * Загрузка служебной информации по миграциям камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CAMERAS_MIGRATION]() {
      const response = await this.getters.privateAjax.post("/v0/cameras/migrations/info/");
      return [FilterInfo.createFromDataApi(response.data.filters, CUSTOM_TYPES_VALUES_FOR_LOOKUPS_BY_FIELD), response.data.orders];


    },
    /**
     * Загрузка списка миграций камер.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_MIGRATION](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CAMERA_MIGRATION, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/migrations", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка миграций для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_MIGRATION_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_CAMERAS_MIGRATION, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CAMERAS_MIGRATION_FOR_TABLE});
    },

    // Методы для загрузки информации по истории камер, начинается с /v0/cameras/history
    /**
     * Загрузка служебной информации для камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CAMERAS_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/cameras/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters, CUSTOM_TYPES_VALUES_FOR_LOOKUPS_BY_FIELD), response.data.orders];


    },
    /**
     * Загрузка списка камер.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CAMERA_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;

        });

      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Управление камерой PTZ
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_PTZ_CAMERA](context, action) {
      return this.getters.privateAjax.post("v0/cameras/setup/ptz", {
        ...action,
      });
    },
    /**
     * Загрузка списка камер для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERAS_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_CAMERAS_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CAMERAS_HISTORY_FOR_TABLE});
    },
  },
};
