<template>
  <div class="content__settings__body">
    <div class="content__table cams-table-wrapper">
      <table class="cams-table">
        <tr>
          <th class="cams-table__cell">
            {{ columnCaptions.key }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.username }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.date }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.ip }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.user_agent }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.path }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.front_id }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.front_type }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.http_host }}
          </th>
        </tr>
        <tr v-if="isLoadingPage" class="cams-table__row-spinner">
          <td :colspan="7" class="cams-table__cell">
            <SpinnerLoading size="s" />
          </td>
        </tr>
        <tr v-for="(rowDataTable, rowDataTableIndex) in listDataTable" :key="rowDataTableIndex">
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.key] }}
          </td>
          <td class="cams-table__cell">
            <!--   todo пока непонятно куда редиректить при клике на пользователя - админы или клиенты         -->
            <router-link :to="{name: routes.ROUTE_EDIT_ADMIN, params: {adminId: rowDataTable[fieldsTable.user_id]}}">
              {{ rowDataTable[fieldsTable.username] }}
            </router-link>
            <router-link :to="{name: routes.ROUTE_EDIT_CLIENT, params: {clientId: rowDataTable[fieldsTable.user_id]}}">
              {{ rowDataTable[fieldsTable.username] }}
            </router-link>
          </td>
          <td :title="rowDataTable[fieldsTable.date] | localDateTimeZone" class="cams-table__cell">
            {{ rowDataTable[fieldsTable.date] | localDateTime }}
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.ip] }}
          </td>
          <td :title="rowDataTable[fieldsTable.user_agent]" class="cams-table__cell">
            <template v-if="rowDataTable[fieldsTable.user_agent]">
              {{ rowDataTable[fieldsTable.user_agent].replace(/^(.{20}[^\s]*).*/, "$1...") }}
            </template>
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.path] }}
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.front_id] }}
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.front_type] }}
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.http_host] }}
          </td>
        </tr>
      </table>
    </div>

    <nav class="pagination">
      <paginate
        v-model="currentPage"
        :active-class="'pagination__list__item_active'"
        :break-view-class="'pagination__list__item_collapse'"
        :click-handler="selectPage"
        :container-class="'pagination__list'"
        :hide-prev-next="true"
        :page-class="'pagination__list__item'"
        :page-count="pageInfo.numPages"
        :page-range="5"
        next-text=""
        prev-text=""
      />
    </nav>
  </div>
</template>

<script>
import {logByCriteriaMixin} from "@/components/log/mixin.js";
import {FIELDS_LOG_AUTHS, TITLES_FIELDS_LOG_AUTHS} from "@/store/log/index.js";

/**
 * Несамостоятельный компонент для страниц отображения таблиц быстрого доступа к логам авторизации.
 * Необходимо передать критерий фильтрации по логам.
 */
export default {
  mixins: [logByCriteriaMixin],
  data() {
    return {
      fieldsTable: FIELDS_LOG_AUTHS,
      columnCaptions: TITLES_FIELDS_LOG_AUTHS,
    };
  },
};
</script>
