/**
 * Вспомогательные функции.
 */

/**
 * Преобразует перечень ошибок полей форм в формат пригодный для их отображения.
 *
 * При передаче форм создания/редактирования объектов могут возникать ошибки.
 * Формат коллекции ошибок определенный сервером не подходит для использования их в чистом виде,
 * поэтому он преобразуется в вид {fieldName: "All errors by field", fieldNameArray: {errorIndex: "Error in value by index"}}.
 *
 * @param {Object} errorsFields
 * @return {Object}
 */
export function reformatErrorsForm(errorsFields) {
  return _.reduce(errorsFields, (result, value, key) => {
    const [errorField, errorFieldIndex] = key.split(".");
    if (errorFieldIndex) {
      (result[errorField] || (result[errorField] = []))[errorFieldIndex] = value.join(" ");
    } else {
      result[errorField] = value.join(" ");
    }
    return result;
  }, {});
}

/**
 * Перечисление типов для значений которые передаются в качестве аргументов к фильтрам.
 * Типы нужны чтобы определить формат значений вводимых пользователем и отобразить в интерфейсе нужное поле ввода.
 *
 * Типы указываются в API к методам фильтрации (Lookup).
 */
export const TYPES_VALUES_FOR_LOOKUPS = Object.freeze({
  // Стандартные типы.
  INT: "int",
  STR: "str",
  BOOL: "bool",
  DECIMAL: "decimal",
  ARRAY: "array",
  DATE_TIME: "datetime",
  // Специальные типы.
  DATE_TIME_BETWEEN: "datetime_between",
});

/**
 * Настройки для создания объектов-lookup специальных типов.
 */
const CONFIGS_CUSTOM_LOOKUPS = Object.freeze({
  [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME_BETWEEN]: {
    lookup: "datetime_between",
    title: "между",
    isAvailableExclude: false,
  }
});

/**
 * Класс для представления информации об имеющихся фильтров для определенного поля у сущности.
 * Класс нужен только чтобы отобразить для клиентского приложения ту информацию о доступных пользователю фильтрах,
 * которая хранится на сервере.
 *
 * Для некоторых фильтров предусмотрены специальные объекты-lookup особых типов,
 * которые по особому будут обрабатываться на клиентской стороне.
 */
export class FilterInfo {
  constructor(name, title, lookups, customTypesValuesForLookups = null) {
    this.name = name;
    this.title = title;

    this.lookups = lookups.map(({lookup, title, type}) => {
      return new LookupInfo(this.name, lookup, title, type);
    });
    // К дефолтным методам фильтрации подмешиваются специальные.
    if (customTypesValuesForLookups) {
      const customTypesValuesForLookup = _.get(customTypesValuesForLookups, name, []);
      customTypesValuesForLookup.forEach((customTypeValueForLookup) => {
        const configCustomLookup = CONFIGS_CUSTOM_LOOKUPS[customTypeValueForLookup];
        if (configCustomLookup) {
          this.lookups.push(
            new LookupInfo(
              this.name,
              configCustomLookup.lookup,
              configCustomLookup.title,
              configCustomLookup.lookup,
              configCustomLookup.isAvailableExclude
            )
          );
        }
      });
    }

    this.lookups = _.keyBy(this.lookups, itemLookupInfo => itemLookupInfo.lookup);
  }

  /**
   * Вернет объект с набором информации о фильтрах по сырым данным, полученным из сервера.
   *
   * @param {Object} dataApi
   * @param {Object|null} customTypesValuesForLookups
   * @return {Object}
   */
  static createFromDataApi(dataApi, customTypesValuesForLookups = null) {
    return _.mapValues(dataApi, (filterInfo) => {
      return new FilterInfo(
        filterInfo.name,
        filterInfo.label,
        filterInfo.lookups.map((lookupInfo) => {
          return {lookup: lookupInfo.lookup, title: lookupInfo.name, type: lookupInfo.argument_type};
        }),
        customTypesValuesForLookups
      );
    });
  }
}

/**
 * Класс для представления объектов-lookup необходимых для формирования компонентов фильтрации.
 *
 * Lookup по сути является методом фильтрации, т.е. задает правила как применять значение для фильтрации к конкретному фильтру.
 * Одни и те же методы могут применяться к разным фильтрам, разница может быть в типе значений для фильтрации,
 * поэтому в этом классе организуется построение конечного комплекта данных для передачи в API для фильтрации
 * и при этом происходит приведение этого значения к требуемому типу.
 *
 * Класс связан с классом FilterInfo, т.к. именно фильтры содержат в своих рамках информацию о доступных методах фильтрации.
 */
export class LookupInfo {
  /**
   * @param {String} nameFilter
   * @param {String} lookup
   * @param {String} title
   * @param {String} type
   * @param {Boolean} isAvailableExclude
   */
  constructor(nameFilter, lookup, title, type = TYPES_VALUES_FOR_LOOKUPS.STR, isAvailableExclude = true) {
    this.nameFilter = nameFilter;
    this.lookup = lookup;
    this.title = title;
    this.type = type;
    this.isAvailableExclude = isAvailableExclude;

    // Принятие дефолтной функции для обработки значений в зависимости от типа метода фильтра.
    this.buildFilterApi = {
      [TYPES_VALUES_FOR_LOOKUPS.INT]: this.builderFilterApiForInt,
      [TYPES_VALUES_FOR_LOOKUPS.STR]: this.builderFilterApiForStr,
      [TYPES_VALUES_FOR_LOOKUPS.BOOL]: this.builderFilterApiForBool,
      [TYPES_VALUES_FOR_LOOKUPS.DECIMAL]: this.builderFilterApiForDecimal,
      [TYPES_VALUES_FOR_LOOKUPS.ARRAY]: this.builderFilterApiForArray,
      [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME]: this.builderFilterApiForDateTime,
      [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME_BETWEEN]: this.builderFilterApiForDateTimeBetween,
    }[this.type];
  }

  // Функции для преобразования вводимого пользователем значения для фильтра в аргументы для фильтрации.

  builderFilterApiForInt(rawValue, isExclude) {
    return makeFilterApi(this.nameFilter, this.lookup, Number(rawValue[0]), isExclude);
  }

  builderFilterApiForStr(rawValue, isExclude) {
    return makeFilterApi(this.nameFilter, this.lookup, rawValue[0], isExclude);
  }

  builderFilterApiForBool(rawValue, isExclude) {
    return makeFilterApi(this.nameFilter, this.lookup, Boolean(rawValue[0]), isExclude);
  }

  builderFilterApiForDecimal(rawValue, isExclude) {
    return makeFilterApi(this.nameFilter, this.lookup, Number(rawValue[0]), isExclude);
  }

  builderFilterApiForArray(rawValue, isExclude) {
    return makeFilterApi(this.nameFilter, this.lookup, String(rawValue[0]).split(",").map(v => v.trim()), isExclude);
  }

  builderFilterApiForDateTime(rawValue, isExclude) {
    return makeFilterApi(this.nameFilter, this.lookup, rawValue[0], isExclude);
  }

  // eslint-disable-next-line no-unused-vars
  builderFilterApiForDateTimeBetween(rawValue, isExclude) {
    return [
      makeFilterApi(this.nameFilter, ">=", rawValue[0], false),
      makeFilterApi(this.nameFilter, "<=", rawValue[1], false)
    ];
  }
}

/**
 * Класс для задания данных для фильтрации.
 *
 * При том что классы FilterInfo и LookupInfo необходимы для представления информации о доступных фильтрах,
 * информация об этом хранится на сервере, данный класс служит для хранения информации о конкретных фильтрах
 * которые задает и использует пользователь.
 *
 * Важно! В качестве манипулируемого значения для фильтра выступает массив.
 * У каждого фильтра, чаще всего, в интерфейсе надо указать одно единственное значение.
 * Однако, иногда в интерфейсе удобно указывать несколько значений, которые потом преобразуются в отдельные фильтры.
 * Поэтому из соображений удобства пусть в значении будет всегда массив,
 * в шаблоне каждое поле ввода значения для фильтра будет ссылаться на конкретный индекс этого массива.
 * Т.е. для простых фильтров всегда надо оперировать value[0], а в сложных value[0] value[1] и т.д.
 * При сборе фильтров для передачи в api поведение аналогично.
 */
export class FilterData {
  constructor(name, lookup = "", value = [], isExclude = false, isActive = true) {
    this.name = name;
    this.lookup = lookup;
    this.value = value;
    this.isExclude = isExclude;
    this.isActive = isActive;
  }
}

/**
 * Общая функция сборки одного комплекта информации о фильтре, который можно передавать в API для фильтрации сущностей.
 *
 * @param {String} name
 * @param {String} lookup
 * @param {*} value
 * @param {Boolean} isExclude
 * @return {{lookup: String, argument: String, field: *, exclude: Boolean}}
 */
export function makeFilterApi(name, lookup, value, isExclude = false) {
  return {field: name, lookup, argument: value, exclude: isExclude};
}

/**
 * Построит массив состоящий из объектов, пригодных для передачи в API в параметр фильтрации данных.
 *
 * На основе пользовательских требований к фильтрам (которые описываются через массив {@link FilterData})
 * и доступных фильтрах и их настройках (описания в {@link FilterInfo}) происходит смешивание
 * и в результате будет массив объектов, который можно просто подставить в параметр фильтрации API.
 *
 * @param {Array} listFiltersData Массив {@link FilterData} с данными для которых будет построены фильтры.
 * @param {Object} listFiltersInfo Массив {@link FilterInfo} с начальной информацией и параметрами работы фильтров.
 * @return {Array}
 */
export function buildFiltersApiFromData(listFiltersData, listFiltersInfo) {
  return _.chain(listFiltersData).flatMap((filterData) => {
    if (filterData.isActive && filterData.name && filterData.lookup && !_.isEmpty(filterData.value)) {
      return listFiltersInfo[filterData.name].lookups[filterData.lookup].buildFilterApi(
        filterData.value,
        filterData.isExclude
      );
    }
    return null;
  }).filter().value();
}

/**
 * Класс с согласованной структурой параметров для таблиц сущностей.
 *
 * Параметры необходимы для корректной выдачи информации в таблице.
 * Класс необходим для преобразования объекта параметров в строку и обратно, чтобы иметь в одном месте механизм сериализации
 * параметров, результат которой будет подставляться в адресную строку.
 *
 * Если для какого-либо параметра информация не задана - она исключается из конечного результата сериализации.
 * Для такого случая в конечном счете значения принимаются по умолчанию, которые задаются для каждой сущности в отдельности.
 */
export class TableQueryParams {
  constructor({currentPage = null, pageSize = null, order = null, selectedFieldsTableForView = null, filters = null, searchText = null}) {
    this.currentPage = currentPage;
    this.order = order;
    this.selectedFieldsTableForView = selectedFieldsTableForView;
    this.filters = filters;
    this.searchText = searchText;
    this.pageSize = pageSize;
  }

  /**
   * Создаст из переданной строки объект {@link TableQueryParams}.
   *
   * @param {String|Null} params
   * @return {TableQueryParams}
   */
  static parse(params) {
    const parsedParams = JSON.parse(decodeURI(params || encodeURI("{}")));
    return new TableQueryParams({
      currentPage: parsedParams.currentPage ? parseInt(parsedParams.currentPage) : null,
      order: parsedParams.order || null,
      selectedFieldsTableForView: parsedParams.selectedFieldsTableForView || null,
      filters: parsedParams.filters || null,
      searchText: parsedParams.searchText || null,
      pageSize: parsedParams.pageSize || null
    });
  }

  /**
   * Преобразует объект в строку, пригодную для вставки в качестве значения query параметра в URL.
   *
   * @return {String}
   */
  stringify() {
    const params = _.omitBy({
      currentPage: this.currentPage,
      order: this.order,
      selectedFieldsTableForView: this.selectedFieldsTableForView,
      filters: this.filters,
      searchText: this.searchText,
      pageSize: this.pageSize,
    }, _.isNil);

    return encodeURI(JSON.stringify(params));
  }
}

/**
 * Класс для представления результата обработки одного экземпляра сущности.
 *
 * Можно представить результат в виде одной строчки, можно в виде массива строк.
 */
export class ResultProcessingEntityMultiEdit {
  /**
   * @param {String|Array<String>} result
   */
  constructor(result) {
    this.result = result;
  }

  /**
   * Вернет простой объект успешного результата.
   *
   * @param {String|Number} entityKey
   * @return {ResultProcessingEntityMultiEdit}
   */
  static success(entityKey) {
    return new ResultProcessingEntityMultiEdit(`${moment().format("DD.MM.YYYY HH:mm:ss")} - Успешно [${entityKey}]`);
  }

  /**
   * Вернет простой объект ошибочного результата.
   *
   * @param {String|Number} entityKey
   * @return {ResultProcessingEntityMultiEdit}
   */
  static error(entityKey) {
    return new ResultProcessingEntityMultiEdit(`${moment().format("DD.MM.YYYY HH:mm:ss")} - Ошибка [${entityKey}]`);
  }

  /**
   * Вернет объект ошибочного результата полученный в результате запроса.
   *
   * @param {String|Number} entityKey
   * @param {Object} error
   * @return {ResultProcessingEntityMultiEdit}
   */
  static errorRequest(entityKey, error) {
    return new ResultProcessingEntityMultiEdit(
      `${moment().format("DD.MM.YYYY HH:mm:ss")} - Ошибка [${entityKey}]: ${JSON.stringify(error.response.data)}`
    );
  }

  /**
   * Текст для помещения в лог.
   *
   * @return {String|Array<String>}
   */
  toLog() {
    return this.result;
  }
}
