<template>
  <div class="event-screenshot-dialog">
    <SpinnerLoadingModal v-if="isLoadingScreenShot" />

    <img
      v-show="!isLoadingScreenShot && fullScreenshotLoaded"
      ref="screenshotImage"
      :src="fullScreenshot"
      style="width: 100%"
      @load="handleImageLoad"
      @error="handleImageError"
    >

    <h4 v-show="!fullScreenshot">
      Скриншот в обработке.
    </h4>
    <p class="event-screenshot-dialog__caption" v-text="analyticMessage.summarize" />
  </div>
</template>

<script>
import {ACTION_LOAD_FULL_SCREENSHOT_EVENT} from "@/store/analytics/index.js";
import {AnalyticMessage} from "@/store/analytics/analyticMessage.js";

/**
 * Вторичное диалоговое окно, которое нужно только для отображения скриншота события.
 * Это окно всплывает поверх другого модального окна. Нужно учесть это и закрыть их в правильном порядке, чтоб не наслаивались.
 */
export default {
  name: "EventScreenshotDialog",
  props: {
    /**
     * Экземпляр {@link AnalyticMessage} из которого извлекается URL полного скриншота.
     */
    analyticMessage: {
      type: AnalyticMessage,
      required: true
    },
    archiveToken: {
      type: String,
    },
  },
  data() {
    return {
      isLoadingScreenShot: true,
      fullScreenshot: "",
      fullScreenshotLoaded: false
    };
  },
  /**
   * Получение скриншота из сообщения события.
   * Сообщение может быть получено по каналам не предусматривающих извлечение URL напрямую из сообщения (SSE на прямой трансляции),
   * тогда в общем случае нужно это проверять и в случае отсутствия запрашивать по API.
   */
  async created() {
    try {
      const fullScreenshot = await this.$store.dispatch(`analytics/${ACTION_LOAD_FULL_SCREENSHOT_EVENT}`, {
        analyticMessage: this.analyticMessage,
        screenshotToken: this.archiveToken,
      });
      this.fullScreenshot = fullScreenshot;
    } catch (error) {
      //
    }
  },
  methods: {
    handleImageLoad() {
      this.isLoadingScreenShot = false;
      this.fullScreenshotLoaded = true;
    },
    handleImageError() {
      this.isLoadingScreenShot = false;
      this.showError = true;
    },
  },
};
</script>



<style lang="scss">
@import "../../../node_modules/camsng-frontend-shared/styles/vars";

.event-screenshot-dialog {
  &__caption {
    font-family: $cams-font-roboto-light;
    font-size: 13px;
  }
}
</style>
