<template>
  <div class="map-popup">
    <div v-if="!markerInfo.isInactive && markerInfo.displayPopup" class="map-popup__screenshot">
      <img :src="screenshotUrl" alt="Скриншот не доступен">
    </div>

    <p class="map-popup__number">
      <strong>
        <router-link :to="{name: routes.ROUTE_EDIT_CAMERA, params: {cameraNumber: markerInfo.cameraNumber}}">
          {{ markerInfo.cameraNumber }}
        </router-link>
      </strong>
    </p>

    <div v-if="!markerInfo.isInactive" class="buttons-group map-popup__controls">
      <CamsButton
        v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)"
        icon-type="only"
        style="border: none; font-size: 24px;"
        title="Просмотр прямой трансляции"
        type="button"
        @click="openDialogRequestTokenCamera([typesToken.L, typesToken.R])"
      >
        <span class="icon icon-super-play" />
      </CamsButton>
      <CamsButton
        v-if="$can($abilitiesActions.LIVE_REASON, $abilitiesSubjects.CAMERA)"
        icon-type="only"
        style="border: none; font-size: 24px;"
        title="Просмотр прямой трансляции"
        type="button"
        @click="openDialogRequestTokenCamera([typesToken.L])"
      >
        <span class="icon icon-play" />
      </CamsButton>
      <CamsButton
        v-if="$can($abilitiesActions.DVR_REASON, $abilitiesSubjects.CAMERA)"
        icon-type="only"
        style="border: none; font-size: 24px;"
        title="Просмотр архива"
        type="button"
        @click="openDialogRequestTokenCamera([typesToken.R])"
      >
        <span class="icon icon-archive" />
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {ROUTE_EDIT_CAMERA} from "@/router/names.js";
import {CAMERA_SCREENSHOT_SIZES, CONFIG_BASE_URL} from "@/utils/consts.js";
import RequestTokenCameraDialog from "@/components/cameras/RequestTokenCameraDialog.vue";
import {TOKEN_TYPES} from "@/store/cameras/index.js";
import {MarkerInfo} from "@/components/onMap/mixin.js";

/**
 * Компонент для отображения содержимого popup-окна для маркера с информацией по камере.
 *
 * Скриншот с камеры будет появляться (и соответственно его загрузка) только когда окно будет открыто.
 * Если делать без проверки на отображение тогда все маркеры на карте будут запрашивать скриншоты одновременно при загрузке компонента,
 * даже если он не отображается.
 */
export default {
  props: {
    /**
     * Данные по маркеру, над которым показывается данный попап.
     */
    markerInfo: {
      type: MarkerInfo,
      required: true,
    },
  },
  data() {
    return {
      routes: {
        ROUTE_EDIT_CAMERA,
      },
      typesToken: TOKEN_TYPES,
    };
  },
  computed: {
    /**
     * @return {String} URL до актуального скриншота с выбранной камеры.
     */
    screenshotUrl() {
      return `${CONFIG_BASE_URL}v0/easy/screenshots/${this.markerInfo.cameraNumber}~${CAMERA_SCREENSHOT_SIZES.s400}.jpg?token=${this.$store.state.token}`;
    },
  },
  methods: {
    /**
     * Открытие формы для запроса видео с камеры.
     *
     * @param {Array<String>} tokenTypes
     */
    openDialogRequestTokenCamera(tokenTypes) {
      this.$camsdals.open(
        RequestTokenCameraDialog,
        {
          cameraNumber: this.markerInfo.cameraNumber,
          title: this.markerInfo.title,
          tokenTypes,
          domain: this.markerInfo.domain,
          streamCount: this.markerInfo.streamCount,
          hasSound: this.markerInfo.hasSound,
          hasPtz: this.markerInfo.hasPtz
        },
        {dialogTitle: `Просмотр видео с камеры №${this.markerInfo.cameraNumber} - ${this.markerInfo.title}`},
        {
          size: "lg",
        },
      );
    },
  },
};
</script>

<style lang="scss">
.map-popup {
  display: block;

  &__screenshot {
    width: 400px;
  }

  &__number {
    text-align: center;
  }

  &__controls {
    justify-content: center;
  }
}
</style>
