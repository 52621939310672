<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.name]"
        :caption="titlesFields[fieldsEntity.name]"
        :error="errorsFormEdit[fieldsEntity.name]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_ANALYTIC_CLUSTER} from "@/router/names.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {
  ACTION_CREATE_ANALYTIC_CLUSTER,
  FIELDS_ANALYTIC_CLUSTER,
  TITLES_FIELDS_ANALYTIC_CLUSTER
} from "@/store/analytics/analyticClusters/index.js";

/**
 * Компонент диалога создания кластера аналитики.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `analytics/analyticClusters/${ACTION_CREATE_ANALYTIC_CLUSTER}`,
      fieldsEntity: FIELDS_ANALYTIC_CLUSTER,
      titlesFields: TITLES_FIELDS_ANALYTIC_CLUSTER,
      formEdit: {
        [FIELDS_ANALYTIC_CLUSTER.name]: "",
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование кластера аналитики.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_ANALYTIC_CLUSTER, params: {clusterId: newEntity[FIELDS_ANALYTIC_CLUSTER.id]}};
    },
  },
};
</script>
