<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <a class="tabs__item tabs__item_active" href="#">Карта</a>
      </div>
    </div>

    <div class="main__content content">
      <div class="row">
        <SmartInputAddress
          :initial-latitude="centerMap[0]"
          :initial-longitude="centerMap[1]"
          class="col"
          @extract-coordinates="changeCenterMapFromAddress($event)"
        />
      </div>

      <div style="width: 100%; height: 700px;">
        <l-map
          ref="map"
          :center="centerMap"
          :options="settingsMap.options"
          :zoom="zoom"
          @ready="updateContentOnMap($event.getBounds())"
          @update:bounds="updateContentOnMap"
          @update:center="changeCenterMap"
        >
          <l-tile-layer attribution="&copy; Участники <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>" :url="mapTilesUrl" />

          <l-rectangle v-if="showHints" :bounds="hints[0].bounds" :weight="0" fill-color="red" />
          <l-marker v-if="showHints" :lat-lng="hints[0].center">
            <l-tooltip :content="hints[0].countCameras" :options="{permanent: true, interactive: true}" />
          </l-marker>

          <l-rectangle v-if="showHints" :bounds="hints[1].bounds" :weight="0" fill-color="green" />
          <l-marker v-if="showHints" :lat-lng="hints[1].center">
            <l-tooltip :content="hints[1].countCameras" :options="{permanent: true, interactive: true}" />
          </l-marker>

          <l-rectangle v-if="showHints" :bounds="hints[2].bounds" :weight="0" fill-color="blue" />
          <l-marker v-if="showHints" :lat-lng="hints[2].center">
            <l-tooltip :content="hints[2].countCameras" :options="{permanent: true, interactive: true}" />
          </l-marker>

          <l-rectangle v-if="showHints" :bounds="hints[3].bounds" :weight="0" fill-color="yellow" />
          <l-marker v-if="showHints" :lat-lng="hints[3].center">
            <l-tooltip :content="hints[3].countCameras" :options="{permanent: true, interactive: true}" />
          </l-marker>

          <l-cluster>
            <l-marker
              v-for="markerInfo in markersCameras"
              :key="'camera-' + markerInfo.cameraNumber"
              :ref="markerInfo.cameraNumber"
              :icon="markerInfo.cameraIcon"
              :lat-lng="markerInfo.coordinates"
              @popupclose="markerInfo.hidePopup()"
              @popupopen="markerInfo.showPopup()"
            >
              <l-popup :options="{closeButton: false, minWidth: 400}">
                <MapPopup :marker-info="markerInfo" />
              </l-popup>
            </l-marker>
          </l-cluster>
        </l-map>

        <p v-if="isLoading">
          Загрузка
        </p>
        <p v-else>
          Реальное количество камер на карте в пределах текущих границ: <strong>{{ realCountCamerasOnMap }}</strong>,
          отображается: <strong>{{ markersCameras.length }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {LMap, LMarker, LPopup, LRectangle, LTileLayer, LTooltip} from "vue2-leaflet";
import {ACTION_LOAD_CAMERAS_FOR_MAP, ACTION_LOAD_COUNT_CAMERAS_FOR_MAP, EXTRAS_CAMERA, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {mapMixin} from "@/utils/mixins.js";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import {FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import MapPopup from "@/components/onMap/MapPopup.vue";
import {MarkerInfo} from "@/components/onMap/mixin.js";
import {DEFAULT_PAGE_SIZE_FOR_MAP, MAP_TILES_URL} from "@/utils/consts.js";

/**
 * Компонент большой карты с маркерами камер и функциям для быстрого доступа к ним.
 */
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LRectangle,
    LTooltip,
    "l-cluster": Vue2LeafletMarkerCluster,
    MapPopup
  },
  mixins: [
    mapMixin
  ],
  data() {
    return {
      mapTilesUrl: MAP_TILES_URL,
      isLoading: false,
      /**
       * Массив с объектами данных для отрисовки маркеров на карте и их всплывающих окон {@link MarkerInfo}.
       */
      markersCameras: [],
      realCountCamerasOnMap: 0,
      zoom: null,
      // Сектора с подсказками на случай если на карте слишком много камер.
      showHints: true,
      hints: [
        {bounds: [[0, 0], [1, 1]], center: [0, 0], countCameras: "-"},
        {bounds: [[0, 0], [1, 1]], center: [0, 0], countCameras: "-"},
        {bounds: [[0, 0], [1, 1]], center: [0, 0], countCameras: "-"},
        {bounds: [[0, 0], [1, 1]], center: [0, 0], countCameras: "-"},
      ]
    };
  },
  /**
   * При создании компонента зум устанавливается в стандартное положение.
   */
  created() {
    this.zoom = this.settingsMap.defaultZoom;
  },
  methods: {
    /**
     * При изменении центра карты через поле поиска адреса еще меняется и зум.
     *
     * @param {{lat: Number, lng: Number}|Array} newCenterMap
     */
    changeCenterMapFromAddress(newCenterMap) {
      this.zoom = 15;
      this.changeCenterMap(newCenterMap);
    },
    /**
     * Обновление содержимого на карте в зависимости от заданных границ карты и камер.
     *
     * Вначале запрашивается только количество камер на всей границе карты.
     * Если количество камер на карте не умещается в лимиты, то работает функция по отображению их количества.
     * Если лимиты соблюдаются то запрашиваются камеры и отрисовываются маркеры на карте.
     *
     * @param {Object} boundsMap
     */
    async updateContentOnMap(boundsMap) {
      const north = boundsMap.getNorth(),
            east = boundsMap.getEast(),
            south = boundsMap.getSouth(),
            west = boundsMap.getWest(),
            centerMap = boundsMap.getCenter();

      this.isLoading = true;
      const realCountCamerasOnMap = await this.$store
        .dispatch(`cameras/${ACTION_LOAD_COUNT_CAMERAS_FOR_MAP}`, {north, east, south, west});

      this.realCountCamerasOnMap = realCountCamerasOnMap;
      if (realCountCamerasOnMap <= DEFAULT_PAGE_SIZE_FOR_MAP) {
        this.showHints = false;
        await this.loadCamerasForMap(north, east, south, west);
      } else {
        this.showHints = true;
        this.markersCameras = [];
        await this.loadHints(north, east, south, west, centerMap);
      }
      this.isLoading = false;

    },
    /**
     * Загрузка камер для отображения их на карте.
     *
     * @param {Number} north
     * @param {Number} east
     * @param {Number} south
     * @param {Number} west
     */
    async loadCamerasForMap(north, east, south, west) {
      const responseData = await this.$store
        .dispatch(`cameras/${ACTION_LOAD_CAMERAS_FOR_MAP}`, {north, east, south, west});

      const domainsByServers = _.chain(responseData.extra[EXTRAS_CAMERA.server])
        .keyBy(FIELDS_SERVER.id)
        .mapValues(FIELDS_SERVER.domain)
        .value();

      this.markersCameras = responseData.results.map((cameraInfo) => {
        return new MarkerInfo(
          cameraInfo[FIELDS_CAMERA.number],
          cameraInfo[FIELDS_CAMERA.title],
          !!cameraInfo[FIELDS_CAMERA.inactivity_period_id],
          [cameraInfo[FIELDS_CAMERA.latitude], cameraInfo[FIELDS_CAMERA.longitude]],
          domainsByServers[cameraInfo[FIELDS_CAMERA.server_id]],
          cameraInfo[FIELDS_CAMERA.streams_count],
          cameraInfo[FIELDS_CAMERA.is_sounding],
          cameraInfo[FIELDS_CAMERA.marker_id],
        );
      });
    },
    /**
     * Разделение видимого участка карты на несколько секторов и опрос сервера на количество камер в заданных секторах
     *
     * @param {Number} north
     * @param {Number} east
     * @param {Number} south
     * @param {Number} west
     * @param {Object} centerMap
     */
    loadHints(north, east, south, west, centerMap) {
      // Границы секторов и их центры.
      this.hints[0].bounds = [[north, west], [centerMap.lat, centerMap.lng]];
      this.hints[1].bounds = [[north, centerMap.lng], [centerMap.lat, east]];
      this.hints[2].bounds = [[centerMap.lat, west], [south, centerMap.lng]];
      this.hints[3].bounds = [[centerMap.lat, centerMap.lng], [south, east]];

      this.hints.map(async (hint) => {
        hint.center = [(hint.bounds[0][0] + hint.bounds[1][0]) / 2, (hint.bounds[0][1] + hint.bounds[1][1]) / 2];
        hint.countCameras = "-";

        const countCameras = await this.$store.dispatch(`cameras/${ACTION_LOAD_COUNT_CAMERAS_FOR_MAP}`, {
          north: hint.bounds[0][0],
          east: hint.bounds[1][1],
          south: hint.bounds[1][0],
          west: hint.bounds[0][1]
        });
        hint.countCameras = String(countCameras);
      });
    }
  },
};
</script>