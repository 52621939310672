<template>
  <div>
    <div v-if="!isCorrectData" class="row">
      <p><strong>Произошла ошибка. На этой камере невозможно запросить видеопоток.</strong></p>
    </div>

    <template v-else>
      <div v-if="hasAnyToken && !isLoading" class="row smart-player-screen">
        <SmartPlayer
          :analytic-lines-config="analyticLinesConfig"
          :analytic-zones-config="analyticZonesConfig"
          :show-zones-options-flag="showZonesOptionsFlag"
          :archive-token="archiveToken"
          :available-archive-fragments="availableArchiveFragments"
          :camera-number="cameraNumber"
          :domain="domain"
          :vendor-name="vendorName"
          :force-block-live-mode="forceBlockLiveMode"
          :get-line-fragments="getLineFragments"
          :has-sound="hasSound"
          :http-protocol="$store.getters.protocolVideoOverHTTP"
          :initial-low-latency-mode="lowLatencyMode || hasPtz"
          :has-ptz="hasPtz"
          :initial-time-shift="initialTimeShift"
          :live-token="liveToken"
          :max-unix-download-delta="maxUnixDownloadDelta"
          :min-unix-download-delta="minUnixDownloadDelta"
          :possible-low-latency-hls="false"
          :stream-count="streamCount"
          :ws-protocol="$store.getters.protocolVideoOverWS"
          :on-p-t-z-start="debouncedStartPTZ"
          :on-p-t-z-stop="stopPTZ"
          :on-p-t-z-centralize="centralizePTZ"
          @download-video="downloadVideo"
          @save-lines="saveLines"
          @save-zones="saveZones"
        />

        <!--iframe для скачивания no-referrer -->
        <iframe
          :src="downloadUrl"
          frameborder="0"
          height="1"
          referrerpolicy="no-referrer"
          width="1"
        />
      </div>

      <form v-if="!hasAnyToken && !isLoading" @keyup.enter="requestTokens()" @submit.prevent="requestTokens()">
        <div class="row">
          <SmartInputText
            v-model="reason"
            :error="textError"
            :make-focus="true"
            caption="Укажите причину для просмотра видео"
            class="col"
          />
        </div>

        <div class="dialog-actions">
          <CamsButton type="button" @click="closeDialog()">
            Отменить
          </CamsButton>
          <CamsButton priority="primary" type="submit">
            Запросить
          </CamsButton>
        </div>
      </form>
    </template>

    <SpinnerLoading v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {
  ACTION_LOAD_RECORDING_STATUS,
  ACTION_PTZ_CAMERA,
  ACTION_REQUEST_TOKEN,
  TOKEN_TYPES
} from "@/store/cameras/index.js";
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {AnalyticLinesConfig,AnalyticZonesConfig} from "camsng-frontend-shared/components/smartPlayer/mixins.js";
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER, MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER} from "@/utils/consts.js";

/**
 * Компонент диалога для запроса токена для получения видео с конкретной камеры.
 */
export default {
  components: {
    SmartPlayer
  },
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Номер камеры для получения токена.
     */
    cameraNumber: {
      type: String,
      default: null,
    },
    /**
     * Заголовок камеры.
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Типы запрашиваемых токенов.
     * В зависимости от ситуации можно указать несколько токенов и все будут получены и использованы для плеера.
     */
    tokenTypes: {
      type: Array,
      default: () => ([TOKEN_TYPES.L, TOKEN_TYPES.R]),
    },
    /**
     * Домен сервера с которого можно получить поток с камеры.
     */
    domain: {
      type: String,
      default: null,
    },
    /**
     * Количество доступных потоков.
     */
    streamCount: {
      type: Number,
      default: null,
    },
    /**
     * Флаг, определяющий возможность настройки громкости.
     */
    hasSound: {
      type: Boolean,
      default: false,
    },
    /**
     * Флаг, определяющий возможность управления PTZ камерой.
     */
    hasPtz: {
      type: Boolean,
      default: false,
    },
    /**
     * Флаг, определяющий возможность настройки громкости.
     */
    vendorName: {
      type: String,
      default: null,
    },
    showZonesOptionsFlag: {
      type: Boolean,
      default: false,
    },
    /**
     * Набор настроек и прочей информации по зонам аналитики.
     */
    analyticLinesConfig: {
      type: AnalyticLinesConfig,
      default: null,
    },
    analyticZonesConfig: {
      type: AnalyticZonesConfig,
      default: null,
    },
  },
  data() {
    const isCorrectData = this.cameraNumber && !_.isEmpty(this.tokenTypes) && this.domain && this.streamCount,
          initialTimeShift = new Date();
    initialTimeShift.setMinutes(initialTimeShift.getMinutes() - 10, 0, 0);

    return {
      isCorrectData: isCorrectData,
      isLoading: false,
      textError: "",
      reason: "",
      archiveToken: null,
      liveToken: null,
      lowLatencyMode: false, // todo имеет смысл запускать всегда hls т.к. MSE не может заработать хотя ошибок никаких не выдает
      forceBlockLiveMode: !this.tokenTypes.includes(TOKEN_TYPES.L),
      timerIdForReceivingRecordingStatus: null,
      availableArchiveFragments: [],
      initialTimeShift: !this.tokenTypes.includes(TOKEN_TYPES.L) ? initialTimeShift : null,
      isLoadingDownloadToken: false,
      downloadUrl: "",
      minUnixDownloadDelta: MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      maxUnixDownloadDelta: MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
    };
  },
  computed: {
    /**
     * @return {Boolean} Вернет true если есть хоть какой-то токен.
     */
    hasAnyToken() {
      return Boolean(this.archiveToken || this.liveToken);
    }
  },
  /**
   * Прерываем частые запросы на поворт камеры.
   */
  created() {
    this.debouncedStartPTZ = _.debounce(this.startPTZ, 0.1);
  },
  /**
   * Автоматический запрос токена для суперадминистраторов, которым не требуется указывать причину запроса.
   */
  mounted() {
    if (this.$can(this.$abilitiesActions.SUPER_ACTION, this.$abilitiesSubjects.SUPER_SUBJECT)) {
      this.requestTokens();
    }
  },
  beforeDestroy() {
    clearInterval(this.timerIdForReceivingRecordingStatus);
  },
  methods: {
    /**
     * Методы управления камерой ptz.
     */
    async sendPTZRequest({action, code, camera_number}) {
      const payload = {
        action,
        camera_number,
      };
      if (code) {
        payload.code = code;
      }
      await this.$store.dispatch(`cameras/${ACTION_PTZ_CAMERA}`, payload);
    },
    async startPTZ(direction) {
      await this.sendPTZRequest({action: 'start', code: direction, camera_number: this.cameraNumber});
    },
    async stopPTZ() {
      clearInterval(this.ptzInterval);
      await this.sendPTZRequest({action: 'stop', code: '', camera_number:this.cameraNumber});
    },
    async centralizePTZ() {
      await this.sendPTZRequest({action: 'centralize', code: '', camera_number: this.cameraNumber});
    },
    /**
     * Отправка запроса.
     * В случае успешного получения токена открывается плеер для воспроизведения видео.
     */
    async requestTokens() {
      this.textError = "";
      if (!this.reason && !this.$can(this.$abilitiesActions.SUPER_ACTION, this.$abilitiesSubjects.SUPER_SUBJECT)) {
        this.textError = "Необходимо указать причину, по которой вам потребовалось смотреть видео с камеры";
        return;
      }

      this.isLoading = true;
      for (const tokenType of this.tokenTypes) {
        // todo обработать ошибку в catch
        // eslint-disable-next-line no-useless-catch
        try {
          const results = await this.$store.dispatch(`cameras/${ACTION_REQUEST_TOKEN}`, {
            camerasNumbers: [this.cameraNumber],
            tokenType: tokenType,
            reason: this.reason,
          });

          if (tokenType === TOKEN_TYPES.L) {
            this.liveToken = results[0].token;
          }
          if (tokenType === TOKEN_TYPES.R) {
            this.archiveToken = results[0].token;
            await this.receiveRecordingStatus();
            this.timerIdForReceivingRecordingStatus = setInterval(this.receiveRecordingStatus, 5000);
          }
        } catch (error) {
          throw error;
        }
      }
      this.isLoading = false;
    },
    /**
     * Получение доступных фрагментов видео от сервера.
     */
    async receiveRecordingStatus() {
      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUS}`, {
        cameraNumber: this.cameraNumber,
        domain: this.domain,
        token: this.archiveToken,
      });
    },
    /**
     * Функция должна вернуть актуальный набор данных для отображения на временной шкале.
     * Для доступного архива фильтруются доступные участки для рисования, уточняется их цвет и низший приоритет.
     *
     * @param {Date} leftBoundary
     * @param {Date} rightBoundary
     * @return {Promise<Array<Array<Date, Date, String, Number>>>}
     */
    async getLineFragments(leftBoundary, rightBoundary) {
      return this.availableArchiveFragments.filter(([dateStart, dateEnd]) => {
        return +leftBoundary < +dateEnd && +rightBoundary > +dateStart;
      }).map(([dateStart, dateEnd]) => {
        return [dateStart, dateEnd, "#bbbbbb", 0];
      });
    },
    /**
     * Отправка запроса на получение токена для скачивания фрагмента видео.
     * При его получении формирование URL и автоматический запуск скачивания.
     *
     * @param {Function} getterDownloadUrl
     * @param {Number} unixDownloadFrom
     * @param {Number} unixDownloadDelta
     */
    async downloadVideo([getterDownloadUrl, unixDownloadFrom, unixDownloadDelta]) {
      // Конкретные ограничения по длительности запрашиваемого видео.
      if ((unixDownloadDelta < MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER) || (unixDownloadDelta > MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER)) {
        this.$camsdals.alert("Невозможно скачать. Выбран некорректный диапазон времени, попробуйте изменить границы скачивания.");
        return;
      }

      let tokenDownload = null;
      this.isLoadingDownloadToken = true;
      try {
        const tokens = await this.$store.dispatch(`cameras/${ACTION_REQUEST_TOKEN}`, {
          camerasNumbers: [this.cameraNumber],
          tokenType: TOKEN_TYPES.D,
          reason: this.reason,
          start: unixDownloadFrom,
          duration: unixDownloadDelta,
        });
        tokenDownload = tokens[0].token;
      } catch (error) {
        devLog("[downloadVideo]", error);
      }

      if (tokenDownload) {
        this.downloadUrl = getterDownloadUrl(tokenDownload);
      } else {
        this.$camsdals.alert("Невозможно скачать. Попробуйте позднее.");
      }
      this.isLoadingDownloadToken = false;
    },
    /**
     * Передаст параметры зон из плеера для сохранения в родительский компонент.
     *
     */
    saveLines(analyticLinesConfig) {
      this.closeDialog(analyticLinesConfig);
    },
    saveZones(analyticZonesConfig) {
      this.closeDialog(analyticZonesConfig);
    }
  }
};
</script>

<style lang="scss">
// Коррекция размера экрана плеера. Без него камеры с большой высотой будут выходить на 100% высоты своего кадра и уходить за пределы окна.
.smart-player-screen {
  .smart-player__display {
    height: 460px;
  }
}
</style>
