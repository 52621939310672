<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Тариф
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.name)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.name]"
                    :caption="titlesFields[fieldsEntity.name]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.name)"
                    :error="errorsFormEdit[fieldsEntity.name]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.dvr_hours)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.dvr_hours]"
                    :caption="titlesFields[fieldsEntity.dvr_hours]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.dvr_hours)"
                    :error="errorsFormEdit[fieldsEntity.dvr_hours]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.dvr_size)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.dvr_size]"
                    :caption="titlesFields[fieldsEntity.dvr_size]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.dvr_size)"
                    :error="errorsFormEdit[fieldsEntity.dvr_size]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.max_sessions)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.max_sessions]"
                    :caption="titlesFields[fieldsEntity.max_sessions]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.max_sessions)"
                    :error="errorsFormEdit[fieldsEntity.max_sessions]"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.TARIFF)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.TARIFF)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.TARIFF)"
                        type="button"
                        @click="deleteTariff()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.TARIFF)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.date_joined)"
                  v-show="sourceData.entityInfo[fieldsEntity.date_joined]"
                  :title="sourceData.entityInfo[fieldsEntity.date_joined] | localDateTimeZone"
                >
                  {{ titlesFields[fieldsEntity.date_joined] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.date_joined] | localDate }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.last_login)"
                  v-show="sourceData.entityInfo[fieldsEntity.last_login]"
                  :title="sourceData.entityInfo[fieldsEntity.last_login] | localDateTimeZone"
                >
                  {{ titlesFields[fieldsEntity.last_login] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.last_login] | localDate }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.TARIFF, fieldsEntity.date_block)"
                  v-show="sourceData.entityInfo[fieldsEntity.date_block]"
                  :title="sourceData.entityInfo[fieldsEntity.date_block] | localDateTimeZone"
                >
                  {{ titlesFields[fieldsEntity.date_block] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.date_block] | localDate }}</strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_TARIFFS} from "@/router/names.js";
import {
  ACTION_DELETE_TARIFF,
  ACTION_EDIT_TARIFF,
  ACTION_LOAD_TARIFF_FOR_EDIT,
  FIELDS_TARIFF,
  TITLES_FIELDS_TARIFF
} from "@/store/tariffs/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования тарифа.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    return {
      routeBack: {name: ROUTE_TARIFFS},
      fieldsEntity: FIELDS_TARIFF,
      titlesFields: TITLES_FIELDS_TARIFF,
      entityId: Number(this.$route.params.tariffId),
      nameActionLoadDataForEdit: `tariffs/${ACTION_LOAD_TARIFF_FOR_EDIT}`,
      nameActionEdit: `tariffs/${ACTION_EDIT_TARIFF}`,
      deletedField: FIELDS_TARIFF.is_deleted,
    };
  },
  methods: {
    /**
     * Отправка запроса на удаление текущего тарифа.
     */
    deleteTariff() {
      this.$camsdals.confirm("Хотите удалить этот тариф?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`tariffs/${ACTION_DELETE_TARIFF}`, {tariffIds: [this.entityId]});
          this.$camsdals.alert("Тариф удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении тарифа");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
