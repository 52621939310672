/**
 * Отдельный vuex модуль для взаимодействия по analytics/servers/logs-API
 */

import {DEFAULT_PAGE_SIZE_FOR_TABLE, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для servers/logs. Вызов действия начинать с "analytics/serverLogs"
export const ACTION_LOAD_ANALYTIC_SERVERS_LOGS_FOR_TABLE = "ACTION_LOAD_ANALYTIC_SERVERS_LOGS_FOR_TABLE";
export const ACTION_LOAD_ANALYTIC_SERVERS_LOGS = "ACTION_LOAD_ANALYTIC_SERVERS_LOGS";
export const ACTION_LOAD_INFO_ANALYTIC_SERVERS_LOGS = "ACTION_LOAD_INFO_ANALYTIC_SERVERS_LOGS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_ANALYTIC_SERVER_LOGS = Object.freeze( {
  id: "id",
  date: "date",
  analytic_server_id: "analytic_server_id",
  analytic_server_front_id: "analytic_server_front_id",
  text: "text",
  camera_number: "camera_number",
  analytic_type: "analytic_type",
  lvl: "lvl",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_ANALYTIC_SERVER_LOGS = Object.freeze({
  id: "id",
  camera_number: "camera_number",
  date: "date",
  analytic_server_id: "analytic_server_id",
  analytic_type: "analytic_type",
});
/**
 * Стандартные названия для полей групп прав.
 */
export const TITLES_FIELDS_ANALYTIC_SERVER_LOGS = {
  [FIELDS_ANALYTIC_SERVER_LOGS.id]: "ID",
  [FIELDS_ANALYTIC_SERVER_LOGS.date]: "Дата",
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_id]: "ID сервера",
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_front_id]: "FRONT ID",
  [FIELDS_ANALYTIC_SERVER_LOGS.camera_number]: "Номер камеры",
  [FIELDS_ANALYTIC_SERVER_LOGS.text]: "Текст",
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_type]: "Тип аналитики",
  [FIELDS_ANALYTIC_SERVER_LOGS.lvl]: "Уровень логирования",

};
/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_BY_FIELDS_ANALYTIC_SERVER_LOGS = {
  [FIELDS_ANALYTIC_SERVER_LOGS.date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_SERVER_LOGS.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_front_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_type]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};
/**
 * Перечисления опций для загрузки дополнительной информации.
 */
export const EXTRAS_ANALYTIC_SERVER_LOGS = Object.freeze({
  analytic_server: "analytic_server",
  camera: "camera"
});
export const EXTRAS_BY_FIELDS_ANALYTIC_SERVER_LOGS = Object.freeze({
  [FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_front_id]: [EXTRAS_ANALYTIC_SERVER_LOGS.analytic_server],
  [FIELDS_ANALYTIC_SERVER_LOGS.camera_number]: [EXTRAS_ANALYTIC_SERVER_LOGS.camera],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации по логам серверов аналитики.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_ANALYTIC_SERVERS_LOGS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/servers/logs/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка логов серверов аналитики.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {{field: (string|*), direction: string}} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS_LOGS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_ANALYTIC_SERVER_LOGS, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/logs/", {

          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка логов серверов аналитики для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS_LOGS_FOR_TABLE]({dispatch}, {
      page = 1,
      pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE,
      orderBy =[] ,
      fields = [],
      filters = [],
      search = ""
    }) {
      return dispatch(ACTION_LOAD_ANALYTIC_SERVERS_LOGS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_ANALYTIC_SERVERS_LOGS_FOR_TABLE});
    },
  }
};
