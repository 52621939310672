/**
 * Отдельный vuex модуль для взаимодействия по employeePhotos-API
 */
import {SORT_DIRECTIONS, DEFAULT_PAGE_SIZE_FOR_TABLE} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";

// actions для photo. Вызов действия начинать с "employeesPhoto/"
export const ACTION_LOAD_PHOTOS = "LOAD_PHOTOS";
export const ACTION_LOAD_INFO_PHOTOS = "LOAD_INFO_PHOTOS";
export const ACTION_LOAD_PHOTOS_FOR_EMPLOYEE = "LOAD_PHOTOS_FOR_EMPLOYEE";
export const ACTION_CREATE_PHOTO = "CREATE_PHOTO";
export const ACTION_DELETE_PHOTO = "DELETE_PHOTO";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_PHOTO = Object.freeze({
  id: "id",
  image: "image",
  status: "status",
  error_message: "error_message",
  width: "width",
  height: "height",
  embedding: "embedding",
  upload_date: "upload_date",
  employee_id: "employee_id",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_PHOTO = Object.freeze({
  id: "id",
  employee_id: "employee_id",
});

/**
 * Стандартные названия для полей фоток.
 */
export const TITLES_FIELDS_PHOTO = {
  id: "id",
  image: "Изображение",
  status: "Статус",
  error_message: "Ошибка",
  width: "Ширина",
  height: "Высота",
  employee_id: "Сотрудник",
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с фото.
 */
export const EXTRAS_PHOTO = Object.freeze({
  employee: "employee",
});

const EXTRAS_BY_FIELDS_EXTRAS_PHOTO = Object.freeze({
  [FIELDS_PHOTO.employee_id]: [EXTRAS_PHOTO.employee],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для фото.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PHOTOS]() {
      const response = await this.getters.privateAjax.post("/v0/employees/photo/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка фото.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PHOTOS](context, {page, pageSize, orderBy, fields, filters, search}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_EXTRAS_PHOTO, fields))),
        response = await this.getters.privateAjax.post("/v0/employees/photo/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search,
        });
      return response.data;
    },
    /**
     * Загрузка фото по сотруднику.
     *
     * @param {Function} dispatch
     * @param {Number} employeeId
     * @return {Promise}
     */
    async [ACTION_LOAD_PHOTOS_FOR_EMPLOYEE]({dispatch}, {employeeId,page}) {
      return dispatch(ACTION_LOAD_PHOTOS, {
        page: page,
        pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE,
        orderBy: [{field: FIELDS_PHOTO.id, direction: SORT_DIRECTIONS.DESC}],
        fields: [
          FIELDS_PHOTO.id,
          FIELDS_PHOTO.image,
          FIELDS_PHOTO.status,
          FIELDS_PHOTO.error_message,
          FIELDS_PHOTO.embedding,
          FIELDS_PHOTO.upload_date,
          FIELDS_PHOTO.width,
          FIELDS_PHOTO.height,
          FIELDS_PHOTO.employee_id,
        ],
        search: "",
        filters: [makeFilterApi(FILTERS_PHOTO.employee_id, "=", employeeId)],
      });
    },
    /**
     * Создание фото.
     *
     * @param {Object} context
     * @param {Object} photoInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_PHOTO](context, photoInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/employees/photo/create/", photoInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление фото.
     *
     * @param {Object} context
     * @param {Array.<Number>} photosIds
     * @return {Promise}
     */
    async [ACTION_DELETE_PHOTO](context, photosIds) {
      return this.getters.privateAjax.post("/v0/employees/photo/delete/", {
        ids: photosIds,
      });
    },
  },
};
