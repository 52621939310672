<template>
  <div>
    <ProcessingMultiEditDialog
      v-if="goProcessing"
      :promise-save-data="promiseSaveData"
      :selected-entities="selectedEntities"
    />
    <div v-else>
      <p v-if="countSelectedOriginally !== selectedEntities.length">
        Внимание: количество строк в фильтрации и количество для множественного редактирования - не совпадают!
      </p>

      <div class="row">
        <div class="col-2">
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT) || $can($abilitiesActions.READ_FIELD, $abilitiesSubjects.MULTI_EDIT_BUTTON)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.EDIT_CAMERA ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.EDIT_CAMERA"
            >
              Редактирование параметров
            </CamsButton>
          </div>
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.CREATE_MIGRATION ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.CREATE_MIGRATION"
            >
              Перенос камер
            </CamsButton>
          </div>
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.CANCEL_MIGRATION ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.CANCEL_MIGRATION"
            >
              Отмена миграции камер
            </CamsButton>
          </div>
          <div v-if="$can($abilitiesActions.DVR_REASON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.READ_FIELD, $abilitiesSubjects.MULTI_DOWNLOAD_BUTTON)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.DOWNLOAD_VIDEO ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.DOWNLOAD_VIDEO"
            >
              Множественное скачивание
            </CamsButton>
          </div>
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.EDIT_FIRMWARE ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              :disabled="selectedEntities.length > 200"
              @click="selectedComponentMultiEdit = componentsMultiEdit.EDIT_FIRMWARE"
            >
              Массовая прошивка камер
            </CamsButton>
          </div>
          <strong v-if="selectedEntities.length > 200" style="color: #E12F2F">
            Количество камер для прошивки не может быть больше 200
          </strong>
        </div>

        <div class="col-2">
          <div v-show="!selectedComponentMultiEdit" class="row">
            <p>Выберите функцию для массового действия.</p>
          </div>

          <div class="row">
            <component
              :is="selectedComponentMultiEdit"
              v-if="selectedComponentMultiEdit"
              class="col"
              @change-promise="promiseSaveData = $event"
              @ready-for-processing="isReadyForProcessing = $event"
            />
          </div>

          <div v-show="selectedComponentMultiEdit" class="dialog-actions">
            <CamsButton
              type="button"
              @click="closeDialog()"
            >
              Отменить
            </CamsButton>
            <CamsButton
              :disabled="!isReadyForProcessing"
              priority="primary"
              type="button"
              @click="goProcessing = true"
            >
              Применить
            </CamsButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CancelMigration from "@/components/cameras/functionsMultiEdit/CancelMigration.vue";
import CreateMigration from "@/components/cameras/functionsMultiEdit/CreateMigration.vue";
import DownloadVideo from "@/components/cameras/functionsMultiEdit/DownloadVideo.vue";
import EditCamera from "@/components/cameras/functionsMultiEdit/EditCamera.vue";
import ProcessingMultiEditDialog from "@/components/dialogs/ProcessingMultiEditDialog.vue";
import {methodsForDialogMixin, multiEditEntityMixin} from "@/utils/mixins.js";
import EditFirmware from "@/components/cameras/functionsMultiEdit/EditFirmware.vue";

const COMPONENTS_MULTI_EDIT_CAMERAS = Object.freeze({
  EDIT_CAMERA: EditCamera,
  CREATE_MIGRATION: CreateMigration,
  CANCEL_MIGRATION: CancelMigration,
  DOWNLOAD_VIDEO: DownloadVideo,
  EDIT_FIRMWARE: EditFirmware,
});

/**
 * Компонент для организации множественного редактирования камер.
 */
export default {
  components: {
    ProcessingMultiEditDialog,
    CreateMigration,
    EditCamera,
    CancelMigration,
  },
  mixins: [
    methodsForDialogMixin,
    multiEditEntityMixin
  ],
  data() {
    return {
      componentsMultiEdit: COMPONENTS_MULTI_EDIT_CAMERAS,
    };
  },
};
</script>
