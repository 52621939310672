<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.front_id]"
        :caption="titlesFields[fieldsEntity.front_id]"
        :error="errorsFormEdit[fieldsEntity.front_id]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.ips]"
        :caption="titlesFields[fieldsEntity.ips]"
        :error="errorsFormEdit[fieldsEntity.ips]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSelect
        v-model="formEdit[fieldsEntity.log_lvl]"
        :options="logLevelSettings"
        caption="Уровень логирования"
        width="fill"
      />
    </div>

    <div>
      <SmartSwitch
        v-model="formEdit[fieldsEntity.car_number_event_is_active]"
        :caption="titlesFields[fieldsEntity.car_number_event_is_active]"
        :error="errorsFormEdit[fieldsEntity.car_number_event_is_active]"
        class="col"
      />

      <SmartSwitch
        v-model="formEdit[fieldsEntity.feature_computation_is_active]"
        :caption="titlesFields[fieldsEntity.feature_computation_is_active]"
        :error="errorsFormEdit[fieldsEntity.feature_computation_is_active]"
        class="col"
      />

      <SmartSwitch
        v-model="formEdit[fieldsEntity.crowd_is_active]"
        :caption="titlesFields[fieldsEntity.crowd_is_active]"
        :error="errorsFormEdit[fieldsEntity.crowd_is_active]"
        class="col"
      />

      <SmartSwitch
        v-model="formEdit[fieldsEntity.thermal_vision_is_active]"
        :caption="titlesFields[fieldsEntity.thermal_vision_is_active]"
        :error="errorsFormEdit[fieldsEntity.thermal_vision_is_active]"
        class="col"
      />
      <SmartSwitch
        v-model="formEdit[fieldsEntity.perimeter_security_is_active]"
        :caption="titlesFields[fieldsEntity.perimeter_security_is_active]"
        :error="errorsFormEdit[fieldsEntity.perimeter_security_is_active]"
        class="col"
      />
      <SmartSwitch
        v-model="formEdit[fieldsEntity.car_number_is_active]"
        :caption="titlesFields[fieldsEntity.car_number_is_active]"
        :error="errorsFormEdit[fieldsEntity.car_number_is_active]"
        class="col"
      />
      <SmartSwitch
        v-model="formEdit[fieldsEntity.helmet_is_active]"
        :caption="titlesFields[fieldsEntity.helmet_is_active]"
        :error="errorsFormEdit[fieldsEntity.helmet_is_active]"
        class="col"
      />
      <SmartSwitch
        v-model="formEdit[fieldsEntity.mask_is_active]"
        :caption="titlesFields[fieldsEntity.mask_is_active]"
        :error="errorsFormEdit[fieldsEntity.mask_is_active]"
        class="col"
      />
      <SmartSwitch
        v-model="formEdit[fieldsEntity.face_recognition_is_active]"
        :caption="titlesFields[fieldsEntity.face_recognition_is_active]"
        :error="errorsFormEdit[fieldsEntity.face_recognition_is_active]"
        class="col"
      />
    </div>

    <SmartInputText
      v-show="formEdit[fieldsEntity.thermal_vision_is_active]"
      v-model="formEdit[fieldsEntity.thermal_vision_workers]"
      :caption="titlesFields[fieldsEntity.thermal_vision_workers]"
      :error="errorsFormEdit[fieldsEntity.thermal_vision_workers]"
      class="col"
    />

    <SmartInputText
      v-show="formEdit[fieldsEntity.face_recognition_is_active]"
      v-model="formEdit[fieldsEntity.face_recognition_workers]"
      :caption="titlesFields[fieldsEntity.face_recognition_workers]"
      :error="errorsFormEdit[fieldsEntity.face_recognition_workers]"
      class="col"
    />


    <SmartInputText
      v-show="formEdit[fieldsEntity.feature_computation_is_active]"
      v-model="formEdit[fieldsEntity.feature_computation_workers]"
      :caption="titlesFields[fieldsEntity.feature_computation_workers]"
      :error="errorsFormEdit[fieldsEntity.feature_computation_workers]"
      class="col"
    />

    <SmartInputText
      v-show="formEdit[fieldsEntity.car_number_event_is_active]"
      v-model="formEdit[fieldsEntity.car_number_event_workers]"
      :caption="titlesFields[fieldsEntity.car_number_event_workers]"
      :error="errorsFormEdit[fieldsEntity.car_number_event_workers]"
      class="col"
    />


    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_ANALYTIC_SERVER} from "@/router/names.js";
import {
  ACTION_CREATE_ANALYTIC_SERVER,
  FIELDS_ANALYTIC_SERVER,
  LOGGING_LEVEL,
  TITLES_FIELDS_ANALYTIC_SERVER
} from "@/store/analytics/servers/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания сервера аналитики.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      logLevelSettings: LOGGING_LEVEL,
      nameActionCreate: `analytics/servers/${ACTION_CREATE_ANALYTIC_SERVER}`,
      fieldsEntity: FIELDS_ANALYTIC_SERVER,
      titlesFields: TITLES_FIELDS_ANALYTIC_SERVER,
      formEdit: {
        [FIELDS_ANALYTIC_SERVER.front_id]: "",
        [FIELDS_ANALYTIC_SERVER.ips]: [],
        [FIELDS_ANALYTIC_SERVER.log_lvl]: LOGGING_LEVEL[3],
        [FIELDS_ANALYTIC_SERVER.car_number_is_active]: false,
        [FIELDS_ANALYTIC_SERVER.helmet_is_active]: false,
        [FIELDS_ANALYTIC_SERVER.thermal_vision_is_active]: false,
        [FIELDS_ANALYTIC_SERVER.perimeter_security_is_active]: false,
        [FIELDS_ANALYTIC_SERVER.thermal_vision_workers]: 1,
        [FIELDS_ANALYTIC_SERVER.face_recognition_is_active]: false,
        [FIELDS_ANALYTIC_SERVER.face_recognition_workers]: 1,
        [FIELDS_ANALYTIC_SERVER.feature_computation_is_active]: false,
        [FIELDS_ANALYTIC_SERVER.feature_computation_workers]: 1,
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование сервера аналитики.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: newEntity[FIELDS_ANALYTIC_SERVER.id]}};
    },
  },
};
</script>
