<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.number]"
        :caption="titlesFields[fieldsEntity.number]"
        :error="errorsFormEdit[fieldsEntity.number]"
        :make-focus="true"
        class="col"
      />
    </div>
    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.employee_id]"
        :caption="titlesFields[fieldsEntity.employee_id]"
        :error="errorsFormEdit[fieldsEntity.employee_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.employee_id]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_CAR} from "@/router/names.js";
import {ACTION_CREATE_CAR, FIELDS_CAR, TITLES_FIELDS_CAR} from "@/store/pacs/cars/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент диалога создания автомобиля.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `cars/${ACTION_CREATE_CAR}`,
      fieldsEntity: FIELDS_CAR,
      titlesFields: TITLES_FIELDS_CAR,
      formEdit: {
        [FIELDS_CAR.number]: "",
        [FIELDS_CAR.employee_id]: null,
        [FIELDS_CAR.gang_id]: null,
        [FIELDS_CAR.is_active]: true,
      },
      settingsSelects: {
        [FIELDS_CAR.employee_id]: {
          action: `employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
          valueField: FIELDS_EMPLOYEE.id,
          labelField: FIELDS_EMPLOYEE.title,
        },
        [FIELDS_CAR.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      }
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование автомоблия.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_CAR, params: {carId: newEntity[FIELDS_CAR.id]}};
    },
  },
};
</script>
