<template>
  <div>
    <form>
      <SpinnerLoadingModal v-if="isLoading" />
      <div class="col">
        <SmartVSelect
          v-model="firmwareId"
          caption="Выберите файл"
          :error="errorsFormEdit[firmwareId]"
          :settings-remote-search="firmwareSettings"
        />
        <br>
      </div>
      <br>
      <div class="dialog-actions">
        <CamsButton type="button" @click="closeDialog()">
          Отменить
        </CamsButton>
        <CamsButton priority="primary" type="button" @click="cameraUpgradeManual()">
          Сохранить
        </CamsButton>
      </div>
    </form>
  </div>
</template>

<script>
import {
  ACTION_LOAD_FIRMWARES_FOR_SELECT,
  FIELDS_FIRMWARE,
  TITLES_FIELDS_FIRMWARE,
} from "@/store/firmwares/index.js";
import {ACTION_EDIT_CAMERA_SETUP_UPGRADE_MANUAL} from "@/store/camerasSetup/index.js";
import {formWithFieldsMixin, methodsForDialogMixin} from "@/utils/mixins.js";
/**
 * Компонент диалога создания прошивки камеры.
 */
export default {
  mixins: [formWithFieldsMixin,methodsForDialogMixin],
  props: {
    /**
     * Номер камеры.
     */
    cameraNumber: {
      type: String,
      required: true,
    },
    /**
     * Номер камеры.
     */
    cameraModel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      firmwareId: null,
      titlesFields: TITLES_FIELDS_FIRMWARE,
      fieldsEntity: FIELDS_FIRMWARE,
      firmwareSettings: {
        action: `firmwares/${ACTION_LOAD_FIRMWARES_FOR_SELECT}`,
        valueField: FIELDS_FIRMWARE.id,
        labelField: [FIELDS_FIRMWARE.model,FIELDS_FIRMWARE.type, FIELDS_FIRMWARE.version],
        searchParams: {cameraModel: this.cameraModel}
      }
    };
  },
  methods: {

    /**
     * Произойдет перезагрузка страницы, пользователь вернется к таблице с прошивками.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    async cameraUpgradeManual () {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`camerasSetup/${ACTION_EDIT_CAMERA_SETUP_UPGRADE_MANUAL}`,
                                   {cameraNumber: this.cameraNumber, firmwareId: this.firmwareId}
        );
        this.closeDialog(true);
        location.reload();
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${ Object.values(error.response.data.fields).join(", ")}`);
      }
      this.isLoading = false;
    },
  },
};
</script>
