/**
 * Отдельный vuex модуль для взаимодействия по analytics/thermal_vision-API
 */

import {ZERO_ZONE_FOR_CREATE} from "@/store/analytics/helpers.js";
import {DEFAULT_PAGE_SIZE_FOR_TABLE, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";

// actions для thermalVisions. Вызов действия начинать с "analytics/thermalVisions/"
export const ACTION_LOAD_INFO_THERMAL_VISIONS = "LOAD_INFO_THERMAL_VISIONS";
export const ACTION_LOAD_THERMAL_VISIONS = "LOAD_THERMAL_VISIONS";
export const ACTION_LOAD_THERMAL_VISIONS_FOR_TABLE = "LOAD_THERMAL_VISIONS_FOR_TABLE";
export const ACTION_LOAD_THERMAL_VISION_FOR_EDIT = "LOAD_THERMAL_VISION_FOR_EDIT";
export const ACTION_CREATE_THERMAL_VISION = "CREATE_THERMAL_VISION";
export const ACTION_EDIT_THERMAL_VISION = "EDIT_THERMAL_VISION";
export const ACTION_DELETE_THERMAL_VISIONS = "DELETE_THERMAL_VISIONS";
export const ACTION_LOAD_INFO_THERMAL_VISION_HISTORY = "LOAD_INFO_THERMAL_VISION_HISTORY";
export const ACTION_LOAD_THERMAL_VISION_HISTORY = "LOAD_THERMAL_VISION_HISTORY";
export const ACTION_LOAD_THERMAL_VISION_HISTORY_FOR_TABLE = "LOAD_THERMAL_VISION_HISTORY_FOR_TABLE";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_THERMAL_VISION = Object.freeze({
  camera_number: "camera_number",
  push_domain: "push_domain",
  is_active: "is_active",
  is_deleted: "is_deleted",
  face_recognition: "face_recognition",
  image_height: "image_height",
  image_width: "image_width",
  zones: "zones",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_THERMAL_VISION = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей настроек аналитики тепловизора.
 */
export const TITLES_FIELDS_THERMAL_VISION = {
  [FIELDS_THERMAL_VISION.camera_number]: "Номер камеры",
  [FIELDS_THERMAL_VISION.push_domain]: "Домен PUSH",
  [FIELDS_THERMAL_VISION.is_active]: "Активна",
  [FIELDS_THERMAL_VISION.is_deleted]: "Удалена",
  [FIELDS_THERMAL_VISION.face_recognition]: "Распознавать лица",
  [FIELDS_THERMAL_VISION.image_height]: "Ширина зоны",
  [FIELDS_THERMAL_VISION.image_width]: "Высота зоны",
  [FIELDS_THERMAL_VISION.zones]: "Зоны аналитики",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_THERMAL_VISION = {
  [FIELDS_THERMAL_VISION.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_THERMAL_VISION.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_THERMAL_VISION.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_THERMAL_VISION.face_recognition]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с настройками аналитик тепловизора.
 */
export const EXTRAS_THERMAL_VISION = Object.freeze({});

const EXTRAS_BY_FIELDS_THERMAL_VISION = Object.freeze({});

// Analytics camera car number history
export const FIELDS_THERMAL_VISION_HISTORY = Object.freeze({
  min_object_width:"min_object_width",
  hpk:"hpk",
  camera_number:"camera_number",
  is_deleted:"is_deleted",
  history_create_date:"history_create_date",
  push_domain:"push_domain",
  is_active:"is_active",
  analytic_server_id:"analytic_server_id",
  image_width:"image_width",
  image_height:"image_height",
  zones:"zones",
  is_event:"is_event",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent"
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_THERMAL_VISION_HISTORY = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  zones: "zones",
  data_change_event_date: "data_change_event_date",
  analytic_server_id:"analytic_server_id",
  data_change_event_user_id: "data_change_event_user_id",
  history_create_date: "history_create_date",
});
export const TITLES_FIELDS_THERMAL_VISION_HISTORY = {
  [FIELDS_THERMAL_VISION_HISTORY.analytic_server_id]: "ID сервера аналитики",
  [FIELDS_THERMAL_VISION_HISTORY.camera_number]: "Номер камеры",
  [FIELDS_THERMAL_VISION_HISTORY.hpk]:"hpk",
  [FIELDS_THERMAL_VISION_HISTORY.image_height]: "Высота изображения",
  [FIELDS_THERMAL_VISION_HISTORY.image_width]: "Ширина изображения",
  [FIELDS_THERMAL_VISION_HISTORY.is_active]: "Активна",
  [FIELDS_THERMAL_VISION_HISTORY.is_deleted]: "Удалена",
  [FIELDS_THERMAL_VISION_HISTORY.is_event]: "Событие",
  [FIELDS_THERMAL_VISION_HISTORY.min_object_width]: "Минимальная уверенность",
  [FIELDS_THERMAL_VISION_HISTORY.push_domain]: "Пуш домен",
  [FIELDS_THERMAL_VISION_HISTORY.zones]:"Зоны",
  [FIELDS_THERMAL_VISION_HISTORY.history_create_date]: "Дата события",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_date]: "Дата события 2",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_user_agent]: "user agent",
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_user_id]: "Пользователь",
};
export const EXTRAS_THERMAL_VISION_HISTORY = Object.freeze({
  user: "user",
  analytic_server: "analytic_server",
});

const EXTRAS_BY_FIELDS_THERMAL_VISION_HISTORY = Object.freeze({
  [FIELDS_THERMAL_VISION_HISTORY.data_change_event_user_id]: [EXTRAS_THERMAL_VISION_HISTORY.user],
  [FIELDS_THERMAL_VISION_HISTORY.analytic_server_id]: [EXTRAS_THERMAL_VISION_HISTORY.analytic_server],

});
export const TYPES_FIELDS_THERMAL_VISION_HISTORY = {
  [FIELDS_THERMAL_VISION_HISTORY.history_create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для настроек аналитик тепловизоров.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_THERMAL_VISIONS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/thermal_vision/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка настроек аналитик тепловизоров.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_THERMAL_VISIONS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_THERMAL_VISION, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/thermal_vision/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка настроек аналитик тепловизоров для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_THERMAL_VISIONS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_THERMAL_VISIONS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_THERMAL_VISIONS_FOR_TABLE});
    },
    /**
     * Загрузка одной настройки аналитики тепловизора для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_THERMAL_VISION_FOR_EDIT]({dispatch}, [cameraNumber, fields]) {
      const filter = makeFilterApi(FILTERS_THERMAL_VISION.camera_number, "=", cameraNumber),
        responseData = await dispatch(ACTION_LOAD_THERMAL_VISIONS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание новой настройки аналитики тепловизора.
     *
     * @param {Object} context
     * @param {Object} thermalVisionInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_THERMAL_VISION](context, thermalVisionInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/thermal_vision/create/", {...thermalVisionInfo, ...ZERO_ZONE_FOR_CREATE});
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей настройки аналитики тепловизора.
     *
     * @param {Object} context
     * @param {Object} thermalVisionInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_THERMAL_VISION](context, thermalVisionInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/thermal_vision/edit/", thermalVisionInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление настройки аналитики тепловизора.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_THERMAL_VISIONS](context, {camerasNumbers}) {
      return this.getters.privateAjax.post("/v0/analytics/thermal_vision/delete/", {camera_numbers: camerasNumbers});
    },
    // Методы для загрузки информации по истории редактирования аналитики,
    // начинается с v0/analytics/mask/history
    /**
     * Загрузка служебной информации для камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_THERMAL_VISION_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/thermal_vision/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_THERMAL_VISION_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_THERMAL_VISION_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/thermal_vision/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_THERMAL_VISION_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_THERMAL_VISION_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_THERMAL_VISION_HISTORY_FOR_TABLE});
    },
  },
};
