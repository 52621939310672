/**
 * Модуль vuex для объединения группы функций взаимодействующих с API аналитических настроек.
 */

import servers from "@/store/analytics/servers/index.js";
import carNumbers, {ACTION_LOAD_CAR_NUMBER_EVENT} from "@/store/analytics/carNumbers/index.js";
import thermalVisions from "@/store/analytics/thermalVisions/index.js";
import faceRecognitions from "@/store/analytics/faceRecognitions/index.js";
import helmets from "@/store/analytics/helmets/index.js";
import motionAlarms from "@/store/analytics/motionAlarms/index.js";
import serverLogs from "@/store/analytics/serverLogs/index.js";
import masks from "@/store/analytics/masks/index.js";
import crowds from "@/store/analytics/crowds/index.js";
import perimeterSecuritys from "@/store/analytics/perimeterSecuritys/index.js";
import peopleCounts from "@/store/analytics/peopleCounts/index.js";
import analyticClusters from "@/store/analytics/analyticClusters/index.js";
import reports from "@/store/analytics/reports/index.js";
import makeMessage, {AnalyticMessagesChunk, CarNumberMessage} from "@/store/analytics/analyticMessage.js";

export const ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA = "LOAD_ARCHIVE_EVENTS_BY_CAMERA";
export const ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS = "LOAD_ARCHIVE_EVENTS_BY_CAMERAS";
export const ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS = "LOAD_ARCHIVE_EVENTS_CHUNKS";
export const ACTION_LOAD_FULL_SCREENSHOT_EVENT = "LOAD_FULL_SCREENSHOT_EVENT";

export default {
  namespaced: true,
  modules: {
    servers,
    carNumbers,
    thermalVisions,
    faceRecognitions,
    helmets,
    motionAlarms,
    serverLogs,
    masks,
    crowds,
    perimeterSecuritys,
    peopleCounts,
    analyticClusters,
    reports,
  },

  actions: {
    async [ACTION_LOAD_FULL_SCREENSHOT_EVENT]({dispatch}, {analyticMessage, screenshotToken}) {
      let fullScreenshot = "",
        actionLoadEventWithScreenshot;

      fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
      actionLoadEventWithScreenshot = `carNumbers/${ACTION_LOAD_CAR_NUMBER_EVENT}`;
      if (fullScreenshot || !actionLoadEventWithScreenshot) {
        return fullScreenshot;
      }

      const analyticMessageWithScreenshot = await dispatch(actionLoadEventWithScreenshot, {
        cameraNumber: analyticMessage.cameraNumber,
        eventId: analyticMessage.id
      });
      if (analyticMessageWithScreenshot) {
        return analyticMessageWithScreenshot.getFullScreenshotUrl(screenshotToken);
      }
      return "";
    },

    /**
     * Загрузка списка событий произошедших в определенный период по одной камере.
     * Определение типа событий происходит по ключу `type` в каждом экземпляре события.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Number} limit
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessage>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA](context, {
      cameraNumber,
      archiveFrom,
      archiveTo,
      limit = 1000,
      orderByDate = "desc"
    }) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/archive_events/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          limit,
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка списка событий произошедших в определенный период по набору камер.
     * Определение типа событий происходит по ключу `type` в каждом экземпляре события.
     *
     * @param {Object} context
     * @param {Array.<String>} cameraNumbers
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Number} limit
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessage>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS](context, {
      cameraNumbers,
      archiveFrom,
      archiveTo,
      limit = 1000,
      orderByDate = "desc"
    }) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/archive_events_many/", {
          camera_numbers: cameraNumbers,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          limit,
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка сгруппированных событий произошедших в определенный период.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessagesChunk>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS](context, {cameraNumber, archiveFrom, archiveTo, orderByDate = "desc"}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/archive_event_chunks/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawAnalyticMessagesChunk => new AnalyticMessagesChunk(rawAnalyticMessagesChunk));
      } catch {
        return [];
      }
    },
  },
};
