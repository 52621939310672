/**
 * Отдельный vuex модуль для взаимодействия по permissions-API
 */

import {SORT_DIRECTIONS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";

// actions для camera groups. Вызов действия начинать с "permissions/"
export const ACTION_LOAD_INFO_PERMISSIONS_CAMERA_GROUPS = "LOAD_INFO_PERMISSIONS_CAMERA_GROUPS";
export const ACTION_LOAD_PERMISSIONS_CAMERA_GROUPS = "LOAD_PERMISSIONS_CAMERA_GROUPS";
export const ACTION_LOAD_PERMISSIONS_CAMERA_GROUP_FOR_EDIT = "LOAD_PERMISSIONS_CAMERA_GROUPS_FOR_EDIT";
export const ACTION_ADD_PERMISSIONS_CAMERA_GROUPS_BY_FORM = "ADD_PERMISSIONS_CAMERA_GROUPS";
export const ACTION_DELETE_PERMISSIONS_CAMERA_GROUPS = "DELETE_PERMISSIONS_CAMERA_GROUPS";
export const ACTION_SET_PERMISSIONS_CAMERA_GROUPS = "SET_PERMISSIONS_CAMERA_GROUPS";
export const ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP = "GET_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP";

export const ACTION_LOAD_INFO_PERMISSIONS_CAMERAS = "LOAD_INFO_PERMISSIONS_CAMERAS";
export const ACTION_LOAD_PERMISSIONS_CAMERAS = "LOAD_PERMISSIONS_CAMERAS";
export const ACTION_LOAD_PERMISSIONS_CAMERA_FOR_EDIT = "LOAD_PERMISSIONS_CAMERAS_FOR_EDIT";
export const ACTION_ADD_PERMISSIONS_CAMERAS_BY_FORM = "ADD_PERMISSIONS_CAMERAS";
export const ACTION_DELETE_PERMISSIONS_CAMERAS = "DELETE_PERMISSIONS_CAMERAS";
export const ACTION_SET_PERMISSIONS_CAMERAS = "SET_PERMISSIONS_CAMERAS";
export const ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA = "GET_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_PERMISSION_CAMERA_GROUP = Object.freeze({
  camera_group_id: "camera_group_id",
  camera_group_name: "camera_group_name",
  user_id: "user_id",
  username: "username",
  permission: "permission",
  is_auto: "is_auto",
});

export const FIELDS_PERMISSION_CAMERA = Object.freeze({
  camera_number: "camera_number",
  user_id: "user_id",
  username: "username",
  permission: "permission",
  is_auto: "is_auto",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_PERMISSION_CAMERA_GROUP = Object.freeze({
  camera_group_id: "camera_group_id",
  user_id: "user_id",
});

export const FILTERS_PERMISSION_CAMERA = Object.freeze({
  camera_number: "camera_number",
  user_id: "user_id",
});

/**
 * Перечисления опций для загрузки дополнительной информации вместе с правами.
 */
export const EXTRAS_PERMISSION_CAMERA_GROUP = Object.freeze({
  user: "user",
  camera_group: "camera_group",
});

export const EXTRAS_PERMISSION_CAMERA = Object.freeze({
  user: "user",
  camera: "camera",
});

const EXTRAS_BY_FIELDS_PERMISSION_CAMERA_GROUP = Object.freeze({
  [FIELDS_PERMISSION_CAMERA_GROUP.user_id]: [EXTRAS_PERMISSION_CAMERA_GROUP.user],
  [FIELDS_PERMISSION_CAMERA_GROUP.camera_group_id]: [EXTRAS_PERMISSION_CAMERA_GROUP.camera_group],
});

const EXTRAS_BY_FIELDS_PERMISSION_CAMERA = Object.freeze({
  [FIELDS_PERMISSION_CAMERA.user_id]: [EXTRAS_PERMISSION_CAMERA.user],
  [FIELDS_PERMISSION_CAMERA.camera_number]: [EXTRAS_PERMISSION_CAMERA.camera],
});

/**
 * Перечисление прав на камеры и группы камер для назначения их пользователям.
 */
export const PERMISSIONS_CAMERAS = Object.freeze({
  OWNER: 10,
  ARCHIVE: 20,
  STREAM: 30,
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // -------------------------------------------------------------------------------------------------------------------------------------
    // camera_groups
    /**
     * Загрузка служебной информации для прав.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PERMISSIONS_CAMERA_GROUPS]() {
      const response = await this.getters.privateAjax.post("/v0/permissions/camera_groups/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка прав
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_CAMERA_GROUPS](context, {page, pageSize, orderBy, fields, filters, search}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_PERMISSION_CAMERA_GROUP, fields))),
        response = await this.getters.privateAjax.post("/v0/permissions/camera_groups/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} clientId
     * @param {Number} cameraGroupId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_CAMERA_GROUP_FOR_EDIT]({dispatch}, {clientId = null, cameraGroupId = null, search = "", page = 1}) {
      let filter = makeFilterApi(FILTERS_PERMISSION_CAMERA_GROUP.user_id, "=", clientId),
        orderBy = {field: FIELDS_PERMISSION_CAMERA_GROUP.camera_group_name, direction: SORT_DIRECTIONS.ASC};
      if (cameraGroupId) {
        filter = makeFilterApi(FILTERS_PERMISSION_CAMERA_GROUP.camera_group_id, "=", cameraGroupId);
        orderBy = {field: FIELDS_PERMISSION_CAMERA_GROUP.username, direction: SORT_DIRECTIONS.ASC};
      }

      return dispatch(ACTION_LOAD_PERMISSIONS_CAMERA_GROUPS, {
        page,
        pageSize: 10,
        orderBy: [orderBy],
        fields: [
          FIELDS_PERMISSION_CAMERA_GROUP.camera_group_id,
          FIELDS_PERMISSION_CAMERA_GROUP.camera_group_name,
          FIELDS_PERMISSION_CAMERA_GROUP.user_id,
          FIELDS_PERMISSION_CAMERA_GROUP.username,
          FIELDS_PERMISSION_CAMERA_GROUP.permission,
          FIELDS_PERMISSION_CAMERA_GROUP.is_auto,
        ],
        filters: [filter],
        search
      });
    },
    /**
     * Выдача новых прав.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[cameraGroupId, permission], ...]
     * @param {Boolean} mergePermissions
     */
    async [ACTION_ADD_PERMISSIONS_CAMERA_GROUPS_BY_FORM](context, {clientId = null, isAuto = false, rawPermissionsInfo, mergePermissions = true}) {
      try {
        await this.getters.privateAjax.post("/v0/permissions/camera_groups/add/", {
          user_id: clientId,
          is_auto: isAuto,
          permissions: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {camera_group_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          }),
          merge_permissions: mergePermissions
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление прав.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {Boolean} isAuto
     * @param {Array} cameraGroupIds
     */
    async [ACTION_DELETE_PERMISSIONS_CAMERA_GROUPS](context, {clientId, isAuto, cameraGroupIds}) {
      try {
        await this.getters.privateAjax.post("/v0/permissions/camera_groups/delete/", {
          user_id: clientId,
          is_auto: isAuto,
          camera_group_ids: cameraGroupIds
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Установка прав.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[cameraGroupId, permission], ...]
     */
    async [ACTION_SET_PERMISSIONS_CAMERA_GROUPS](context, {clientId, isAuto, rawPermissionsInfo}) {
      try {
        await this.getters.privateAjax.post("/v0/permissions/camera_groups/set/", {
          user_id: clientId,
          is_auto: isAuto,
          permissions: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {camera_group_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          })
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Получение количества установленных прав для группы камер или пользователя.
     *
     * @param {Function} dispatch
     * @param {Number} clientId
     * @param {Number} cameraGroupId
     * @return {Promise}
     */
    async [ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP]({dispatch}, {clientId = null, cameraGroupId = null}) {
      let filter;
      if (clientId) {
        filter = makeFilterApi(FILTERS_PERMISSION_CAMERA_GROUP.user_id, "=", clientId);
      } else if (cameraGroupId) {
        filter = makeFilterApi(FILTERS_PERMISSION_CAMERA_GROUP.camera_group_id, "=", cameraGroupId);
      } else {
        return;
      }

      const responseData = await dispatch(ACTION_LOAD_PERMISSIONS_CAMERA_GROUPS, {page: 1, pageSize: 1, filters: [filter]});
      return responseData.count;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------
    // cameras
    /**
     * Загрузка служебной информации для прав.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PERMISSIONS_CAMERAS]() {
      const response = await this.getters.privateAjax.post("/v0/permissions/cameras/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка прав
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_CAMERAS](context, {page, pageSize, orderBy, fields, filters, search}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_PERMISSION_CAMERA, fields))),
        response = await this.getters.privateAjax.post("/v0/permissions/cameras/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} clientId
     * @param {String} cameraNumber
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_CAMERA_FOR_EDIT]({dispatch}, {clientId = null, cameraNumber = null, search = "", page = 1}) {
      let filter = makeFilterApi(FILTERS_PERMISSION_CAMERA.user_id, "=", clientId);
      if (cameraNumber) {
        filter = makeFilterApi(FILTERS_PERMISSION_CAMERA.camera_number, "=", cameraNumber);
      }

      return dispatch(ACTION_LOAD_PERMISSIONS_CAMERAS, {
        page,
        pageSize: 10,
        orderBy: [],
        fields: [
          FIELDS_PERMISSION_CAMERA.camera_number,
          FIELDS_PERMISSION_CAMERA.user_id,
          FIELDS_PERMISSION_CAMERA.username,
          FIELDS_PERMISSION_CAMERA.permission,
          FIELDS_PERMISSION_CAMERA.is_auto,
        ],
        filters: [filter],
        search
      });
    },
    /**
     * Выдача новых прав.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[cameraNumber, permission], ...]
     * @param {Boolean} mergePermissions
     */
    async [ACTION_ADD_PERMISSIONS_CAMERAS_BY_FORM](context, {clientId = null, isAuto = false, rawPermissionsInfo, mergePermissions = true}) {
      try {
        await this.getters.privateAjax.post("/v0/permissions/cameras/add/", {
          user_id: clientId,
          is_auto: isAuto,
          permissions: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {camera_number: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          }),
          merge_permissions: mergePermissions
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление прав.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {Boolean} isAuto
     * @param {Array} cameraNumbers
     */
    async [ACTION_DELETE_PERMISSIONS_CAMERAS](context, {clientId, isAuto, cameraNumbers}) {
      try {
        await this.getters.privateAjax.post("/v0/permissions/cameras/delete/", {
          user_id: clientId,
          is_auto: isAuto,
          camera_numbers: cameraNumbers
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Установка прав.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[cameraNumber, permission], ...]
     */
    async [ACTION_SET_PERMISSIONS_CAMERAS](context, {clientId, isAuto, rawPermissionsInfo}) {
      try {
        await this.getters.privateAjax.post("/v0/permissions/cameras/set/", {
          user_id: clientId,
          is_auto: isAuto,
          permissions: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {camera_number: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          })
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Получение количества установленных прав для камеры или пользователя.
     *
     * @param {Function} dispatch
     * @param {Number} clientId
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA]({dispatch}, {clientId = null, cameraNumber = null}) {
      let filter;
      if (clientId) {
        filter = makeFilterApi(FILTERS_PERMISSION_CAMERA.user_id, "=", clientId);
      } else if (cameraNumber) {
        filter = makeFilterApi(FILTERS_PERMISSION_CAMERA.camera_number, "=", cameraNumber);
      } else {
        return;
      }

      const responseData = await dispatch(ACTION_LOAD_PERMISSIONS_CAMERAS, {page: 1, pageSize: 1, filters: [filter]});
      return responseData.count;
    },
  },
};
