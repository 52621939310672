<template>
  <div class="page-inner">
    <SpinnerLoadingModal v-if="isLoading" />
    <MainFrame v-else-if="isAuth && isLoadedContext" />
    <SignIn v-else />
    <vuedal />
  </div>
</template>

<script>
import MainFrame from "@/components/MainFrame.vue";
import SignIn from "@/components/SignIn.vue";
import {ROUTE_403} from "@/router/names.js";
import {ACTION_AUTO_UPDATE, ACTION_LOAD_CONTEXT} from "@/store/actions.js";
import {CONFIG_IS_AUTH_VIA_TOKEN} from "@/utils/consts.js";
import {Component as Vuedal} from "vuedals";
import {mapGetters, mapState} from "vuex";

/**
 * Главный компонент приложения. В нем описывается базовое поведение приложения вне зависимости от загружаемых компонентов.
 *
 * Компонент SpinnerLoadingModal - отображается в процессе загрузки приложения для авторизованного пользователя.
 *
 * Данные загружаются в MainFrame только если пользователь авторизован,
 * его токен проверен (или сессия проверена) и загружена сопутствующая по приложению информация.
 *
 * Если пользователь не авторизован, (он выходит из системы или авторизация автоматически сбрасывается по какой-то причине)
 * то отображается компонент SignIn с формой авторизации.
 * В свою очередь после авторизации вновь происходит запрос информации по пользователю
 * и в этот момент отображается компонент SpinnerLoadingModal.
 */
export default {
  components: {
    MainFrame,
    SignIn,
    Vuedal,
  },
  data() {
    return {
      isLoading: false,
      isLoadedContext: false,
      isAuthViaToken: CONFIG_IS_AUTH_VIA_TOKEN,
    };
  },
  computed: {
    ...mapState(["needUpdate"]),
    ...mapGetters(["isAuth"]),
  },
  watch: {
    /**
     * При выходе из системы сбрасывается токен, при входе в систему токен проверяется.
     * В обоих случаях перезапрашивается текущее состояние сервера для клиентского приложения.
     */
    isAuth() {
      this.loadContext();
    },
    /**
     * Показ сообщения если появились обновления.
     */
    needUpdate(needUpdate) {
      if (needUpdate) {
        this.$camsdals.confirm(
          {
            messageText: "Хотите перезагрузить страницу чтобы обновиться?",
            approveText: "Перезагрузить сейчас",
            rejectionText: "Отложить",
          },
          () => location.reload(),
          {dialogTitle: "Вышла новая версия приложения"}
        );
      }
    },
  },
  /**
   * При создании самого главного компонента происходит загрузка контекстной информации,
   * в случае если ранее была сохранена информация по авторизации.
   * Таким образом попутно верификацируется токен авторизации.
   *
   * При создании компонента все ошибки убираются - т.к. этот компонент создается при загрузке страницы.
   *
   * Настройка автоматического обновления клиентского приложения, на случай обнаружения изменений.
   */
  created() {
    // При создании приложения в консоль в дебаг режиме отправится отладочная информация используемая для сборки.
    devLog(process.env);

    this.loadContext();

    if (process.env.NODE_ENV === "production") {
      this.$store.dispatch(ACTION_AUTO_UPDATE);
    }
  },
  methods: {
    /**
     * Загрузка контекстной информации.
     *
     * Если после получения контекстной информации есть понимание, что пользователь не имеет доступа к запрошенной странице,
     * то ему отображается ошибка и при этом он может попытаться вернуться к этой странице т.к. переход сохранился в истории.
     * Для авторизованных пользователей проверка доступа происходит в роутере.
     */
    async loadContext() {
      this.isLoading = true;
      this.isLoadedContext = false;
      try {
        await this.$store.dispatch(ACTION_LOAD_CONTEXT);
        if (this.isAuth && this.$route.meta.abilitySubject && !this.$can(this.$abilitiesActions.READ_COMMON, this.$route.meta.abilitySubject)) {
          this.$router.push({name: ROUTE_403});
        }
        this.isLoadedContext = true;
      } catch {
        this.$camsdals.confirm(
          {
            messageText: "Ой, при загрузке страницы что-то пошло не так. Давайте попробуем перезагрузить страницу.",
            approveText: "Перезагрузить",
            rejectionText: "Отложить",
          },
          () => location.reload(),
          {dialogTitle: "Произошла ошибка"}
        );
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
