<template>
  <div class="content__settings__body">
    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />

    <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
      <div class="row">
        <div class="col-2">
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.username)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.username]"
              :caption="titlesFields[fieldsEntity.username]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.username)"
              :error="errorsFormEdit[fieldsEntity.username]"
              :make-focus="true"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.about)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.about]"
              :caption="titlesFields[fieldsEntity.about]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.about)"
              :error="errorsFormEdit[fieldsEntity.about]"
              class="col"
              input-type="area"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.labels)" class="row">
            <SmartInputArray
              v-model="formEdit[fieldsEntity.labels]"
              :caption="titlesFields[fieldsEntity.labels]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.labels)"
              :error="errorsFormEdit[fieldsEntity.labels]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.is_active)" class="row">
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_active]"
              :caption="titlesFields[fieldsEntity.is_active]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.is_active)"
              :error="errorsFormEdit[fieldsEntity.is_active]"
              class="col"
            />
          </div>
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.is_userlinkable)" class="row">
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_userlinkable]"
              :caption="titlesFields[fieldsEntity.is_userlinkable]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.is_userlinkable)"
              :error="errorsFormEdit[fieldsEntity.is_userlinkable]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.date_block)" class="row">
            <SmartInputDate
              v-model="formEdit[fieldsEntity.date_block]"
              :caption="titlesFields[fieldsEntity.date_block]"
              :config-flat-pickr="configFlatPickr"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.date_block)"
              :error="errorsFormEdit[fieldsEntity.date_block]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.gang_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.gang_id]"
              :caption="titlesFields[fieldsEntity.gang_id]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.gang_id)"
              :error="errorsFormEdit[fieldsEntity.gang_id]"
              :initial-options="initialOptionsGang"
              :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.gang_admin_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.gang_admin_id]"
              :caption="titlesFields[fieldsEntity.gang_admin_id]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.gang_admin_id)"
              :error="errorsFormEdit[fieldsEntity.gang_admin_id]"
              :initial-options="initialOptionsGangAdmin"
              :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
              class="col"
            />
          </div>

          <div class="row">
            <div class="buttons-group">
              <CamsButton
                v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_CLIENT)"
                priority="primary"
                type="button"
                @click="saveData()"
              >
                Сохранить и продолжить редактирование
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_CLIENT)"
                priority="primary"
                type="button"
                @click="saveDataAndRedirect()"
              >
                Сохранить
              </CamsButton>
            </div>
          </div>

          <div class="row">
            <div class="buttons-group">
              <router-link
                v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_CLIENT)"
                :to="routeBack"
                class="button button_btn button_medium button_btn-default"
              >
                Отменить
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="buttons-group">
              <CamsButton
                v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.USER_CLIENT)"
                type="button"
                @click="openDialogChangePasswordClient()"
              >
                Сменить пароль
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.RESET_SALT, $abilitiesSubjects.USER_CLIENT)"
                type="button"
                @click="resetSaltClient()"
              >
                Сбросить соль
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.USER_CLIENT)"
                type="button"
                @click="openDialogSetLinksForClients()"
              >
                Изменить связи
              </CamsButton>
            </div>
          </div>

          <div class="row">
            <div class="buttons-group">
              <CamsButton
                v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_PERMISSION)"
                type="button"
                @click="openDialogSetPermissionsForCameras()"
              >
                Изменить права на камеры
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP_PERMISSION)"
                type="button"
                @click="openDialogSetPermissionsForCameraGroups()"
              >
                Изменить права на группы
              </CamsButton>
            </div>
          </div>
        </div>

        <div class="col-2">
          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.date_joined)"
            v-show="sourceData.entityInfo[fieldsEntity.date_joined]"
            :title="sourceData.entityInfo[fieldsEntity.date_joined] | localDateTimeZone"
          >
            {{ titlesFields[fieldsEntity.date_joined] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.date_joined] | localDate }}</strong>
          </p>

          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.last_login)"
            v-show="sourceData.entityInfo[fieldsEntity.last_login]"
            :title="sourceData.entityInfo[fieldsEntity.last_login] | localDateTimeZone"
          >
            {{ titlesFields[fieldsEntity.last_login] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.last_login] | localDate }}</strong>
          </p>

          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_CLIENT, fieldsEntity.is_local_password)">
            {{ titlesFields[fieldsEntity.is_local_password] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.is_local_password] | bool }}</strong>
          </p>

          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_PERMISSION)">
            Количество записей прав для камер:
            <strong>{{ countPermissionsByCameras }}</strong>
          </p>

          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP_PERMISSION)">
            Количество записей прав для групп камер:
            <strong>{{ countPermissionsByCameraGroups }}</strong>
          </p>

          <br>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_AUTH)">
            <strong>
              <router-link :to="routeLogAuthsByClient">Логи авторизаций</router-link>
            </strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_VIEW)">
            <strong>
              <router-link :to="routeLogViewsByClient">Логи просмотров</router-link>
            </strong>
          </p>
        </div>
      </div>
    </form>

    <div v-else class="row">
      <p>Данные не удалось загрузить.</p>
    </div>
  </div>
</template>

<script>
import ChangePasswordClientDialog from "@/components/users/clients/ChangePasswordClientDialog.vue";
import SetPermissionsForCameraGroupsDialog from "@/components/permissions/SetPermissionsForCameraGroupsDialog.vue";
import SetPermissionsForCamerasDialog from "@/components/permissions/SetPermissionsForCamerasDialog.vue";
import {ROUTE_CLIENTS, ROUTE_LOG_AUTHS, ROUTE_LOG_VIEWS} from "@/router/names.js";
import {
  ACTION_EDIT_CLIENT,
  ACTION_LOAD_CLIENT_FOR_EDIT,
  ACTION_RESET_SALT_CLIENTS,
  EXTRAS_CLIENT,
  FIELDS_CLIENT,
  TITLES_FIELDS_CLIENT
} from "@/store/users/clients/index.js";
import {FILTERS_LOG_AUTHS, FILTERS_LOG_VIEWS} from "@/store/log/index.js";
import {
  ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA,
  ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP
} from "@/store/permissions/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE, SORT_DIRECTIONS} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";
import SetLinksForClientsDialog from "@/components/users/clients/links/SetLinksForClientsDialog.vue";

/**
 * Компонент страницы редактирования клиента.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    const clientId = Number(this.$route.params.clientId),
          paramsRouteLogAuthsByClient = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_AUTHS.user_id, "=", [clientId]),
              new FilterData(FILTERS_LOG_AUTHS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_AUTHS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogAuthsByClient = {name: ROUTE_LOG_AUTHS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogAuthsByClient.stringify()}},
          paramsRouteLogViewsByClient = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_VIEWS.user_id, "=", [clientId]),
              new FilterData(FILTERS_LOG_VIEWS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_VIEWS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogViewsByClient = {name: ROUTE_LOG_VIEWS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogViewsByClient.stringify()}};

    return {
      routeBack: {name: ROUTE_CLIENTS},
      fieldsEntity: FIELDS_CLIENT,
      titlesFields: TITLES_FIELDS_CLIENT,
      entityId: clientId,

      initialOptionsGang: [],
      initialOptionsGangAdmin: [],
      nameActionLoadDataForEdit: `clients/${ACTION_LOAD_CLIENT_FOR_EDIT}`,
      nameActionEdit: `clients/${ACTION_EDIT_CLIENT}`,
      settingsSelects: {
        [FIELDS_CLIENT.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      },
      configFlatPickr: {
        minDate: "today",
        minTime: Date.now(),
      },
      countPermissionsByCameras: 0,
      countPermissionsByCameraGroups: 0,
      routeLogViewsByClient: routeLogViewsByClient,
      routeLogAuthsByClient: routeLogAuthsByClient,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготавливаются начальные опции для выпадающих списков.
      this.initialOptionsGang = this.sourceData.extraInfo[EXTRAS_CLIENT.gang];
      this.initialOptionsGangAdmin = this.sourceData.extraInfo[EXTRAS_CLIENT.gang_admin];
      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.CAMERA_PERMISSION)) {
        this.countPermissionsByCameras = await this.$store.dispatch(`permissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA}`, {clientId: this.entityId});
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.CAMERA_GROUP_PERMISSION)) {
        this.countPermissionsByCameraGroups = await this.$store.dispatch(`permissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP}`, {clientId: this.entityId});
      }
    },
    /**
     * Открытие формы для изменения пароля клиента.
     */
    openDialogChangePasswordClient() {
      this.$camsdals.open(
        ChangePasswordClientDialog,
        {clientId: this.entityId},
        {dialogTitle: "Изменить пароль клиента"}
      );
    },
    /**
     * Открытие формы для изменения прав клиента на группы камер.
     */
    openDialogSetPermissionsForCameraGroups() {
      this.$camsdals.open(
        SetPermissionsForCameraGroupsDialog,
        {clientId: this.entityId},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие формы для изменения прав клиента на камеры.
     */
    openDialogSetPermissionsForCameras() {
      this.$camsdals.open(
        SetPermissionsForCamerasDialog,
        {clientId: this.entityId},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие формы для изменения связей между клиентами.
     */
    openDialogSetLinksForClients() {
      this.$camsdals.open(
        SetLinksForClientsDialog,
        {clientId: this.entityId},
        {dialogTitle: "Настройки связи между клиентами"},
        {name: "js-click-outside", size: "lg"}
      );
    },
    /**
     * Сброс соли у клиента.
     */
    resetSaltClient() {
      this.$camsdals.confirm("Хотите сбросить соль у клиента?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`clients/${ACTION_RESET_SALT_CLIENTS}`, {clientsIds: [this.entityId]});
          this.$camsdals.alert("Соль сброшена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка сбросе соли");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
