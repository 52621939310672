<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS}" class="tabs__item">
          Серверы аналитики
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CLUSTERS}" class="tabs__item">
          Кластеры серверов аналитик
        </router-link>
        <router-link :to="{name: routes.currentRoute}" class="tabs__item tabs__item_active">
          Логи серверов
        </router-link>
        <router-link :to="{name: routes.ROUTE_ROOT_ANALYTICS_SETTINGS}" class="tabs__item">
          Настройка аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CAR_NUMBER_REPORT}" class="tabs__item">
          Отчеты
        </router-link>
      </div>
    </div>
    <!--    <div class="main__header">-->
    <!--      <div class="tabs">-->
    <!--        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS}" class="tabs__item">-->
    <!--          Серверы аналитики-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">-->
    <!--          Логи серверов-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_THERMAL_VISIONS}" class="tabs__item">-->
    <!--          Тепловизоры-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_CAR_NUMBERS}" class="tabs__item">-->
    <!--          Автомобили-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_FACE_RECOGNITIONS}" class="tabs__item">-->
    <!--          Распознавание лиц-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_HELMETS}" class="tabs__item">-->
    <!--          Каски-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_MOTION_ALARMS}" class="tabs__item">-->
    <!--          Движения-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_MASKS}" class="tabs__item">-->
    <!--          Маски-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_CROWDS}" class="tabs__item">-->
    <!--          Скопление людей-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_PERIMETER_SECURITYS}" class="tabs__item">-->
    <!--          Вторжение в зону-->
    <!--        </router-link>-->
    <!--        <router-link :to="{name: routes.ROUTE_PEOPLE_COUNTS}" class="tabs__item">-->
    <!--          Подсчет посетителей-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_CAR_NUMBERS,
  ROUTE_EDIT_ANALYTIC_SERVER,
  ROUTE_EDIT_CAMERA,
  ROUTE_EDIT_CAR_NUMBER,
  ROUTE_EDIT_FACE_RECOGNITION,
  ROUTE_EDIT_HELMET,
  ROUTE_EDIT_MOTION_ALARM,
  ROUTE_EDIT_THERMAL_VISION,
  ROUTE_FACE_RECOGNITIONS,
  ROUTE_HELMETS,
  ROUTE_MOTION_ALARMS,
  ROUTE_THERMAL_VISIONS,
  ROUTE_MASKS,
  ROUTE_CROWDS,
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_PEOPLE_COUNTS,
  ROUTE_ROOT_ANALYTICS_SETTINGS,
  ROUTE_ANALYTIC_CLUSTERS,
  ROUTE_ANALYTIC_CAR_NUMBER_REPORT
} from "@/router/names.js";
import {FilterData} from "@/utils/helpers.js";
import {
  ACTION_LOAD_ANALYTIC_SERVERS_LOGS_FOR_TABLE,
  ACTION_LOAD_INFO_ANALYTIC_SERVERS_LOGS,
  FIELDS_ANALYTIC_SERVER_LOGS,
  FILTERS_ANALYTIC_SERVER_LOGS,
  TITLES_FIELDS_ANALYTIC_SERVER_LOGS,
  TYPES_BY_FIELDS_ANALYTIC_SERVER_LOGS
} from "@/store/analytics/serverLogs/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {ANALYTICS} from "@/store/analytics/helpers.js";

const analyticRoutes = {
  [ANALYTICS.thermal_vision]: ROUTE_EDIT_THERMAL_VISION,
  [ANALYTICS.car_number]: ROUTE_EDIT_CAR_NUMBER,
  [ANALYTICS.face_recognition]: ROUTE_EDIT_FACE_RECOGNITION,
  [ANALYTICS.helmet]: ROUTE_EDIT_HELMET,
  [ANALYTICS.motion_alarm]: ROUTE_EDIT_MOTION_ALARM,
};

/**
 * Компонент отображения таблицы и фильтров с логами серверов аналитики.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      routes: {
        ROUTE_THERMAL_VISIONS,
        ROUTE_CAR_NUMBERS,
        ROUTE_FACE_RECOGNITIONS,
        ROUTE_HELMETS,
        ROUTE_MOTION_ALARMS,
        ROUTE_ANALYTIC_SERVERS,
        ROUTE_MASKS,
        ROUTE_CROWDS,
        ROUTE_PERIMETER_SECURITYS,
        ROUTE_PEOPLE_COUNTS,
        ROUTE_ROOT_ANALYTICS_SETTINGS,
        ROUTE_ANALYTIC_CLUSTERS,
        ROUTE_ANALYTIC_CAR_NUMBER_REPORT

      },
      cameraNumber: "",
      currentRoute: ROUTE_ANALYTIC_SERVERS_LOGS,
      rawFields: FIELDS_ANALYTIC_SERVER_LOGS,
      columnCaptions: TITLES_FIELDS_ANALYTIC_SERVER_LOGS,
      nameActionLoadDataForTable: `analytics/serverLogs/${ACTION_LOAD_ANALYTIC_SERVERS_LOGS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `analytics/serverLogs/${ACTION_LOAD_INFO_ANALYTIC_SERVERS_LOGS}`,
      defaultFilters: [
        new FilterData(FILTERS_ANALYTIC_SERVER_LOGS.date, ">=", [defaultFilterByDate])
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_ANALYTIC_SERVER_LOGS.camera_number:
        if (rawTranslation) {
          readyTranslation = rawTranslation;
          readyParams = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: rawTranslation}};
          if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
            readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
          }
        }
        break;
      case FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_front_id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: rowData[FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_ANALYTIC_SERVER_LOGS.analytic_type:
        if (analyticRoutes[FIELDS_ANALYTIC_SERVER_LOGS.analytic_type]) {
          readyTranslation = rawTranslation;
          readyParams = {
            name: analyticRoutes[FIELDS_ANALYTIC_SERVER_LOGS.analytic_type],
            params: {cameraNumber: rowData[FIELDS_ANALYTIC_SERVER_LOGS.camera_number]}
          };
          if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
            readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
          }
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_BY_FIELDS_ANALYTIC_SERVER_LOGS[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
