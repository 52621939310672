/**
 * Миксин для слежения выбранных прав в табах, если выбранно то или иное разрешение,
 * таб будет подсвечен. Содержит в себе набор всех прав для сравнения с выбранными, и метод слежения.
 * Используется при создании и редактировании группы прав.
 */
import {FIELDS_RIGHT_GROUP} from "@/store/rightGroups/index.js";

export const rightGroupTabCheckMixin = {
  data() {
    return {
      permissionsInCamerasTab: [
        "camera:create",
        "camera:read",
        "camera:update",
        "camera_permission:read",
        "log_camera_watch_reason:read",
        "camera.location:update",
        "camera:reset_salt",
        "camera:activate",
        "camera.live_reason",
        "camera.dvr_reason",
        "camera:blocking",
        "camera.embed:read",
        "camera.embed:update",
        "camera.is_managed:read",
        "camera.is_managed:update",
        "camera.server_id:read",
        "camera.server_id:update",
        "camera.inventory:read",
        "camera.inventory:update",
        "camera.streams:read",
        "camera.streams:update",
        "camera.group:read",
        "camera.group:update",
      ],
      permissionsInCameraGroupsTab: [
        "camera_group:create",
        "camera_group:read",
        "camera_group:update",
        "camera_group_type:create",
        "camera_group_type:read",
        "camera_group_type:update",
        "camera_group_permission:read"
      ],
      permissionsInUsersTab: [
        "user_client:create",
        "user_client:read",
        "user_client:update",
        "user_admin:create",
        "user_admin:read",
        "user_admin:update",
        "right_group:create",
        "right_group:read",
        "right_group:update",
        "user_client:reset_salt",
        "user_admin:reset_salt",
        "log_auth:read",
        "log_view:read",
      ],
      permissionsInTariffTab: [
        "tariff:create",
        "tariff:read",
        "tariff:update",
      ],
      permissionsInServersTab: [
        "server:create",
        "server:read",
        "server:update",
        "cluster:create",
        "cluster:read",
        "cluster:update",
      ],
      permissionsInAnalyticsTab: [
        "analytic_server:create",
        "analytic_server:read",
        "analytic_server:update",
      ],
      permissionsInGangTab: [
        "gang:create",
        "gang:read",
        "gang:update",
      ],
      permissionsInPacsTab: [
        "employee:create",
        "employee:read",
        "employee:update",
        "device_access_group:create",
        "device_access_group:read",
        "device_access_group:update",
        "device:create",
        "device:read",
        "device:update",
      ],
      permissionsForButtons: [
        "camera:multi_download",
        "camera:download_csv",
        "camera:multi_edit",
      ],
    };
  },
  methods: {
    /**
     * Вернет true если в указанном табе (массив прав в рамках таба) есть выбранные права.
     *
     * @param {Array.<String>} permissionsInTab
     * @return {Boolean}
     */
    checkPermissionsInTab(permissionsInTab) {
      const permissionData = this.formEdit[FIELDS_RIGHT_GROUP.permissions],
        tabResult = _.intersection(permissionData, permissionsInTab);
      return !!tabResult.length;
    },
  }
};
