<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_CLIENTS}" class="tabs__item">
          Клиенты
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Персонал
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateAdmin"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_ADMINS_FOR_TABLE,
  ACTION_LOAD_INFO_ADMINS,
  FIELDS_ADMIN,
  TITLES_FIELDS_ADMIN,
  TYPES_FIELDS_ADMIN
} from "@/store/users/admins/index.js";
import {ROUTE_ADMINS, ROUTE_CLIENTS, ROUTE_EDIT_ADMIN} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateAdminDialog from "@/components/users/admins/CreateAdminDialog.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по админам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_CLIENTS,
      },
      currentRoute: ROUTE_ADMINS,
      rawFields: FIELDS_ADMIN,
      columnCaptions: TITLES_FIELDS_ADMIN,
      nameActionLoadDataForTable: `admins/${ACTION_LOAD_ADMINS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `admins/${ACTION_LOAD_INFO_ADMINS}`,
      defaultFieldsTableForView: [
        FIELDS_ADMIN.id,
        FIELDS_ADMIN.username,
        FIELDS_ADMIN.about,
        FIELDS_ADMIN.is_active,
        FIELDS_ADMIN.last_login,
        FIELDS_ADMIN.date_joined,
        FIELDS_ADMIN.date_block,
        FIELDS_ADMIN.is_local_password,
        FIELDS_ADMIN.is_superuser,
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_ADMIN.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_ADMIN, params: {adminId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_ADMIN.username:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_ADMIN, params: {adminId: rowData[FIELDS_ADMIN.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_ADMIN[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания админа.
     */
    openDialogCreateAdmin() {
      this.$camsdals.open(CreateAdminDialog, {}, {dialogTitle: "Создание администратора"});
    },
  },
};
</script>
