<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.title]"
        :caption="titlesFields[fieldsEntity.title]"
        :error="errorsFormEdit[fieldsEntity.title]"
        :make-focus="true"
        class="col"
      />
    </div>
    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.gang_id]"
        :caption="titlesFields[fieldsEntity.gang_id]"
        :error="errorsFormEdit[fieldsEntity.gang_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
        class="col"
        @input="updateUserGangId"
      />
    </div>
    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.attached_usernames]"
        :caption="titlesFields[fieldsEntity.attached_usernames]"
        :error="errorsFormEdit[fieldsEntity.attached_usernames]"
        :settings-remote-search="settingsSelects[fieldsEntity.attached_usernames]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_active]"
        :caption="titlesFields[fieldsEntity.is_active]"
        :error="errorsFormEdit[fieldsEntity.is_active]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_EMPLOYEE} from "@/router/names.js";
import {ACTION_CREATE_EMPLOYEE, FIELDS_EMPLOYEE, TITLES_FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_CLIENTS_FOR_SELECT, FIELDS_CLIENT} from "@/store/users/clients/index.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент диалога создания сотрудника.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `employees/${ACTION_CREATE_EMPLOYEE}`,
      fieldsEntity: FIELDS_EMPLOYEE,
      titlesFields: TITLES_FIELDS_EMPLOYEE,
      formEdit: {
        [FIELDS_EMPLOYEE.title]: "",
        [FIELDS_EMPLOYEE.gang_id]: null,
        [FIELDS_EMPLOYEE.attached_usernames]: null,
        [FIELDS_EMPLOYEE.is_active]: true,
      },
      settingsSelects: {
        [FIELDS_EMPLOYEE.attached_usernames]: {
          action: `clients/${ACTION_LOAD_CLIENTS_FOR_SELECT}`,
          valueField: FIELDS_CLIENT.id,
          labelField: FIELDS_CLIENT.username,
        },
        [FIELDS_EMPLOYEE.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      }
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование сотрудника.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_EMPLOYEE, params: {employeeId: newEntity[FIELDS_EMPLOYEE.id]}};
    },
    /**
     * Корректировка фильтра для выпадающего списка при изменении компании.
     *
     * @param {Number} gangId
     */
    updateUserGangId (gangId) {
      this.settingsSelects[FIELDS_EMPLOYEE.attached_usernames].searchParams = {gangId};
      this.formEdit[FIELDS_EMPLOYEE.attached_usernames] = null;
    },
  },
};
</script>
