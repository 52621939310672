/**
 * Отдельный vuex модуль для взаимодействия по analytics/people_count-API
 */

import {ZERO_ZONE_FOR_CREATE_PEOPLE_COUNT} from "@/store/analytics/helpers.js";
import {DEFAULT_PAGE_SIZE_FOR_TABLE, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";

// actions для people_count. Вызов действия начинать с "analytics/peopleCounts/"
export const ACTION_LOAD_INFO_PEOPLE_COUNTS = "LOAD_INFO_PEOPLE_COUNTS";
export const ACTION_LOAD_PEOPLE_COUNTS = "LOAD_PEOPLE_COUNTS";
export const ACTION_LOAD_PEOPLE_COUNTS_FOR_TABLE = "LOAD_PEOPLE_COUNTS_FOR_TABLE";
export const ACTION_LOAD_PEOPLE_COUNTS_FOR_MULTI_EDIT = "LOAD_PEOPLE_COUNTS_FOR_MULTI_EDIT";
export const ACTION_LOAD_PEOPLE_COUNT_FOR_EDIT = "LOAD_PEOPLE_COUNT_FOR_EDIT";
export const ACTION_CREATE_PEOPLE_COUNT = "CREATE_PEOPLE_COUNT";
export const ACTION_EDIT_PEOPLE_COUNT = "EDIT_PEOPLE_COUNT";
export const ACTION_DELETE_PEOPLE_COUNTS = "DELETE_PEOPLE_COUNTS";
export const ACTION_LOAD_INFO_PEOPLE_COUNT_HISTORY = "LOAD_INFO_PEOPLE_COUNT_HISTORY";
export const ACTION_LOAD_PEOPLE_COUNT_HISTORY = "LOAD_PEOPLE_COUNT_HISTORY";
export const ACTION_LOAD_PEOPLE_COUNT_HISTORY_FOR_TABLE = "LOAD_PEOPLE_COUNT_HISTORY_FOR_TABLE";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_PEOPLE_COUNT = Object.freeze({
  camera_number: "camera_number",
  push_domain: "push_domain",
  is_active: "is_active",
  is_deleted: "is_deleted",
  analytic_server_id: "analytic_server_id",
  image_height: "image_height",
  image_width: "image_width",
  counter_line: "counter_line",
  enter_point: "enter_point",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_PEOPLE_COUNT = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  analytic_server_id: "analytic_server_id",
});

/**
 * Стандартные названия для полей настроек аналитики подсчета посетителей.
 */
export const TITLES_FIELDS_PEOPLE_COUNT = {
  [FIELDS_PEOPLE_COUNT.camera_number]: "Номер камеры",
  [FIELDS_PEOPLE_COUNT.push_domain]: "Домен PUSH",
  [FIELDS_PEOPLE_COUNT.is_active]: "Активна",
  [FIELDS_PEOPLE_COUNT.is_deleted]: "Удалена",
  [FIELDS_PEOPLE_COUNT.analytic_server_id]: "Сервер аналитики",
  [FIELDS_PEOPLE_COUNT.image_height]: "Ширина зоны",
  [FIELDS_PEOPLE_COUNT.image_width]: "Высота зоны",
  [FIELDS_PEOPLE_COUNT.counter_line]: "Контрольная линия",
  [FIELDS_PEOPLE_COUNT.enter_point]: "Точка входа",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_PEOPLE_COUNT = {
  [FIELDS_PEOPLE_COUNT.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_PEOPLE_COUNT.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_PEOPLE_COUNT.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_PEOPLE_COUNT.analytic_server_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с настройками аналитики подсчета посетителей.
 */
export const EXTRAS_PEOPLE_COUNT = Object.freeze({
  analytic_server: "analytic_server"
});

const EXTRAS_BY_FIELDS_PEOPLE_COUNT = Object.freeze({
  [FIELDS_PEOPLE_COUNT.analytic_server_id]: [EXTRAS_PEOPLE_COUNT.analytic_server]
});
// Analytics camera people count history
export const FIELDS_PEOPLE_COUNT_HISTORY = Object.freeze({
  counter_line: "counter_line",
  enter_point: "enter_point",
  min_object_width:"min_object_width",
  hpk:"hpk",
  camera_number:"camera_number",
  is_deleted:"is_deleted",
  history_create_date:"history_create_date",
  push_domain:"push_domain",
  is_active:"is_active",
  analytic_server_id:"analytic_server_id",
  image_width:"image_width",
  image_height:"image_height",
  zones:"zones",
  is_event:"is_event",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent"
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_PEOPLE_COUNT_HISTORY = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  zones: "zones",
  data_change_event_date: "data_change_event_date",
  analytic_server_id:"analytic_server_id",
  data_change_event_user_id: "data_change_event_user_id",
  history_create_date: "history_create_date",
});
export const TITLES_FIELDS_PEOPLE_COUNT_HISTORY = {
  [FIELDS_PEOPLE_COUNT_HISTORY.min_object_width]: "Минимальная уверенность",
  [FIELDS_PEOPLE_COUNT_HISTORY.hpk]:"hpk",
  [FIELDS_PEOPLE_COUNT_HISTORY.camera_number]: "Номер камеры",
  [FIELDS_PEOPLE_COUNT_HISTORY.is_deleted]: "Удалена",
  [FIELDS_PEOPLE_COUNT_HISTORY.history_create_date]: "Дата события",
  [FIELDS_PEOPLE_COUNT_HISTORY.push_domain]: "Пуш домен",
  [FIELDS_PEOPLE_COUNT_HISTORY.is_active]: "Активна",
  [FIELDS_PEOPLE_COUNT_HISTORY.analytic_server_id]: "ID сервера аналитики",
  [FIELDS_PEOPLE_COUNT_HISTORY.image_width]: "Ширина изображения",
  [FIELDS_PEOPLE_COUNT_HISTORY.image_height]: "Высота изображения",
  [FIELDS_PEOPLE_COUNT_HISTORY.zones]:"Зоны",
  [FIELDS_PEOPLE_COUNT_HISTORY.is_event]: "Событие",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_date]: "Дата события 2",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_user_agent]: "user agent",
};
export const EXTRAS_PEOPLE_COUNT_HISTORY = Object.freeze({
  user: "user",
  analytic_server: "analytic_server",
});

const EXTRAS_BY_FIELDS_PEOPLE_COUNT_HISTORY = Object.freeze({
  [FIELDS_PEOPLE_COUNT_HISTORY.data_change_event_user_id]: [EXTRAS_PEOPLE_COUNT_HISTORY.user],
  [FIELDS_PEOPLE_COUNT_HISTORY.analytic_server_id]: [EXTRAS_PEOPLE_COUNT_HISTORY.analytic_server],

});
export const TYPES_FIELDS_PEOPLE_COUNT_HISTORY = {
  [FIELDS_PEOPLE_COUNT_HISTORY.history_create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для настроек аналитики подсчета посетителей.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PEOPLE_COUNTS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/people_counter/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка настроек аналитики подсчета посетителей.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNTS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_PEOPLE_COUNT, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/people_counter/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка настроек аналитики подсчета посетителей для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNTS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_PEOPLE_COUNTS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_PEOPLE_COUNTS_FOR_TABLE});
    },
    /**
     * Загрузка списка настроек аналитики подсчета посетителей для обработки их в рамках множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNTS_FOR_MULTI_EDIT]({dispatch}, {filters = [], search = ""}) {
      const responseData = await dispatch(ACTION_LOAD_PEOPLE_COUNTS, {page: 1, pageSize: 1000, fields: [FIELDS_PEOPLE_COUNT.camera_number], filters, search});
      return _.map(responseData.results, FIELDS_PEOPLE_COUNT.camera_number);
    },
    /**
     * Загрузка одной настройки аналитики подсчета посетителей для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_FOR_EDIT]({dispatch}, [cameraNumber, fields]) {
      const filter = makeFilterApi(FILTERS_PEOPLE_COUNT.camera_number, "=", cameraNumber),
        responseData = await dispatch(ACTION_LOAD_PEOPLE_COUNTS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание новой настройки аналитики подсчета посетителей.
     *
     * @param {Object} context
     * @param {Object} maskInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_PEOPLE_COUNT](context, maskInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/people_counter/create/", {...maskInfo, ...ZERO_ZONE_FOR_CREATE_PEOPLE_COUNT});
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей настройки аналитики подсчета посетителей.
     *
     * @param {Object} context
     * @param {Object} maskInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_PEOPLE_COUNT](context, maskInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/people_counter/edit/", maskInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление настройки аналитики определения подсчета посетителей.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_PEOPLE_COUNTS](context, {camerasNumbers}) {
      return this.getters.privateAjax.post("/v0/analytics/people_counter/delete/", {camera_numbers: camerasNumbers});
    },
    // Методы для загрузки информации по истории редактирования аналитики,
    // начинается с v0/analytics/people_counter/history
    /**
     * Загрузка служебной информации для камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PEOPLE_COUNT_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/people_counter/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_PEOPLE_COUNT_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/people_counter/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_PEOPLE_COUNT_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_PEOPLE_COUNT_HISTORY_FOR_TABLE});
    },
  },
};
