import {
  ACTION_LOAD_CAMERA_FOR_EDIT,
  EXTRAS_CAMERA,
  FIELDS_CAMERA,
  TOKEN_TYPES
} from "@/store/cameras/index.js";
import {FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {AnalyticLine, AnalyticLinesConfig,AnalyticZonesConfig,AnalyticZone} from "camsng-frontend-shared/components/smartPlayer/mixins.js";
import SmartPlayerScreen from "@/components/oneScreen/SmartPlayerScreen.vue";
import "@/components/oneScreen/scss/index.scss";


/**
 * Примесь для опций редактирования аналитики.
 *
 * Вынесена в отдельную часть из-за объема кода.
 * Очень зависит от прочих примесей, поэтому используется только в рамках редактирования аналитик.
 * Возможно потом сильно будет переделана.
 */
export const QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER = "n";
export const QUERY_KEY_ONE_SCREEN_TIME_SHIFT = "s";
export const QUERY_KEY_ONE_SCREEN_TAB = "t";
export const editAnalyticsMixin = {
  mixins: [editDataEntityMixin],
  methods: {
    /**
     * Открытие формы для запроса видео с камеры чтобы редактировать линию и точку, по аналитике подсчета посетителей .
     * Для запроса видео нужна необходимая информация по камере.
     * Необходима трансформация информации по точкам в объекты, пригодные для редактора.
     * Так же нужна обратная трансформация по завершению работ и сохранение.
     */
    async openDialogEditLines() {
      let cameraInfo, extraCameraInfo;
      this.isLoading = true;
      try {
        const {entityInfo, extraInfo} = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`, [
          this.entityId,
          [FIELDS_CAMERA.title, FIELDS_CAMERA.streams_count, FIELDS_CAMERA.is_sounding, FIELDS_CAMERA.inventory_is_ptz, FIELDS_CAMERA.server_id],
        ]);
        cameraInfo = entityInfo;
        extraCameraInfo = extraInfo;
      } catch (error) {
        this.$camsdals.alert(error);
        return;
      } finally {
        this.isLoading = false;
      }
      const linesInfoForEdit = [this.formEdit[this.fieldsEntity.counter_line], [this.formEdit[this.fieldsEntity.enter_point]]],
        analyticLinesConfigForEdit = new AnalyticLinesConfig(
          this.formEdit[this.fieldsEntity.image_width],
          this.formEdit[this.fieldsEntity.image_height],
          1,
          3,
          [linesInfoForEdit].map((rawAnalyticLine) => new AnalyticLine(rawAnalyticLine)));
      this.$camsdals.open(
        SmartPlayerScreen,
        {
          cameraNumber: this.entityId,
          cameraInfo: cameraInfo,
          title: cameraInfo[FIELDS_CAMERA.title],
          tokenTypes: [TOKEN_TYPES.L, TOKEN_TYPES.R],
          vendorName: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.vendor_name], null),
          domain: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.domain], null),
          streamCount: cameraInfo[FIELDS_CAMERA.streams_count],
          hasSound: cameraInfo[FIELDS_CAMERA.is_sounding],
          hasPtz: cameraInfo[FIELDS_CAMERA.inventory_is_ptz],
          showSidebar: true,
          analyticLinesConfig: analyticLinesConfigForEdit,
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${cameraInfo[FIELDS_CAMERA.title]}`},
        {
          name: "js-click-outside",
          size: "vuedal-auto-width",
          onClose: async (analyticLinesConfigForSave) => {
            if (!analyticLinesConfigForSave) {
              return;
            }
            this.formEdit[this.fieldsEntity.image_width] = analyticLinesConfigForSave.linesWidth;
            this.formEdit[this.fieldsEntity.image_height] = analyticLinesConfigForSave.linesHeight;
            this.formEdit[this.fieldsEntity.counter_line] = analyticLinesConfigForSave.lines[0].points[0]
              .map((point) => ({x: Math.trunc(point.x), y: Math.trunc(point.y)}));
            let tempEnterPoint =analyticLinesConfigForSave.lines[0].points[1]
              .map((point) => ({x: Math.trunc(point.x), y: Math.trunc(point.y)}));
            this.formEdit[this.fieldsEntity.enter_point] = tempEnterPoint[0];
            this.saveData();
            // this.errorsFormEdit[this.fieldsEntity.lines] = this.errorsFormEdit[this.fieldsEntity.lines].join(", ");
          }
        }
      );
    },
    /**
     * Открытие формы для запроса видео с камеры чтобы редактировать зоны аналитики для распознавания номеров.
     * Для запроса видео нужна необходимая информация по камере.
     * Необходима трансформация информации по точкам в объекты, пригодные для редактора.
     * Так же нужна обратная трансформация по завершению работ и сохранение.
     * В форме присутствуют события на таймлайне, а также боковая панель для отображения скриншота и описания события
     */
    async openDialogEditZones() {
      let cameraInfo, extraCameraInfo;
      this.isLoading = true;
      try {
        const {entityInfo, extraInfo} = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`, [
          this.entityId,
          [FIELDS_CAMERA.title, FIELDS_CAMERA.streams_count, FIELDS_CAMERA.is_sounding, FIELDS_CAMERA.inventory_is_ptz, FIELDS_CAMERA.server_id, FIELDS_CAMERA.address],
        ]);
        cameraInfo = entityInfo;
        extraCameraInfo = extraInfo;
      } catch (error) {
        this.$camsdals.alert(error);
        return;
      } finally {
        this.isLoading = false;
      }

      const zonesInfoForEdit = this.formEdit[this.fieldsEntity.zones] || [],
        analyticZonesConfigForEdit = new AnalyticZonesConfig(
          this.formEdit[this.fieldsEntity.image_width],
          this.formEdit[this.fieldsEntity.image_height],
          3,
          100,
          zonesInfoForEdit.map((rawAnalyticZone) => new AnalyticZone(rawAnalyticZone.coordinates, rawAnalyticZone.sensitivity)));

      this.$camsdals.open(
        SmartPlayerScreen,
        {
          cameraInfo: cameraInfo,
          cameraNumber: this.entityId,
          title: cameraInfo[FIELDS_CAMERA.title],
          address: cameraInfo[FIELDS_CAMERA.address],
          tokenTypes: [TOKEN_TYPES.L, TOKEN_TYPES.R],
          vendorName: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.vendor_name], null),
          domain: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.domain], null),
          streamCount: cameraInfo[FIELDS_CAMERA.streams_count],
          hasSound: cameraInfo[FIELDS_CAMERA.is_sounding],
          hasPtz: cameraInfo[FIELDS_CAMERA.inventory_is_ptz],
          showSidebar: true,
          analyticZonesConfig: analyticZonesConfigForEdit,
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${cameraInfo[FIELDS_CAMERA.title]}`},
        {
          name: "js-click-outside",
          size: "vuedal-auto-width",
          onClose: async (analyticZonesConfigForSave) => {
            if (!analyticZonesConfigForSave) {
              return;
            }
            this.formEdit[this.fieldsEntity.image_width] = analyticZonesConfigForSave.zonesWidth;
            this.formEdit[this.fieldsEntity.image_height] = analyticZonesConfigForSave.zonesHeight;
            this.formEdit[this.fieldsEntity.zones] = analyticZonesConfigForSave.exportZones().map((analyticZone) => {
              return {coordinates: analyticZone.exportPoints(), sensitivity: analyticZone.sensitivity};
            });
            this.saveData();
            // this.errorsFormEdit[this.fieldsEntity.zones] = this.errorsFormEdit[this.fieldsEntity.zones].join(", ");
          }
        }
      );
    },
    async openDialogMotionAlarmEvents() {
      let cameraInfo, extraCameraInfo;
      this.isLoading = true;
      try {
        const {entityInfo, extraInfo} = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`, [
          this.entityId,
          [FIELDS_CAMERA.title, FIELDS_CAMERA.streams_count, FIELDS_CAMERA.is_sounding, FIELDS_CAMERA.inventory_is_ptz, FIELDS_CAMERA.server_id, FIELDS_CAMERA.address],
        ]);
        cameraInfo = entityInfo;
        extraCameraInfo = extraInfo;
      } catch (error) {
        this.$camsdals.alert(error);
        return;
      } finally {
        this.isLoading = false;
      }


      this.$camsdals.open(
        SmartPlayerScreen,
        {
          cameraInfo: cameraInfo,
          cameraNumber: this.entityId,
          title: cameraInfo[FIELDS_CAMERA.title],
          address: cameraInfo[FIELDS_CAMERA.address],
          tokenTypes: [TOKEN_TYPES.L, TOKEN_TYPES.R],
          vendorName: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.vendor_name], null),
          domain: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.domain], null),
          streamCount: cameraInfo[FIELDS_CAMERA.streams_count],
          hasSound: cameraInfo[FIELDS_CAMERA.is_sounding],
          hasPtz: cameraInfo[FIELDS_CAMERA.inventory_is_ptz],
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${cameraInfo[FIELDS_CAMERA.title]}`},
        {
          name: "js-click-outside",
          size: "vuedal-auto-width",
          onClose: (needReload) => needReload ? this.loadSourceData() : null
        }
      );
    },

  },
};
