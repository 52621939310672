/**
 * Отдельный vuex модуль для взаимодействия по markers-API
 */
import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для markers. Вызов действия начинать с "markers/"
export const ACTION_LOAD_INFO_MARKERS = "LOAD_INFO_MARKERS";
export const ACTION_LOAD_MARKERS = "LOAD_MARKERS";
export const ACTION_LOAD_MARKERS_FOR_TABLE = "LOAD_MARKERS_FOR_TABLE";
export const ACTION_LOAD_MARKERS_FOR_SELECT = "LOAD_MARKERS_FOR_SELECT";
export const ACTION_LOAD_MARKER_FOR_EDIT = "LOAD_MARKER_FOR_EDIT";
export const ACTION_CREATE_MARKER = "CREATE_MARKER";
export const ACTION_EDIT_MARKER = "EDIT_MARKER";
export const ACTION_DELETE_MARKER = "DELETE_MARKER";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_MARKER = Object.freeze({
  id: "id",
  name: "name",
  main: "main",
  retina: "retina",
  shadow: "shadow",
  is_deleted: "is_deleted",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_MARKER = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей фильтров.
 */
export const TITLES_FIELDS_MARKER = {
  [FIELDS_MARKER.id]: "ID",
  [FIELDS_MARKER.name]: "Заголовок маркера",
  [FIELDS_MARKER.main]: "Главное изображение",
  [FIELDS_MARKER.retina]: "Retina версия",
  [FIELDS_MARKER.shadow]: "Тень",
  [FIELDS_MARKER.is_deleted]: "Удален?",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_MARKER = {
  [FIELDS_MARKER.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_MARKER.name]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_MARKER.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

/**
 * Перечисления опций для загрузки дополнительной информации.
 */
export const EXTRAS_MARKER = Object.freeze({
  name: "name",
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для маркера.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_MARKERS]() {
      const response = await this.getters.privateAjax.post("/v0/markers/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка маркеров.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_MARKERS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/markers/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка маркеров для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_MARKERS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_MARKERS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_MARKERS_FOR_TABLE});
    },
    /**
     * Загрузка списка маркеров для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_MARKERS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_MARKER.is_deleted, "=", false);
      const responseData = await dispatch(ACTION_LOAD_MARKERS, {
        pageSize,
        fields,
        filters: [defaultFilterForSelect],
        search,
        cancelTokenKey: ACTION_LOAD_MARKERS_FOR_SELECT
      });
      return responseData.results;
    },
    /**
     * Загрузка одного маркера для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} markerId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_MARKER_FOR_EDIT]({dispatch}, [markerId, fields]) {
      const filter = makeFilterApi(FILTERS_MARKER.id, "=", markerId),
        responseData = await dispatch(ACTION_LOAD_MARKERS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание маркера.
     *
     * @param {Object} context
     * @param {Object} markerInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_MARKER](context, markerInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/markers/create/", markerInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование маркера.
     *
     * @param {Object} context
     * @param {Object} markerInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_MARKER](context, markerInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/markers/edit/", markerInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление маркера.
     *
     * @param {Object} context
     * @param {Array} markerIds
     * @param {String} comment
     * @return {Promise}
     */
    async [ACTION_DELETE_MARKER](context, {markerIds, comment = ""}) {
      return this.getters.privateAjax.post("/v0/markers/delete/", {
        ids: markerIds,
        comment
      });
    },
  },
};
