<template>
  <form @keyup.enter="changePassword()" @submit.prevent="changePassword()">
    <p>Введите новый пароль</p>

    <div class="row">
      <SmartInputText
        v-model="formEdit.password"
        :error="errorsFormEdit['password']"
        :input-type="inputType"
        caption="Новый пароль"
        class="col"
      >
        <CamsButton
          position="r"
          size="s"
          type="button"
          icon-type="only"
          @mousedown="inputType = 'text'"
          @mouseout="inputType = 'password'"
          @mouseup="inputType = 'password'"
        >
          <svg class="icon" style="padding: 3px 0 0 5px;width: 40px; height: 30px">
            <use xlink:href="@/assets/img/icons.svg#icon-search" />
          </svg>
        </CamsButton>
      </SmartInputText>
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ACTION_CHANGE_PASSWORD_CLIENT} from "@/store/users/clients/index.js";
import {formWithFieldsMixin, methodsForDialogMixin} from "@/utils/mixins.js";
import {reformatErrorsForm} from "@/utils/helpers.js";

/**
 * Компонент диалога изменения пароля клиента.
 */
export default {
  mixins: [methodsForDialogMixin, formWithFieldsMixin],
  props: {
    /**
     * Идентификатор клиента.
     */
    clientId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      formEdit: {
        password: "",
      },
      inputType: "password",
    };
  },
  methods: {
    /**
     * Отправка нового пароля.
     */
    async changePassword() {
      this.isLoading = true;
      this.errorsFormEdit = {};
      try {
        await this.$store.dispatch(`clients/${ACTION_CHANGE_PASSWORD_CLIENT}`, {
          clientId: this.clientId,
          password: this.formEdit.password,
        });
        this.closeDialog();
      } catch (error) {
        this.errorsFormEdit = reformatErrorsForm(error.fields);
      }
      this.isLoading = false;
    },
  }
};
</script>
