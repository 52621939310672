/**
 * Отдельный vuex модуль для взаимодействия по firmwares-API
 */
import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для markers. Вызов действия начинать с "markers/"
export const ACTION_LOAD_INFO_FIRMWARES = "LOAD_INFO_FIRMWARES";
export const ACTION_LOAD_FIRMWARES = "LOAD_FIRMWARES";
export const ACTION_LOAD_FIRMWARES_FOR_TABLE = "LOAD_FIRMWARES_FOR_TABLE";
export const ACTION_LOAD_FIRMWARES_FOR_SELECT = "LOAD_FIRMWARES_FOR_SELECT";
export const ACTION_LOAD_FIRMWARE_FOR_EDIT = "LOAD_FIRMWARE_FOR_EDIT";
export const ACTION_CREATE_FIRMWARE = "CREATE_FIRMWARE";
export const ACTION_EDIT_FIRMWARE = "EDIT_FIRMWARE";
export const ACTION_DELETE_FIRMWARE = "DELETE_FIRMWARE";
export const ACTION_LOAD_SUPPORTED_CAMERA_MODELS = "LOAD_SUPPORTED_CAMERA_MODELS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_FIRMWARE = Object.freeze({
  id: "id",
  model: "model",
  version: "version",
  upload_date: "upload_date",
  is_deleted: "is_deleted",
  type: "type",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_FIRMWARE = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  model: "model"
});
/**
 * Тип файла прошивки
 */
export const TYPES_FIRMWARE = [
  "rootfs",
  "kernel"
];

/**
 * Стандартные названия для полей фильтров.
 */
export const TITLES_FIELDS_FIRMWARE = {
  [FIELDS_FIRMWARE.id]: "ID",
  [FIELDS_FIRMWARE.model]: "Модель",
  [FIELDS_FIRMWARE.version]: "Версия ПО",
  [FIELDS_FIRMWARE.upload_date]: "Дата загрузки",
  [FIELDS_FIRMWARE.is_deleted]: "Удален?",
  [FIELDS_FIRMWARE.type]: "Тип файла",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_FIRMWARE = {
  [FIELDS_FIRMWARE.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_FIRMWARE.model]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_FIRMWARE.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для прошивки.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_FIRMWARES]() {
      const response = await this.getters.privateAjax.post("/v0/cameras/firmware/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка камер доступных к обновлению прошивки.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_FIRMWARES](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/firmware", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка камера доступных к обновлению прошивок, для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_FIRMWARES_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_FIRMWARES, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_FIRMWARES_FOR_TABLE});
    },
    /**
     * Загрузка списка прошивок для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_FIRMWARES_FOR_SELECT]({dispatch}, {fields, search, cameraModel = null,}) {
      const defaultFilterForSelect = cameraModel
        ? [
          makeFilterApi(FILTERS_FIRMWARE.is_deleted, "=", false),
          makeFilterApi(FILTERS_FIRMWARE.model, "=", cameraModel)
        ]
        : [makeFilterApi(FILTERS_FIRMWARE.is_deleted, "=", false)];
      const responseData = await dispatch(ACTION_LOAD_FIRMWARES, {
        fields,
        filters: defaultFilterForSelect,
        search,
        cancelTokenKey: ACTION_LOAD_FIRMWARES_FOR_SELECT
      });
      return responseData.results;
    },
    /**
     * Загрузка прошивки для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} firmwareId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_FIRMWARE_FOR_EDIT]({dispatch}, [firmwareId, fields]) {
      const filter = makeFilterApi(FILTERS_FIRMWARE.id, "=", firmwareId),
        responseData = await dispatch(ACTION_LOAD_FIRMWARES, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание прошивки.
     *
     * @param {Object} context
     * @param {Object} firmwareInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_FIRMWARE](context, firmwareInfo) {
      let formData = new FormData();
      formData.append('firmware', firmwareInfo.firmware);
      formData.append('version', firmwareInfo.version);
      formData.append('type', firmwareInfo.type);
      formData.append('model', firmwareInfo.model);

      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/firmware/create/", formData);
        return  response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование прошивки.
     *
     * @param {Object} context
     * @param {Object} firmwareInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_FIRMWARE](context, firmwareInfo) {
      let formData = new FormData();
      formData.append('id', firmwareInfo.id);
      formData.append('firmware', firmwareInfo.firmware);
      formData.append('version', firmwareInfo.version);
      formData.append('type', firmwareInfo.type);
      formData.append('model', firmwareInfo.model);
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/firmware/edit/",  formData);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление прошивки.
     *
     * @param {Object} context
     * @param {Array} firmwareIds
     * @param {String} comment
     * @return {Promise}
     */
    async [ACTION_DELETE_FIRMWARE](context, {id, comment = ""}) {
      let formData = new FormData();
      formData.append('id', id);

      return this.getters.privateAjax.post("/v0/cameras/firmware/delete/",
        formData, {
          headers: {
            'content-type': 'multipart/form-data'}
        },);
    },
    /**
     * Загрузка списка камер доступных к обновлению прошивки.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_SUPPORTED_CAMERA_MODELS](context) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/setup/supported_models", {
        });
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
  },
};
