<template>
  <div class="content__settings__body">
    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />

    <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
      <div class="row">
        <div class="col-2">
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.username)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.username]"
              :caption="titlesFields[fieldsEntity.username]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.username)"
              :error="errorsFormEdit[fieldsEntity.username]"
              :make-focus="true"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.about)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.about]"
              :caption="titlesFields[fieldsEntity.about]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.about)"
              :error="errorsFormEdit[fieldsEntity.about]"
              class="col"
              input-type="area"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.is_superuser)" class="row">
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_superuser]"
              :caption="titlesFields[fieldsEntity.is_superuser]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.is_superuser)"
              :error="errorsFormEdit[fieldsEntity.is_superuser]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.labels)" class="row">
            <SmartInputArray
              v-model="formEdit[fieldsEntity.labels]"
              :caption="titlesFields[fieldsEntity.labels]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.labels)"
              :error="errorsFormEdit[fieldsEntity.labels]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.right_group_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.right_group_id]"
              :caption="titlesFields[fieldsEntity.right_group_id]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.right_group_id)"
              :error="errorsFormEdit[fieldsEntity.right_group_id]"
              :initial-options="initialOptionsRightGroups"
              :settings-remote-search="settingsSelects[fieldsEntity.right_group_id]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.is_active)" class="row">
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_active]"
              :caption="titlesFields[fieldsEntity.is_active]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.is_active)"
              :error="errorsFormEdit[fieldsEntity.is_active]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.date_block)" class="row">
            <SmartInputDate
              v-model="formEdit[fieldsEntity.date_block]"
              :caption="titlesFields[fieldsEntity.date_block]"
              :config-flat-pickr="configFlatPickr"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.date_block)"
              :error="errorsFormEdit[fieldsEntity.date_block]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.immortal_token_access)" class="row">
            <SmartSwitch
              v-model="formEdit[fieldsEntity.immortal_token_access]"
              :caption="titlesFields[fieldsEntity.immortal_token_access]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.immortal_token_access)"
              :error="errorsFormEdit[fieldsEntity.immortal_token_access]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.immortal_token_ips)" class="row">
            <SmartInputArray
              v-model="formEdit[fieldsEntity.immortal_token_ips]"
              :caption="titlesFields[fieldsEntity.immortal_token_ips]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.immortal_token_ips)"
              :error="errorsFormEdit[fieldsEntity.immortal_token_ips]"
              class="col"
            />
          </div>

          <div class="row">
            <div class="buttons-group">
              <CamsButton
                v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_ADMIN)"
                priority="primary"
                type="button"
                @click="saveData()"
              >
                Сохранить и продолжить редактирование
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_ADMIN)"
                priority="primary"
                type="button"
                @click="saveDataAndRedirect()"
              >
                Сохранить
              </CamsButton>
            </div>
          </div>

          <div class="row">
            <div class="buttons-group">
              <router-link
                v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_ADMIN)"
                :to="routeBack"
                class="button button_btn button_medium button_btn-default"
              >
                Отменить
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="buttons-group">
              <CamsButton
                v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.USER_ADMIN)"
                priority="primary"
                type="button"
                @click="openDialogChangePasswordAdmin()"
              >
                Сменить пароль
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.RESET_SALT, $abilitiesSubjects.USER_ADMIN)"
                priority="primary"
                type="button"
                @click="resetSaltAdmin()"
              >
                Сбросить соль
              </CamsButton>
            </div>
          </div>
        </div>

        <div class="col-2">
          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.date_joined)"
            v-show="sourceData.entityInfo[fieldsEntity.date_joined]"
            :title="sourceData.entityInfo[fieldsEntity.date_joined] | localDateTimeZone"
          >
            {{ titlesFields[fieldsEntity.date_joined] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.date_joined] | localDate }}</strong>
          </p>

          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.last_login)"
            v-show="sourceData.entityInfo[fieldsEntity.last_login]"
            :title="sourceData.entityInfo[fieldsEntity.last_login] | localDateTimeZone"
          >
            {{ titlesFields[fieldsEntity.last_login] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.last_login] | localDate }}</strong>
          </p>

          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.USER_ADMIN, fieldsEntity.is_local_password)">
            {{ titlesFields[fieldsEntity.is_local_password] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.is_local_password] | bool }}</strong>
          </p>

          <br>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_AUTH)">
            <strong>
              <router-link :to="routeLogAuthsByAdmin">Логи авторизаций</router-link>
            </strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_VIEW)">
            <strong>
              <router-link :to="routeLogViewsByAdmin">Логи просмотров</router-link>
            </strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_CAMERA_WATCH_REASON)">
            <strong>
              <router-link :to="routeLogCameraWatchReasonsByAdmin">Логи запросов доступа к камере</router-link>
            </strong>
          </p>
        </div>
      </div>
    </form>

    <div v-else class="row">
      <p>Данные не удалось загрузить.</p>
    </div>
  </div>
</template>

<script>
import ChangePasswordAdminDialog from "@/components/users/admins/ChangePasswordAdminDialog.vue";
import {ROUTE_ADMINS, ROUTE_LOG_AUTHS, ROUTE_LOG_CAMERA_WATCH_REASONS, ROUTE_LOG_VIEWS} from "@/router/names.js";
import {
  ACTION_EDIT_ADMIN,
  ACTION_LOAD_ADMIN_FOR_EDIT,
  ACTION_RESET_SALT_ADMINS,
  EXTRAS_ADMIN,
  FIELDS_ADMIN,
  TITLES_FIELDS_ADMIN
} from "@/store/users/admins/index.js";
import {FILTERS_LOG_AUTHS, FILTERS_LOG_CAMERA_WATCH_REASONS, FILTERS_LOG_VIEWS} from "@/store/log/index.js";
import {ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT, FIELDS_RIGHT_GROUP} from "@/store/rightGroups/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE, SORT_DIRECTIONS} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования админа.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    const adminId = Number(this.$route.params.adminId),
          paramsRouteLogAuthsByAdmin = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_AUTHS.user_id, "=", [adminId]),
              new FilterData(FILTERS_LOG_AUTHS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_AUTHS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogAuthsByAdmin = {name: ROUTE_LOG_AUTHS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogAuthsByAdmin.stringify()}},
          paramsRouteLogViewsByAdmin = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_VIEWS.user_id, "=", [adminId]),
              new FilterData(FILTERS_LOG_VIEWS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_VIEWS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogViewsByAdmin = {name: ROUTE_LOG_VIEWS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogViewsByAdmin.stringify()}},
          paramsRouteLogCameraWatchReasonsByAdmin = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_CAMERA_WATCH_REASONS.user_id, "=", [adminId]),
              new FilterData(FILTERS_LOG_CAMERA_WATCH_REASONS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_CAMERA_WATCH_REASONS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogCameraWatchReasonsByAdmin = {name: ROUTE_LOG_CAMERA_WATCH_REASONS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogCameraWatchReasonsByAdmin.stringify()}};

    return {
      routeBack: {name: ROUTE_ADMINS},
      fieldsEntity: FIELDS_ADMIN,
      titlesFields: TITLES_FIELDS_ADMIN,
      entityId: adminId,
      nameActionLoadDataForEdit: `admins/${ACTION_LOAD_ADMIN_FOR_EDIT}`,
      nameActionEdit: `admins/${ACTION_EDIT_ADMIN}`,

      initialOptionsRightGroups: [],
      settingsSelects: {
        [FIELDS_ADMIN.right_group_id]: {
          action: `rightGroups/${ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_RIGHT_GROUP.id,
          labelField: FIELDS_RIGHT_GROUP.name
        },
      },

      configFlatPickr: {
        minDate: "today",
        minTime: Date.now(),
      },
      routeLogViewsByAdmin: routeLogViewsByAdmin,
      routeLogAuthsByAdmin: routeLogAuthsByAdmin,
      routeLogCameraWatchReasonsByAdmin: routeLogCameraWatchReasonsByAdmin,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      this.initialOptionsRightGroups = this.sourceData.extraInfo[EXTRAS_ADMIN.right_group];
    },
    /**
     * Открытие формы для изменения пароля админа.
     */
    openDialogChangePasswordAdmin() {
      this.$camsdals.open(
        ChangePasswordAdminDialog,
        {adminId: this.entityId},
        {dialogTitle: "Изменить пароль админа"},
        {},
      );
    },
    /**
     * Сброс соли у админа.
     */
    resetSaltAdmin() {
      this.$camsdals.confirm("Хотите сбросить соль у администратора?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`admins/${ACTION_RESET_SALT_ADMINS}`, {adminsIds: [this.entityId]});
          this.$camsdals.alert("Соль сброшена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка сбросе соли");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
