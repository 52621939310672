<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS}" class="tabs__item">
          Серверы аналитики
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CLUSTERS}" class="tabs__item">
          Кластеры серверов аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS_LOGS}" class="tabs__item">
          Логи серверов
        </router-link>
        <router-link :to="{name: routes.ROUTE_ROOT_ANALYTICS_SETTINGS}" class="tabs__item tabs__item_active">
          Настройка аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CAR_NUMBER_REPORT}" class="tabs__item">
          Отчеты
        </router-link>
      </div>
    </div>
    <div class="tabs_analytics">
      <router-link :to="{name: routes.ROUTE_CAR_NUMBERS}" class="tabs__item">
        Автомобили
      </router-link>
      <router-link :to="{name: routes.ROUTE_HELMETS}" class="tabs__item">
        Каски
      </router-link>
      <router-link :to="{name: routes.ROUTE_MOTION_ALARMS}" class="tabs__item">
        Движения
      </router-link>
      <router-link :to="{name: routes.ROUTE_MASKS}" class="tabs__item">
        Маски
      </router-link>
      <router-link :to="{name: routes.ROUTE_CROWDS}" class="tabs__item">
        Скопление людей
      </router-link>
      <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
        Вторжение в зону
      </router-link>
      <router-link :to="{name: routes.ROUTE_PEOPLE_COUNTS}" class="tabs__item">
        Подсчет посетителей
      </router-link>
      <router-link :to="{name: routes.ROUTE_THERMAL_VISIONS}" class="tabs__item">
        Тепловизоры
      </router-link>
      <router-link :to="{name: routes.ROUTE_FACE_RECOGNITIONS}" class="tabs__item">
        Распознавание лиц
      </router-link>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreatePerimeter"
      :column-captions="columnCaptions"
      :component-multi-edit="componentMultiEdit"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.camera_number"
      :name-action-load-data-for-multi-edit="nameActionLoadDataForMultiEdit"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreatePerimeterSecurityDialog from "@/components/analytics/perimeterSecurity/CreatePerimeterSecurityDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_CAR_NUMBERS,
  ROUTE_EDIT_ANALYTIC_SERVER,
  ROUTE_EDIT_PERIMETER_SECURITY,
  ROUTE_FACE_RECOGNITIONS,
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_MOTION_ALARMS,
  ROUTE_THERMAL_VISIONS,
  ROUTE_HELMETS,
  ROUTE_CROWDS, ROUTE_PEOPLE_COUNTS, ROUTE_ANALYTIC_CAR_NUMBER_REPORT, ROUTE_ANALYTIC_CLUSTERS
} from "@/router/names.js";
import {FIELDS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData} from "@/utils/helpers.js";
import MultiEditAnalyticsDialog from "@/components/analytics/MultiEditAnalyticsDialog.vue";
import {
  ACTION_LOAD_INFO_PERIMETER_SECURITYS,
  ACTION_LOAD_PERIMETER_SECURITYS_FOR_MULTI_EDIT, ACTION_LOAD_PERIMETER_SECURITYS_FOR_TABLE,
  EXTRAS_PERIMETER_SECURITY,
  FIELDS_PERIMETER_SECURITY, FILTERS_PERIMETER_SECURITY, TITLES_FIELDS_PERIMETER_SECURITY,
  TYPES_FIELDS_PERIMETER_SECURITY
} from "@/store/analytics/perimeterSecuritys/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по настройкам аналитик определения масок.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_ANALYTIC_SERVERS,
        ROUTE_THERMAL_VISIONS,
        ROUTE_CAR_NUMBERS,
        ROUTE_FACE_RECOGNITIONS,
        ROUTE_MOTION_ALARMS,
        ROUTE_ANALYTIC_SERVERS_LOGS,
        ROUTE_HELMETS,
        ROUTE_CROWDS,
        ROUTE_PERIMETER_SECURITYS,
        ROUTE_PEOPLE_COUNTS,
        ROUTE_ANALYTIC_CAR_NUMBER_REPORT,
        ROUTE_ANALYTIC_CLUSTERS
      },
      currentRoute: ROUTE_PERIMETER_SECURITYS,
      rawFields: FIELDS_PERIMETER_SECURITY,
      columnCaptions: TITLES_FIELDS_PERIMETER_SECURITY,
      nameActionLoadDataForTable: `analytics/perimeterSecuritys/${ACTION_LOAD_PERIMETER_SECURITYS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `analytics/perimeterSecuritys/${ACTION_LOAD_INFO_PERIMETER_SECURITYS}`,
      defaultFilters: [
        new FilterData(FILTERS_PERIMETER_SECURITY.is_deleted, "=", [false])
      ],
      defaultFieldsTableForView: [
        FIELDS_PERIMETER_SECURITY.camera_number,
        FIELDS_PERIMETER_SECURITY.push_domain,
        FIELDS_PERIMETER_SECURITY.is_active,
        FIELDS_PERIMETER_SECURITY.analytic_server_id,
        FIELDS_PERIMETER_SECURITY.is_deleted,
      ],
      nameActionLoadDataForMultiEdit: `analytics/perimeterSecuritys/${ACTION_LOAD_PERIMETER_SECURITYS_FOR_MULTI_EDIT}`,
      componentMultiEdit: MultiEditAnalyticsDialog,
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_PERIMETER_SECURITY.analytic_server]) {
        storedExtraInfo.analyticServers = {};
        sourceExtraInfo[EXTRAS_PERIMETER_SECURITY.analytic_server].forEach((analyticServerInfo) => {
          storedExtraInfo.analyticServers[analyticServerInfo[FIELDS_ANALYTIC_SERVER.id]] = {
            id: analyticServerInfo[FIELDS_ANALYTIC_SERVER.id],
            name: analyticServerInfo[FIELDS_ANALYTIC_SERVER.front_id],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_PERIMETER_SECURITY.camera_number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_PERIMETER_SECURITY, params: {cameraNumber: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_PERIMETER_SECURITY.analytic_server_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.analyticServers[rawTranslation], "name", "-");
          readyParams = {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_PERIMETER_SECURITY[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания настройки аналитики вторжения в зону.
     */
    openDialogCreatePerimeter() {
      this.$camsdals.open(
        CreatePerimeterSecurityDialog,
        {},
        {dialogTitle: "Создание аналитики вторжение в зону"},
        {},
      );
    },
  },
};
</script>
