<template>
  <div v-click-outside="hideListOptions" :class="['dropdown', {'dropdown__opened': isOpened}]">
    <div class="dropdown__toggle">
      <button class="button button_icon button_xsmall button_dropdown" type="button" @click="toggleListOptions">
        {{ textSelectedValue }}
        <svg class="icon icon-arrow-breadcrumb">
          <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
        </svg>
      </button>
    </div>

    <div class="dropdown__content">
      <div class="dropdown__content__title">
        Выберите уровень блокировки
      </div>
      <div class="dropdown__content__list dropdown-list">
        <div class="dropdown-list__item" title="Блокировка прямой трансляции и ниже" @click="selectOption(blockingLevel.L)">
          Трансляция
        </div>
        <div class="dropdown-list__item" title="Блокировка просмотра архива и ниже" @click="selectOption(blockingLevel.R)">
          Архив
        </div>
        <div class="dropdown-list__item" title="Блокировка скачивания" @click="selectOption(blockingLevel.D)">
          Скачивание
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TITLES_TOKEN_TYPES, TOKEN_TYPES} from "@/store/cameras/index.js";

/**
 * Компонент для управления блокировкой. Похож на список {@link PermissionSelect}.
 */
export default {
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: String,
      default: TOKEN_TYPES.L
    },
  },
  data() {
    return {
      isOpened: false,
      newValue: this.value,
      blockingLevel: TOKEN_TYPES,
    };
  },
  computed: {
    textSelectedValue() {
      return TITLES_TOKEN_TYPES[this.newValue];
    },
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события `@change`.
     *
     * @param {String} val
     */
    value(val) {
      this.newValue = val;
      this.$emit("change", val);
    }
  },
  methods: {
    /**
     * Метод срабатывает при клике на опцию в списке.
     * Новое значение сохраняется в компоненте и передается в родительский через `@input`, в конце список скрывается.
     *
     * @param {Number} newValue
     */
    selectOption(newValue) {
      this.newValue = newValue;
      this.$emit("input", newValue);
      this.hideListOptions();
    },
    /**
     * Метод вызывается по событию клика в поле для открытия списка.
     * Переключает видимость списка.
     */
    toggleListOptions() {
      this.isOpened = !this.isOpened;
    },
    /**
     * Метод скрывает список опций.
     * Его следует передавать как аргумент в директиву, что отслеживает клики за пределами элемента, в котором она указана.
     * Это нужно чтобы список скрывался при клике за его пределами.
     */
    hideListOptions() {
      this.isOpened = false;
    }
  },
};
</script>
