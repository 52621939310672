<template>
  <div class="main__content content">
    <div class="content__settings">
      <div class="content__settings__header">
        <div class="tabs">
          <a
            class="tabs__item js-tab-link"
            :class="{'tabs__item_active': activeTab === 'js-tab-id-filters'}"
            data-target-tab="js-tab-id-filters"
            href="#"
            @click="openTab"
          >Фильтры</a>
          <a
            class="tabs__item js-tab-link"
            :class="{'tabs__item_active': activeTab === 'js-tab-id-fields'}"
            data-target-tab="js-tab-id-fields"
            href="#"
            @click="openTab"
          >Таблица</a>
        </div>
      </div>
      <div class="content__settings__body">
        <div v-show="isVisibleTabContent" class="settings">
          <div v-if="activeTab === 'js-tab-id-filters'" id="js-tab-id-filters" class="js-tab-content">
            <div class="buttons-group" style="margin: 5px 0 15px 0;">
              <button class="button button_small button_btn-icon" type="button" @click="resetSettingsTable()">
                Сбросить
                <svg class="icon icon-table">
                  <use xlink:href="@/assets/img/icons.svg#icon-refresh" />
                </svg>
              </button>
              <button
                v-show="isVisibleTabContent"
                class="button button_small button_btn button_btn-default"
                type="button"
                @click="isVisibleTabContent = false"
              >
                Свернуть
              </button>

              <SmartFilterSelect
                v-model="selectedSavedFilters"
                :options="savedFilters"
                class="keeper-params-table__select"
                field-label="title"
                field-sort="title"
                field-value="title"
                placeholder="Выбрать фильтры"
                style="margin-bottom: 0"
                @input="applySavedParamsTable"
              />

              <div class="buttons-group">
                <button
                  class="button button_small button_btn button_btn-default"
                  type="button"
                  @click="saveParamsTable()"
                >
                  Сохранить
                </button>

                <button
                  :disabled="selectedSavedFilters === null"
                  class="button button_small button_btn button_btn-default"
                  type="button"
                  @click="deleteParamsTable()"
                >
                  Удалить
                </button>
              </div>
            </div>
            <SmartFilters
              v-if="listFiltersInfo"
              :initial-filters="settingsTable.filters"
              :source-list-filters-info="listFiltersInfo"
              @change-filters="settingsTable.filters = $event"
              @submit-filters="applyTableSettings()"
            />
          </div>

          <div v-if="activeTab === 'js-tab-id-fields'" id="js-tab-id-fields" class="js-tab-content">
            <SmartInputText
              v-model="searchFieldText"
              type="text"
              placeholder="Поиск"
              class="input_search"
            />
            <div class="settings-table">
              <draggable
                :list="filteredNotSelectedFields"
                class="settings-table__area area-fields area-fields_all"
                draggable=".js-draggable-item"
                group="selectedFieldsTableForView"
                @end="onDragEnd"
              >
                <div
                  v-for="nameField in filteredNotSelectedFields"
                  :key="nameField"
                  class="area-fields__item js-draggable-item"
                  @dblclick="moveSelectedFieldsTableForView(nameField, true)"
                >
                  {{ columnCaptions[nameField] }}
                </div>
              </draggable>

              <img alt="" src="@/assets/img/icons/drag.svg">
              <draggable
                :list="settingsTable.selectedFieldsTableForView"
                class="settings-table__area area-fields area-fields area-fields_selected"
                draggable=".js-draggable-item"
                group="selectedFieldsTableForView"
                @end="onDragEnd"
              >
                <div
                  v-for="nameField in settingsTable.selectedFieldsTableForView"
                  :key="nameField"
                  :class="{'js-draggable-item': nameField !== keyField}"
                  class="area-fields__item"
                  @dblclick="(nameField !== keyField) && moveSelectedFieldsTableForView(nameField, false)"
                >
                  {{ columnCaptions[nameField] }}
                </div>
                <p slot="footer">
                  Перетащите заголовки столбцов, которые вы хотите отобразить в таблице.
                </p>
              </draggable>
            </div>
          </div>
        </div>

        <button v-show="!isVisibleTabContent" class="button button_filters_collapse" type="button" @click="isVisibleTabContent = true">
          <svg class="sidebar__icon">
            <use xlink:href="@/assets/img/icons.svg#icon-arrow-double" />
          </svg>
          Развернуть
        </button>
      </div>

      <div class="content__settings__footer">
        <div class="input input_small input_btn input_search">
          <input v-model="settingsTable.searchText" placeholder="Поиск" type="text" @keyup.enter="applyTableSettings()">
          <button class="button" type="button" @click="applyTableSettings()">
            <svg class="icon icon-table">
              <use xlink:href="@/assets/img/icons.svg#icon-search" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="content__specialbar">
      <div class="buttons-group">
        <button
          v-if="$can($abilitiesActions.CREATE, $route.meta.abilitySubject) && clickButtonCreate"
          class="button button_small button_btn-icon"
          type="button"
          @click="clickButtonCreate()"
        >
          Добавить
          <svg class="icon icon-table">
            <use xlink:href="@/assets/img/icons.svg#icon-plus" />
          </svg>
        </button>
        <button
          :disabled="!isAvailableMultiEditor"
          class="button button_small button_btn-icon"
          type="button"
          @click="openDialogMultiEdit()"
        >
          Множественные действия
          <svg class="icon icon-table">
            <use xlink:href="@/assets/img/icons.svg#icon-edit" />
          </svg>
        </button>
        <button
          v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT) || $can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DOWNLOAD_CSV_BUTTON)"
          class="button button_small button_btn button_btn-default"
          type="button"
          @click="openDialogExportToCSV()"
        >
          CSV
        </button>
      </div>

      <nav class="pagination">
        <paginate
          v-model="currentPage"
          :active-class="'pagination__list__item_active'"
          :break-view-class="'pagination__list__item_collapse'"
          :click-handler="selectPage"
          :container-class="'pagination__list'"
          :hide-prev-next="true"
          :page-class="'pagination__list__item'"
          :page-count="pageInfo.numPages"
          :page-range="5"
          next-text=""
          prev-text=""
        />
        <SmartSelect
          v-model="settingsTable.pageSize"
          :one-row="true"
          :options="[20, 50, 75, 100, 150, 200]"
          @input="selectPageSize"
        />
      </nav>

      <span>Найдено: {{ pageInfo.count }}</span>
    </div>

    <div class="content__table cams-table-wrapper">
      <table class="cams-table">
        <tr>
          <th class="cams-table__cell cams-table__cell_fixed-width">
            <div class="checkbox">
              <input
                v-model="selectedAllEntitiesForMultiEdit"
                :disabled="!isAvailableMultiEditor"
                class="checkbox__input"
                type="checkbox"
                @change="selectedEntitiesForMultiEdit = selectedAllEntitiesForMultiEdit ? true : []"
              >
              <span class="checkbox__img" />
            </div>
          </th>

          <th
            v-for="nameField in fieldsTable"
            :key="nameField"
            :class="{'cams-table__cell_sort-asc': hasOrderAscByField(nameField), 'cams-table__cell_sort-desc': hasOrderDescByField(nameField), 'cams-table__cell_sortable': listFieldsForSort.includes(nameField)}"
            class="cams-table__cell"
            @click="listFieldsForSort.includes(nameField) && changeOrder(nameField)"
            v-html="`<div class='cams-table__cell-wrapper'>${columnCaptions[nameField]} <span>${indexOrderByField(nameField)}</span></div>`"
          />
        </tr>

        <tr v-if="isLoadingPage" class="cams-table__row-spinner">
          <td :colspan="settingsTable.selectedFieldsTableForView.length+1" class=" cams-table__cell">
            <SpinnerLoading size="s" />
          </td>
        </tr>
        <tr v-if="selectedAllEntitiesForMultiEdit || selectedEntitiesForMultiEdit.length" class="cams-table__row-count">
          <td :colspan="settingsTable.selectedFieldsTableForView.length+1" class="cams-table__cell">
            <template v-if="selectedAllEntitiesForMultiEdit">
              Выбраны все строки ({{ pageInfo.count }}).
            </template>
            <template v-else>
              Выбрано {{ selectedEntitiesForMultiEdit.length }} строк.
              <a href="#" @click="selectedAllEntitiesForMultiEdit = selectedEntitiesForMultiEdit = true">
                Выбрать все {{ pageInfo.count }}.
              </a>
            </template>
          </td>
        </tr>

        <tr v-for="(rowDataTable, indexDataTable) in listDataTable" :key="`row-${rowDataTable[keyField]}`">
          <td class="cams-table__cell cams-table__cell_fixed-width" :class="{'cams-table__cell_pale': deletedField && rowDataTable[deletedField]}">
            <div class="checkbox">
              <input
                v-model="selectedEntitiesForMultiEdit"
                :disabled="!isAvailableMultiEditor"
                :value="rowDataTable[keyField]"
                class="checkbox__input js-row-checkbox"
                type="checkbox"
                @change="selectedAllEntitiesForMultiEdit && flushSelectedEntitiesForMultiEdit()"
                @click.exact="lastIndexClickedCheckbox = indexDataTable"
                @click.shift.exact="shiftSelectEntitiesForMultiEdit(indexDataTable)"
              >
              <span class="checkbox__img" />
            </div>
          </td>
          <SmartTableCell
            v-for="(nameField, indexField) in fieldsTable"
            :key="indexDataTable + '-' + indexField"
            :class="{'cams-table__cell_pale': deletedField && rowDataTable[deletedField]}"
            :current-data-cell="calcDataForCell(nameField, rowDataTable, extraInfo)"
          />
        </tr>
      </table>
    </div>

    <nav class="pagination">
      <paginate
        v-model="currentPage"
        :active-class="'pagination__list__item_active'"
        :break-view-class="'pagination__list__item_collapse'"
        :click-handler="selectPage"
        :container-class="'pagination__list'"
        :hide-prev-next="true"
        :page-class="'pagination__list__item'"
        :page-count="pageInfo.numPages"
        :page-range="5"
        next-text=""
        prev-text=""
      />
      <div>
        <SmartSelect
          v-model="settingsTable.pageSize"
          :one-row="true"
          :options="[20, 50, 75, 100, 150, 200]"
          @input="selectPageSize"
        />
      </div>
    </nav>

    <SpinnerLoadingModal v-if="isLoadingCommon" />
  </div>
</template>

<script>
import SmartFilters from "@/components/smart/table/SmartFilters.vue";
import SmartFilterSelect from "@/components/smart/table/SmartFilterSelect.vue";
import SmartTableCell from "@/components/smart/table/SmartTableCell.vue";
import {
  ACTION_DELETE_PARAMS_FROM_TABLE,
  ACTION_SAVE_PARAMS_FOR_TABLE,
  GETTER_ALL_PARAMS_TABLE,
  GETTER_PARAMS_TABLE
} from "@/store/keeperParamsTable/index.js";
import {DEFAULT_PAGE_SIZE_FOR_TABLE, NAME_QUERY_PARAM_FOR_TABLE, SORT_DIRECTIONS} from "@/utils/consts.js";
import {buildFiltersApiFromData, TableQueryParams} from "@/utils/helpers.js";
import draggable from "vuedraggable";
import ProcessingExportToCSVDialog from "@/components/dialogs/ProcessingExportToCSVDialog.vue";

/**
 * Общий компонент таблицы и механизмов фильтрации данных в таблице.
 *
 * Замечание по использованию draggable элементов (без разницы плагин не плагин). В Firefox при разработке может происходить зависание.
 * Помогает только перезапуск всего браузера.
 */
export default {
  components: {
    SmartTableCell,
    SmartFilters,
    draggable,
    SmartFilterSelect,
  },
  props: {
    /**
     * Текущий маршрут для основного компонента, в котором отрисовывается эта таблица.
     * Необходим для работы постраничной навигации.
     */
    currentRoute: {
      type: String,
      required: true,
    },
    /**
     * Перечень полей сущности в сыром виде как он задан в vuex.
     */
    rawFields: {
      type: Object,
      required: true,
    },
    /**
     * Объект со всеми заголовками полей для таблицы.
     */
    columnCaptions: {
      type: Object,
      required: true,
    },
    /**
     * Полное название action в vuex, которое предназначено для загрузки данных для таблицы.
     */
    nameActionLoadDataForTable: {
      type: String,
      required: true,
    },
    /**
     * Полное название action в vuex, которое предназначено для загрузки служебной информации для работы данной таблицы.
     */
    nameActionLoadInfoForTable: {
      type: String,
      required: true,
    },
    /**
     * Перечень стандартных фильтров для таблицы, которые применяются по умолчанию при первой загрузке и при сборсе.
     */
    defaultFilters: {
      type: Array,
      default: () => ([])
    },
    /**
     * Перечень стандартных полей для отображения в таблице, которые применяются по умолчанию при первой загрузке и при сборсе.
     */
    initialDefaultFieldsTableForView: {
      type: Array,
      default: null,
    },
    /**
     * Функция для парсинга дополнительных (extra) данных, на которые могут ссылаться сущности таблицы.
     * Вызывается после получения данных для таблицы.
     *
     * @param {Object} sourceExtraInfo Серверные данные которые необходимо распарсить.
     * @param {Object} storedExtraInfo В этот объект необходимо поместить обработанные данные
     * (ссылается на объект внутри компонента таблицы).
     */
    parseExtra: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default(sourceExtraInfo, storedExtraInfo) {
      }
    },
    /**
     * Функция должна возвращать структурированный объект для компонента SmartTableCell для отображения данных в ячейке.
     *
     * @param {String} nameField Название поля для которого сейчас запрашивается информация из rowData.
     * @param {Object} rowData Полный набор текущих данных для сущности.
     * @param {Object} storedExtraInfo Ранее сохраненная, обработанная, дополнительная информация.
     * @returns {Object}
     */
    calcDataForCell: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default(nameField, rowData, storedExtraInfo) {
        return {
          type: undefined,
          value: rowData[nameField],
          params: {},
        };
      },
    },
    /**
     * Обработчик для клика по кнопке создания сущности.
     */
    clickButtonCreate: {
      type: Function,
      default: null,
    },
    /**
     * Ключевое поле для таблицы, которое используется в ряде функциональных штук:
     * - его нельзя удалить из списка видимости, потому что по нему формируется ссылка для редактирования,
     * - на него опирается работа для чекбоксов для множественного редактирования.
     */
    keyField: {
      type: String,
      required: true,
    },
    /**
     * Поле с признаком удаления сущности.
     * Строку можно маркировать как "удаленная", если значение в этом поле содержит положительное булевское значение.
     */
    deletedField: {
      type: String,
      default: null,
    },
    /**
     * Компонент для мультиредактирования сущностей. Открывается в диалоговом окне при клике на соответствующую кнопку.
     */
    componentMultiEdit: {
      type: Object,
      default: null,
    },
    /**
     * Полное название action в vuex, которое предназначено для загрузки данных для мультиредактирования.
     * Применяется для случаев когда необходимо совершать выборку элементов за пределами текущей страницы.
     */
    nameActionLoadDataForMultiEdit: {
      type: String,
      default: null,
    },
  },
  data() {
    // Парсинг параметров URL строки необходимо начать здесь, а не в created. Т.к. из-за реактивности возможны повторные срабатывания.
    // Исключительно для начального процесса инициализации в одноименные переменные дублируется функционал из методов и вычисляемых свойств.
    const parsedParams = TableQueryParams.parse(this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]),
          listAvailableFields = Object.values(this.rawFields).filter(
            (nameField) => this.$can(this.$abilitiesActions.READ_FIELD, this.$route.meta.abilitySubject, nameField)
          ),
          defaultFieldsTableForView = this.initialDefaultFieldsTableForView || listAvailableFields,
          selectedFieldsTableForView = parsedParams.selectedFieldsTableForView || [...defaultFieldsTableForView],
          notSelectedFieldsTableForView = _.difference(listAvailableFields, selectedFieldsTableForView);

    return {
      isLoadingCommon: false,
      isLoadingPage: false,
      isVisibleTabContent: true,
      activeTab: 'js-tab-id-filters', // Устанавливаем по умолчанию активный таб
      settingsTable: {
        selectedFieldsTableForView: selectedFieldsTableForView,
        order: parsedParams.order || [],
        searchText: parsedParams.searchText || "",
        filters: parsedParams.filters || _.cloneDeep(this.defaultFilters),
        pageSize: parsedParams.pageSize || DEFAULT_PAGE_SIZE_FOR_TABLE
      },
      // Противоположность settingsTable.selectedFieldsTableForView - список невыбранных полей.
      // Выведено из состава computed т.к. для использования в рамках плагина draggable
      // нужна честная переменная (и ее начальная инициализация) чтобы избежать проблем при перемещении элементов между списками,
      // в противном случае возможны зависания и исчезновения элементов в одном из списках.
      notSelectedFieldsTableForView: notSelectedFieldsTableForView,
      // Список доступных полей для просмотра в таблице.
      listAvailableFields: listAvailableFields,
      listFiltersInfo: {},
      listFieldsForSort: [],
      listDataTable: [],
      currentPage: parsedParams.currentPage || 1,
      pageInfo: {
        numPages: 0,
        count: 0,
      },
      extraInfo: {},
      // 2 параметра для выбора строк для множественного редактирования.
      // Одним определяются ключи, а второй нужен на случай выбора всех строк.
      // Когда используется последний - то обе переменные становяться в true (чтобы визуально все чекбоксы были выбраны).
      selectedEntitiesForMultiEdit: [],
      selectedAllEntitiesForMultiEdit: false,
      lastIndexClickedCheckbox: 0,
      // Параметры для сохраненных фильтров.
      selectedSavedFilters: null,
      savedFilters: {},
      searchFieldText: '',
    };
  },
  computed: {
    filteredNotSelectedFields() {
      if (!this.searchFieldText) { // Проверка на пустую строку или неопределенное значение
        return this.notSelectedFieldsTableForView;
      }

      return this.notSelectedFieldsTableForView.filter(nameField =>
        this.columnCaptions[nameField].toLowerCase().includes(this.searchFieldText.toLowerCase())
      );
    },
    /**
     * Корректировка списка стандартных полей для отображения в таблице - при их отсутсвии отображаются все доступные поля.
     *
     * @return {Array}
     */
    defaultFieldsTableForView() {
      return this.initialDefaultFieldsTableForView || this.listAvailableFields;
    },
    /**
     * Актуальный перечень полей таблицы.
     * Вычисляется исходя из полученных с сервера данных (по первому значению)
     * или из заявленных в списке выбранных
     *
     * todo на время запроса надо блокировать чтоб не пересчитывался при опустошении listDataTable
     *
     * @return {Array}
     */
    fieldsTable() {
      if (!_.isEmpty(this.listDataTable)) {
        return _.intersection(this.settingsTable.selectedFieldsTableForView, _.keys(this.listDataTable[0]));
      }
      return this.settingsTable.selectedFieldsTableForView;
    },
    /**
     * @return {Object} Детали сортировки в пригодном для отрисовки элементов таблицы виде.
     */
    detailsOrder() {
      const detailsOrder = {};
      this.settingsTable.order.forEach((itemOrder, index) => {
        detailsOrder[itemOrder.field] = {
          index: index + 1,
          direction: itemOrder.direction
        };
      });
      return detailsOrder;
    },
    /**
     * Это хак для наблюдения за массивом, см. детали https://github.com/vuejs/vue/issues/2164
     *
     * Просто наблюдать через vue за массивом нельзя - это объект, а объекты передаются по ссылке,
     * поэтому старые и новые значения будут совпадать в аргументах метода watch.
     * Одно из решений такое - делаем computed версию массива через его копию (новый объект).
     *
     * @return {Array}
     */
    computedActualOrder() {
      return [...this.settingsTable.order];
    },
    /**
     * @return {Boolean} Вернет true если соблюдены необходимые условия для работы множественного редактирования.
     */
    isAvailableMultiEditor() {
      return Boolean(this.keyField && this.componentMultiEdit && this.nameActionLoadDataForMultiEdit);
    },
  },
  watch: {
    /**
     * Перехват параметризованного URL - извлечение номера новой страницы и ее загрузка.
     * Хук beforeRouteUpdate работает только в компоненте, который непосредственно обслуживает маршрут,
     * но не его внутренние компоненты.
     *
     * @param {Object} to
     */
    $route(to) {
      this.parseQueryParams(to.query);
      this.loadPage();
    },
    /**
     * При изменении сортировки обновляется содержимое таблицы.
     * Вручную проверяем совпадения в массивах - если изменились поля сортировки.
     *
     * @param {Array} newOrder
     * @param {Array} oldOrder
     */
    computedActualOrder(newOrder, oldOrder) {
      if (!_.isEqual(newOrder, oldOrder)) {
        this.stringifyQueryParams({});
      }
    },
  },
  /**
   * При создании компонента таблицы нужно загрузить информацию о доступных фильтрах и сортировках.
   * Только получив эту информацию можно строить фильтрацию, которая задана в настройках таблицы,
   * поэтому первая загрузка данных в компонент происходит только после прогрузки настроек.
   *
   * Однако если для таблицы не предусмотрено механизмов фильтрации - данные можно запрашивать сразу.
   *
   * Инициализируется список невыбранных полей (на основании тех что были заданы для показа).
   */
  created() {
    this.isLoadingCommon = true;

    if (this.nameActionLoadInfoForTable) {
      this.listFiltersInfo = {};
      this.$store
        .dispatch(this.nameActionLoadInfoForTable)
        .then(([listFiltersInfo, listFieldsForSort]) => {
          this.listFiltersInfo = listFiltersInfo;
          this.listFieldsForSort = listFieldsForSort;
        })
        .then(() => {
          this.loadPage();
        })
        .finally(() => {
          this.isLoadingCommon = false;
        });
    } else {
      this.loadPage();
      this.isLoadingCommon = false;
    }

    this.updateSavedFilters();
  },
  methods: {

    /**
     * Приведет заданные настройки таблицы в строку для подстановки ее в адресную строку.
     *
     * Передача полученной строки в GET параметр и переход по готовому URL для применения настроек.
     * Переход по URL не осуществляется если новый и старый URL совпадают,
     * но при этом вызывается функция переданная в аргументе onAbort,
     * через нее принудительно вызывается разбор актуального URL для перезагрузки таблицы.
     */
    stringifyQueryParams({currentPage = null, pageSize = null, order = null, selectedFieldsTableForView = null, filters = null, searchText = null,}) {
      const params = new TableQueryParams({
        currentPage: parseInt(currentPage) || this.currentPage,
        pageSize: pageSize || this.settingsTable.pageSize,
        order: order || this.settingsTable.order,
        selectedFieldsTableForView: selectedFieldsTableForView || this.settingsTable.selectedFieldsTableForView,
        filters: filters || this.settingsTable.filters,
        searchText: searchText || this.settingsTable.searchText,
      });

      this.$router.push(
        {name: this.currentRoute, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}},
        null,
        () => {
          this.parseQueryParams(this.$route.query);
        }
      );
    },
    /**
     * Разбор параметров GET строки, парсинг и подстановка параметров в компонент.
     * Вызывать после осуществления навигации, для заполнения настроек переданными значениями или дефолтными.
     *
     * При присвоении настройкам таблицы значений из дефолтных, коими являются объекты - их необходимо присваивать
     * через полное, глубокое клонирование, чтобы избежать изменение оригинальных настроек, передающихся по ссылке.
     *
     * @param {Object} params
     */
    parseQueryParams(params) {
      const parsedParams = TableQueryParams.parse(params[NAME_QUERY_PARAM_FOR_TABLE]);
      this.currentPage = parsedParams.currentPage || 1;
      this.settingsTable.pageSize = parsedParams.pageSize || DEFAULT_PAGE_SIZE_FOR_TABLE;
      this.settingsTable.order = parsedParams.order || [];
      this.settingsTable.selectedFieldsTableForView = parsedParams.selectedFieldsTableForView || [...this.defaultFieldsTableForView];
      // [] != false и parsedParams.filters != [] только если параметры пусты - значит это простой переход
      // к странице и в этом случае актуальны фильтры по умолчанию.
      this.settingsTable.filters = parsedParams.filters || _.cloneDeep(this.defaultFilters);
      this.settingsTable.searchText = parsedParams.searchText || "";
      // Коррекция списка невыбранных полей при изменении настроек таблицы.
      this.notSelectedFieldsTableForView = _.difference(this.listAvailableFields, this.settingsTable.selectedFieldsTableForView);
      // Обнуление значений для множественного редактирования.
      this.selectedEntitiesForMultiEdit = [];
      this.selectedAllEntitiesForMultiEdit = false;
      this.lastIndexClickedCheckbox = 0;
    },
    /**
     * Переход к новой странице, который отражается в истории браузера.
     */
    selectPage(selectedPage) {
      this.stringifyQueryParams({currentPage: selectedPage});
    },
    /**
     * Выбор количества строк на странице.
     */
    selectPageSize(pageSize) {
      this.stringifyQueryParams({currentPage: 1, pageSize});
    },
    /**
     * Ручное применение настроек таблицы.
     */
    applyTableSettings() {
      this.stringifyQueryParams({currentPage: 1});
    },
    /**
     * Сброс настроек таблицы в начальное состояние.
     */
    resetSettingsTable() {
      this.stringifyQueryParams({
        currentPage: 1,
        pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE,
        order: [],
        selectedFieldsTableForView: this.defaultFieldsTableForView,
        filters: this.defaultFilters,
        searchText: "",
      });
    },
    /**
     * Загрузка списка информации по заданным настройкам.
     * Все параметры перед отправкой в механизм работы с API клонируются,
     * чтобы избежать нежелательных эффектов при их изменении перед отправкой на сервер.
     * Поле для маркировки удаленных строк запрашивается всегда (но не факт что это разрешено, поэтому все может сломаться).
     */
    async loadPage() {
      const dataForLoadTable = {
        page: this.currentPage,
        pageSize: this.settingsTable.pageSize,
        orderBy: this.settingsTable.order,
        fields: this.deletedField ? [this.deletedField, ...this.settingsTable.selectedFieldsTableForView] : this.settingsTable.selectedFieldsTableForView,
        filters: buildFiltersApiFromData(this.settingsTable.filters, this.listFiltersInfo),
        search: this.settingsTable.searchText,
      };
      this.listDataTable = [];
      this.isLoadingPage = true;
      try {
        const responseData = await this.$store.dispatch(this.nameActionLoadDataForTable, _.cloneDeep(dataForLoadTable));
        this.parseExtra(responseData.extra, this.extraInfo);
        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.listDataTable = responseData.results;
        this.isLoadingPage = false;
      } catch (error) {
        if (!error) {
          return; // Для прерванных ранее отправленных запросов.
        }
        this.isLoadingPage = false;
        throw error;
      }
    },
    /**
     * Изменение порядка сортировки у таблицы.
     *
     * Если у поля ранее была сортировка - текущий порядок сортировки меняется,
     * поле с измененной сортировкой ставиться в последнюю очередь.
     * Поле, которое ранее шло по возрастанию, теперь пойдет по убыванию.
     * Поле, которое ранее шло по убыванию, будет удалено из настроек сортировки.
     *
     * @param {String} nameField
     */
    changeOrder(nameField) {
      let indexForDelete = null,
          newItemOrder = null;
      // Проверка изменения существующих настроек сортировки.
      this.settingsTable.order.forEach((itemOrder, index) => {
        if (itemOrder.field === nameField) {
          indexForDelete = index;
          if (itemOrder.direction === SORT_DIRECTIONS.ASC) {
            newItemOrder = {
              field: nameField,
              direction: SORT_DIRECTIONS.DESC,
            };
          }
        }
      });
      // Или удаляем предыдущий элемент сортировки или задаем новое поле для сортировки.
      if (_.isNumber(indexForDelete)) {
        this.settingsTable.order.splice(indexForDelete, 1);
      } else {
        newItemOrder = {
          field: nameField,
          direction: SORT_DIRECTIONS.ASC,
        };
      }
      // Новая настройка для сортировки, если она есть, попадает в самый конец списка,
      // чтобы у нее был наименьший приоритет.
      if (newItemOrder !== null) {
        this.settingsTable.order.push(newItemOrder);
      }
    },
    /**
     * Имеется ли у поля сортировка по возрастанию.
     *
     * @param {String} nameField
     * @return {Boolean}
     */
    hasOrderAscByField(nameField) {
      return _.get(this.detailsOrder, `${nameField}.direction`) === SORT_DIRECTIONS.ASC;
    },
    /**
     * Имеется ли у поля сортировка по убыванию.
     *
     * @param {String} nameField
     * @return {Boolean}
     */
    hasOrderDescByField(nameField) {
      return _.get(this.detailsOrder, `${nameField}.direction`) === SORT_DIRECTIONS.DESC;
    },
    /**
     * Вернет порядок сортировки для поля (если он есть) в строком виде для вставки в шаблон.
     *
     * Внимание: в шаблоне функция вызывается внутри v-html, а не просто в html через {{}},
     * так нужно чтобы все работало во всех браузерах.
     * Если применять {{}} то будет работать в ff, но не в chrome, нет идей почему так,
     * возможно что-то связано с "контент полиси".
     *
     * @param {String} nameField
     * @return {String}
     */
    indexOrderByField(nameField) {
      return _.get(this.detailsOrder, `${nameField}.index`, "");
    },
    /**
     * Метод для переключения видимости содержимого табов, в которых содержатся настройки отображения таблицы.
     *
     *
     * @param {Event} eventClickLinkTab
     */
    openTab(eventClickLinkTab) {
      eventClickLinkTab.preventDefault(); // Предотвращаем стандартное поведение ссылки
      this.activeTab = eventClickLinkTab.target.dataset["targetTab"]; // Обновляем активный таб
    },
    onDragEnd() {
      this.applyTableSettings(); // Вызов метода, который обычно вызывается при нажатии на "Применить"
    },
    /**
     * Перемещение полей между списками для управления видимостей колонок, через клики по названиям полей.
     *
     * @param {String} nameField
     * @param {Boolean} needAdd
     */
    moveSelectedFieldsTableForView(nameField, needAdd) {
      if (needAdd) {
        this.settingsTable.selectedFieldsTableForView.push(nameField);
      } else {
        this.settingsTable.selectedFieldsTableForView.splice(this.settingsTable.selectedFieldsTableForView.indexOf(nameField), 1);
      }
      this.notSelectedFieldsTableForView = _.difference(this.listAvailableFields, this.settingsTable.selectedFieldsTableForView);
    },
    /**
     * Метод для клика по чекбоксу при выбранных всех элементах. Сбрасывает состояние выбранных чекбоксов.
     */
    flushSelectedEntitiesForMultiEdit() {
      this.selectedAllEntitiesForMultiEdit = false;
      this.selectedEntitiesForMultiEdit = [];
    },
    /**
     * Выбор чекбоксов в таблице при зажатой клавише `shift`.
     * Позволяет отметить (снять отметку) у группы чекбоксов на основе последнего выбранного и текущего.
     *
     * На основе последнего нажатого чекбокса и текущего кликнутого с шифтом,
     * определяется диапазон интересующих ключевых значений из чекбоксов.
     *
     * На основе кликнутого с шифтом чекбоксе определяется - необходимо либо всех попадающие в диапазон значения учесть,
     * при массовом редактировании, либо все вычесть из него.
     *
     * В зависимости от полученных настроек интересующие значения в итоговый массив будут попадать либо удаляться,
     * с учетом его текущего состояния.
     *
     * @param {Number} newIndexDataTable
     */
    shiftSelectEntitiesForMultiEdit(newIndexDataTable) {
      if (this.selectedAllEntitiesForMultiEdit) {
        this.flushSelectedEntitiesForMultiEdit();
        return;
      }

      const startIndex = Math.min(newIndexDataTable, this.lastIndexClickedCheckbox),
            endIndex = Math.max(newIndexDataTable, this.lastIndexClickedCheckbox),
            allCheckboxes = Array.from(document.querySelectorAll(".js-row-checkbox")),
            allInterestingValues = _.map(allCheckboxes.slice(startIndex, endIndex + 1), "value"),
            needAllCheckOrElse = !this.selectedEntitiesForMultiEdit.includes(allCheckboxes[newIndexDataTable].value);

      allInterestingValues.forEach((interestingValue) => {
        if (needAllCheckOrElse && !this.selectedEntitiesForMultiEdit.includes(interestingValue)) {
          this.selectedEntitiesForMultiEdit.push(interestingValue);
        } else if (!needAllCheckOrElse && this.selectedEntitiesForMultiEdit.includes(interestingValue)) {
          this.selectedEntitiesForMultiEdit.splice(this.selectedEntitiesForMultiEdit.indexOf(interestingValue), 1);
        }
      });

      this.lastIndexClickedCheckbox = newIndexDataTable;
    },
    /**
     * Открытие формы для множественного редактирования.
     * Перед открытием происходит загрузка информации из ключевого поля, для передачи конечного набора сущностей,
     * над которыми будут проводится операции.
     *
     * todo если количество выбранных не совпадает с количеством полученных
     */
    openDialogMultiEdit() {
      if (!this.isAvailableMultiEditor) {
        return;
      }

      // Промис по умолчанию с выбранными строками или промис со всеми отфильтрованными.
      let promise = Promise.resolve(),
          countSelectedOriginally = this.pageInfo.count;
      if (this.selectedAllEntitiesForMultiEdit) {
        const dataForLoadTable = {
          filters: buildFiltersApiFromData(this.settingsTable.filters, this.listFiltersInfo),
          search: this.settingsTable.searchText,
        };
        this.isLoadingPage = true;
        promise = this.$store
          .dispatch(this.nameActionLoadDataForMultiEdit, _.cloneDeep(dataForLoadTable))
          .finally(() => {
            this.isLoadingPage = false;
          });
      } else {
        countSelectedOriginally = this.selectedEntitiesForMultiEdit.length;
        promise = promise.then(() => this.selectedEntitiesForMultiEdit);
      }

      // Полученные списки строк передаются в компонент множественного редактирования.
      promise
        .then((selectedEntities) => {
          if (_.isEmpty(selectedEntities)) {
            throw 0;
          }
          this.$camsdals.open(
            this.componentMultiEdit,
            {selectedEntities, countSelectedOriginally},
            {dialogTitle: "Множественные действия"},
            {size: "xl"}
          );
        })
        .catch((error) => {
          const textError = error === 0 ? "Не выбрано строк для редактирования" : "Ошибка при получении всех строк для редактирования";
          this.$camsdals.alert(textError);
        });
    },
    /**
     * Открытие диалога экспорта данных из таблицы со всех страниц в CSV.
     */
    openDialogExportToCSV() {
      const dataForLoadTable = {
        page: this.currentPage,
        pageSize: this.settingsTable.pageSize,
        orderBy: this.settingsTable.order,
        fields: this.settingsTable.selectedFieldsTableForView,
        filters: buildFiltersApiFromData(this.settingsTable.filters, this.listFiltersInfo),
        search: this.settingsTable.searchText,
      };

      this.$camsdals.open(
        ProcessingExportToCSVDialog,
        {
          actionForLoadData: this.nameActionLoadDataForTable,
          dataForAction: _.cloneDeep(dataForLoadTable),
          currentRoute: this.currentRoute,
          columnNames: this.fieldsTable,
          columnCaptions: this.columnCaptions,
          parseExtra: this.parseExtra,
          calcDataForCell: this.calcDataForCell,
        },
        {dialogTitle: "Экспорт данных в CSV"},
      );
    },
    /**
     * Обновление набора сохраненных фильтров.
     */
    updateSavedFilters() {
      this.savedFilters = _.mapValues(
        this.$store.getters[`keeperParamsTable/${GETTER_ALL_PARAMS_TABLE}`](this.currentRoute),
        (value, title) => ({title, value})
      );
    },
    /**
     * Ввод названия набора фильтров в диалоговом окне и сохранение его.
     */
    saveParamsTable() {
      this.$camsdals.prompt("Введите название для набора фильтров", (nameParamsTable) => {
        if (!nameParamsTable) {
          return;
        }

        const params = new TableQueryParams({
          currentPage: this.currentPage,
          pageSize: this.settingsTable.pageSize,
          order: this.settingsTable.order,
          selectedFieldsTableForView: this.settingsTable.selectedFieldsTableForView,
          filters: this.settingsTable.filters,
          searchText: this.settingsTable.searchText,
        });

        this.$store.dispatch(`keeperParamsTable/${ACTION_SAVE_PARAMS_FOR_TABLE}`, {
          nameTable: this.currentRoute,
          nameParamsTable: nameParamsTable,
          queryParam: params.stringify()
        });

        this.updateSavedFilters();
      });

    },
    /**
     * Удаление выбранного набора фильтров.
     */
    deleteParamsTable() {
      if (!this.selectedSavedFilters) {
        return;
      }
      this.$store.dispatch(`keeperParamsTable/${ACTION_DELETE_PARAMS_FROM_TABLE}`, {
        nameTable: this.currentRoute,
        nameParamsTable: this.selectedSavedFilters,
      });
      this.updateSavedFilters();
    },
    /**
     * Применение сохраненного фильтра для таблицы.
     *
     * @param {String} nameParamsTable
     */
    applySavedParamsTable(nameParamsTable) {
      const queryParam = this.$store.getters[`keeperParamsTable/${GETTER_PARAMS_TABLE}`](this.currentRoute, nameParamsTable);

      this.$router.push(
        {name: this.currentRoute, query: {[NAME_QUERY_PARAM_FOR_TABLE]: queryParam}},
        null,
        () => {
          this.parseQueryParams(this.$route.query);
        }
      );
    }
  },
};
</script>

<style lang="scss">
.keeper-params-table {
  display: flex;
  margin-left: 15px;
  flex-direction: row;

  &__select {
    margin-right: 15px;
  }
}
</style>
