<template>
  <div class="content__settings__body">
    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />

    <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
      <div class="row">
        <div class="col-2">
          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_model]"
              :caption="titlesFields[fieldsEntity.inventory_model]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_model]"
              :make-focus="true"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_serial_number]"
              :caption="titlesFields[fieldsEntity.inventory_serial_number]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_serial_number]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_mac]"
              :caption="titlesFields[fieldsEntity.inventory_mac]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_mac]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_full]"
              :caption="titlesFields[fieldsEntity.inventory_address_full]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_full]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_region]"
              :caption="titlesFields[fieldsEntity.inventory_address_region]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_region]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_area]"
              :caption="titlesFields[fieldsEntity.inventory_address_area]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_area]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_city]"
              :caption="titlesFields[fieldsEntity.inventory_address_city]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_city]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_city_area]"
              :caption="titlesFields[fieldsEntity.inventory_address_city_area]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_city_area]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_city_district]"
              :caption="titlesFields[fieldsEntity.inventory_address_city_district]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_city_district]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_settlement]"
              :caption="titlesFields[fieldsEntity.inventory_address_settlement]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_settlement]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_street]"
              :caption="titlesFields[fieldsEntity.inventory_address_street]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_street]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_house_type]"
              :caption="titlesFields[fieldsEntity.inventory_address_house_type]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_house_type]"
              class="col"
            />
          </div>

          <div class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.inventory_address_house]"
              :caption="titlesFields[fieldsEntity.inventory_address_house]"
              :disabled="disabledFields"
              :error="errorsFormEdit[fieldsEntity.inventory_address_house]"
              class="col"
            />
          </div>

          <template v-if="!isDeleted">
            <div class="row">
              <div class="buttons-group">
                <CamsButton
                  v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.inventory_model)"
                  priority="primary"
                  type="button"
                  @click="saveData()"
                >
                  Сохранить и продолжить редактирование
                </CamsButton>
                <CamsButton
                  v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.inventory_model)"
                  priority="primary"
                  type="button"
                  @click="saveDataAndRedirect()"
                >
                  Сохранить
                </CamsButton>
              </div>
            </div>
          </template>
        </div>

        <div class="col-2" />
      </div>
    </form>

    <div v-else class="row">
      <p>Данные не удалось загрузить.</p>
    </div>
  </div>
</template>

<script>
import {ACTION_EDIT_CAMERA_INVENTORY, ACTION_LOAD_CAMERA_FOR_EDIT, FIELDS_CAMERA, TITLES_FIELDS_CAMERA} from "@/store/cameras/index.js";
import {ROUTE_403, ROUTE_CAMERAS} from "@/router/names.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования камеры.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    return {
      routeBack: {name: ROUTE_CAMERAS},
      fieldsEntity: FIELDS_CAMERA,
      titlesFields: TITLES_FIELDS_CAMERA,
      entityId: this.$route.params.cameraNumber,
      nameActionLoadDataForEdit: `cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`,
      nameActionEdit: `cameras/${ACTION_EDIT_CAMERA_INVENTORY}`,
      deletedField: FIELDS_CAMERA.is_deleted,
    };
  },
  computed: {
    /**
     * Все поля либо отключены либо нет. Зависит от прав.
     *
     * @return {Boolean}
     */
    disabledFields() {
      return !this.$can(this.$abilitiesActions.UPDATE_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.inventory_model);
    },
  },
  /**
   * Доступ к странице требует наличия специальных прав.
   */
  created() {
    if (!this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.inventory_model)) {
      this.$router.replace({name: ROUTE_403});
    }
  },
};
</script>
