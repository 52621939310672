/**
 * Отдельный vuex модуль для взаимодействия по analytics/car_number-API
 */

import {ANALYTICS, ZERO_ZONE_FOR_CREATE} from "@/store/analytics/helpers.js";
import {DEFAULT_PAGE_SIZE_FOR_TABLE, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для carNumbers. Вызов действия начинать с "analytics/carNumbers/"
export const ACTION_LOAD_INFO_CAR_NUMBERS = "LOAD_INFO_CAR_NUMBERS";
export const ACTION_LOAD_CAR_NUMBERS = "LOAD_CAR_NUMBERS";
export const ACTION_LOAD_CAR_NUMBERS_FOR_TABLE = "LOAD_CAR_NUMBERS_FOR_TABLE";
export const ACTION_LOAD_CAR_NUMBERS_FOR_MULTI_EDIT = "LOAD_CAR_NUMBERS_FOR_MULTI_EDIT";
export const ACTION_LOAD_CAR_NUMBER_FOR_EDIT = "LOAD_CAR_NUMBER_FOR_EDIT";
export const ACTION_CREATE_CAR_NUMBER = "CREATE_CAR_NUMBER";
export const ACTION_EDIT_CAR_NUMBER = "EDIT_CAR_NUMBER";
export const ACTION_DELETE_CAR_NUMBERS = "DELETE_CAR_NUMBERS";
export const ACTION_LOAD_INFO_CAR_NUMBER_HISTORY = "LOAD_INFO_CAR_NUMBER_HISTORY";
export const ACTION_LOAD_CAR_NUMBER_HISTORY = "LOAD_CAR_NUMBER_HISTORY";
export const ACTION_LOAD_CAR_NUMBER_HISTORY_FOR_TABLE = "LOAD_CAR_NUMBER_HISTORY_FOR_TABLE";
export const ACTION_LOAD_CAR_NUMBER_EVENT = "LOAD_CAR_NUMBER_EVENT";
export const ACTION_LOAD_CAR_NUMBER_EVENTS = "LOAD_CAR_NUMBER_EVENTS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CAR_NUMBER = Object.freeze({
  camera_number: "camera_number",
  push_domain: "push_domain",
  is_active: "is_active",
  is_deleted: "is_deleted",
  analytic_server_id: "analytic_server_id",
  min_confidence: "min_confidence",
  image_height: "image_height",
  image_width: "image_width",
  zones: "zones",
  sync_success: "sync_success",
  sync_date: "sync_date",
  sync_last_success: "sync_last_success",
  sync_error: "sync_error",
  sync_data: "sync_data",
  is_event: "is_event",
  country: "country",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CAR_NUMBER = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  analytic_server_id: "analytic_server_id",
});

/**
 * Стандартные названия для полей настроек аналитики распознавания номеров.
 */
export const TITLES_FIELDS_CAR_NUMBER = {
  [FIELDS_CAR_NUMBER.camera_number]: "Номер камеры",
  [FIELDS_CAR_NUMBER.push_domain]: "Домен PUSH",
  [FIELDS_CAR_NUMBER.is_active]: "Активна",
  [FIELDS_CAR_NUMBER.is_deleted]: "Удалена",
  [FIELDS_CAR_NUMBER.analytic_server_id]: "Сервер аналитики",
  [FIELDS_CAR_NUMBER.min_confidence]: "Минимальная уверенность",
  [FIELDS_CAR_NUMBER.image_height]: "Ширина зоны",
  [FIELDS_CAR_NUMBER.image_width]: "Высота зоны",
  [FIELDS_CAR_NUMBER.zones]: "Зоны аналитики",
  [FIELDS_CAR_NUMBER.sync_success]: "Успешность последней синхронизации",
  [FIELDS_CAR_NUMBER.sync_date]: "Дата синхронизации",
  [FIELDS_CAR_NUMBER.sync_last_success]: "Дата последней успешной синхронизации",
  [FIELDS_CAR_NUMBER.sync_error]: "Ошибка",
  [FIELDS_CAR_NUMBER.sync_data]: "Ответ от сервера аналитики",
  [FIELDS_CAR_NUMBER.is_event]: "Аналитика по событию",
  [FIELDS_CAR_NUMBER.country]: "Страна",
  [FIELDS_CAR_NUMBER.analytic_cluster_id]: "Кластер аналитики",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_CAR_NUMBER = {
  [FIELDS_CAR_NUMBER.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAR_NUMBER.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAR_NUMBER.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAR_NUMBER.sync_success]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAR_NUMBER.sync_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAR_NUMBER.sync_last_success]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAR_NUMBER.analytic_server_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAR_NUMBER.is_event]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с настройками аналитик распознавания номеров.
 */
export const EXTRAS_CAR_NUMBER = Object.freeze({
  analytic_server: "analytic_server"
});

const EXTRAS_BY_FIELDS_CAR_NUMBER = Object.freeze({
  [FIELDS_CAR_NUMBER.analytic_server_id]: [EXTRAS_CAR_NUMBER.analytic_server]
});
// Analytics camera car number history
export const FIELDS_CAR_NUMBER_HISTORY = Object.freeze({
  min_confidence:"min_confidence",
  hpk:"hpk",
  camera_number:"camera_number",
  is_deleted:"is_deleted",
  history_create_date:"history_create_date",
  push_domain:"push_domain",
  is_active:"is_active",
  analytic_server_id:"analytic_server_id",
  image_width:"image_width",
  image_height:"image_height",
  zones:"zones",
  is_event:"is_event",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent",
  country:"country",
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CAR_NUMBER_HISTORY = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  zones: "zones",
  data_change_event_date: "data_change_event_date",
  analytic_server_id:"analytic_server_id",
  data_change_event_user_id: "data_change_event_user_id",
  history_create_date: "history_create_date",
});
export const TITLES_FIELDS_CAR_NUMBER_HISTORY = {
  [FIELDS_CAR_NUMBER_HISTORY.min_confidence]: "Минимальная уверенность",
  [FIELDS_CAR_NUMBER_HISTORY.hpk]:"hpk",
  [FIELDS_CAR_NUMBER_HISTORY.camera_number]: "Номер камеры",
  [FIELDS_CAR_NUMBER_HISTORY.is_deleted]: "Удалена",
  [FIELDS_CAR_NUMBER_HISTORY.history_create_date]: "Дата события",
  [FIELDS_CAR_NUMBER_HISTORY.push_domain]: "Пуш домен",
  [FIELDS_CAR_NUMBER_HISTORY.is_active]: "Активна",
  [FIELDS_CAR_NUMBER_HISTORY.analytic_server_id]: "ID сервера аналитики",
  [FIELDS_CAR_NUMBER_HISTORY.image_width]: "Ширина изображения",
  [FIELDS_CAR_NUMBER_HISTORY.image_height]: "Высота изображения",
  [FIELDS_CAR_NUMBER_HISTORY.zones]:"Зоны",
  [FIELDS_CAR_NUMBER_HISTORY.is_event]: "Событийная аналитика?",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_date]: "Дата события 2",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_user_agent]: "user agent",
};
export const EXTRAS_CAR_NUMBER_HISTORY = Object.freeze({
  user: "user",
  analytic_server: "analytic_server",
});

const EXTRAS_BY_FIELDS_CAR_NUMBER_HISTORY = Object.freeze({
  [FIELDS_CAR_NUMBER_HISTORY.data_change_event_user_id]: [EXTRAS_CAR_NUMBER_HISTORY.user],
  [FIELDS_CAR_NUMBER_HISTORY.analytic_server_id]: [EXTRAS_CAR_NUMBER_HISTORY.analytic_server],

});
export const TYPES_FIELDS_CAR_NUMBER_HISTORY = {
  [FIELDS_CAR_NUMBER_HISTORY.history_create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CAR_NUMBER_HISTORY.is_event]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};
export const COUNTRY_LIST = [
  "ru",
  "am"
];
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async [ACTION_LOAD_CAR_NUMBER_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/car_number/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.car_number));
      } catch {
        return [];
      }
    },
    async [ACTION_LOAD_CAR_NUMBER_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const carNumbersSettings = await dispatch(ACTION_LOAD_CAR_NUMBER_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(carNumbersSettings)) {
        return null;
      }
      return carNumbersSettings[0];
    },
    /**
     * Загрузка служебной информации для настроек аналитик распознавания номеров.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CAR_NUMBERS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/car_number/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка настроек аналитик распознавания номеров.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBERS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CAR_NUMBER, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/car_number/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка настроек аналитик распознавания номеров для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBERS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CAR_NUMBERS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CAR_NUMBERS_FOR_TABLE});
    },
    /**
     * Загрузка списка настроек аналитик распознавания номеров для обработки их в рамках множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBERS_FOR_MULTI_EDIT]({dispatch}, {filters = [], search = ""}) {
      const responseData = await dispatch(ACTION_LOAD_CAR_NUMBERS, {page: 1, pageSize: 1000, fields: [FIELDS_CAR_NUMBER.camera_number], filters, search});
      return _.map(responseData.results, FIELDS_CAR_NUMBER.camera_number);
    },
    /**
     * Загрузка одной настройки аналитики распознавания номеров для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBER_FOR_EDIT]({dispatch}, [cameraNumber, fields]) {
      const filter = makeFilterApi(FILTERS_CAR_NUMBER.camera_number, "=", cameraNumber),
        responseData = await dispatch(ACTION_LOAD_CAR_NUMBERS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание новой настройки аналитики распознавания номеров.
     *
     * @param {Object} context
     * @param {Object} carNumberInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_CAR_NUMBER](context, carNumberInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/car_number/create/", {...carNumberInfo, ...ZERO_ZONE_FOR_CREATE});
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей настройки аналитики распознавания номеров.
     *
     * @param {Object} context
     * @param {Object} carNumberInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAR_NUMBER](context, carNumberInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/car_number/edit/", carNumberInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление настройки аналитики распознавания номеров.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_CAR_NUMBERS](context, {camerasNumbers}) {
      return this.getters.privateAjax.post("/v0/analytics/car_number/delete/", {camera_numbers: camerasNumbers});
    },


    // Методы для загрузки информации по истории редактирования аналитики автономеров,
    // начинается с v0/analytics/car_number/history
    /**
   * Загрузка служебной информации для камер.
   *
   * @return {Promise}
   */
    async [ACTION_LOAD_INFO_CAR_NUMBER_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/car_number/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
   * Загрузка списка истории.
   * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
   *
   * @param {Object} context
   * @param {Number} page
   * @param {Number} pageSize
   * @param {Array} orderBy
   * @param {Array} fields
   * @param {Array} filters
   * @param {String} search
   * @param {String} cancelTokenKey
   * @return {Promise}
   */
    async [ACTION_LOAD_CAR_NUMBER_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CAR_NUMBER_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/car_number/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
   * Загрузка списка для отображения в таблице.
   *
   * @param {Function} dispatch
   * @param {Number} page
   * @param {Number} pageSize
   * @param {Object} orderBy
   * @param {Array} fields
   * @param {Array} filters
   * @param {String} search
   * @return {Promise}
   */
    async [ACTION_LOAD_CAR_NUMBER_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_CAR_NUMBER_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CAR_NUMBER_HISTORY_FOR_TABLE});
    },
  },
};
