<template>
  <div>
    <div class="col">
      <p class="accordion__title">
        Редактирование параметров
      </p>
    </div>
    <SmartSwitch
      v-show="!deleteRightGroup"
      v-model="setRightGroup"
      :disabled="deleteRightGroup"
      :caption="'Установка прав'"
    />
    <SmartSwitch
      v-show="!setRightGroup"
      v-model="deleteRightGroup"
      :disabled="setRightGroup"
      :caption="'Удаление прав'"
    />
    <div v-if="setRightGroup || deleteRightGroup" class="col">
      <fieldset
        class="col"
        style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding: 17px 10px;"
      >
        <legend
          style="font: 16px 'Roboto-Medium'; text-align: left"
        >
          Права на камеру
        </legend>
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera_permission:read"
          caption="Чтение прав на камеру"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="log_camera_watch_reason:read"
          caption="Чтение логов просмотров"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera.location:update"
          caption="Изменение координат"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera:reset_salt"
          caption="Сброс соли"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera:activate"
          caption="Включение/выключение камеры"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera.live_reason"
          caption="Разрешение на просмотр трансляции"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera.dvr_reason"
          caption="Разрешение на просмотр архива"
          @input="changePromise()"
        />
        <SmartSwitch
          v-model="formEdit[fieldsEntity.permissions]"
          checkbox-value="camera:blocking"
          caption="Блокировка камеры"
          @input="changePromise()"
        />
      </fieldset>
      <fieldset
        class="col"
        style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding: 17px 10px;"
      >
        <legend
          style="font: 16px 'Roboto-Medium'; text-align: left"
        >
          Аналитика
        </legend>
        <div class="accordion__title">
          Серверы видеоаналитики
        </div>
        <div
          class="accordion__ability-actions_one-row"
          style="margin-left: 50px"
        >
          <SmartSwitch
            v-model="formEdit[fieldsEntity.permissions]"
            checkbox-value="analytic_server:create"
            caption="Создание"
            @input="changePromise()"
          />
          <SmartSwitch
            v-model="formEdit[fieldsEntity.permissions]"
            checkbox-value="analytic_server:read"
            caption="Чтение"
            @input="changePromise()"
          />
          <SmartSwitch
            v-model="formEdit[fieldsEntity.permissions]"
            checkbox-value="analytic_server:update"
            caption="Изменение"
            @input="changePromise()"
          />
        </div>
        <div class="accordion__title">
          Настройка аналитики
        </div>
        <div
          class="accordion__ability-actions_one-row"
          style="margin-left: 50px"
        >
          <SmartSwitch
            v-model="formEdit[fieldsEntity.permissions]"
            checkbox-value="analytic:create"
            caption="Создание"
            @input="changePromise()"
          />
          <SmartSwitch
            v-model="formEdit[fieldsEntity.permissions]"
            checkbox-value="analytic:read"
            caption="Чтение"
            @input="changePromise()"
          />
          <SmartSwitch
            v-model="formEdit[fieldsEntity.permissions]"
            checkbox-value="analytic:update"
            caption="Изменение"
            @input="changePromise()"
          />
        </div>
      </fieldset>
    </div>
    <div style="margin-top: 20px">
      <div class="row">
        <SmartVSelect
          v-model="formEdit.cameraGroupsForDelete"
          :disabled="!useSwitch.cameraGroupsForDelete"
          :multiple="true"
          :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
          caption="Группы камер для удаления"
          class="col-2"
          @input="changePromise()"
        />
        <SmartSwitch v-model="useSwitch.cameraGroupsForDelete" caption="Изменить?" class="col-2" @input="changePromise()" />
      </div>
      <div class="row">
        <SmartVSelect
          v-model="formEdit.cameraGroupsForSet"
          :disabled="!useSwitch.cameraGroupsForSet"
          :multiple="true"
          :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
          caption="Группы камер для установки"
          class="col-2"
          @input="changePromise()"
        />
        <SmartSwitch v-model="useSwitch.cameraGroupsForSet" caption="Изменить?" class="col-2" @input="changePromise()" />
      </div>
    </div>
  </div>
</template>

<script>
import {functionMultiEditMixin} from "@/utils/mixins.js";
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";
import {
  ACTION_DELETE_CAMERA_GROUPS_RIGHT_GROUP,
  ACTION_LOAD_RIGHT_GROUP_FOR_EDIT_PERMISSIONS, ACTION_SET_CAMERA_GROUP_RIGHT_GROUP,
  ACTION_SET_PERMISSIONS_RIGHT_GROUP,
  FIELDS_RIGHT_GROUP,
  TITLES_FIELDS_RIGHT_GROUP
} from "@/store/rightGroups/index.js";
import {
  ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT,
  FIELDS_CAMERA_GROUP
} from "@/store/cameraGroups/index.js";

/**
 * Компонент функции мультиредактора камер для постановки задач на миграцию камер на другой сервер/кластер.
 */
export default {
  mixins: [functionMultiEditMixin],
  data() {
    return {
      fieldsEntity: FIELDS_RIGHT_GROUP,
      titlesFields: TITLES_FIELDS_RIGHT_GROUP,
      setRightGroup: false,
      deleteRightGroup: false,
      formEdit: {
        [FIELDS_RIGHT_GROUP.permissions]: [],
        cameraGroupsForDelete: [],
        cameraGroupsForSet: [],
      },
      useSwitch: {
        cameraGroupsForDelete: false,
        cameraGroupsForSet: false,
      },
      settingsSelects: {
        [FIELDS_RIGHT_GROUP.camera_group_ids]: {
          action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP.id,
          labelField: FIELDS_CAMERA_GROUP.name
        },}
    };
  },
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     */
    changePromise() {
      const filteredFormEdit = _.pickBy(this.formEdit, (value, key) => this.useSwitch[key]),
            filteredFormEditCamera = _.pick(filteredFormEdit, Object.values(FIELDS_RIGHT_GROUP));

      this.$emit("change-promise", async (entityKey) => {
        try {
          if(this.setRightGroup || this.deleteRightGroup )  {
            const response = await this.$store.dispatch(`rightGroups/${ACTION_LOAD_RIGHT_GROUP_FOR_EDIT_PERMISSIONS}`,[entityKey]);
            const resultPermissions = this.setRightGroup? _.union(this.formEdit.permissions, response.entityInfo.permissions)
              : _.difference(response.entityInfo.permissions,this.formEdit.permissions);

            await this.$store.dispatch(`rightGroups/${ACTION_SET_PERMISSIONS_RIGHT_GROUP}`, {
              rightGroupId: entityKey,
              permissions:resultPermissions,
            })
            ;}
          if (!_.isEmpty(filteredFormEdit.cameraGroupsForDelete)) {
            await this.$store.dispatch(`rightGroups/${ACTION_DELETE_CAMERA_GROUPS_RIGHT_GROUP}`, {
              rightGroupId: entityKey,
              cameraGroupIds: filteredFormEdit.cameraGroupsForDelete,
            });
          }
          if (!_.isEmpty(filteredFormEdit.cameraGroupsForSet)) {
            await this.$store.dispatch(`rightGroups/${ACTION_SET_CAMERA_GROUP_RIGHT_GROUP}`, {
              rightGroupId: entityKey,
              cameraGroupIds: filteredFormEdit.cameraGroupsForSet,
            });
          }
          return ResultProcessingEntityMultiEdit.success(entityKey);
        } catch (error) {
          return ResultProcessingEntityMultiEdit.errorRequest(entityKey, error);
        }
      });
    },
  },
};
</script>
