/**
 * Отдельный vuex модуль для взаимодействия по cars-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для car. Вызов действия начинать с "cars/"
export const ACTION_LOAD_INFO_CARS = "LOAD_INFO_CARS";
export const ACTION_LOAD_CARS = "LOAD_CARS";
export const ACTION_LOAD_CARS_FOR_TABLE = "LOAD_CARS_FOR_TABLE";
export const ACTION_LOAD_CARS_FOR_SELECT = "LOAD_CARS_FOR_SELECT";
export const ACTION_LOAD_CAR_FOR_EDIT = "LOAD_CAR_FOR_EDIT";
export const ACTION_CREATE_CAR = "CREATE_CAR";
export const ACTION_EDIT_CAR = "EDIT_CAR";
export const ACTION_DELETE_CAR = "DELETE_CAR";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CAR = Object.freeze({
  id: "id",
  employee_id: "employee_id",
  gang_id: "gang_id",
  number: "number",
  is_deleted: "is_deleted",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CAR = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  employee_id: "employee_id",
});

/**
 * Стандартные названия для полей автомобилей.
 */
export const TITLES_FIELDS_CAR = {
  [FIELDS_CAR.id]: "ID",
  [FIELDS_CAR.employee_id]: "Сотрудник",
  [FIELDS_CAR.gang_id]: "Компания",
  [FIELDS_CAR.number]: "Номер",
  [FIELDS_CAR.is_deleted]: "Удален",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_CAR = {
  [FIELDS_CAR.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAR.number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAR.gang_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CAR.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CAR.employee_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с автомобилями.
 */
export const EXTRAS_CAR = Object.freeze({
  employee: "employee",
  gang: "gang",
});

const EXTRAS_BY_FIELDS_EXTRAS_CAR = Object.freeze({
  [FIELDS_CAR.employee_id]: [EXTRAS_CAR.employee],
  [FIELDS_CAR.gang_id]: [EXTRAS_CAR.gang],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для автомобилей.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CARS]() {
      const response = await this.getters.privateAjax.post("/v0/cars/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка автомобилей.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CARS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_EXTRAS_CAR, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/cars/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка автомобилей для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CARS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CARS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CARS_FOR_TABLE});
    },
    /**
     * Загрузка для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных автомобилей.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CARS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_CAR.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_CARS, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_CARS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного авто для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} carId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_FOR_EDIT]({dispatch}, [carId, fields]) {
      const filter = makeFilterApi(FILTERS_CAR.id, "=", carId),
        responseData = await dispatch(ACTION_LOAD_CARS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание автомобиля.
     *
     * @param {Object} context
     * @param {Object} carInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_CAR](context, carInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cars/create/", carInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование автомобиля.
     *
     * @param {Object} context
     * @param {Object} carInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAR](context, carInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cars/edit/", carInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление автомобиля.
     *
     * @param {Object} context
     * @param {Array} carIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CAR](context, {carIds}) {
      return this.getters.privateAjax.post("/v0/cars/delete/", {ids: carIds});
    },
  },
};
