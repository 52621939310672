<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Клиенты
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ clientId }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info">
        <div class="content__settings__header">
          <div class="tabs">
            <router-link :to="routeEditClient" active-class="tabs__item_active" class="tabs__item">
              Редактирование
            </router-link>
            <router-link :to="routeLogViews" active-class="tabs__item_active" class="tabs__item">
              Логи просмотров
            </router-link>
            <router-link :to="routeLogAuths" active-class="tabs__item_active" class="tabs__item">
              Логи авторизаций
            </router-link>
            <router-link v-model="clientId" :to="routeLogUserLinks" active-class="tabs__item_active" class="tabs__item">
              Логи мультиаккаунтинга
            </router-link>
            <router-link v-model="clientId" :to="routeLogUserPermissionsToCamera" active-class="tabs__item_active" class="tabs__item">
              Логи прав на камеру
            </router-link>
            <router-link v-model="clientId" :to="routeLogUserPermissionsToGroup" active-class="tabs__item_active" class="tabs__item">
              Логи прав на группы
            </router-link>
          </div>
        </div>

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ROUTE_CLIENTS,
  ROUTE_EDIT_CLIENT,
  ROUTE_LOG_VIEWS_BY_CLIENT,
  ROUTE_LOG_AUTHS_BY_CLIENT,
  ROUTE_VIEW_USER_LINKS_HISTORY,
  ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY, ROUTE_VIEW_USER_PERMISSIONS_HISTORY
} from "@/router/names.js";
import {routeBackMixin} from "@/utils/mixins.js";

/**
 * Несамостоятельный компонент с элементами навигации по страницам редактирования клиента.
 */
export default {
  mixins: [routeBackMixin],
  data() {
    return {
      routeBack: {name: ROUTE_CLIENTS},
      routeEditClient: {name: ROUTE_EDIT_CLIENT, params: {clientId: this.$route.params.clientId}},
      routeLogViews: {name: ROUTE_LOG_VIEWS_BY_CLIENT, params: {clientId: this.$route.params.clientId}},
      routeLogAuths: {name: ROUTE_LOG_AUTHS_BY_CLIENT, params: {clientId: this.$route.params.clientId}},
      routeLogUserLinks: {name: ROUTE_VIEW_USER_LINKS_HISTORY, params: {clientId: this.$route.params.clientId}},
      routeLogUserPermissionsToCamera: {name: ROUTE_VIEW_USER_PERMISSIONS_HISTORY, params: {clientId: this.$route.params.clientId}},
      routeLogUserPermissionsToGroup: {name: ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY, params: {clientId: this.$route.params.clientId}},
      clientId: this.$route.params.clientId,
    };
  },
  /**
   * Уточняются маршруты для табов с учетом параметров маршрута назад на страницу с таблицей,
   * которая была в том состоянии (с нужными фильтрами) из которого был совершен на страницу редактирования.
   */
  created() {
    if (this.routeBack.query) {
      this.routeEditClient.query = this.routeBack.query;
    }
  },
};
</script>
