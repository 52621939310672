<template>
  <div>
    <div class="row">
      <SmartVSelect
        v-model="selectedEmployeeId"
        :settings-remote-search="settingsSelectEmployee"
        class="col"
        placeholder="Укажите сотрудника"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отмена
      </CamsButton>
      <CamsButton priority="primary" type="button" @click="addEmployeeToDeviceAccessGroup()">
        Добавить
      </CamsButton>
    </div>

    <SpinnerLoading v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {ACTION_ADD_EMPLOYEE_GROUPS, ACTION_LOAD_EMPLOYEES_FOR_SELECT, FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";

/**
 * Компонент диалога добавления сотрудника к группе доступа.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Идентификатор группы доступа.
     */
    deviceAccessGroupId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор компании.
     */
    gangId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
      selectedEmployeeId: null,
      settingsSelectEmployee: {
        action: `employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: FIELDS_EMPLOYEE.id,
        labelField: FIELDS_EMPLOYEE.title,
        searchParams: {gangId: this.gangId}
      },
    };
  },
  methods: {
    /**
     * Обработка поля выбора камеры для добавления ее в группу.
     */
    async addEmployeeToDeviceAccessGroup() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`employees/${ACTION_ADD_EMPLOYEE_GROUPS}`, {
          employeeIds: [this.selectedEmployeeId],
          deviceAccessGroupId: this.deviceAccessGroupId,
        });
        this.closeDialog(true);
      } catch {
        this.$camsdals.alert("Ошибка при добавлении сотрудника к группе");
      }
      this.isLoading = false;
    },
  },
};
</script>
