<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Автомобиль
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.number)"
                  class="row"
                >
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.number]"
                    :caption="titlesFields[fieldsEntity.number]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.number)"
                    :error="errorsFormEdit[fieldsEntity.number]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.employee_id)"
                  class="row"
                >
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.employee_id]"
                    :caption="titlesFields[fieldsEntity.employee_id]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.employee_id)"
                    :error="errorsFormEdit[fieldsEntity.employee_id]"
                    :initial-options="initialOptionsEmployee"
                    :settings-remote-search="settingsSelects[fieldsEntity.employee_id]"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.gang_id)" class="row">
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.gang_id]"
                    :caption="titlesFields[fieldsEntity.gang_id]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.gang_id)"
                    :error="errorsFormEdit[fieldsEntity.gang_id]"
                    :initial-options="initialOptionsGangs"
                    :settings-remote-search="settingsSelectsGangs[fieldsEntity.gang_id]"
                    class="col"
                  />
                </div>
                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.EMPLOYEE)"
                        type="button"
                        @click="deleteCar()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-2">
                <p v-if="routeGang">
                  <strong>
                    <router-link :to="routeGang">Посмотреть информацию по компании</router-link>
                  </strong>
                </p>
                <p v-if="routeEmployee">
                  <strong>
                    <router-link :to="routeEmployee">Посмотреть информацию по сотруднику</router-link>
                  </strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_CARS, ROUTE_EDIT_EMPLOYEE, ROUTE_EDIT_GANG} from "@/router/names.js";
import {
  ACTION_DELETE_CAR,
  ACTION_EDIT_CAR,
  ACTION_LOAD_CAR_FOR_EDIT,
  EXTRAS_CAR,
  FIELDS_CAR,
  TITLES_FIELDS_CAR,
} from "@/store/pacs/cars/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент страницы редактирования автомобиля.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const carId = Number(this.$route.params.carId);
    return {
      routeBack: {name: ROUTE_CARS},
      fieldsEntity: FIELDS_CAR,
      titlesFields: TITLES_FIELDS_CAR,
      entityId: carId,
      nameActionLoadDataForEdit: `cars/${ACTION_LOAD_CAR_FOR_EDIT}`,
      nameActionEdit: `cars/${ACTION_EDIT_CAR}`,
      deletedField: FIELDS_CAR.is_deleted,
      initialOptionsEmployee: [],
      initialOptionsGangs: [],
      settingsSelectsGangs: {
        [FIELDS_CAR.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      },
      settingsSelects: {
        [FIELDS_CAR.employee_id]: {
          action: `employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
          valueField: FIELDS_EMPLOYEE.id,
          labelField: FIELDS_EMPLOYEE.title,
        },
      },
      routeGang: null,
      routeEmployee: null,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготавливаются начальные опции для выпадающих списков.
      this.initialOptionsGangs = this.sourceData.extraInfo[EXTRAS_CAR.gang];
      this.initialOptionsEmployee = this.sourceData.extraInfo[EXTRAS_CAR.employee];
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.GANG) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.EMPLOYEE, FIELDS_EMPLOYEE.gang_id)) {
        const gangId = this.sourceData.entityInfo[FIELDS_CAR.gang_id];
        this.routeGang = gangId ? {name: ROUTE_EDIT_GANG, params: {gangId}} : null;
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.EMPLOYEE) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.EMPLOYEE, FIELDS_EMPLOYEE.id)) {
        const employeeId = this.sourceData.entityInfo[FIELDS_CAR.employee_id];
        this.routeEmployee = employeeId ? {name: ROUTE_EDIT_EMPLOYEE, params: {employeeId}} : null;
      }
    },
    /**
     * Отправка запроса на удаление текущего автомобиля.
     */
    deleteCar() {
      this.$camsdals.confirm("Хотите удалить этот автомобиль?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`cars/${ACTION_DELETE_CAR}`, {carIds: [this.entityId]});
          this.$camsdals.alert("Автомобиль удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении автомобиля");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
