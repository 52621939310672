<template>
  <div>
    <l-map
      ref="map"
      :center="centerMap"
      :options="settingsMap.options"
      :zoom="settingsMap.defaultZoom"
      @click="placeMarker"
      @update:center="changeCenterMap"
    >
      <l-tile-layer attribution="&copy; Участники <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>" :url="mapTilesUrl" />
      <l-marker
        v-if="hasMarkerPosition"
        :draggable="!disabledMoveMarker"
        :lat-lng="markerPosition"
        :visible="true"
        :icon="getIconForEdit()"
        @update:latLng="changeMarkerPosition"
      />
      <l-control :position="'topleft'">
        <button
          v-if="!disabledMoveMarker"
          class="button button_medium button_icon"
          type="button"
          @click.exact="isEditableMarker = !isEditableMarker"
          @click.stop="placeMarker"
        >
          <svg v-show="isEditableMarker">
            <use
              xlink:href="@/assets/img/icons.svg#icon-marker"
              style="width: 30px; height: 30px"
            />
          </svg>
          <svg
            v-show="!isEditableMarker"
            style="width: 30px; height: 30px"
          >
            <use xlink:href="@/assets/img/icons.svg#icon-marker-active" />
          </svg>
        </button>
      </l-control>
    </l-map>
  </div>
</template>

<script>
import {LControl, LMap, LMarker, LTileLayer} from "vue2-leaflet";
import {mapMixin} from "@/utils/mixins.js";
import {MAP_TILES_URL} from "@/utils/consts.js";
import {icon} from "leaflet";
/**
 * Компонент отображения карты для редактирования камеры.
 *
 * Карта позволяет отобразить 1 маркер на карте, менять его координаты и передавать их (при изменении)
 * родительскому компоненту через `@change-marker-position`,
 * которое вернет в качестве результата широту и долготу.
 *
 * Из родительского компонента так же можно передать начальные координаты маркера,
 * и при получении широты и долготы он отрисуется и отцентрирует на нем карту.
 */
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
  },
  mixins: [
    mapMixin
  ],
  props: {
    markerId: {
      type: Number,
      default: null
    },
    initialMarkerLatitude: {
      type: [String, Number],
      default: null,
    },
    initialMarkerLongitude: {
      type: [String, Number],
      default: null,
    },
    disabledMoveMarker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapTilesUrl: MAP_TILES_URL,
      markerPosition: [null, null],
      isEditableMarker: false,
    };
  },
  computed: {
    /**
     * Вернет true если есть четкие координаты для маркера (должны быть оба числа).
     *
     * @return {Boolean}
     */
    hasMarkerPosition() {
      return Number.isFinite(this.markerPosition[0]) && Number.isFinite(this.markerPosition[1]);
    },
    /**
     * Нормализация широты из родительского компонента.
     *
     * @return {Number|NaN}
     */
    normalizeInitialMarkerLatitude() {
      return parseFloat(this.initialMarkerLatitude);
    },
    /**
     * Нормализация долготы из родительского компонента.
     *
     * @return {Number|NaN}
     */
    normalizeInitialMarkerLongitude() {
      return parseFloat(this.initialMarkerLongitude);
    },
  },
  watch: {
    /**
     * Изменение нормализованной широты у маркера из родительского компонента.
     */
    normalizeInitialMarkerLatitude() {
      this.debouncedChangePositionMarkerFromParent();
    },
    /**
     * Изменение нормализованной долготы у маркера из родительского компонента.
     */
    normalizeInitialMarkerLongitude() {
      this.debouncedChangePositionMarkerFromParent();
    }
  },
  /**
   * Регистрируется отложенное выполнение изменения позиции маркера для случаев их изменения из родительского компонента.
   * Одновременно с эта функция вызывается незамедлительно для применения настроек маркера при создании текущего компонента.
   */
  created() {
    this.debouncedChangePositionMarkerFromParent = _.debounce(this.changePositionMarkerFromParent, 1000);
    this.changePositionMarkerFromParent();
  },
  methods: {
    /**
     * @returns {Object|*} Метод вернет иконку для редактируемой камеры.
     */
    getIconForEdit() {
      if (this.markerId != null) {
        return icon({
          iconRetinaUrl: `${window.location.origin}/admin/api/v0/markers/${this.markerId}-retina.img`,
          iconUrl: `${window.location.origin}/admin/api/v0/markers/${this.markerId}-main.img`,
          shadowUrl: `${window.location.origin}/admin/api/v0/markers/${this.markerId}-shadow.img`,
          iconSize: [55, 57],
          iconAnchor: [29, 43],
          popupAnchor: [0, -47],
          shadowAnchor: [12, 39],
        });
      }
      return null;
    },
    /**
     * Функция для фиксации изменений положения маркера, и проброса `@change-marker-position`.
     *
     * @param {{lat: Number, lng: Number}|Array} newPositionMarker
     */
    changeMarkerPosition(newPositionMarker) {
      const [latitude, longitude] = this.extractCoordinates(newPositionMarker);
      this.$emit("change-marker-position", [latitude, longitude]);
      this.markerPosition = [latitude, longitude];
    },
    /**
     * Функция связана с кликом по карте чтобы отрисовать маркер в указанном месте,
     * в случае если включен режим редактирования маркера.
     *
     * fixme при клике на кнопки (свои Control) на карте тоже вызывается это событие,
     * пока не удалось нормально предотвращать распространение клика по карте на дочерние элементы,
     * но через @click.stop на кнопке удалось разделить возникаемые события,
     * и таким образом в эту функцию передаются разные объекты в случаях клика по карте и по кнопке,
     * поэтому внутри функции уже проверяется наличие информации актуальной при клике по карте,
     * остальные надо игнорировать.
     *
     * @param {MouseEvent} dataMouseEvent
     */
    placeMarker(dataMouseEvent) {
      if (this.isEditableMarker && dataMouseEvent.latlng) {
        this.changeMarkerPosition(dataMouseEvent.latlng);
        this.isEditableMarker = false;
      }
    },
    /**
     * Изменение позиции маркера если нормализованные значения для маркера, пришедшие из родительского компонента,
     * не совпадают с тем что были установлены в нем ранее.
     *
     * В случае если из родительского компонента пришли не нормальные числа - обнуляем позицию маркера в null,
     * таким образом по нашей логике мы его уберем из карты, при этом карта не будет менять своего вида.
     *
     * В нормальном случае маркер изменит свою позицию и отцентрует по нему карту.
     */
    changePositionMarkerFromParent() {
      if (!Number.isFinite(this.normalizeInitialMarkerLatitude) || !Number.isFinite(this.normalizeInitialMarkerLongitude)) {
        this.markerPosition = [null, null];
        return;
      }

      if ((this.normalizeInitialMarkerLatitude !== this.markerPosition[0]) || (this.normalizeInitialMarkerLongitude !== this.markerPosition[1])) {
        this.changeCenterMap([this.normalizeInitialMarkerLatitude, this.normalizeInitialMarkerLongitude]);
        this.changeMarkerPosition([this.normalizeInitialMarkerLatitude, this.normalizeInitialMarkerLongitude]);
      }
    },
  },
};
</script>
