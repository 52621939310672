<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Группа доступа
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE_ACCESS_GROUP, fieldsEntity.title)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.title]"
                    :caption="titlesFields[fieldsEntity.title]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE_ACCESS_GROUP, fieldsEntity.title)"
                    :error="errorsFormEdit[fieldsEntity.title]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE_ACCESS_GROUP, fieldsEntity.about)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.about]"
                    :caption="titlesFields[fieldsEntity.about]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE_ACCESS_GROUP, fieldsEntity.about)"
                    :error="errorsFormEdit[fieldsEntity.about]"
                    class="col"
                    input-type="area"
                    maxlength="200"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.DEVICE_ACCESS_GROUP)"
                        type="button"
                        @click="deleteDeviceAccessGroup()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsCamera.DEVICE_ACCESS_GROUP_ids)"
                        type="button"
                        @click="openCreateDeviceAccessGroupDialog()"
                      >
                        Добавить сотрудника в группу
                      </CamsButton>
                    </div>
                  </div>
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)"
                        type="button"
                        @click="openDialogSetPermissions()"
                      >
                        Изменить права на устройства
                      </CamsButton>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="routeGang">
                  <strong>
                    <router-link :to="routeGang">Посмотреть информацию по компании</router-link>
                  </strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE_ACCESS_GROUP, fieldsEntity.cameras_count)">
                  <strong>
                    <router-link :to="routeEmployeesInGroup">
                      Посмотреть всех сотрудников в этой группе ({{
                        sourceData.entityInfo[fieldsEntity.employee_count]
                      }})
                    </router-link>
                  </strong>
                </p>

                <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)">
                  Количество записей прав для группы доступа:
                  <strong>{{ countPermissionsByDevices }}</strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ACTION_DELETE_DEVICE_ACCESS_GROUP,
  ACTION_EDIT_DEVICE_ACCESS_GROUP,
  ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT,
  FIELDS_DEVICE_ACCESS_GROUP,
  TITLES_FIELDS_DEVICE_ACCESS_GROUP
} from "@/store/pacs/deviceAccessGroups/index.js";
import {ROUTE_DEVICE_ACCESS_GROUPS, ROUTE_EDIT_GANG, ROUTE_EMPLOYEES} from "@/router/names.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import SetPermissionsForDeviceAccessGroupsDialog from "@/components/pacs/pacsPermissons/SetPermissionsForDeviceAccessGroupsDialog.vue";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {FIELDS_CAMERA} from "@/store/cameras/index.js";
import {FILTERS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import AddEmployeeToDeviceAccessGroupDialog from "@/components/pacs/deviceAccessGroups/AddEmployeeToDeviceAccessGroupDialog.vue";
import {ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP} from "@/store/pacs/pacsPermissions/index.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент страницы редактирования группы доступа.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const deviceAccessGroupId = Number(this.$route.params.deviceAccessGroupId),
          paramsRouteEmployeesInGroup = new TableQueryParams({filters: [new FilterData(FILTERS_EMPLOYEE.device_access_group_id, "=", [deviceAccessGroupId])]}),
          routeEmployeesInGroup = {
            name: ROUTE_EMPLOYEES,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteEmployeesInGroup.stringify()}
          };

    return {
      routeBack: {name: ROUTE_DEVICE_ACCESS_GROUPS},
      fieldsEntity: FIELDS_DEVICE_ACCESS_GROUP,
      titlesFields: TITLES_FIELDS_DEVICE_ACCESS_GROUP,
      entityId: deviceAccessGroupId,
      nameActionLoadDataForEdit: `deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT}`,
      nameActionEdit: `deviceAccessGroups/${ACTION_EDIT_DEVICE_ACCESS_GROUP}`,
      deletedField: FIELDS_DEVICE_ACCESS_GROUP.is_deleted,
      fieldsCamera: FIELDS_CAMERA,
      settingsSelects: {
        [FIELDS_DEVICE_ACCESS_GROUP.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      },
      countPermissionsByDevices: 0,
      routeGang: null,
      routeEmployeesInGroup: routeEmployeesInGroup,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      const gangId = this.sourceData.entityInfo[FIELDS_DEVICE_ACCESS_GROUP.gang_id];
      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.GANG) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.DEVICE_ACCESS_GROUP, FIELDS_DEVICE_ACCESS_GROUP.gang_id)) {
        this.routeGang = gangId ? {name: ROUTE_EDIT_GANG, params: {gangId}} : null;
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)) {
        this.countPermissionsByDevices = await this.$store.dispatch(`pacsPermissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP}`, {deviceAccessGroupId: this.entityId});
      }
    },
    /**
     * Отправка запроса на удаление текущей группы доступа к устройствам.
     */
    deleteDeviceAccessGroup() {
      this.$camsdals.confirm("Хотите удалить эту группу доступа?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`deviceAccessGroups/${ACTION_DELETE_DEVICE_ACCESS_GROUP}`, {deviceAccessGroupIds: [this.entityId]});
          this.$camsdals.alert("Группа доступа удалена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении группы доступа");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открытие формы для изменения прав устройств на группу сотрудников.
     */
    openDialogSetPermissions() {
      this.$camsdals.open(
        SetPermissionsForDeviceAccessGroupsDialog,
        {deviceAccessGroupId: this.entityId, gangId: this.sourceData.entityInfo[FIELDS_DEVICE_ACCESS_GROUP.gang_id]},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие диалогового окна для добавления сотрудника в текущую группу.
     */
    openCreateDeviceAccessGroupDialog() {
      this.$camsdals.open(
        AddEmployeeToDeviceAccessGroupDialog,
        {deviceAccessGroupId: this.entityId, gangId: this.sourceData.entityInfo[FIELDS_DEVICE_ACCESS_GROUP.gang_id]},
        {dialogTitle: `Добавление сотрудника в группу "${this.sourceData.entityInfo[FIELDS_DEVICE_ACCESS_GROUP.title]}"`},
        {closeOnBackdrop: false, onClose: (needReload) => needReload ? this.loadSourceData() : null}
      );
    },
  },
};
</script>
