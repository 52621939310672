<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.name]"
        :caption="titlesFields[fieldsEntity.name]"
        :error="errorsFormEdit[fieldsEntity.name]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.dvr_hours]"
        :caption="titlesFields[fieldsEntity.dvr_hours]"
        :error="errorsFormEdit[fieldsEntity.dvr_hours]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.dvr_size]"
        :caption="titlesFields[fieldsEntity.dvr_size]"
        :error="errorsFormEdit[fieldsEntity.dvr_size]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.max_sessions]"
        :caption="titlesFields[fieldsEntity.max_sessions]"
        :error="errorsFormEdit[fieldsEntity.max_sessions]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_TARIFF} from "@/router/names.js";
import {ACTION_CREATE_TARIFF, FIELDS_TARIFF, TITLES_FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания тарифа.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `tariffs/${ACTION_CREATE_TARIFF}`,
      fieldsEntity: FIELDS_TARIFF,
      titlesFields: TITLES_FIELDS_TARIFF,
      formEdit: {
        [FIELDS_TARIFF.name]: "",
        [FIELDS_TARIFF.dvr_hours]: null,
        [FIELDS_TARIFF.dvr_size]: null,
        [FIELDS_TARIFF.max_sessions]: null,
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование тарифа.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_TARIFF, params: {tariffId: newEntity[FIELDS_TARIFF.id]}};
    },
  },
};
</script>
