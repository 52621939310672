<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Устройства доступа
        </router-link>
        <router-link :to="{name: routes.ROUTE_EMPLOYEES}" class="tabs__item">
          Сотрудники
        </router-link>
        <router-link :to="{ name: routes.ROUTE_DEVICE_ACCESS_GROUPS }" class="tabs__item">
          Группы доступа
        </router-link>
        <router-link :to="{ name: routes.ROUTE_CARS }" class="tabs__item">
          Автомобили
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateDevice"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_DEVICES_FOR_TABLE,
  ACTION_LOAD_INFO_DEVICES,
  EXTRAS_DEVICE,
  FIELDS_DEVICE,
  FILTERS_DEVICE,
  TITLES_FIELDS_DEVICE,
  TYPES_FIELDS_DEVICE,
} from "@/store/pacs/devices/index.js";
import {
  ROUTE_CAMERAS,
  ROUTE_CARS,
  ROUTE_DEVICE_ACCESS_GROUPS,
  ROUTE_DEVICES,
  ROUTE_EDIT_DEVICE,
  ROUTE_EDIT_GANG,
  ROUTE_EMPLOYEES,
} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateDeviceDialog from "@/components/pacs/devices/CreateDeviceDialog.vue";
import {FIELDS_GANG} from "@/store/gangs/index.js";
import {FIELDS_CAMERA, FILTERS_CAMERA} from "@/store/cameras/index.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по устройствам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_EMPLOYEES,
        ROUTE_DEVICE_ACCESS_GROUPS,
        ROUTE_CARS,
      },
      defaultFieldsTableForView: [
        FIELDS_DEVICE.id,
        FIELDS_DEVICE.title,
        FIELDS_DEVICE.camera_numbers,
        FIELDS_DEVICE.camera_count,
        FIELDS_DEVICE.ident,
        FIELDS_DEVICE.model,
        FIELDS_DEVICE.gang_id,
        FIELDS_DEVICE.emergency_vehicle_access,
        FIELDS_DEVICE.is_active,
      ],
      currentRoute: ROUTE_DEVICES,
      rawFields: FIELDS_DEVICE,
      columnCaptions: TITLES_FIELDS_DEVICE,
      nameActionLoadDataForTable: `devices/${ACTION_LOAD_DEVICES_FOR_TABLE}`,
      nameActionLoadInfoForTable: `devices/${ACTION_LOAD_INFO_DEVICES}`,
      defaultFilters: [
        new FilterData(FILTERS_DEVICE.is_deleted, "=", [false])
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_DEVICE.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_DEVICE.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_DEVICE.camera]) {
        storedExtraInfo.cameras = {};
        sourceExtraInfo[EXTRAS_DEVICE.camera].forEach((cameraInfo) => {
          storedExtraInfo.cameras[cameraInfo[FIELDS_CAMERA.number]] = {
            number: cameraInfo[FIELDS_CAMERA.number],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};
      let params;
      switch (nameField) {
      case FIELDS_DEVICE.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_DEVICE, params: {deviceId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_DEVICE.title:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_DEVICE, params: {deviceId: rowData[FIELDS_DEVICE.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_DEVICE.camera_numbers:
        readyTranslation = _.chain(storedExtraInfo.cameras)
          .pick(rawTranslation)
          .mapValues("number")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA.number, "in", [rowData[FIELDS_DEVICE.camera_numbers]])]});
        readyParams = {name: ROUTE_CAMERAS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_DEVICE.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_DEVICE[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания устройства.
     */
    openDialogCreateDevice() {
      this.$camsdals.open(CreateDeviceDialog, {}, {dialogTitle: "Создание устройства"});
    },
  },
};
</script>
