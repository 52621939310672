<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.camera_number]"
        :caption="titlesFields[fieldsEntity.camera_number]"
        :error="errorsFormEdit[fieldsEntity.camera_number]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.push_domain]"
        :caption="titlesFields[fieldsEntity.push_domain]"
        :error="errorsFormEdit[fieldsEntity.push_domain]"
        class="col"
      />
    </div>
    <div class="row">
      <div class="col">
        <div class="param">
          <div class="param__name">
            Выберите тип
          </div>
          <div class="radiobuttons-group">
            <div class="radiobutton">
              <input
                id="chooseCluster"
                v-model="chooseCluster"
                :value="true"
                class="radiobutton__input"
                name="type"
                type="radio"
                @click="choiceClusterServer"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseCluster">Кластер</label>
            </div>
            <div class="radiobutton">
              <input
                id="chooseServer"
                v-model="chooseCluster"
                :value="false"
                class="radiobutton__input"
                name="type"
                type="radio"
                @click="choiceClusterServer"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseServer">Сервер</label>
            </div>
          </div>
          <div v-show="errorChoiceClusterServer" class="error" v-html="errorChoiceClusterServer" />
        </div>
      </div>
    </div>

    <div v-show="chooseCluster" class="row">
      <SmartVSelect
        v-model="formEdit.analytic_cluster_id"
        :error="errorsFormEdit.analytic_cluster_id"
        :settings-remote-search="settingsSelects.clusterId"
        caption="Кластер"
        class="col"
        @input="errorChoiceClusterServer = ''"
      />
    </div>

    <div v-show="!chooseCluster" class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.analytic_server_id]"
        :caption="titlesFields[fieldsEntity.analytic_server_id]"
        :error="errorsFormEdit[fieldsEntity.analytic_server_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.analytic_server_id]"
        class="col"
        @input="errorChoiceClusterServer = ''"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.min_confidence]"
        :caption="titlesFields[fieldsEntity.min_confidence]"
        :error="errorsFormEdit[fieldsEntity.min_confidence]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartSelect
        v-model="formEdit[fieldsEntity.country]"
        :options="countryForSelect"
        caption="Страна"
        width="fill"
      />
    </div>
    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_CAR_NUMBER} from "@/router/names.js";
import {
  ACTION_CREATE_CAR_NUMBER, COUNTRY_LIST,
  FIELDS_CAR_NUMBER,
  TITLES_FIELDS_CAR_NUMBER
} from "@/store/analytics/carNumbers/index.js";
import {ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT, FIELDS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_ANALYTIC_CLUSTERS_FOR_SELECT, FIELDS_ANALYTIC_CLUSTER} from "@/store/analytics/analyticClusters/index.js";

/**
 * Компонент диалога создания настройки аналитики распознавания номеров.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `analytics/carNumbers/${ACTION_CREATE_CAR_NUMBER}`,
      fieldsEntity: FIELDS_CAR_NUMBER,
      titlesFields: TITLES_FIELDS_CAR_NUMBER,
      countryForSelect: COUNTRY_LIST,
      analytic_cluster_id: null,
      formEdit: {
        [FIELDS_CAR_NUMBER.camera_number]: "",
        [FIELDS_CAR_NUMBER.push_domain]: "",
        [FIELDS_CAR_NUMBER.min_confidence]: "",
        [FIELDS_CAR_NUMBER.analytic_server_id]: null,
      },
      chooseCluster: true,
      errorChoiceClusterServer: "",
      settingsSelects: {
        [FIELDS_CAR_NUMBER.analytic_server_id]: {
          action: `analytics/servers/${ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_ANALYTIC_SERVER.id,
          labelField: FIELDS_ANALYTIC_SERVER.front_id
        },
        clusterId: {
          action: `analytics/analyticClusters/${ACTION_LOAD_ANALYTIC_CLUSTERS_FOR_SELECT}`,
          valueField: FIELDS_ANALYTIC_CLUSTER.id,
          labelField: FIELDS_ANALYTIC_CLUSTER.name
        },
      },
    };
  },
  methods: {
    getDataForCreateEntity() {
      const cameraInfo = Object.assign({}, this.formEdit);
      this.chooseCluster ? (cameraInfo[FIELDS_CAR_NUMBER.analytic_server_id] = null) : (cameraInfo.analytic_cluster_id = null);
      return cameraInfo;
    },
    /**
     * Перенаправление на редактирование настройки аналитики распознавания номеров.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_CAR_NUMBER, params: {cameraNumber: newEntity[FIELDS_CAR_NUMBER.camera_number]}};
    },
    choiceClusterServer(eventClick) {
      this.errorChoiceClusterServer = "";
      if (this.formEdit.analytic_cluster_id || this.formEdit[FIELDS_CAR_NUMBER.analytic_server_id]) {
        const titleFieldForEscape = this.formEdit.analytic_cluster_id ?
          "Кластер" : this.titlesFields[FIELDS_CAR_NUMBER.analytic_server_id];

        this.errorChoiceClusterServer = `Для изменения типа очистите поле "<b>${titleFieldForEscape}</b>"`;
        eventClick.preventDefault();
      }
    },
  },
};
</script>
