/**
 * Перечень доступных аналитик по камерам.
 * Названия возвращаются в виде массива для {@see FIELDS_CAMERA.analytics}.
 */
export const ANALYTICS = Object.freeze({
  thermal_vision: "thermal_vision",
  car_number: "car_number",
  face_recognition: "face_recognition",
  helmet: "helmet",
  motion_alarm: "motion_alarm",
  mask: "mask",
  crowd: "crowd",
  perimeter_security: "perimeter_security",
  people_counter: "people_counter",
});

/**
 * Набор нулевых данных по зоне при создании аналитики. См. названия полей аналитики.
 * Поля обязательны при создании аналитики, но в момент ее создания нет подходящей возможности нарисовать различные фигуры.
 * Поэтому используется такая заглушка.
 */
export const ZERO_ZONE_FOR_CREATE = {
  image_height: 0,
  image_width: 0,
};
/**
 * Заглушка для аналитики подсчета посетителей
 */
export const ZERO_ZONE_FOR_CREATE_PEOPLE_COUNT = {
  image_height: 455,
  image_width: 810,
  counter_line: [
    {
      "x": 493,
      "y": 170
    },
    {
      "x": 410,
      "y": 365
    }
  ],
  enter_point: {
    "x": 334,
    "y": 247
  },
};
