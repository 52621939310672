/**
 * Отдельный vuex модуль для взаимодействия по pacsPermissions-API
 */

import {SORT_DIRECTIONS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";

// actions для pacs_permissions. Вызов действия начинать с "pacsPermissions/"
export const ACTION_LOAD_INFO_PERMISSIONS_DEVICE_ACCESS_GROUPS = "LOAD_INFO_PERMISSIONS_DEVICE_ACCESS_GROUPS";
export const ACTION_LOAD_PERMISSIONS_DEVICE_ACCESS_GROUPS = "LOAD_PERMISSIONS_DEVICE_ACCESS_GROUPS";
export const ACTION_LOAD_PERMISSIONS_DEVICE_ACCESS_GROUP_FOR_EDIT = "LOAD_PERMISSIONS_DEVICE_ACCESS_GROUP_FOR_EDIT";
export const ACTION_ADD_PERMISSIONS_DEVICE_ACCESS_GROUPS_BY_FORM = "ADD_PERMISSIONS_DEVICE_ACCESS_GROUPS_BY_FORM";
export const ACTION_DELETE_PERMISSIONS_DEVICE_ACCESS_GROUPS = "DELETE_PERMISSIONS_DEVICE_ACCESS_GROUPS";
export const ACTION_SET_PERMISSIONS_DEVICE_ACCESS_GROUPS = "SET_PERMISSIONS_DEVICE_ACCESS_GROUPS";
export const ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP = "GET_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP";

export const ACTION_LOAD_INFO_PERMISSIONS_EMPLOYEES = "LOAD_INFO_PERMISSIONS_EMPLOYEES";
export const ACTION_LOAD_PERMISSIONS_EMPLOYEES = "LOAD_PERMISSIONS_EMPLOYEES";
export const ACTION_LOAD_PERMISSIONS_EMPLOYEE_FOR_EDIT = "LOAD_PERMISSIONS_EMPLOYEE_FOR_EDIT";
export const ACTION_ADD_PERMISSIONS_EMPLOYEES_BY_FORM = "ADD_PERMISSIONS_EMPLOYEES";
export const ACTION_DELETE_PERMISSIONS_EMPLOYEES = "DELETE_PERMISSIONS_EMPLOYEES";
export const ACTION_SET_PERMISSIONS_EMPLOYEES = "SET_PERMISSIONS_EMPLOYEES";
export const ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE = "GET_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_PERMISSION_DEVICE_ACCESS_GROUP = Object.freeze({
  access_type: "access_type",
  is_auto: "is_auto",
  device_access_group_id: "device_access_group_id",
  device_id: "device_id",
});

export const FIELDS_PERMISSION_EMPLOYEE = Object.freeze({
  access_type: "access_type",
  is_auto: "is_auto",
  employee_id: "employee_id",
  device_id: "device_id",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_PERMISSION_DEVICE_ACCESS_GROUP = Object.freeze({
  device_access_group_id: "device_access_group_id",
  device_id: "device_id",
});

export const FILTERS_PERMISSION_EMPLOYEE = Object.freeze({
  employee_id: "employee_id",
  device_id: "device_id",
});

/**
 * Перечисления опций для загрузки дополнительной информации вместе с правами.
 */
export const EXTRAS_PERMISSION_DEVICE_ACCESS_GROUP = Object.freeze({
  device: "device",
  device_access_group: "device_access_group",
});

export const EXTRAS_PERMISSION_EMPLOYEE = Object.freeze({
  device: "device",
  employee: "employee",
});

const EXTRAS_BY_FIELDS_PERMISSION_DEVICE_ACCESS_GROUP = Object.freeze({
  [FIELDS_PERMISSION_DEVICE_ACCESS_GROUP.device_id]: [EXTRAS_PERMISSION_DEVICE_ACCESS_GROUP.device],
  [FIELDS_PERMISSION_DEVICE_ACCESS_GROUP.device_access_group_id]: [EXTRAS_PERMISSION_DEVICE_ACCESS_GROUP.device_access_group],
});

const EXTRAS_BY_FIELDS_PERMISSION_EMPLOYEE = Object.freeze({
  [FIELDS_PERMISSION_EMPLOYEE.device_id]: [EXTRAS_PERMISSION_EMPLOYEE.device],
  [FIELDS_PERMISSION_EMPLOYEE.employee_id]: [EXTRAS_PERMISSION_EMPLOYEE.employee],
});

/**
 * Перечисление прав на сотрудников и группы доступа к устройствам (группы сотрудников), для назначения их устройствам.
 */
export const DEVICES_PERMISSIONS = Object.freeze({
  ACCESS: 0,
  ENTRANCE: 1,
  EXIT: 2,
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // -------------------------------------------------------------------------------------------------------------------------------------
    // DeviceAccessGroupsPermission
    /**
     * Загрузка служебной информации для прав.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PERMISSIONS_DEVICE_ACCESS_GROUPS]() {
      const response = await this.getters.privateAjax.post("/v0/pacs_permissions/device_access_groups/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка прав.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_DEVICE_ACCESS_GROUPS](context, {page, pageSize, orderBy, fields, filters, search}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_PERMISSION_DEVICE_ACCESS_GROUP, fields))),
        response = await this.getters.privateAjax.post("/v0/pacs_permissions/device_access_groups/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {Number} deviceAccessGroupId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_DEVICE_ACCESS_GROUP_FOR_EDIT]({dispatch}, {deviceId = null, deviceAccessGroupId = null, search = "", page = 1}) {
      let filter = makeFilterApi(FILTERS_PERMISSION_DEVICE_ACCESS_GROUP.device_id, "=", deviceId);
      if (deviceAccessGroupId) {
        filter = makeFilterApi(FILTERS_PERMISSION_DEVICE_ACCESS_GROUP.device_access_group_id, "=", deviceAccessGroupId);
      }

      return dispatch(ACTION_LOAD_PERMISSIONS_DEVICE_ACCESS_GROUPS, {
        page,
        pageSize: 10,
        orderBy: [],
        fields: [
          FIELDS_PERMISSION_DEVICE_ACCESS_GROUP.device_access_group_id,
          FIELDS_PERMISSION_DEVICE_ACCESS_GROUP.access_type,
          FIELDS_PERMISSION_DEVICE_ACCESS_GROUP.device_id,
          FIELDS_PERMISSION_DEVICE_ACCESS_GROUP.is_auto,
        ],
        filters: [filter],
        search
      });
    },
    /**
     * Выдача новых прав.
     *
     * @param {Object} context
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[deviceId, permission], ...]
     * @param {Boolean} mergePermissions
     */
    async [ACTION_ADD_PERMISSIONS_DEVICE_ACCESS_GROUPS_BY_FORM](context, {deviceAccessGroupId = null, isAuto = false, rawPermissionsInfo, mergePermissions = true}) {
      try {
        await this.getters.privateAjax.post("/v0/pacs_permissions/device_access_groups/add/", {
          device_access_group_id: deviceAccessGroupId,
          is_auto: isAuto,
          access_type: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {device_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          }),
          merge_permissions: mergePermissions
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление прав.
     *
     * @param {Object} context
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     * @param {Array} deviceIds
     */
    async [ACTION_DELETE_PERMISSIONS_DEVICE_ACCESS_GROUPS](context, {deviceAccessGroupId, isAuto, deviceIds}) {
      try {
        await this.getters.privateAjax.post("/v0/pacs_permissions/device_access_groups/delete/", {
          device_access_group_id: deviceAccessGroupId,
          is_auto: isAuto,
          device_ids: deviceIds
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Установка прав.
     *
     * @param {Object} context
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[deviceId, permission], ...]
     */
    async [ACTION_SET_PERMISSIONS_DEVICE_ACCESS_GROUPS](context, {deviceAccessGroupId, isAuto, rawPermissionsInfo}) {
      try {
        await this.getters.privateAjax.post("/v0/pacs_permissions/device_access_groups/set/", {
          device_access_group_id: deviceAccessGroupId,
          is_auto: isAuto,
          access_type: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {device_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          })
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Получение количества установленных прав для группы доступа или устройства.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {Number} deviceAccessGroupId
     * @return {Promise}
     */
    async [ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP]({dispatch}, {deviceId = null, deviceAccessGroupId = null}) {
      let filter;
      if (deviceId) {
        filter = makeFilterApi(FILTERS_PERMISSION_DEVICE_ACCESS_GROUP.device_id, "=", deviceId);
      } else if (deviceAccessGroupId) {
        filter = makeFilterApi(FILTERS_PERMISSION_DEVICE_ACCESS_GROUP.device_access_group_id, "=", deviceAccessGroupId);
      } else {
        return;
      }

      const responseData = await dispatch(ACTION_LOAD_PERMISSIONS_DEVICE_ACCESS_GROUPS, {page: 1, pageSize: 1, filters: [filter]});
      return responseData.count;
    },
    // -------------------------------------------------------------------------------------------------------------------------------------
    // DeviceAccessPermission
    /**
     * Загрузка служебной информации для прав.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_PERMISSIONS_EMPLOYEES]() {
      const response = await this.getters.privateAjax.post("/v0/pacs_permissions/devices/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка прав
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_EMPLOYEES](context, {page, pageSize, orderBy, fields, filters, search}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_PERMISSION_EMPLOYEE, fields))),
        response = await this.getters.privateAjax.post("/v0/pacs_permissions/devices/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {String}  employeeId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_PERMISSIONS_EMPLOYEE_FOR_EDIT]({dispatch}, {deviceId = null, employeeId = null, search = "", page = 1}) {
      let filter = makeFilterApi(FILTERS_PERMISSION_EMPLOYEE.device_id, "=", deviceId);
      if (employeeId) {
        filter = makeFilterApi(FILTERS_PERMISSION_EMPLOYEE.employee_id, "=", employeeId);
      }

      return dispatch(ACTION_LOAD_PERMISSIONS_EMPLOYEES, {
        page,
        pageSize: 10,
        orderBy: [],
        fields: [
          FIELDS_PERMISSION_EMPLOYEE.device_id,
          FIELDS_PERMISSION_EMPLOYEE.employee_id,
          FIELDS_PERMISSION_EMPLOYEE.access_type,
          FIELDS_PERMISSION_EMPLOYEE.is_auto,
        ],
        filters: [filter],
        search
      });
    },
    /**
     * Выдача новых прав.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[deviceId, permission], ...]
     * @param {Boolean} mergePermissions
     */
    async [ACTION_ADD_PERMISSIONS_EMPLOYEES_BY_FORM](context, {employeeId = null, isAuto = false, rawPermissionsInfo, mergePermissions = true}) {
      try {
        await this.getters.privateAjax.post("/v0/pacs_permissions/devices/add/", {
          employee_id: employeeId,
          is_auto: isAuto,
          access_type: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {device_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          }),
          merge_permissions: mergePermissions
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление прав.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Array} deviceIds
     */
    async [ACTION_DELETE_PERMISSIONS_EMPLOYEES](context, {employeeId, isAuto, deviceIds}) {
      try {
        await this.getters.privateAjax.post("/v0/pacs_permissions/devices/delete/", {
          employee_id: employeeId,
          is_auto: isAuto,
          device_ids: deviceIds
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Установка прав.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[deviceId, permission], ...]
     */
    async [ACTION_SET_PERMISSIONS_EMPLOYEES](context, {employeeId, isAuto, rawPermissionsInfo}) {
      try {
        await this.getters.privateAjax.post("/v0/pacs_permissions/devices/set/", {
          employee_id: employeeId,
          is_auto: isAuto,
          permissions: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {device_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          })
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Получение количества установленных прав для сотрудника или устройства.
     *
     * @param {Function} dispatch
     * @param {Number} employeeId
     * @param {String} deviceId
     * @return {Promise}
     */
    async [ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE]({dispatch}, {employeeId = null, deviceId = null}) {
      let filter;
      if (employeeId) {
        filter = makeFilterApi(FILTERS_PERMISSION_EMPLOYEE.employee_id, "=", employeeId);
      } else if (deviceId) {
        filter = makeFilterApi(FILTERS_PERMISSION_EMPLOYEE.device_id, "=", deviceId);
      } else {
        return;
      }

      const responseData = await dispatch(ACTION_LOAD_PERMISSIONS_EMPLOYEES, {page: 1, pageSize: 1, filters: [filter]});
      return responseData.count;
    },
  },
};
