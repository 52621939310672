/**
 * Описание маршрутов приложения.
 *
 * В рамках основного компонента `MainFrame` будет происходит редирект на первую доступную страницу при обращении к корневому URL.
 *
 * В описании маршрутов в разделе мета информации идет описание контроля прав доступа,
 * а ниже, в общем `beforeEach`, эта информация проверяется на основе загруженной информации о правах.
 *
 * Проверка прав доступа на маршруте имеет смысл только при наличии авторизации. Если ее нет, тогда URL остается прежним,
 * но главный компонент покажет страницу авторизации, после прохождения которой только пользователь увидит запрошенную страницу,
 * и то только если будет иметь на это права. Однако в таком случае проверка прав происходит в главном компоненте при получении
 * контекста с правами авторизованного пользователя - на этапе маршрута этого проверить нельзя,
 * т.к. после авторизации URL не меняется (все таки его надо сохранить для легитимных случаев),
 * а значит `beforeEach` не вызывается.
 */

import Vue from "vue";
import VueRouter from "vue-router";
import {Bus as Vuedals} from "vuedals";
import store from "@/store/index.js";
import {ABILITIES_ACTIONS, ABILITIES_SUBJECTS, ability} from "@/store/ability.js";
import {APPLICATION_TITLE} from "@/utils/consts.js";

import Page403 from "@/components/Page403.vue";
import TableCameras from "@/components/cameras/TableCameras.vue";
import TabsEditorCamera from "@/components/cameras/TabsEditorCamera.vue";
import EditCamera from "@/components/cameras/EditCamera.vue";
import EditCameraInventory from "@/components/cameras/EditCameraInventory.vue";
import LogCameraWatchReasonsByCamera from "@/components/cameras/LogCameraWatchReasons.vue";
import LogViewsByCamera from "@/components/cameras/LogViews.vue";
import TableCameraGroups from "@/components/cameraGroups/TableCameraGroups.vue";
import EditCameraGroup from "@/components/cameraGroups/EditCameraGroup.vue";
import TableCameraGroupTypes from "@/components/cameraGroupTypes/TableCameraGroupTypes.vue";
import EditCameraGroupType from "@/components/cameraGroupTypes/EditCameraGroupType.vue";
import TableTariffs from "@/components/tariffs/TableTariffs.vue";
import EditTariff from "@/components/tariffs/EditTariff.vue";
import TableServers from "@/components/heavyMetal/servers/TableServers.vue";
import EditServer from "@/components/heavyMetal/servers/EditServer.vue";
import TableClusters from "@/components/heavyMetal/clusters/TableClusters.vue";
import EditCluster from "@/components/heavyMetal/clusters/EditCluster.vue";
import TableClients from "@/components/users/clients/TableClients.vue";
import TabsEditorClient from "@/components/users/clients/TabsEditorClient.vue";
import EditClient from "@/components/users/clients/EditClient.vue";
import LogViewsByClient from "@/components/users/clients/LogViews.vue";
import LogAuthsByClient from "@/components/users/clients/LogAuths.vue";
import TableAdmins from "@/components/users/admins/TableAdmins.vue";
import TabsEditorAdmin from "@/components/users/admins/TabsEditorAdmin.vue";
import EditAdmin from "@/components/users/admins/EditAdmin.vue";
import LogCameraWatchReasonsByAdmin from "@/components/users/admins/LogCameraWatchReasons.vue";
import LogViewsByAdmin from "@/components/users/admins/LogViews.vue";
import LogAuthsByAdmin from "@/components/users/admins/LogAuths.vue";
import OnMap from "@/components/onMap/OnMap.vue";
import TableDomainSettings from "@/components/domainSettings/TableDomainSettings.vue";
import EditDomainSetting from "@/components/domainSettings/EditDomainSetting.vue";
import TableRightGroup from "@/components/rightGroups/TableRightGroup.vue";
import EditRightGroup from "@/components/rightGroups/EditRightGroup.vue";
import TableLogAuths from "@/components/log/TableLogAuths.vue";
import TableLogViews from "@/components/log/TableLogViews.vue";
import TableLogCameraWatchReasons from "@/components/log/TableLogCameraWatchReasons.vue";
import EditAnalyticServer from "@/components/analytics/servers/EditAnalyticServer.vue";
import TableAnalyticServers from "@/components/analytics/servers/TableAnalyticServers.vue";
import EditThermalVision from "@/components/analytics/thermalVisions/EditThermalVision.vue";
import TableThermalVisions from "@/components/analytics/thermalVisions/TableThermalVisions.vue";
import EditCarNumber from "@/components/analytics/carNumbers/EditCarNumber.vue";
import TableCarNumbers from "@/components/analytics/carNumbers/TableCarNumbers.vue";
import EditFaceRecognition from "@/components/analytics/faceRecognitions/EditFaceRecognition.vue";
import TableFaceRecognitions from "@/components/analytics/faceRecognitions/TableFaceRecognitions.vue";
import EditHelmet from "@/components/analytics/helmets/EditHelmet.vue";
import TableHelmets from "@/components/analytics/helmets/TableHelmets.vue";
import EditMotionAlarm from "@/components/analytics/motionAlarms/EditMotionAlarm.vue";
import TableMotionAlarms from "@/components/analytics/motionAlarms/TableMotionAlarms.vue";
import TableEmployees from "@/components/pacs/employees/TableEmployees.vue";
import EditEmployee from "@/components/pacs/employees/EditEmployee.vue";
import TableGangs from "@/components/gangs/TableGangs.vue";
import EditGang from "@/components/gangs/EditGang.vue";
import EditCar from "@/components/pacs/cars/EditCar.vue";
import TableCars from "@/components/pacs/cars/TableCars.vue";
import EditDeviceAccessGroup from "@/components/pacs/deviceAccessGroups/EditDeviceAccessGroup.vue";
import TableDeviceAccessGroups from "@/components/pacs/deviceAccessGroups/TableDeviceAccessGroups.vue";
import EditDevice from "@/components/pacs/devices/EditDevice.vue";
import TableDevices from "@/components/pacs/devices/TableDevices.vue";
import EmployeePhoto from "@/components/pacs/employees/EmployeePhoto.vue";
import TabsEditorEmployee from "@/components/pacs/employees/TabsEditorEmployee.vue";
import TableAnalyticServersLogs from "@/components/analytics/logs/TableAnalyticServersLogs.vue";
import TableMarkers from "@/components/markers/TableMarkers.vue";
import EditMarker from "@/components/markers/EditMarker.vue";
import EditMask from "@/components/analytics/masks/EditMask.vue";
import TableMasks from "@/components/analytics/masks/TableMasks.vue";
import TableCrowds from "@/components/analytics/crowds/TableCrowds.vue";
import EditCrowd from "@/components/analytics/crowds/EditCrowd.vue";
import TableHistory from "@/components/cameras/TableHistory.vue";
import TablePerimeterSecurity from "@/components/analytics/perimeterSecurity/TablePerimeterSecurity.vue";
import EditPerimeterSecurity from "@/components/analytics/perimeterSecurity/EditPerimeterSecurity.vue";
import BolidDevices from "@/components/integrations/bolid/BolidDevices/BolidDevices.vue";
import BolidEventsTable from "@/components/integrations/bolid/BolidEventsTable.vue";
import EditBolidServer from "@/components/integrations/bolid/servers/EditBolidServer.vue";
import TableBolidServers from "@/components/integrations/bolid/servers/TableBolidServers.vue";
import TableLogUcamsgo from "@/components/log/TableLogUcamsgo.vue";
import TableAnalyticCarNumberHistory from "@/components/analytics/carNumbers/TableAnalyticCarNumberHistory.vue";
import TableAnalyticCrowdHistory from "@/components/analytics/crowds/TableAnalyticCrowdHistory.vue";
import TableAnalyticFaceRecognitionHistory from "@/components/analytics/faceRecognitions/TableAnalyticFaceRecognitionHistory.vue";
import TableAnalyticHelmetHistory from "@/components/analytics/helmets/TableAnalyticHelmetHistory.vue";
import TableAnalyticMaskHistory from "@/components/analytics/masks/TableAnalyticMaskHistory.vue";
import TableAnalyticPerimeterSecurityHistory from "@/components/analytics/perimeterSecurity/TableAnalyticPerimeterSecurityHistory.vue";
import TableAnalyticMotionAlarmHistory from "@/components/analytics/motionAlarms/TableAnalyticMotionAlarmHistory.vue";
import TableAnalyticThermalVisionHistory from "@/components/analytics/thermalVisions/TableAnalyticThermalVisionHistory.vue";
import TableAnalyticPeopleCountHistory from "@/components/analytics/peopleCounts/TableAnalyticPeopleCountHistory.vue";
import EditPeopleCount from "@/components/analytics/peopleCounts/EditPeopleCount.vue";
import TablePeopleCount from "@/components/analytics/peopleCounts/TablePeopleCount.vue";
import TableDeviceHistory from "@/components/pacs/devices/TableDeviceHistory.vue";
import TableDeviceModuleHistory from "@/components/pacs/devices/TableDeviceModuleHistory.vue";
import TableUserLinksHistory from "@/components/users/clients/TableUserLinksHistory.vue";
import SetupCamera from "@/components/cameras/SetupCamera.vue";
import TableFirmwares from "@/components/firmwares/TableFirmwares.vue";
import EditFirmware from "@/components/firmwares/EditFirmware.vue";
import TableServerHistory from "@/components/heavyMetal/servers/TableServerHistory.vue";
import TableAnalyticServerHistory from "@/components/analytics/servers/TableAnalyticServerHistory.vue";
import TableAnalyticClusters from "@/components/analytics/analyticClusters/TableAnalyticClusters.vue";
import EditAnalyticCluster from "@/components/analytics/analyticClusters/EditAnalyticCluster.vue";
import TableAdminPermissionsToCamera from "@/components/users/admins/TableAdminPermissionsToCamera.vue";
import TableAdminPermissionsToGroup from "@/components/users/admins/TableAdminPermissionsToGroup.vue";
import TableUserPermissionsToCamera from "@/components/users/clients/TableUserPermissionsToCamera.vue";
import TableUserPermissionsToGroup from "@/components/users/clients/TableUserPermissionsToGroup.vue";
import TableCameraHistory from "@/components/cameras/TableCameraHistory.vue";
import TableCamerasMigration from "@/components/cameras/TableCamerasMigration.vue";
import TableReports from "@/components/analytics/reports/TableReports.vue";
import TableRightGroupHistory from "@/components/rightGroups/TableRightGroupHistory.vue";
import {
  ROUTE_403,
  ROUTE_EDIT_ADMIN,
  ROUTE_ADMINS,
  ROUTE_EDIT_CAMERA_GROUP,
  ROUTE_EDIT_CAMERA_GROUP_TYPE,
  ROUTE_CAMERA_GROUP_TYPES,
  ROUTE_CAMERA_GROUPS,
  ROUTE_CAMERAS,
  ROUTE_EDIT_CLIENT,
  ROUTE_CLIENTS,
  ROUTE_EDIT_CLUSTER,
  ROUTE_CLUSTERS,
  ROUTE_EDIT_DOMAIN_SETTING,
  ROUTE_DOMAIN_SETTINGS,
  ROUTE_EDIT_CAMERA,
  ROUTE_EDIT_CAMERA_INVENTORY,
  ROUTE_LOG_CAMERA_WATCH_REASONS_BY_CAMERA,
  ROUTE_LOG_CAMERA_WATCH_REASONS_BY_ADMIN,
  ROUTE_LOG_VIEWS_BY_CAMERA,
  ROUTE_LOG_VIEWS_BY_CLIENT,
  ROUTE_LOG_VIEWS_BY_ADMIN,
  ROUTE_LOG_AUTHS_BY_CLIENT,
  ROUTE_LOG_AUTHS_BY_ADMIN,
  ROUTE_ON_MAP,
  ROUTE_EDIT_RIGHT_GROUP,
  ROUTE_RIGHT_GROUPS,
  ROUTE_ROOT,
  ROUTE_ROOT_CAMERA_GROUPS,
  ROUTE_ROOT_HEAVY_METAL,
  ROUTE_ROOT_USERS,
  ROUTE_EDIT_SERVER,
  ROUTE_SERVERS,
  ROUTE_EDIT_TARIFF,
  ROUTE_TARIFFS,
  ROUTE_ROOT_LOGS,
  ROUTE_LOG_AUTHS,
  ROUTE_LOG_VIEWS,
  ROUTE_LOG_CAMERA_WATCH_REASONS,
  ROUTE_ROOT_ANALYTICS,
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_EDIT_ANALYTIC_SERVER,
  ROUTE_THERMAL_VISIONS,
  ROUTE_EDIT_THERMAL_VISION,
  ROUTE_EDIT_CAR_NUMBER,
  ROUTE_CAR_NUMBERS,
  ROUTE_FACE_RECOGNITIONS,
  ROUTE_EDIT_FACE_RECOGNITION,
  ROUTE_HELMETS,
  ROUTE_EDIT_HELMET,
  ROUTE_MOTION_ALARMS,
  ROUTE_EDIT_MOTION_ALARM,
  ROUTE_EMPLOYEES,
  ROUTE_EDIT_EMPLOYEE,
  ROUTE_GANGS,
  ROUTE_EDIT_GANG,
  ROUTE_CARS,
  ROUTE_EDIT_CAR,
  ROUTE_DEVICES,
  ROUTE_EDIT_DEVICE,
  ROUTE_DEVICE_ACCESS_GROUPS,
  ROUTE_EDIT_DEVICE_ACCESS_GROUP,
  ROUTE_PHOTO_FOR_EMPLOYEE,
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_MARKERS,
  ROUTE_EDIT_MARKER,
  ROUTE_MASKS,
  ROUTE_EDIT_MASK,
  ROUTE_CROWDS,
  ROUTE_EDIT_CROWD,
  ROUTE_HISTORY,
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_EDIT_PERIMETER_SECURITY,
  ROUTE_EDIT_BOLID_SERVER,
  ROUTE_BOLID_EVENTS,
  ROUTE_BOLID_DEVICES,
  ROUTE_BOLID_SERVERS,
  ROUTE_LOG_UCAMSGO,
  ROUTE_VIEW_ANALYTIC_CAR_NUMBER_HISTORY,
  ROUTE_VIEW_ANALYTIC_CROWD_HISTORY,
  ROUTE_VIEW_ANALYTIC_FACE_RECOGNITION_HISTORY,
  ROUTE_VIEW_ANALYTIC_HELMET_HISTORY,
  ROUTE_VIEW_ANALYTIC_MASK_HISTORY,
  ROUTE_VIEW_ANALYTIC_PERIMETER_SECURITY_HISTORY,
  ROUTE_VIEW_ANALYTIC_MOTION_ALARM_HISTORY,
  ROUTE_VIEW_ANALYTIC_THERMAL_VISION_HISTORY,
  ROUTE_VIEW_ANALYTIC_PEOPLE_COUNT_HISTORY,
  ROUTE_EDIT_PEOPLE_COUNT,
  ROUTE_PEOPLE_COUNTS,
  ROUTE_ROOT_ANALYTICS_SETTINGS,
  ROUTE_VIEW_DEVICE_HISTORY,
  ROUTE_VIEW_DEVICE_MODULE_HISTORY,
  ROUTE_VIEW_USER_LINKS_HISTORY,
  ROUTE_CAMERA_SETUP,
  ROUTE_EDIT_FIRMWARE,
  ROUTE_FIRMWARES,
  ROUTE_VIEW_SERVER_HISTORY,
  ROUTE_ANALYTIC_CLUSTERS,
  ROUTE_EDIT_ANALYTIC_CLUSTER,
  ROUTE_VIEW_ANALYTIC_SERVER_HISTORY,
  ROUTE_VIEW_ADMIN_PERMISSIONS_HISTORY,
  ROUTE_VIEW_ADMIN_PERMISSIONS_GROUP_HISTORY,
  ROUTE_VIEW_USER_PERMISSIONS_HISTORY,
  ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY,
  ROUTE_CAMERA_HISTORY,
  ROUTE_MIGRATION,
  ROUTE_ANALYTIC_CAR_NUMBER_REPORT,
  ROUTE_VIEW_RIGHT_GROUP_HISTORY
} from "@/router/names.js";






Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes: [
    {
      path: "/403",
      component: Page403,
      name: ROUTE_403,
      meta: {
        title: `Доступ запрещен — ${APPLICATION_TITLE}`,
      },
    },
    {
      path: "*",
      redirect: {name: ROUTE_ROOT}
    },
    {
      path: "/",
      name: ROUTE_ROOT,
      meta: {
        title: APPLICATION_TITLE,
      },
    },
    {
      path: "/cameras",
      name: ROUTE_CAMERAS,
      component: TableCameras,
      meta: {
        title: `Камеры — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/cameras/edit",
      component: TabsEditorCamera,
      redirect: {name: ROUTE_CAMERAS},
      children: [
        {
          path: "camera/:cameraNumber",
          name: ROUTE_EDIT_CAMERA,
          component: EditCamera,
          meta: {
            title: `Камеры — редактирование — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.CAMERA,
          },
        },
        {
          path: "/cameras/each/history/:cameraNumber",
          name: ROUTE_CAMERA_HISTORY,
          component: TableCameraHistory,
          meta: {
            title: `История по камерам — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.CAMERA,
          },
        },
        {
          path: "camera-inventory/:cameraNumber",
          name: ROUTE_EDIT_CAMERA_INVENTORY,
          component: EditCameraInventory,
          meta: {
            title: `Камеры — редактирование инвентарных данных — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.CAMERA,
          },
        },
        {
          path: "log-camera-watch-reasons/:cameraNumber/:currentPage?",
          name: ROUTE_LOG_CAMERA_WATCH_REASONS_BY_CAMERA,
          component: LogCameraWatchReasonsByCamera,
          meta: {
            title: `Камеры — обоснованный доступ — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_CAMERA_WATCH_REASON,
          },
          props: (route) => ({...route.params, currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-views/:cameraNumber/:currentPage?",
          name: ROUTE_LOG_VIEWS_BY_CAMERA,
          component: LogViewsByCamera,
          meta: {
            title: `Камеры — логи просмотров — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_VIEW,
          },
          props: (route) => ({...route.params, currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "setup/:cameraNumber",
          name: ROUTE_CAMERA_SETUP,
          component: SetupCamera,
          meta: {
            title: `Камеры — управление камерой — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.CAMERA_SETUP,
          },
          props: (route) => ({...route.params, currentPage: parseInt(route.params.currentPage) || undefined}),
        },
      ]
    },
    {
      path: "/cameras/history",
      name: ROUTE_HISTORY,
      component: TableHistory,
      meta: {
        title: `История по камерам — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/cameras/markers",
      name: ROUTE_MARKERS,
      component: TableMarkers,
      meta: {
        title: `Маркеры для камер — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/cameras/markers/marker/:markerId",
      name: ROUTE_EDIT_MARKER,
      component: EditMarker,
      meta: {
        title: `Маркеры для камер — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/cameras/firmwares",
      name: ROUTE_FIRMWARES,
      component: TableFirmwares,
      meta: {
        title: `Прошивки для камер — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/cameras/firmwares/firmware/:firmwareId",
      name: ROUTE_EDIT_FIRMWARE,
      component: EditFirmware,
      meta: {
        title: `Прошивки для камер — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/cameras/migrations",
      name: ROUTE_MIGRATION,
      component: TableCamerasMigration,
      meta: {
        title: `Миграции камер — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/camera-groups",
      name: ROUTE_ROOT_CAMERA_GROUPS,
      redirect: {name: ROUTE_CAMERA_GROUPS},
    },
    {
      path: "/camera-groups/groups",
      name: ROUTE_CAMERA_GROUPS,
      component: TableCameraGroups,
      meta: {
        title: `Группы камер — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA_GROUP,
      },
    },
    {
      path: "/camera-groups/group/:cameraGroupId",
      name: ROUTE_EDIT_CAMERA_GROUP,
      component: EditCameraGroup,
      meta: {
        title: `Группы камер — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA_GROUP,
      },
    },
    {
      path: "/camera-groups/types",
      name: ROUTE_CAMERA_GROUP_TYPES,
      component: TableCameraGroupTypes,
      meta: {
        title: `Типы групп камер — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE,
      },
    },
    {
      path: "/camera-groups/type/:cameraGroupTypeId",
      name: ROUTE_EDIT_CAMERA_GROUP_TYPE,
      component: EditCameraGroupType,
      meta: {
        title: `Типы групп камер — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE,
      },
    },
    {
      path: "/on-map",
      name: ROUTE_ON_MAP,
      component: OnMap,
      meta: {
        title: `Карта — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CAMERA,
      },
    },
    {
      path: "/heavy-metal",
      name: ROUTE_ROOT_HEAVY_METAL,
      redirect: {name: ROUTE_SERVERS},
    },
    {
      path: "/heavy-metal/servers",
      name: ROUTE_SERVERS,
      component: TableServers,
      meta: {
        title: `Серверы — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.SERVER,
      },
    },
    {
      path: "/heavy-metal/servers/history/:serverId",
      name: ROUTE_VIEW_SERVER_HISTORY,
      component: TableServerHistory,
      meta: {
        title: `Серверы  — История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.SERVER,
      },
    },
    {
      path: "/heavy-metal/servers/server/:serverId",
      name: ROUTE_EDIT_SERVER,
      component: EditServer,
      meta: {
        title: `Серверы — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.SERVER,
      },
    },
    {
      path: "/heavy-metal/clusters",
      name: ROUTE_CLUSTERS,
      component: TableClusters,
      meta: {
        title: `Кластеры серверов — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CLUSTER,
      },
    },
    {
      path: "/heavy-metal/clusters/cluster/:clusterId",
      name: ROUTE_EDIT_CLUSTER,
      component: EditCluster,
      meta: {
        title: `Кластеры серверов — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CLUSTER,
      },
    },
    {
      path: "/tariffs",
      name: ROUTE_TARIFFS,
      component: TableTariffs,
      meta: {
        title: `Тарифы — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.TARIFF,
      },
    },
    {
      path: "/tariffs/tariff/:tariffId",
      name: ROUTE_EDIT_TARIFF,
      component: EditTariff,
      meta: {
        title: `Тарифы — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.TARIFF,
      },
    },
    {
      path: "/users",
      name: ROUTE_ROOT_USERS,
      redirect: {name: ROUTE_CLIENTS},
    },
    {
      path: "/users/clients",
      name: ROUTE_CLIENTS,
      component: TableClients,
      meta: {
        title: `Клиенты — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.USER_CLIENT,
      },
    },
    {
      path: "/users/clients/edit",
      component: TabsEditorClient,
      redirect: {name: ROUTE_CLIENTS},
      children: [
        {
          path: "client/:clientId",
          name: ROUTE_EDIT_CLIENT,
          component: EditClient,
          meta: {
            title: `Клиенты — редактирование — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.USER_CLIENT,
          },
        },
        {
          path: "log-views/:clientId/:currentPage?",
          name: ROUTE_LOG_VIEWS_BY_CLIENT,
          component: LogViewsByClient,
          meta: {
            title: `Клиенты — логи просмотров — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_VIEW,
          },
          props: (route) => ({clientId: parseInt(route.params.clientId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-auths/:clientId/:currentPage?",
          name: ROUTE_LOG_AUTHS_BY_CLIENT,
          component: LogAuthsByClient,
          meta: {
            title: `Клиенты — логи авторизаций — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({clientId: parseInt(route.params.clientId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-user-links/:clientId/:currentPage?",
          name: ROUTE_VIEW_USER_LINKS_HISTORY,
          component: TableUserLinksHistory,
          meta: {
            title: `Клиенты — логи мультиаккаунтинга — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({clientId: parseInt(route.params.clientId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-permission-camera/:clientId/:currentPage?",
          name: ROUTE_VIEW_USER_PERMISSIONS_HISTORY,
          component: TableUserPermissionsToCamera,
          meta: {
            title: `Персонал — логи прав на камеру — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({clientId: parseInt(route.params.clientId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-permission-group/:clientId/:currentPage?",
          name: ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY,
          component: TableUserPermissionsToGroup,
          meta: {
            title: `Персонал — логи прав на группы — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({clientId: parseInt(route.params.clientId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
      ]
    },
    {
      path: "/users/admins",
      name: ROUTE_ADMINS,
      component: TableAdmins,
      meta: {
        title: `Персонал — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.USER_ADMIN,
      },
    },
    {
      path: "/users/admins/edit",
      component: TabsEditorAdmin,
      redirect: {name: ROUTE_CLIENTS},
      children: [
        {
          path: "admin/:adminId",
          name: ROUTE_EDIT_ADMIN,
          component: EditAdmin,
          meta: {
            title: `Персонал — редактирование — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.USER_ADMIN,
          },
        },
        {
          path: "log-camera-watch-reasons/:adminId/:currentPage?",
          name: ROUTE_LOG_CAMERA_WATCH_REASONS_BY_ADMIN,
          component: LogCameraWatchReasonsByAdmin,
          meta: {
            title: `Персонал — обоснованный доступ — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_CAMERA_WATCH_REASON,
          },
          props: (route) => ({adminId: parseInt(route.params.adminId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-views/:adminId/:currentPage?",
          name: ROUTE_LOG_VIEWS_BY_ADMIN,
          component: LogViewsByAdmin,
          meta: {
            title: `Персонал — логи просмотров — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_VIEW,
          },
          props: (route) => ({adminId: parseInt(route.params.adminId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-auths/:adminId/:currentPage?",
          name: ROUTE_LOG_AUTHS_BY_ADMIN,
          component: LogAuthsByAdmin,
          meta: {
            title: `Персонал — логи авторизаций — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({adminId: parseInt(route.params.adminId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-permission-camera/:adminId/:currentPage?",
          name: ROUTE_VIEW_ADMIN_PERMISSIONS_HISTORY,
          component: TableAdminPermissionsToCamera,
          meta: {
            title: `Персонал — логи прав на камеру — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({adminId: parseInt(route.params.adminId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
        {
          path: "log-permission-group/:adminId/:currentPage?",
          name: ROUTE_VIEW_ADMIN_PERMISSIONS_GROUP_HISTORY,
          component: TableAdminPermissionsToGroup,
          meta: {
            title: `Персонал — логи прав на группы — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
          },
          props: (route) => ({adminId: parseInt(route.params.adminId), currentPage: parseInt(route.params.currentPage) || undefined}),
        },
      ]
    },
    {
      path: "/domain-settings",
      name: ROUTE_DOMAIN_SETTINGS,
      component: TableDomainSettings,
      meta: {
        title: `Доменные настройки — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.SUPER_SUBJECT,
      },
    },
    {
      path: "/domain-settings/domain/:domainSettingId",
      name: ROUTE_EDIT_DOMAIN_SETTING,
      component: EditDomainSetting,
      meta: {
        title: `Доменные настройки — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.SUPER_SUBJECT,
      },
    },
    {
      path: "/right-groups",
      name: ROUTE_RIGHT_GROUPS,
      component: TableRightGroup,
      meta: {
        title: `Группы прав персонала — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.RIGHT_GROUP,
      },
    },
    {
      path: "/right-groups/history/:rightGroupId",
      name: ROUTE_VIEW_RIGHT_GROUP_HISTORY,
      component: TableRightGroupHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.RIGHT_GROUP,
      },
    },
    {
      path: "/right-groups/right-group/:rightGroupId",
      name: ROUTE_EDIT_RIGHT_GROUP,
      component: EditRightGroup,
      meta: {
        title: `Группы прав персонала — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.RIGHT_GROUP,
      },
    },
    {
      path: "/logs",
      name: ROUTE_ROOT_LOGS,
      redirect: {name: ROUTE_LOG_AUTHS},
    },
    {
      path: "/logs/auths",
      name: ROUTE_LOG_AUTHS,
      component: TableLogAuths,
      meta: {
        title: `Логи авторизаций — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.LOG_AUTH,
      },
    },
    {
      path: "/logs/views",
      name: ROUTE_LOG_VIEWS,
      component: TableLogViews,
      meta: {
        title: `Логи просмотров видео — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.LOG_VIEW,
      },
    },
    {
      path: "/logs/camera-watch-reasons",
      name: ROUTE_LOG_CAMERA_WATCH_REASONS,
      component: TableLogCameraWatchReasons,
      meta: {
        title: `Логи запросов доступа к камере — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.LOG_CAMERA_WATCH_REASON,
      },
    },
    {
      path: "/logs/ucamsgo",
      name: ROUTE_LOG_UCAMSGO,
      component: TableLogUcamsgo,
      meta: {
        title: `Логи ucamsgo — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.LOG_UCAMSGO_VIEW,
      },
    },

    {
      path: "/analytics",
      name: ROUTE_ROOT_ANALYTICS,
      redirect: {name: ROUTE_ANALYTIC_SERVERS},
    },
    {
      path: "/analytics/settings",
      name: ROUTE_ROOT_ANALYTICS_SETTINGS,
      redirect: {name: ROUTE_CAR_NUMBERS},
      component: TableThermalVisions,
      meta: {
        title: `Настройки аналитик — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/servers",
      name: ROUTE_ANALYTIC_SERVERS,
      component: TableAnalyticServers,
      meta: {
        title: `Серверы аналитики — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC_SERVER,
      },
    },
    {
      path: "/analytics/servers/server/:analyticServerId",
      name: ROUTE_EDIT_ANALYTIC_SERVER,
      component: EditAnalyticServer,
      meta: {
        title: `Серверы аналитики — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC_SERVER,
      },
    },
    {
      path: "/analytics/clusters",
      name: ROUTE_ANALYTIC_CLUSTERS,
      component: TableAnalyticClusters,
      meta: {
        title: `Кластеры серверов аналитик— ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CLUSTER,
      },
    },
    {
      path: "/analytics/clusters/cluster/:clusterId",
      name: ROUTE_EDIT_ANALYTIC_CLUSTER,
      component: EditAnalyticCluster,
      meta: {
        title: `Кластеры серверов аналитик — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.CLUSTER,
      },
    },
    {
      path: "/analytics/servers/log",
      name: ROUTE_ANALYTIC_SERVERS_LOGS,
      component: TableAnalyticServersLogs,
      meta: {
        title: `Серверы аналитики — логи — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC_SERVER_LOG,
      },
    },
    {
      path: "/analytics/reports",
      name: ROUTE_ANALYTIC_CAR_NUMBER_REPORT,
      component: TableReports,
      meta: {
        title: `Аналитика — отчеты — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC_SERVER_LOG,
      },
    },
    {
      path: "/analytics/servers/history/:analyticServerId",
      name: ROUTE_VIEW_ANALYTIC_SERVER_HISTORY,
      component: TableAnalyticServerHistory,
      meta: {
        title: `Серверы аналитики — История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC_SERVER,
      },
    },
    {
      path: "/analytics/thermal-visions",
      name: ROUTE_THERMAL_VISIONS,
      component: TableThermalVisions,
      meta: {
        title: `Настройки аналитики тепловизоров — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/thermal-visions/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_THERMAL_VISION_HISTORY,
      component: TableAnalyticThermalVisionHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/thermal-visions/thermal-vision/:cameraNumber",
      name: ROUTE_EDIT_THERMAL_VISION,
      component: EditThermalVision,
      meta: {
        title: `Настройки аналитики тепловизоров — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/car-numbers",
      name: ROUTE_CAR_NUMBERS,
      component: TableCarNumbers,
      meta: {
        title: `Настройки аналитики распознавания номеров — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/car-numbers/car-number/:cameraNumber",
      name: ROUTE_EDIT_CAR_NUMBER,
      component: EditCarNumber,
      meta: {
        title: `Настройки аналитики распознавания номеров — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,

      },
    },
    {
      path: "/analytics/car-numbers/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_CAR_NUMBER_HISTORY,
      component: TableAnalyticCarNumberHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/face-recognitions",
      name: ROUTE_FACE_RECOGNITIONS,
      component: TableFaceRecognitions,
      meta: {
        title: `Настройки аналитики распознавания лиц — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/face-recognitions/face-recognition/:cameraNumber",
      name: ROUTE_EDIT_FACE_RECOGNITION,
      component: EditFaceRecognition,
      meta: {
        title: `Настройки аналитики распознавания лиц — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/face-recognitions/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_FACE_RECOGNITION_HISTORY,
      component: TableAnalyticFaceRecognitionHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/helmets",
      name: ROUTE_HELMETS,
      component: TableHelmets,
      meta: {
        title: `Настройки аналитики определения касок — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/helmets/helmet/:cameraNumber",
      name: ROUTE_EDIT_HELMET,
      component: EditHelmet,
      meta: {
        title: `Настройки аналитики определения касок — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/helmets/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_HELMET_HISTORY,
      component: TableAnalyticHelmetHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/motion-alarms",
      name: ROUTE_MOTION_ALARMS,
      component: TableMotionAlarms,
      meta: {
        title: `Настройки аналитики детекции движения — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/motion-alarms/motion-alarm/:cameraNumber",
      name: ROUTE_EDIT_MOTION_ALARM,
      component: EditMotionAlarm,
      meta: {
        title: `Настройки аналитики детекции движения — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/motion-alarms/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_MOTION_ALARM_HISTORY,
      component: TableAnalyticMotionAlarmHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/masks",
      name: ROUTE_MASKS,
      component: TableMasks,
      meta: {
        title: `Настройки аналитики определения масок — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/masks/mask/:cameraNumber",
      name: ROUTE_EDIT_MASK,
      component: EditMask,
      meta: {
        title: `Настройки аналитики определения масок — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/masks/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_MASK_HISTORY,
      component: TableAnalyticMaskHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/perimeters",
      name: ROUTE_PERIMETER_SECURITYS,
      component: TablePerimeterSecurity,
      meta: {
        title: `Настройки аналитики вторжения в зону — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/perimeters/perimeter/:cameraNumber",
      name: ROUTE_EDIT_PERIMETER_SECURITY,
      component: EditPerimeterSecurity,
      meta: {
        title: `Настройки аналитики вторжения в зону — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/perimeters/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_PERIMETER_SECURITY_HISTORY,
      component: TableAnalyticPerimeterSecurityHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/counts",
      name: ROUTE_PEOPLE_COUNTS,
      component: TablePeopleCount,
      meta: {
        title: `Настройки аналитики подсчета посетителей — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/counts/count/:cameraNumber",
      name: ROUTE_EDIT_PEOPLE_COUNT,
      component: EditPeopleCount,
      meta: {
        title: `Настройки аналитики подсчета посетителей — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/counts/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_PEOPLE_COUNT_HISTORY,
      component: TableAnalyticPeopleCountHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/crowds",
      name: ROUTE_CROWDS,
      component: TableCrowds,
      meta: {
        title: `Настройки аналитики определения толпы — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/crowds/crowd/:cameraNumber",
      name: ROUTE_EDIT_CROWD,
      component: EditCrowd,
      meta: {
        title: `Настройки аналитики определения толпы — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/analytics/crowds/history/:cameraNumber",
      name: ROUTE_VIEW_ANALYTIC_CROWD_HISTORY,
      component: TableAnalyticCrowdHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/gangs",
      name: ROUTE_GANGS,
      component: TableGangs,
      meta: {
        title: `Компании — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.GANG,
      },
    },
    {
      path: "/gangs/gang/:gangId",
      name: ROUTE_EDIT_GANG,
      component: EditGang,
      meta: {
        title: `Компании — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.GANG,
      },
    },
    {
      path: "/pacs/employees",
      name: ROUTE_EMPLOYEES,
      component: TableEmployees,
      meta: {
        title: `СКУД — сотрудники — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.EMPLOYEE,
      },
    },
    {
      path: "/pacs/employees/edit",
      component: TabsEditorEmployee,
      redirect: {name: ROUTE_EMPLOYEES},
      children: [
        {
          path: "employee/:employeeId",
          name: ROUTE_EDIT_EMPLOYEE,
          component: EditEmployee,
          meta: {
            title: `СКУД — сотрудники — редактирование — ${APPLICATION_TITLE}`,
            abilitySubject: ABILITIES_SUBJECTS.EMPLOYEE,
          },
        },
        // todo до переделки апи фоток
        // {
        //   path: "/employee/:employeeId/photos/:currentPage?",
        //   name: ROUTE_PHOTO_FOR_EMPLOYEE,
        //   component: EmployeePhoto,
        //   meta: {
        //     title: `СКУД — сотрудники — фото  — ${APPLICATION_TITLE}`,
        //     abilitySubject: ABILITIES_SUBJECTS.EMPLOYEE,
        //   },
        //   props: (route) => ({employeeId: parseInt(route.params.employeeId), currentPage: parseInt(route.params.currentPage) || undefined}),
        // },
      ]
    },
    {
      path: "/pacs/cars",
      name: ROUTE_CARS,
      component: TableCars,
      meta: {
        title: `СКУД — автомобили — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.EMPLOYEE,
      },
    },
    {
      path: "/pacs/cars/car/:carId",
      name: ROUTE_EDIT_CAR,
      component: EditCar,
      meta: {
        title: `СКУД — автомобили — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.EMPLOYEE,
      },
    },
    {
      path: "/pacs/devices",
      name: ROUTE_DEVICES,
      component: TableDevices,
      meta: {
        title: `СКУД — устройства доступа — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.DEVICE,
      },
    },
    {
      path: "/pacs/devices/device/:deviceId",
      name: ROUTE_EDIT_DEVICE,
      component: EditDevice,
      meta: {
        title: `СКУД — устройства доступа — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.DEVICE,
      },
    },
    {
      path: "/pacs/devices/device/history/:deviceId",
      name: ROUTE_VIEW_DEVICE_HISTORY,
      component: TableDeviceHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/pacs/devices/device/module/history/:moduleId",
      name: ROUTE_VIEW_DEVICE_MODULE_HISTORY,
      component: TableDeviceModuleHistory,
      meta: {
        title: `История изменений — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.ANALYTIC,
      },
    },
    {
      path: "/pacs/devices-access-groups",
      name: ROUTE_DEVICE_ACCESS_GROUPS,
      component: TableDeviceAccessGroups,
      meta: {
        title: `СКУД — группы доступа к устройствам — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP,
      },
    },
    {
      path: "/pacs/devices-access-groups/devices-access-group/:deviceAccessGroupId",
      name: ROUTE_EDIT_DEVICE_ACCESS_GROUP,
      component: EditDeviceAccessGroup,
      meta: {
        title: `СКУД — группы доступа к устройствам — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP,
      },
    },
    {
      path: "/integrations/bolid/servers",
      name: ROUTE_BOLID_SERVERS,
      component: TableBolidServers,
      meta: {
        title: `Интеграции — сервера BOLID — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.BOLID_SERVER,
      },
    },
    {
      path: "/integrations/bolid/servers/server/:serverId",
      name: ROUTE_EDIT_BOLID_SERVER,
      component: EditBolidServer,
      meta: {
        title: `Интеграции —  сервера BOLID  — редактирование — ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.BOLID_SERVER,
      },
    },
    {
      path: "/integrations/bolid/events",
      name: ROUTE_BOLID_EVENTS,
      component: BolidEventsTable,
      meta: {
        title: `Интеграции —  события BOLID  ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.BOLID_SERVER,
      },
    },
    {
      path: "/integrations/bolid/devices",
      name: ROUTE_BOLID_DEVICES,
      component: BolidDevices,
      meta: {
        title: `Интеграции —  уст BOLID  ${APPLICATION_TITLE}`,
        abilitySubject: ABILITIES_SUBJECTS.BOLID_DEVICE,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Любые перемещения по сайту должны закрывать открытые диалоговые окна.
  Vuedals.$emit("close");

  document.title = to.meta.title;
  if (store.getters.isAuth && to.meta.abilitySubject && !ability.can(ABILITIES_ACTIONS.READ_COMMON, to.meta.abilitySubject)) {
    next({name: ROUTE_403});
  } else {
    next();
  }
});

export default router;
