<template>
  <div>
    <div class="progress">
      <div class="progress__status" v-text="status" />
      <div class="progress__track">
        <div :style="'width: ' + percentageProgress + '%'" class="progress__line" />
      </div>

      <CamsButton
        :disabled="this.markStop || !this.processing"
        type="button"
        @click.once="stopProcessing()"
      >
        Прервать
      </CamsButton>
    </div>

    <div class="logs">
      <div class="logs__list js-clipboard">
        <div v-for="(log, logIndex) in logs" :key="logIndex" class="logs__list__item">
          {{ log }}
        </div>
      </div>

      <CamsButton type="button" @click="copyLogToClipboard()">
        Копировать лог
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";

/**
 * Несамостоятельный компонент для организации процесса непосредственно изменения данных.
 * Является частью диалогового окна мультиредактирования у конкретной сущности.
 * В компонент передаются ключевые идентификаторы редактируемых сущностей и экземпляр функции,
 * в которую поочередно надо передавать эти идентификаторы.
 *
 * Запуск обработки происходит сразу при включении компонента в работу.
 */
export default {
  props: {
    /**
     * Перечень с ключевыми идентификаторами редактируемых сущностей.
     */
    selectedEntities: {
      type: Array,
      default: () => ([])
    },
    /**
     * Функция проведения (или вызова другой функции для) непосредственной обработки данных над одним экземляром сущности.
     *
     * Функция должна вернуть экземпляр Promise, предназначенный для проведения изменений над одной сущностью,
     * для которой был передан ключ в `entityKey`.
     * В этом Promise функции .then() и .catch() должны быть определены и возвращать объект типа {@link ResultProcessingEntityMultiEdit},
     * который используется для помещения результата работы в лог.
     *
     * Должна зависеть от конкретной выбранной операции изменения.
     *
     * @param {Number|String} entityKey
     * @param {Number} index Порядковый номер в списке обрабатываемых элементов.
     * @return {Promise}
     */
    promiseSaveData: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: (entityKey, index) => Promise.resolve(),
    },
  },
  data() {
    return {
      processing: false,
      currentIndex: 0,
      logs: [],
      markStop: false,
      status: "",
    };
  },
  computed: {
    /**
     * Процентный показатель выполненной обработки.
     *
     * @return {Number}
     */
    percentageProgress() {
      return Math.round(this.currentIndex / this.selectedEntities.length * 100);
    }
  },
  /**
   * Автоматический запуск процедуры обработки строк, при запуске компонента.
   */
  mounted() {
    this.processingSelectedEntities();
  },
  methods: {
    /**
     * Обработка выбранных строк в таблице, применение заявленных изменений.
     *
     * Асинхронная функция поочередно, для каждого элемента в списке сущностей, вызывает необходимую функцию,
     * которая производит изменения и должна вернуть Promise.
     * Функция применения изменений может быть любая (синхронная/асинхронная), в нее передаются ключевые данные из списка сущностей,
     * изменения она принимает из данных текущего компонента.
     *
     * Ожидается, что у Promise применения изменений имеются обработчики обработки результата, чтобы на выходе был получен объект
     * типа {@link ResultProcessingEntityMultiEdit}. Если результат некорректно обработан, тогда здесь будет происходить
     * только стандартная обработка.
     *
     * Так как в основном функции применения изменений отправляют AJAX запросы на сервер (т.е. они асинхронные
     * и уже будут иметь Promise) необходимо обеспечить их поочередное выполнение для обеспечения возможности прерывания,
     * на случай если пользователь передумал дожидаться выполнения всех запросов.
     *
     * todo обработка ошибок
     */
    async processingSelectedEntities() {
      if (!this.promiseSaveData) {
        this.$camsdals.alert("Ошибка при обработки формы с данными");
      }
      this.processing = true;

      this.logs = [`${moment().format("DD.MM.YYYY HH:mm:ss")} - Началась обработка`];
      for (const [index, entityKey] of this.selectedEntities.entries()) {
        if (this.markStop) {
          break;
        }
        this.currentIndex = index + 1;
        this.status = `Идет обработка, завершено ${index} из ${this.selectedEntities.length} (${this.percentageProgress}%)`;

        this.logs.push(`${moment().format("DD.MM.YYYY HH:mm:ss")} - Обработка [${entityKey}]`);

        let processingResult;
        try {
          const promiseSaveDataResult = await this.promiseSaveData(entityKey, index);
          processingResult = promiseSaveDataResult instanceof ResultProcessingEntityMultiEdit ? promiseSaveDataResult : ResultProcessingEntityMultiEdit.success(entityKey);
        } catch (promiseSaveDataResult) {
          processingResult = promiseSaveDataResult instanceof ResultProcessingEntityMultiEdit ? promiseSaveDataResult : ResultProcessingEntityMultiEdit.error(entityKey);
        }
        // В зависимости от конкретного результата (строка или массив строк) - каждая строка из результата
        // займет свое место в итоговом логе.
        const log = processingResult.toLog();
        if (Array.isArray(log)) {
          this.logs.push(...log);
        } else {
          this.logs.push(log);
        }
      }

      this.status = `Обработка завершена`;
      if (this.markStop) {
        this.status = `Обработка прервана, завершено ${this.currentIndex} из ${this.selectedEntities.length}`;
      }
      this.logs.push(`${moment().format("DD.MM.YYYY HH:mm:ss")} - Обработка завершена`);

      this.processing = false;
    },
    /**
     * Установка маркера для прекращения обработки.
     */
    stopProcessing() {
      if (!this.markStop) {
        this.markStop = true;
        this.status = "Прерывание...";
        this.logs.push(`${moment().format("DD.MM.YYYY HH:mm:ss")} - Прерывание`);
      }
    },
    /**
     * Скопирует содержимое лога в буфер обмена.
     */
    copyLogToClipboard() {
      const elementClipboard = document.querySelector(".js-clipboard"),
            range = document.createRange();
      range.selectNode(elementClipboard);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
    },
  },
};
</script>
