<template>
  <div>
    <div class="row">
      <SmartVSelect
        v-model="selectedCameraNumber"
        :settings-remote-search="settingsSelectCamera"
        class="col"
        placeholder="Введите номер камеры"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отмена
      </CamsButton>
      <CamsButton priority="primary" type="button" @click="addCameraToCameraGroup()">
        Добавить
      </CamsButton>
    </div>

    <SpinnerLoading v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {ACTION_ADD_CAMERA_GROUPS, ACTION_LOAD_CAMERAS_FOR_SELECT, FIELDS_CAMERA} from "@/store/cameras/index.js";

/**
 * Компонент диалога добавления камеры к группе камер.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Идентификатор группы камер.
     */
    cameraGroupId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
      selectedCameraNumber: null,
      settingsSelectCamera: {
        action: `cameras/${ACTION_LOAD_CAMERAS_FOR_SELECT}`,
        valueField: FIELDS_CAMERA.number,
        labelField: [FIELDS_CAMERA.number, FIELDS_CAMERA.title, FIELDS_CAMERA.address]
      },
    };
  },
  methods: {
    /**
     * Обработка поля выбора камеры для добавления ее в группу.
     */
    async addCameraToCameraGroup() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_ADD_CAMERA_GROUPS}`, {
          cameraNumber: this.selectedCameraNumber,
          cameraGroupIds: [this.cameraGroupId],
        });
        this.closeDialog(true);
      } catch {
        this.$camsdals.alert("Ошибка при добавлении камеры к группе");
      }
      this.isLoading = false;
    },
  },
};
</script>
