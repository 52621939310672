<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_CAMERA_GROUPS}" class="tabs__item">
          Группы камер
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Типы групп камер
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateCameraGroupType"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreateCameraGroupTypeDialog from "@/components/cameraGroupTypes/CreateCameraGroupTypeDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {ROUTE_EDIT_CAMERA_GROUP_TYPE, ROUTE_CAMERA_GROUP_TYPES, ROUTE_CAMERA_GROUPS} from "@/router/names.js";
import {FILTERS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {
  ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_TABLE,
  ACTION_LOAD_INFO_CAMERA_GROUP_TYPES,
  FIELDS_CAMERA_GROUP_TYPE,
  TITLES_FIELDS_CAMERA_GROUP_TYPE,
  TYPES_FIELDS_CAMERA_GROUP_TYPE
} from "@/store/cameraGroupTypes/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по типам групп камер.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_CAMERA_GROUPS,
      },
      currentRoute: ROUTE_CAMERA_GROUP_TYPES,
      rawFields: FIELDS_CAMERA_GROUP_TYPE,
      columnCaptions: TITLES_FIELDS_CAMERA_GROUP_TYPE,
      nameActionLoadDataForTable: `cameraGroupTypes/${ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_TABLE}`,
      nameActionLoadInfoForTable: `cameraGroupTypes/${ACTION_LOAD_INFO_CAMERA_GROUP_TYPES}`,
      defaultFieldsTableForView: [
        FIELDS_CAMERA_GROUP_TYPE.id,
        FIELDS_CAMERA_GROUP_TYPE.name,
        FIELDS_CAMERA_GROUP_TYPE.is_deleted,
        FIELDS_CAMERA_GROUP_TYPE.is_unique,
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      let params;
      switch (nameField) {
      case FIELDS_CAMERA_GROUP_TYPE.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA_GROUP_TYPE, params: {cameraGroupTypeId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA_GROUP_TYPE.name:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA_GROUP_TYPE, params: {cameraGroupTypeId: rowData[FIELDS_CAMERA_GROUP_TYPE.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA_GROUP_TYPE.camera_group_ids:
        readyTranslation = rawTranslation;
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA_GROUP.camera_group_type_id, "=", [rowData[FIELDS_CAMERA_GROUP_TYPE.id]])]});
        readyParams = {name: ROUTE_CAMERA_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CAMERA_GROUP_TYPE[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания типа группы камер.
     */
    openDialogCreateCameraGroupType() {
      this.$camsdals.open(
        CreateCameraGroupTypeDialog,
        {},
        {dialogTitle: "Создание типа группы камер"},
        {},
      );
    },
  },
};
</script>
