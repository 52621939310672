<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_LOG_AUTHS}" class="tabs__item">
          Логи авторизаций
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Логи просмотров видео
        </router-link>
        <router-link :to="{name: routes.ROUTE_LOG_CAMERA_WATCH_REASONS}" class="tabs__item">
          Логи запросов доступа к камере
        </router-link>
        <router-link :to="{name: routes.ROUTE_LOG_UCAMSGO}" class="tabs__item">
          Логи UCAMSGO
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_EDIT_CAMERA,
  ROUTE_LOG_AUTHS,
  ROUTE_LOG_CAMERA_WATCH_REASONS,
  ROUTE_LOG_UCAMSGO,
  ROUTE_LOG_VIEWS
} from "@/router/names.js";
import {TOKEN_TYPES} from "@/store/cameras/index.js";
import {
  ACTION_LOAD_INFO_LOG_VIEWS,
  ACTION_LOAD_LOG_VIEWS_FOR_TABLE,
  FIELDS_LOG_VIEWS,
  FILTERS_LOG_VIEWS,
  TITLES_FIELDS_LOG_VIEWS,
  TYPES_FIELDS_LOG_VIEWS
} from "@/store/log/index.js";
import {FilterData} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с логами просмотров.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      routes: {
        ROUTE_LOG_AUTHS,
        ROUTE_LOG_CAMERA_WATCH_REASONS,
        ROUTE_LOG_UCAMSGO
      },
      currentRoute: ROUTE_LOG_VIEWS,
      rawFields: FIELDS_LOG_VIEWS,
      columnCaptions: TITLES_FIELDS_LOG_VIEWS,
      nameActionLoadDataForTable: `log/${ACTION_LOAD_LOG_VIEWS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `log/${ACTION_LOAD_INFO_LOG_VIEWS}`,
      defaultFilters: [
        new FilterData(FILTERS_LOG_VIEWS.date, ">=", [defaultFilterByDate])
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      // todo пока непонятно куда редиректить при клике на пользователя - админы или клиенты
      // if (sourceExtraInfo[EXTRAS_LOG_VIEWS.user]) {
      //   storedExtraInfo.users = {};
      //   sourceExtraInfo[EXTRAS_LOG_VIEWS.user].forEach((userInfo) => {
      //     // См. FIELDSj_ADMIN и FIELDS_CLIENT
      //     storedExtraInfo.users[userInfo["id"]] = {
      //       id: userInfo["id"],
      //       name: userInfo["username"],
      //     };
      //   });
      // }

      storedExtraInfo.captionsForTokens = {
        [TOKEN_TYPES.L]: "Прямая трансляция [L]",
        [TOKEN_TYPES.R]: "Архив [R]",
        [TOKEN_TYPES.D]: "Скачивание [D]",
      };
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_LOG_VIEWS.camera_number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: rawTranslation}};
        break;
      case FIELDS_LOG_VIEWS.token_type:
        readyTranslation = storedExtraInfo.captionsForTokens[rawTranslation];
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_LOG_VIEWS[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
