<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Сервер аналитики
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <router-link
              v-if="routeAnalyticServerHistory"
              :to="routeAnalyticServerHistory"
            >
              <a class="tabs__item">История изменений</a>
            </router-link>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.ips)"
                  class="row"
                >
                  <SmartInputArray
                    v-model="formEdit[fieldsEntity.ips]"
                    :caption="titlesFields[fieldsEntity.ips]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.ips)"
                    :error="errorsFormEdit[fieldsEntity.ips]"
                    class="col"
                  />
                </div>
                <div class="row">
                  <SmartSelect
                    v-model="formEdit[fieldsEntity.log_lvl]"
                    :options="logLevelSettings"
                    caption="Уровень логирования"
                    width="fill"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.description)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.description]"
                    :caption="titlesFields[fieldsEntity.description]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.description)"
                    :error="errorsFormEdit[fieldsEntity.description]"
                    input-type="area"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.analytic_cluster_ids)" class="row">
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.analytic_cluster_ids]"
                    :caption="titlesFields[fieldsEntity.analytic_cluster_ids]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.analytic_cluster_ids)"
                    :error="errorsFormEdit[fieldsEntity.analytic_cluster_ids]"
                    :initial-options="initialOptionsClusters"
                    :multiple="true"
                    :settings-remote-search="settingsSelects[fieldsEntity.analytic_cluster_ids]"
                    class="col"
                  />
                </div>
                <div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.car_number_event_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.car_number_event_is_active]"
                      :caption="titlesFields[fieldsEntity.car_number_event_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.car_number_event_is_active)"
                      :error="errorsFormEdit[fieldsEntity.car_number_event_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.feature_computation_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.feature_computation_is_active]"
                      :caption="titlesFields[fieldsEntity.feature_computation_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.feature_computation_is_active)"
                      :error="errorsFormEdit[fieldsEntity.feature_computation_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.feature_computation_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.perimeter_security_is_active]"
                      :caption="titlesFields[fieldsEntity.perimeter_security_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.perimeter_security_is_active)"
                      :error="errorsFormEdit[fieldsEntity.perimeter_security_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.crowd_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.crowd_is_active]"
                      :caption="titlesFields[fieldsEntity.crowd_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.crowd_is_active)"
                      :error="errorsFormEdit[fieldsEntity.crowd_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.thermal_vision_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.thermal_vision_is_active]"
                      :caption="titlesFields[fieldsEntity.thermal_vision_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.thermal_vision_is_active)"
                      :error="errorsFormEdit[fieldsEntity.thermal_vision_is_active]"
                      class="col"
                    />
                  </div>

                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.car_number_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.car_number_is_active]"
                      :caption="titlesFields[fieldsEntity.car_number_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.car_number_is_active)"
                      :error="errorsFormEdit[fieldsEntity.car_number_is_active]"
                      class="col"
                    />
                  </div>

                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.helmet_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.helmet_is_active]"
                      :caption="titlesFields[fieldsEntity.helmet_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.helmet_is_active)"
                      :error="errorsFormEdit[fieldsEntity.helmet_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.mask_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.mask_is_active]"
                      :caption="titlesFields[fieldsEntity.mask_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.mask_is_active)"
                      :error="errorsFormEdit[fieldsEntity.mask_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.people_counter_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.people_counter_is_active]"
                      :caption="titlesFields[fieldsEntity.people_counter_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.people_counter_is_active)"
                      :error="errorsFormEdit[fieldsEntity.people_counter_is_active]"
                      class="col"
                    />
                  </div>
                  <div
                    v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.face_recognition_is_active)"
                    class="row"
                  >
                    <SmartSwitch
                      v-model="formEdit[fieldsEntity.face_recognition_is_active]"
                      :caption="titlesFields[fieldsEntity.face_recognition_is_active]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.face_recognition_is_active)"
                      :error="errorsFormEdit[fieldsEntity.face_recognition_is_active]"
                      class="col"
                    />
                  </div>
                </div>
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.thermal_vision_workers)"
                  class="row"
                >
                  <SmartInputText
                    v-show="formEdit[fieldsEntity.thermal_vision_is_active]"
                    v-model="formEdit[fieldsEntity.thermal_vision_workers]"
                    :caption="titlesFields[fieldsEntity.thermal_vision_workers]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.thermal_vision_workers)"
                    :error="errorsFormEdit[fieldsEntity.thermal_vision_workers]"
                    class="col"
                  />
                </div>
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.face_recognition_workers)"
                  class="row"
                >
                  <SmartInputText
                    v-show="formEdit[fieldsEntity.face_recognition_is_active]"
                    v-model="formEdit[fieldsEntity.face_recognition_workers]"
                    :caption="titlesFields[fieldsEntity.face_recognition_workers]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.face_recognition_workers)"
                    :error="errorsFormEdit[fieldsEntity.face_recognition_workers]"
                    class="col"
                  />
                </div>

                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.feature_computation_workers)"
                  class="row"
                >
                  <SmartInputText
                    v-show="formEdit[fieldsEntity.feature_computation_is_active]"
                    v-model="formEdit[fieldsEntity.feature_computation_workers]"
                    :caption="titlesFields[fieldsEntity.feature_computation_workers]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.feature_computation_workers)"
                    :error="errorsFormEdit[fieldsEntity.feature_computation_workers]"
                    class="col"
                  />
                </div>
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.car_number_event_workers)"
                  class="row"
                >
                  <SmartInputText
                    v-show="formEdit[fieldsEntity.car_number_event_is_active]"
                    v-model="formEdit[fieldsEntity.car_number_event_workers]"
                    :caption="titlesFields[fieldsEntity.car_number_event_workers]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.car_number_event_workers)"
                    :error="errorsFormEdit[fieldsEntity.car_number_event_workers]"
                    class="col"
                  />
                </div>
                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.ANALYTIC_SERVER)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.ANALYTIC_SERVER)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.ANALYTIC_SERVER)"
                        type="button"
                        @click="deleteServer()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.ANALYTIC_SERVER)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.front_id)">
                  {{ titlesFields[fieldsEntity.front_id] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.front_id] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.video_card_count)">
                  {{ titlesFields[fieldsEntity.video_card_count] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.video_card_count] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.max_free_memory)">
                  {{ titlesFields[fieldsEntity.max_free_memory] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.max_free_memory] }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.sync_success)"
                >
                  {{ titlesFields[fieldsEntity.sync_success] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_success] | bool }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.sync_error_stage)"
                >
                  {{ titlesFields[fieldsEntity.sync_error_stage] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_error_stage] }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.sync_error)"
                >
                  {{ titlesFields[fieldsEntity.sync_error] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_error] }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.sync_last_success)"
                  :title="sourceData.entityInfo[fieldsEntity.sync_last_success] | localDateTimeZone"
                >
                  {{ titlesFields[fieldsEntity.sync_last_success] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_last_success] | localDateTime }}</strong>
                </p>

                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.sync_data)"
                >
                  {{ titlesFields[fieldsEntity.sync_data] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_data] }}</strong>
                </p>

                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_car_number_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routeCarNumbers">
                      Посмотреть все аналитики по а/м номерам
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_car_number_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_helmet_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routeHelmets">
                      Посмотреть все аналитики по каскам
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_helmet_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_mask_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routeMasks">
                      Посмотреть все аналитики по маскам
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_mask_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_face_recognition_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routeFaceRecognitions">
                      Посмотреть все аналитики по лицам
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_face_recognition_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>
                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_crowd_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routeCrowds">
                      Посмотреть все аналитики по детекции толпы
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_crowd_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>
                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_perimeter_security_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routePerimeterSecuritys">
                      Посмотреть все аналитики по вторжению
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_perimeter_security_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>
                <br>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC_SERVER, fieldsEntity.analytic_perimeter_security_camera_numbers_count)"
                >
                  <strong>
                    <router-link :to="routePeopleCounters">
                      Посмотреть все аналитики подсчета посетителей
                      ({{ sourceData.entityInfo[fieldsEntity.analytic_people_counter_camera_numbers_count] }})
                    </router-link>
                  </strong>
                </p>
                <br>
                <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_VIEW)">
                  <strong>
                    <router-link :to="routeLogViewsByServer">Логи сервера аналитики</router-link>
                  </strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_CAR_NUMBERS,
  ROUTE_CROWDS,
  ROUTE_FACE_RECOGNITIONS,
  ROUTE_HELMETS,
  ROUTE_MASKS,
  ROUTE_PEOPLE_COUNTS,
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_VIEW_ANALYTIC_SERVER_HISTORY,
} from "@/router/names.js";
import {FILTERS_CAR_NUMBER} from "@/store/analytics/carNumbers/index.js";
import {FILTERS_FACE_RECOGNITION} from "@/store/analytics/faceRecognitions/index.js";
import {FILTERS_HELMET} from "@/store/analytics/helmets/index.js";
import {
  ACTION_DELETE_ANALYTIC_SERVERS,
  ACTION_EDIT_ANALYTIC_SERVER_WITH_ALL,
  ACTION_LOAD_ANALYTIC_SERVER_FOR_EDIT, EXTRAS_ANALYTIC_SERVER,
  FIELDS_ANALYTIC_SERVER, LOGGING_LEVEL,
  TITLES_FIELDS_ANALYTIC_SERVER
} from "@/store/analytics/servers/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {FILTERS_ANALYTIC_SERVER_LOGS} from "@/store/analytics/serverLogs/index.js";
import {FILTERS_PERIMETER_SECURITY} from "@/store/analytics/perimeterSecuritys/index.js";
import {FILTERS_PEOPLE_COUNT} from "@/store/analytics/peopleCounts/index.js";
import {ACTION_LOAD_ANALYTIC_CLUSTERS_FOR_SELECT, FIELDS_ANALYTIC_CLUSTER} from "@/store/analytics/analyticClusters/index.js";

/**
 * Компонент страницы редактирования сервера аналитики.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const analyticServerId = Number(this.$route.params.analyticServerId),
          paramsRouteCarNumbersInServer = new TableQueryParams({filters: [new FilterData(FILTERS_CAR_NUMBER.analytic_server_id, "=", [analyticServerId])]}),
          routeCarNumbers = {
            name: ROUTE_CAR_NUMBERS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCarNumbersInServer.stringify()}
          },
          paramsRouteHelmetsForServer = new TableQueryParams({filters: [new FilterData(FILTERS_HELMET.analytic_server_id, "=", [analyticServerId])]}),
          routeHelmets = {
            name: ROUTE_HELMETS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteHelmetsForServer.stringify()}
          },
          paramsRouteMasksForServer = new TableQueryParams({filters: [new FilterData(FILTERS_HELMET.analytic_server_id, "=", [analyticServerId])]}),
          routeMasks = {
            name: ROUTE_MASKS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteMasksForServer.stringify()}
          },
          paramsRoutePeopleCountersForServer = new TableQueryParams({filters: [new FilterData(FILTERS_PEOPLE_COUNT.analytic_server_id, "=", [analyticServerId])]}),
          routePeopleCounters = {
            name: ROUTE_PEOPLE_COUNTS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRoutePeopleCountersForServer.stringify()}
          },
          paramsRoutePerimeterSecurityForServer = new TableQueryParams({filters: [new FilterData(FILTERS_PERIMETER_SECURITY.analytic_server_id, "=", [analyticServerId])]}),
          routePerimeterSecuritys = {
            name: ROUTE_PERIMETER_SECURITYS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRoutePerimeterSecurityForServer.stringify()}
          },
          paramsRouteCrowdsForServer = new TableQueryParams({filters: [new FilterData(FILTERS_HELMET.analytic_server_id, "=", [analyticServerId])]}),
          routeCrowds = {
            name: ROUTE_CROWDS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCrowdsForServer.stringify()}
          },
          paramsRouteFaceRecognitionsForServer = new TableQueryParams({filters: [new FilterData(FILTERS_FACE_RECOGNITION.analytic_server_id, "=", [analyticServerId])]}),
          routeFaceRecognitions = {
            name: ROUTE_FACE_RECOGNITIONS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteFaceRecognitionsForServer.stringify()}
          },
          paramsRouteServerLogs = new TableQueryParams({filters: [new FilterData(FILTERS_ANALYTIC_SERVER_LOGS.analytic_server_id, "=", [analyticServerId])]}),
          routeLogViewsByServer = {
            name: ROUTE_ANALYTIC_SERVERS_LOGS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteServerLogs.stringify()}
          };

    return {
      logLevelSettings: LOGGING_LEVEL,
      routeBack: {name: ROUTE_ANALYTIC_SERVERS},
      fieldsEntity: FIELDS_ANALYTIC_SERVER,
      titlesFields: TITLES_FIELDS_ANALYTIC_SERVER,
      entityId: analyticServerId,
      routeAnalyticServerHistory: null,
      initialOptionsClusters: [],
      nameActionLoadDataForEdit: `analytics/servers/${ACTION_LOAD_ANALYTIC_SERVER_FOR_EDIT}`,
      nameActionEdit: `analytics/servers/${ACTION_EDIT_ANALYTIC_SERVER_WITH_ALL}`,
      deletedField: FIELDS_ANALYTIC_SERVER.is_deleted,
      routePerimeterSecuritys: routePerimeterSecuritys,
      routeFaceRecognitions: routeFaceRecognitions,
      routeLogViewsByServer: routeLogViewsByServer,
      routePeopleCounters: routePeopleCounters,
      routeCarNumbers: routeCarNumbers,
      routeHelmets: routeHelmets,
      routeCrowds: routeCrowds,
      routeMasks: routeMasks,
      settingsSelects: {
        [FIELDS_ANALYTIC_SERVER.analytic_cluster_ids]: {
          action: `analytics/analyticClusters/${ACTION_LOAD_ANALYTIC_CLUSTERS_FOR_SELECT}`,
          valueField: FIELDS_ANALYTIC_CLUSTER.id,
          labelField: FIELDS_ANALYTIC_CLUSTER.name
        },
      }
    };
  },
  methods: {
    async afterLoadSourceData() {
      this.initialOptionsClusters = this.sourceData.extraInfo[EXTRAS_ANALYTIC_SERVER.analytic_cluster];
      const paramsRouteAnalyticServerHistory = new TableQueryParams({
        filters: [
          new FilterData(FIELDS_ANALYTIC_SERVER.id,  "=", [this.entityId]),
        ]});
      this.routeAnalyticServerHistory = {
        name: ROUTE_VIEW_ANALYTIC_SERVER_HISTORY,
        query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteAnalyticServerHistory.stringify()},
        params: {analyticServerId: this.entityId}};
    },
    /**
     * Коррекции подвергаются следующие поля:
     *
     * - description - нужно привести к "" либо null при отсутствии значений.
     *
     * @link editDataEntityMixin.methods.getDataForSave
     */
    getDataForSave() {
      const analyticServerInfo = Object.assign({}, this.formEdit);
      analyticServerInfo[FIELDS_ANALYTIC_SERVER.description] =  analyticServerInfo[FIELDS_ANALYTIC_SERVER.description] ?  analyticServerInfo[FIELDS_ANALYTIC_SERVER.description] : "";
      return analyticServerInfo;
    },
    /**
     * Отправка запроса на удаление текущего сервера аналитики.
     */
    deleteServer() {
      this.$camsdals.confirm("Хотите удалить этот сервер аналитики?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`analytics/servers/${ACTION_DELETE_ANALYTIC_SERVERS}`, {analyticServerIds: [this.entityId]});
          this.$camsdals.alert("Сервер аналитики удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении сервера аналитики");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
<!--Здесь прижимаем свитчер к правому краю по всему проекту, необходимо в шаре поправить-->
<style lang="scss">
.smart-input_switch .smart-input__wrapper {
  margin-left: auto;
}
</style>
