<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Камеры
        </router-link>
        <router-link :to="{ name: routes.ROUTE_MARKERS }" class="tabs__item">
          Маркеры камер
        </router-link>
        <router-link :to="{ name: routes.ROUTE_HISTORY }" class="tabs__item">
          История по камерам
        </router-link>
        <router-link :to="{ name: routes.ROUTE_FIRMWARES }" class="tabs__item">
          Прошивки
        </router-link>
        <router-link :to="{ name: routes.ROUTE_MIGRATION }" class="tabs__item">
          Миграция камер
        </router-link>
      </div>
    </div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateCamera"
      :column-captions="columnCaptions"
      :component-multi-edit="componentMultiEdit"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.number"
      :name-action-load-data-for-multi-edit="nameActionLoadDataForMultiEdit"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreateCameraDialog from "@/components/cameras/CreateCameraDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_CAMERA_GROUPS,
  ROUTE_CAMERAS,
  ROUTE_EDIT_CAMERA,
  ROUTE_MARKERS,
  ROUTE_EDIT_GANG,
  ROUTE_HISTORY,
  ROUTE_FIRMWARES,
  ROUTE_MIGRATION
} from "@/router/names.js";
import {FIELDS_CAMERA_GROUP, FILTERS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {
  ACTION_LOAD_CAMERAS_FOR_MULTI_EDIT,
  ACTION_LOAD_CAMERAS_FOR_TABLE,
  ACTION_LOAD_INFO_CAMERAS,
  EXTRAS_CAMERA,
  FIELDS_CAMERA,
  FIELDS_PERIOD_INACTIVITY,
  FILTERS_CAMERA,
  TITLES_FIELDS_CAMERA,
  TITLES_TOKEN_TYPES,
  TYPES_FIELDS_CAMERA,
} from "@/store/cameras/index.js";
import {FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import MultiEditCamerasDialog from "@/components/cameras/MultiEditCamerasDialog.vue";
import {FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по камерам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_MARKERS,
        ROUTE_HISTORY,
        ROUTE_FIRMWARES,
        ROUTE_MIGRATION
      },
      currentRoute: ROUTE_CAMERAS,
      rawFields: FIELDS_CAMERA,
      columnCaptions: TITLES_FIELDS_CAMERA,
      nameActionLoadDataForTable: `cameras/${ACTION_LOAD_CAMERAS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `cameras/${ACTION_LOAD_INFO_CAMERAS}`,
      defaultFilters: [
        new FilterData(FILTERS_CAMERA.is_deleted, "=", [false])
      ],
      defaultFieldsTableForView: [
        FIELDS_CAMERA.number,
        FIELDS_CAMERA.address,
        FIELDS_CAMERA.title,
        FIELDS_CAMERA.timezone,
        FIELDS_CAMERA.is_sounding,
        FIELDS_CAMERA.tariff_id,
        FIELDS_CAMERA.is_deleted,
      ],
      nameActionLoadDataForMultiEdit: `cameras/${ACTION_LOAD_CAMERAS_FOR_MULTI_EDIT}`,
      componentMultiEdit: MultiEditCamerasDialog,
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CAMERA.tariff]) {
        storedExtraInfo.tariffs = {};
        sourceExtraInfo[EXTRAS_CAMERA.tariff].forEach((tariffInfo) => {
          storedExtraInfo.tariffs[tariffInfo[FIELDS_TARIFF.id]] = {
            id: tariffInfo[FIELDS_TARIFF.id],
            name: tariffInfo[FIELDS_TARIFF.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA.server]) {
        storedExtraInfo.servers = {};
        sourceExtraInfo[EXTRAS_CAMERA.server].forEach((serverInfo) => {
          storedExtraInfo.servers[serverInfo[FIELDS_SERVER.id]] = {
            id: serverInfo[FIELDS_SERVER.id],
            name: serverInfo[FIELDS_SERVER.domain],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA.camera_group]) {
        storedExtraInfo.cameraGroups = {};
        sourceExtraInfo[EXTRAS_CAMERA.camera_group].forEach((cameraGroupInfo) => {
          storedExtraInfo.cameraGroups[cameraGroupInfo[FIELDS_CAMERA_GROUP.id]] = {
            id: cameraGroupInfo[FIELDS_CAMERA_GROUP.id],
            name: cameraGroupInfo[FIELDS_CAMERA_GROUP.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA.inactivity_period]) {
        storedExtraInfo.inactivityPeriods = {};
        sourceExtraInfo[EXTRAS_CAMERA.inactivity_period].forEach((inactivityPeriodInfo) => {
          storedExtraInfo.inactivityPeriods[inactivityPeriodInfo[FIELDS_PERIOD_INACTIVITY.id]] = {
            id: inactivityPeriodInfo[FIELDS_PERIOD_INACTIVITY.id],
            name: inactivityPeriodInfo[FIELDS_PERIOD_INACTIVITY.start],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CAMERA.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_CAMERA.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      let params;
      switch (nameField) {
      case FIELDS_CAMERA.number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: rawTranslation}};
        // К маршруту редактирования камеры подмешиваются параметры фильтрации таблицы для ссылок обратного перехода.
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA.tariff_id:
        readyTranslation = _.get(storedExtraInfo.tariffs[rawTranslation], "name", "Нет тарифа");
        break;
      case FIELDS_CAMERA.server_id:
        readyTranslation = _.get(storedExtraInfo.servers[rawTranslation], "name", "Нет сервера");
        break;
      case FIELDS_CAMERA.blocking_lvl:
        readyTranslation = _.get(TITLES_TOKEN_TYPES, rawTranslation, "-");
        break;
      case FIELDS_CAMERA.camera_group_ids:
        readyTranslation = _.chain(storedExtraInfo.cameraGroups)
          .pick(rawTranslation)
          .mapValues("name")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA_GROUP.camera_number, "=", [rowData[FIELDS_CAMERA.number]])]});
        readyParams = {name: ROUTE_CAMERA_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_CAMERA.inactivity_period_id:
        readyTranslation = this.$options.filters.localDateTime(
          _.get(storedExtraInfo.inactivityPeriods[rawTranslation], "name", "")
        );
        break;
      case FIELDS_CAMERA.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CAMERA[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания камеры.
     */
    openDialogCreateCamera() {
      this.$camsdals.open(
        CreateCameraDialog,
        {},
        {dialogTitle: "Создание камеры"},
        {},
      );
    },
  },
};
</script>
