<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.camera_number]"
        :caption="titlesFields[fieldsEntity.camera_number]"
        :error="errorsFormEdit[fieldsEntity.camera_number]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.push_domain]"
        :caption="titlesFields[fieldsEntity.push_domain]"
        :error="errorsFormEdit[fieldsEntity.push_domain]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.analytic_server_id]"
        :caption="titlesFields[fieldsEntity.analytic_server_id]"
        :error="errorsFormEdit[fieldsEntity.analytic_server_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.analytic_server_id]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton property="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_FACE_RECOGNITION} from "@/router/names.js";
import {
  ACTION_CREATE_FACE_RECOGNITION,
  FIELDS_FACE_RECOGNITION,
  TITLES_FIELDS_FACE_RECOGNITION
} from "@/store/analytics/faceRecognitions/index.js";
import {ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT, FIELDS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания настройки аналитики распознавания номеров.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `analytics/faceRecognitions/${ACTION_CREATE_FACE_RECOGNITION}`,
      fieldsEntity: FIELDS_FACE_RECOGNITION,
      titlesFields: TITLES_FIELDS_FACE_RECOGNITION,
      formEdit: {
        [FIELDS_FACE_RECOGNITION.camera_number]: "",
        [FIELDS_FACE_RECOGNITION.push_domain]: "",
      },
      settingsSelects: {
        [FIELDS_FACE_RECOGNITION.analytic_server_id]: {
          action: `analytics/servers/${ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_ANALYTIC_SERVER.id,
          labelField: FIELDS_ANALYTIC_SERVER.front_id
        },
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование настройки аналитики распознавания номеров.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_FACE_RECOGNITION, params: {cameraNumber: newEntity[FIELDS_FACE_RECOGNITION.camera_number]}};
    },
  },
};
</script>
