<template>
  <div v-click-outside="hideListOptions" class="select select_small">
    <div class="select__toggle" @click="toggleListOptions" v-html="valueForView" />
    <div class="select__list">
      <div class="select__search">
        <SmartInputText
          v-if="showFinder"
          v-model="searchText"
          style="border: none"
          type="text"
          placeholder="Поиск..."
          @input="filterOptions"
        />
      </div>

      <div
        v-for="(optionText, optionKey) in displayOptions"
        :key="optionKey"
        class="select__list__item"
        @click="selectOption(optionKey)"
      >
        {{ optionText }}
      </div>
      <div v-if="isEmptyParsedOptions" class="select__list__item" @click="hideListOptions()">
        {{ emptyValue }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Компонент отображения кастомного выпадающего списка.
 * В компонент передается модель данных через v-model, с которой будет устанавливаться связь.
 * Так же передается объект со списком опций и текст для невыбранного значения.
 *
 * В качестве возможных опций можно передать простой объект с текстовыми ключами и значениями,
 * можно передать массив из сложных объектов и дополнительно указать наименования полей которые будут использоваться
 * для отображения значения и для передачи значения.
 * @deprecated
 */
export default {
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: String,
      default: ""
    },
    /**
     * Набор опций для выпадающего списка.
     */
    options: {
      type: Object,
      default() {
        return {
          "k": "v"
        };
      }
    },
    /**
     * Название поля в списке options, содержимое которого будет передаваться в качестве значения при выборе.
     */
    fieldValue: {
      type: String,
      required: true,
    },
    /**
     * Название поля в списке options, содержимое которого будет использоваться как название опции.
     */
    fieldLabel: {
      type: String,
      required: true,
    },
    /**
     * Название поля в списке options, содержимое которого будет использоваться как ключ сортировки.
     */
    fieldSort: {
      type: String,
      default: null,
    },
    /**
     * Название поля в списке options, содержимое которого будет использоваться как ключ сортировки.
     */
    showFinder: {
      type: Boolean,
    },
    /**
     * Содержимое для placeholder - используется в качестве неустановленного значения.
     */
    placeholder: {
      type: String,
      default: "Выберите из списка"
    },
    emptyValue: {
      type: String,
      default: "-пусто-",
    },
  },
  data() {
    return {
      newValue: this.value,
      searchText: '',

    };
  },
  computed: {
    displayOptions() {
      if (!this.searchText) {
        // Если инпут пустой, показываем все опции
        return this.parsedOptions;
      } else {
        // Иначе показываем отфильтрованные опции
        return this.filteredOptions;
      }
    },
    filteredOptions() {
      const filteredKeys = this.filterOptions();
      return Object.fromEntries(
        Object.entries(this.parsedOptions).filter(([key]) =>
          filteredKeys.includes(key)
        )
      );
    },
    /**
     * Вычисляет отображаемое значение - выбранное из списка или значение при не выбранной опции.
     *
     * @return {String}
     */
    valueForView() {
      return _.get(this.parsedOptions, this.newValue, this.placeholder);
    },
    /**
     * Вернет список опций для компонентов выпадающих списков из исходного набора опций.
     *
     * @return {Object}
     */
    parsedOptions() {
      if (this.fieldValue && this.fieldLabel) {
        let chain = _.chain(this.options);
        if (this.fieldSort) {
          chain = chain.sortBy(this.fieldSort);
        }
        return chain
          .keyBy(this.fieldValue)
          .mapValues(this.fieldLabel)
          .value();
      }
      return this.options;
    },
    /**
     * @return {Boolean} Признак наличия свойств в выпадающем списке.
     */
    isEmptyParsedOptions() {
      return _.isEmpty(this.parsedOptions);
    }
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события `@change`.
     *
     * @param {String} val
     */
    value(val) {
      this.newValue = val;
      this.$emit("change", val);
    }
  },
  methods: {
    filterOptions() {
      const searchValue = (this.searchText || '').toLowerCase(); // Добавляем дополнительную проверку на пустое значение
      if (!searchValue) return []; // Добавляем проверку на пустое значение
      return Object.keys(this.parsedOptions).filter(optionKey =>
        this.parsedOptions[optionKey].toLowerCase().includes(searchValue)
      );
    },
    /**
     * Метод срабатывает при клике на опцию в списке.
     * Новое значение сохраняется в компоненте и передается в родительский через `@input`, в конце список скрывается.
     *
     * @param {String} newValue
     */
    selectOption(newValue) {
      this.newValue = newValue;
      this.$emit("input", this.newValue);
      this.hideListOptions();
    },
    /**
     * Метод вызывается по событию клика в поле для открытия списка.
     * Переключает видимость списка.
     */
    toggleListOptions() {
      this.$el.classList.toggle("select_active");
    },
    /**
     * Метод скрывает список опций.
     * Его следует передавать как аргумент в директиву, что отслеживает клики за пределами элемента, в котором она указана.
     * Это нужно чтобы список скрывался при клике за его пределами.
     */
    hideListOptions() {
      this.$el.classList.remove("select_active");
    }
  },
};
</script>
