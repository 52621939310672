<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Клиенты
        </router-link>
        <router-link :to="{name: routes.ROUTE_ADMINS}" class="tabs__item">
          Персонал
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateClient"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_CLIENTS_FOR_TABLE,
  ACTION_LOAD_INFO_CLIENTS,
  EXTRAS_CLIENT,
  FIELDS_CLIENT,
  TITLES_FIELDS_CLIENT,
  TYPES_FIELDS_CLIENT
} from "@/store/users/clients/index.js";
import {ROUTE_ADMINS, ROUTE_CLIENTS, ROUTE_EDIT_CLIENT, ROUTE_EDIT_GANG} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateClientDialog from "@/components/users/clients/CreateClientDialog.vue";
import {FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по клиентам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_ADMINS,
      },
      currentRoute: ROUTE_CLIENTS,
      rawFields: FIELDS_CLIENT,
      columnCaptions: TITLES_FIELDS_CLIENT,
      nameActionLoadDataForTable: `clients/${ACTION_LOAD_CLIENTS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `clients/${ACTION_LOAD_INFO_CLIENTS}`,
      defaultFieldsTableForView: [
        FIELDS_CLIENT.id,
        FIELDS_CLIENT.username,
        FIELDS_CLIENT.about,
        FIELDS_CLIENT.is_active,
        FIELDS_CLIENT.last_login,
        FIELDS_CLIENT.date_joined,
        FIELDS_CLIENT.date_block,
        FIELDS_CLIENT.is_local_password,
        FIELDS_CLIENT.labels,
        FIELDS_CLIENT.gang_id,
        FIELDS_CLIENT.gang_admin_id,
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CLIENT.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_CLIENT.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_CLIENT.gang_admin]) {
        storedExtraInfo.gang_admin = {};
        sourceExtraInfo[EXTRAS_CLIENT.gang_admin].forEach((gangInfo) => {
          storedExtraInfo.gang_admin[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_CLIENT.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CLIENT, params: {clientId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CLIENT.username:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CLIENT, params: {clientId: rowData[FIELDS_CLIENT.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CLIENT.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      case FIELDS_CLIENT.gang_admin_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gang_admin[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CLIENT[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания клиента.
     */
    openDialogCreateClient() {
      this.$camsdals.open(
        CreateClientDialog,
        {},
        {dialogTitle: "Создание клиента"},
        {},
      );
    },
  },
};
</script>
