<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Камера
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ cameraNumber }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <router-link :to="routeEditCamera" active-class="tabs__item_active" class="tabs__item">
              Редактирование
            </router-link>
            <router-link :to="routeHistoryByCamera" active-class="tabs__item_active" class="tabs__item">
              История изменений
            </router-link>
            <router-link :to="routeEditCameraInventory" active-class="tabs__item_active" class="tabs__item">
              Инвентарные данные
            </router-link>
            <router-link :to="routeLogCameraWatchReasons" active-class="tabs__item_active" class="tabs__item">
              Обоснованный доступ
            </router-link>
            <router-link :to="routeLogViews" active-class="tabs__item_active" class="tabs__item">
              Логи просмотров
            </router-link>
            <router-link :to="routeCameraSetup" active-class="tabs__item_active" class="tabs__item">
              Управление камерой
            </router-link>
          </div>
        </div>

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ROUTE_CAMERA_HISTORY,
  ROUTE_CAMERA_SETUP,
  ROUTE_CAMERAS,
  ROUTE_EDIT_CAMERA,
  ROUTE_EDIT_CAMERA_INVENTORY,
  ROUTE_LOG_CAMERA_WATCH_REASONS_BY_CAMERA,
  ROUTE_LOG_VIEWS_BY_CAMERA
} from "@/router/names.js";
import {deletedEntityMarkMixin, routeBackMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_CAMERA_FOR_EDIT, FIELDS_CAMERA, FILTERS_CAMERA_HISTORY} from "@/store/cameras/index.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";

/**
 * Несамостоятельный компонент с элементами навигации по страницам редактирования камеры.
 */
export default {
  mixins: [routeBackMixin, deletedEntityMarkMixin],
  data() {
    return {
      entityId: this.$route.params.cameraNumber,
      nameActionLoadDataForEdit: `cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`,
      deletedField: FIELDS_CAMERA.is_deleted,
      routeBack: {name: ROUTE_CAMERAS},
      routeEditCamera: {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: this.$route.params.cameraNumber,}},
      routeCameraSetup: {name: ROUTE_CAMERA_SETUP, params: {cameraNumber: this.$route.params.cameraNumber}},
      routeEditCameraInventory: {name: ROUTE_EDIT_CAMERA_INVENTORY, params: {cameraNumber: this.$route.params.cameraNumber}},
      routeLogCameraWatchReasons: {name: ROUTE_LOG_CAMERA_WATCH_REASONS_BY_CAMERA, params: {cameraNumber: this.$route.params.cameraNumber}},
      routeLogViews: {name: ROUTE_LOG_VIEWS_BY_CAMERA, params: {cameraNumber: this.$route.params.cameraNumber}},
      routeHistoryByCamera: {name: ROUTE_CAMERA_HISTORY, params: {cameraNumber: this.$route.params.cameraNumber}},
      cameraNumber: this.$route.params.cameraNumber,
    };
  },
  /**
   * Уточняются маршруты для табов с учетом параметров маршрута назад на страницу с таблицей,
   * которая была в том состоянии (с нужными фильтрами) из которого был совершен на страницу редактирования.
   */
  created() {
    if (this.routeBack.query) {
      this.routeEditCamera.query = this.routeBack.query;
      this.routeEditCameraInventory.query = this.routeBack.query;
    }
    const paramsRouteHistoryByCamera = new TableQueryParams({
      filters: [
        new FilterData(FILTERS_CAMERA_HISTORY.number, "=", [this.$route.params.cameraNumber]),
      ],
    });
    this.routeHistoryByCamera = {
      name: ROUTE_CAMERA_HISTORY,
      query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteHistoryByCamera.stringify()}
    };
  },
};
</script>
