<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Настройка аналитики вторжения в зону
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info">
        <div class="content__settings__header">
          <div class="tabs">
            <router-link :to="routeEditCamera" active-class="tabs__item_active" class="tabs__item">
              Редактирование
            </router-link>
            <a class="tabs__item tabs__item_active" href="#">История изменений</a>
          </div>
        </div>
      </div>
      <SmartTable
        :calc-data-for-cell="calcDataForCell"
        :column-captions="columnCaptions"
        :current-route="currentRoute"
        :default-filters="defaultFilters"
        :key-field="rawFields.history_create_date"
        :name-action-load-data-for-table="nameActionLoadDataForTable"
        :name-action-load-info-for-table="nameActionLoadInfoForTable"
        :parse-extra="parseExtra"
        :raw-fields="rawFields"
        :initial-default-fields-table-for-view="defaultFieldsTableForView"
      />
    </div>
  </div>
</template>

<script>
import {
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_EDIT_PERIMETER_SECURITY,
  ROUTE_VIEW_ANALYTIC_PERIMETER_SECURITY_HISTORY
} from "@/router/names.js";
import {
  ACTION_LOAD_PERIMETER_SECURITY_HISTORY_FOR_TABLE,
  ACTION_LOAD_INFO_PERIMETER_SECURITY_HISTORY,
  EXTRAS_PERIMETER_SECURITY_HISTORY,
  FIELDS_PERIMETER_SECURITY_HISTORY,
  FILTERS_PERIMETER_SECURITY_HISTORY,
  TITLES_FIELDS_PERIMETER_SECURITY_HISTORY,
  TYPES_FIELDS_PERIMETER_SECURITY_HISTORY
} from "@/store/analytics/perimeterSecuritys/index.js";
import {FilterData} from "@/utils/helpers.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {FIELDS_ADMIN} from "@/store/users/admins/index.js";

/**
 * Таблица с отображением информации о редактировании аналитики.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);
    return {
      cameraNumber: this.$route.params.cameraNumber,
      entityId: this.$route.params.cameraNumber,
      routeEditCamera: {name: ROUTE_EDIT_PERIMETER_SECURITY, params: {cameraNumber: this.$route.params.cameraNumber}},
      routeBack: {name: ROUTE_PERIMETER_SECURITYS},
      currentRoute: ROUTE_VIEW_ANALYTIC_PERIMETER_SECURITY_HISTORY,
      rawFields: FIELDS_PERIMETER_SECURITY_HISTORY,
      columnCaptions: TITLES_FIELDS_PERIMETER_SECURITY_HISTORY,
      nameActionLoadDataForTable: `analytics/perimeterSecuritys/${ACTION_LOAD_PERIMETER_SECURITY_HISTORY_FOR_TABLE}`,
      nameActionLoadInfoForTable: `analytics/perimeterSecuritys/${ACTION_LOAD_INFO_PERIMETER_SECURITY_HISTORY}`,
      defaultFilters: [
        new FilterData(FILTERS_PERIMETER_SECURITY_HISTORY.history_create_date, ">=", [defaultFilterByDate]),
      ],
      defaultFieldsTableForView: [
        FIELDS_PERIMETER_SECURITY_HISTORY.history_create_date,
        FIELDS_PERIMETER_SECURITY_HISTORY.camera_number,
        FIELDS_PERIMETER_SECURITY_HISTORY.data_change_event_user_id,
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_PERIMETER_SECURITY_HISTORY.user]) {
        storedExtraInfo.admins = {};
        sourceExtraInfo[EXTRAS_PERIMETER_SECURITY_HISTORY.user].forEach((adminInfo) => {
          storedExtraInfo.admins[adminInfo[FIELDS_ADMIN.id]] = {
            id: adminInfo[FIELDS_ADMIN.id],
            name: adminInfo[FIELDS_ADMIN.username],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};
      switch (nameField) {
      case FIELDS_PERIMETER_SECURITY_HISTORY.data_change_event_user_id:
        readyTranslation = _.get(storedExtraInfo.admins[rawTranslation], "name", "-");
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_PERIMETER_SECURITY_HISTORY[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
