<template>
  <div class="one-screen">
    <form v-if="!hasAnyToken && !isLoading" @keyup.enter="requestTokens()" @submit.prevent="requestTokens()">
      <div class="row">
        <SmartInputText
          v-model="reason"
          :error="textError"
          :make-focus="true"
          caption="Укажите причину для просмотра видео"
          class="col"
        />
      </div>
      <div class="dialog-actions">
        <CamsButton type="button" @click="closeDialog()">
          Отменить
        </CamsButton>
        <CamsButton priority="primary" type="submit">
          Запросить
        </CamsButton>
      </div>
    </form>
    <div class="one-screen__main">
      <div v-if="!isCorrectData">
        <p><strong>Произошла ошибка. На этой камере невозможно запросить видеопоток.</strong></p>
      </div>
      <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

      <template v-else>
        <div v-if="hasAnyToken && !isLoading" class="main__camera ">
          <SmartPlayer
            :analytic-zones-config="analyticZonesConfig"
            :analytic-lines-config="analyticLinesConfig"
            :archive-token="archiveToken"
            :available-archive-fragments="availableArchiveFragments"
            :camera-number="cameraNumber"
            :domain="domain"
            :vendor-name="vendorName"
            :initial-low-latency-mode="lowLatencyMode || hasPtz"
            :has-ptz="hasPtz"
            :get-line-fragments="getLineFragments"
            :http-protocol="$store.getters.protocolVideoOverHTTP"
            :initial-time-shift="initialTimeShift"
            :live-token="liveToken"
            :max-unix-download-delta="maxUnixDownloadDelta"
            :min-unix-download-delta="minUnixDownloadDelta"
            :stream-count="streamCount"
            :ws-protocol="$store.getters.protocolVideoOverWS"
            class="smart-player-screen__player"
            :on-p-t-z-start="debouncedStartPTZ"
            :on-p-t-z-stop="stopPTZ"
            :on-p-t-z-centralize="centralizePTZ"
            @download-video="downloadVideo"
            @time-shift-update="changeTimeShift"
            @save-zones="saveZones"
            @save-lines="saveLines"
          />
          <!--iframe для скачивания-->
          <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
        </div>
        <div v-if="hasAnyToken && !isLoading && showSidebar" class="main__panel">
          <div class="panel__header">
            <button
              :class="{'btn--active': selectedTab === sideTabs.EVENTS}"
              class="btn"
              type="button"
              @click="selectedTab = sideTabs.EVENTS"
            >
              События
            </button>
          </div>

          <ArchiveEvents
            v-show="selectedTab === sideTabs.EVENTS"
            :archive-from="archiveFrom"
            :archive-to="archiveTo"
            :camera-number="cameraNumber"
            :camera-info="cameraInfo"
            :archive-token="archiveToken"
            class="panel__body"
            @play-event-start="playEventStart($event, cameraInfo)"
            @show-full-screenshot="showFullScreenshot($event, cameraInfo,archiveToken)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {
  ACTION_LOAD_RECORDING_STATUS, ACTION_PTZ_CAMERA, ACTION_REQUEST_TOKEN, TOKEN_TYPES,
} from "@/store/cameras/index.js";
import ArchiveEvents from "@/components/oneScreen/ArchiveEvents.vue";
import {eventHandlersMixin} from "@/components/events/mixins.js";
import {ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA, ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS} from "@/store/analytics/index.js";
import {MUTATION_SET_SHARED_TIME_SHIFT} from "@/store/mutations.js";
import {QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/components/analytics/mixins.js";
import {MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER, MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER} from "@/utils/consts.js";
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {AnalyticLinesConfig,AnalyticZonesConfig} from "camsng-frontend-shared/components/smartPlayer/mixins.js";

/**
 * Табы бокового меню, содержат блок с общей информацией по камере и блок с отображением информации по событиям аналитики.
 */
const SIDE_TABS = Object.freeze({
  EVENTS: "EVENTS",
  INFO: "INFO",
});

/**
 * Компонент с универсальным плеером для показа прямой трансляции и архива видео с камеры,
 * а так же встроенной боковой панелью, на которой отображаются дополнительные опции показа видео и событий аналитики.
 */
export default {
  name: "SmartPlayerScreen",
  mixins: [
    eventHandlersMixin,
    methodsForDialogMixin
  ],
  components: {
    SmartPlayer,
    ArchiveEvents,
  },
  props: {
    vendorName: {
      type: String,
      default: null,
    },
    streamCount: {
      type: Number,
      default: null,
    },
    cameraNumber: {
      type: String,
      default: null,
    },
    cameraInfo: {
      type: Object,
      required: true
    },
    showSidebar: {
      type: Boolean,
    },
    hasPtz: {
      type: Boolean,
      default: false,
    },
    domain: {
      type: String,
      default:""
    },
    address: {
      type: String,
      default:""
    },
    tokenTypes: {
      type: Array,
      default: () => ([TOKEN_TYPES.L, TOKEN_TYPES.R]),
    },
    analyticZonesConfig: {
      type: AnalyticZonesConfig,
      default: null,
    },
    analyticLinesConfig: {
      type: AnalyticLinesConfig,
      default: null,
    },
  },
  data() {
    const isCorrectData = this.cameraNumber && !_.isEmpty(this.tokenTypes) && this.domain && this.streamCount,
          initialTimeShift = new Date();
    initialTimeShift.setMinutes(initialTimeShift.getMinutes() - 10, 0, 0);

    return {
      isCorrectData: isCorrectData,
      isLoading: false,
      textError: "",
      reason: "",
      archiveToken: null,
      lowLatencyMode: false,
      liveToken: null,
      availableArchiveFragments: [],
      timeoutIdForReceivingRecordingStatus: null,
      downloadUrl: "",
      sideTabs: SIDE_TABS,
      forceBlockLiveMode: !this.tokenTypes.includes(TOKEN_TYPES.L),
      selectedTab: SIDE_TABS.EVENTS,
      currentTimeShift: null, // Отслеживаемый сдвиг во времени - необходим для запроса архивных событий.
      // todo требует корректировки в работе временной шкалы либо выносить новый плеер на данный срез
      initialTimeShift: !this.tokenTypes.includes(TOKEN_TYPES.L) ? initialTimeShift : null,// Необходимый сдвиг во времени - в случае перехода к конкретному времени (событию) в архиве.
      minUnixDownloadDelta: MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      maxUnixDownloadDelta: MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      neededTimeShift: this.currentTimeShift, // Необходимый сдвиг во времени - в случае перехода к конкретному времени (событию) в архиве.

    };
  },
  computed: {
    /**
     * @return {Date} Начальный отрезок времени для запроса архивных событий.
     */
    archiveFrom() {
      const calculatedArchiveFrom = new Date(this.currentTimeShift);
      calculatedArchiveFrom.setMinutes(calculatedArchiveFrom.getMinutes() - 5, 0, 0);
      return calculatedArchiveFrom;
    },
    /**
     * @return {Date} Конечный отрезок времени для запроса архивных событий.
     */
    archiveTo() {
      const calculatedArchiveTo = new Date(this.currentTimeShift);
      calculatedArchiveTo.setMinutes(calculatedArchiveTo.getMinutes() + 5, 0, 0);
      return calculatedArchiveTo;
    },
    hasAnyToken() {
      return Boolean(this.archiveToken || this.liveToken);
    }
  },
  watch: {
    /**
     * Признак того, что маршрут поменялся после нажатия на кнопку,
     * отображаемую при наведении на описание события.
     * Далее следует перевод временного сдвига плеера к точке события.
     *
     * @param {Object} to
     */
    "$route"(to) {
      if (to.params.isClickButtonToPlayEvent) {
        this.neededTimeShift = new Date(to.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT] - 1000);
      }
    },
    /**
     * Для того, чтобы держать актуальный url, следим за изменением режима просмотра
     * и при переключении на live-режим изменяем url.
     */
  },
  mounted() {
    if (this.$can(this.$abilitiesActions.SUPER_ACTION, this.$abilitiesSubjects.SUPER_SUBJECT)) {
      this.requestTokens();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeoutIdForReceivingRecordingStatus);
  },
  /**
   * Прерываем частые запросы на поворт камеры.
   */
  created() {
    this.debouncedStartPTZ = _.debounce(this.startPTZ, 0.1);
  },
  methods: {
    /**
     * Методы управления камерой ptz.
     */
    async sendPTZRequest({action, code, camera_number}) {
      const payload = {
        action,
        camera_number,
      };
      if (code) {
        payload.code = code;
      }
      await this.$store.dispatch(`cameras/${ACTION_PTZ_CAMERA}`, payload);
    },
    async startPTZ(direction) {
      await this.sendPTZRequest({action: 'start', code: direction, camera_number: this.cameraNumber});
    },
    async stopPTZ() {
      clearInterval(this.ptzInterval);
      await this.sendPTZRequest({action: 'stop', code: '', camera_number:this.cameraNumber});
    },
    async centralizePTZ() {
      await this.sendPTZRequest({action: 'centralize', code: '', camera_number: this.cameraNumber});
    },
    /**
     * Отправка запроса.
     * В случае успешного получения токена открывается плеер для воспроизведения видео.
     */
    async requestTokens() {
      this.textError = "";
      if (!this.reason && !this.$can(this.$abilitiesActions.SUPER_ACTION, this.$abilitiesSubjects.SUPER_SUBJECT)) {
        this.textError = "Необходимо указать причину, по которой вам потребовалось смотреть видео с камеры";
        return;
      }

      this.isLoading = true;
      for (const tokenType of this.tokenTypes) {
        // todo обработать ошибку в catch
        // eslint-disable-next-line no-useless-catch
        try {
          const results = await this.$store.dispatch(`cameras/${ACTION_REQUEST_TOKEN}`, {
            camerasNumbers: [this.cameraNumber],
            tokenType: tokenType,
            reason: this.reason,
          });

          if (tokenType === TOKEN_TYPES.L) {
            this.liveToken = results[0].token;
          }
          if (tokenType === TOKEN_TYPES.R) {
            this.archiveToken = results[0].token;
            await this.receiveRecordingStatus();
          }
        } catch (error) {
          throw error;
        }
      }
      this.isLoading = false;

    },

    /**
     * Сохранить дату, соответствующую метке на временной шкале находясь в режиме просмотра архива.
     * Сохранение происходит ежесекундно поскольку после выбора времени на шкале
     * необходимо постоянно иметь актуальное архивное время при движении метки по шкале.
     *
     * @param {Date} timeShift
     */
    changeTimeShift(timeShift) {
      this.currentTimeShift = timeShift;
      this.$store.commit(MUTATION_SET_SHARED_TIME_SHIFT, timeShift);
    },

    /**
     * Функция для перекрытия и запуска операций, которые необходимо выполнить после загрузки
     * информации по камере.
     *
     * @see loadCameraInfoMixin.methods.afterLoadCameraInfo
     * @return {Promise}
     */
    /**
     * Получение доступных фрагментов видео от сервера. Периодический ее вызов для актуализации данных в плеере.
     */
    async receiveRecordingStatus() {
      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUS}`, {
        cameraNumber: this.cameraNumber,
        domain: this.domain,
        token: this.archiveToken,
      });
    },
    /**
     * Отправка запроса на получение токена для скачивания фрагмента видео.
     * При его получении формирование URL и автоматический запуск скачивания.
     *
     * @param {Function} getterDownloadUrl
     * @param {Number} unixDownloadFrom
     * @param {Number} unixDownloadDelta
     */
    async downloadVideo([getterDownloadUrl, unixDownloadFrom, unixDownloadDelta]) {
      // Конкретные ограничения по длительности запрашиваемого видео.
      if ((unixDownloadDelta < MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER) || (unixDownloadDelta > MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER)) {
        this.$camsdals.alert("Невозможно скачать. Выбран некорректный диапазон времени, попробуйте изменить границы скачивания.");
        return;
      }

      let tokenDownload = null;
      this.isLoadingDownloadToken = true;
      try {
        const tokens = await this.$store.dispatch(`cameras/${ACTION_REQUEST_TOKEN}`, {
          camerasNumbers: [this.cameraNumber],
          tokenType: TOKEN_TYPES.D,
          reason: this.reason,
          start: unixDownloadFrom,
          duration: unixDownloadDelta,
        });
        tokenDownload = tokens[0].token;
      } catch (error) {
        devLog("[downloadVideo]", error);
      }

      if (tokenDownload) {
        this.downloadUrl = getterDownloadUrl(tokenDownload);
      } else {
        this.$camsdals.alert("Невозможно скачать. Попробуйте позднее.");
      }
      this.isLoadingDownloadToken = false;
    },
    /**
     * Функция должна вернуть актуальный набор данных для отображения на временной шкале.
     * Для доступного архива фильтруются доступные участки для рисования, уточняется их цвет и низший приоритет.
     * Для событий - они запрашиваются из API и преобразуются из объектов сообщений в простую структуру для выгрузки.
     *
     * @param {Date} leftBoundary
     * @param {Date} rightBoundary
     * @return {Promise<Array<Array<Date, Date, String, Number>>>}
     */
    async getLineFragments(leftBoundary, rightBoundary) {
      const availableArchiveFragments = this.availableArchiveFragments.filter(([startDate, endDate]) => {
        return +leftBoundary < +endDate && +rightBoundary > +startDate;
      }).map(([startDate, endDate]) => {
        return [startDate, endDate, "#bbbbbb", 0]; // Приоритет 0 = Архив.
      });

      let archiveEventsFragments = [];
      if ((rightBoundary - leftBoundary) > 86400000) {
        // На шкале больше суток.
        const archiveEventsChunks = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS}`, {
          cameraNumber: this.cameraNumber,
          archiveFrom: leftBoundary,
          archiveTo: rightBoundary,
        });
        archiveEventsFragments = archiveEventsChunks.map((chunk) => {
          return [chunk.startDate, chunk.endDate, chunk.color, chunk.priority];
        });

        // Обработка случая когда смотрим на live а чанков за этот период еще не собрано (обычно за последний час нет чанков),
        // тогда берется следующее событие и из его времени создается псевдо чанк длиной до конца архива.
        const lastChunk = archiveEventsChunks[0];
        if (lastChunk) {
          const archiveEventsNotInChunk = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA}`, {
                  cameraNumber: this.cameraNumber,
                  archiveFrom: new Date(+lastChunk.endDate + 1000),
                  archiveTo: rightBoundary,
                  limit: 1,
                  orderByDate: "asc"
                }),
                messageNotInChunk = archiveEventsNotInChunk[0];
          if (messageNotInChunk) {
            archiveEventsFragments.push([messageNotInChunk.startDate, rightBoundary, messageNotInChunk.color, messageNotInChunk.priority]);
          }
        }
      } else {
        // На шкале меньше суток.
        const archiveEvents = await this.$store.dispatch(`analytics/${ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA}`, {
          cameraNumber: this.cameraNumber,
          archiveFrom: leftBoundary,
          archiveTo: rightBoundary,
        });
        archiveEventsFragments = archiveEvents.map((message) => {
          return [message.startDate, message.endDate, message.color, message.priority];
        });
      }

      return [...availableArchiveFragments, ...archiveEventsFragments];
    },
    saveZones(analyticZonesConfig) {
      this.closeDialog(analyticZonesConfig);
    },
    saveLines(analyticLinesConfig) {
      this.closeDialog(analyticLinesConfig);
    },
  }
};
</script>
