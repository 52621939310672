<template>
  <div class="event-playback-dialog">
    <SmartPlayer
      :archive-token="archiveToken"
      :available-archive-fragments="[[archiveFrom, archiveTo]]"
      :camera-number="cameraNumber"
      :domain="domain"
      :expectation-archive-mode="false"
      :initial-time-shift="archiveFrom"
      :stream-count="streamCount"
      :use-simple-interface="true"
      class="event-playback-dialog__player"
    />
    <p class="event-playback-dialog__caption" v-text="analyticMessage.summarize" />
  </div>
</template>

<script>
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {AnalyticMessage} from "@/store/analytics/analyticMessage.js";
import {ACTION_LOAD_RECORDING_STATUS} from "@/store/cameras/index.js";

/**
 * Вторичное диалоговое окно, которое нужно только для проигрывания в плеере заданного участка события.
 * Это окно всплывает поверх другого модального окна. Нужно учесть это и закрыть их в правильном порядке, чтоб не наслаивались.
 *
 * Так как компонент - прокси для плеера с заготовкой по временным рамкам, то здесь эмпирически подобраны временные смещения
 * для запроса участка видео. С определенного периода флюсоник отдает видео фрагменты длиной кратно 5 (+2+3 секунды).
 */
export default {
  name: "EventPlaybackDialog",
  components: {
    SmartPlayer,
  },
  props: {
    streamCount: {
      type: Number,
      default: null,
    },
    cameraNumber: {
      type: String,
      default: null,
    },
    domain: {
      type: String,
      default:""
    },
    archiveToken: {
      type: String
    },
    /**
     * Экземпляр {@link AnalyticMessage} по которому отображается информация в компоненте.
     */
    analyticMessage: {
      type: AnalyticMessage,
      required: true,
    },
  },
  data(){
    return {
      availableArchiveFragments: []
    };
  },
  created() {
    this.receiveRecordingStatus();
  },
  methods: {
    async receiveRecordingStatus() {
      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUS}`, {
        cameraNumber: this.cameraNumber,
        domain: this.domain,
        token: this.archiveToken,
      });
    },
  },
  computed: {

    /**
     * Начало отрезка времени для запроса архивного фрагмента видео.
     */
    archiveFrom() {
      const archiveFrom = new Date(+this.analyticMessage.startDate);
      archiveFrom.setSeconds(archiveFrom.getSeconds() - 15);
      return archiveFrom;

    },
    /**
     * Конец отрезка времени для запроса архивного фрагмента видео.
     */
    archiveTo() {
      const archiveTo = new Date(+this.analyticMessage.endDate);
      archiveTo.setSeconds(archiveTo.getSeconds() + 1);
      return archiveTo;
    },
  },
};
</script>


<style lang="scss">
@import "../../../node_modules/camsng-frontend-shared/styles/vars";

.event-playback-dialog {
  max-width: 816px;
  &__player {
    width: 816px;
    height: 460px;
  }

  &__caption {
    font-family: $cams-font-roboto-light;
    font-size: 13px;
  }
}
</style>
