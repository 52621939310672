/**
 * Перечень мутаций для хранилища состояния приложения.
 */

export const MUTATION_RESET_STATE = "RESET_STATE";
export const MUTATION_SET_USERNAME = "SET_USERNAME";
export const MUTATION_SET_TOKEN = "SET_TOKEN";
export const MUTATION_SET_CONTEXT = "SET_CONTEXT";
export const MUTATION_CHANGE_DEFAULT_CENTER_MAP = "CHANGE_DEFAULT_CENTER_MAP";
export const MUTATION_SET_ETAG = "SET_ETAG";
export const MUTATION_SET_NEED_UPDATE = "SET_NEED_UPDATE";
export const MUTATION_SET_SHARED_TIME_SHIFT = "SET_SHARED_TIME_SHIFT";
