/**
 * Перечень имен для маршрутов навигации в приложении.
 *
 * Некоторые маршруты идут с префиксом ROOT_ - они используются как корневые для страниц на которых осуществляется
 * переход к таблицам разных сущностей. Переход к общему корневому маршруту должен выполнять редирект на сущность
 * по умолчанию. Этот подход используется как простой способ сделать маркировку активных ссылок на главном меню,
 * будучи на странице некоторой внутренней вложенности.
 */

export const ROUTE_403 = "403",
  ROUTE_ROOT = "ROOT",
  ROUTE_CAMERAS = "CAMERAS",
  ROUTE_EDIT_CAMERA = "EDIT_CAMERA",
  ROUTE_EDIT_CAMERA_INVENTORY = "EDIT_CAMERA_INVENTORY",
  ROUTE_LOG_CAMERA_WATCH_REASONS_BY_CAMERA = "LOG_CAMERA_WATCH_REASONS_BY_CAMERA",
  ROUTE_LOG_VIEWS_BY_CAMERA = "LOG_VIEWS_BY_CAMERA",
  ROUTE_ROOT_CAMERA_GROUPS = "ROOT_CAMERA_GROUPS",
  ROUTE_CAMERA_GROUPS = "CAMERA_GROUPS",
  ROUTE_EDIT_CAMERA_GROUP = "EDIT_CAMERA_GROUP",
  ROUTE_CAMERA_GROUP_TYPES = "CAMERA_GROUP_TYPES",
  ROUTE_EDIT_CAMERA_GROUP_TYPE = "EDIT_CAMERA_GROUP_TYPE",
  ROUTE_ON_MAP = "ON_MAP",
  ROUTE_ROOT_HEAVY_METAL = "ROOT_HEAVY_METAL",
  ROUTE_SERVERS = "SERVERS",
  ROUTE_EDIT_SERVER = "EDIT_SERVER",
  ROUTE_CLUSTERS = "CLUSTERS",
  ROUTE_EDIT_CLUSTER = "EDIT_CLUSTER",
  ROUTE_TARIFFS = "TARIFFS",
  ROUTE_EDIT_TARIFF = "EDIT_TARIFF",
  ROUTE_ROOT_USERS = "ROOT_USERS",
  ROUTE_CLIENTS = "CLIENTS",
  ROUTE_EDIT_CLIENT = "EDIT_CLIENT",
  ROUTE_LOG_VIEWS_BY_CLIENT = "LOG_VIEWS_BY_CLIENT",
  ROUTE_LOG_AUTHS_BY_CLIENT = "LOG_AUTHS_BY_CLIENT",
  ROUTE_ADMINS = "ADMINS",
  ROUTE_EDIT_ADMIN = "EDIT_ADMIN",
  ROUTE_LOG_CAMERA_WATCH_REASONS_BY_ADMIN = "LOG_CAMERA_WATCH_REASONS_BY_ADMIN",
  ROUTE_LOG_VIEWS_BY_ADMIN = "LOG_VIEWS_BY_ADMIN",
  ROUTE_LOG_AUTHS_BY_ADMIN = "LOG_AUTHS_BY_ADMIN",
  ROUTE_DOMAIN_SETTINGS = "DOMAIN_SETTINGS",
  ROUTE_EDIT_DOMAIN_SETTING = "DOMAIN_SETTING",
  ROUTE_RIGHT_GROUPS = "RIGHT_GROUPS",
  ROUTE_EDIT_RIGHT_GROUP = "RIGHT_GROUP",
  ROUTE_VIEW_RIGHT_GROUP_HISTORY = "VIEW_RIGHT_GROUP_HISTORY",
  ROUTE_ROOT_LOGS = "ROOT_LOGS",
  ROUTE_LOG_CAMERA_WATCH_REASONS = "LOG_CAMERA_WATCH_REASONS",
  ROUTE_LOG_VIEWS = "LOG_VIEWS",
  ROUTE_LOG_AUTHS = "LOG_AUTHS",
  ROUTE_LOG_UCAMSGO = "LOG_UCAMSGO",
  ROUTE_ROOT_ANALYTICS = "ROOT_ANALYTICS",
  ROUTE_ROOT_ANALYTICS_SETTINGS = "ROUTE_ROOT_ANALYTICS_SETTINGS",
  ROUTE_ANALYTIC_SERVERS = "ANALYTIC_SERVERS",
  ROUTE_EDIT_ANALYTIC_SERVER = "EDIT_ANALYTIC_SERVER",
  ROUTE_THERMAL_VISIONS = "THERMAL_VISIONS",
  ROUTE_EDIT_THERMAL_VISION = "THERMAL_VISION",
  ROUTE_CAR_NUMBERS = "CAR_NUMBERS",
  ROUTE_EDIT_CAR_NUMBER = "EDIT_CAR_NUMBER",
  ROUTE_FACE_RECOGNITIONS = "FACE_RECOGNITIONS",
  ROUTE_EDIT_FACE_RECOGNITION = "EDIT_FACE_RECOGNITION",
  ROUTE_HELMETS = "HELMETS",
  ROUTE_EDIT_HELMET = "EDIT_HELMET",
  ROUTE_MOTION_ALARMS = "MOTION_ALARMS",
  ROUTE_EDIT_MOTION_ALARM = "EDIT_MOTION_ALARM",
  ROUTE_EMPLOYEES = "EMPLOYEES",
  ROUTE_EDIT_EMPLOYEE = "EDIT_EMPLOYEE",
  ROUTE_GANGS = "GANGS",
  ROUTE_EDIT_GANG = "EDIT_GANG",
  ROUTE_CARS = "CARS",
  ROUTE_EDIT_CAR = "EDIT_CAR",
  ROUTE_DEVICES = "DEVICES",
  ROUTE_EDIT_DEVICE = "EDIT_DEVICE",
  ROUTE_DEVICE_ACCESS_GROUPS = "DEVICE_ACCESS_GROUPS",
  ROUTE_EDIT_DEVICE_ACCESS_GROUP = "EDIT_DEVICE_ACCESS_GROUP",
  ROUTE_PHOTO_FOR_EMPLOYEE = "PHOTO_FOR_EMPLOYEE",
  ROUTE_ANALYTIC_SERVERS_LOGS = "ROUTE_ANALYTIC_SERVERS_LOGS",
  ROUTE_MARKERS = "MARKERS",
  ROUTE_EDIT_MARKER = "ROUTE EDIT MARKER",
  ROUTE_MASKS = "MASKS",
  ROUTE_EDIT_MASK = "ROUTE EDIT MASK",
  ROUTE_CROWDS = "CROWDS",
  ROUTE_EDIT_CROWD = "ROUTE EDIT CROWD",
  ROUTE_HISTORY = "ROUTE HISTORY",
  ROUTE_CAMERA_HISTORY = "ROUTE_CAMERA_HISTORY",
  ROUTE_PERIMETER_SECURITYS = "PERIMETER_SECURITYS",
  ROUTE_EDIT_PERIMETER_SECURITY = "ROUTE EDIT PERIMETER_SECURITY",
  ROUTE_BOLID_SERVERS = "BOLID_SERVERS",
  ROUTE_EDIT_BOLID_SERVER = "EDIT_BOLID_SERVER",
  ROUTE_BOLID_DEVICES = "BOLID_DEVICES",
  ROUTE_BOLID_EVENTS = "BOLID_EVENTS",
  ROUTE_VIEW_ANALYTIC_CAR_NUMBER_HISTORY = "VIEW_ANALYTIC_CAR_NUMBER_HISTORY",
  ROUTE_VIEW_ANALYTIC_CROWD_HISTORY = "VIEW_ANALYTIC_CROWD_HISTORY",
  ROUTE_VIEW_ANALYTIC_FACE_RECOGNITION_HISTORY = "VIEW_ANALYTIC_FACE_RECOGNITION_HISTORY",
  ROUTE_VIEW_ANALYTIC_HELMET_HISTORY = "VIEW_ANALYTIC_HELMET_HISTORY",
  ROUTE_VIEW_ANALYTIC_MASK_HISTORY = "VIEW_ANALYTIC_MASK_HISTORY",
  ROUTE_VIEW_ANALYTIC_MOTION_ALARM_HISTORY = "VIEW_ANALYTIC_MOTION_ALARM_HISTORY",
  ROUTE_VIEW_ANALYTIC_PERIMETER_SECURITY_HISTORY = "VIEW_ANALYTIC_PERIMETER_SECURITY_HISTORY",
  ROUTE_VIEW_ANALYTIC_THERMAL_VISION_HISTORY = "VIEW_ANALYTIC_THERMAL_VISION_HISTORY",
  ROUTE_VIEW_ANALYTIC_SERVER_HISTORY = "VIEW_ANALYTIC_SERVER_HISTORY",
  ROUTE_VIEW_SERVER_HISTORY = "VIEW_SERVER_HISTORY",
  ROUTE_PEOPLE_COUNTS = "PEOPLE_COUNTS",
  ROUTE_EDIT_PEOPLE_COUNT = "ROUTE EDIT PEOPLE_COUNT",
  ROUTE_VIEW_ANALYTIC_PEOPLE_COUNT_HISTORY = "VIEW_ANALYTIC_PEOPLE_COUNT_HISTORY",
  ROUTE_VIEW_DEVICE_HISTORY = "VIEW_DEVICE_HISTORY",
  ROUTE_VIEW_DEVICE_MODULE_HISTORY = "VIEW_DEVICE_MODULE_HISTORY",
  ROUTE_VIEW_USER_LINKS_HISTORY = "VIEW_VIEW_USER_LINKS_HISTORY",
  ROUTE_CAMERA_SETUP = "CAMERA_SETUP",
  ROUTE_FIRMWARES = "FIRMWARES",
  ROUTE_EDIT_FIRMWARE = "ROUTE EDIT FIRMWARE",
  ROUTE_VIEW_ADMIN_PERMISSIONS_HISTORY = "ROUTE_VIEW_ADMIN_PERMISSIONS_HISTORY",
  ROUTE_VIEW_ADMIN_PERMISSIONS_GROUP_HISTORY = "ROUTE_VIEW_ADMIN_PERMISSIONS_GROUP_HISTORY",
  ROUTE_VIEW_USER_PERMISSIONS_HISTORY = "ROUTE_VIEW_USER_PERMISSIONS_HISTORY",
  ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY = "ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY",
  ROUTE_EDIT_ANALYTIC_CLUSTER = "EDIT_ANALYTIC_CLUSTER",
  ROUTE_ANALYTIC_CLUSTERS = "ROUTE_ANALYTIC_CLUSTERS",
  ROUTE_MIGRATION = "ROUTE_MIGRATION",
  ROUTE_ANALYTIC_CAR_NUMBER_REPORT = "ROUTE_ANALYTIC_CAR_NUMBER_REPORT";

