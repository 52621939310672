<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: routes.ROUTE_DEVICES }" class="tabs__item">
          Устройства доступа
        </router-link>
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Сотрудники
        </router-link>
        <router-link :to="{ name: routes.ROUTE_DEVICE_ACCESS_GROUPS }" class="tabs__item">
          Группы доступа
        </router-link>
        <router-link :to="{name: routes.ROUTE_CARS}" class="tabs__item">
          Автомобили
        </router-link>
      </div>
    </div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateEmployee"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_EMPLOYEES_FOR_TABLE,
  ACTION_LOAD_INFO_EMPLOYEES,
  EXTRAS_EMPLOYEE,
  FIELDS_EMPLOYEE,
  FILTERS_EMPLOYEE,
  TITLES_FIELDS_EMPLOYEE,
  TYPES_FIELDS_EMPLOYEE,
} from "@/store/pacs/employees/index.js";
import {
  ROUTE_CARS,
  ROUTE_DEVICE_ACCESS_GROUPS,
  ROUTE_DEVICES,
  ROUTE_EDIT_CLIENT,
  ROUTE_EDIT_EMPLOYEE,
  ROUTE_EDIT_GANG,
  ROUTE_EMPLOYEES,
} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateEmployeeDialog from "@/components/pacs/employees/CreateEmployeeDialog.vue";
import {FIELDS_CLIENT} from "@/store/users/clients/index.js";
import {FIELDS_GANG} from "@/store/gangs/index.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {FIELDS_DEVICE_ACCESS_GROUP, FILTERS_DEVICE_ACCESS_GROUP} from "@/store/pacs/deviceAccessGroups/index.js";
import {FIELDS_CAR, FILTERS_CAR} from "@/store/pacs/cars/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по сотрудникам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_CARS,
        ROUTE_DEVICES,
        ROUTE_DEVICE_ACCESS_GROUPS,
      },
      defaultFieldsTableForView: [
        FIELDS_EMPLOYEE.id,
        FIELDS_EMPLOYEE.title,
        FIELDS_EMPLOYEE.gang_id,
        FIELDS_EMPLOYEE.car_ids,
        FIELDS_EMPLOYEE.device_access_group_ids,
        FIELDS_EMPLOYEE.is_active,
        FIELDS_EMPLOYEE.is_deleted,
      ],
      currentRoute: ROUTE_EMPLOYEES,
      rawFields: FIELDS_EMPLOYEE,
      columnCaptions: TITLES_FIELDS_EMPLOYEE,
      nameActionLoadDataForTable: `employees/${ACTION_LOAD_EMPLOYEES_FOR_TABLE}`,
      nameActionLoadInfoForTable: `employees/${ACTION_LOAD_INFO_EMPLOYEES}`,
      defaultFilters: [
        new FilterData(FILTERS_EMPLOYEE.is_deleted, "=", [false])
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_EMPLOYEE.user]) {
        storedExtraInfo.users = {};
        sourceExtraInfo[EXTRAS_EMPLOYEE.user].forEach((userInfo) => {
          storedExtraInfo.users[userInfo[FIELDS_CLIENT.id]] = {
            id: userInfo[FIELDS_CLIENT.id],
            name: userInfo[FIELDS_CLIENT.username],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_EMPLOYEE.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_EMPLOYEE.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_EMPLOYEE.device_access_group]) {
        storedExtraInfo.deviceAccessGroups = {};
        sourceExtraInfo[EXTRAS_EMPLOYEE.device_access_group].forEach((groupInfo) => {
          storedExtraInfo.deviceAccessGroups[groupInfo[FIELDS_DEVICE_ACCESS_GROUP.id]] = {
            id: groupInfo[FIELDS_DEVICE_ACCESS_GROUP.id],
            group: groupInfo[FIELDS_DEVICE_ACCESS_GROUP.title],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_EMPLOYEE.car]) {
        storedExtraInfo.cars = {};
        sourceExtraInfo[EXTRAS_EMPLOYEE.car].forEach((carInfo) => {
          storedExtraInfo.cars[carInfo[FIELDS_CAR.id]] = {
            id: carInfo[FIELDS_CAR.id],
            number: carInfo[FIELDS_CAR.number],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {},
          params;

      switch (nameField) {
      case FIELDS_EMPLOYEE.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_EMPLOYEE, params: {employeeId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_EMPLOYEE.title:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_EMPLOYEE, params: {employeeId: rowData[FIELDS_EMPLOYEE.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_EMPLOYEE.user_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.users[rawTranslation], "name", "-");
          readyParams = {name: ROUTE_EDIT_CLIENT, params: {clientId: rawTranslation}};
        }
        break;
      case FIELDS_EMPLOYEE.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      case FIELDS_EMPLOYEE.device_access_group_ids:
        readyTranslation = _.chain(storedExtraInfo.deviceAccessGroups)
          .pick(rawTranslation)
          .mapValues("group")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_DEVICE_ACCESS_GROUP.employee_id, "=", [rowData[FIELDS_EMPLOYEE.id]])]});
        readyParams = {name: ROUTE_DEVICE_ACCESS_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_EMPLOYEE.car_ids:
        readyTranslation = _.chain(storedExtraInfo.cars)
          .pick(rawTranslation)
          .mapValues("number")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAR.employee_id, "=", [rowData[FIELDS_EMPLOYEE.id]])]});
        readyParams = {name: ROUTE_CARS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_EMPLOYEE[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания сотрудника.
     */
    openDialogCreateEmployee() {
      this.$camsdals.open(CreateEmployeeDialog, {}, {dialogTitle: "Создание сотрудника"});
    },
  },
};
</script>
