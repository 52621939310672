/**
 * Отдельный vuex модуль для взаимодействия по domain_settings-API
 */

import {DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для domainSettings. Вызов действия начинать с "domainSettings/"
export const ACTION_LOAD_INFO_DOMAIN_SETTINGS = "LOAD_INFO_DOMAIN_SETTINGS";
export const ACTION_LOAD_DOMAIN_SETTINGS = "LOAD_DOMAIN_SETTINGS";
export const ACTION_LOAD_DOMAIN_SETTINGS_FOR_TABLE = "LOAD_DOMAIN_SETTINGS_FOR_TABLE";
export const ACTION_LOAD_DOMAIN_SETTING_FOR_EDIT = "LOAD_DOMAIN_SETTING_FOR_EDIT";
export const ACTION_CREATE_DOMAIN_SETTING = "CREATE_DOMAIN_SETTING";
export const ACTION_EDIT_DOMAIN_SETTING = "EDIT_DOMAIN_SETTING";
export const ACTION_DELETE_DOMAIN_SETTING = "DELETE_DOMAIN_SETTING";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_DOMAIN_SETTING = Object.freeze({
  id: "id",
  domain: "domain",
  title: "title",
  favicon: "favicon",
  logo: "logo",
  logo_link: "logo_link",
  is_deleted: "is_deleted",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_DOMAIN_SETTING = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей доменных настроек.
 */
export const TITLES_FIELDS_DOMAIN_SETTING = {
  [FIELDS_DOMAIN_SETTING.id]: "ID",
  [FIELDS_DOMAIN_SETTING.domain]: "Доменное имя",
  [FIELDS_DOMAIN_SETTING.title]: "Заголовок сайта",
  [FIELDS_DOMAIN_SETTING.favicon]: "Favicon (base64)",
  [FIELDS_DOMAIN_SETTING.logo]: "Логотип (base64)",
  [FIELDS_DOMAIN_SETTING.logo_link]: "Ссылка по нажатию на логотип",
  [FIELDS_DOMAIN_SETTING.is_deleted]: "Удален",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_DOMAIN_SETTING = {
  [FIELDS_DOMAIN_SETTING.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_DOMAIN_SETTING.domain]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_DOMAIN_SETTING.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для доменных настроек.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_DOMAIN_SETTINGS]() {
      const response = await this.getters.privateAjax.post("/v0/domain_settings/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка доменных настроек.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_DOMAIN_SETTINGS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/domain_settings/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка доменных настроек для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DOMAIN_SETTINGS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_DOMAIN_SETTINGS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_DOMAIN_SETTINGS_FOR_TABLE});
    },
    /**
     * Загрузка одного домена с настройками для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} domainSettingId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_DOMAIN_SETTING_FOR_EDIT]({dispatch}, [domainSettingId, fields]) {
      const filter = makeFilterApi(FILTERS_DOMAIN_SETTING.id, "=", domainSettingId),
        responseData = await dispatch(ACTION_LOAD_DOMAIN_SETTINGS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового домена с настройками.
     *
     * @param {Object} context
     * @param {Object} domainSettingInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_DOMAIN_SETTING](context, domainSettingInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/domain_settings/create/", domainSettingInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего домена с настройками.
     *
     * @param {Object} context
     * @param {Object} domainSettingInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_DOMAIN_SETTING](context, domainSettingInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/domain_settings/edit/", domainSettingInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление домена с настройками.
     *
     * @param {Object} context
     * @param {Array} domainSettingsIds
     * @return {Promise}
     */
    async [ACTION_DELETE_DOMAIN_SETTING](context, {domainSettingsIds}) {
      return this.getters.privateAjax.post("/v0/domain_settings/delete/", {ids: domainSettingsIds});
    },
  },
};
