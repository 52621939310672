<template>
  <div v-click-outside="hideListOptions" :class="['dropdown', {'dropdown__opened': isOpened}]">
    <div class="dropdown__toggle" style="margin-top: 5px">
      <button class="button button_icon  button_dropdown" type="button" @click="toggleListOptions">
        <svg v-if="iconSelectedValue==='icon-archive'" class="icon icon-play-panel">
          <use xlink:href="@/assets/img/icons.svg#icon-archive" />
        </svg>
        <svg v-if="iconSelectedValue==='icon-owner'" class="icon icon-play-panel">
          <use xlink:href="@/assets/img/icons.svg#icon-owner" />
        </svg>
        <svg v-if="iconSelectedValue==='icon-play'" class="icon icon-play-panel">
          <use xlink:href="@/assets/img/icons.svg#icon-play" />
        </svg>
        <svg class="icon icon-arrow-breadcrumb">
          <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
        </svg>
      </button>
    </div>

    <div class="dropdown__content">
      <div class="dropdown__content__title">
        Выберите уровень доступа
      </div>
      <div class="dropdown__content__list dropdown-list">
        <div class="dropdown-list__item" @click="selectOption(permissions.STREAM)">
          <svg class="icon-play-panel">
            <use xlink:href="@/assets/img/icons.svg#icon-play" />
          </svg> Поток
        </div>
        <div class="dropdown-list__item" @click="selectOption(permissions.ARCHIVE)">
          <svg class="icon-play-panel">
            <use xlink:href="@/assets/img/icons.svg#icon-archive" />
          </svg> Архив
        </div>
        <div class="dropdown-list__item" @click="selectOption(permissions.OWNER)">
          <svg class="icon-play-panel">
            <use xlink:href="@/assets/img/icons.svg#icon-owner" />
          </svg> Владелец
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PERMISSIONS_CAMERAS} from "@/store/permissions/index.js";

/**
 * Компонент для управления правами клиентов. Похож на список {@link SmartSelect},
 * но тут он выглядит по другому и элементы заранее известны.
 */
export default {
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: Number,
      default: PERMISSIONS_CAMERAS.ARCHIVE
    },
  },
  data() {
    return {
      isOpened: false,
      newValue: this.value,
      permissions: PERMISSIONS_CAMERAS,
    };
  },
  computed: {
    iconSelectedValue() {
      return {
        [PERMISSIONS_CAMERAS.OWNER]: "icon-owner",
        [PERMISSIONS_CAMERAS.ARCHIVE]: "icon-archive",
        [PERMISSIONS_CAMERAS.STREAM]: "icon-play",
      }[this.newValue];
    },
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события `@change`.
     *
     * @param {String} val
     */
    value(val) {
      this.newValue = val;
      this.$emit("change", val);
    }
  },
  methods: {
    /**
     * Метод срабатывает при клике на опцию в списке.
     * Новое значение сохраняется в компоненте и передается в родительский через `@input`, в конце список скрывается.
     *
     * @param {Number} newPermissions
     */
    selectOption(newPermissions) {
      this.newValue = newPermissions;
      this.$emit("input", newPermissions);
      this.hideListOptions();
    },
    /**
     * Метод вызывается по событию клика в поле для открытия списка.
     * Переключает видимость списка.
     */
    toggleListOptions() {
      this.isOpened = !this.isOpened;
    },
    /**
     * Метод скрывает список опций.
     * Его следует передавать как аргумент в директиву, что отслеживает клики за пределами элемента, в котором она указана.
     * Это нужно чтобы список скрывался при клике за его пределами.
     */
    hideListOptions() {
      this.isOpened = false;
    }
  },
};
</script>
