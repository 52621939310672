<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: routes.ROUTE_CAMERAS }" class="tabs__item">
          Камеры
        </router-link>
        <router-link :to="{ name: routes.ROUTE_MARKERS }" class="tabs__item">
          Маркеры камер
        </router-link>
        <router-link :to="{ name: routes.ROUTE_HISTORY }" class="tabs__item">
          История по камерам
        </router-link>
        <router-link :to="{ name: routes.ROUTE_FIRMWARES }" class="tabs__item">
          Прошивки
        </router-link>
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Миграция камер
        </router-link>
      </div>
    </div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :component-multi-edit="componentMultiEdit"
      :key-field="rawFields.camera_number"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :name-action-load-data-for-multi-edit="nameActionLoadDataForMultiEdit"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_CAMERAS,
  ROUTE_MARKERS,
  ROUTE_MIGRATION,
  ROUTE_FIRMWARES, ROUTE_HISTORY, ROUTE_EDIT_CAMERA, ROUTE_EDIT_SERVER
} from "@/router/names.js";
import {
  ACTION_LOAD_CAMERAS_MIGRATION_FOR_MULTI_EDIT,
  ACTION_LOAD_CAMERAS_MIGRATION_FOR_TABLE,
  ACTION_LOAD_INFO_CAMERAS_MIGRATION,
  EXTRAS_CAMERA_MIGRATION,
  FIELDS_CAMERA_MIGRATION,
  FILTERS_CAMERA_MIGRATION,
  TITLES_FIELDS_CAMERA_MIGRATION, TYPES_FIELDS_CAMERA_MIGRATION,

} from "@/store/cameras/index.js";
import {FilterData} from "@/utils/helpers.js";
import {FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import MultiEditMigration from "@/components/cameras/functionsMultiEdit/MultiEditMigration.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по миграциям камер.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);
    return {
      routes: {
        ROUTE_MARKERS,
        ROUTE_CAMERAS,
        ROUTE_FIRMWARES,
        ROUTE_HISTORY
      },
      currentRoute: ROUTE_MIGRATION,
      rawFields: FIELDS_CAMERA_MIGRATION,
      columnCaptions: TITLES_FIELDS_CAMERA_MIGRATION,
      nameActionLoadDataForTable: `cameras/${ACTION_LOAD_CAMERAS_MIGRATION_FOR_TABLE}`,
      nameActionLoadInfoForTable: `cameras/${ACTION_LOAD_INFO_CAMERAS_MIGRATION}`,
      defaultFieldsTableForView: [
        FIELDS_CAMERA_MIGRATION.camera_number,
        FIELDS_CAMERA_MIGRATION.server_id,
        FIELDS_CAMERA_MIGRATION.status,
        FIELDS_CAMERA_MIGRATION.status_date,
        FIELDS_CAMERA_MIGRATION.stats,
        FIELDS_CAMERA_MIGRATION.confirmable,
      ],
      defaultFilters: [
        new FilterData(FILTERS_CAMERA_MIGRATION.status, "=", ["DONE"], true),
        new FilterData(FILTERS_CAMERA_MIGRATION.status, "=", ["CANCEL"], true),
      ],
      nameActionLoadDataForMultiEdit: `cameras/${ACTION_LOAD_CAMERAS_MIGRATION_FOR_MULTI_EDIT}`,
      componentMultiEdit: MultiEditMigration
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CAMERA_MIGRATION.server]) {
        storedExtraInfo.migrationServers = {};
        sourceExtraInfo[EXTRAS_CAMERA_MIGRATION.server].forEach((migrationServersInfo) => {
          storedExtraInfo.migrationServers[migrationServersInfo[FIELDS_SERVER.id]] = {
            id: migrationServersInfo[FIELDS_SERVER.id],
            name: migrationServersInfo[FIELDS_SERVER.domain],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};
      let params;

      switch (nameField) {
      case FIELDS_CAMERA_MIGRATION.camera_number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: rawTranslation}};
        // К маршруту редактирования камеры подмешиваются параметры фильтрации таблицы для ссылок обратного перехода.
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA_MIGRATION.server_id:
        readyTranslation = _.get(storedExtraInfo.migrationServers[rawTranslation], "name", "Нет сервера");
        readyParams = {name: ROUTE_EDIT_SERVER, params: {serverId: rawTranslation}};
        // К маршруту редактирования камеры подмешиваются параметры фильтрации таблицы для ссылок обратного перехода.
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CAMERA_MIGRATION[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    // afterLoadSourceData()
  },
};
</script>
