/**
 * Отдельный vuex модуль для взаимодействия по log-API
 */

import {DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";


// Логи причин просмотра.
// actions для log. Вызов действия начинать с "log/"
export const ACTION_LOAD_INFO_LOG_CAMERA_WATCH_REASONS = "LOAD_INFO_LOG_CAMERA_WATCH_REASONS";
export const ACTION_LOAD_LOG_CAMERA_WATCH_REASONS = "LOAD_LOG_CAMERA_WATCH_REASONS";
export const ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_FOR_TABLE = "LOAD_LOG_CAMERA_WATCH_REASONS_FOR_TABLE";
export const ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_BY_CAMERA = "LOAD_LOG_CAMERA_WATCH_REASONS_BY_CAMERA";
export const ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_BY_USER = "LOAD_LOG_CAMERA_WATCH_REASONS_BY_USER";
export const ACTION_LOAD_LOG_UCAMSGO = "LOAD_LOG_UCAMSGO";
export const ACTION_LOAD_INFO_LOG_UCAMSGO = "LOAD_INFO_LOG_UCAMSGO";
export const ACTION_LOAD_LOG_UCAMSGO_FOR_TABLE = "LOAD_LOG_UCAMSGO_FOR_TABLE";


/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_LOG_CAMERA_WATCH_REASONS = Object.freeze({
  id: "id",
  camera_number: "camera_number",
  user_id: "user_id",
  username: "username",
  token_type: "token_type",
  date: "date",
  reason: "reason",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_LOG_CAMERA_WATCH_REASONS = Object.freeze({
  id: "id",
  user_id: "user_id",
  camera_number: "camera_number",
  date: "date",
});

/**
 * Стандартные названия для полей групп прав.
 */
export const TITLES_FIELDS_LOG_CAMERA_WATCH_REASONS = {
  [FIELDS_LOG_CAMERA_WATCH_REASONS.id]: "ID",
  [FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number]: "Номер камеры",
  [FIELDS_LOG_CAMERA_WATCH_REASONS.user_id]: "Пользователь",
  [FIELDS_LOG_CAMERA_WATCH_REASONS.username]: "Имя пользователя",
  [FIELDS_LOG_CAMERA_WATCH_REASONS.token_type]: "Тип токена",
  [FIELDS_LOG_CAMERA_WATCH_REASONS.date]: "Дата",
  [FIELDS_LOG_CAMERA_WATCH_REASONS.reason]: "Причина",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_LOG_CAMERA_WATCH_REASONS = {
  [FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_LOG_CAMERA_WATCH_REASONS.user_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_LOG_CAMERA_WATCH_REASONS.date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с группами прав.
 */
export const EXTRAS_LOG_CAMERA_WATCH_REASONS = Object.freeze({
  camera: "camera",
  user: "user",
});

const EXTRAS_BY_FIELDS_LOG_CAMERA_WATCH_REASONS = Object.freeze({
  [FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number]: [EXTRAS_LOG_CAMERA_WATCH_REASONS.camera],
  [FIELDS_LOG_CAMERA_WATCH_REASONS.user_id]: [EXTRAS_LOG_CAMERA_WATCH_REASONS.user],
  [FIELDS_LOG_CAMERA_WATCH_REASONS.username]: [EXTRAS_LOG_CAMERA_WATCH_REASONS.user],
});

// Логи просмотра камер от всех пользователей.
export const ACTION_LOAD_INFO_LOG_VIEWS = "LOAD_INFO_LOG_VIEWS";
export const ACTION_LOAD_LOG_VIEWS = "LOAD_LOG_VIEWS";
export const ACTION_LOAD_LOG_VIEWS_FOR_TABLE = "LOAD_LOG_VIEWS_FOR_TABLE";
export const ACTION_LOAD_LOG_VIEWS_BY_CAMERA = "LOAD_LOG_VIEWS_BY_CAMERA";
export const ACTION_LOAD_LOG_VIEWS_BY_USER = "LOAD_LOG_VIEWS_BY_USER";

export const FIELDS_LOG_VIEWS = Object.freeze({
  id: "id",
  user_id: "user_id",
  username: "username",
  camera_number: "camera_number",
  date: "date",
  token_type: "token_type",
  duration: "duration",
  start: "start",
  ip: "ip",
  user_agent: "user_agent",
  label: "label",
});

export const FILTERS_LOG_VIEWS = Object.freeze({
  id: "id",
  camera_number: "camera_number",
  user_id: "user_id",
  date: "date",
});

export const TITLES_FIELDS_LOG_VIEWS = {
  [FIELDS_LOG_VIEWS.id]: "ID",
  [FIELDS_LOG_VIEWS.user_id]: "Пользователь",
  [FIELDS_LOG_VIEWS.username]: "Имя пользователя",
  [FIELDS_LOG_VIEWS.camera_number]: "Номер камеры",
  [FIELDS_LOG_VIEWS.date]: "Дата",
  [FIELDS_LOG_VIEWS.token_type]: "Тип токена",
  [FIELDS_LOG_VIEWS.duration]: "Продолжительность",
  [FIELDS_LOG_VIEWS.start]: "Начало",
  [FIELDS_LOG_VIEWS.ip]: "IP",
  [FIELDS_LOG_VIEWS.user_agent]: "User-Agent",
  [FIELDS_LOG_VIEWS.label]: "Label",
};

export const TYPES_FIELDS_LOG_VIEWS = {
  [FIELDS_LOG_VIEWS.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_LOG_VIEWS.date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_LOG_VIEWS.start]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};

export const EXTRAS_LOG_VIEWS = Object.freeze({
  camera: "camera",
  user: "user",
});

const EXTRAS_BY_FIELDS_LOG_VIEWS = Object.freeze({
  [FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number]: [EXTRAS_LOG_VIEWS.camera],
  [FIELDS_LOG_CAMERA_WATCH_REASONS.user_id]: [EXTRAS_LOG_VIEWS.user],
  [FIELDS_LOG_CAMERA_WATCH_REASONS.username]: [EXTRAS_LOG_VIEWS.user],
});

// Логи авторизаций.
export const ACTION_LOAD_INFO_LOG_AUTHS = "LOAD_INFO_LOG_AUTHS";
export const ACTION_LOAD_LOG_AUTHS = "LOAD_LOG_AUTHS";
export const ACTION_LOAD_LOG_AUTHS_FOR_TABLE = "LOAD_LOG_AUTHS_FOR_TABLE";
export const ACTION_LOAD_LOG_AUTHS_BY_USER = "LOAD_LOG_AUTHS_BY_USER";

export const FIELDS_LOG_AUTHS = Object.freeze({
  id: "id",
  key: "key",
  user_id: "user_id",
  username: "username",
  auth_username: "auth_username",
  date: "date",
  ip: "ip",
  front_id: "front_id",
  front_type: "front_type",
  http_host: "http_host",
  path: "path",
  user_agent: "user_agent",
});

export const FILTERS_LOG_AUTHS = Object.freeze({
  id: "id",
  user_id: "user_id",
  date: "date",
});

export const TITLES_FIELDS_LOG_AUTHS = {
  [FIELDS_LOG_AUTHS.id]: "ID",
  [FIELDS_LOG_AUTHS.key]: "Key",
  [FIELDS_LOG_AUTHS.user_id]: "Пользователь",
  [FIELDS_LOG_AUTHS.username]: "Имя пользователя",
  [FIELDS_LOG_AUTHS.auth_username]: "Введенное имя пользователя",
  [FIELDS_LOG_AUTHS.date]: "Дата",
  [FIELDS_LOG_AUTHS.ip]: "IP",
  [FIELDS_LOG_AUTHS.front_id]: "Front ID",
  [FIELDS_LOG_AUTHS.front_type]: "Front type",
  [FIELDS_LOG_AUTHS.http_host]: "HTTP Host",
  [FIELDS_LOG_AUTHS.path]: "Path",
  [FIELDS_LOG_AUTHS.user_agent]: "User-Agent",
};

export const TYPES_FIELDS_LOG_AUTHS = {
  [FIELDS_LOG_AUTHS.date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};

export const EXTRAS_LOG_AUTHS = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_LOG_AUTHS = Object.freeze({
  [FIELDS_LOG_CAMERA_WATCH_REASONS.user_id]: [EXTRAS_LOG_AUTHS.user],
  [FIELDS_LOG_CAMERA_WATCH_REASONS.username]: [EXTRAS_LOG_AUTHS.user],
});
// Логи ucamsgo
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_LOG_UCAMSGO = Object.freeze({
  id: "id",
  date_created: "date_created",
  error_message: "error_message",
  loglevel: "loglevel",
  task_id: "task_id",
  task_key: "task_key",
});
/**
 * Стандартные названия для полей групп прав.
 */
export const TITLES_FIELDS_LOG_UCAMSGO = {
  [FIELDS_LOG_UCAMSGO.id]: "ID",
  [FIELDS_LOG_UCAMSGO.error_message]: "Текст",
  [FIELDS_LOG_UCAMSGO.date_created]: "Дата создания",
  [FIELDS_LOG_UCAMSGO.loglevel]: "Уровень логирования",
  [FIELDS_LOG_UCAMSGO.task_id]: "Идентификатор задачи",
  [FIELDS_LOG_UCAMSGO.task_key]: "Название задачи",
};

export const TYPES_FIELDS_LOG_UCAMSGO = {
  [FIELDS_LOG_UCAMSGO.date_created]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};
export const FILTERS_LOG_UCAMSGO = Object.freeze({

  date_created: "date_created",
});
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для логов запросов на доступ к камере.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_LOG_UCAMSGO]() {
      const response = await this.getters.privateAjax.post("/v0/log/ucamsgo/info/");

      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка логов запросов на доступ к камере.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_UCAMSGO](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(fields)));
      try {
        const response = await this.getters.privateAjax.post("/v0/log/ucamsgo/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка логов запросов на доступ к камере для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_UCAMSGO_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_LOG_UCAMSGO, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_LOG_UCAMSGO_FOR_TABLE});
    },
    // log reasons
    /**
     * Загрузка служебной информации для логов запросов на доступ к камере.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_LOG_CAMERA_WATCH_REASONS]() {
      const response = await this.getters.privateAjax.post("/v0/log/camera_watch_reasons/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка логов запросов на доступ к камере.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {{field: (string|*), direction: string}} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_CAMERA_WATCH_REASONS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_LOG_CAMERA_WATCH_REASONS, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/log/camera_watch_reasons/", {
          page,
          page_size: pageSize,
          orderBy: [orderBy],
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка логов запросов на доступ к камере для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {{field: (string|*), direction: string}} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy, fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_LOG_CAMERA_WATCH_REASONS, {page, pageSize, orderBy: [orderBy], fields, filters, search, cancelTokenKey: ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_FOR_TABLE});
    },
    /**
     * Загрузка логов запросов на доступ к конкретной камере для таблиц быстрого доступа к логам.
     * По умолчанию с первой страницы и всегда в порядке убывания дат событий.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_BY_CAMERA]({dispatch}, {cameraNumber, page = 1}) {
      const filter = makeFilterApi(FILTERS_LOG_CAMERA_WATCH_REASONS.camera_number, "=", cameraNumber),
        fields = [
          FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number,
          FIELDS_LOG_CAMERA_WATCH_REASONS.username,
          FIELDS_LOG_CAMERA_WATCH_REASONS.token_type,
          FIELDS_LOG_CAMERA_WATCH_REASONS.date,
          FIELDS_LOG_CAMERA_WATCH_REASONS.reason,
        ];
      return dispatch(ACTION_LOAD_LOG_CAMERA_WATCH_REASONS, {
        page,
        pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE,
        orderBy: [],
        fields,
        filters: [filter]
      });
    },
    /**
     * Загрузка логов запросов на доступ к конкретной камере для таблиц быстрого доступа к логам.
     * По умолчанию с первой страницы и всегда в порядке убывания дат событий.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Number} userId
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_BY_USER]({dispatch}, {userId, page = 1}) {
      const filter = makeFilterApi(FILTERS_LOG_CAMERA_WATCH_REASONS.user_id, "=", userId),
        fields = [
          FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number,
          FIELDS_LOG_CAMERA_WATCH_REASONS.username,
          FIELDS_LOG_CAMERA_WATCH_REASONS.token_type,
          FIELDS_LOG_CAMERA_WATCH_REASONS.date,
          FIELDS_LOG_CAMERA_WATCH_REASONS.reason,
        ];
      return dispatch(ACTION_LOAD_LOG_CAMERA_WATCH_REASONS, {
        page,
        pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE,
        orderBy: [],
        fields,
        filters: [filter]
      });
    },
    /**
     * Загрузка служебной информации для логов просмотров с камеры.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_LOG_VIEWS]() {
      const response = await this.getters.privateAjax.post("/v0/log/views/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка логов просмотров с камеры.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_VIEWS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_LOG_VIEWS, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/log/views/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка логов просмотров с камеры для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {{field: string, direction: string}} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_VIEWS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_LOG_VIEWS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_LOG_VIEWS_FOR_TABLE});
    },
    /**
     * Загрузка логов просмотров с конкретной камеры для таблиц быстрого доступа к логам.
     * По умолчанию с первой страницы и всегда в порядке убывания дат событий.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_VIEWS_BY_CAMERA]({dispatch}, {cameraNumber, page = 1}) {
      const filter = makeFilterApi(FILTERS_LOG_VIEWS.camera_number, "=", cameraNumber),
        fields = [
          FIELDS_LOG_VIEWS.user_id,
          FIELDS_LOG_VIEWS.username,
          FIELDS_LOG_VIEWS.camera_number,
          FIELDS_LOG_VIEWS.date,
          FIELDS_LOG_VIEWS.token_type,
          FIELDS_LOG_VIEWS.duration,
          FIELDS_LOG_VIEWS.start,
          FIELDS_LOG_VIEWS.ip,
          FIELDS_LOG_VIEWS.user_agent,
          FIELDS_LOG_VIEWS.label,
        ];
      return dispatch(ACTION_LOAD_LOG_VIEWS, {page, pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy: [], fields, filters: [filter]});
    },
    /**
     * Загрузка логов просмотров конкретного пользователя для таблиц быстрого доступа к логам.
     * По умолчанию с первой страницы и всегда в порядке убывания дат событий.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Number} userId
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_VIEWS_BY_USER]({dispatch}, {userId, page = 1}) {
      const filter = makeFilterApi(FILTERS_LOG_VIEWS.user_id, "=", userId),
        fields = [
          FIELDS_LOG_VIEWS.user_id,
          FIELDS_LOG_VIEWS.username,
          FIELDS_LOG_VIEWS.camera_number,
          FIELDS_LOG_VIEWS.date,
          FIELDS_LOG_VIEWS.token_type,
          FIELDS_LOG_VIEWS.duration,
          FIELDS_LOG_VIEWS.start,
          FIELDS_LOG_VIEWS.ip,
          FIELDS_LOG_VIEWS.user_agent,
          FIELDS_LOG_VIEWS.label,
        ];
      return dispatch(ACTION_LOAD_LOG_VIEWS, {page, pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy: [], fields, filters: [filter]});
    },

    /**
     * Загрузка служебной информации для логов авторизации.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_LOG_AUTHS]() {
      const response = await this.getters.privateAjax.post("/v0/log/auths/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка логов авторизации.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_AUTHS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_LOG_AUTHS, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/log/auths/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка логов авторизации для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {{field: string, direction: string}} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_AUTHS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_LOG_AUTHS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_LOG_AUTHS_FOR_TABLE});
    },
    /**
     * Загрузка логов авторизации конкретного пользователя для таблиц быстрого доступа к логам.
     * По умолчанию с первой страницы и всегда в порядке убывания дат событий.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Number} userId
     * @return {Promise}
     */
    async [ACTION_LOAD_LOG_AUTHS_BY_USER]({dispatch}, {userId, page = 1}) {
      const filter = makeFilterApi(FILTERS_LOG_AUTHS.user_id, "=", userId),
        fields = [
          FIELDS_LOG_AUTHS.id,
          FIELDS_LOG_AUTHS.key,
          FIELDS_LOG_AUTHS.user_id,
          FIELDS_LOG_AUTHS.username,
          FIELDS_LOG_AUTHS.auth_username,
          FIELDS_LOG_AUTHS.date,
          FIELDS_LOG_AUTHS.ip,
          FIELDS_LOG_AUTHS.front_id,
          FIELDS_LOG_AUTHS.front_type,
          FIELDS_LOG_AUTHS.http_host,
          FIELDS_LOG_AUTHS.path,
          FIELDS_LOG_AUTHS.user_agent,
        ];
      return dispatch(ACTION_LOAD_LOG_AUTHS, {page, pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy: [], fields, filters: [filter]});
    },
  },
};
