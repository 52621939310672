<template>
  <LogViewsByCriteria
    :criteria="{userId: adminId}"
    :initial-current-page="currentPage"
    :name-action-load-log="nameActionLoadLog"
  />
</template>

<script>
import {ACTION_LOAD_LOG_VIEWS_BY_USER} from "@/store/log/index.js";
import LogViewsByCriteria from "@/components/log/LogViewsByCriteria.vue";

/**
 * Компонент страницы отбражения таблицы быстрого доступа к логам просмотров видео с камеры указанным пользователем.
 */
export default {
  components: {
    LogViewsByCriteria,
  },
  props: {
    /**
     * ID администратора по которому будут загружены логи. Передается через параметры маршрута.
     */
    adminId: {
      type: Number,
      required: true,
    },
    /**
     * Начальное значение номер страницы в таблице логов. Передается через параметры маршрута.
     */
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      nameActionLoadLog: ACTION_LOAD_LOG_VIEWS_BY_USER,
    };
  },
};
</script>
