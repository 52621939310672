<template>
  <div class="user-links-dialog">
    <div class="user-links-dialog__editor">
      <div class="user-links-dialog__editor-title-icons">
        <svg class="user-links-dialog__editor-title-icon">
          <use xlink:href="../../../../assets/img/icons.svg#users" />
        </svg>
        <svg class="user-links-dialog__editor-title-icon">
          <use xlink:href="../../../../assets/img/icons.svg#links" />
        </svg>
        <svg class="user-links-dialog__editor-title-icon">
          <use xlink:href="../../../../assets/img/icons.svg#street-view" />
        </svg>
      </div>
      <p class="user-links-dialog__editor-title">
        Привязаны к клиенту
      </p>
      <SetLinksForClient :parent-user-id="clientId" />
    </div>

    <div class="user-links-dialog__delimiter" />

    <div class="user-links-dialog__editor">
      <div class="user-links-dialog__editor-title-icons">
        <svg class="user-links-dialog__editor-title-icon">
          <use xlink:href="../../../../assets/img/icons.svg#street-view" />
        </svg>
        <svg class="user-links-dialog__editor-title-icon">
          <use xlink:href="../../../../assets/img/icons.svg#links" />
        </svg>
        <svg class="user-links-dialog__editor-title-icon">
          <use xlink:href="../../../../assets/img/icons.svg#users" />
        </svg>
      </div>
      <p class="user-links-dialog__editor-title">
        Привязан к клиентам
      </p>
      <SetLinksForClient :child-user-id="clientId" />
    </div>
  </div>
</template>

<script>
import SetLinksForClient from "@/components/users/clients/links/SetLinksForClient.vue";

/**
 * Компонент диалога для настройки связей между клиентами.
 */
export default {
  components: {
    SetLinksForClient,
  },
  props: {
    /**
     * Идентификатор пользователя, для которого будут отображены текущие связи.
     */
    clientId: {
      type: Number,
      default: null
    },
  },
};
</script>

<style lang="scss">
@import "camsng-frontend-shared/styles/vars.scss";

.user-links-dialog {
  display: grid;
  grid-auto-flow: row;
  gap: 2vh;
  grid-template-columns: 1fr auto 1fr;

  // Разделитель между двумя редакторами. Отдельный класс для отдельного дива чтобы не маяться с расчетом размеров ширины с учетом гапа.
  &__delimiter {
    width: 1px;
    background-color: $cams-color-alto-dark;
  }

  &__editor {
    margin-top: 1vh;
  }

  &__editor-title {
    font-family: $cams-font-roboto-medium;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding: 0.5vh;
  }

  &__editor-title-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 1vh;
  }

  &__editor-title-icon {
    fill: $cams-color-azure-radiance;
    height: 52px;
    width: 53px;
  }
}
</style>
