<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Компания
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
    <div class="main__content content">
      <div class="content__settings entity-info">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.GANG, fieldsEntity.name)"
                  class="row"
                >
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.name]"
                    :caption="titlesFields[fieldsEntity.name]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.GANG, fieldsEntity.name)"
                    :error="errorsFormEdit[fieldsEntity.name]"
                    class="col"
                  />
                </div>
                <div class="row">
                  <div class="buttons-group">
                    <CamsButton
                      v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.GANG)"
                      priority="primary"
                      type="button"
                      @click="saveData()"
                    >
                      Сохранить и продолжить редактирование
                    </CamsButton>
                    <CamsButton
                      v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.GANG)"
                      priority="primary"
                      type="button"
                      @click="saveDataAndRedirect()"
                    >
                      Сохранить
                    </CamsButton>
                  </div>
                </div>

                <div class="row">
                  <div class="buttons-group">
                    <router-link
                      v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.GANG)"
                      :to="routeBack"
                      class="button button_btn button_medium button_btn-default"
                    >
                      Отменить
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_GANGS} from "@/router/names.js";
import {
  ACTION_EDIT_GANG,
  ACTION_LOAD_GANG_FOR_EDIT,
  EXTRAS_GANG,
  FIELDS_GANG,
  TITLES_FIELDS_GANG,
} from "@/store/gangs/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования сотрудника.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const gangId = Number(this.$route.params.gangId);
    return {
      routeBack: {name: ROUTE_GANGS},
      fieldsEntity: FIELDS_GANG,
      titlesFields: TITLES_FIELDS_GANG,
      entityId: gangId,
      nameActionLoadDataForEdit: `gangs/${ACTION_LOAD_GANG_FOR_EDIT}`,
      nameActionEdit: `gangs/${ACTION_EDIT_GANG}`,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготавливаются начальные опции для выпадающих списков.
      this.initialOptionsUsers = this.sourceData.extraInfo[EXTRAS_GANG.name];
    },
  },
};
</script>
