<template>
  <div>
    <img :src="photoInfo.image" alt="Фото сотрудника" class="employee-photo-image">
    <div class="employee-photo-text">
      Дата загрузки: {{ photoInfo.upload_date | localDate }}<br>
      Сообщение об ошибке: {{ photoInfo.error_message || "-" }}<br>
      Встраивание: {{ photoInfo.embedding }}
    </div>
  </div>
</template>
<script>

export default {
  name: "EventScreenshotDialog",
  props: {
    /**
     * Информация по фотографии.
     */
    photoInfo: {
      type: Object,
      required: true
    },
  },
};
</script>

<style lang="scss">
.employee-photo-image {
  max-width: 500px;
  max-height: 500px;
  display: block;
  margin: 0 auto;
}
</style>