/**
 * Отдельный vuex модуль для взаимодействия по analytics/reports-API
 */

import {DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
// actions для reports. Вызов действия начинать с "analytics/reports/"
export const ACTION_LOAD_INFO_ANALYTIC_CAR_NUMBER_REPORTS = "LOAD_INFO_ANALYTIC_CAR_NUMBER_REPORTS";
export const ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS = "LOAD_ANALYTIC_CAR_NUMBER_REPORTS";
export const ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS_FOR_TABLE = "LOAD_ANALYTIC_CAR_NUMBER_REPORTS_FOR_TABLE";
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_ANALYTIC_CAR_NUMBER_REPORT = Object.freeze({
  id:"id",
  date:"date",
  camera_number:"camera_number",
  text:"text",
  plate_number:"plate_number",
  access:"access",
  screenshot_domain:"screenshot_domain",
  gang_id:"gang_id",
  transport_class:"transport_class",
  confidence:"confidence",
  emergency_vehicle_light:"emergency_vehicle_light",
  employee_id:"employee_id",
  device_id:"device_id",
  recognition_date:"recognition_date",
  start_date:"start_date",
  screen_date:"screen_date",
  consume_date:"consume_date",
  db_date:"db_date",
  open_date:"open_date",
  plate_screenshot_url:"plate_screenshot_url",
  full_screenshot_url:"full_screenshot_url",
});
/**
 * Стандартные названия для полей сервера аналитики.
 */
export const TITLES_FIELDS_ANALYTIC_CAR_NUMBER_REPORT = {
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.id]: "Id",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.date]: "Дата события",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.text]: "Текст",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.plate_number]: "Гос.номер",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.access]: "Доступ",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.camera_number]: "Номер камеры",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.screenshot_domain]: "Домен для скриншотов",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.gang_id]: "Компания",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.transport_class]: "Тип транспорта",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.confidence]: "Уверенность",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.emergency_vehicle_light]: "Спец транспорт",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.employee_id]: "Сотрудник",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.device_id]: "Устройство",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.recognition_date]: "Дата распознавания",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.start_date]: "Дата начала попытки получить скриншот",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.screen_date]: "Дата скриншота",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.consume_date]: "Дата получения события от аналитики",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.db_date]: "Дата обработки сообщения в БД",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.open_date]: "Дата открытия",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.plate_screenshot_url]: "Скриншот гос.номера",
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.full_screenshot_url]: "Скриншот события",
};
/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_ANALYTIC_CAR_NUMBER_REPORT = {
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.start_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.open_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.db_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.consume_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.screen_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.screen_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.recognition_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.access]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.device_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.plate_screenshot_url]: SPECIAL_TYPES_DATA_IN_CELLS.IMG,
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.full_screenshot_url]: SPECIAL_TYPES_DATA_IN_CELLS.IMG,
};
/**
 * Перечисления опций для загрузки дополнительной информации.
 */
export const EXTRAS_ANALYTIC_CAR_NUMBER_REPORT = Object.freeze({
  gang: "gang",
  device: "device",
  employee: "employee"
});

const EXTRAS_BY_FIELDS_ANALYTIC_CAR_NUMBER_REPORT = Object.freeze({
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.gang_id]: [EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.gang],
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.device_id]: [EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.device],
  [FIELDS_ANALYTIC_CAR_NUMBER_REPORT.employee_id]: [EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.employee]
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для таблицы отчетов анлатики автономеров.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_ANALYTIC_CAR_NUMBER_REPORTS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/car_number/report/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка отчетов.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_ANALYTIC_CAR_NUMBER_REPORT, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/car_number/report/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка отчетов по аналитики для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS_FOR_TABLE});
    },
  },
};
