/**
 * Отдельный vuex модуль для взаимодействия по clusters-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для clusters. Вызов действия начинать с "clusters/"
export const ACTION_LOAD_INFO_CLUSTERS = "LOAD_INFO_CLUSTERS";
export const ACTION_LOAD_CLUSTERS = "LOAD_CLUSTERS";
export const ACTION_LOAD_CLUSTERS_FOR_TABLE = "LOAD_CLUSTERS_FOR_TABLE";
export const ACTION_LOAD_CLUSTERS_FOR_SELECT = "LOAD_CLUSTERS_FOR_SELECT";
export const ACTION_LOAD_CLUSTER_FOR_EDIT = "LOAD_CLUSTER_FOR_EDIT";
export const ACTION_CREATE_CLUSTER = "CREATE_CLUSTER";
export const ACTION_EDIT_CLUSTER = "EDIT_CLUSTER";
export const ACTION_DELETE_CLUSTERS = "DELETE_CLUSTERS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CLUSTER = Object.freeze({
  id: "id",
  name: "name",
  is_deleted: "is_deleted",
  server_ids: "server_ids",
  servers_count: "servers_count",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CLUSTER = Object.freeze({
  id: "id",
  server_id: "server_id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей кластера.
 */
export const TITLES_FIELDS_CLUSTER = {
  [FIELDS_CLUSTER.id]: "ID",
  [FIELDS_CLUSTER.name]: "Имя",
  [FIELDS_CLUSTER.is_deleted]: "Удален",
  [FIELDS_CLUSTER.server_ids]: "Сервера",
  [FIELDS_CLUSTER.servers_count]: "Количество серверов",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_CLUSTER = {
  [FIELDS_CLUSTER.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CLUSTER.name]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CLUSTER.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CLUSTER.server_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с кластерами.
 */
export const EXTRAS_CLUSTER = Object.freeze({
  server: "server",
});

const EXTRAS_BY_FIELDS_CLUSTER = Object.freeze({
  [FIELDS_CLUSTER.server_ids]: [EXTRAS_CLUSTER.server],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для кластеров.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CLUSTERS]() {
      const response = await this.getters.privateAjax.post("/v0/clusters/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка кластеров.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CLUSTERS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CLUSTER, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/clusters/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка кластеров для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CLUSTERS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CLUSTERS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CLUSTERS_FOR_TABLE});
    },
    /**
     * Загрузка списка кластеров для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных кластеров.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CLUSTERS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_CLUSTER.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_CLUSTERS, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_CLUSTERS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного кластера для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} clusterId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_CLUSTER_FOR_EDIT]({dispatch}, [clusterId, fields]) {
      const filter = makeFilterApi(FILTERS_CLUSTER.id, "=", clusterId),
        responseData = await dispatch(ACTION_LOAD_CLUSTERS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового кластера.
     *
     * @param {Object} context
     * @param {Object} clusterInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_CLUSTER](context, clusterInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/clusters/create/", clusterInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего кластера.
     *
     * @param {Object} context
     * @param {Object} clusterInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CLUSTER](context, clusterInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/clusters/edit/", clusterInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление кластеров.
     *
     * @param {Object} context
     * @param {Array} clusterIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CLUSTERS](context, {clusterIds}) {
      return this.getters.privateAjax.post("/v0/clusters/delete/", {ids: clusterIds});
    },
  },
};
