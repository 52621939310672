<template>
  <div>
    <div class="tabs">
      <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
        Интеграция с BOLID
      </router-link>
    </div>

    <div>
      <div class="main__header">
        <div class="tabs">
          <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
            Серверы
          </router-link>
          <router-link :to="{name: routes.ROUTE_BOLID_EVENTS}" class="tabs__item">
            События
          </router-link>
          <router-link :to="{name: routes.ROUTE_BOLID_DEVICES}" class="tabs__item">
            Устройства
          </router-link>
        </div>
      </div>

      <SmartTable
        :calc-data-for-cell="calcDataForCell"
        :click-button-create="openDialogCreateBolidServer"
        :column-captions="columnCaptions"
        :current-route="currentRoute"
        :default-filters="defaultFilters"
        :key-field="rawFields.id"
        :name-action-load-data-for-table="nameActionLoadDataForTable"
        :name-action-load-info-for-table="nameActionLoadInfoForTable"
        :parse-extra="parseExtra"
        :raw-fields="rawFields"
        :initial-default-fields-table-for-view="defaultFieldsTableForView"
      />
    </div>
  </div>
</template>

<script>
import {ROUTE_BOLID_DEVICES, ROUTE_BOLID_EVENTS, ROUTE_BOLID_SERVERS, ROUTE_EDIT_BOLID_SERVER} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData} from "@/utils/helpers.js";
import {
  ACTION_LOAD_BOLID_SERVERS_FOR_TABLE,
  ACTION_LOAD_INFO_BOLID_SERVERS, EXTRAS_BOLID_SERVER,
  FIELDS_BOLID_SERVER, FILTERS_BOLID_SERVER,
  TITLES_FIELDS_BOLID_SERVER,
  TYPES_FIELDS_BOLID_SERVER
} from "@/store/integrations/bolid/servers/index.js";
import CreateBolidServerDialog from "@/components/integrations/bolid/servers/CreateBolidServerDialog.vue";
/**
 * Компонент отображения таблицы и фильтров с информацией по серверам ОПС Болид.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_BOLID_EVENTS,
        ROUTE_BOLID_DEVICES
      },
      currentRoute: ROUTE_BOLID_SERVERS,
      rawFields: FIELDS_BOLID_SERVER,
      columnCaptions: TITLES_FIELDS_BOLID_SERVER,
      nameActionLoadDataForTable: `bolidServers/${ACTION_LOAD_BOLID_SERVERS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `bolidServers/${ACTION_LOAD_INFO_BOLID_SERVERS}`,
      defaultFilters: [
        new FilterData(FILTERS_BOLID_SERVER.is_deleted, "=", [false])
      ],
      defaultFieldsTableForView: [
        FIELDS_BOLID_SERVER.id,
        FIELDS_BOLID_SERVER.host,
        FIELDS_BOLID_SERVER.port,
        FIELDS_BOLID_SERVER.soap_url,
        FIELDS_BOLID_SERVER.status_id,
        FIELDS_BOLID_SERVER.is_auth_enabled,
        FIELDS_BOLID_SERVER.is_token_required,
        FIELDS_BOLID_SERVER.is_deleted,
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_BOLID_SERVER.bolid_server_status]) {
        storedExtraInfo.statuses = {};
        sourceExtraInfo[EXTRAS_BOLID_SERVER.bolid_server_status].forEach((serverInfo) => {
          storedExtraInfo.statuses[serverInfo[FIELDS_BOLID_SERVER.id]] = {
            id: serverInfo[FIELDS_BOLID_SERVER.id],
            is_connection_active: serverInfo.is_connection_active,
          };
        });
      }

    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_BOLID_SERVER.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_BOLID_SERVER, params: {serverId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_BOLID_SERVER.host:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_BOLID_SERVER, params: {serverId: rowData[FIELDS_BOLID_SERVER.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }

        break;
      case FIELDS_BOLID_SERVER.status_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.statuses[rawTranslation], "is_connection_active", "-");
          readyParams = {name: ROUTE_EDIT_BOLID_SERVER, params: {statusId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_BOLID_SERVER[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания сервера BOLID.
     */
    openDialogCreateBolidServer() {
      this.$camsdals.open(
        CreateBolidServerDialog,
        {},
        {dialogTitle: "Создание сервера"},
        {},
      );
    },
  },
};
</script>
