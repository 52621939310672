/**
 * Отдельный vuex модуль для взаимодействия по analytics/face_recognition-API
 */

import {ZERO_ZONE_FOR_CREATE} from "@/store/analytics/helpers.js";
import {DEFAULT_PAGE_SIZE_FOR_TABLE, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";

// actions для faceRecognitions. Вызов действия начинать с "analytics/faceRecognitions/"
export const ACTION_LOAD_INFO_FACE_RECOGNITIONS = "LOAD_INFO_FACE_RECOGNITIONS";
export const ACTION_LOAD_FACE_RECOGNITIONS = "LOAD_FACE_RECOGNITIONS";
export const ACTION_LOAD_FACE_RECOGNITIONS_FOR_TABLE = "LOAD_FACE_RECOGNITIONS_FOR_TABLE";
export const ACTION_LOAD_FACE_RECOGNITION_FOR_EDIT = "LOAD_FACE_RECOGNITION_FOR_EDIT";
export const ACTION_CREATE_FACE_RECOGNITION = "CREATE_FACE_RECOGNITION";
export const ACTION_EDIT_FACE_RECOGNITION = "EDIT_FACE_RECOGNITION";
export const ACTION_DELETE_FACE_RECOGNITIONS = "DELETE_FACE_RECOGNITIONS";
export const ACTION_LOAD_INFO_FACE_RECOGNITION_HISTORY = "LOAD_INFO_FACE_RECOGNITION_HISTORY";
export const ACTION_LOAD_FACE_RECOGNITION_HISTORY = "LOAD_FACE_RECOGNITION_HISTORY";
export const ACTION_LOAD_FACE_RECOGNITION_HISTORY_FOR_TABLE = "LOAD_FACE_RECOGNITION_HISTORY_FOR_TABLE";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_FACE_RECOGNITION = Object.freeze({
  camera_number: "camera_number",
  push_domain: "push_domain",
  is_active: "is_active",
  is_deleted: "is_deleted",
  analytic_server_id: "analytic_server_id",
  image_height: "image_height",
  image_width: "image_width",
  zones: "zones",
  sync_success: "sync_success",
  sync_date: "sync_date",
  sync_last_success: "sync_last_success",
  sync_error: "sync_error",
  sync_data: "sync_data",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_FACE_RECOGNITION = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  analytic_server_id: "analytic_server_id",
});

/**
 * Стандартные названия для полей настроек аналитики распознавания лиц.
 */
export const TITLES_FIELDS_FACE_RECOGNITION = {
  [FIELDS_FACE_RECOGNITION.camera_number]: "Номер камеры",
  [FIELDS_FACE_RECOGNITION.push_domain]: "Домен PUSH",
  [FIELDS_FACE_RECOGNITION.is_active]: "Активна",
  [FIELDS_FACE_RECOGNITION.is_deleted]: "Удалена",
  [FIELDS_FACE_RECOGNITION.analytic_server_id]: "Сервер аналитики",
  [FIELDS_FACE_RECOGNITION.image_height]: "Ширина зоны",
  [FIELDS_FACE_RECOGNITION.image_width]: "Высота зоны",
  [FIELDS_FACE_RECOGNITION.zones]: "Зоны аналитики",
  [FIELDS_FACE_RECOGNITION.sync_success]: "Успешность последней синхронизации",
  [FIELDS_FACE_RECOGNITION.sync_date]: "Дата синхронизации",
  [FIELDS_FACE_RECOGNITION.sync_last_success]: "Дата последней успешной синхронизации",
  [FIELDS_FACE_RECOGNITION.sync_error]: "Ошибка",
  [FIELDS_FACE_RECOGNITION.sync_data]: "Ответ от сервера аналитики",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_FACE_RECOGNITION = {
  [FIELDS_FACE_RECOGNITION.camera_number]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_FACE_RECOGNITION.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_FACE_RECOGNITION.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_FACE_RECOGNITION.sync_success]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_FACE_RECOGNITION.sync_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_FACE_RECOGNITION.sync_last_success]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_FACE_RECOGNITION.analytic_server_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с настройками аналитик распознавания лиц.
 */
export const EXTRAS_FACE_RECOGNITION = Object.freeze({
  analytic_server: "analytic_server"
});

const EXTRAS_BY_FIELDS_FACE_RECOGNITION = Object.freeze({
  [FIELDS_FACE_RECOGNITION.analytic_server_id]: [EXTRAS_FACE_RECOGNITION.analytic_server]
});
// Analytics camera car number history
export const FIELDS_FACE_RECOGNITION_HISTORY = Object.freeze({
  min_confidence:"min_confidence",
  hpk:"hpk",
  camera_number:"camera_number",
  is_deleted:"is_deleted",
  history_create_date:"history_create_date",
  push_domain:"push_domain",
  is_active:"is_active",
  analytic_server_id:"analytic_server_id",
  image_width:"image_width",
  image_height:"image_height",
  zones:"zones",
  is_event:"is_event",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent"
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_FACE_RECOGNITION_HISTORY = Object.freeze({
  camera_number: "camera_number",
  is_deleted: "is_deleted",
  zones: "zones",
  data_change_event_date: "data_change_event_date",
  analytic_server_id:"analytic_server_id",
  data_change_event_user_id: "data_change_event_user_id",
  history_create_date: "history_create_date",
});
export const TITLES_FIELDS_FACE_RECOGNITION_HISTORY = {
  [FIELDS_FACE_RECOGNITION_HISTORY.min_confidence]: "Минимальная уверенность",
  [FIELDS_FACE_RECOGNITION_HISTORY.hpk]:"hpk",
  [FIELDS_FACE_RECOGNITION_HISTORY.camera_number]: "Номер камеры",
  [FIELDS_FACE_RECOGNITION_HISTORY.is_deleted]: "Удалена",
  [FIELDS_FACE_RECOGNITION_HISTORY.history_create_date]: "Дата события",
  [FIELDS_FACE_RECOGNITION_HISTORY.push_domain]: "Пуш домен",
  [FIELDS_FACE_RECOGNITION_HISTORY.is_active]: "Активна",
  [FIELDS_FACE_RECOGNITION_HISTORY.analytic_server_id]: "ID сервера аналитики",
  [FIELDS_FACE_RECOGNITION_HISTORY.image_width]: "Ширина изображения",
  [FIELDS_FACE_RECOGNITION_HISTORY.image_height]: "Высота изображения",
  [FIELDS_FACE_RECOGNITION_HISTORY.zones]:"Зоны",
  [FIELDS_FACE_RECOGNITION_HISTORY.is_event]: "Событие",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_date]: "Дата события 2",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_user_agent]: "user agent",
};
export const EXTRAS_FACE_RECOGNITION_HISTORY = Object.freeze({
  user: "user",
  analytic_server: "analytic_server",
});

const EXTRAS_BY_FIELDS_FACE_RECOGNITION_HISTORY = Object.freeze({
  [FIELDS_FACE_RECOGNITION_HISTORY.data_change_event_user_id]: [EXTRAS_FACE_RECOGNITION_HISTORY.user],
  [FIELDS_FACE_RECOGNITION_HISTORY.analytic_server_id]: [EXTRAS_FACE_RECOGNITION_HISTORY.analytic_server],

});
export const TYPES_FIELDS_FACE_RECOGNITION_HISTORY = {
  [FIELDS_FACE_RECOGNITION_HISTORY.history_create_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для настроек аналитик распознавания лиц.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_FACE_RECOGNITIONS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/face_recognition/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка настроек аналитик распознавания лиц.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_FACE_RECOGNITIONS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_FACE_RECOGNITION, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/face_recognition/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка настроек аналитик распознавания лиц для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_FACE_RECOGNITIONS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_FACE_RECOGNITIONS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_FACE_RECOGNITIONS_FOR_TABLE});
    },
    /**
     * Загрузка одной настройки аналитики распознавания лиц для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_FOR_EDIT]({dispatch}, [cameraNumber, fields]) {
      const filter = makeFilterApi(FILTERS_FACE_RECOGNITION.camera_number, "=", cameraNumber),
        responseData = await dispatch(ACTION_LOAD_FACE_RECOGNITIONS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание новой настройки аналитики распознавания лиц.
     *
     * @param {Object} context
     * @param {Object} faceRecognitionInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_FACE_RECOGNITION](context, faceRecognitionInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/face_recognition/create/", {...faceRecognitionInfo, ...ZERO_ZONE_FOR_CREATE});
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующей настройки аналитики распознавания лиц.
     *
     * @param {Object} context
     * @param {Object} faceRecognitionInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_FACE_RECOGNITION](context, faceRecognitionInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/face_recognition/edit/", faceRecognitionInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление настройки аналитики распознавания лиц.
     *
     * @param {Object} context
     * @param {Array<String>} camerasNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_FACE_RECOGNITIONS](context, {camerasNumbers}) {
      return this.getters.privateAjax.post("/v0/analytics/face_recognition/delete/", {camera_numbers: camerasNumbers});
    },
    // Методы для загрузки информации по истории редактирования аналитики,
    // начинается с v0/analytics/face_recognition/history
    /**
     * Загрузка служебной информации для камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_FACE_RECOGNITION_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/face_recognition/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_FACE_RECOGNITION_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/face_recognition/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_FACE_RECOGNITION_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_FACE_RECOGNITION_HISTORY_FOR_TABLE});
    },
  },
};
