<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: routes.ROUTE_CAMERAS }" class="tabs__item">
          Камеры
        </router-link>
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Маркеры камер
        </router-link>
        <router-link :to="{ name: routes.ROUTE_HISTORY }" class="tabs__item">
          История по камерам
        </router-link>
        <router-link :to="{ name: routes.ROUTE_FIRMWARES }" class="tabs__item">
          Прошивки
        </router-link>
        <router-link :to="{ name: routes.ROUTE_MIGRATION }" class="tabs__item">
          Миграция камер
        </router-link>
      </div>
    </div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateMarker"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :key-field="keyField"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {ACTION_LOAD_MARKERS_FOR_TABLE, ACTION_LOAD_INFO_MARKERS, FIELDS_MARKER, TITLES_FIELDS_MARKER, TYPES_FIELDS_MARKER,} from "@/store/markers/index.js";
import {
  ROUTE_CAMERAS,
  ROUTE_EDIT_MARKER,
  ROUTE_FIRMWARES,
  ROUTE_HISTORY,
  ROUTE_MARKERS,
  ROUTE_MIGRATION
} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateMarkerDialog from "@/components/markers/CreateMarkerDialog.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по маркерам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_CAMERAS,
        ROUTE_HISTORY,
        ROUTE_FIRMWARES,
        ROUTE_MIGRATION
      },
      currentRoute: ROUTE_MARKERS,
      rawFields: FIELDS_MARKER,
      defaultFieldsTableForView: [
        FIELDS_MARKER.id,
        FIELDS_MARKER.name,
        FIELDS_MARKER.is_deleted
      ],
      columnCaptions: TITLES_FIELDS_MARKER,
      nameActionLoadDataForTable: `markers/${ACTION_LOAD_MARKERS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `markers/${ACTION_LOAD_INFO_MARKERS}`,
      keyField: FIELDS_MARKER.id,
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_MARKER.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_MARKER, params: {markerId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_MARKER.name:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_MARKER, params: {markerId: rowData[FIELDS_MARKER.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_MARKER[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания маркера.
     */
    openDialogCreateMarker() {
      this.$camsdals.open(CreateMarkerDialog, {}, {dialogTitle: "Создание маркера"});
    },
  },
};
</script>
