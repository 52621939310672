<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Сотрудники
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ employeeId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <router-link :to="routeEditEmployee" active-class="tabs__item_active" class="tabs__item">
              Редактирование
            </router-link>
            <!-- todo до переделки апи фоток
            <router-link :to="routeEmployeePhoto" active-class="tabs__item_active" class="tabs__item">
              Фото сотрудника
            </router-link>
            -->
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ROUTE_EMPLOYEES,
  ROUTE_EDIT_EMPLOYEE,
  ROUTE_PHOTO_FOR_EMPLOYEE
} from "@/router/names.js";
import {deletedEntityMarkMixin, routeBackMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_EMPLOYEE_FOR_EDIT, FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";

/**
 * Несамостоятельный компонент с элементами навигации по страницам редактирования сотрудника.
 */
export default {
  mixins: [routeBackMixin, deletedEntityMarkMixin],
  data() {
    return {
      entityId: Number(this.$route.params.employeeId),
      nameActionLoadDataForEdit: `employees/${ACTION_LOAD_EMPLOYEE_FOR_EDIT}`,
      deletedField: FIELDS_EMPLOYEE.is_deleted,
      routeBack: {name: ROUTE_EMPLOYEES},
      routeEditEmployee: {name: ROUTE_EDIT_EMPLOYEE, params: {employeeId: this.$route.params.employeeId}},
      routeEmployeePhoto: {name: ROUTE_PHOTO_FOR_EMPLOYEE, params: {employeeId: this.$route.params.employeeId}},
      employeeId: this.$route.params.employeeId,
    };
  },
  /**
   * Уточняются маршруты для табов с учетом параметров маршрута назад на страницу с таблицей,
   * которая была в том состоянии (с нужными фильтрами) из которого был совершен на страницу редактирования.
   */
  created() {
    if (this.routeBack.query) {
      this.routeEditEmployee.query = this.routeBack.query;
    }
  },
};
</script>
