<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_LOG_AUTHS}" class="tabs__item">
          Логи авторизаций
        </router-link>
        <router-link :to="{name: routes.ROUTE_LOG_VIEWS}" class="tabs__item">
          Логи просмотров видео
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Логи запросов доступа к камере
        </router-link>
        <router-link :to="{name: routes.ROUTE_LOG_UCAMSGO}" class="tabs__item">
          Логи UCAMSGO
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_EDIT_ADMIN,
  ROUTE_EDIT_CAMERA,
  ROUTE_LOG_AUTHS,
  ROUTE_LOG_CAMERA_WATCH_REASONS,
  ROUTE_LOG_UCAMSGO,
  ROUTE_LOG_VIEWS
} from "@/router/names.js";
import {FIELDS_ADMIN} from "@/store/users/admins/index.js";
import {TOKEN_TYPES} from "@/store/cameras/index.js";
import {
  ACTION_LOAD_INFO_LOG_CAMERA_WATCH_REASONS,
  ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_FOR_TABLE,
  EXTRAS_LOG_CAMERA_WATCH_REASONS,
  FIELDS_LOG_CAMERA_WATCH_REASONS,
  FILTERS_LOG_CAMERA_WATCH_REASONS,
  TITLES_FIELDS_LOG_CAMERA_WATCH_REASONS,
  TYPES_FIELDS_LOG_CAMERA_WATCH_REASONS
} from "@/store/log/index.js";
import {FilterData} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с логами запросов доступа к камере.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      routes: {
        ROUTE_LOG_AUTHS,
        ROUTE_LOG_VIEWS,
        ROUTE_LOG_UCAMSGO
      },
      currentRoute: ROUTE_LOG_CAMERA_WATCH_REASONS,
      rawFields: FIELDS_LOG_CAMERA_WATCH_REASONS,
      columnCaptions: TITLES_FIELDS_LOG_CAMERA_WATCH_REASONS,
      nameActionLoadDataForTable: `log/${ACTION_LOAD_LOG_CAMERA_WATCH_REASONS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `log/${ACTION_LOAD_INFO_LOG_CAMERA_WATCH_REASONS}`,
      defaultFilters: [
        new FilterData(FILTERS_LOG_CAMERA_WATCH_REASONS.date, ">=", [defaultFilterByDate])
      ],
      defaultFieldsTableForView: [
        FIELDS_LOG_CAMERA_WATCH_REASONS.id,
        FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number,
        FIELDS_LOG_CAMERA_WATCH_REASONS.user_id,
        FIELDS_LOG_CAMERA_WATCH_REASONS.token_type,
        FIELDS_LOG_CAMERA_WATCH_REASONS.date,
        FIELDS_LOG_CAMERA_WATCH_REASONS.reason,
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_LOG_CAMERA_WATCH_REASONS.user]) {
        storedExtraInfo.admins = {};
        sourceExtraInfo[EXTRAS_LOG_CAMERA_WATCH_REASONS.user].forEach((adminInfo) => {
          storedExtraInfo.admins[adminInfo[FIELDS_ADMIN.id]] = {
            id: adminInfo[FIELDS_ADMIN.id],
            name: adminInfo[FIELDS_ADMIN.username],
          };
        });
      }

      storedExtraInfo.captionsForTokens = {
        [TOKEN_TYPES.L]: "Прямая трансляция [L]",
        [TOKEN_TYPES.R]: "Архив [R]",
        [TOKEN_TYPES.D]: "Скачивание [D]",
      };
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_LOG_CAMERA_WATCH_REASONS.camera_number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: rawTranslation}};
        break;
      case FIELDS_LOG_CAMERA_WATCH_REASONS.user_id:
        readyTranslation = _.get(storedExtraInfo.admins[rawTranslation], "name", rawTranslation);
        readyParams = {name: ROUTE_EDIT_ADMIN, params: {adminId: rawTranslation}};
        break;
      case FIELDS_LOG_CAMERA_WATCH_REASONS.token_type:
        readyTranslation = storedExtraInfo.captionsForTokens[rawTranslation];
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_LOG_CAMERA_WATCH_REASONS[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
