<template>
  <div>
    <div class="tabs">
      <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
        Интеграция с BOLID
      </router-link>
    </div>

    <div>
      <div class="main__header">
        <div class="tabs">
          <router-link :to="{name: routes.ROUTE_BOLID_SERVERS}" class="tabs__item">
            Серверы
          </router-link>
          <router-link :to="{name: routes.ROUTE_BOLID_EVENTS}" class="tabs__item">
            События
          </router-link>
          <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
            Устройства
          </router-link>
        </div>
      </div>
    </div>
    <div class="main__content content">
      <div class="content__settings__body">
        <div class="row">
          <SmartVSelect
            v-model="serverId"
            :settings-remote-search="settingsSelectServer"
            class="settings_access__select"
            style="width: 350px"
            placeholder="Выберите сервер"
          />
          <CamsButton
            v-model="serverId"
            priority="primary"
            type="button"
            @click="loadDeviceList(serverId)"
          >
            Показать доступные устройства
          </CamsButton>
        </div>
        <div class="cams-table-wrapper">
          <table class="cams-table">
            <tr>
              <th class="cams-table__cell cams-table__cell_sortable">
                Устройство
              </th>
              <th class="cams-table__cell cams-table__cell_sortable">
                Считыватель
              </th>
            </tr>

            <template v-for="(device, id) in result">
              <tr :key="id">
                <td class="cams-table__cell" style="vertical-align: top;">
                  {{ device.Name }}
                </td>
                <!--                <CamsButton-->
                <!--                  v-show="result.Items.length > 1"-->
                <!--                  class="cams-table__cell"-->
                <!--                  @click="toggle = !toggle"-->
                <!--                >-->
                <!--                  Раскрыть-->
                <!--                </CamsButton>-->
                <td
                  v-for="item in device.Items "
                  :key="item.deviceId"
                  class="cams-table__cell"
                  style="display: flex;
                 "
                >
                  <CamsButton
                    v-model="result.deviceId"
                    priority="primary"
                    type="button"
                    size="m"
                    style="padding: 0 10px 0 10px"
                    @click="openAddCameraToBolidDeviceDialog(item.deviceId, item.Name)"
                  >
                    {{ item.Name }}
                  </CamsButton>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
    <SpinnerLoading v-if="isLoading" size="l" />
  </div>
</template>

<script>
import {ROUTE_BOLID_DEVICES, ROUTE_BOLID_EVENTS, ROUTE_BOLID_SERVERS} from "@/router/names.js";
import {
  ACTION_LOAD_BOLID_SERVERS_FOR_SELECT,
  FIELDS_BOLID_SERVER,
} from "@/store/integrations/bolid/servers/index.js";
import AddCameraToBolidDeviceDialog from "@/components/integrations/bolid/BolidDevices/AddCameraToBolidDeviceDialog.vue";
/**
 * Компонент отображения таблицы с информацией по Устройствам ОПС.
 */
export default {
  props: {
    /**
     * Идентификатор устройства.
     */
    deviceId: {
      type: Number,
      default: null
    },
  },

  data() {

    return {
      isLoading: false,
      serverId : this.newValue,
      toggle: true,
      itemIndex: false,
      result: "",
      routes: {
        ROUTE_BOLID_EVENTS,
        ROUTE_BOLID_SERVERS
      },
      dynamicList: [],
      currentRoute: ROUTE_BOLID_DEVICES,
      settingsSelectServer: {
        action: `bolidServers/${ACTION_LOAD_BOLID_SERVERS_FOR_SELECT}`,
        valueField: FIELDS_BOLID_SERVER.id,
        labelField: FIELDS_BOLID_SERVER.host,
        searchParams: {serverId: this.serverId}

      },
    };
  },
  methods: {
    async loadDeviceList() {
      this.isLoading = true;
      try {
        // const response = await this.$store.dispatch(`bolidDevices/${ACTION_LOAD_BOLID_DEVICES}`, {serverId: this.serverId});
        const response = {
          "count devices": 88,
          "count items": 3832,
          "results": [
            {
              "Id": 1,
              "Name": "С2000/C2000М (1)",
              "Items": [
                {
                  "deviceId": 1,
                  "Name": "Считыватель 1, Прибор 1",
                  "ItemType": "READER",
                }
              ]
            },
            {
              "Id": 2,
              "Name": "С2000/C2000М (2)",
              "Items": [
                {
                  "deviceId": 2,
                  "Name": "Считыватель 1, Прибор 2",
                  "ItemType": "READER",
                }
              ]
            },
            {
              "Id": 4,
              "Name": "С2000-АСПТ (106)",
              "Items": [
                {
                  "deviceId": 4,
                  "Name": "ШС 1, Прибор 106",
                  "ItemType": "LOOP",
                },
                {
                  "deviceId": 5,
                  "Name": "ШС 2, Прибор 106",
                  "ItemType": "LOOP",
                },
                {
                  "deviceId": 6,
                  "Name": "ШС 3, Прибор 106",
                  "ItemType": "LOOP",
                },
                {
                  "deviceId": 7,
                  "Name": "ШС 4, Прибор 106",
                  "ItemType": "LOOP",
                }
              ]
            }
          ]
        };

        this.result =  response.results;
        _.map(response.results, 'Items').forEach(Items => {
          this.dynamicList.push({...Items, visible: false});
        });
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.isLoading = false;

    },

    /**
     * Открытие диалогового окна для добавления камеры к устройству.
     */
    openAddCameraToBolidDeviceDialog(deviceId, name) {

      this.$camsdals.open(
        AddCameraToBolidDeviceDialog,
        {deviceId: deviceId},
        {dialogTitle: `${name}`},
        {name: "js-click-outside"}
      );
    },
  },
};
</script>
