/**
 * Корректный набор часовых поясов, заранее отфильтрованного по странам. Используется для выпадающих списков выбора часового пояса.
 *
 * Извлекается некрасивым способом из мета данных moment, т.к. тот в свою очередь не предоставляет корретного API доступа к ним.
 * Смотри https://github.com/moment/moment-timezone/pull/410
 */

import meta from "moment-timezone/data/meta/latest.json";

const ACTUAL_COUNTRIES = Object.freeze([
  "RU",
  "BY",
  "KZ",
]);

const ACTUAL_ZONES = ACTUAL_COUNTRIES.reduce((accumulator, currentValue) => {
  return [...accumulator, ...meta.countries[currentValue].zones];
}, []);

export default ACTUAL_ZONES;
