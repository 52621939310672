/**
 * Перечень действий для хранилища состояния приложения.
 */

export const ACTION_SIGN_IN_VIA_TOKEN = "SIGN_IN_VIA_TOKEN";
export const ACTION_SIGN_OUT = "SIGN_OUT";
export const ACTION_LOAD_CONTEXT = "LOAD_CONTEXT";
export const ACTION_CONFORMITY_RIGHTS = "CONFORMITY_RIGHTS";
export const ACTION_GET_NEW_TOKEN = "GET_NEW_TOKEN";
export const ACTION_AUTO_UPDATE = "AUTO_UPDATE";
