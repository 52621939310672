<template>
  <div>
    <div class="row">
      <p>Множественное скачивание</p>
    </div>

    <div class="row">
      <div class="col">
        <div class="param">
          <div class="param__name">
            Выберите тип
          </div>
          <div class="radiobuttons-group">
            <div class="radiobutton">
              <input
                id="chooseMP4"
                v-model="formEdit.type"
                class="radiobutton__input"
                name="type"
                type="radio"
                value="mp4"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseMP4">mp4</label>
            </div>
            <div class="radiobutton">
              <input
                id="chooseTS"
                v-model="formEdit.type"
                class="radiobutton__input"
                name="type"
                type="radio"
                value="ts"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseTS">ts</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit.reason"
        :error="errorsFormEdit.reason"
        caption="Причина для скачивания"
        class="col"
        @input="changePromise()"
      />
    </div>

    <div class="row">
      <SmartInputDate
        v-model="formEdit.archiveFrom"
        :error="errorsFormEdit.archiveFrom"
        caption="Начало"
        class="col-2"
        @input="changePromise()"
      />
      <SmartInputDate
        v-model="formEdit.archiveTo"
        :error="errorsFormEdit.archiveTo"
        caption="Конец"
        class="col-2"
        @input="changePromise()"
      />
    </div>

    <div class="row">
      <div class="col">
        <div class="param">
          <div class="param__name">
            Разделить по: {{ readableDivision }}
          </div>
          <div class="input input_medium">
            <input
              v-model="formEdit.division"
              style="padding: 0px"
              max="480"
              min="1"
              step="1"
              type="range"
              @input="changePromise()"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {functionMultiEditMixin} from "@/utils/mixins.js";
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";
import {ACTION_GET_NEW_TOKEN} from "@/store/actions.js";
/**
 * Компонент функции мультиредактора камер для получения ссылок на скачивание видео заданных параметров с камер.
 *
 * todo input range в дизайн
 */
export default {
  mixins: [functionMultiEditMixin],
  data() {
    const defaultArchiveTo = new Date(),
          defaultArchiveFrom = new Date(defaultArchiveTo.getTime());
    defaultArchiveFrom.setMinutes(defaultArchiveFrom.getMinutes() - 10);

    return {
      formEdit: {
        type: "mp4",
        reason: "",
        archiveFrom: defaultArchiveFrom,
        archiveTo: defaultArchiveTo,
        division: 480,
        token: null,
      },
      errorsFormEdit: {
        reason: "",
        archiveFrom: "",
        archiveTo: "",
      }
    };
  },
  computed: {
    /**
     * Читаемый вид из минут в часы и минуты.
     */
    readableDivision() {
      const hours = (this.formEdit.division / 60) | 0,
            minutes = this.formEdit.division % 60;
      return `${hours ? `${hours} ч. ` : ""}${minutes ? `${minutes} мин.` : ""}`;
    }
  },
  /**
   * Запрос нового токена для подстановки его в URL.
   */
  mounted() {
    return this.$store
      .dispatch(ACTION_GET_NEW_TOKEN, {})
      .then((newToken) => {
        this.formEdit.token = newToken;
        this.changePromise();
      });
  },
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     */
    changePromise() {
      this.readyForProcessing();

      // Все переводится в секунды и работа в секундах.
      const unixArchiveFrom = Math.trunc(this.formEdit.archiveFrom / 1000),
            unixArchiveTo = Math.trunc(this.formEdit.archiveTo / 1000),
            clearArchiveFrom = Math.min(unixArchiveFrom, unixArchiveTo),
            clearArchiveTo = Math.max(unixArchiveFrom, unixArchiveTo),
            absoluteDelta = clearArchiveTo - clearArchiveFrom,
            division = this.formEdit.division * 60,
            allFromDelta = [];

      if (absoluteDelta > division) {
        // Если весь интересующий диапазон не вмещается в division,
        // то его надлежит разбить на несколько цельных частей равных division + остаток.
        const whole = absoluteDelta / division | 0,
              part = absoluteDelta % division;

        let currentFrom = clearArchiveFrom;
        // Целые части.
        for (let i = 0; i < whole; i++) {
          allFromDelta.push([currentFrom, division]);
          currentFrom += division;
        }
        // Остаток.
        if (part > 0) {
          allFromDelta.push([currentFrom, absoluteDelta - whole * division]);
        }
      } else {
        // Если весь диапазон умещается в division то получится 1 скачиваемый фрагмент видео с камеры.
        allFromDelta.push([clearArchiveFrom, absoluteDelta]);
      }

      this.$emit("change-promise", (entityKey) => {
        return Promise.resolve().then(() => {
          // Все собранные части собираются в URL.
          const allLinks = [];
          for (const [partFrom, partDelta] of allFromDelta) {
            const paramsURL = new URLSearchParams({
              token: this.formEdit.token,
              number: entityKey,
              type: this.formEdit.type,
              reason: this.formEdit.reason,
              duration: partDelta,
              start: partFrom,
            });

            allLinks.push(`${window.location.origin}/admin/api/v0/easy/downloads/?${paramsURL.toString()}`);
          }

          return new ResultProcessingEntityMultiEdit(allLinks);
        });
      });
    },
    /**
     * Выброс `@ready-for-processing` с флагом готовности к обработке.
     *
     * Вся работа для данной формы фактически выполняется на клиенте, поскольку только формируются URL для скачивания.
     * Поэтому валидация формы тоже необходима на клиенте чтобы вовремя отобразить корректные ошибки.
     */
    readyForProcessing() {
      if (!this.$can(this.$abilitiesActions.SUPER_ACTION, this.$abilitiesSubjects.SUPER_SUBJECT)) {
        this.errorsFormEdit.reason = !this.formEdit.reason ? "Это поле является обязательным" : "";
      }
      this.errorsFormEdit.archiveFrom = !this.formEdit.archiveFrom ? "Это поле является обязательным" : "";
      this.errorsFormEdit.archiveTo = !this.formEdit.archiveTo ? "Это поле является обязательным" : "";
      const isReadyForProcessing = !(this.errorsFormEdit.reason || this.errorsFormEdit.archiveFrom || this.errorsFormEdit.archiveTo);
      this.$emit("ready-for-processing", isReadyForProcessing);
    },
  },
};
</script>
