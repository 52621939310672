<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Настройка аналитики определения масок
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <router-link
              v-if="routeAnalyticMaskHistory"
              :to="routeAnalyticMaskHistory"
            >
              <a class="tabs__item">История изменений</a>
            </router-link>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.push_domain)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.push_domain]"
                    :caption="titlesFields[fieldsEntity.push_domain]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.push_domain)"
                    :error="errorsFormEdit[fieldsEntity.push_domain]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.is_active)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_active]"
                    :caption="titlesFields[fieldsEntity.is_active]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.is_active)"
                    :error="errorsFormEdit[fieldsEntity.is_active]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.analytic_server_id)" class="row">
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.analytic_server_id]"
                    :caption="titlesFields[fieldsEntity.analytic_server_id]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.analytic_server_id)"
                    :error="errorsFormEdit[fieldsEntity.analytic_server_id]"
                    :initial-options="initialOptionsAnalyticServers"
                    :settings-remote-search="settingsSelects[fieldsEntity.analytic_server_id]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.min_confidence)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.min_confidence]"
                    :caption="titlesFields[fieldsEntity.min_confidence]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.min_confidence)"
                    :error="errorsFormEdit[fieldsEntity.min_confidence]"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.ANALYTIC)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.ANALYTIC)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.ANALYTIC)"
                        type="button"
                        @click="deleteMasks()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.ANALYTIC)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.READ_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.camera_number)">
                  <strong>
                    <router-link :to="routeCamera">
                      Посмотреть камеру ({{ sourceData.entityInfo[fieldsEntity.camera_number] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p v-if="routeAnalyticServer">
                  <strong>
                    <router-link :to="routeAnalyticServer">
                      Посмотреть сервер аналитики
                    </router-link>
                  </strong>
                </p>

                <br>
                <p v-if="routeAnalyticServerLogs">
                  <strong>
                    <router-link :to="routeAnalyticServerLogs">
                      Посмотреть Логи серверов
                    </router-link>
                  </strong>
                </p>

                <br>
                <CamsButton
                  v-if="$can($abilitiesActions.LIVE_REASON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.DVR_REASON, $abilitiesSubjects.CAMERA)"
                  :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.image_height) && !$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.image_width) && !$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.ANALYTIC, fieldsEntity.zones)"
                  title="Открыть плеер для просмотра и редактирования зон аналитики"
                  type="button"
                  @click="openDialogEditZones()"
                >
                  Плеер для редактирования зон
                </CamsButton>
                <p v-if="errorsFormEdit[fieldsEntity.image_height]" class="error" v-text="errorsFormEdit[fieldsEntity.image_height]" />
                <p v-if="errorsFormEdit[fieldsEntity.image_width]" class="error" v-text="errorsFormEdit[fieldsEntity.image_width]" />
                <p v-if="errorsFormEdit[fieldsEntity.zones]" class="error">
                  При сохранении зон возникли ошибки
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {editAnalyticsMixin} from "@/components/analytics/mixins.js";
import {
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_EDIT_ANALYTIC_SERVER,
  ROUTE_EDIT_CAMERA,
  ROUTE_MASKS, ROUTE_VIEW_ANALYTIC_MASK_HISTORY
} from "@/router/names.js";
import {
  ACTION_DELETE_MASKS,
  ACTION_EDIT_MASK,
  ACTION_LOAD_MASK_FOR_EDIT,
  EXTRAS_MASK,
  FIELDS_MASK,
  TITLES_FIELDS_MASK
} from "@/store/analytics/masks/index.js";
import {ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT, FIELDS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {FILTERS_ANALYTIC_SERVER_LOGS} from "@/store/analytics/serverLogs/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";

/**
 * Компонент страницы редактирования настройки аналитики определения масок.
 */
export default {
  mixins: [editDataEntityMixin, editAnalyticsMixin],
  data() {
    const cameraNumber = this.$route.params.cameraNumber,
          routeCamera = {name: ROUTE_EDIT_CAMERA, params: {cameraNumber}};

    return {
      routeBack: {name: ROUTE_MASKS},
      fieldsEntity: FIELDS_MASK,
      titlesFields: TITLES_FIELDS_MASK,
      entityId: cameraNumber,
      nameActionLoadDataForEdit: `analytics/masks/${ACTION_LOAD_MASK_FOR_EDIT}`,
      nameActionEdit: `analytics/masks/${ACTION_EDIT_MASK}`,
      deletedField: FIELDS_MASK.is_deleted,

      initialOptionsAnalyticServers: [],
      settingsSelects: {
        [FIELDS_MASK.analytic_server_id]: {
          action: `analytics/servers/${ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_ANALYTIC_SERVER.id,
          labelField: FIELDS_ANALYTIC_SERVER.front_id
        },
      },
      routeCamera: routeCamera,
      routeAnalyticServer: null,
      routeAnalyticServerLogs: null,
      routeAnalyticMaskHistory: null,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготавливаются начальные опции для выпадающих списков.
      this.initialOptionsAnalyticServers = this.sourceData.extraInfo[EXTRAS_MASK.analytic_server];

      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC_SERVER) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.ANALYTIC, FIELDS_MASK.analytic_server_id)) {
        const analyticServerId = this.formEdit[FIELDS_MASK.analytic_server_id],
              paramsRouteAnalyticServerLogs = new TableQueryParams({
                filters: [new FilterData(FILTERS_ANALYTIC_SERVER_LOGS.analytic_server_id,  "=", [analyticServerId]),
                          new FilterData(FILTERS_ANALYTIC_SERVER_LOGS.camera_number,  "=", [this.entityId]),
                          // new FilterData(FILTERS_ANALYTIC_SERVER_LOGS.analytic_type,  "=", [ANALYTICS.mask])
                ]});
        this.routeAnalyticServer = analyticServerId ? {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: analyticServerId}} : null;
        this.routeAnalyticServerLogs = {name: ROUTE_ANALYTIC_SERVERS_LOGS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteAnalyticServerLogs.stringify()}};
        const paramsRouteAnalyticMaskHistory = new TableQueryParams({
          filters: [
            new FilterData(FIELDS_MASK.camera_number,  "=", [this.entityId]),
          ]});
        this.routeAnalyticMaskHistory = {name: ROUTE_VIEW_ANALYTIC_MASK_HISTORY, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteAnalyticMaskHistory.stringify()},params: {cameraNumber: this.entityId}};
      }
    },
    /**
     * Отправка запроса на удаление текущей настройки аналитики определения масок.
     */
    deleteMasks() {
      this.$camsdals.confirm("Хотите удалить эту настройку аналитики определения масок?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`analytics/masks/${ACTION_DELETE_MASKS}`, {camerasNumbers: [this.entityId]});
          this.$camsdals.alert("Настройка аналитики определения масок удалена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении настройки аналитики определения масок");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
