/**
 * Отдельный vuex модуль для взаимодействия по API с сервисом dadata.ru
 * https://dadata.ru/suggestions/#address
 * https://confluence.hflabs.ru/pages/viewpage.action?pageId=204669107
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT} from "@/utils/consts.js";

// actions для dadata. Вызов действия начинать с "dadata/"
export const ACTION_LOAD_HINTS_ADDRESS = "LOAD_HINTS_ADDRESS";
export const ACTION_LOAD_HINTS_ADDRESSES_FOR_SELECT = "LOAD_HINTS_ADDRESSES_FOR_SELECT";
export const ACTION_LOAD_LAT_LON_BY_ADDRESS = "LOAD_LAT_LON_BY_ADDRESS";
export const ACTION_LOAD_KLADR_IDS_BY_LAT_LON = "LOAD_ADDRESS_BY_LAT_LON";


export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка подсказок по адресам.
     *
     * @param {Object} context
     * @param {String} query
     * @param {Number} count
     * @param {Array} locationsBoost
     * @return {Promise}
     */
    async [ACTION_LOAD_HINTS_ADDRESS](context, {query, count, locationsBoost = []}) {
      return this.getters.privateAjax.post("internal/dadata/?suggestions=true&url=/suggestions/api/4_1/rs/suggest/address", {
        query,
        count,
        locations_boost: locationsBoost.map(kladrId => ({kladr_id: kladrId}))
      });
    },
    /**
     * Загрузка списка адресов для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {String} search
     * @param {Number} pageSize
     * @param {Array} locationsBoost
     * @return {Promise}
     */
    async [ACTION_LOAD_HINTS_ADDRESSES_FOR_SELECT]({dispatch}, {search, pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, locationsBoost = []}) {
      const response = await dispatch(ACTION_LOAD_HINTS_ADDRESS, {query: search, count: pageSize, locationsBoost});
      return response.data.suggestions ? response.data.suggestions.map(currentValue => ({value: currentValue["value"]})) : [];
    },
    /**
     * Вернет массив из 2х значений [широта, долгота] или массив с двумя null, по заданному в текстовом виде адресу.
     * Применяется при клике на подсказке адреса в выпадающем списке.
     *
     * Используется тот же API что и при поиске адресов но с явным указанием возврата единственного экземпляра результата,
     * т.к. только в таком случае dadata возвращает координаты по указанному адресу.
     * Есть альтренативная API которая возвращает единственный результат по FIAS ID, однако она не может возвращать
     * результаты по коду домов, следовательно в таком виде она для нас бесполезна.
     *
     * @param {Function} dispatch
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_LAT_LON_BY_ADDRESS]({dispatch}, search) {
      try {
        const response = await dispatch(ACTION_LOAD_HINTS_ADDRESS, {query: search, count: 1}),
          actualHint = _.head(response.data.suggestions);
        if (actualHint.data) {
          return [actualHint.data.geo_lat, actualHint.data.geo_lon];
        }
      } catch {
        return [null, null];
      }
      return [null, null];
    },
    /**
     * Вернет массив из идентификаторов географических объектов из справочника КЛАДР по указанным координатам.
     * Они понадобяться для ранжирования подсказок при поиске адреса.
     *
     * Обрати внимание на то что используются не все идентификаторы и в определенном порядке.
     *
     * @param {Object} context
     * @param {Number} latitude
     * @param {Number} longitude
     * @return {Promise<Array>}
     */
    async [ACTION_LOAD_KLADR_IDS_BY_LAT_LON](context, {latitude, longitude}) {
      try {
        const response = await this.getters.privateAjax.post("internal/dadata/?suggestions=true&url=/suggestions/api/4_1/rs/geolocate/address", {
            lat: latitude,
            lon: longitude,
            count: 1
          }),
          actualHint = _.head(response.data.suggestions),
          kladrIds = [
            actualHint.data.settlement_kladr_id,
            actualHint.data.city_district_kladr_id,
            actualHint.data.city_kladr_id,
            actualHint.data.area_kladr_id,
            actualHint.data.region_kladr_id,
          ];
        return _.filter(kladrIds);
      } catch {
        return [];
      }
    },
  },
};
