<template>
  <div class="sidebar-nav__profile profile">
    <span :title="username" class="profile__login" v-text="username" />

    <template v-if="isAuthViaToken">
      <CamsButton
        icon-type="only"
        style="background: none; border: none;"
        title="Выйти из системы"
        type="button"
        @click="signOut()"
      >
        <svg class="sidebar__icon">
          <use xlink:href="../assets/img/icons.svg#logout" />
        </svg>
      </CamsButton>
    </template>
    <template v-else>
      <a :href="urlSignOut" class="button button_icon" title="Выйти из системы">
        <svg class="sidebar__icon">
          <use xlink:href="../assets/img/icons.svg#logout" />
        </svg>
      </a>
    </template>
  </div>
</template>

<script>
import {ACTION_SIGN_OUT} from "@/store/actions.js";
import {CONFIG_IS_AUTH_VIA_TOKEN} from "@/utils/consts.js";
import {mapGetters} from "vuex";

/**
 * Компонент меню авторизованного пользователя.
 *
 * В зависимости от настроек приложения,
 * поведение при выходе может идти как для авторизованного через токен (через сброс состояния)
 * или через честный переход по URL для сброса информации о сессии.
 */
export default {
  data() {
    return {
      isAuthViaToken: CONFIG_IS_AUTH_VIA_TOKEN,
    };
  },
  computed: mapGetters(["username", "urlSignOut"]),
  methods: {
    /**
     * Вызов функции обнуления информации о сессии для выхода из системы.
     */
    signOut() {
      this.$store.dispatch(ACTION_SIGN_OUT);
    },
  },
};
</script>
