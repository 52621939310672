/**
 * Общие данные и методы для компонентов установки прав.
 */

import {DEVICES_PERMISSIONS} from "@/store/pacs/pacsPermissions/index.js";

export const PERMISSIONS_FOR_SELECT = Object.freeze({
  [DEVICES_PERMISSIONS.ACCESS]: "Полный доступ",
  [DEVICES_PERMISSIONS.ENTRANCE]: "Вход",
  [DEVICES_PERMISSIONS.EXIT]: "Выход",
});

// todo dupl src/components/permissions/mixin.js
export const setPermissionsMixin = {
  data() {
    return {
      isLoading: false,
      modeAddPermission: false,
      // Форма добавления новых элементов прав в виде объекта и глобальный индекс этого объекта.
      // Использование массивов и честное удаление его элементов приведет в ошибкам - передача элементов по ссылке
      // в моделях вложенных компонентов будет в конечном счете указывать на удаленные строки.
      // Короче надо обеспечить удаление и добавление элементов в списке - чтобы не было вариантов ссылаться на удаляемые значения.
      formAddPermission: {},
      globalIndexFormAddPermission: 0,

      permissionsInfo: [],
      search: "",
      currentPage: 1,
      pageInfo: {
        numPages: 0,
        count: 0,
      },
    };
  },
  computed: {
    /**
     * Вернет true если есть перечень ранее установленных прав.
     *
     * @return {Boolean}
     */
    isAvailablePermissions() {
      return !_.isEmpty(this.permissionsInfo);
    },
    /**
     * Количество полей на форме добавления прав. Проверяется через количество элементов в объекте.
     *
     * @return {Number}
     */
    lengthFormAddPermission() {
      return _.size(this.formAddPermission);
    }
  },
  methods: {
    /**
     * Переход к режиму добавления прав устанавливает начальные настройки для формы добавления прав.
     */
    switchOnModeAddPermission() {
      this.addNewFieldFormAddPermission();
      this.modeAddPermission = true;
    },
    /**
     * Выход из режима добавления прав обновляет список имеющихся прав.
     */
    switchOffModeAddPermission() {
      this.formAddPermission = {};
      this.search = "";
      this.loadPermissions();
      this.modeAddPermission = false;
    },
    /**
     * Добавление новой строчки на форме добавления прав.
     * Из-за особенностей работы формы и передачи параметров, происходит добавление новой строчки под уникальным глобальным
     * (для этого компонента) ключом.
     */
    addNewFieldFormAddPermission() {
      if (this.lengthFormAddPermission < 3) {
        this.$set(this, "formAddPermission", {
          ...this.formAddPermission,
          [++this.globalIndexFormAddPermission]: {entityId: "", permission: DEVICES_PERMISSIONS.ENTRANCE}
        });
      }
    },
    /**
     * Удаление строчки поля формы по индексу с сохранением порядка ключей.
     * Имеется необходимость в сохранении реактивности свойств формы,
     * потому нужно имитировать честное изменение значения объекта по правилам vue - т.е. через this.$set с установкой актуального
     * (т.е. без удаленного элемента коллекции) значения.
     *
     * @param {Number} indexField
     */
    deleteFieldFormAddPermission(indexField) {
      const cloneFormAddPermission = _.cloneDeep(this.formAddPermission);
      delete cloneFormAddPermission[indexField];
      this.$set(this, "formAddPermission", cloneFormAddPermission);
    }
  },
};
