<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.domain]"
        :caption="titlesFields[fieldsEntity.domain]"
        :error="errorsFormEdit[fieldsEntity.domain]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.title]"
        :caption="titlesFields[fieldsEntity.title]"
        :error="errorsFormEdit[fieldsEntity.title]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.favicon]"
        :caption="titlesFields[fieldsEntity.favicon]"
        :error="errorsFormEdit[fieldsEntity.favicon]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.logo]"
        :caption="titlesFields[fieldsEntity.logo]"
        :error="errorsFormEdit[fieldsEntity.logo]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.logo_link]"
        :caption="titlesFields[fieldsEntity.logo_link]"
        :error="errorsFormEdit[fieldsEntity.logo_link]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_DOMAIN_SETTING} from "@/router/names.js";
import {ACTION_CREATE_DOMAIN_SETTING, FIELDS_DOMAIN_SETTING, TITLES_FIELDS_DOMAIN_SETTING} from "@/store/domainSettings/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания настроек для домена.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `domainSettings/${ACTION_CREATE_DOMAIN_SETTING}`,
      fieldsEntity: FIELDS_DOMAIN_SETTING,
      titlesFields: TITLES_FIELDS_DOMAIN_SETTING,
      formEdit: {
        [FIELDS_DOMAIN_SETTING.domain]: "",
        [FIELDS_DOMAIN_SETTING.title]: null,
        [FIELDS_DOMAIN_SETTING.favicon]: null,
        [FIELDS_DOMAIN_SETTING.logo]: null,
        [FIELDS_DOMAIN_SETTING.logo_link]: null,
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование настроек для домена.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_DOMAIN_SETTING, params: {domainSettingId: newEntity[FIELDS_DOMAIN_SETTING.id]}};
    },
  },
};
</script>
