<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Серверы аналитики
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CLUSTERS}" class="tabs__item">
          Кластеры серверов аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS_LOGS}" class="tabs__item">
          Логи серверов
        </router-link>
        <router-link :to="{name: routes.ROUTE_ROOT_ANALYTICS_SETTINGS}" class="tabs__item">
          Настройка аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CAR_NUMBER_REPORT}" class="tabs__item">
          Отчеты
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateServer"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreateAnalyticServerDialog from "@/components/analytics/servers/CreateAnalyticServerDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_CAR_NUMBERS,
  ROUTE_EDIT_ANALYTIC_SERVER,
  ROUTE_FACE_RECOGNITIONS,
  ROUTE_HELMETS,
  ROUTE_MOTION_ALARMS,
  ROUTE_THERMAL_VISIONS,
  ROUTE_MASKS,
  ROUTE_CROWDS,
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_PEOPLE_COUNTS,
  ROUTE_ROOT_ANALYTICS_SETTINGS,
  ROUTE_ANALYTIC_CLUSTERS,
  ROUTE_ANALYTIC_CAR_NUMBER_REPORT
} from "@/router/names.js";
import {FILTERS_CAR_NUMBER} from "@/store/analytics/carNumbers/index.js";
import {FILTERS_HELMET} from "@/store/analytics/helmets/index.js";
import {FILTERS_FACE_RECOGNITION} from "@/store/analytics/faceRecognitions/index.js";
import {
  ACTION_LOAD_ANALYTIC_SERVERS_FOR_TABLE,
  ACTION_LOAD_INFO_ANALYTIC_SERVERS,
  FIELDS_ANALYTIC_SERVER,
  FILTERS_ANALYTIC_SERVER,
  TITLES_FIELDS_ANALYTIC_SERVER,
  TYPES_FIELDS_ANALYTIC_SERVER
} from "@/store/analytics/servers/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по серверам аналитики.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_ANALYTIC_SERVERS_LOGS,
        ROUTE_THERMAL_VISIONS,
        ROUTE_CAR_NUMBERS,
        ROUTE_FACE_RECOGNITIONS,
        ROUTE_HELMETS,
        ROUTE_MOTION_ALARMS,
        ROUTE_MASKS,
        ROUTE_CROWDS,
        ROUTE_PERIMETER_SECURITYS,
        ROUTE_PEOPLE_COUNTS,
        ROUTE_ROOT_ANALYTICS_SETTINGS,
        ROUTE_ANALYTIC_CLUSTERS,
        ROUTE_ANALYTIC_CAR_NUMBER_REPORT
      },
      currentRoute: ROUTE_ANALYTIC_SERVERS,
      rawFields: FIELDS_ANALYTIC_SERVER,
      columnCaptions: TITLES_FIELDS_ANALYTIC_SERVER,
      nameActionLoadDataForTable: `analytics/servers/${ACTION_LOAD_ANALYTIC_SERVERS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `analytics/servers/${ACTION_LOAD_INFO_ANALYTIC_SERVERS}`,
      defaultFilters: [
        new FilterData(FILTERS_ANALYTIC_SERVER.is_deleted, "=", [false])
      ],
      defaultFieldsTableForView: [
        FIELDS_ANALYTIC_SERVER.id,
        FIELDS_ANALYTIC_SERVER.front_id,
        FIELDS_ANALYTIC_SERVER.sync_last_success,
        FIELDS_ANALYTIC_SERVER.sync_success,
        FIELDS_ANALYTIC_SERVER.description,
        FIELDS_ANALYTIC_SERVER.max_free_memory,
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {},
          params = null;

      switch (nameField) {
      case FIELDS_ANALYTIC_SERVER.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_ANALYTIC_SERVER.front_id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_ANALYTIC_SERVER, params: {analyticServerId: rowData[FIELDS_ANALYTIC_SERVER.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers:
        readyTranslation = rawTranslation;
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAR_NUMBER.analytic_server_id, "=", [rowData[FIELDS_ANALYTIC_SERVER.id]])]});
        readyParams = {name: ROUTE_CAR_NUMBERS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers:
        readyTranslation = rawTranslation;
        params = new TableQueryParams({filters: [new FilterData(FILTERS_HELMET.analytic_server_id, "=", [rowData[FIELDS_ANALYTIC_SERVER.id]])]});
        readyParams = {name: ROUTE_HELMETS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers:
        readyTranslation = rawTranslation;
        params = new TableQueryParams({filters: [new FilterData(FILTERS_FACE_RECOGNITION.analytic_server_id, "=", [rowData[FIELDS_ANALYTIC_SERVER.id]])]});
        readyParams = {name: ROUTE_FACE_RECOGNITIONS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_ANALYTIC_SERVER[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания сервера аналитики.
     */
    openDialogCreateServer() {
      this.$camsdals.open(
        CreateAnalyticServerDialog,
        {},
        {dialogTitle: "Создание сервера аналитики"},
        {},
      );
    },
  },
};
</script>
