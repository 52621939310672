/**
 * Отдельный vuex модуль для взаимодействия по tariffs-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для tariffs. Вызов действия начинать с "tariffs/"
export const ACTION_LOAD_INFO_TARIFFS = "LOAD_INFO_TARIFFS";
export const ACTION_LOAD_TARIFFS = "LOAD_TARIFFS";
export const ACTION_LOAD_TARIFFS_FOR_TABLE = "LOAD_TARIFFS_FOR_TABLE";
export const ACTION_LOAD_TARIFFS_FOR_SELECT = "LOAD_TARIFFS_FOR_SELECT";
export const ACTION_LOAD_TARIFF_FOR_EDIT = "LOAD_TARIFF_FOR_EDIT";
export const ACTION_CREATE_TARIFF = "CREATE_TARIFF";
export const ACTION_EDIT_TARIFF = "EDIT_TARIFF";
export const ACTION_DELETE_TARIFF = "DELETE_TARIFF";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_TARIFF = Object.freeze({
  id: "id",
  name: "name",
  dvr_hours: "dvr_hours",
  dvr_size: "dvr_size",
  max_sessions: "max_sessions",
  is_deleted: "is_deleted",
  is_archive: "is_archive",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_TARIFF = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  server_id: "server_id",
});

/**
 * Стандартные названия для полей тарифа.
 */
export const TITLES_FIELDS_TARIFF = {
  [FIELDS_TARIFF.id]: "ID",
  [FIELDS_TARIFF.name]: "Имя",
  [FIELDS_TARIFF.dvr_hours]: "Глубина архива в часах",
  [FIELDS_TARIFF.dvr_size]: "Глубина архива в GB",
  [FIELDS_TARIFF.max_sessions]: "Сессий на поток",
  [FIELDS_TARIFF.is_deleted]: "Удален",
  [FIELDS_TARIFF.is_archive]: "Архивный",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_TARIFF = {
  [FIELDS_TARIFF.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_TARIFF.name]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_TARIFF.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_TARIFF.is_archive]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для тарифов.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_TARIFFS]() {
      const response = await this.getters.privateAjax.post("/v0/tariffs/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка тарифов.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_TARIFFS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/tariffs/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка тарифов для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_TARIFFS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_TARIFFS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_TARIFFS_FOR_TABLE});
    },
    /**
     * Загрузка списка тарифов для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных тарифов.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_TARIFFS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_TARIFF.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_TARIFFS, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_TARIFFS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного тарифа для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} tariffId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_TARIFF_FOR_EDIT]({dispatch}, [tariffId, fields]) {
      const filter = makeFilterApi(FILTERS_TARIFF.id, "=", tariffId),
        responseData = await dispatch(ACTION_LOAD_TARIFFS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового тарифа.
     *
     * @param {Object} context
     * @param {Object} tariffInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_TARIFF](context, tariffInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/tariffs/create/", tariffInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего тарифа.
     *
     * @param {Object} context
     * @param {Object} tariffInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_TARIFF](context, tariffInfo) {

      try {
        const response = await this.getters.privateAjax.post("/v0/tariffs/edit/", tariffInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление тарифа.
     *
     * @param {Object} context
     * @param {Array} tariffIds
     * @return {Promise}
     */
    async [ACTION_DELETE_TARIFF](context, {tariffIds}) {
      return this.getters.privateAjax.post("/v0/tariffs/delete/", {ids: tariffIds});
    },
  },
};
