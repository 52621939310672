<template>
  <div>
    <div class="content__settings__body">
      <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
        <div class="row">
          <div class="col-2">
            <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.title)" class="row">
              <SmartInputText
                v-model="formEdit[fieldsEntity.title]"
                :caption="titlesFields[fieldsEntity.title]"
                :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.title)"
                :error="errorsFormEdit[fieldsEntity.title]"
                :make-focus="true"
                class="col"
              />
            </div>

            <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.attached_usernames)" class="row">
              <SmartInputArray
                v-model="formEdit[fieldsEntity.attached_usernames]"
                :caption="titlesFields[fieldsEntity.attached_usernames]"
                :error="errorsFormEdit[fieldsEntity.attached_usernames]"
                :initial-options="initialOptionsUsers"
                :multiple="true"
                :settings-remote-search="settingsSelects[fieldsEntity.attached_usernames]"
                class="col"
              />
            </div>
            <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.is_active)" class="row">
              <SmartSwitch
                v-model="formEdit[fieldsEntity.is_active]"
                :caption="titlesFields[fieldsEntity.is_active]"
                :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.EMPLOYEE, fieldsEntity.is_active)"
                :error="errorsFormEdit[fieldsEntity.is_active]"
                class="col"
              />
            </div>

            <template v-if="!isDeleted">
              <div class="row">
                <div class="buttons-group">
                  <CamsButton
                    v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
                    priority="primary"
                    type="button"
                    @click="saveData()"
                  >
                    Сохранить и продолжить редактирование
                  </CamsButton>
                  <CamsButton
                    v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
                    priority="primary"
                    type="button"
                    @click="saveDataAndRedirect()"
                  >
                    Сохранить
                  </CamsButton>
                </div>
              </div>

              <div class="row">
                <div class="buttons-group">
                  <CamsButton
                    v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.EMPLOYEE)"
                    type="button"
                    @click="deleteEmployee()"
                  >
                    Удалить
                  </CamsButton>
                  <router-link
                    v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
                    :to="routeBack"
                    class="button button_btn button_medium button_btn-default"
                  >
                    Отменить
                  </router-link>
                </div>
              </div>
              <div class="row">
                <div class="buttons-group">
                  <CamsButton
                    v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_PERMISSION)"
                    type="button"
                    @click="openDialogSetPermissions()"
                  >
                    Изменить права на устройства
                  </CamsButton>
                </div>
              </div>
            </template>
          </div>

          <div class="col-2">
            <br>
            <p v-if="routeGang">
              <strong>
                <router-link :to="routeGang">Посмотреть информацию по компании</router-link>
              </strong>
            </p>

            <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_PERMISSION)">
              Количество записей прав для сотрудника:
              <strong>{{ countPermissionsByDevices }}</strong>
            </p>
            <p>
              <strong>
                <router-link :to="routeCars">Список автомобилей</router-link>
              </strong>
            </p>

            <br>
            <div v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP)">
              <p v-if="routeDeviceAccessGroups">
                <strong>
                  <router-link :to="routeDeviceAccessGroups">Список групп доступа у сотрудника</router-link>
                </strong>
              </p>
              <ul>
                <li
                  v-for="(deviceAccessGroupTitle, deviceAccessGroupId) in deviceAccessGroupLinksInfo"
                  :key="`deviceAccessGroup-${deviceAccessGroupId}`"
                >
                  <router-link :to="{name: routes.ROUTE_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId}}">
                    Смотреть группу <strong>{{ deviceAccessGroupTitle }}</strong>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
      <div v-else class="row">
        <p>Данные не удалось загрузить.</p>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_CARS, ROUTE_DEVICE_ACCESS_GROUPS, ROUTE_EDIT_DEVICE_ACCESS_GROUP, ROUTE_EDIT_GANG, ROUTE_EMPLOYEES} from "@/router/names.js";
import {
  ACTION_DELETE_EMPLOYEE,
  ACTION_EDIT_EMPLOYEE,
  ACTION_LOAD_EMPLOYEE_FOR_EDIT,
  EXTRAS_EMPLOYEE,
  FIELDS_EMPLOYEE,
  TITLES_FIELDS_EMPLOYEE,
} from "@/store/pacs/employees/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_CLIENTS_FOR_SELECT, FIELDS_CLIENT} from "@/store/users/clients/index.js";
import SetPermissionsForEmployeesDialog from "@/components/pacs/pacsPermissons/SetPermissionsForEmployeesDialog.vue";
import {ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE} from "@/store/pacs/pacsPermissions/index.js";
import {FIELDS_DEVICE_ACCESS_GROUP, FILTERS_DEVICE_ACCESS_GROUP} from "@/store/pacs/deviceAccessGroups/index.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FILTERS_CAR} from "@/store/pacs/cars/index.js";

/**
 * Компонент страницы редактирования сотрудника.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const employeeId = Number(this.$route.params.employeeId),
          paramsRouteDeviceAccessGroups = new TableQueryParams({filters: [new FilterData(FILTERS_DEVICE_ACCESS_GROUP.employee_id, "=", [employeeId])]}),
          routeDeviceAccessGroups = {name: ROUTE_DEVICE_ACCESS_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteDeviceAccessGroups.stringify()}},
          paramsRouteCars = new TableQueryParams({filters: [new FilterData(FILTERS_CAR.employee_id, "=", [employeeId])]}),
          routeCars = {name: ROUTE_CARS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCars.stringify()}};

    return {
      routeBack: {name: ROUTE_EMPLOYEES},
      fieldsEntity: FIELDS_EMPLOYEE,
      titlesFields: TITLES_FIELDS_EMPLOYEE,
      entityId: employeeId,
      nameActionLoadDataForEdit: `employees/${ACTION_LOAD_EMPLOYEE_FOR_EDIT}`,
      nameActionEdit: `employees/${ACTION_EDIT_EMPLOYEE}`,
      deletedField: FIELDS_EMPLOYEE.is_deleted,
      initialOptionsUsers: [],
      settingsSelects: {
        [FIELDS_EMPLOYEE.attached_usernames]: {
          action: `clients/${ACTION_LOAD_CLIENTS_FOR_SELECT}`,
          valueField: FIELDS_CLIENT.username,
          labelField: FIELDS_CLIENT.username,
        },
      },
      countPermissionsByDevices: 0,
      routeGang: null,
      routes: {ROUTE_EDIT_DEVICE_ACCESS_GROUP},
      deviceAccessGroupLinksInfo: {},
      routeDeviceAccessGroups: routeDeviceAccessGroups,
      routeCars: routeCars,
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготавливаются начальные опции для выпадающих списков.
      this.initialOptionsUsers = this.sourceData.extraInfo[EXTRAS_EMPLOYEE.user];
      const gangId = this.sourceData.entityInfo[FIELDS_EMPLOYEE.gang_id];
      this.settingsSelects[FIELDS_EMPLOYEE.attached_usernames].searchParams = {gangId};
      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.GANG) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.EMPLOYEE, FIELDS_EMPLOYEE.gang_id)) {
        this.routeGang = gangId ? {name: ROUTE_EDIT_GANG, params: {gangId}} : null;
      }

      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.DEVICE_PERMISSION)) {
        this.countPermissionsByDevices = await this.$store.dispatch(`pacsPermissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE}`, {employeeId: this.entityId});
      }

      // Сборка данных для ссылок на группы доступа.
      this.deviceAccessGroupLinksInfo = {};
      for (const deviceAccessGroupInfo of this.sourceData.extraInfo[EXTRAS_EMPLOYEE.device_access_group]) {
        this.deviceAccessGroupLinksInfo[deviceAccessGroupInfo[FIELDS_DEVICE_ACCESS_GROUP.id]] = deviceAccessGroupInfo[FIELDS_DEVICE_ACCESS_GROUP.title];
      }
    },
    /**
     * Отправка запроса на удаление текущего сотрудника.
     */
    deleteEmployee() {
      this.$camsdals.confirm("Хотите удалить этого сотрудника?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`employees/${ACTION_DELETE_EMPLOYEE}`, {employeeIds: [this.entityId]});
          this.$camsdals.alert("Сотрудник удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении сотрудника");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открытие формы для изменения прав клиентов на группу камер.
     */
    openDialogSetPermissions() {
      this.$camsdals.open(
        SetPermissionsForEmployeesDialog,
        {employeeId: this.entityId, gangId: this.sourceData.entityInfo[FIELDS_EMPLOYEE.gang_id]},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
  },
};
</script>
