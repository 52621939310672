<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Группы камер
        </router-link>
        <router-link :to="{name: routes.ROUTE_CAMERA_GROUP_TYPES}" class="tabs__item">
          Типы групп камер
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateCameraGroup"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :deleted-field="rawFields.is_deleted"
      :default-filters="defaultFilters"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreateCameraGroupDialog from "@/components/cameraGroups/CreateCameraGroupDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {ROUTE_CAMERA_GROUP_TYPES, ROUTE_CAMERA_GROUPS, ROUTE_CAMERAS, ROUTE_EDIT_CAMERA_GROUP} from "@/router/names.js";
import {
  ACTION_LOAD_CAMERA_GROUPS_FOR_TABLE,
  ACTION_LOAD_INFO_CAMERA_GROUPS,
  EXTRAS_CAMERA_GROUP,
  FIELDS_CAMERA_GROUP, FILTERS_CAMERA_GROUP,
  TITLES_FIELDS_CAMERA_GROUP,
  TYPES_FIELDS_CAMERA_GROUP
} from "@/store/cameraGroups/index.js";
import {FIELDS_CAMERA_GROUP_TYPE} from "@/store/cameraGroupTypes/index.js";
import {FILTERS_CAMERA} from "@/store/cameras/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по группам камер.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_CAMERA_GROUP_TYPES,
      },
      currentRoute: ROUTE_CAMERA_GROUPS,
      rawFields: FIELDS_CAMERA_GROUP,
      columnCaptions: TITLES_FIELDS_CAMERA_GROUP,
      nameActionLoadDataForTable: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `cameraGroups/${ACTION_LOAD_INFO_CAMERA_GROUPS}`,
      defaultFieldsTableForView: [
        FIELDS_CAMERA_GROUP.id,
        FIELDS_CAMERA_GROUP.name,
        FIELDS_CAMERA_GROUP.title,
        FIELDS_CAMERA_GROUP.about,
        FIELDS_CAMERA_GROUP.is_deleted,
        FIELDS_CAMERA_GROUP.is_public,
        FIELDS_CAMERA_GROUP.camera_group_type_id,
        FIELDS_CAMERA_GROUP.layout,
      ],
      defaultFilters: [
        new FilterData(FILTERS_CAMERA_GROUP.is_deleted, "=", [false])
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CAMERA_GROUP.camera_group_type]) {
        storedExtraInfo.cameraGroupTypes = {};
        sourceExtraInfo[EXTRAS_CAMERA_GROUP.camera_group_type].forEach((cameraGroupTypeInfo) => {
          storedExtraInfo.cameraGroupTypes[cameraGroupTypeInfo[FIELDS_CAMERA_GROUP_TYPE.id]] = {
            id: cameraGroupTypeInfo[FIELDS_CAMERA_GROUP_TYPE.id],
            name: cameraGroupTypeInfo[FIELDS_CAMERA_GROUP_TYPE.name],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      let params;
      switch (nameField) {
      case FIELDS_CAMERA_GROUP.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA_GROUP, params: {cameraGroupId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA_GROUP.name:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAMERA_GROUP, params: {cameraGroupId: rowData[FIELDS_CAMERA_GROUP.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAMERA_GROUP.camera_numbers:
        readyTranslation = rawTranslation;
        params = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA.camera_group_id, "=", [rowData[FIELDS_CAMERA_GROUP.id]])]});
        readyParams = {name: ROUTE_CAMERAS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_CAMERA_GROUP.camera_group_type_id:
        readyTranslation = _.get(storedExtraInfo.cameraGroupTypes[rawTranslation], "name", "-");
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CAMERA_GROUP[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания группы камер.
     */
    openDialogCreateCameraGroup() {
      this.$camsdals.open(
        CreateCameraGroupDialog,
        {},
        {dialogTitle: "Создание группы камер"},
        {},
      );
    },
  },
};
</script>
