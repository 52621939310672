/**
 * Отдельный vuex модуль для взаимодействия по users/clients-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {FIELDS_HISTORY} from "@/store/history/index.js";

// actions для clients. Вызов действия начинать с "clients/"
export const ACTION_LOAD_INFO_CLIENTS = "LOAD_INFO_CLIENTS";
export const ACTION_LOAD_CLIENTS = "LOAD_CLIENTS";
export const ACTION_LOAD_CLIENTS_FOR_TABLE = "LOAD_CLIENTS_FOR_TABLE";
export const ACTION_LOAD_CLIENTS_FOR_SELECT = "LOAD_CLIENTS_FOR_SELECT";
export const ACTION_LOAD_CLIENT_FOR_EDIT = "LOAD_CLIENT_FOR_EDIT";
export const ACTION_CREATE_CLIENT = "CREATE_CLIENT";
export const ACTION_EDIT_CLIENT = "EDIT_CLIENT";
export const ACTION_RESET_SALT_CLIENTS = "RESET_SALT_CLIENTS";
export const ACTION_CHANGE_PASSWORD_CLIENT = "CHANGE_PASSWORD_CLIENT";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CLIENT = Object.freeze({
  id: "id",
  last_login: "last_login",
  username: "username",
  is_active: "is_active",
  date_joined: "date_joined",
  about: "about",
  date_block: "date_block",
  is_local_password: "is_local_password",
  labels_count: "labels_count",
  labels: "labels",
  gang_id: "gang_id",
  gang_admin_id: "gang_admin_id",
  is_userlinkable: "is_userlinkable"
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_CLIENT = Object.freeze({
  id: "id",
  is_active: "is_active",
  gang_id: "gang_id",
  gang_admin_id: "gang_admin_id"
});

/**
 * Стандартные названия для полей клиента.
 */
export const TITLES_FIELDS_CLIENT = {
  [FIELDS_CLIENT.id]: "ID",
  [FIELDS_CLIENT.last_login]: "Последний вход",
  [FIELDS_CLIENT.username]: "Имя пользователя",
  [FIELDS_CLIENT.is_active]: "Активный",
  [FIELDS_CLIENT.date_joined]: "Дата создания",
  [FIELDS_CLIENT.about]: "Описание",
  [FIELDS_CLIENT.date_block]: "Дата блокировки",
  [FIELDS_CLIENT.is_local_password]: "Локальный пароль",
  [FIELDS_CLIENT.labels_count]: "Количество меток",
  [FIELDS_CLIENT.labels]: "Метки",
  [FIELDS_CLIENT.gang_id]: "Компания",
  [FIELDS_CLIENT.gang_admin_id]: "Управляемая компания",
  [FIELDS_CLIENT.is_userlinkable]: "Мультиаккаунтинг",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_CLIENT = {
  [FIELDS_CLIENT.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CLIENT.username]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CLIENT.last_login]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CLIENT.date_joined]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CLIENT.date_block]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_CLIENT.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CLIENT.is_local_password]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CLIENT.is_userlinkable]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_CLIENT.labels]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_CLIENT.gang_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_CLIENT.gang_admin_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};
/**
 * Перечисления опций для загрузки дополнительной информации вместе с пользователями.
 */
export const EXTRAS_CLIENT = Object.freeze({
  gang_admin: "gang_admin",
  gang: "gang",
});

const EXTRAS_BY_FIELDS_CLIENT = Object.freeze({
  [FIELDS_CLIENT.gang_id]: [EXTRAS_CLIENT.gang],
  [FIELDS_CLIENT.gang_admin_id]: [EXTRAS_CLIENT.gang_admin],
});

// Действия для линковки пользователей между собой. Актуально для клиентов.
export const ACTION_LOAD_INFO_USER_LINKS = "LOAD_INFO_USER_LINKS";
export const ACTION_LOAD_USER_LINKS = "LOAD_USER_LINKS";
export const ACTION_LOAD_USER_LINK_FOR_EDIT = "LOAD_USER_LINK_FOR_EDIT";
export const ACTION_ADD_USER_LINKS = "ADD_USER_LINKS";
export const ACTION_DELETE_USER_LINKS = "DELETE_USER_LINKS";
export const ACTION_LOAD_USER_LINKS_HISTORY = "LOAD_USER_LINKS_HISTORY";
export const ACTION_LOAD_INFO_USER_LINKS_HISTORY = "LOAD_INFO_USER_LINKS_HISTORY";
export const ACTION_LOAD_USER_LINKS_HISTORY_FOR_TABLE = "LOAD_USER_LINKS_HISTORY_FOR_TABLE";

export const FIELDS_USER_LINK = Object.freeze({
  "parent_user_id": "parent_user_id",
  "parent_username": "parent_username",
  "child_user_id": "child_user_id",
  "child_username": "child_username",
  "date": "date",
});

export const FILTERS_USER_LINK = Object.freeze({
  "parent_user_id": "parent_user_id",
  "parent_username": "parent_username",
  "child_user_id": "child_user_id",
  "child_username": "child_username",
});
//user_link_history
/**
 * Поля по истории мультиаккаунтинга
 */
export const FIELDS_USER_LINK_HISTORY = Object.freeze({
  hpk: "hpk",
  date: "date",
  child_user_id: "child_user_id",
  id: "id",
  parent_user_id: "parent_user_id",
  child_username: "child_username",
  parent_username: "parent_username",
});

export const TITLES_FIELDS_USER_LINK_HISTORY = Object.freeze({
  [FIELDS_USER_LINK_HISTORY.id]: "id",
  [FIELDS_USER_LINK_HISTORY.hpk]: "hpk",
  [FIELDS_USER_LINK_HISTORY.date]: "Дата",
  [FIELDS_USER_LINK_HISTORY.parent_user_id]: "Родительский ID",
  [FIELDS_USER_LINK_HISTORY.child_username]: "Дочернее название",
  [FIELDS_USER_LINK_HISTORY.parent_username]: "Родительское название",
  [FIELDS_USER_LINK_HISTORY.child_user_id]: "Дочерний ID",
});

export const TYPES_FIELDS_USER_LINK_HISTORY  = {
  [FIELDS_USER_LINK_HISTORY.date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_HISTORY.data_close_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,

};
export const EXTRAS_USER_LINKS_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_USER_LINKS_HISTORY = Object.freeze({
  [FIELDS_HISTORY.data_change_event_user_id]: [EXTRAS_USER_LINKS_HISTORY.user],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для клиентов.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_CLIENTS]() {
      const response = await this.getters.privateAjax.post("/v0/users/clients/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка клиентов.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_CLIENTS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_CLIENT, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/users/clients/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка клиентов для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CLIENTS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CLIENTS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_CLIENTS_FOR_TABLE});
    },
    /**
     * Загрузка списка клиентов для отображения в селекте.
     * Зафиксирован фильтр для поиска по активным клиентам.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @param {Number} gangId
     * @return {Promise}
     */
    async [ACTION_LOAD_CLIENTS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search, filters}) {
      // const defaultFilterForSelect = gangId
      //     ? [
      //       makeFilterApi(FILTERS_CLIENT.is_active, "=", true),
      //       makeFilterApi(FILTERS_CLIENT.gang_id, "=", gangId)
      //     ]
      //     : [makeFilterApi(FILTERS_CLIENT.is_active, "=", true)],
      const  responseData = await dispatch(ACTION_LOAD_CLIENTS, {
        pageSize,
        fields,
        filters,
        search,
        cancelTokenKey: ACTION_LOAD_CLIENTS_FOR_SELECT
      });
      return responseData.results;
    },
    /**
     * Загрузка одного клиента для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} clientId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_CLIENT_FOR_EDIT]({dispatch}, [clientId, fields]) {
      const filter = makeFilterApi(FILTERS_CLIENT.id, "=", clientId),
        responseData = await dispatch(ACTION_LOAD_CLIENTS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового клиента.
     *
     * @param {Object} context
     * @param {Object} clientInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_CLIENT](context, clientInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/users/clients/create/", clientInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего клиента.
     *
     * @param {Object} context
     * @param {Object} clientInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CLIENT](context, clientInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/users/clients/edit/", clientInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Обновление соли в токенах у клиентов.
     *
     * @param {Object} context
     * @param {Array} clientsIds
     * @return {Promise}
     */
    async [ACTION_RESET_SALT_CLIENTS](context, {clientsIds}) {
      return this.getters.privateAjax.post("/v0/users/clients/reset_salt/", {user_ids: clientsIds});
    },
    /**
     * Изменение пароля у клиента.
     *
     * @param {Object} context
     * @param {Number} clientId
     * @param {String} password
     * @return {Promise}
     */
    async [ACTION_CHANGE_PASSWORD_CLIENT](context, {clientId, password}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/users/clients/change_password/", {
          user_id: clientId,
          password
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },

    // -------------------------------------------------------------------------------------------------------------------------------------
    // links
    /**
     * Загрузка служебной информации по линкам.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_USER_LINKS]() {
      const response = await this.getters.privateAjax.post("/v0/users/links/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка линков
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_USER_LINKS](context, {page, pageSize, orderBy, fields, filters, search}) {
      const response = await this.getters.privateAjax.post("/v0/users/links/", {
        page,
        page_size: pageSize,
        order_by: orderBy,
        fields,
        filters,
        search
      });
      return response.data;
    },
    /**
     * Загрузка списка линков для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} parentUserId
     * @param {Number} childUserId
     * @param {String} searchByUsername
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_USER_LINK_FOR_EDIT]({dispatch}, {parentUserId = null, childUserId = null, searchByUsername = "", page = 1}) {
      const filters = [
        childUserId
          ? makeFilterApi(FILTERS_USER_LINK.child_user_id, "=", childUserId)
          : makeFilterApi(FILTERS_USER_LINK.parent_user_id, "=", parentUserId)
      ];
      if (searchByUsername) {
        filters.push(
          childUserId
            ? makeFilterApi(FILTERS_USER_LINK.parent_username, "ilike", searchByUsername)
            : makeFilterApi(FILTERS_USER_LINK.child_username, "ilike", searchByUsername)
        );
      }

      return dispatch(ACTION_LOAD_USER_LINKS, {
        page,
        pageSize: 10,
        orderBy: [{field: FIELDS_USER_LINK.date, direction: SORT_DIRECTIONS.DESC}],
        fields: Object.values(FIELDS_USER_LINK),
        filters,
      });
    },
    /**
     * Добавление линков.
     *
     * @param {Object} context
     * @param {Number} parentUserId
     * @param {Number} childUserId
     */
    async [ACTION_ADD_USER_LINKS](context, {parentUserId, childUserId}) {
      try {
        await this.getters.privateAjax.post("/v0/users/links/add/", {
          parent_user_id: parentUserId,
          child_user_ids: [childUserId],
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление линков.
     *
     * @param {Object} context
     * @param {Number} parentUserId
     * @param {Number} childUserId
     */
    async [ACTION_DELETE_USER_LINKS](context, {parentUserId, childUserId}) {
      try {
        await this.getters.privateAjax.post("/v0/users/links/delete/", {
          parent_user_id: parentUserId,
          child_user_ids: [childUserId],
        });
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Загрузка информации по истории мультиаккаунтинга клиентов.
     *
     */
    async [ACTION_LOAD_INFO_USER_LINKS_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/users/links/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории мультиаккаунтинга клиентов.
     *
     */
    async [ACTION_LOAD_USER_LINKS_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_USER_LINKS_HISTORY, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/users/links/history/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка истории мультиаккаунтинга клиентов.
     *
     */
    async [ACTION_LOAD_USER_LINKS_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_USER_LINKS_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_USER_LINKS_HISTORY_FOR_TABLE});
    },
  },
};
