<template>
  <div>
    <div class="row">
      <p>Эта процедура изменит существующее значение кластера у выбранного сервера.</p>
    </div>
    <div class="row">
      <SmartVSelect
        v-model="selectedServerId"
        :settings-remote-search="settingsSelectServer"
        class="col"
        placeholder="Введите сервер"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отмена
      </CamsButton>
      <CamsButton priority="primary" type="button" @click="addServerToCluster()">
        Добавить
      </CamsButton>
    </div>

    <SpinnerLoading v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {ACTION_ADD_CLUSTER_SERVER, ACTION_LOAD_SERVERS_FOR_SELECT, FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";

/**
 * Компонент диалога добавления сервера к заданному кластеру.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Идентификатор кластера.
     */
    clusterId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
      selectedServerId: null,
      settingsSelectServer: {
        action: `servers/${ACTION_LOAD_SERVERS_FOR_SELECT}`,
        valueField: FIELDS_SERVER.id,
        labelField: FIELDS_SERVER.domain
      },
    };
  },
  methods: {
    /**
     * Обработка поля выбора сервера для добавления его в кластер.
     */
    async addServerToCluster() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`servers/${ACTION_ADD_CLUSTER_SERVER}`, {
          serverId: this.selectedServerId,
          clusterIds: [this.clusterId],
        });
        this.closeDialog(true);
      } catch {
        this.$camsdals.alert("Ошибка при добавлении кластера у выбранного сервера");
      }
      this.isLoading = false;
    },
  },
};
</script>
