<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.title]"
        :caption="titlesFields[fieldsEntity.title]"
        :error="errorsFormEdit[fieldsEntity.title]"
        :make-focus="true"
        class="col"
      />
    </div>
    <div class="row">
      <SmartSelect
        v-model="formEdit[fieldsEntity.model]"
        :options="settingsModel"
        caption="Модель устройства"
        :error="errorsFormEdit[fieldsEntity.model]"
        width="fill"
      />
    </div>
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.ident]"
        :caption="titlesFields[fieldsEntity.ident]"
        :error="errorsFormEdit[fieldsEntity.ident]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.gang_id]"
        :caption="titlesFields[fieldsEntity.gang_id]"
        :error="errorsFormEdit[fieldsEntity.gang_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.mac]"
        :caption="titlesFields[fieldsEntity.mac]"
        :error="errorsFormEdit[fieldsEntity.mac]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.hw_version]"
        :caption="titlesFields[fieldsEntity.hw_version]"
        :error="errorsFormEdit[fieldsEntity.hw_version]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.config]"
        :caption="titlesFields[fieldsEntity.config]"
        :error="errorsFormEdit[fieldsEntity.config]"
        class="col"
        input-type="area"
      />
    </div>
    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.emergency_vehicle_access]"
        :caption="titlesFields[fieldsEntity.emergency_vehicle_access]"
        :error="errorsFormEdit[fieldsEntity.emergency_vehicle_access]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_active]"
        :caption="titlesFields[fieldsEntity.is_active]"
        :error="errorsFormEdit[fieldsEntity.is_active]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_DEVICE} from "@/router/names.js";
import {ACTION_CREATE_DEVICE, DEVICE_MODELS, FIELDS_DEVICE, TITLES_FIELDS_DEVICE,} from "@/store/pacs/devices/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент диалога создания устройства.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      settingsModel: DEVICE_MODELS,
      nameActionCreate: `devices/${ACTION_CREATE_DEVICE}`,
      fieldsEntity: FIELDS_DEVICE,
      titlesFields: TITLES_FIELDS_DEVICE,
      formEdit: {
        [FIELDS_DEVICE.model]: "",
        [FIELDS_DEVICE.ident]: "",
        [FIELDS_DEVICE.title]: "",
        [FIELDS_DEVICE.emergency_vehicle_access]: false,
        [FIELDS_DEVICE.mac]: "",
        [FIELDS_DEVICE.gang_id]: null,
        [FIELDS_DEVICE.hw_version]: "",
        [FIELDS_DEVICE.config]: "",
        [FIELDS_DEVICE.is_active]: true,
      },
      settingsSelects: {
        [FIELDS_DEVICE.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      }
    };
  },
  methods: {
    /**
     * Коррекции подвергаются следующие поля:
     *
     * - config - JSON
     *
     * @return {Object}
     */
    getDataForCreateEntity() {
      const deviceInfo = Object.assign({}, this.formEdit);
      deviceInfo[FIELDS_DEVICE.config] = deviceInfo[FIELDS_DEVICE.config] ? JSON.parse(deviceInfo[FIELDS_DEVICE.config]) : {};
      return deviceInfo;
    },
    /**
     * Перенаправление на редактирование устройства.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_DEVICE, params: {deviceId: newEntity[FIELDS_DEVICE.id]}};
    },
  },
};
</script>
