/**
 * Отдельный vuex модуль для взаимодействия по admins-API
 */

import {DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {FIELDS_HISTORY} from "@/store/history/index.js";

// actions для admins. Вызов действия начинать с "admins/"
export const ACTION_LOAD_INFO_ADMINS = "LOAD_INFO_ADMINS";
export const ACTION_LOAD_ADMINS = "LOAD_ADMINS";
export const ACTION_LOAD_ADMINS_FOR_TABLE = "LOAD_ADMINS_FOR_TABLE";
export const ACTION_LOAD_ADMIN_FOR_EDIT = "LOAD_ADMIN_FOR_EDIT";
export const ACTION_CREATE_ADMIN = "CREATE_ADMIN";
export const ACTION_EDIT_ADMIN = "EDIT_ADMIN";
export const ACTION_RESET_SALT_ADMINS = "RESET_SALT_ADMINS";
export const ACTION_CHANGE_PASSWORD_ADMIN = "CHANGE_PASSWORD_ADMIN";
export const ACTION_LOAD_USER_PERMISSIONS_CAMERA_HISTORY_FOR_TABLE = "_LOAD_USER_PERMISSIONS_CAMERA_HISTORY_FOR_TABLE";
export const ACTION_LOAD_INFO_USER_PERMISSIONS_CAMERA_HISTORY = "LOAD_INFO_USER_PERMISSIONS_CAMERA_HISTORY";
export const ACTION_LOAD_USER_PERMISSIONS_CAMERA_HISTORY = "LOAD_USER_PERMISSIONS_CAMERA_HISTORY";
export const ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY_FOR_TABLE = "_LOAD_USER_PERMISSIONS_GROUP_HISTORY_FOR_TABLE";
export const ACTION_LOAD_INFO_USER_PERMISSIONS_GROUP_HISTORY = "LOAD_INFO_USER_PERMISSIONS_GROUP_HISTORY";
export const ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY = "LOAD_USER_PERMISSIONS_GROUP_HISTORY";
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_ADMIN = Object.freeze({
  id: "id",
  last_login: "last_login",
  username: "username",
  is_active: "is_active",
  date_joined: "date_joined",
  is_superuser: "is_superuser",
  about: "about",
  date_block: "date_block",
  is_local_password: "is_local_password",
  labels_count: "labels_count",
  labels: "labels",
  right_group_id: "right_group_id",
  immortal_token_access: "immortal_token_access",
  immortal_token_ips: "immortal_token_ips",
  immortal_token_ips_count: "immortal_token_ips_count",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_ADMIN = Object.freeze({
  id: "id",
  right_group_id: "right_group_id",
});

/**
 * Стандартные названия для полей админа.
 */
export const TITLES_FIELDS_ADMIN = {
  [FIELDS_ADMIN.id]: "ID",
  [FIELDS_ADMIN.last_login]: "Последний вход",
  [FIELDS_ADMIN.username]: "Имя пользователя",
  [FIELDS_ADMIN.is_active]: "Активный",
  [FIELDS_ADMIN.date_joined]: "Дата создания",
  [FIELDS_ADMIN.is_superuser]: "Суперпользователь",
  [FIELDS_ADMIN.about]: "Описание",
  [FIELDS_ADMIN.date_block]: "Дата блокировки",
  [FIELDS_ADMIN.is_local_password]: "Локальный пароль",
  [FIELDS_ADMIN.labels_count]: "Количество меток",
  [FIELDS_ADMIN.labels]: "Метки",
  [FIELDS_ADMIN.right_group_id]: "Группа прав",
  [FIELDS_ADMIN.immortal_token_access]: "Доступ к ДТ",
  [FIELDS_ADMIN.immortal_token_ips]: "IP для ДТ",
  [FIELDS_ADMIN.immortal_token_ips_count]: "Кол-во IP для ДТ",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_ADMIN = {
  [FIELDS_ADMIN.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ADMIN.username]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ADMIN.last_login]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ADMIN.date_joined]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ADMIN.date_block]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ADMIN.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ADMIN.is_superuser]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ADMIN.is_local_password]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ADMIN.immortal_token_access]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ADMIN.labels]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ADMIN.immortal_token_ips]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с админами.
 */
export const EXTRAS_ADMIN = Object.freeze({
  right_group: "right_group",
});

const EXTRAS_BY_FIELDS_ADMIN = Object.freeze({
  [FIELDS_ADMIN.right_group_id]: [EXTRAS_ADMIN.right_group],
});
//Истроия изменений прав на камеры

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_USER_PERMISSIONS_CAMERA_HISTORY = Object.freeze({
  id: "id",
  permission: "permission",
  is_auto: "is_auto",
  camera_id: "camera_id",
  user_id: "user_id",
  username: "username",
  camera_number: "camera_number",
});
/**
 * Стандартные названия для полей истории по правам на камеру.
 */
export const TITLES_FIELDS_USER_PERMISSIONS_CAMERA_HISTORY = Object.freeze({
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.id]: "id",
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.permission]: "Права",
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.is_auto]: "авто ?",
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.camera_id]: "ID камеры",
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.user_id]: "USER ID",
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.username]: "Клиент",
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.camera_number]: "Номер камеры",
});

export const TYPES_FIELDS_USER_PERMISSIONS_HISTORY  = {
  [FIELDS_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_HISTORY.data_close_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_USER_PERMISSIONS_CAMERA_HISTORY.is_auto]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,

};
export const EXTRAS_USER_PERMISSIONS_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_USER_PERMISSIONS_CAMERA_HISTORY = Object.freeze({
  [FIELDS_HISTORY.data_change_event_user_id]: [EXTRAS_USER_PERMISSIONS_HISTORY.user],
});
//Истроия изменений прав на группы
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_USER_PERMISSIONS_GROUP_HISTORY = Object.freeze({
  id: "id",
  permission: "permission",
  is_auto: "is_auto",
  camera_group_id: "camera_group_id",
  camera_group_name: "camera_group_name",
  user_id: "user_id",
  username: "username",
});
/**
 * Стандартные названия для полей истории по правам на группу камер.
 */
export const TITLES_FIELDS_USER_PERMISSIONS_GROUP_HISTORY = Object.freeze({
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.id]: "id",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.permission]: "Права",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.is_auto]: "авто ?",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.camera_group_id]: "ID группы камер",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.camera_group_name]: "Группа камер",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.user_id]: "USER ID",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.username]: "Клиент",
  [FIELDS_USER_PERMISSIONS_GROUP_HISTORY.camera_number]: "Номер камеры",
});
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для тарифов.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_ADMINS]() {
      const response = await this.getters.privateAjax.post("/v0/users/admins/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка админов.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_ADMINS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_ADMIN, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/users/admins/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка админов для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_ADMINS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_ADMINS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_ADMINS_FOR_TABLE});
    },
    /**
     * Загрузка одного админа для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} adminId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_ADMIN_FOR_EDIT]({dispatch}, [adminId, fields]) {
      const filter = makeFilterApi(FILTERS_ADMIN.id, "=", adminId),
        responseData = await dispatch(ACTION_LOAD_ADMINS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового админа.
     *
     * @param {Object} context
     * @param {Object} adminInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_ADMIN](context, adminInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/users/admins/create/", adminInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего админа.
     *
     * @param {Object} context
     * @param {Object} adminInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_ADMIN](context, adminInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/users/admins/edit/", adminInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Обновление соли в токенах у админов.
     *
     * @param {Object} context
     * @param {Array} adminsIds
     * @return {Promise}
     */
    async [ACTION_RESET_SALT_ADMINS](context, {adminsIds}) {
      return this.getters.privateAjax.post("/v0/users/admins/reset_salt/", {user_ids: adminsIds});
    },
    /**
     * Изменение пароля у админа.
     *
     * @param {Object} context
     * @param {Number} adminId
     * @param {String} password
     * @return {Promise}
     */
    async [ACTION_CHANGE_PASSWORD_ADMIN](context, {adminId, password}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/users/admins/change_password/", {
          user_id: adminId,
          password,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка служебной информации по истории прав на камеры.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_USER_PERMISSIONS_CAMERA_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/permissions/cameras/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории прав.
     *
     */
    async [ACTION_LOAD_USER_PERMISSIONS_CAMERA_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_USER_PERMISSIONS_CAMERA_HISTORY, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/permissions/cameras/history/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка данных для отображения в таблице.
     *
     */
    async [ACTION_LOAD_USER_PERMISSIONS_CAMERA_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_USER_PERMISSIONS_CAMERA_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_USER_PERMISSIONS_CAMERA_HISTORY_FOR_TABLE});
    },
    /**
     * Загрузка служебной информации по истории прав на группы.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_USER_PERMISSIONS_GROUP_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/permissions/camera_groups/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории прав на группы камер.
     *
     */
    async [ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_USER_PERMISSIONS_CAMERA_HISTORY, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/permissions/camera_groups/history/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка данных для отображения в таблице.
     *
     */
    async [ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY_FOR_TABLE});
    },
  },
};
