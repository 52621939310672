/**
 * Отдельный vuex модуль для взаимодействия по camerasSetup-API
 */
// actions для camerasSetup. Вызов действия начинать с "camerasSetup/"
export const ACTION_LOAD_CAMERA_SETUP_FOR_EDIT = "LOAD_CAMERA_SETUP_FOR_EDIT";
export const ACTION_EDIT_CAMERA_SETUP = "EDIT_CAMERA_SETUP";
export const ACTION_EDIT_CAMERA_SETUP_UPGRADE = "EDIT_CAMERA_SETUP_UPGRADE";
export const ACTION_EDIT_CAMERA_SETUP_UPGRADE_MANUAL = "EDIT_CAMERA_SETUP_UPGRADE_MANUAL";
export const ACTION_EDIT_CAMERA_SETUP_REBOOT = "EDIT_CAMERA_SETUP_REBOOT";
export const ACTION_EDIT_CAMERA_SETUP_RESET = "EDIT_CAMERA_SETUP_RESET";
export const ACTION_LOAD_TIME_ZONE_FOR_SELECT = "LOAD_TIME_ZONE_FOR_SELECT";
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_CAMERA_SETUP = Object({
  audio_enable: "audio_enable",
  camera_number: "camera_number",
  model: "model",
  night_mode_enable: "night_mode_enable",
  ntp_server: "ntp_server",
  timezone: "timezone",
  video_bitrate: "video_bitrate",
  video_enable: "video_enable",
  video_resolution: "video_resolution",
  firmware_version: "firmware_version",
  kernel_version: "kernel_version",
  motion_detect_dejitter : "motion_detect_dejitter",
  motion_detect_enable : "motion_detect_enable",
  motion_detect_sensitive : "motion_detect_sensitive",
  motion_detect_server_host : "motion_detect_server_host",
  motion_detect_server_port : "motion_detect_server_port",
  motion_detect_server_path: "motion_detect_server_path",
  osd_front_color: "osd_front_color",
  osd_time_title_x: "osd_time_title_x",
  osd_time_title_y: "osd_time_title_y",
  osd_time_title_text: "osd_time_title_text",
  count_of_attempts_to_update: "count_of_attempts_to_update",
  motion_video_in_contour_light: "motion_video_in_contour_light",
  motion_video_in_wdr: "motion_video_in_wdr",
});

/**
 * Набор заготовок для выбора разрешения камеры.
 */
export const VIDEO_RESOLUTION_OPTIONS = [
  "1920x1080",
  "1280x720",
  "856x480"
];
export const VIDEO_BITRATE_OPTIONS = [
  "512",
  "1024",
  "1536",
  "2048"
];
export const NIGHT_MODE_OPTIONS = [
  "on",
  "off",
  "auto",
];
export const WDR_OPTIONS = Object.freeze({
  0 : "Выкл",
  1 : "Слабый режим",
  2 : "Средний режим",
  3 : "Cильный режим",
  4 : "Суперсильный режим",
  5 : "Включена компенсация засветки",
});
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка одной камеры для настройки.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_CAMERA_SETUP_FOR_EDIT](context, cameraNumber) {
      try {
        const cameraNumberInfo = cameraNumber[0];
        const response = await this.getters.privateAjax.post("/v0/cameras/setup", {
          camera_number: cameraNumberInfo,
        });
        return{entityInfo: response.data};
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Сохранение настроек камеры.
     * @param {Object} context
     * @param {Object} cameraInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_SETUP](context, cameraInfo,) {
      try {
        const response = await this.getters.privateAjax.post("/v0/cameras/setup/configure", cameraInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Обновление прошивки камеры.
     * Кастомный таймаут на 60сек, из-за долгой загрзуки файла прошивки.
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_SETUP_UPGRADE](context, cameraInfo) {
      try {
        return this.getters.privateAjax.post("/v0/cameras/setup/upgrade", {camera_number:cameraInfo},{timeout: 60000});
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Обновление прошивки камеры вручную
     * Кастомный таймаут на 60сек, из-за долгой загрзуки файла прошивки.
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_SETUP_UPGRADE_MANUAL](context, {cameraNumber,firmwareId}) {
      try {
        return this.getters.privateAjax.post("/v0/cameras/setup/upgrade/manual", {camera_number:cameraNumber, firmware_id: firmwareId},{timeout: 60000});
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Сброс камеры к заводским настройкам.
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_SETUP_RESET](context, cameraNumber) {
      try {
        return this.getters.privateAjax.post("/v0/cameras/setup/reset",{camera_number: cameraNumber});
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Перезагрузка камеры.
     * @param {Object} context
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_EDIT_CAMERA_SETUP_REBOOT](context, cameraNumber) {
      try {
        return this.getters.privateAjax.post("/v0/cameras/setup/reboot", {camera_number: cameraNumber});
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    // eslint-disable-next-line no-unused-vars
    async [ACTION_LOAD_TIME_ZONE_FOR_SELECT]({dispatch}, {page, pageSize,search}) {
      const response = await this.getters.privateAjax.post("/v0/timezones/", {
        page,
        search,
        pageSize
      });
      return response.data.results.map(currentValue => ({value: currentValue}));
    },

  },
};
