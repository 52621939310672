/**
 * Отдельный vuex модуль для взаимодействия по integrations/bolid/servers-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для bolid/servers. Вызов действия начинать с "integrations/bolid/servers/"
export const ACTION_LOAD_INFO_BOLID_SERVERS = "LOAD_INFO_BOLID_SERVERS";
export const ACTION_LOAD_BOLID_SERVERS = "LOAD_BOLID_SERVERS";
export const ACTION_LOAD_BOLID_SERVERS_FOR_TABLE = "LOAD_BOLID_SERVERS_FOR_TABLE";
export const ACTION_LOAD_BOLID_SERVERS_FOR_SELECT = "LOAD_BOLID_SERVERS_FOR_SELECT";
export const ACTION_LOAD_BOLID_SERVER_FOR_EDIT = "LOAD_BOLID_SERVER_FOR_EDIT";
export const ACTION_CREATE_BOLID_SERVER = "CREATE_BOLID_SERVER";
export const ACTION_EDIT_BOLID_SERVER = "EDIT_BOLID_SERVER";
export const ACTION_DELETE_BOLID_SERVER = "DELETE_BOLID_SERVERS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_BOLID_SERVER = Object.freeze({
  id: "id",
  host: "host",
  port: "port",
  soap_url: "soap_url",
  status_id: "status_id",
  is_auth_enabled: "is_auth_enabled",
  is_token_required: "is_token_required",
  login: "login",
  password: "password",
  is_deleted: "is_deleted"
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_BOLID_SERVER = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей сервера.
 */
export const TITLES_FIELDS_BOLID_SERVER = {
  [FIELDS_BOLID_SERVER.id]: "ID",
  [FIELDS_BOLID_SERVER.host]: "Хост",
  [FIELDS_BOLID_SERVER.port]: "Порт",
  [FIELDS_BOLID_SERVER.soap_url]: "URL SOAP протокола",
  [FIELDS_BOLID_SERVER.status_id]: "Статус соединения",
  [FIELDS_BOLID_SERVER.is_auth_enabled]: "Авторизация",
  [FIELDS_BOLID_SERVER.is_token_required]: "Авторизация по токену",
  [FIELDS_BOLID_SERVER.login]: "Логин",
  [FIELDS_BOLID_SERVER.password]: "Пароль",
  [FIELDS_BOLID_SERVER.is_deleted]: "Удалаен",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_BOLID_SERVER = {
  [FIELDS_BOLID_SERVER.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_BOLID_SERVER.is_auth_enabled]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_BOLID_SERVER.is_token_required]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_BOLID_SERVER.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_BOLID_SERVER.host]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_BOLID_SERVER.status_id]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с серверами.
 */
export const EXTRAS_BOLID_SERVER = Object.freeze({
  bolid_server_status: "bolid_server_status",
});

const EXTRAS_BY_FIELDS_BOLID_SERVER = Object.freeze({
  [FIELDS_BOLID_SERVER.status_id]: [EXTRAS_BOLID_SERVER.bolid_server_status]
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для серверов.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_BOLID_SERVERS]() {
      const response = await this.getters.privateAjax.post("/v0/integrations/bolid/servers/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка серверов.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_SERVERS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_BOLID_SERVER, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/integrations/bolid/servers/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    async [ACTION_LOAD_BOLID_SERVERS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search,filters}) {

      const responseData = await dispatch(ACTION_LOAD_BOLID_SERVERS, {
        pageSize,
        fields,
        filters,
        search,
        cancelTokenKey: ACTION_LOAD_BOLID_SERVERS_FOR_SELECT
      });
      return responseData.results;
    },
    /**
     * Загрузка списка серверов для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_SERVERS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_BOLID_SERVERS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_BOLID_SERVERS_FOR_TABLE});
    },
    /**
     * Загрузка одного сервера для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} serverId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_SERVER_FOR_EDIT]({dispatch}, [serverId, fields]) {
      const filter = makeFilterApi(FILTERS_BOLID_SERVER.id, "=", serverId),
        responseData = await dispatch(ACTION_LOAD_BOLID_SERVERS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового сервера.
     *
     * @param {Object} context
     * @param {Object} serverInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_BOLID_SERVER](context, serverInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/integrations/bolid/servers/create/", serverInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },

    /**
     * Редактирование существующего сервера.
     *
     * @param {Object} context
     * @param {Object} serverInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_BOLID_SERVER](context, serverInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/integrations/bolid/servers/edit/", serverInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async [ACTION_DELETE_BOLID_SERVER](context, {serverIds}) {
      return this.getters.privateAjax.post("/v0/integrations/bolid/servers/delete/", {ids: serverIds});
    },
  },
};
