<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.title]"
        :caption="titlesFields[fieldsEntity.title]"
        :error="errorsFormEdit[fieldsEntity.title]"
        :make-focus="true"
        class="col-2"
      />

      <SmartInputText
        v-model="formEdit[fieldsEntity.number]"
        :caption="titlesFields[fieldsEntity.number]"
        :error="errorsFormEdit[fieldsEntity.number]"
        class="col-2"
      />
    </div>

    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.streams]"
        :caption="titlesFields[fieldsEntity.streams]"
        :error="errorsFormEdit[fieldsEntity.streams]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.screenshot_url]"
        :caption="titlesFields[fieldsEntity.screenshot_url]"
        :error="errorsFormEdit[fieldsEntity.screenshot_url]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.ip]"
        :caption="titlesFields[fieldsEntity.ip]"
        :error="errorsFormEdit[fieldsEntity.ip]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.storage]"
        :caption="titlesFields[fieldsEntity.storage]"
        :error="errorsFormEdit[fieldsEntity.storage]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.marker_id]"
        :caption="titlesFields[fieldsEntity.marker_id]"
        :error="errorsFormEdit[fieldsEntity.marker_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.marker_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.camera_group_ids]"
        :caption="titlesFields[fieldsEntity.camera_group_ids]"
        :error="errorsFormEdit[fieldsEntity.camera_group_ids]"
        :multiple="true"
        :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
        class="col"
      />
    </div>

    <div class="row">
      <div class="col">
        <div class="param">
          <div class="param__name">
            Выберите тип
          </div>
          <div class="radiobuttons-group">
            <div class="radiobutton">
              <input
                id="chooseCluster"
                v-model="chooseCluster"
                :value="true"
                class="radiobutton__input"
                name="type"
                type="radio"
                @click="choiceClusterServer"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseCluster">Кластер</label>
            </div>
            <div class="radiobutton">
              <input
                id="chooseServer"
                v-model="chooseCluster"
                :value="false"
                class="radiobutton__input"
                name="type"
                type="radio"
                @click="choiceClusterServer"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseServer">Сервер</label>
            </div>
          </div>
          <div v-show="errorChoiceClusterServer" class="error" v-html="errorChoiceClusterServer" />
        </div>
      </div>
    </div>

    <div v-show="chooseCluster" class="row">
      <SmartVSelect
        v-model="formEdit.cluster_id"
        :error="errorsFormEdit.cluster_id"
        :settings-remote-search="settingsSelects.clusterId"
        caption="Кластер"
        class="col"
        @input="errorChoiceClusterServer = ''"
      />
    </div>

    <div v-show="!chooseCluster" class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.server_id]"
        :caption="titlesFields[fieldsEntity.server_id]"
        :error="errorsFormEdit[fieldsEntity.server_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.server_id]"
        class="col"
        @input="errorChoiceClusterServer = ''"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.tariff_id]"
        :caption="titlesFields[fieldsEntity.tariff_id]"
        :error="errorsFormEdit[fieldsEntity.tariff_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.tariff_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.gang_id]"
        :caption="titlesFields[fieldsEntity.gang_id]"
        :error="errorsFormEdit[fieldsEntity.gang_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputAddress
        v-model="formEdit[fieldsEntity.address]"
        :caption="titlesFields[fieldsEntity.address]"
        :error="errorsFormEdit[fieldsEntity.address]"
        :initial-latitude="centerMap[0] || formEdit[fieldsEntity.latitude]"
        :initial-longitude="centerMap[1] || formEdit[fieldsEntity.longitude]"
        class="col"
        placeholder="Необязательное поле"
        @extract-coordinates="[formEdit[fieldsEntity.latitude], formEdit[fieldsEntity.longitude]] = $event"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.latitude]"
        :caption="titlesFields[fieldsEntity.latitude]"
        :error="errorsFormEdit[fieldsEntity.latitude]"
        class="col-2"
        placeholder="Необязательное поле"
      />

      <SmartInputText
        v-model="formEdit[fieldsEntity.longitude]"
        :caption="titlesFields[fieldsEntity.longitude]"
        :error="errorsFormEdit[fieldsEntity.longitude]"
        class="col-2"
        placeholder="Необязательное поле"
      />
    </div>

    <div class="row">
      <div class="col">
        <MapEditCamera
          :initial-marker-latitude="formEdit[fieldsEntity.latitude]"
          :initial-marker-longitude="formEdit[fieldsEntity.longitude]"
          class="map"
          @change-center-map="centerMap = $event"
          @change-marker-position="[formEdit[fieldsEntity.latitude], formEdit[fieldsEntity.longitude]] = $event"
        />
      </div>
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.timezone]"
        :caption="titlesFields[fieldsEntity.timezone]"
        :error="errorsFormEdit[fieldsEntity.timezone]"
        :initial-options="initialOptionsTimezones"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_sounding]"
        :caption="titlesFields[fieldsEntity.is_sounding]"
        :error="errorsFormEdit[fieldsEntity.is_sounding]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import MapEditCamera from "@/components/cameras/MapEditCamera.vue";
import {ROUTE_EDIT_CAMERA} from "@/router/names.js";
import {ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT, FIELDS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {ACTION_CREATE_CAMERA, FIELDS_CAMERA, TITLES_FIELDS_CAMERA} from "@/store/cameras/index.js";
import {ACTION_LOAD_CLUSTERS_FOR_SELECT, FIELDS_CLUSTER} from "@/store/heavyMetal/clusters/index.js";
import {ACTION_LOAD_SERVERS_FOR_SELECT, FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {ACTION_LOAD_TARIFFS_FOR_SELECT, FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import ACTUAL_ZONES from "@/utils/tz.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";
import {ACTION_LOAD_MARKERS_FOR_SELECT, FIELDS_MARKER} from "@/store/markers/index.js";

/**
 * Компонент диалога создания камеры.
 */
export default {
  components: {
    MapEditCamera,
  },
  mixins: [
    createEntityMixin
  ],
  data() {
    return {
      nameActionCreate: `cameras/${ACTION_CREATE_CAMERA}`,
      fieldsEntity: FIELDS_CAMERA,
      titlesFields: TITLES_FIELDS_CAMERA,
      formEdit: {
        [FIELDS_CAMERA.title]: "",
        [FIELDS_CAMERA.number]: "",
        [FIELDS_CAMERA.streams]: [],
        [FIELDS_CAMERA.screenshot_url]: null,
        [FIELDS_CAMERA.ip]: null,
        [FIELDS_CAMERA.storage]: "",
        [FIELDS_CAMERA.address]: "",
        [FIELDS_CAMERA.longitude]: null,
        [FIELDS_CAMERA.latitude]: null,
        [FIELDS_CAMERA.timezone]: null,
        [FIELDS_CAMERA.camera_group_ids]: [],
        [FIELDS_CAMERA.server_id]: null,
        [FIELDS_CAMERA.tariff_id]: null,
        [FIELDS_CAMERA.is_sounding]: false,
        [FIELDS_CAMERA.gang_id]: null,
        cluster_id: null, // В таком виде передается в API создания камеры, не относится к полям камеры.
      },
      chooseCluster: true,
      initialOptionsTimezones: ACTUAL_ZONES,
      settingsSelects: {
        [FIELDS_CAMERA.camera_group_ids]: {
          action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP.id,
          labelField: FIELDS_CAMERA_GROUP.name
        },
        [FIELDS_CAMERA.tariff_id]: {
          action: `tariffs/${ACTION_LOAD_TARIFFS_FOR_SELECT}`,
          valueField: FIELDS_TARIFF.id,
          labelField: FIELDS_TARIFF.name
        },
        [FIELDS_CAMERA.server_id]: {
          action: `servers/${ACTION_LOAD_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_SERVER.id,
          labelField: FIELDS_SERVER.domain
        },
        [FIELDS_CAMERA.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
        [FIELDS_CAMERA.marker_id]: {
          action: `markers/${ACTION_LOAD_MARKERS_FOR_SELECT}`,
          valueField: FIELDS_MARKER.id,
          labelField: FIELDS_MARKER.name,
        },
        clusterId: {
          action: `clusters/${ACTION_LOAD_CLUSTERS_FOR_SELECT}`,
          valueField: FIELDS_CLUSTER.id,
          labelField: FIELDS_CLUSTER.name
        },
      },
      errorChoiceClusterServer: "",
      centerMap: [null, null],
    };
  },
  methods: {
    /**
     * Коррекции подвергаются следующие поля:
     *
     * - longitude, latitude - округлить до 9 знаков,
     * - server_id, cluster_id - занулить значение в зависимости от выбранной настройки.
     *
     * @return {Object}
     */
    getDataForCreateEntity() {
      const cameraInfo = Object.assign({}, this.formEdit);
      cameraInfo[FIELDS_CAMERA.longitude] = Number.isFinite(cameraInfo[FIELDS_CAMERA.longitude]) ? _.round(cameraInfo[FIELDS_CAMERA.longitude], 9) : null;
      cameraInfo[FIELDS_CAMERA.latitude] = Number.isFinite(cameraInfo[FIELDS_CAMERA.latitude]) ? _.round(cameraInfo[FIELDS_CAMERA.latitude], 9) : null;
      this.chooseCluster ? (cameraInfo[FIELDS_CAMERA.server_id] = null) : (cameraInfo.cluster_id = null);
      return cameraInfo;
    },
    /**
     * Перенаправление на редактирование камеры.
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_CAMERA, params: {cameraNumber: newEntity[FIELDS_CAMERA.number]}};
    },
    /**
     * Отслеживание события клика по переключателю отображения полей кластер/сервер.
     *
     * Необходимо для корректного отображения ошибок о невозможности одновременного выбора кластера и сервера.
     * В случае заполненного ранее выбранного поля, прервется выполнение события клика по переключателю.
     *
     * @param {Event} eventClick
     */
    choiceClusterServer(eventClick) {
      this.errorChoiceClusterServer = "";
      if (this.formEdit.cluster_id || this.formEdit[FIELDS_CAMERA.server_id]) {
        const titleFieldForEscape = this.formEdit.cluster_id ?
          "Кластер" : this.titlesFields[FIELDS_CAMERA.server_id];

        this.errorChoiceClusterServer = `Для изменения типа очистите поле "<b>${titleFieldForEscape}</b>"`;
        eventClick.preventDefault();
      }
    },
  },
};
</script>
