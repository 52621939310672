<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Кластер
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CLUSTER, fieldsEntity.name)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.name]"
                    :caption="titlesFields[fieldsEntity.name]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CLUSTER, fieldsEntity.name)"
                    :error="errorsFormEdit[fieldsEntity.name]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CLUSTER)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CLUSTER)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.CLUSTER)"
                        type="button"
                        @click="deleteCluster()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CLUSTER)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.SERVER)"
                        type="button"
                        @click="openDialogAddServerToCluster()"
                      >
                        Добавить сервер в кластер аналитики
                      </CamsButton>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CLUSTER, fieldsEntity.analytic_servers_count)">
                  <strong>
                    <router-link :to="routeServersInCluster">
                      Посмотреть все сервер аналитики в этом кластере ({{ sourceData.entityInfo[fieldsEntity.analytic_servers_count] }})
                    </router-link>
                  </strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_ANALYTIC_CLUSTERS, ROUTE_ANALYTIC_SERVERS} from "@/router/names.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {
  ACTION_DELETE_ANALYTIC_CLUSTERS,
  ACTION_EDIT_ANALYTIC_CLUSTER,
  ACTION_LOAD_ANALYTIC_CLUSTER_FOR_EDIT,
  FIELDS_ANALYTIC_CLUSTER,
  TITLES_FIELDS_ANALYTIC_CLUSTER
} from "@/store/analytics/analyticClusters/index.js";
import AddServerToAnalyticClusterDialog from "@/components/analytics/analyticClusters/AddServerToAnalyticClusterDialog.vue";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FILTERS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";

/**
 * Компонент страницы редактирования кластера.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const clusterId = Number(this.$route.params.clusterId),
          paramsRouteServersInCluster = new TableQueryParams({filters: [new FilterData(FILTERS_ANALYTIC_SERVER.analytic_cluster_id, "=", [clusterId])]}),
          routeServersInCluster = {name: ROUTE_ANALYTIC_SERVERS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteServersInCluster.stringify()}};

    return {
      routeBack: {name: ROUTE_ANALYTIC_CLUSTERS},
      fieldsEntity: FIELDS_ANALYTIC_CLUSTER,
      titlesFields: TITLES_FIELDS_ANALYTIC_CLUSTER,
      entityId: clusterId,
      nameActionLoadDataForEdit: `analytics/analyticClusters/${ACTION_LOAD_ANALYTIC_CLUSTER_FOR_EDIT}`,
      nameActionEdit: `analytics/analyticClusters/${ACTION_EDIT_ANALYTIC_CLUSTER}`,
      deletedField: FIELDS_ANALYTIC_CLUSTER.is_deleted,

      routeServersInCluster: routeServersInCluster,
    };
  },
  methods: {
    /**
     * Отправка запроса на удаление текущего кластера.
     */
    deleteCluster() {
      this.$camsdals.confirm("Хотите удалить этот кластер?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`analytics/analyticClusters/${ACTION_DELETE_ANALYTIC_CLUSTERS}`, {clusterIds: [this.entityId]});
          this.$camsdals.alert("Кластер аналитики удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении кластера");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открытие диалогового окна для добавления сервера в текущий кластер.
     */
    openDialogAddServerToCluster() {
      this.$camsdals.open(
        AddServerToAnalyticClusterDialog,
        {clusterId: this.entityId},
        {dialogTitle: "Добавление сервера в кластер аналитики"},
        {
          onClose: (needReload) => needReload ? this.loadSourceData() : null
        },
      );
    },
  },
};
</script>
