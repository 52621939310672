<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="col">
      <SmartInputText
        v-model="formEdit[fieldsEntity.name]"
        :caption="titlesFields[fieldsEntity.name]"
        :error="errorsFormEdit[fieldsEntity.name]"
      />
      <div class="marker-dialog">
        <SmartInputImage
          v-model="formEdit[fieldsEntity.main]"
          :caption="titlesFields[fieldsEntity.main]"
        />
        <SmartInputImage
          v-model="formEdit[fieldsEntity.retina]"
          :caption="titlesFields[fieldsEntity.retina]"
        />
        <SmartInputImage
          v-model="formEdit[fieldsEntity.shadow]"
          :caption="titlesFields[fieldsEntity.shadow]"
        />
      </div>
      <br>
    </div>
    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_MARKER} from "@/router/names.js";
import {ACTION_CREATE_MARKER, FIELDS_MARKER, TITLES_FIELDS_MARKER} from "@/store/markers/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
/**
 * Компонент диалога создания маркера.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      fileInput: null,
      nameActionCreate: `markers/${ACTION_CREATE_MARKER}`,
      fieldsEntity: FIELDS_MARKER,
      titlesFields: TITLES_FIELDS_MARKER,
      formEdit: {
        [FIELDS_MARKER.name]: "",
        [FIELDS_MARKER.main]: "",
        [FIELDS_MARKER.retina]: "",
        [FIELDS_MARKER.shadow]: "",
      },
    };
  },
  methods: {

    /**
     * Перенаправление на редактирование маркера.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_MARKER, params: {markerId: newEntity[FIELDS_MARKER.id]}};
    },
  },
};
</script>
<style lang="scss">
.marker-dialog {
  margin: 20px 0px 0px 160px;

}
</style>
