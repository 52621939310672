<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Маркер
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <SmartInputText
                  v-model="formEdit[fieldsEntity.name]"
                  :caption="titlesFields[fieldsEntity.name]"
                  :error="errorsFormEdit[fieldsEntity.name]"
                />
                <br>
                <SmartInputImage
                  v-model="formEdit[fieldsEntity.main]"
                  :caption="titlesFields[fieldsEntity.main]"
                  :error="errorsFormEdit[fieldsEntity.main]"
                  class="marker-image"
                />
                <SmartInputImage
                  v-model="formEdit[fieldsEntity.retina]"
                  :caption="titlesFields[fieldsEntity.retina]"
                  :error="errorsFormEdit[fieldsEntity.retina]"
                  class="marker-image"
                />
                <SmartInputImage
                  v-model="formEdit[fieldsEntity.shadow]"
                  :caption="titlesFields[fieldsEntity.shadow]"
                  :error="errorsFormEdit[fieldsEntity.shadow]"
                  class="marker-image"
                />
                <br>
                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.CAMERA)"
                        type="button"
                        @click="deleteMarker()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_MARKERS} from "@/router/names.js";
import {
  ACTION_DELETE_MARKER,
  ACTION_EDIT_MARKER,
  ACTION_LOAD_MARKER_FOR_EDIT,
  FIELDS_MARKER,
  TITLES_FIELDS_MARKER,
} from "@/store/markers/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования маркера.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const markerId = Number(this.$route.params.markerId);
    return {
      routeBack: {name: ROUTE_MARKERS},
      fieldsEntity: FIELDS_MARKER,
      titlesFields: TITLES_FIELDS_MARKER,
      entityId: markerId,
      deletedField: FIELDS_MARKER.is_deleted,
      nameActionLoadDataForEdit: `markers/${ACTION_LOAD_MARKER_FOR_EDIT}`,
      nameActionEdit: `markers/${ACTION_EDIT_MARKER}`,
      nameFieldIsDeleted: FIELDS_MARKER.is_deleted,
      formEdit: {
        [FIELDS_MARKER.name]: "",
        [FIELDS_MARKER.main]: "",
        [FIELDS_MARKER.retina]: "",
        [FIELDS_MARKER.shadow]: "",
      },
    };
  },

  methods: {
    /**
     * Отправка запроса на удаление текущего маркера.
     */
    deleteMarker() {
      this.$camsdals.confirm("Хотите удалить маркер?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`markers/${ACTION_DELETE_MARKER}`, {markerIds: [this.entityId]});
          this.$camsdals.alert("Маркер удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении маркера");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
<style lang="scss">

.marker-image {
 &__img{
  max-width: 100px;
  margin: 30px;
 }
}
</style>
