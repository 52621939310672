<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: routes.ROUTE_DEVICES }" class="tabs__item">
          Устройства доступа
        </router-link>
        <router-link :to="{name: routes.ROUTE_EMPLOYEES}" class="tabs__item">
          Сотрудники
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Группы доступа
        </router-link>
        <router-link :to="{ name: routes.ROUTE_CARS }" class="tabs__item">
          Автомобили
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openCreateDeviceAccessGroupDialog"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreateDeviceAccessGroupDialog from "@/components/pacs/deviceAccessGroups/CreateDeviceAccessGroupDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_CARS,
  ROUTE_DEVICE_ACCESS_GROUPS,
  ROUTE_DEVICES,
  ROUTE_EDIT_DEVICE_ACCESS_GROUP,
  ROUTE_EDIT_GANG,
  ROUTE_EMPLOYEES
} from "@/router/names.js";
import {
  ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE,
  ACTION_LOAD_INFO_DEVICE_ACCESS_GROUPS,
  EXTRAS_DEVICE_ACCESS_GROUP,
  FIELDS_DEVICE_ACCESS_GROUP,
  FILTERS_DEVICE_ACCESS_GROUP,
  TITLES_FIELDS_DEVICE_ACCESS_GROUP,
  TYPES_FIELDS_FIELDS_DEVICE_ACCESS_GROUP,
} from "@/store/pacs/deviceAccessGroups/index.js";
import {EXTRAS_EMPLOYEE, FIELDS_EMPLOYEE, FILTERS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по группам камер.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_EMPLOYEES,
        ROUTE_CARS,
        ROUTE_DEVICES,
      },
      currentRoute: ROUTE_DEVICE_ACCESS_GROUPS,
      rawFields: FIELDS_DEVICE_ACCESS_GROUP,
      columnCaptions: TITLES_FIELDS_DEVICE_ACCESS_GROUP,
      nameActionLoadDataForTable: `deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `deviceAccessGroups/${ACTION_LOAD_INFO_DEVICE_ACCESS_GROUPS}`,
      defaultFieldsTableForView: [
        FIELDS_DEVICE_ACCESS_GROUP.id,
        FIELDS_DEVICE_ACCESS_GROUP.title,
        FIELDS_DEVICE_ACCESS_GROUP.gang_id,
        FIELDS_DEVICE_ACCESS_GROUP.employee_ids,
        FIELDS_DEVICE_ACCESS_GROUP.is_deleted,
      ],
      defaultFilters: [
        new FilterData(FILTERS_DEVICE_ACCESS_GROUP.is_deleted, "=", [false])
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_DEVICE_ACCESS_GROUP.employee]) {
        storedExtraInfo.employees = {};
        sourceExtraInfo[EXTRAS_DEVICE_ACCESS_GROUP.employee].forEach((employeeInfo) => {
          storedExtraInfo.employees[employeeInfo[FIELDS_EMPLOYEE.id]] = {
            id: employeeInfo[FIELDS_EMPLOYEE.id],
            name: employeeInfo[FIELDS_EMPLOYEE.title],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_EMPLOYEE.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_EMPLOYEE.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {},
          params;

      switch (nameField) {
      case FIELDS_DEVICE_ACCESS_GROUP.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_DEVICE_ACCESS_GROUP.title:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId: rowData[FIELDS_DEVICE_ACCESS_GROUP.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_DEVICE_ACCESS_GROUP.employee_ids:
        readyTranslation = _.chain(storedExtraInfo.employees)
          .pick(rawTranslation)
          .mapValues("name")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_EMPLOYEE.device_access_group_id, "=", [rowData[FIELDS_DEVICE_ACCESS_GROUP.id]])]});
        readyParams = {name: ROUTE_EMPLOYEES, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      case FIELDS_EMPLOYEE.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_FIELDS_DEVICE_ACCESS_GROUP [nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания группы камер.
     */
    openCreateDeviceAccessGroupDialog() {
      this.$camsdals.open(CreateDeviceAccessGroupDialog, {}, {dialogTitle: "Создание группы доступа к устройствам"});
    },
  },
};
</script>
