<template>
  <div>
    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}" style="width: 450px ;">
        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.open)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.open]"
                    :caption="titlesFields[fieldsEntity.open]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.open)"
                    :error="errorsFormEdit[fieldsEntity.open]"
                    :make-focus="true"
                    placeholder="Порог для срабатывания реле в промилле от 1 до 100"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.adjust)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.adjust]"
                    :caption="titlesFields[fieldsEntity.adjust]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.adjust)"
                    :error="errorsFormEdit[fieldsEntity.adjust]"
                    placeholder="Порог скользящих средних в промилле от 10 до 200"
                    class="col"
                  />
                </div>

                <fieldset
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.loop0_camera_number)"
                  class="row"
                  style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding: 17px 10px;"
                >
                  <legend
                    style="font: 16px 'Roboto-Medium'; text-align: right"
                  >
                    Петля 0
                  </legend>
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.loop0_camera_number]"
                    :error="errorsFormEdit[fieldsEntity.loop0_camera_number]"
                    :caption="formEdit[fieldsEntity.loop0_camera_number] ? `Камера ${formEdit[fieldsEntity.loop0_camera_number]}` : 'Камера не выбрана'"
                    :settings-remote-search="settingsSelectCameras1"
                    class="settings_access__select"
                    placeholder="Выберите камеру для петли 0"
                  />
                  <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.loop0_mode)" class="row">
                    <SmartSelect
                      v-model="formEdit[fieldsEntity.loop0_mode]"
                      :options="settingsModel"
                      :caption="titlesFields[fieldsEntity.loop0_mode]"
                      width="fill"
                    />
                  </div>
                </fieldset>
                <fieldset
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.loop1_camera_number)"
                  style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding: 17px 10px;"
                  class="row"
                >
                  <legend
                    style="font: 16px 'Roboto-Medium'; text-align: right"
                  >
                    Петля 1
                  </legend>
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.loop1_camera_number]"
                    :error="errorsFormEdit[fieldsEntity.loop1_camera_number]"
                    :caption="formEdit[fieldsEntity.loop1_camera_number] ? `Камера ${formEdit[fieldsEntity.loop1_camera_number]}` : 'Камера не выбрана'"
                    :settings-remote-search="settingsSelectCameras2"
                    class="settings_access__select"
                    placeholder="Выберите камеру для петли 1"
                  />
                  <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.loop1_mode)" class="row">
                    <SmartSelect
                      v-model="formEdit[fieldsEntity.loop1_mode]"
                      :options="settingsModel"
                      :caption="titlesFields[fieldsEntity.loop1_mode]"
                      width="fill"
                    />
                  </div>
                </fieldset>
                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>
                  <div class="col">
                    <p>
                      {{ titlesFields[fieldsEntity.sync_success] }}:
                      <strong>{{ sourceData.entityInfo[fieldsEntity.sync_success] | bool }}</strong>
                    </p>
                    <p>
                      {{ titlesFields[fieldsEntity.sync_last_date] }}:
                      <strong>{{ sourceData.entityInfo[fieldsEntity.sync_last_date] | localDateTime }}</strong>
                    </p>
                    <p
                      v-if="sourceData.entityInfo[fieldsEntity.sync_error]"
                    >
                      {{ titlesFields[fieldsEntity.sync_error] }}:
                      <strong>{{ sourceData.entityInfo[fieldsEntity.sync_error] }}</strong>
                    </p>
                    <strong>
                      <router-link :to="routeDeviceModuleHistory">Посмотреть историю изменения </router-link>
                    </strong>
                  </div>
                </template>
              </div>
            </div>
          </form>
          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ACTION_EDIT_MODULE,
  ACTION_LOAD_MODULE_FOR_EDIT, FIELDS_DEVICE,
  FIELDS_MODULE,
  TITLES_FIELDS_MODULE,
} from "@/store/pacs/devices/index.js";
import {editDataEntityMixin, methodsForDialogMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_CAMERAS_FOR_SELECT, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {
  ROUTE_EDIT_DEVICE,
  ROUTE_EDIT_GANG,
  ROUTE_VIEW_DEVICE_HISTORY,
  ROUTE_VIEW_DEVICE_MODULE_HISTORY
} from "@/router/names.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";

/**
 * Компонент страницы редактирования устройства доступа.
 */
export default {
  mixins: [editDataEntityMixin,methodsForDialogMixin],
  props: {
    moduleNumber: {
      type: Number,
      required: true,
    },
    moduleInfo: {
      type: Array,
      required: true,
    },
    deviceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fieldsEntity: FIELDS_MODULE,
      titlesFields: TITLES_FIELDS_MODULE,
      nameActionLoadDataForEdit: `devices/${ACTION_LOAD_MODULE_FOR_EDIT}`,
      nameActionEdit: `devices/${ACTION_EDIT_MODULE}`,
      entityId: this.moduleNumber,
      showLoop0: false,
      showLoop1: false,
      selectedCameraId: null,
      routeDeviceModuleHistory: null,
      settingsSelectCameras1: {
        action: `cameras/${ACTION_LOAD_CAMERAS_FOR_SELECT}`,
        valueField: FIELDS_CAMERA.number,
        labelField: [FIELDS_CAMERA.number, FIELDS_CAMERA.title, FIELDS_CAMERA.address],
      },
      settingsSelectCameras2: {
        action: `cameras/${ACTION_LOAD_CAMERAS_FOR_SELECT}`,
        valueField: FIELDS_CAMERA.number,
        labelField: [FIELDS_CAMERA.number, FIELDS_CAMERA.title, FIELDS_CAMERA.address],
      },
      settingsModel: {
        0 : "Всегда открывать",
        1 : "Никак не реагировать",
        2 : "Распознавание"
      },
    };
  },
  methods: {
    async afterLoadSourceData() {
      {
        const paramsRouteDeviceModuleHistory = new TableQueryParams({
          filters: [
            new FilterData(FIELDS_MODULE.id,  "=", [this.entityId]),
          ]});
        this.routeDeviceModuleHistory = {
          name: ROUTE_VIEW_DEVICE_MODULE_HISTORY,
          query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteDeviceModuleHistory.stringify()},
          params: {moduleId: this.entityId, deviceId: this.deviceId}};
      }

    },

    async saveDataAndRedirect() {
      try {
        await this.$_editDataEntityMixin_saveData();
        await this.$router.push(this.routeBack);
      } catch {
        // Заглушка чтобы не пробрасывать исключение из $_editDataEntityMixin_saveData(), но прервать нормальный процесс функции.
      }
      this.loadSourceData();
      this.closeDialog();
    },
  },
};
</script>
