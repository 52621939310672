<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Прошивки
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @submit.prevent="saveData()" @keyup.enter="saveData()">
            <div class="row">
              <div class="col-2">
                <!--                <strong style="font-size: 15px">Модель камеры: {{ formEdit[fieldsEntity.model] }}</strong>-->
                <!--                <br>-->
                <!--                <strong style="font-size: 15px">Тип файла: {{ formEdit[fieldsEntity.type] }}</strong>-->
                <!--                <br>-->
                <SmartSelect
                  v-model="formEdit[fieldsEntity.model]"
                  :options="this.supportedCameraModels"
                  caption="Модель камеры"
                  :error="errorsFormEdit[fieldsEntity.model]"
                  width="fill"
                />
                <SmartSelect
                  v-model="formEdit[fieldsEntity.type]"
                  :options="this.typesFirmwareFile"
                  caption="Тип файла"
                  :error="errorsFormEdit[fieldsEntity.type]"
                  width="fill"
                />
                <SmartInputText
                  v-model="formEdit[fieldsEntity.version]"
                  :caption="titlesFields[fieldsEntity.version]"
                  :error="errorsFormEdit[fieldsEntity.version]"
                />
                <template v-if="!isDeleted">
                  <br>
                  <div><strong>Дата загрузки прошивки:</strong> {{ formEdit.upload_date | localDateTime }}</div>
                  <br>
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.CAMERA)"
                        type="button"
                        @click="deleteFirmware()"
                      >
                        Удалить
                      </CamsButton>
                      <div>
                        <input
                          ref="fileInput"
                          type="file"
                          style="display:none"
                          accept=".squashfs"
                          onclick="this.value=null"
                          @change="firmwareUploadFile"
                        >

                        <CamsButton
                          v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                          priority="primary"
                          type="button"
                          @click="saveData()"
                        >
                          Сохранить
                        </CamsButton>
                      </div>
                    </div>
                  </div>
                  <div style="color: #E12F2F">
                    {{ errorsFormEdit.firmware }}
                  </div>
                </template>
              </div>
              <div class="row-2" />
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
      <notifications classes="customized-notification-style" group="main" position="top right" />
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_FIRMWARES} from "@/router/names.js";
import {
  ACTION_DELETE_FIRMWARE,
  ACTION_EDIT_FIRMWARE,
  ACTION_LOAD_FIRMWARE_FOR_EDIT, ACTION_LOAD_SUPPORTED_CAMERA_MODELS,
  FIELDS_FIRMWARE,
  TITLES_FIELDS_FIRMWARE, TYPES_FIRMWARE,
} from "@/store/firmwares/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {reformatErrorsForm} from "@/utils/helpers.js";

/**
 * Компонент страницы редактирования прошивки.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    return {
      fieldsEntity: FIELDS_FIRMWARE,
      routeBack: {name: ROUTE_FIRMWARES},
      titlesFields: TITLES_FIELDS_FIRMWARE,
      supportedCameraModels: [],
      typesFirmwareFile: TYPES_FIRMWARE,
      entityId: this.$route.params.firmwareId,
      deletedField: FIELDS_FIRMWARE.is_deleted,
      nameActionLoadDataForEdit: `firmwares/${ACTION_LOAD_FIRMWARE_FOR_EDIT}`,
      formEdit: {
        [FIELDS_FIRMWARE.firmware]: "",
        [FIELDS_FIRMWARE.version]: "",
        [FIELDS_FIRMWARE.type]: "",
        [FIELDS_FIRMWARE.model]: "",
      },
    };
  },
  created() {
    this.$store.dispatch(`firmwares/${ACTION_LOAD_SUPPORTED_CAMERA_MODELS}`, {
    })
      .then((supportedCameraModels) => {
        this.supportedCameraModels = supportedCameraModels.results ;
      });

  },
  methods: {
    async firmwareUploadFile(event) {
      this.formEdit.firmware = event.target.files[0];
      this.$notify({
        group: "main",
        text: "Чтобы файл применился нажмите сохранить",
        duration: 5000,
        type: "default"
      });
    },
    async saveData() {
      this.isLoading = true;
      const firmwareInfo = {
        'id': this.formEdit.id,
        'model': this.formEdit.model,
        'type': this.formEdit.type,
        'firmware':this.formEdit.firmware,
        'version':this.formEdit.version
      };
      try {
        await this.$store.dispatch(`firmwares/${ACTION_EDIT_FIRMWARE}`, firmwareInfo);
        this.$notify({
          group: "main",
          text: "Прошивка обновлена",
          duration: 5000,
          type: "success"
        });
        this.errorsFormEdit = {};
        await this.loadSourceData();
      } catch (error) {
        this.errorsFormEdit = reformatErrorsForm(error.fields);
        this.$notify({
          group: "main",
          text: `Ошибка при сохранении`,
          duration: 5000,
          type: "error"
        });
      }
      this.isLoading = false;
    },
    /**
     * Отправка запроса на удаление текущей прошивки.
     */
    deleteFirmware() {
      this.$camsdals.confirm("Хотите удалить прошивку?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`firmwares/${ACTION_DELETE_FIRMWARE}`, {id: this.entityId});
          this.$camsdals.alert("Прошивка удалена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении прошивки");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
