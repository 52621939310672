/**
 * Примеси для проекта.
 */

import {MUTATION_CHANGE_DEFAULT_CENTER_MAP} from "@/store/mutations.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {reformatErrorsForm} from "@/utils/helpers.js";
import {mapState} from "vuex";

/**
 * Общие методы для применения на компонентах - диалоговых окон.
 */
export const methodsForDialogMixin = {
  /**
   * Фокус при показе диалогового окна, если нужно.
   */
  mounted() {
    if (this.$refs.elementForFocus) {
      this.$refs.elementForFocus.focus();
    }
  },
  methods: {
    /**
     * Закрытие диалогового окна.
     *
     * @param data
     */
    closeDialog(data) {
      this.$vuedals.close(data);
    },
  }
};

/**
 * Общая примесь для компонентов с формами в которых есть поля для редактирования данных.
 */
export const formWithFieldsMixin = {
  data() {
    return {
      /**
       * Маркер для отображения процесса загрузки.
       */
      isLoading: false,
      /**
       * Объект с перечнем полей сущности.
       * Требует перекрытия.
       */
      fieldsEntity: {},
      /**
       * Перечень заголовков полей формы.
       * Требует перекрытия.
       */
      titlesFields: {},
      /**
       * Перечень моделей для полей с редактируемыми данными.
       *
       * Для редактирования каждого поля в отдельности предусмотрена отдельная модель,
       * которая заполняется значениями при загрузке данных на стороне
       * До загрузки данных участок шаблона с формой не будет отрендерен, т.к. необходимо передать во внутренние компоненты
       * подготовленные данные.
       *
       * Начальное null для отслеживания пустого значения - данные еще не загружены и не форма не должна рендерится
       */
      formEdit: null,
      /**
       * Отдельное поле с комментарием для выполняемой операции (сохранение/редактирование).
       * Не является полем какой-либо сущности, но передается как отдельное поле в API для ведения истории.
       */
      comment: "",
      /**
       * Перечень ошибок валидации по полям, для показа на форме.
       */
      errorsFormEdit: {},
    };
  },
};

/**
 * Примесь для случаев когда надо учитывать маршрут с которого пользователь попал на страницу, чтобы иметь возможность вернуться обратно.
 * Используется при переходе со страниц просмотра таблицы сущностей на страницы их редактирования.
 */
export const routeBackMixin = {
  data() {
    return {
      /**
       * Маршрут на который будет перенаправлен пользователь при использовании навигации на странице.
       * Требует перекрытия.
       */
      routeBack: {},
    };
  },
  /**
   * Уточняется маршрут назад на страницу с таблицей, которая была в том состоянии (с нужными фильтрами)
   * из которого был совершен на страницу редактирования.
   */
  created() {
    // todo имеет смысл проверять совпадает ли имя маршрута с тем что стоит в this.routeBack
    // если вдруг на редактирование одного типа сущности перешли с таблицы просмотра другого типа - тогда параметры таблицы будут нерелевантны
    if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
      this.routeBack.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
    }
  },
};

/**
 * Примесь для маркировки удаленных сущностей.
 * Применять для страниц просмотра информации о сущности, но там где надо визуально выделить внешнюю рамку внутри которой размещена информация.
 */
export const deletedEntityMarkMixin = {
  data() {
    return {
      /**
       * Значение ключевого идентификатора сущности для его передачи в API для получения и сохранения данных.
       * Требует перекрытия.
       * @link editDataEntityMixin
       */
      entityId: null,
      /**
       * Полное название action в vuex, которое предназначено для загрузки данных по конкретной сущности.
       * Требует перекрытия.
       * @link editDataEntityMixin
       */
      nameActionLoadDataForEdit: null,
      /**
       * Поле с признаком удаления сущности.
       * Требует перекрытия.
       * @link editDataEntityMixin
       */
      deletedField: null,
      /**
       * Вычисляемое в примеси значение. Если сущность удалена = true. Если нет или если не хватает данных для получения флага = false.
       */
      isDeleted: false,
    };
  },
  mounted() {
    this.$_deletedEntityMarkMixin_loadDeletedMark();
  },
  methods: {
    /**
     * Загрузит информацию для маркера удаленной сущности.
     */
    async $_deletedEntityMarkMixin_loadDeletedMark() {
      if (this.nameActionLoadDataForEdit && this.entityId && this.deletedField) {
        try {
          const {entityInfo} = await this.$store.dispatch(this.nameActionLoadDataForEdit, [this.entityId, [this.deletedField]]);
          this.isDeleted = entityInfo[this.deletedField];
        } catch {
          //
        }
      }
    },
  },
};

/**
 * Примесь для компонентов редактирования данных некоторой сущности.
 *
 * Верстка у каждого компонента своя, со своими особенностями, но набор функций связанных с обработкой ошибок,
 * получения и сохранения информации в общем один и тот же и не отличается, потому и вынесен в это общее место.
 *
 * В наследниках необходимо перекрывать часть полей, описывающих редактируемую сущность.
 */
export const editDataEntityMixin = {
  mixins: [formWithFieldsMixin, routeBackMixin],
  data() {
    return {
      /**
       * Маркер для отображения процесса загрузки формы редактирования данных и завершении инициализации всех необходимых последовательностей.
       * Как правило это первая загрузка данных.
       */
      isEditFormLoading: false,
      /**
       * Значение ключевого идентификатора сущности для его передачи в API для получения и сохранения данных.
       * Требует перекрытия.
       */
      entityId: null,
      /**
       * Полное название action в vuex, которое предназначено для загрузки данных по конкретной сущности.
       * Требует перекрытия.
       */
      nameActionLoadDataForEdit: null,
      /**
       * Полное название action в vuex, которое предназначено для отправки обновленных данных.
       * Требует перекрытия.
       */
      nameActionEdit: null,
      /**
       * Исходные данные по редактируемой сущности и дополнительная информация по ней.
       */
      sourceData: {
        entityInfo: {},
        extraInfo: {}
      },
      /**
       * Поле с признаком удаления сущности.
       */
      deletedField: null,
    };
  },
  computed: {
    /**
     * @return {Boolean} Вернет true если сущность удалена. Проверка идет по полю с флагом удаления, если такого поля не указано то вернет false.
     */
    isDeleted() {
      return this.deletedField && this.sourceData.entityInfo[this.deletedField];
    },
    /**
     * @return {String[]} Список доступных полей для просмотра.
     */
    fieldsForRead() {
      return Object.values(this.fieldsEntity).filter(
        (fieldName) => this.$can(this.$abilitiesActions.READ_FIELD, this.$route.meta.abilitySubject, fieldName)
      );
    },
    /**
     * @return {String[]} Список доступных полей для обновления.
     */
    fieldsForUpdate() {
      return Object.values(this.fieldsEntity).filter(
        (fieldName) => this.$can(this.$abilitiesActions.UPDATE_FIELD, this.$route.meta.abilitySubject, fieldName)
      );
    },
  },
  /**
   * Загрузка данных для редактирования.
   */
  mounted() {
    this.isEditFormLoading = true;
    this.loadSourceData()
      .finally(() => {
        this.isEditFormLoading = false;
      });
  },
  methods: {
    /**
     * Загрузит информацию для редактирования и подставит загружаемые данные "как есть" в поля формы.
     *
     * @return {Promise}
     */
    async loadSourceData() {
      this.isLoading = true;
      try {
        const {entityInfo, extraInfo} = await this.$store.dispatch(this.nameActionLoadDataForEdit, [this.entityId, this.fieldsForRead]);
        this.sourceData.entityInfo = entityInfo;
        this.sourceData.extraInfo = extraInfo;
        this.formEdit = _.cloneDeep(entityInfo);
        await this.afterLoadSourceData();
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Метод по умолчанию вызовется в `then` при загрузке данных для редактирования.
     * Необходим для операций по уточнению загруженной информации.
     */
    async afterLoadSourceData() {
    },
    /**
     * Вернет набор данных пригодных к сохранению.
     * В наследниках может быть перекрыт с целью преобразования содержимого некоторых полей.
     *
     * @return {Object}
     */
    getDataForSave() {
      return this.formEdit;
    },
    /**
     * Отправка данных, пригодных к сохранению, на сервер.
     * Перед самой отправкой происходит фильтрация собранных данных по информации о наличии прав на их обновление.
     *
     * todo нет обработки общих ошибок 400 не связанных с валидацией передаваемых полей
     *
     * @return {Promise}
     */
    async $_editDataEntityMixin_saveData() {
      this.isLoading = true;
      this.errorsFormEdit = {};
      try {
        await this.$store.dispatch(this.nameActionEdit, _.pick(this.getDataForSave(), this.fieldsForUpdate));
      } catch (error) {
        this.errorsFormEdit = reformatErrorsForm(error.fields);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Сохранение данных и продолжение редактирования.
     * После успешного сохранения данных происходит перезагрузка исходных данных.
     *
     * @return {Promise}
     */
    async saveData() {
      try {
        await this.$_editDataEntityMixin_saveData();
        this.loadSourceData();
      } catch {
        // Заглушка чтобы не пробрасывать исключение из $_editDataEntityMixin_saveData(), но прервать нормальный процесс функции.
      }
    },
    /**
     * Сохранение данных и редирект по маршруту routeBack.
     *
     * @return {Promise}
     */
    async saveDataAndRedirect() {
      try {
        await this.$_editDataEntityMixin_saveData();
        await this.$router.push(this.routeBack);
      } catch {
        // Заглушка чтобы не пробрасывать исключение из $_editDataEntityMixin_saveData(), но прервать нормальный процесс функции.
      }
    },
  }
};

/**
 * Примесь для компонентов для создания некоторой сущности.
 * Аналогичен {@link editDataEntityMixin} но проще, т.к. не требует предварительной загрузки данных.
 * Как правило, создание сущности происходит в диалоговом окне.
 */
export const createEntityMixin = {
  mixins: [methodsForDialogMixin, formWithFieldsMixin],
  data() {
    return {
      /**
       * Полное название action в vuex, которое предназначено для отправки новых данных.
       * Требует перекрытия.
       */
      nameActionCreate: null,
    };
  },
  methods: {
    /**
     * Вернет набор данных пригодных к сохранению.
     * В наследниках может быть перекрыт с целью преобразования содержимого некоторых полей.
     *
     * @return {Object}
     */
    getDataForCreateEntity() {
      return this.formEdit;
    },
    /**
     * Вернет маршрут по которому будет совершено перенаправление после успешного создания сущности.
     * Принимает информацию по новой созданной сущности.
     * Если ничего не вернет - редиректа не будет.
     *
     * @param {Object} newEntity
     * @return {boolean|null|Object}
     */
    // eslint-disable-next-line no-unused-vars
    getRouteRedirectAfterCreate(newEntity) {
      return false;
    },
    /**
     * Отправка данных на сервер для создания новой сущности.
     *
     * @return {Promise}
     */
    async createEntity() {
      this.isLoading = true;
      this.errorsFormEdit = {};
      try {
        return await this.$store.dispatch(this.nameActionCreate, this.getDataForCreateEntity());
      } catch (error) {
        this.errorsFormEdit = reformatErrorsForm(error.fields);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Отправка данных на сервер для создания новой сущности и в случае успеха редирект на страницу ее редактирования.
     * Перехват пробрасываемого catch чтобы не сыпались ошибки валидации.
     *
     * @return {Promise}
     */
    async createEntityAndRedirect() {
      try {
        const result = await this.createEntity(),
          routeRedirect = this.getRouteRedirectAfterCreate(result);
        if (routeRedirect) {
          this.$router.push(routeRedirect);
        }
      } catch {
        // Заглушка для исключений чтобы прервать нормальное поведение.
      }
    }
  }
};

/**
 * Примесь для компонентов на которых размещены компоненты с картой.
 * Набор общих функций для поддержания единой логики во всех случаях работы с картой.
 *
 * Событие `@change-center-map` выбрасывают изменения отображаемого центра карты.
 */
export const mapMixin = {
  data() {
    return {
      centerMap: [null, null],
    };
  },
  computed: {
    ...mapState({
      settingsMap: state => state.settingsMap,
    }),
  },
  /**
   * При создании компонента восстанавливается ранее сохраненный центр карты.
   */
  created() {
    this.centerMap = this.settingsMap.defaultCenter;
  },
  methods: {
    /**
     * Функция для фиксации изменений центра карты, и проброса `@change-center-map`.
     *
     * @param {{lat: Number, lng: Number}|Array} newCenterMap
     */
    changeCenterMap(newCenterMap) {
      const [latitude, longitude] = this.extractCoordinates(newCenterMap);
      this.$emit("change-center-map", [latitude, longitude]);
      this.$store.commit(MUTATION_CHANGE_DEFAULT_CENTER_MAP, [latitude, longitude]);
      this.centerMap = [latitude, longitude];
    },
    /**
     * Вернет пару [широта, долгота] извлеченную из объекта полученного в результате работы L-компонентов
     * или других функций которые возвращают массив.
     *
     * @param {{lat: Number, lng: Number}|Array} LObjOrArray
     */
    extractCoordinates(LObjOrArray) {
      if (Array.isArray(LObjOrArray)) {
        return LObjOrArray;
      }
      return [LObjOrArray.lat, LObjOrArray.lng];
    }
  },
};

/**
 * Примесь для родительских компонентов в диалоговых окнах,
 * которые объединяют функционал по работе мультиредактирования каких-либо сущностей.
 */
export const multiEditEntityMixin = {
  props: {
    /**
     * Перечень с ключевыми идентификаторами редактируемых сущностей.
     */
    selectedEntities: {
      type: Array,
      default: () => ([])
    },
    /**
     * Количество изначально выбранных элементов.
     * Нужно исключительно для проверки т.к. если не совпадает с количеством полученных элементов - повод задуматься.
     */
    countSelectedOriginally: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      /**
       * Функция проведения (или вызова другой функции для) непосредственной обработки данных над одним экземляром сущности.
       */
      promiseSaveData: null,
      /**
       * Выбранный компонент для отображения формы с элементами редактирования данных.
       */
      selectedComponentMultiEdit: null,
      /**
       * Флаг для обозначения готовности перехода к процессу обработки.
       */
      isReadyForProcessing: true,
      /**
       * Условный маркер для перехода к процессу обработки и получению результатов мультиредактирования.
       */
      goProcessing: false,
      /**
       * Перечень компонентов для мультиредактирования. Требует перекрытия.
       */
      componentsMultiEdit: {},
    };
  },
  watch: {
    /**
     * Сброс функции сохранения данных при смене компонента их редактирования.
     */
    selectedComponentMultiEdit() {
      this.promiseSaveData = null;
    }
  },
};

/**
 * Примесь для компонентов, которые обеспечивают формы редактирования данных для множественного редактирования.
 */
export const functionMultiEditMixin = {
  /**
   * Сразу при создании обновление в родителе функции отправки изменений и возможности запуска процесса обработки если форма это позволяет.
   */
  created() {
    this.readyForProcessing();
    this.changePromise();
  },
  methods: {
    /**
     * Выброс через `@change-promise` функции отправки изменений при изменении данных формы, с замыканием этих данных.
     */
    changePromise() {
      // eslint-disable-next-line no-unused-vars
      this.$emit("change-promise", (entityKey, index) => Promise.resolve());
    },
    /**
     * Выброс `@ready-for-processing` с флагом готовности к обработке.
     * В общем случае всегда возвращает true, но если форма требует некоторых клиентских проверок, из-за которых требуется заблокировать
     * элементы запуска обработки.
     */
    readyForProcessing() {
      this.$emit("ready-for-processing", () => true);
    },
  },
};
