<template>
  <div>
    <div class="row">
      <p>Здесь вы можете заблокировать или разблокировать камеру.</p>
    </div>

    <div class="row">
      <SmartInputDate
        v-model="formEdit.blockingEndDate"
        :config-flat-pickr="configFlatPickr"
        :error="errorsFormEdit.blockingEndDate"
        caption="Дата снятия блокировки"
        class="col-2"
      />

      <div class="col-2">
        <div class="buttons-group">
          <LevelBlockingSelect v-model="formEdit.blockingLevel" />
        </div>
      </div>
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit.comment"
        caption="Комментарий"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отмена
      </CamsButton>
      <CamsButton priority="primary" type="button" @click="blockingCamera()">
        Заблокировать
      </CamsButton>
    </div>

    <SpinnerLoading v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {methodsForDialogMixin} from "@/utils/mixins.js";
import {ACTION_BLOCKING_CAMERAS, TOKEN_TYPES} from "@/store/cameras/index.js";
import LevelBlockingSelect from "@/components/cameras/blocking/LevelBlockingSelect.vue";

/**
 * Компонент диалога блокировки доступа к камере.
 */
export default {
  components: {
    LevelBlockingSelect,
  },
  mixins: [
    methodsForDialogMixin,
  ],
  props: {
    /**
     * Номер камеры.
     */
    cameraNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      formEdit: {
        blockingLevel: TOKEN_TYPES.L,
        blockingEndDate: (new Date()).toISOString(),
        comment: "",
      },
      errorsFormEdit: {
        blockingEndDate: "",
      },
      // Для снятия блокировки допускается только будущее время.
      configFlatPickr: {
        minDate: "today",
        minTime: Date.now(),
      }
    };
  },
  watch: {
    /**
     * Сообщение о необходимости обязательного заполнения поля возможно только на клиенте - серверу это не обязательно.
     */
    "formEdit.blockingEndDate"() {
      this.errorsFormEdit.blockingEndDate = !this.formEdit.blockingEndDate ? "Это поле является обязательным" : "";
    }
  },
  methods: {
    /**
     * Отправка запроса на блокировку камеры.
     */
    async blockingCamera() {
      if (!this.formEdit.blockingLevel || !this.formEdit.blockingEndDate) {
        return;
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_BLOCKING_CAMERAS}`, {
          camerasNumbers: [this.cameraNumber],
          blockingLevel: this.formEdit.blockingLevel,
          blockingEndDate: this.formEdit.blockingEndDate,
          comment: this.formEdit.comment,
        });
        this.closeDialog(true);
      } catch {
        this.$camsdals.alert("Ошибка при блокировке камеры");
      }
      this.isLoading = false;
    },
  },
};
</script>
