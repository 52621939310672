<template>
  <LogAuthsByCriteria
    :criteria="{userId: clientId}"
    :initial-current-page="currentPage"
    :name-action-load-log="nameActionLoadLog"
  />
</template>

<script>
import {ACTION_LOAD_LOG_AUTHS_BY_USER} from "@/store/log/index.js";
import LogAuthsByCriteria from "@/components/log/LogAuthsByCriteria.vue";

/**
 * Компонент страницы отбражения таблицы быстрого доступа к логам авторизации.
 */
export default {
  components: {
    LogAuthsByCriteria,
  },
  props: {
    /**
     * ID клиента по которому будут загружены логи. Передается через параметры маршрута.
     */
    clientId: {
      type: Number,
      required: true,
    },
    /**
     * Начальное значение номер страницы в таблице логов. Передается через параметры маршрута.
     */
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      nameActionLoadLog: ACTION_LOAD_LOG_AUTHS_BY_USER,
    };
  },
};
</script>
