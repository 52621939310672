<template>
  <div>
    <div class="row">
      <p>Редактирование камеры</p>
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit.cameraGroupsForDelete"
        :disabled="!useSwitch.cameraGroupsForDelete"
        :multiple="true"
        :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
        caption="Группы камер для удаления"
        class="col-2"
        @input="changePromise()"
      />
      <SmartSwitch v-model="useSwitch.cameraGroupsForDelete" caption="Изменить?" class="col-2" @input="changePromise()" />
    </div>
    <div class="row">
      <SmartVSelect
        v-model="formEdit.cameraGroupsForAdd"
        :disabled="!useSwitch.cameraGroupsForAdd"
        :multiple="true"
        :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
        caption="Группы камер для добавления"
        class="col-2"
        @input="changePromise()"
      />
      <SmartSwitch v-model="useSwitch.cameraGroupsForAdd" caption="Изменить?" class="col-2" @input="changePromise()" />
    </div>
    <div class="row">
      <SmartVSelect
        v-model="formEdit.cameraGroupsForSet"
        :disabled="!useSwitch.cameraGroupsForSet"
        :multiple="true"
        :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
        caption="Группы камер для установки"
        class="col-2"
        @input="changePromise()"
      />
      <SmartSwitch v-model="useSwitch.cameraGroupsForSet" caption="Изменить?" class="col-2" @input="changePromise()" />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.tariff_id]"
        :caption="titlesFields[fieldsEntity.tariff_id]"
        :disabled="!useSwitch[fieldsEntity.tariff_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.tariff_id]"
        class="col-2"
        @input="changePromise()"
      />
      <SmartSwitch v-model="useSwitch[fieldsEntity.tariff_id]" caption="Изменить?" class="col-2" @input="changePromise()" />
    </div>
    <div class="row">
      <SmartInputAddress
        v-model="formEdit[fieldsEntity.address]"
        :caption="titlesFields[fieldsEntity.address]"
        :disabled="!useSwitch[fieldsEntity.address]"
        class="col-2"
        @extract-coordinates="[formEdit[fieldsEntity.latitude], formEdit[fieldsEntity.longitude]] = $event"
        @input="changePromise()"
      />
      <SmartSwitch v-model="useSwitch[fieldsEntity.address]" caption="Изменить?" class="col-2" @input="changePromise()" />
    </div>
    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_sounding]"
        :caption="titlesFields[fieldsEntity.is_sounding]"
        :disabled="!useSwitch[fieldsEntity.is_sounding]"
        class="col-2"
        @input="changePromise()"
      />
      <SmartSwitch v-model="useSwitch[fieldsEntity.is_sounding]" caption="Изменить?" class="col-2" @input="changePromise()" />
    </div>
  </div>
</template>

<script>
import {ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT, FIELDS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {ACTION_SET_CAMERA_GROUPS} from "@/store/cameras/index.js";
import {
  ACTION_ADD_CAMERA_GROUPS,
  ACTION_DELETE_CAMERA_GROUPS,
  ACTION_EDIT_CAMERA,
  FIELDS_CAMERA,
  TITLES_FIELDS_CAMERA
} from "@/store/cameras/index.js";
import {ACTION_LOAD_TARIFFS_FOR_SELECT, FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";
import {functionMultiEditMixin} from "@/utils/mixins.js";

/**
 * Компонент функции мультиредактора камер для изменения звука.
 */
export default {
  mixins: [functionMultiEditMixin],
  data() {
    return {
      fieldsEntity: FIELDS_CAMERA,
      titlesFields: TITLES_FIELDS_CAMERA,
      formEdit: {
        [FIELDS_CAMERA.is_sounding]: false,
        [FIELDS_CAMERA.tariff_id]: null,
        [FIELDS_CAMERA.address]: null,
        [FIELDS_CAMERA.longitude]: null,
        [FIELDS_CAMERA.latitude]: null,
        cameraGroupsForAdd: [],
        cameraGroupsForDelete: [],
        cameraGroupsForSet: [],
      },
      // Переключатели дублируют перечень полей и используются для фильтрации редактируемых значений.
      useSwitch: {
        [FIELDS_CAMERA.is_sounding]: false,
        [FIELDS_CAMERA.tariff_id]: false,
        [FIELDS_CAMERA.address]: false,
        [FIELDS_CAMERA.longitude]: false,
        [FIELDS_CAMERA.latitude]: false,
        cameraGroupsForAdd: false,
        cameraGroupsForDelete: false,
        cameraGroupsForSet: false,
      },
      settingsSelects: {
        [FIELDS_CAMERA.camera_group_ids]: {
          action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP.id,
          labelField: FIELDS_CAMERA_GROUP.name
        },
        [FIELDS_CAMERA.tariff_id]: {
          action: `tariffs/${ACTION_LOAD_TARIFFS_FOR_SELECT}`,
          valueField: FIELDS_TARIFF.id,
          labelField: FIELDS_TARIFF.name
        },
      },
    };
  },
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     */
    changePromise() {
      // В редакцию попадут элементы для которых был включен переключатель редактирования.
      const filteredFormEdit = _.pickBy(this.formEdit, (value, key) => this.useSwitch[key]),
            filteredFormEditCamera = _.pick(filteredFormEdit, Object.values(FIELDS_CAMERA));

      this.$emit("change-promise", async (entityKey) => {
        try {
          if (!_.isEmpty(filteredFormEditCamera)) {
            await this.$store.dispatch(`cameras/${ACTION_EDIT_CAMERA}`, {
              [FIELDS_CAMERA.number]: entityKey,
              ..._.pick(filteredFormEdit, Object.values(FIELDS_CAMERA))
            });
          }
          if (!_.isEmpty(filteredFormEdit.cameraGroupsForDelete)) {
            await this.$store.dispatch(`cameras/${ACTION_DELETE_CAMERA_GROUPS}`, {
              cameraNumber: entityKey,
              cameraGroupIds: filteredFormEdit.cameraGroupsForDelete,
            });
          }
          if (!_.isEmpty(filteredFormEdit.cameraGroupsForAdd)) {
            await this.$store.dispatch(`cameras/${ACTION_ADD_CAMERA_GROUPS}`, {
              cameraNumber: entityKey,
              cameraGroupIds: filteredFormEdit.cameraGroupsForAdd,
            });
          }
          if (!_.isEmpty(filteredFormEdit.cameraGroupsForSet)) {
            await this.$store.dispatch(`cameras/${ACTION_SET_CAMERA_GROUPS}`, {
              cameraNumber: entityKey,
              cameraGroupIds: filteredFormEdit.cameraGroupsForSet,
            });
          }

          return ResultProcessingEntityMultiEdit.success(entityKey);
        } catch (error) {
          return ResultProcessingEntityMultiEdit.errorRequest(entityKey, error);
        }
      });
    },
  },
};
</script>
