/**
 * Отдельный vuex модуль для взаимодействия по device_access_groups-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для device_access_group. Вызов действия начинать с "deviceAccessGroups/"
export const ACTION_LOAD_INFO_DEVICE_ACCESS_GROUPS = "LOAD_INFO_DEVICE_ACCESS_GROUPS";
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS = "LOAD_DEVICE_ACCESS_GROUPS";
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE = "LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE";
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT = "LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT";
export const ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT = "LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT";
export const ACTION_CREATE_DEVICE_ACCESS_GROUP = "CREATE_DEVICE_ACCESS_GROUP";
export const ACTION_EDIT_DEVICE_ACCESS_GROUP = "EDIT_DEVICE_ACCESS_GROUP";
export const ACTION_DELETE_DEVICE_ACCESS_GROUP = "DELETE_DEVICE_ACCESS_GROUP";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_DEVICE_ACCESS_GROUP = Object.freeze({
  id: "id",
  title: "title",
  gang_id: "gang_id",
  about: "about",
  employee_count: "employee_count",
  employee_ids: "employee_ids",
  is_deleted: "is_deleted",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_DEVICE_ACCESS_GROUP = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  employee_id: "employee_id",
  gang_id: "gang_id"
});

/**
 * Стандартные названия для полей групп доступа к устройствам.
 */
export const TITLES_FIELDS_DEVICE_ACCESS_GROUP = {
  [FIELDS_DEVICE_ACCESS_GROUP.id]: "ID",
  [FIELDS_DEVICE_ACCESS_GROUP.title]: "Название",
  [FIELDS_DEVICE_ACCESS_GROUP.gang_id]: "Компания",
  [FIELDS_DEVICE_ACCESS_GROUP.about]: "Доп. описание",
  [FIELDS_DEVICE_ACCESS_GROUP.employee_count]: "Количество сотрудников",
  [FIELDS_DEVICE_ACCESS_GROUP.employee_ids]: "Сотрудники группы",
  [FIELDS_DEVICE_ACCESS_GROUP.is_deleted]: "Удален",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_FIELDS_DEVICE_ACCESS_GROUP = {
  [FIELDS_DEVICE_ACCESS_GROUP.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_DEVICE_ACCESS_GROUP.title]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_DEVICE_ACCESS_GROUP.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_DEVICE_ACCESS_GROUP.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_DEVICE_ACCESS_GROUP.employee_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_DEVICE_ACCESS_GROUP.gang_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с группами доступа к устройствам.
 */
export const EXTRAS_DEVICE_ACCESS_GROUP = Object.freeze({
  employee: "employee",
  gang: "gang",
});

const EXTRAS_BY_FIELDS_DEVICE_ACCESS_GROUP = Object.freeze({
  [FIELDS_DEVICE_ACCESS_GROUP.employee_ids]: [EXTRAS_DEVICE_ACCESS_GROUP.employee],
  [FIELDS_DEVICE_ACCESS_GROUP.gang_id]: [EXTRAS_DEVICE_ACCESS_GROUP.gang],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для групп доступа к устройствам.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_DEVICE_ACCESS_GROUPS]() {
      const response = await this.getters.privateAjax.post("/v0/device_access_groups/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка групп доступа к устройствам.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_DEVICE_ACCESS_GROUP, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/device_access_groups/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка групп доступа к устройствам для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE});
    },
    /**
     * Загрузка списка групп доступа к устройствам для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных групп доступа к устройствам.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @param {Number} gangId
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search, gangId = null}) {
      const defaultFilterForSelect = gangId
          ? [
            makeFilterApi(FILTERS_DEVICE_ACCESS_GROUP.is_deleted, "=", false),
            makeFilterApi(FILTERS_DEVICE_ACCESS_GROUP.gang_id, "=", gangId)
          ]
          : [makeFilterApi(FILTERS_DEVICE_ACCESS_GROUP.is_deleted, "=", false)],
        responseData = await dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS, {
          pageSize,
          fields,
          filters: defaultFilterForSelect,
          search,
          cancelTokenKey: ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одной группы доступа к устройствам для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceAccessGroupId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT]({dispatch}, [deviceAccessGroupId, fields]) {
      const filter = makeFilterApi(FILTERS_DEVICE_ACCESS_GROUP.id, "=", deviceAccessGroupId),
        responseData = await dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание группы доступа к устройствам.
     *
     * @param {Object} context
     * @param {Object} deviceAccessGroupInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_DEVICE_ACCESS_GROUP](context, deviceAccessGroupInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/device_access_groups/create/", deviceAccessGroupInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование групп доступа к устройствам.
     *
     * @param {Object} context
     * @param {Object} deviceAccessGroupInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_DEVICE_ACCESS_GROUP](context, deviceAccessGroupInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/device_access_groups/edit/", deviceAccessGroupInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление группы доступа к устройствам.
     *
     * @param {Object} context
     * @param {Array} deviceAccessGroupIds
     * @return {Promise}
     */
    async [ACTION_DELETE_DEVICE_ACCESS_GROUP](context, {deviceAccessGroupIds}) {
      return this.getters.privateAjax.post("/v0/device_access_groups/delete/", {ids: deviceAccessGroupIds});
    },
  },
};
