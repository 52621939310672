<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Доменные настройки
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateDomainSetting"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
    />
  </div>
</template>

<script>
import {ROUTE_DOMAIN_SETTINGS, ROUTE_EDIT_DOMAIN_SETTING} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {
  ACTION_LOAD_DOMAIN_SETTINGS_FOR_TABLE,
  ACTION_LOAD_INFO_DOMAIN_SETTINGS,
  FIELDS_DOMAIN_SETTING,
  FILTERS_DOMAIN_SETTING,
  TITLES_FIELDS_DOMAIN_SETTING,
  TYPES_FIELDS_DOMAIN_SETTING
} from "@/store/domainSettings/index.js";
import CreateDomainSettingDialog from "@/components/domainSettings/CreateDomainSettingDialog.vue";
import {FilterData} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по доменным настройкам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      currentRoute: ROUTE_DOMAIN_SETTINGS,
      rawFields: FIELDS_DOMAIN_SETTING,
      columnCaptions: TITLES_FIELDS_DOMAIN_SETTING,
      nameActionLoadDataForTable: `domainSettings/${ACTION_LOAD_DOMAIN_SETTINGS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `domainSettings/${ACTION_LOAD_INFO_DOMAIN_SETTINGS}`,
      defaultFieldsTableForView: [
        FIELDS_DOMAIN_SETTING.id,
        FIELDS_DOMAIN_SETTING.domain,
        FIELDS_DOMAIN_SETTING.title,
        FIELDS_DOMAIN_SETTING.is_deleted,
      ],
      defaultFilters: [
        new FilterData(FILTERS_DOMAIN_SETTING.is_deleted, "=", [false])
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_DOMAIN_SETTING.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_DOMAIN_SETTING, params: {domainSettingId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_DOMAIN_SETTING.domain:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_DOMAIN_SETTING, params: {domainSettingId: rowData[FIELDS_DOMAIN_SETTING.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_DOMAIN_SETTING[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания настроек для домена.
     */
    openDialogCreateDomainSetting() {
      this.$camsdals.open(
        CreateDomainSettingDialog,
        {},
        {dialogTitle: "Создание настроек для домена"},
        {},
      );
    },
  },
};
</script>
