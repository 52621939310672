<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: routes.ROUTE_DEVICES }" class="tabs__item">
          Устройства доступа
        </router-link>
        <router-link :to="{name: routes.ROUTE_EMPLOYEES}" class="tabs__item">
          Сотрудники
        </router-link>
        <router-link :to="{ name: routes.ROUTE_DEVICE_ACCESS_GROUPS }" class="tabs__item">
          Группы доступа
        </router-link>
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Автомобили
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateCar"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_CARS_FOR_TABLE,
  ACTION_LOAD_INFO_CARS,
  EXTRAS_CAR,
  FIELDS_CAR,
  FILTERS_CAR,
  TITLES_FIELDS_CAR,
  TYPES_FIELDS_CAR,
} from "@/store/pacs/cars/index.js";
import {
  ROUTE_CARS,
  ROUTE_DEVICE_ACCESS_GROUPS,
  ROUTE_DEVICES,
  ROUTE_EDIT_CAR,
  ROUTE_EDIT_EMPLOYEE,
  ROUTE_EDIT_GANG,
  ROUTE_EMPLOYEES
} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {EXTRAS_EMPLOYEE, FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {FIELDS_GANG} from "@/store/gangs/index.js";
import {FilterData} from "@/utils/helpers.js";
import CreateCarDialog from "@/components/pacs/cars/CreateCarDialog.vue";

/**
 * Компонент отображения таблицы и фильтров с информацией по автомобилям.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_EMPLOYEES,
        ROUTE_DEVICES,
        ROUTE_DEVICE_ACCESS_GROUPS,
      },
      currentRoute: ROUTE_CARS,
      rawFields: FIELDS_CAR,
      columnCaptions: TITLES_FIELDS_CAR,
      nameActionLoadDataForTable: `cars/${ACTION_LOAD_CARS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `cars/${ACTION_LOAD_INFO_CARS}`,
      defaultFieldsTableForView: [
        FIELDS_CAR.id,
        FIELDS_CAR.number,
        FIELDS_CAR.employee_id,
        FIELDS_CAR.gang_id,
        FIELDS_CAR.is_deleted,
      ],
      defaultFilters: [
        new FilterData(FILTERS_CAR.is_deleted, "=", [false])
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CAR.employee]) {
        storedExtraInfo.employees = {};
        sourceExtraInfo[EXTRAS_CAR.employee].forEach((employeeInfo) => {
          storedExtraInfo.employees[employeeInfo[FIELDS_EMPLOYEE.id]] = {
            id: employeeInfo[FIELDS_EMPLOYEE.id],
            name: employeeInfo[FIELDS_EMPLOYEE.title],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_EMPLOYEE.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_EMPLOYEE.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            gang: gangInfo[FIELDS_GANG.name],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_CAR.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAR, params: {carId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAR.number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAR, params: {carId: rowData[FIELDS_CAR.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CAR.employee_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.employees[rawTranslation], "name", "-");
          readyParams = {name: ROUTE_EDIT_EMPLOYEE, params: {employeeId: rawTranslation}};
        }
        break;
      case FIELDS_EMPLOYEE.gang_id:
        if (rawTranslation) {
          readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "gang", "-");
          readyParams = {name: ROUTE_EDIT_GANG, params: {gangId: rawTranslation}};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CAR[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания автомобиля.
     */
    openDialogCreateCar() {
      this.$camsdals.open(CreateCarDialog, {}, {dialogTitle: "Создание автомобиля"});
    },
  },
};
</script>
