<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Сервер
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <router-link
              v-if="routeServerHistory"
              :to="routeServerHistory"
            >
              <a class="tabs__item">История изменений</a>
            </router-link>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <SmartSelect
                  v-model="formEdit[fieldsEntity.vendor_name]"
                  :caption="titlesFields[fieldsEntity.vendor_name]"
                  :error="errorsFormEdit[fieldsEntity.vendor_name]"
                  :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.domain)"
                  :options="vendorName"
                  width="fill"
                  class="col"
                />
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.domain)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.domain]"
                    :caption="titlesFields[fieldsEntity.domain]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.domain)"
                    :error="errorsFormEdit[fieldsEntity.domain]"
                    :make-focus="true"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.description)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.description]"
                    :caption="titlesFields[fieldsEntity.description]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.description)"
                    :error="errorsFormEdit[fieldsEntity.description]"
                    input-type="area"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.max_cameras)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.max_cameras]"
                    :caption="titlesFields[fieldsEntity.max_cameras]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.max_cameras)"
                    :error="errorsFormEdit[fieldsEntity.max_cameras]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.screenshot_domain)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.screenshot_domain]"
                    :caption="titlesFields[fieldsEntity.screenshot_domain]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.screenshot_domain)"
                    :error="errorsFormEdit[fieldsEntity.screenshot_domain]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.proxy_domain)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.proxy_domain]"
                    :caption="titlesFields[fieldsEntity.proxy_domain]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.proxy_domain)"
                    :error="errorsFormEdit[fieldsEntity.proxy_domain]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.storage)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.storage]"
                    :caption="titlesFields[fieldsEntity.storage]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.storage)"
                    :error="errorsFormEdit[fieldsEntity.storage]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.disk_limit)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.disk_limit]"
                    :caption="titlesFields[fieldsEntity.disk_limit]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.disk_limit)"
                    :error="errorsFormEdit[fieldsEntity.disk_limit]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.is_auto_allocation)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_auto_allocation]"
                    :caption="titlesFields[fieldsEntity.is_auto_allocation]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.is_auto_allocation)"
                    :error="errorsFormEdit[fieldsEntity.is_auto_allocation]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.is_managed)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_managed]"
                    :caption="titlesFields[fieldsEntity.is_managed]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.is_managed)"
                    :error="errorsFormEdit[fieldsEntity.is_managed]"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.is_multistorage_enabled)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_multistorage_enabled]"
                    :caption="titlesFields[fieldsEntity.is_multistorage_enabled]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.is_multistorage_enabled)"
                    :error="errorsFormEdit[fieldsEntity.is_multistorage_enabled]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.cluster_ids)" class="row">
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.cluster_ids]"
                    :caption="titlesFields[fieldsEntity.cluster_ids]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.cluster_ids)"
                    :error="errorsFormEdit[fieldsEntity.cluster_ids]"
                    :initial-options="initialOptionsClusters"
                    :multiple="true"
                    :settings-remote-search="settingsSelects[fieldsEntity.cluster_ids]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.tariff_ids)" class="row">
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.tariff_ids]"
                    :caption="titlesFields[fieldsEntity.tariff_ids]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.tariff_ids)"
                    :error="errorsFormEdit[fieldsEntity.tariff_ids]"
                    :initial-options="initialOptionsTariffs"
                    :multiple="true"
                    :settings-remote-search="settingsSelects[fieldsEntity.tariff_ids]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.api_allowed_from)" class="row">
                  <SmartInputArray
                    v-model="formEdit[fieldsEntity.api_allowed_from]"
                    :caption="titlesFields[fieldsEntity.api_allowed_from]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.api_allowed_from)"
                    :error="errorsFormEdit[fieldsEntity.api_allowed_from]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.cluster_key)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.cluster_key]"
                    :caption="titlesFields[fieldsEntity.cluster_key]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.cluster_key)"
                    :error="errorsFormEdit[fieldsEntity.cluster_key]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.migrate_limit)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.migrate_limit]"
                    :caption="titlesFields[fieldsEntity.migrate_limit]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.migrate_limit)"
                    :error="errorsFormEdit[fieldsEntity.migrate_limit]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.login)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.login]"
                    :caption="titlesFields[fieldsEntity.login]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.login)"
                    :error="errorsFormEdit[fieldsEntity.login]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.password)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.password]"
                    :caption="titlesFields[fieldsEntity.password]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.password)"
                    :error="errorsFormEdit[fieldsEntity.password]"
                    class="col"
                  />
                </div>


                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.SERVER)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.SERVER)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.SERVER)"
                        type="button"
                        @click="deleteServer()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.SERVER)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.sync_success)">
                  {{ titlesFields[fieldsEntity.sync_success] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_success] | bool }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.sync_error_stage)">
                  {{ titlesFields[fieldsEntity.sync_error_stage] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_error_stage] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.sync_error)">
                  {{ titlesFields[fieldsEntity.sync_error] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_error] }}</strong>
                </p>
                <p
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.sync_last_success)"
                  :title="sourceData.entityInfo[fieldsEntity.sync_last_success] | localDateTimeZone"
                >
                  {{ titlesFields[fieldsEntity.sync_last_success] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.sync_last_success] | localDateTime }}</strong>
                </p>

                <br>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.stats_version)">
                  {{ titlesFields[fieldsEntity.stats_version] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.stats_version] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.stats_total_clients)">
                  {{ titlesFields[fieldsEntity.stats_total_clients] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.stats_total_clients] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.stats_memory_usage)">
                  {{ titlesFields[fieldsEntity.stats_memory_usage] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.stats_memory_usage] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.stats_cpu_usage)">
                  {{ titlesFields[fieldsEntity.stats_cpu_usage] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.stats_cpu_usage] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.stats_output_kbit)">
                  {{ titlesFields[fieldsEntity.stats_output_kbit] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.stats_output_kbit] }}</strong>
                </p>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.stats_input_kbit)">
                  {{ titlesFields[fieldsEntity.stats_input_kbit] }}:
                  <strong>{{ sourceData.entityInfo[fieldsEntity.stats_input_kbit] }}</strong>
                </p>

                <br>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.cameras_count)">
                  <strong>
                    <router-link :to="routeCamerasInServer">
                      Посмотреть все камеры в этом сервере ({{ sourceData.entityInfo[fieldsEntity.cameras_count] }})
                    </router-link>
                  </strong>
                </p>

                <br>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.tariff_count)">
                  <strong>
                    <router-link :to="routeTariffsForServer">
                      Посмотреть все тарифы, разрешённые на сервере при автоматическом распределении
                      ({{ sourceData.entityInfo[fieldsEntity.tariff_count] }})
                    </router-link>
                  </strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ROUTE_CAMERAS,
  ROUTE_SERVERS,
  ROUTE_TARIFFS,
  ROUTE_VIEW_SERVER_HISTORY
} from "@/router/names.js";
import {FILTERS_CAMERA} from "@/store/cameras/index.js";
import {ACTION_LOAD_CLUSTERS_FOR_SELECT, FIELDS_CLUSTER} from "@/store/heavyMetal/clusters/index.js";
import {
  ACTION_DELETE_SERVERS,
  ACTION_EDIT_SERVER_WITH_ALL,
  ACTION_LOAD_SERVER_FOR_EDIT,
  EXTRAS_SERVER,
  FIELDS_SERVER,
  TITLES_FIELDS_SERVER, VENDOR_NAME
} from "@/store/heavyMetal/servers/index.js";
import {ACTION_LOAD_TARIFFS_FOR_SELECT, FIELDS_TARIFF, FILTERS_TARIFF} from "@/store/tariffs/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {editDataEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент страницы редактирования сервера.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const serverId = Number(this.$route.params.serverId),
          paramsRouteCamerasInServer = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA.server_id, "=", [serverId])]}),
          routeCamerasInServer = {name: ROUTE_CAMERAS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCamerasInServer.stringify()}},
          paramsRouteTariffsForServer = new TableQueryParams({filters: [new FilterData(FILTERS_TARIFF.server_id, "=", [serverId])]}),
          routeTariffsForServer = {name: ROUTE_TARIFFS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteTariffsForServer.stringify()}};

    return {
      routeBack: {name: ROUTE_SERVERS},
      fieldsEntity: FIELDS_SERVER,
      titlesFields: TITLES_FIELDS_SERVER,
      vendorName: VENDOR_NAME,
      entityId: serverId,
      nameActionLoadDataForEdit: `servers/${ACTION_LOAD_SERVER_FOR_EDIT}`,
      nameActionEdit: `servers/${ACTION_EDIT_SERVER_WITH_ALL}`,
      deletedField: FIELDS_SERVER.is_deleted,
      routeCamerasInServer: routeCamerasInServer,
      routeTariffsForServer: routeTariffsForServer,
      initialOptionsClusters: [],
      initialOptionsTariffs: [],
      routeServerHistory: null,
      settingsSelects: {
        [FIELDS_SERVER.cluster_ids]: {
          action: `clusters/${ACTION_LOAD_CLUSTERS_FOR_SELECT}`,
          valueField: FIELDS_CLUSTER.id,
          labelField: FIELDS_CLUSTER.name
        },
        [FIELDS_SERVER.tariff_ids]: {
          action: `tariffs/${ACTION_LOAD_TARIFFS_FOR_SELECT}`,
          valueField: FIELDS_TARIFF.id,
          labelField: FIELDS_TARIFF.name
        },
      }
    };
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      this.initialOptionsClusters = this.sourceData.extraInfo[EXTRAS_SERVER.cluster];
      this.initialOptionsTariffs = this.sourceData.extraInfo[EXTRAS_SERVER.tariff];
      const paramsRouteServerHistory = new TableQueryParams({
        filters: [
          new FilterData(FIELDS_SERVER.id,  "=", [this.entityId]),
        ]});
      this.routeServerHistory = {
        name: ROUTE_VIEW_SERVER_HISTORY,
        query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteServerHistory.stringify()},
        params: {serverId: this.entityId}};
    },
    /**
     * Коррекции подвергаются следующие поля:
     *
     * - tariff_ids - особые указания в зависимости от прав.
     * - cluster_key - нужно привести к null при отсутствии значений.
     *
     * @link editDataEntityMixin.methods.getDataForSave
     */
    getDataForSave() {
      const serverInfo = Object.assign({}, this.formEdit);
      if (this.$can(this.$abilitiesActions.UPDATE_FIELD, this.$abilitiesSubjects.SERVER, FIELDS_SERVER.tariff_ids)) {
        const newSetTariffs = serverInfo[FIELDS_SERVER.tariff_ids],
              tariffsForDelete = _.difference(this.sourceData.entityInfo[FIELDS_SERVER.tariff_ids], newSetTariffs),
              tariffsForAdd = _.difference(newSetTariffs, this.sourceData.entityInfo[FIELDS_SERVER.tariff_ids]);
        serverInfo[FIELDS_SERVER.tariff_ids] = [tariffsForDelete, tariffsForAdd];
        serverInfo[FIELDS_SERVER.description] =  serverInfo[FIELDS_SERVER.description] ?  serverInfo[FIELDS_SERVER.description] : "";
      }

      return serverInfo;
    },
    /**
     * Отправка запроса на удаление текущего сервера.
     */
    deleteServer() {
      this.$camsdals.confirm("Хотите удалить этот сервер?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`servers/${ACTION_DELETE_SERVERS}`, {serverIds: [this.entityId]});
          this.$camsdals.alert("Сервер удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении сервера");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
