<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Устройство доступа
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <router-link
              v-if="routeDeviceHistory"
              :to="routeDeviceHistory"
            >
              <a class="tabs__item">История изменений</a>
            </router-link>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.title)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.title]"
                    :caption="titlesFields[fieldsEntity.title]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.title)"
                    :error="errorsFormEdit[fieldsEntity.title]"
                    :make-focus="true"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.model)" class="row">
                  <SmartSelect
                    v-model="formEdit[fieldsEntity.model]"
                    :options="settingsModel"
                    :error="errorsFormEdit[fieldsEntity.model]"
                    caption="Модель устройства"
                    class="col"
                    width="fill"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.ident)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.ident]"
                    :caption="titlesFields[fieldsEntity.ident]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.ident)"
                    :error="errorsFormEdit[fieldsEntity.ident]"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.gang_id)" class="row">
                  <SmartVSelect
                    v-model="formEdit[fieldsEntity.gang_id]"
                    :caption="titlesFields[fieldsEntity.gang_id]"
                    :error="errorsFormEdit[fieldsEntity.gang_id]"
                    :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
                    :disabled="!$can($abilitiesActions.UPDATE_DEVICE_GANG, $abilitiesSubjects.DEVICE)"
                    :initial-options="initialOptionsGangs"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.mac)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.mac]"
                    :caption="titlesFields[fieldsEntity.mac]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.mac)"
                    :error="errorsFormEdit[fieldsEntity.mac]"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.hw_version)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.hw_version]"
                    :caption="titlesFields[fieldsEntity.hw_version]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.hw_version)"
                    :error="errorsFormEdit[fieldsEntity.hw_version]"
                    class="col"
                  />
                </div>
                <VDropdown :auto-hide="true" :skidding="390" :distance="-35">
                  <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.ban_period)" class="row">
                    <SmartInputText
                      v-model="formEdit[fieldsEntity.ban_period]"
                      :caption="titlesFields[fieldsEntity.ban_period]"
                      :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.ban_period)"
                      :error="errorsFormEdit[fieldsEntity.ban_period]"
                      class="col"
                    />
                  </div>

                  <template #popper>
                    Данный параметр означает время в секундах,
                    в которых команды на открытие боксера будут игнорироваться
                    (например для ворот, у которых данный параметр >3 сек)
                  </template>
                </VDropdown>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.config)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.config]"
                    :caption="titlesFields[fieldsEntity.config]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.config)"
                    :error="errorsFormEdit[fieldsEntity.config]"
                    class="col"
                    input-type="area"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.emergency_vehicle_access)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.emergency_vehicle_access]"
                    :caption="titlesFields[fieldsEntity.emergency_vehicle_access]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.emergency_vehicle_access)"
                    :error="errorsFormEdit[fieldsEntity.emergency_vehicle_access]"
                    class="col"
                  />
                </div>
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.is_active)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_active]"
                    :caption="titlesFields[fieldsEntity.is_active]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.DEVICE, fieldsEntity.is_active)"
                    :error="errorsFormEdit[fieldsEntity.is_active]"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.DEVICE)"
                        type="button"
                        @click="deleteDevice()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.DEVICE)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_PERMISSION)"
                        type="button"
                        @click="openDialogSetPermissionsForEmployees()"
                      >
                        Изменить права для сотрудников
                      </CamsButton>
                    </div>
                  </div>
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)"
                        type="button"
                        @click="openDialogSetPermissionsForDeviceAccessGroups()"
                      >
                        Изменить права для групп доступа
                      </CamsButton>
                    </div>
                  </div>
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)"
                        v-show="formEdit[fieldsEntity.model] !== 'boxer-modules'"
                        type="button"
                        @click="openAddCameraToDeviceDialog()"
                      >
                        Управление камерами устройства
                      </CamsButton>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="routeGang">
                  <strong>
                    <router-link :to="routeGang">Посмотреть информацию по компании</router-link>
                  </strong>
                </p>

                <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_PERMISSION)">
                  Количество записей прав для сотрудников:
                  <strong>{{ countPermissionsByEmployees }}</strong>
                </p>

                <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)">
                  Количество записей прав для групп доступа:
                  <strong>{{ countPermissionsByAccessGroups }}</strong>
                </p>
                <p
                  v-show="formEdit[fieldsEntity.model] === 'boxer-modules'"
                >
                  Доступные модули устройства :
                </p>

                <strong> <p
                  v-show="formEdit[fieldsEntity.model] === 'boxer-modules' && extraModules.length < 1"
                  style="margin-top: 15px"
                >
                  Нет доступных модулей.
                </p>
                </strong>

                <CamsButton
                  v-for="extraModules in extraModules"
                  :key="extraModules.module_number"
                  style="margin-top: 15px; display: flex;flex-direction: column"
                  type="button"
                  @click="openEditModuleDialog(extraModules.id, extraModules.module_number)"
                >
                  {{ "Модуль " + extraModules.module_number }}
                </CamsButton>
                <p>
                  <CamsButton
                    v-show="formEdit[fieldsEntity.model] === 'boxer-modules'"
                    priority="primary"
                    style="margin-top: 15px"
                    type="button"
                    @click="syncModules()"
                  >
                    Перезаписать информацию по модулям
                  </CamsButton>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>
    <notifications classes="customized-notification-style" group="main" position="top right" />

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>

import VueSSE from 'vue-sse';
import {
  ROUTE_DEVICES,
  ROUTE_EDIT_GANG,
  ROUTE_VIEW_DEVICE_HISTORY,
} from "@/router/names.js";
import {
  ACTION_DELETE_DEVICE,
  ACTION_EDIT_DEVICE,
  ACTION_LOAD_DEVICE_FOR_EDIT, ACTION_SYNC_MODULES,
  DEVICE_MODELS,
  TITLES_FIELDS_DEVICE, EXTRAS_DEVICE, FIELDS_DEVICE
} from "@/store/pacs/devices/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";
import {FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import SetPermissionsForEmployeesDialog from "@/components/pacs/pacsPermissons/SetPermissionsForEmployeesDialog.vue";
import SetPermissionsForDeviceAccessGroupsDialog from "@/components/pacs/pacsPermissons/SetPermissionsForDeviceAccessGroupsDialog.vue";
import {
  ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP,
  ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE
} from "@/store/pacs/pacsPermissions/index.js";
import AddCameraToDeviceDialog from "@/components/pacs/devices/AddCameraToDeviceDialog.vue";
import EditModule from "@/components/pacs/devices/EditModule.vue";
import Vue from "vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
let sseClient;
/**
 * Компонент страницы редактирования устройства доступа.
 */
Vue.use(VueSSE, {
});
export default {
  mixins: [editDataEntityMixin],
  data() {
    const deviceId = Number(this.$route.params.deviceId);
    return {
      routeBack: {name: ROUTE_DEVICES},
      fieldsEntity: FIELDS_DEVICE,
      titlesFields: TITLES_FIELDS_DEVICE,
      moduleNumber: "",
      entityId: deviceId,
      nameActionLoadDataForEdit: `devices/${ACTION_LOAD_DEVICE_FOR_EDIT}`,
      nameActionEdit: `devices/${ACTION_EDIT_DEVICE}`,
      deletedField: FIELDS_DEVICE.is_deleted,
      settingsModel: DEVICE_MODELS,
      handleMessage: {},
      initialOptionsGangs: [],
      settingsSelects: {
        [FIELDS_DEVICE.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      },
      countPermissionsByEmployees: 0,
      countPermissionsByAccessGroups: 0,
      routeGang: null,
      routeDeviceHistory: null,
    };
  },
  sse: {
    cleanup: true,
  },
  mounted() {
    sseClient = this.$sse.create({
      format: 'json',
      polyfill: true,
      url:`/admin/api/v0/push/devices/${this.entityId}`,
      withCredentials: true,
    });
    sseClient.on('error', (e) => {
      console.error('lost connection or failed to parse!', e);

    });
    sseClient.on('message', () => this.infoReload());
    this.sse = this.$sse.create(  {format: 'json',
                                   polyfill: true,
                                   url:`/admin/api/v0/push/devices/${this.entityId}`,
                                   withCredentials: true})
      .connect()
      .catch((err) => console.error('Failed make initial connection:', err));
  },
  methods: {
    async infoReload(){
      try{
        await this.loadSourceData();
      } catch {
        //
      }
      this.$notify({
        group: "main",
        text: "Получена новая конфигурация по модулям",
        duration: 5000,
        type: "success"
      });
    },
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // todo Правка config т.к. оно - object, пока нет редактора json
      this.formEdit[FIELDS_DEVICE.config] = this.formEdit[FIELDS_DEVICE.config] ? JSON.stringify(this.formEdit[FIELDS_DEVICE.config]) : "";
      this.initialOptionsGangs = this.sourceData.extraInfo[EXTRAS_DEVICE.gang];
      this.modules = this.sourceData.entityInfo.module_ids ;
      this.extraModules = this.sourceData.extraInfo.module ;
      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.GANG) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.EMPLOYEE, FIELDS_EMPLOYEE.gang_id)) {
        const gangId = this.sourceData.entityInfo[FIELDS_EMPLOYEE.gang_id];
        this.routeGang = gangId ? {name: ROUTE_EDIT_GANG, params: {gangId}} : null;
        const paramsRouteDeviceHistory = new TableQueryParams({
          filters: [
            new FilterData(FIELDS_DEVICE.id,  "=", [this.entityId]),
          ]});  this.routeDeviceHistory = {
          name: ROUTE_VIEW_DEVICE_HISTORY,
          query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteDeviceHistory.stringify()},
          params: {deviceId: this.entityId}};
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.DEVICE_PERMISSION)) {
        this.countPermissionsByEmployees = await this.$store.dispatch(`pacsPermissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_EMPLOYEE}`, {deviceId: this.entityId});
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.DEVICE_ACCESS_GROUP_PERMISSION)) {
        this.countPermissionsByAccessGroups = await this.$store.dispatch(`pacsPermissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_DEVICE_AND_DEVICES_ACCESS_GROUP}`, {deviceId: this.entityId});
      }


    },
    /**
     * Коррекции подвергаются следующие поля:
     *
     * - config - JSON
     *
     * @link editDataEntityMixin.methods.getDataForSave
     */
    getDataForSave() {

      const deviceInfo = Object.assign({}, this.formEdit);
      deviceInfo[FIELDS_DEVICE.config] = deviceInfo[FIELDS_DEVICE.config] ? JSON.parse(deviceInfo[FIELDS_DEVICE.config]) : {};
      return deviceInfo;
    },
    /**
     * Отправка запроса на удаление текущего устройства.
     */
    deleteDevice() {
      this.$camsdals.confirm("Хотите удалить это устройство доступа?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`devices/${ACTION_DELETE_DEVICE}`, {deviceIds: [this.entityId]});
          this.$camsdals.alert("Устройство доступа удалено");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении устройства доступа");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открытие формы для изменения прав сотрудника на устройство.
     */
    openDialogSetPermissionsForEmployees() {
      this.$camsdals.open(
        SetPermissionsForEmployeesDialog,
        {deviceId: this.entityId, gangId: this.sourceData.entityInfo[FIELDS_EMPLOYEE.gang_id]},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие формы для изменения прав групп доступа на устройство.
     */
    openDialogSetPermissionsForDeviceAccessGroups() {
      this.$camsdals.open(
        SetPermissionsForDeviceAccessGroupsDialog,
        {deviceId: this.entityId, gangId: this.sourceData.entityInfo[FIELDS_DEVICE.gang_id]},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие диалогового окна для добавления камеры к устройству.
     */
    openAddCameraToDeviceDialog() {
      this.$camsdals.open(
        AddCameraToDeviceDialog,
        {deviceId: this.entityId, gangId: this.sourceData.entityInfo[FIELDS_DEVICE.gang_id]},
        {dialogTitle: "Управление камерами"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие диалогового окна для добавления камеры к устройству.
     */
    openEditModuleDialog(moduleNumber, moduleTitle) {
      this.$camsdals.open(
        EditModule,
        {moduleNumber:moduleNumber, moduleInfo: this.sourceData.extraInfo[EXTRAS_DEVICE.module], deviceId:this.entityId},
        {dialogTitle: `Редактирование модуля ${moduleTitle}`},
        {name: "js-click-outside"}
      );
    },
    syncModules() {
      this.isLoading = true;
      try {
        this.$store.dispatch(`devices/${ACTION_SYNC_MODULES}`, this.entityId);

      } catch {
        //
      }
      this.loadSourceData();

    },
  },
};
</script>
<style lang="scss">
@import "EditDevice.scss";
</style>
