/**
 * Отдельный vuex модуль для взаимодействия по devices-API
 */

import {
  DEFAULT_PAGE_SIZE_FOR_SELECT,
  DEFAULT_PAGE_SIZE_FOR_TABLE,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";

// actions для device. Вызов действия начинать с "devices/"
export const ACTION_LOAD_INFO_DEVICES = "LOAD_INFO_DEVICES";
export const ACTION_LOAD_DEVICES = "LOAD_DEVICES";
export const ACTION_LOAD_MODULES = "LOAD_MODULES";
export const ACTION_SYNC_MODULES = "SYNC_MODULES";
export const ACTION_LOAD_DEVICES_FOR_TABLE = "LOAD_DEVICES_FOR_TABLE";
export const ACTION_LOAD_DEVICES_FOR_SELECT = "LOAD_DEVICES_FOR_SELECT";
export const ACTION_LOAD_DEVICE_FOR_EDIT = "LOAD_DEVICE_FOR_EDIT";
export const ACTION_LOAD_MODULE_FOR_EDIT = "LOAD_MODULE_FOR_EDIT";
export const ACTION_CREATE_DEVICE = "CREATE_DEVICE";
export const ACTION_EDIT_DEVICE = "EDIT_DEVICE";
export const ACTION_EDIT_MODULE = "EDIT_MODULE";
export const ACTION_DELETE_DEVICE = "DELETE_DEVICE";
export const ACTION_LOAD_INFO_DEVICES_TO_CAMERAS = "LOAD_INFO_DEVICES_TO_CAMERAS";
export const ACTION_LOAD_DEVICES_TO_CAMERAS = "LOAD_DEVICES_TO_CAMERAS";
export const ACTION_LOAD_DEVICES_TO_CAMERAS_FOR_EDIT = "LOAD_DEVICES_TO_CAMERAS_FOR_EDIT";
export const ACTION_ADD_DEVICES_TO_CAMERAS_BY_FORM = "ADD_DEVICES_TO_CAMERAS_BY_FORM";
export const ACTION_DELETE_DEVICES_TO_CAMERAS = "DELETE_DEVICES_TO_CAMERAS";
export const ACTION_LOAD_INFO_DEVICE_HISTORY = "LOAD_INFO_DEVICE_HISTORY";
export const ACTION_LOAD_DEVICE_HISTORY = "LOAD_DEVICE_HISTORY";
export const ACTION_LOAD_DEVICE_HISTORY_FOR_TABLE = "LOAD_DEVICE_HISTORY_FOR_TABLE";
export const ACTION_LOAD_INFO_DEVICE_MODULE_HISTORY = "LOAD_INFO_DEVICE_MODULE_HISTORY";
export const ACTION_LOAD_DEVICE_MODULE_HISTORY = "LOAD_DEVICE_MODULE_HISTORY";
export const ACTION_LOAD_DEVICE_MODULE_HISTORY_FOR_TABLE = "LOAD_DEVICE_MODULE_HISTORY_FOR_TABLE";
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_DEVICE = Object.freeze({
  id: "id",
  model: "model",
  ident: "ident",
  title: "title",
  gang_id: "gang_id",
  mac: "mac",
  hw_version: "hw_version",
  config: "config",
  camera_count: "camera_count",
  camera_numbers: "camera_numbers",
  emergency_vehicle_access: "emergency_vehicle_access",
  is_active: "is_active",
  is_deleted: "is_deleted",
  module_ids: "module_ids",
  ban_period: "ban_period",
});

export const FIELDS_DEVICE_TO_CAMERA = Object.freeze({
  device_id: "device_id",
  camera_direction: "camera_direction",
  camera_number: "camera_number"
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_DEVICE = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  gang_id: "gang_id"
});

export const FILTERS_DEVICE_TO_CAMERA = Object.freeze({
  device_id: "device_id",
  camera_number: "camera_number",
});

/**
 * Стандартные названия для полей устройств.
 */
export const TITLES_FIELDS_DEVICE = {
  [FIELDS_DEVICE.id]: "ID",
  [FIELDS_DEVICE.model]: "Модель",
  [FIELDS_DEVICE.ident]: "Идентификатор устройства",
  [FIELDS_DEVICE.title]: "Название",
  [FIELDS_DEVICE.gang_id]: "Компания",
  [FIELDS_DEVICE.mac]: "MAC адрес",
  [FIELDS_DEVICE.hw_version]: "Версия ПО",
  [FIELDS_DEVICE.config]: "Конфигурация",
  [FIELDS_DEVICE.camera_count]: "Количество камер",
  [FIELDS_DEVICE.camera_numbers]: "Камеры",
  [FIELDS_DEVICE.is_deleted]: "Удален",
  [FIELDS_DEVICE.emergency_vehicle_access]: "Доступ спецтранспорта",
  [FIELDS_DEVICE.is_active]: "Активен",
  [FIELDS_DEVICE.module_ids]: "Модули",
  [FIELDS_DEVICE.ban_period]: "Задержка повторного открывания устройства (сек)",
};
export const TITLES_FIELDS_DEVICE_TO_CAMERAS = {
  [FIELDS_DEVICE_TO_CAMERA.device_id]: "ID",
  [FIELDS_DEVICE_TO_CAMERA.camera_number]: "Номера камеры",
  [FIELDS_DEVICE_TO_CAMERA.camera_direction]: "Направление обзора"
};
/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_DEVICE = {
  [FIELDS_DEVICE.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_DEVICE.title]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_DEVICE.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_DEVICE.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_DEVICE.emergency_vehicle_access]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_DEVICE.camera_numbers]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_DEVICE.gang_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};
// Поля для истории по устройствам
export const FIELDS_DEVICE_HISTORY = Object.freeze({
  id: "id",
  model: "model",
  ident: "ident",
  title: "title",
  gang_id: "gang_id",
  mac: "mac",
  hw_version: "hw_version",
  config: "config",
  camera_numbers: "camera_numbers",
  emergency_vehicle_access: "emergency_vehicle_access",
  is_active: "is_active",
  is_deleted: "is_deleted",
  ban_period: "ban_period",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent",
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_DEVICE_HISTORY = Object.freeze({
  model: "model",
  is_deleted: "is_deleted",
  data_change_event_date: "data_change_event_date",
  data_change_event_user_id: "data_change_event_user_id",
});
export const TITLES_FIELDS_DEVICE_HISTORY = {
  [FIELDS_DEVICE_HISTORY.id]: "id",
  [FIELDS_DEVICE_HISTORY.model]:"Модель",
  [FIELDS_DEVICE_HISTORY.ident]: "Идентификатор устройства",
  [FIELDS_DEVICE_HISTORY.title]: "Название",
  [FIELDS_DEVICE_HISTORY.gang_id]: "Компания",
  [FIELDS_DEVICE_HISTORY.mac]: "MAC адрес",
  [FIELDS_DEVICE_HISTORY.hw_version]: "Версия ПО",
  [FIELDS_DEVICE_HISTORY.config]: "Конфигурация",
  [FIELDS_DEVICE_HISTORY.camera_numbers]: "Камеры",
  [FIELDS_DEVICE_HISTORY.emergency_vehicle_access]: "Доступ спецтранспорта",
  [FIELDS_DEVICE_HISTORY.is_active]:"Активен",
  [FIELDS_DEVICE_HISTORY.is_deleted]: "Удален",
  [FIELDS_DEVICE_HISTORY.ban_period]: "Задержка повторного открывания",
  [FIELDS_DEVICE_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_DEVICE_HISTORY.data_change_event_date]: "Дата события 2",
  [FIELDS_DEVICE_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_DEVICE_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_DEVICE_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_DEVICE_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_DEVICE_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_DEVICE_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_DEVICE_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_DEVICE_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_DEVICE_HISTORY.data_change_event_user_agent]: "user agent",
};



export const EXTRAS_DEVICE_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_DEVICE_HISTORY = Object.freeze({
  [FIELDS_DEVICE_HISTORY.data_change_event_user_id]: [EXTRAS_DEVICE_HISTORY.user],

});
export const TYPES_FIELDS_DEVICE_HISTORY = {
  [FIELDS_DEVICE_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};
// Поля для истории по модулям устройства
export const FIELDS_DEVICE_MODULE_HISTORY = Object.freeze({
  id: "id",
  module_number: "module_number",
  hpk: "hpk",
  mode: "mode",
  open: "open",
  adjust: "adjust",
  loop0_mode: "loop0_mode",
  loop1_mode: "loop1_mode",
  loop0_camera_number: "loop0_camera_number",
  loop1_camera_number: "loop1_camera_number",
  device_id: "device_id",
  is_deleted: "is_deleted",
  data_change_event_id:"data_change_event_id",
  data_change_event_date:"data_change_event_date",
  data_change_event_action:"data_change_event_action",
  data_change_event_comment:"data_change_event_comment",
  data_change_event_user_id:"data_change_event_user_id",
  data_change_event_ip:"data_change_event_ip",
  data_change_event_port:"data_change_event_port",
  data_change_event_front_id:"data_change_event_front_id",
  data_change_event_http_host:"data_change_event_http_host",
  data_change_event_path:"data_change_event_path",
  data_change_event_user_agent:"data_change_event_user_agent",
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_DEVICE_MODULE_HISTORY = Object.freeze({
  model: "model",
  is_deleted: "is_deleted",
  data_change_event_date: "data_change_event_date",
  data_change_event_user_id: "data_change_event_user_id",
});
export const TITLES_FIELDS_DEVICE_MODULE_HISTORY = {
  [FIELDS_DEVICE_MODULE_HISTORY.id]: "id",
  [FIELDS_DEVICE_MODULE_HISTORY.hpk]: "hpk",
  [FIELDS_DEVICE_MODULE_HISTORY.module_number]: "Номер модуля",
  [FIELDS_DEVICE_MODULE_HISTORY.mode]:"Режим",
  [FIELDS_DEVICE_MODULE_HISTORY.open]: "Порог срабатывания",
  [FIELDS_DEVICE_MODULE_HISTORY.adjust]: "Скользящее среднее",
  [FIELDS_DEVICE_MODULE_HISTORY.loop0_mode]: "Режим петли 0",
  [FIELDS_DEVICE_MODULE_HISTORY.loop1_mode]: "Режим петли 1",
  [FIELDS_DEVICE_MODULE_HISTORY.loop0_camera_number]: "Камера для петли 0",
  [FIELDS_DEVICE_MODULE_HISTORY.loop1_camera_number]: "Камера для петли 1",
  [FIELDS_DEVICE_MODULE_HISTORY.device_id]: "Id устроства",
  [FIELDS_DEVICE_MODULE_HISTORY.is_deleted]: "Удален",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_id]: "ID изменения состояния",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_date]: "Дата события",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_user_id]: "Пользователь",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_ip]: "IP-адрес пользователя",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_port]: "Порт",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_front_id]: "FRONT_ID",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_http_host]: "HOST",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_path]: "Метод API",
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_user_agent]: "user agent",
};



export const EXTRAS_DEVICE_MODULE_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_DEVICE_MODULE_HISTORY = Object.freeze({
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_user_id]: [EXTRAS_DEVICE_MODULE_HISTORY.user],

});
export const TYPES_FIELDS_DEVICE_MODULE_HISTORY = {
  [FIELDS_DEVICE_MODULE_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_DEVICE_MODULE_HISTORY.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,

};
/**
 * Перечисления опций для загрузки дополнительной информации вместе с устройствами.
 */
export const EXTRAS_DEVICE = Object.freeze({
  camera: "camera",
  gang: "gang",
  module: "module",
});

export const EXTRAS_DEVICE_TO_CAMERA = Object.freeze({
  device: "device",
  camera: "camera",
});

const EXTRAS_BY_FIELDS_EXTRAS_DEVICE = Object.freeze({
  [FIELDS_DEVICE.camera_numbers]: [EXTRAS_DEVICE.camera],
  [FIELDS_DEVICE.gang_id]: [EXTRAS_DEVICE.gang],
  [FIELDS_DEVICE.module_ids]: [EXTRAS_DEVICE.module],
});

const EXTRAS_BY_FIELDS_DEVICE_TO_CAMERA = Object.freeze({
  [FIELDS_DEVICE_TO_CAMERA.camera_number]: [EXTRAS_DEVICE_TO_CAMERA.camera],
  [FIELDS_DEVICE_TO_CAMERA.device_id]: [EXTRAS_DEVICE_TO_CAMERA.device],
});

/**
 * Список разрешенных моделей для устройств.
 */
export const DEVICE_MODELS = [
  "beward-dsn06ps",
  "u-domophone",
  "domovoy",
  "boxer",
  "boxer-modules",
  "domovoy-rs485",
  "rodos-8",
  "rodos-9",
  "rodos-10"
];
/**
 * Направление обзора камеры на точке доступа СКУД.
 */
export const DEVICE_CAMERA_DIRECTION = Object.freeze({
  ENTRANCE: "entrance",
  EXIT: "exit"
});
//Модули боксера
export const FIELDS_MODULE = Object.freeze({
  id: "id",
  module_number: "module_number",
  mode: "mode",
  open: "open",
  adjust: "adjust",
  loop0_mode: "loop0_mode",
  loop1_mode: "loop1_mode",
  loop0_camera_number: "loop0_camera_number",
  loop1_camera_number: "loop1_camera_number",
  device_id: "device_id",
  is_deleted: "is_deleted",
  sync_success: "sync_success",
  sync_last_date: "sync_last_date",
  sync_error: "sync_error",
});
export const FILTERS_MODULE = Object.freeze({
  id: "id",
});
export const TITLES_FIELDS_MODULE = {
  [FIELDS_MODULE.id]: "ID",
  [FIELDS_MODULE.mode]: "Режим",
  [FIELDS_MODULE.open]: "Порог срабатывания",
  [FIELDS_MODULE.adjust]: "Скользящее среднее",
  [FIELDS_MODULE.loop0_mode]: "Режим работы",
  [FIELDS_MODULE.loop1_mode]: "Режим работы",
  [FIELDS_MODULE.loop0_camera_number]: "Камера для петли 0",
  [FIELDS_MODULE.loop1_camera_number]: "Камера для петли 1",
  [FIELDS_MODULE.device_id]: "Id устройства",
  [FIELDS_MODULE.is_deleted]: "Удалено",
  [FIELDS_MODULE.module_number]: "Номер модуля",
  [FIELDS_MODULE.sync_success]: "Статус синхронизации",
  [FIELDS_MODULE.sync_last_date]: "Дата синхронизации",
  [FIELDS_MODULE.sync_error]: "Ошибка синхронизации",
};
export const EXTRAS_MODULE = Object.freeze({
  device: "device",
});
const EXTRAS_BY_FIELDS_EXTRAS_MODULES = Object.freeze({
  [FIELDS_MODULE.device_id]: [EXTRAS_MODULE.device],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для устройств.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_DEVICES]() {
      const response = await this.getters.privateAjax.post("/v0/devices/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка устройств.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_EXTRAS_DEVICE, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/devices/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка устройств для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_DEVICES, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_DEVICES_FOR_TABLE});
    },
    /**
     * Загрузка списка устройств для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных устройств.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @param {Number} gangId
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search, gangId = null}) {
      const defaultFilterForSelect = gangId
          ?[
            makeFilterApi(FILTERS_DEVICE.is_deleted, "=", false),
            makeFilterApi(FILTERS_DEVICE.gang_id, "=", gangId)
          ]
          : [ makeFilterApi(FILTERS_DEVICE.is_deleted, "=", false)],
        responseData = await dispatch(ACTION_LOAD_DEVICES, {
          pageSize,
          fields,
          filters: defaultFilterForSelect,
          search,
          cancelTokenKey: ACTION_LOAD_DEVICES_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного устройства для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_FOR_EDIT]({dispatch}, [deviceId, fields]) {
      const filter = makeFilterApi(FILTERS_DEVICE.id, "=", deviceId),
        responseData = await dispatch(ACTION_LOAD_DEVICES, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание устройства.
     *
     * @param {Object} context
     * @param {Object} deviceInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_DEVICE](context, deviceInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/create/", deviceInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование устройства.
     *
     * @param {Object} context
     * @param {Object} deviceInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_DEVICE](context, deviceInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/edit/", deviceInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление устройства.
     *
     * @param {Object} context
     * @param {Array} deviceIds
     * @return {Promise}
     */
    async [ACTION_DELETE_DEVICE](context, {deviceIds}) {
      return this.getters.privateAjax.post("/v0/devices/delete/", {ids: deviceIds});
    },
    // -----------------------------------------------------------------------------------------------------------------
    // DEVICE TO CAMERA ADD ACTIONS
    /**
     * Загрузка служебной информации для камер прикрепленных к устройствам СКУД.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_DEVICES_TO_CAMERAS]() {
      const response = await this.getters.privateAjax.post("/v0/devices/cameras/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка камер прикрепленных к устройствам СКУД.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_TO_CAMERAS](context, {page, pageSize, orderBy, fields, filters, search}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_DEVICE_TO_CAMERA, fields))),
        response = await this.getters.privateAjax.post("/v0/devices/cameras/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка камер для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_TO_CAMERAS_FOR_EDIT]({dispatch}, {deviceId = null, search = "", page = 1}) {
      let filter = makeFilterApi( FILTERS_DEVICE_TO_CAMERA.device_id,"=", deviceId);

      return dispatch(ACTION_LOAD_DEVICES_TO_CAMERAS, {
        page,
        pageSize: 10,
        orderBy: [],
        fields: [
          FIELDS_DEVICE_TO_CAMERA.camera_direction,
          FIELDS_DEVICE_TO_CAMERA.camera_number,
          FIELDS_DEVICE_TO_CAMERA.device_id,
        ],
        filters: [filter],
        search
      });
    },
    /**
     * Добавление новой камеры к устройству
     *
     * @param {Object} context
     * @param {Number} deviceId
     * @param {Array} rawCamerasDirectionInfo [[camera_direction, camera_number], ...]
     * @return {Promise}
     */
    async [ACTION_ADD_DEVICES_TO_CAMERAS_BY_FORM](context, {deviceId = null, rawCamerasDirectionInfo}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/cameras/add/", {
          device_id: deviceId,
          cameras: rawCamerasDirectionInfo.map((cameraDirectionInfo) => {
            return {camera_number: cameraDirectionInfo[0], camera_direction: cameraDirectionInfo[1]};
          })
        });
        return response.data;
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление камеры с устройства.
     *
     * @param {Object} context
     * @param {Number} deviceId
     * @param {Boolean} isAuto
     * @param {Array} cameraNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_DEVICES_TO_CAMERAS](context, {deviceId, cameraNumbers}) {
      try {const response = await this.getters.privateAjax.post("/v0/devices/cameras/delete/", {
        device_id: deviceId,
        camera_numbers: cameraNumbers
      });
      return response.data;
      }  catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    // Модули устройств
    /**
     * Загрузка списка модулей устройства.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_MODULES](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_EXTRAS_MODULES, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/devices/modules", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Редактирование модуля.
     *
     * @param {Object} context
     * @param {Object} deviceInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_MODULE](context, deviceInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/modules/edit/", deviceInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async [ACTION_LOAD_MODULE_FOR_EDIT]({dispatch}, [moduleNumber, fields]) {
      const filter = makeFilterApi(FILTERS_MODULE.id, "=", moduleNumber),
        responseData = await dispatch(ACTION_LOAD_MODULES, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Синхронизация модулей по устройству.
     *
     * @param {Object} context
     * @param {Object} deviceInfo
     * @return {Promise}
     */
    async [ACTION_SYNC_MODULES](context, device_id) {
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/modules/sync/", {device_id});
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    // Методы для загрузки информации по истории редактирования аналитики автономеров,
    // начинается с v0/analytics/car_number/history
    /**
     * Загрузка служебной информации для камер.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_DEVICE_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/devices/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_DEVICE_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/history/", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_DEVICE_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_DEVICE_HISTORY_FOR_TABLE});
    },
    // Методы для загрузки информации по истории редактирования модулей боксеров,
    // начинается с v0/analytics/devices/modules/history
    /**
     * Загрузка служебной информации.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_DEVICE_MODULE_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/devices/modules/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_MODULE_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_DEVICE_MODULE_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;
        });
      try {
        const response = await this.getters.privateAjax.post("/v0/devices/modules/history/", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_MODULE_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_DEVICE_MODULE_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_DEVICE_MODULE_HISTORY_FOR_TABLE});
    },
  },
};
