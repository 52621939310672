<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_SERVERS}" class="tabs__item">
          Серверы
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          Кластеры серверов
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateCluster"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :parse-extra="parseExtra"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_CLUSTERS_FOR_TABLE,
  ACTION_LOAD_INFO_CLUSTERS,
  EXTRAS_CLUSTER,
  FIELDS_CLUSTER,
  TITLES_FIELDS_CLUSTER,
  TYPES_FIELDS_CLUSTER
} from "@/store/heavyMetal/clusters/index.js";
import CreateClusterDialog from "@/components/heavyMetal/clusters/CreateClusterDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {ROUTE_CLUSTERS, ROUTE_EDIT_CLUSTER, ROUTE_SERVERS} from "@/router/names.js";
import {FIELDS_SERVER, FILTERS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по кластерам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_SERVERS,
      },
      currentRoute: ROUTE_CLUSTERS,
      rawFields: FIELDS_CLUSTER,
      columnCaptions: TITLES_FIELDS_CLUSTER,
      nameActionLoadDataForTable: `clusters/${ACTION_LOAD_CLUSTERS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `clusters/${ACTION_LOAD_INFO_CLUSTERS}`,
      defaultFieldsTableForView: [
        FIELDS_CLUSTER.id,
        FIELDS_CLUSTER.name,
        FIELDS_CLUSTER.is_deleted,
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_CLUSTER.server]) {
        storedExtraInfo.servers = {};
        sourceExtraInfo[EXTRAS_CLUSTER.server].forEach((serverInfo) => {
          storedExtraInfo.servers[serverInfo[FIELDS_SERVER.id]] = {
            id: serverInfo[FIELDS_SERVER.id],
            name: serverInfo[FIELDS_SERVER.domain],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      let params;
      switch (nameField) {
      case FIELDS_CLUSTER.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CLUSTER, params: {clusterId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CLUSTER.name:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CLUSTER, params: {clusterId: rowData[FIELDS_CLUSTER.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_CLUSTER.server_ids:
        readyTranslation = _.chain(storedExtraInfo.servers)
          .pick(rawTranslation)
          .mapValues("name")
          .values()
          .value();
        params = new TableQueryParams({filters: [new FilterData(FILTERS_SERVER.cluster_id, "=", [rowData[FIELDS_CLUSTER.id]])]});
        readyParams = {name: ROUTE_SERVERS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: params.stringify()}};
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_CLUSTER[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания кластера.
     */
    openDialogCreateCluster() {
      this.$camsdals.open(
        CreateClusterDialog,
        {},
        {dialogTitle: "Создание кластера"},
        {},
      );
    },
  },
};
</script>
