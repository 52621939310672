<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.domain]"
        :caption="titlesFields[fieldsEntity.domain]"
        :error="errorsFormEdit[fieldsEntity.domain]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.max_cameras]"
        :caption="titlesFields[fieldsEntity.max_cameras]"
        :error="errorsFormEdit[fieldsEntity.max_cameras]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.screenshot_domain]"
        :caption="titlesFields[fieldsEntity.screenshot_domain]"
        :error="errorsFormEdit[fieldsEntity.screenshot_domain]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.proxy_domain]"
        :caption="titlesFields[fieldsEntity.proxy_domain]"
        :error="errorsFormEdit[fieldsEntity.proxy_domain]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.storage]"
        :caption="titlesFields[fieldsEntity.storage]"
        :error="errorsFormEdit[fieldsEntity.storage]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.disk_limit]"
        :caption="titlesFields[fieldsEntity.disk_limit]"
        :error="errorsFormEdit[fieldsEntity.disk_limit]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_auto_allocation]"
        :caption="titlesFields[fieldsEntity.is_auto_allocation]"
        :error="errorsFormEdit[fieldsEntity.is_auto_allocation]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_managed]"
        :caption="titlesFields[fieldsEntity.is_managed]"
        :error="errorsFormEdit[fieldsEntity.is_managed]"
        class="col"
      />
    </div>
    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_multistorage_enabled]"
        :caption="titlesFields[fieldsEntity.is_multistorage_enabled]"
        :error="errorsFormEdit[fieldsEntity.is_multistorage_enabled]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.cluster_ids]"
        :caption="titlesFields[fieldsEntity.cluster_ids]"
        :error="errorsFormEdit[fieldsEntity.cluster_ids]"
        :multiple="true"
        :settings-remote-search="settingsSelects[fieldsEntity.cluster_ids]"
        class="col"
      />
    </div>
    <SmartSelect
      v-model="formEdit[fieldsEntity.vendor_name]"
      :caption="titlesFields[fieldsEntity.vendor_name]"
      :error="errorsFormEdit[fieldsEntity.vendor_name]"
      :options="vendorName"
      width="fill"
      class="col"
    />
    <div v-if="$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.SERVER, fieldsEntity.tariff_ids)" class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.tariff_ids]"
        :caption="titlesFields[fieldsEntity.tariff_ids]"
        :error="errorsFormEdit[fieldsEntity.tariff_ids]"
        :multiple="true"
        :settings-remote-search="settingsSelects[fieldsEntity.tariff_ids]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.api_allowed_from]"
        :caption="titlesFields[fieldsEntity.api_allowed_from]"
        :error="errorsFormEdit[fieldsEntity.api_allowed_from]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.cluster_key]"
        :caption="titlesFields[fieldsEntity.cluster_key]"
        :error="errorsFormEdit[fieldsEntity.cluster_key]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.migrate_limit]"
        :caption="titlesFields[fieldsEntity.migrate_limit]"
        :error="errorsFormEdit[fieldsEntity.migrate_limit]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.login]"
        :caption="titlesFields[fieldsEntity.login]"
        :error="errorsFormEdit[fieldsEntity.login]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.password]"
        :caption="titlesFields[fieldsEntity.password]"
        :error="errorsFormEdit[fieldsEntity.password]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_SERVER} from "@/router/names.js";
import {ACTION_LOAD_CLUSTERS_FOR_SELECT, FIELDS_CLUSTER} from "@/store/heavyMetal/clusters/index.js";
import {
  ACTION_CREATE_SERVER_WITH_ALL,
  FIELDS_SERVER,
  TITLES_FIELDS_SERVER,
  VENDOR_NAME
} from "@/store/heavyMetal/servers/index.js";
import {ACTION_LOAD_TARIFFS_FOR_SELECT, FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания сервера.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `servers/${ACTION_CREATE_SERVER_WITH_ALL}`,
      fieldsEntity: FIELDS_SERVER,
      titlesFields: TITLES_FIELDS_SERVER,
      vendorName: VENDOR_NAME,
      formEdit: {
        [FIELDS_SERVER.domain]: "",
        [FIELDS_SERVER.max_cameras]: "",
        [FIELDS_SERVER.screenshot_domain]: "",
        [FIELDS_SERVER.proxy_domain]: "",
        [FIELDS_SERVER.storage]: "",
        [FIELDS_SERVER.disk_limit]: "",
        [FIELDS_SERVER.is_auto_allocation]: false,
        [FIELDS_SERVER.is_managed]: false,
        [FIELDS_SERVER.is_multistorage_enabled]: false,
        [FIELDS_SERVER.cluster_ids]: null,
        [FIELDS_SERVER.tariff_ids]: [],
        [FIELDS_SERVER.api_allowed_from]: [],
        [FIELDS_SERVER.cluster_key]: null,
        [FIELDS_SERVER.migrate_limit]: 3,
        [FIELDS_SERVER.login]: "",
        [FIELDS_SERVER.password]: "",
      },
      settingsSelects: {
        [FIELDS_SERVER.cluster_ids]: {
          action: `clusters/${ACTION_LOAD_CLUSTERS_FOR_SELECT}`,
          valueField: FIELDS_CLUSTER.id,
          labelField: FIELDS_CLUSTER.name
        },
        [FIELDS_SERVER.tariff_ids]: {
          action: `tariffs/${ACTION_LOAD_TARIFFS_FOR_SELECT}`,
          valueField: FIELDS_TARIFF.id,
          labelField: FIELDS_TARIFF.name
        },
      }
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование сервера.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_SERVER, params: {serverId: newEntity[FIELDS_SERVER.id]}};
    },
  },
};
</script>
