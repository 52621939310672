<template>
  <div>
    <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
      <SpinnerLoadingModal v-if="isLoading" />
      <div class="col">
        <SmartSelect
          v-model="formEdit[fieldsEntity.model]"
          :options="this.supportedCameraModels"
          caption="Модель камеры"
          :error="errorsFormEdit[fieldsEntity.model]"
          width="fill"
        />
        <SmartSelect
          v-model="formEdit[fieldsEntity.type]"
          :options="typesFirmwareFile"
          caption="Тип файла"
          :error="errorsFormEdit[fieldsEntity.type]"
          width="fill"
        />
        <SmartInputText
          v-model="formEdit[fieldsEntity.version]"
          :caption="titlesFields[fieldsEntity.version]"
          :error="errorsFormEdit[fieldsEntity.version]"
        />
        <br>
      </div>
      <div>
        <input
          ref="fileInput"
          type="file"
          style="display:none"
          accept=".squashfs"
          onclick="this.value=null"
          @change="firmwareUploadFile"
        >

        <CamsButton
          v-model="formEdit[fieldsEntity.firmware]"
          type="button"
          @click="$refs.fileInput.click()"
        >
          Загрузить прошивку
        </CamsButton>
      </div>
      <div style="color: #E12F2F">
        {{ errorsFormEdit.firmware }}
      </div>
      <br>
      <div class="dialog-actions">
        <CamsButton type="button" @click="closeDialog()">
          Отменить
        </CamsButton>
        <CamsButton priority="primary" type="submit">
          Сохранить
        </CamsButton>
      </div>
    </form>
    <notifications classes="customized-notification-style" style="margin: 15px" group="main" position="top right" />
  </div>
</template>

<script>
import {
  ACTION_CREATE_FIRMWARE,
  ACTION_LOAD_SUPPORTED_CAMERA_MODELS,
  FIELDS_FIRMWARE,
  TITLES_FIELDS_FIRMWARE, TYPES_FIRMWARE,
} from "@/store/firmwares/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
/**
 * Компонент диалога создания прошивки камеры.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      fieldsEntity: FIELDS_FIRMWARE,
      titlesFields: TITLES_FIELDS_FIRMWARE,
      supportedCameraModels: "",
      typesFirmwareFile: TYPES_FIRMWARE,
      nameActionCreate: `firmwares/${ACTION_CREATE_FIRMWARE}`,
      formEdit: {
        [FIELDS_FIRMWARE.firmware]: "",
        [FIELDS_FIRMWARE.version]: "",
        [FIELDS_FIRMWARE.model]: "",
      },
    };
  },
  created() {
    this.$store.dispatch(`firmwares/${ACTION_LOAD_SUPPORTED_CAMERA_MODELS}`, {
    })
      .then((supportedCameraModels) => {
        this.supportedCameraModels = supportedCameraModels.results.sort();
      });

  },
  methods: {

    /**
     * Произойдет перезагрузка страницы, пользователь вернется к таблице с прошивками.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      location.reload();
    },
    /**
     * Загрузка файла прошивки.
     */
    async firmwareUploadFile(event) {
      this.formEdit.firmware = event.target.files[0];
    },
  },
};
</script>
<style lang="scss">
.firmware-dialog {
  margin: 20px 0px 0px 160px;

}
</style>
