<template>
  <div>
    <ProcessingMultiEditDialog
      v-if="goProcessing"
      :promise-save-data="promiseSaveData"
      :selected-entities="selectedEntities"
    />
    <div v-else>
      <p v-if="countSelectedOriginally !== selectedEntities.length">
        Внимание: количество строк в фильтрации и количество для множественного редактирования - не совпадают!
      </p>

      <div class="row">
        <div class="col-2">
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.EDIT_RIGHT_GROUP ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.EDIT_RIGHT_GROUP"
            >
              Редактирование параметров
            </CamsButton>
          </div>
        </div>

        <div class="col-2">
          <div v-show="!selectedComponentMultiEdit" class="row">
            <p>Выберите функцию для массового действия.</p>
          </div>

          <div class="row">
            <component
              :is="selectedComponentMultiEdit"
              v-if="selectedComponentMultiEdit"
              class="col"
              @change-promise="promiseSaveData = $event"
              @ready-for-processing="isReadyForProcessing = $event"
            />
          </div>

          <div v-show="selectedComponentMultiEdit" class="dialog-actions">
            <CamsButton
              type="button"
              @click="closeDialog()"
            >
              Отменить
            </CamsButton>
            <CamsButton
              :disabled="!isReadyForProcessing"
              priority="primary"
              type="button"
              @click="goProcessing = true"
            >
              Применить
            </CamsButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {methodsForDialogMixin, multiEditEntityMixin} from "@/utils/mixins.js";
import EditRightGroup from "@/components/rightGroups/functionsMultiEdit/EditRightGroup.vue";
import ProcessingMultiEditDialog from "@/components/dialogs/ProcessingMultiEditDialog.vue";


const COMPONENTS_MULTI_EDIT_RIGHT_GROUPS = Object.freeze({
  EDIT_RIGHT_GROUP: EditRightGroup,
});

/**
 * Компонент для организации множественного редактирования групп прав.
 */
export default {
  components: {
    EditRightGroup,
    ProcessingMultiEditDialog
  },
  mixins: [
    methodsForDialogMixin,
    multiEditEntityMixin
  ],
  data() {
    return {
      componentsMultiEdit: COMPONENTS_MULTI_EDIT_RIGHT_GROUPS,
    };
  },
};
</script>
