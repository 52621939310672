<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.username]"
        :caption="titlesFields[fieldsEntity.username]"
        :error="errorsFormEdit[fieldsEntity.username]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit.password"
        :error="errorsFormEdit['password']"
        caption="Пароль"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.about]"
        :caption="titlesFields[fieldsEntity.about]"
        :error="errorsFormEdit[fieldsEntity.about]"
        class="col"
        input-type="area"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_superuser]"
        :caption="titlesFields[fieldsEntity.is_superuser]"
        :error="errorsFormEdit[fieldsEntity.is_superuser]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.labels]"
        :caption="titlesFields[fieldsEntity.labels]"
        :error="errorsFormEdit[fieldsEntity.labels]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.right_group_id]"
        :caption="titlesFields[fieldsEntity.right_group_id]"
        :error="errorsFormEdit[fieldsEntity.right_group_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.right_group_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_active]"
        :caption="titlesFields[fieldsEntity.is_active]"
        :error="errorsFormEdit[fieldsEntity.is_active]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputDate
        v-model="formEdit[fieldsEntity.date_block]"
        :caption="titlesFields[fieldsEntity.date_block]"
        :config-flat-pickr="configFlatPickr"
        :error="errorsFormEdit[fieldsEntity.date_block]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.immortal_token_access]"
        :caption="titlesFields[fieldsEntity.immortal_token_access]"
        :error="errorsFormEdit[fieldsEntity.immortal_token_access]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.immortal_token_ips]"
        :caption="titlesFields[fieldsEntity.immortal_token_ips]"
        :error="errorsFormEdit[fieldsEntity.immortal_token_ips]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_ADMIN} from "@/router/names.js";
import {ACTION_CREATE_ADMIN, FIELDS_ADMIN, TITLES_FIELDS_ADMIN} from "@/store/users/admins/index.js";
import {ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT, FIELDS_RIGHT_GROUP} from "@/store/rightGroups/index.js";
import {createEntityMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога создания админа.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `admins/${ACTION_CREATE_ADMIN}`,
      fieldsEntity: FIELDS_ADMIN,
      titlesFields: TITLES_FIELDS_ADMIN,
      formEdit: {
        [FIELDS_ADMIN.username]: "",
        [FIELDS_ADMIN.about]: "",
        [FIELDS_ADMIN.is_superuser]: false,
        [FIELDS_ADMIN.labels]: [],
        [FIELDS_ADMIN.right_group_id]: null,
        [FIELDS_ADMIN.is_active]: true,
        [FIELDS_ADMIN.date_block]: null,
        [FIELDS_ADMIN.immortal_token_access]: false,
        [FIELDS_ADMIN.immortal_token_ips]: [],
        password: "",
      },

      settingsSelects: {
        [FIELDS_ADMIN.right_group_id]: {
          action: `rightGroups/${ACTION_LOAD_RIGHT_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_RIGHT_GROUP.id,
          labelField: FIELDS_RIGHT_GROUP.name
        },
      },

      configFlatPickr: {
        minDate: "today",
        minTime: Date.now(),
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование админа.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_ADMIN, params: {adminId: newEntity[FIELDS_ADMIN.id]}};
    },
  },
};
</script>
