/**
 * Основное хранилище состояния клиентского приложения.
 *
 * Включает в себя основную работу с сервером по API в части авторизации и получения базовой информации.
 * Целевая работа с отдельными сущностями происходит в модулях vuex.
 */

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as axios from "axios";
import {HTTP_MODE} from "camsng-frontend-shared/lib/consts.js";

import {ABILITIES_ADMINS, abilityPlugin} from "@/store/ability.js";
import dadata from "@/store/dadata/index.js";
import cameras from "@/store/cameras/index.js";
import servers from "@/store/heavyMetal/servers/index.js";
import tariffs from "@/store/tariffs/index.js";
import clusters from "@/store/heavyMetal/clusters/index.js";
import cameraGroups from "@/store/cameraGroups/index.js";
import cameraGroupTypes from "@/store/cameraGroupTypes/index.js";
import admins from "@/store/users/admins/index.js";
import clients from "@/store/users/clients/index.js";
import permissions from "@/store/permissions/index.js";
import log from "@/store/log/index.js";
import analytics from "@/store/analytics/index.js";
import domainSettings from "@/store/domainSettings/index.js";
import keeperParamsTable from "@/store/keeperParamsTable/index.js";
import rightGroups, {ACTION_LOAD_INFO_RIGHT_GROUPS} from "@/store/rightGroups/index.js";
import employees from "@/store/pacs/employees/index.js";
import gangs from "@/store/gangs/index.js";
import pacsPermissions from "@/store/pacs/pacsPermissions/index.js";
import devices from "@/store/pacs/devices/index.js";
import deviceAccessGroups from "@/store/pacs/deviceAccessGroups/index.js";
import cars from "@/store/pacs/cars/index.js";
import employeesPhoto from "@/store/pacs/employeesPhoto/index.js";
import markers from "@/store/markers/index.js";
import bolidServers from "@/store/integrations/bolid/servers/index.js";
import bolidDevices from "@/store/integrations/bolid/devices/index.js";
import bolidEvents from "@/store/integrations/bolid/events/index.js";
import camerasSetup from "@/store/camerasSetup/index.js";
import firmwares from "@/store/firmwares/index.js";
import {
  MUTATION_CHANGE_DEFAULT_CENTER_MAP,
  MUTATION_SET_NEED_UPDATE,
  MUTATION_RESET_STATE,
  MUTATION_SET_CONTEXT,
  MUTATION_SET_ETAG,
  MUTATION_SET_TOKEN,
  MUTATION_SET_USERNAME, MUTATION_SET_SHARED_TIME_SHIFT,
} from "@/store/mutations.js";
import {
  ACTION_AUTO_UPDATE,
  ACTION_CONFORMITY_RIGHTS,
  ACTION_GET_NEW_TOKEN,
  ACTION_LOAD_CONTEXT,
  ACTION_SIGN_IN_VIA_TOKEN,
  ACTION_SIGN_OUT
} from "@/store/actions.js";
import {
  CONFIG_AJAX_PREFIX_HEADER_AUTH,
  CONFIG_AJAX_TIMEOUT,
  CONFIG_BASE_URL,
  CONFIG_IS_AUTH_VIA_TOKEN,
} from "@/utils/consts.js";

Vue.use(Vuex);

/**
 * Конструктор клиента для общения с сервером, через настройку библиотеки axios.
 *
 * На axios навешивается перехватчик, который отслеживает 401 ошибку,
 * в этом случае состояние сбрасывается до начального вида.
 *
 * Если приложение сконфигурировано для авторизации через токен - он будет подмешиваться в заголовках,
 * без токена авторизация идет через сессии.
 */
export function constructorAjaxClient(token = null) {
  const config = {
    baseURL: CONFIG_BASE_URL,
    timeout: CONFIG_AJAX_TIMEOUT,
  };

  if (CONFIG_IS_AUTH_VIA_TOKEN) {
    if (token) {
      Object.assign(config, {
        headers: {
          Authorization: `${CONFIG_AJAX_PREFIX_HEADER_AUTH}${token}`,
        },
      });
    }
  } else {
    Object.assign(config, {
      withCredentials: true,
      xsrfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
    });
  }

  const instanceAxios = axios.create(config);
  instanceAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.commit(MUTATION_RESET_STATE);
      }
      return Promise.reject(error);
    }
  );

  return instanceAxios;
}

/**
 * Функция вернет начальное состояние хранилища по ряду параметров, которые могут быть сохранены и после сброса состояния.
 * Как правило такие параметры не должны сбрасываться с основным состоянием, потому что не зависят от контекста и авторизаций.
 *
 * @return {Object}
 */
function zeroState() {
  return {
    settingsMap: {
      defaultCenter: [50, 50], // Центр карты по умолчанию - переопределяется в процессе работы.
      defaultZoom: 13,
    },
    etag: null, // Хеш ETag index.html по которому проверяются обновления.
    needUpdate: false, // Флаг если требуется обновление клиентского приложения.
  };
}

/**
 * Функция вернет начальное состояние хранилища по ряду параметров, для которых необходимо обеспечить сброс.
 * Применяется на этапе инициализации приложения, если у пользователя не было ранее установленного состояния,
 * а так же при сбросе состояния в начальное положение при выходе пользователя из системы.
 *
 * @return {Object}
 */
function initialState() {
  return {
    username: null,
    token: null, // todo сейчас вместо токена используются сессии - оставлять всегда null.
    settingsMap: { // Общие настройки для компонентов карт, просто сгруппированны в один блок.
      options: {
        zoomSnap: 0.5,
      },
    },
    context: {
      title: null,
      httpMode: null,
      dadata: null,
      isSuperuser: null,
      permissions: null,
      sharedTimeShift: null,
    },
  };
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({key: "store"}),
    abilityPlugin,
  ],
  modules: {
    keeperParamsTable,
    dadata,
    cameras,
    servers,
    tariffs,
    clusters,
    cameraGroups,
    cameraGroupTypes,
    admins,
    clients,
    permissions,
    domainSettings,
    rightGroups,
    log,
    analytics,
    employees,
    gangs,
    pacsPermissions,
    devices,
    deviceAccessGroups,
    cars,
    employeesPhoto,
    markers,
    bolidServers,
    bolidDevices,
    bolidEvents,
    camerasSetup,
    firmwares,
  },
  state: () => _.merge(zeroState(), initialState()),
  mutations: {
    /**
     * Сброс состояния к начальному виду.
     *
     * @param {Object} state
     */
    [MUTATION_RESET_STATE](state) {
      // eslint-disable-next-line no-unused-vars
      state = _.merge(state, initialState());
    },
    /**
     * Записывание имени пользователя.
     *
     * Отдельная мутация про изменении имени пользователя необходима для отслеживания
     * внутреннего состояния наличия авторизации {@link getters.isAuth}, на которое завязана логика отображения
     * компонента авторизации и основного контента.
     *
     * Мутация используется при авторизации через сессии (когда имя пользователя приходит из контекста при первой загрузке страницы)
     * и через токены (когда логин приходит из запроса авторизации по токену).
     * Соответственно, при изменении этого атрибута состояния запускается цепочка событий в главном компоненте приложения,
     * которая спровоцирует повторное получение контекста и состояние приложения будет обновлено до актуального вида.
     *
     * @param {Object} state
     * @param {String} username
     */
    [MUTATION_SET_USERNAME](state, username) {
      state.username = username;
    },
    /**
     * Записывание токена.
     *
     * @param {Object} state
     * @param {String} token
     */
    [MUTATION_SET_TOKEN](state, token) {
      state.token = token;
    },
    /**
     * Установка состояния по данным из контекста в котором работает приложение.
     *
     * @param {Object} state
     * @param {Object} context
     */
    [MUTATION_SET_CONTEXT](state, context) {
      state.context.title = context.title;
      state.context.httpMode = context.http_mode;
      state.context.dadata = context.dadata;
      state.context.isSuperuser = context.is_superuser;
      state.context.permissions = context.permissions;
    },
    /**
     * Изменит стандартную центровку для карты.
     *
     * @param {Object} state
     * @param {Array<Number>} newCenterMap
     */
    [MUTATION_CHANGE_DEFAULT_CENTER_MAP](state, newCenterMap) {
      state.settingsMap.defaultCenter = newCenterMap;
    },
    /**
     * Сохранение хеша ETag index.html.
     *
     * @param {Object} state
     * @param {String} etag
     */
    [MUTATION_SET_ETAG](state, etag) {
      state.etag = etag;
    },
    /**
     * Изменение флага необходимости обновления клиентского приложения.
     *
     * @param {Object} state
     * @param {Boolean} needUpdate
     */
    [MUTATION_SET_NEED_UPDATE](state, needUpdate) {
      state.needUpdate = needUpdate;
    },
    [MUTATION_SET_SHARED_TIME_SHIFT](state, sharedTimeShift) {
      state.sharedTimeShift = sharedTimeShift;
    },
  },
  actions: {
    /**
     * Регулярный опрос сервера для того, чтобы узнать наличие обновление в клиентском приложении, чтобы обновить его.
     * Обновления проверяются сравнением значений ETag для index.html, поскольку в нем меняются ссылки на js скрипты, то и ETag будет отличаться.
     *
     * Период обновления = 5 минут + случайное количество секунд (до 10) чтобы обновления происходили не в одно мгновение у всех клиентов,
     * а нагрузка на сервер была более равномерной.
     *
     * @param {Object} state
     * @param {Function} commit
     * @returns {Promise}
     */
    async [ACTION_AUTO_UPDATE]({state, commit}) {
      const response = await fetch("/index.html", {method: "HEAD"});

      commit(MUTATION_SET_ETAG, response.headers.get("ETag"));
      setInterval(async () => {
        try {
          const response = await fetch("/index.html", {method: "HEAD", headers: {"If-None-Match": state.etag}});
          // Для запроса с If-None-Match с тем же ETag будет не ok и статус 304, а при 200 есть изменения и можно обновить страницу.
          commit(MUTATION_SET_NEED_UPDATE, response.ok);
        } catch {
          // В случае ошибок на сети, обновления не происходит чтобы не сломать текущую картинку.
          // location.reload();
        }
      }, 300000 + (Math.random() * 10000));
    },
    /**
     * Отправка данных авторизации и запись полученного токена в хранилище или вывод информации по ошибке.
     * Успешное получение результата характеризуется извлечением токена и имени пользователя.
     *
     * @param {Function} commit
     * @param {String} username
     * @param {String} password
     * @param {Number} ttl
     * @returns {Promise}
     */
    async [ACTION_SIGN_IN_VIA_TOKEN]({commit}, {username, password, ttl = 60 * 60 * 24}) {
      try {
        const response = await this.getters.publicAjax.post(this.getters.urlSignIn, {username, password, ttl});
        commit(MUTATION_SET_TOKEN, response.data.token);
        commit(MUTATION_SET_USERNAME, response.data.username);
        return true;
      } catch (error) {
        commit(MUTATION_RESET_STATE);
        return false;
      }
    },
    /**
     * Выход из системы.
     *
     * @param {Function} commit
     */
    [ACTION_SIGN_OUT]({commit}) {
      commit(MUTATION_RESET_STATE);
    },
    /**
     * Загрузка контекстной информации о рабочем окружении.
     * Необходимо вызывать при перезагрузке страницы и (или) при старте главного компонента приложения.
     *
     * Загрузка контекста будет происходить успешно вне зависимости от наличия авторизации,
     * поэтому только явные ошибки на сети должны провоцировать catch.
     * Тем не менее, отсутствие имени пользователя в контексте говорит о слетевшей сессии,
     * а значит необходимо сбрасывать состояние до начального,
     * после чего обновить служебную информацию из полученного контекста (например о протоколе).
     *
     * По получению основных данных из контекста нужно проверить соответствие механизма прав на клиенте и сервере,
     * и если нет - то клиент не сможет обеспечить свою корректную работу.
     *
     * @param {Function} dispatch
     * @param {Function} commit
     * @returns {Promise}
     */
    async [ACTION_LOAD_CONTEXT]({dispatch, commit}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/context/");
        if (response.data.username === null) {
          commit(MUTATION_RESET_STATE);
        } else {
          commit(MUTATION_SET_USERNAME, response.data.username);
        }
        commit(MUTATION_SET_CONTEXT, response.data);
        return dispatch(ACTION_CONFORMITY_RIGHTS);
      } catch {
        commit(MUTATION_RESET_STATE);
        throw new Error("Ошибка при получении информации от сервера.");
      }
    },
    /**
     * Проверка на совпадение прав зафиксированных на клиенте и на сервере.
     * Если они не совпадают - значит клиент не может обеспечить ту регуляцию которую требует сервер.
     *
     * @param {Function} dispatch
     * @param {Function} commit
     * @returns {Promise}
     */
    async [ACTION_CONFORMITY_RIGHTS]({dispatch, commit}) {
      if (!this.getters.isAuth) {
        return;
      }

      const [, , permissions] = await dispatch(`rightGroups/${ACTION_LOAD_INFO_RIGHT_GROUPS}`);
      if (!_.isEmpty(_.xor(permissions, Object.keys(ABILITIES_ADMINS)))) {
        commit(MUTATION_RESET_STATE);
        throw new Error("Расхождение в списках доступных прав на клиенте и сервере.");
      }
    },
    /**
     * Отправка запроса для получения нового токена.
     * Применяется для его подстановки в другие запросы.
     * Не обновляет текущий токен в приложении.
     * Если получение токена не прошло успешно, тогда будет использоваться текущий клиентский.
     *
     * @param {Object} context
     * @param {Number} ttl
     * @returns {Promise}
     */
    async [ACTION_GET_NEW_TOKEN](context, {ttl = 60 * 60 * 24}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/auth_token_refresh/", {ttl});
        return response.data.token;
      } catch (error) {
        return this.token;
      }
    },
  },
  getters: {
    /**
     * Вернет актуальный режим работы с http.
     *
     * @return {String}
     */
    httpMode: (state) => state.context.httpMode === HTTP_MODE.https ? HTTP_MODE.https : HTTP_MODE.http,
    /**
     * Публичный клиент для общения с сервером.
     *
     * @returns {Object}
     */
    publicAjax: () => constructorAjaxClient(),
    /**
     * Приватный клиент для общения с сервером с токеном авторизации.
     *
     * @param {Object} state
     * @returns {Object}
     */
    privateAjax: (state) => constructorAjaxClient(state.token),
    /**
     * Вернет адрес отправки формы авторизации.
     *
     * @return {String}
     */
    urlSignIn() {
      return `${CONFIG_BASE_URL}${CONFIG_IS_AUTH_VIA_TOKEN ? "v0/auth/" : "internal/login/"}`;
    },
    /**
     * Вернет адрес для выхода из системы.
     *
     * @return {String}
     */
    urlSignOut() {
      return `${CONFIG_BASE_URL}internal/logout/`;
    },
    /**
     * Проверка авторизации.
     *
     * @param {Object} state
     * @returns {Boolean}
     */
    isAuth: (state) => !!state.username,
    /**
     * Вернет username авторизованного пользователя.
     *
     * @param {Object} state
     * @returns {String}
     */
    username: (state) => state.username,
    /**
     * Вернет true если сервер допускает использование сервиса dadata.
     *
     * @param {Object} state
     * @return {Boolean}
     */
    isAvailableDaData: (state) => Boolean(state.context.dadata),
    /**
     * Вернет протокол для использования в запросах к серверам с видео по протоколу http.
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {String}
     */
    protocolVideoOverHTTP: (state, getters) => getters.httpMode,
    /**
     * Вернет протокол для использования в запросах к серверам с видео по протоколу WebSocket.
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {String}
     */
    protocolVideoOverWS: (state, getters) => getters.httpMode === HTTP_MODE.https ? "wss" : "ws",
  }
});

export default store;
