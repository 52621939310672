<template>
  <div>
    <div class="tabs">
      <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
        Интеграция с BOLID
      </router-link>
    </div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_BOLID_SERVERS}" class="tabs__item">
          Серверы
        </router-link>
        <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
          События
        </router-link>
        <router-link :to="{name: routes.ROUTE_BOLID_DEVICES}" class="tabs__item">
          Устройства
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_BOLID_SERVERS,
  ROUTE_BOLID_EVENTS,
  ROUTE_BOLID_DEVICES
} from "@/router/names.js";
import {FilterData} from "@/utils/helpers.js";
import {
  ACTION_LOAD_BOLID_EVENTS_FOR_TABLE,
  ACTION_LOAD_INFO_BOLID_EVENTS,
  FIELDS_BOLID_EVENTS, FILTERS_BOLID_EVENTS,
  TITLES_FIELDS_BOLID_EVENTS, TYPES_BY_FIELDS_BOLID_EVENTS
} from "@/store/integrations/bolid/events/index.js";

/**
 * Компонент отображения таблицы и фильтров с логами событий с устройств ОПС Болид.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      routes: {
        ROUTE_BOLID_SERVERS,
        ROUTE_BOLID_DEVICES
      },
      currentRoute: ROUTE_BOLID_EVENTS,
      rawFields: FIELDS_BOLID_EVENTS,
      columnCaptions: TITLES_FIELDS_BOLID_EVENTS,
      defaultFieldsTableForView: [
        FIELDS_BOLID_EVENTS.device_id,
        FIELDS_BOLID_EVENTS.event_date,
        FIELDS_BOLID_EVENTS.category,
        FIELDS_BOLID_EVENTS.event_description,
        FIELDS_BOLID_EVENTS.device_type,
        FIELDS_BOLID_EVENTS.comments,

      ],

      nameActionLoadDataForTable: `bolidEvents/${ACTION_LOAD_BOLID_EVENTS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `bolidEvents/${ACTION_LOAD_INFO_BOLID_EVENTS}`,
      defaultFilters: [
        new FilterData(FILTERS_BOLID_EVENTS.event_date, ">=", [defaultFilterByDate])
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_BY_FIELDS_BOLID_EVENTS[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
