<template>
  <div>
    <div class="row">
      <p>Подтверждение миграции</p>
    </div>
    <div class="row">
      <p>Подтверждение выполнения задачи на перенос камер между серверами.</p>
    </div>
  </div>
</template>

<script>
import {functionMultiEditMixin} from "@/utils/mixins.js";
import {ACTION_CONFIRM_MIGRATION_CAMERAS} from "@/store/cameras/index.js";
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";

/**
 * Компонент функции мультиредактора камер для подтверждения переноса камер.
 */
export default {
  mixins: [functionMultiEditMixin],
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     */
    changePromise() {
      this.$emit("change-promise", (entityKey) => {
        return this.$store
          .dispatch(`cameras/${ACTION_CONFIRM_MIGRATION_CAMERAS}`, {cameraNumber: entityKey})
          .then(() => ResultProcessingEntityMultiEdit.success(entityKey))
          .catch((error) => ResultProcessingEntityMultiEdit.errorRequest(entityKey, error));
      });
    },
  },
};
</script>
