/**
 * Отдельный vuex модуль для взаимодействия по integrations/devices-API
 */

import {cancelRequestOnReentry} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {SORT_DIRECTIONS} from "@/utils/consts.js";

// actions для servers/logs. Вызов действия начинать с "integrations/devices"
export const ACTION_LOAD_BOLID_EVENTS_FOR_TABLE = "LOAD_BOLID_EVENTS_FOR_TABLE";
export const ACTION_LOAD_BOLID_DEVICES = "LOAD_BOLID_DEVICES";
export const ACTION_LOAD_INFO_BOLID_DEVICES_TO_CAMERAS = "LOAD_INFO_BOLID_DEVICES_TO_CAMERAS";
export const ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS = "LOAD_BOLID_DEVICES_TO_CAMERAS";
export const ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS_FOR_EDIT = "LOAD_BOLID_DEVICES_TO_CAMERAS_FOR_EDIT";
export const ACTION_ADD_BOLID_DEVICES_TO_CAMERAS_BY_FORM = "ADD_BOLID_DEVICES_TO_CAMERAS_BY_FORM";
export const ACTION_DELETE_BOLID_DEVICES_TO_CAMERAS = "DELETE_BOLID_DEVICES_TO_CAMERAS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */

export const FIELDS_BOLID_DEVICES = Object.freeze( {
  server_id: "server_id",
  comment: "comment",
});

export const FIELDS_BOLID_DEVICE_TO_CAMERA = Object.freeze( {
  device_ids: "device_ids",
  camera_number: "camera_number"
});

export const EXTRAS_BOLID_DEVICE_TO_CAMERA = Object.freeze({
  // device: "device",
  camera: "camera",
});
export const FILTERS_DEVICE_BOLID_TO_CAMERA = Object.freeze({
  device_ids: "device_ids",
  camera_number: "camera_number",
});
const EXTRAS_BY_FIELDS_DEVICE_TO_CAMERA = Object.freeze({
  [FIELDS_BOLID_DEVICE_TO_CAMERA.camera_number]: [EXTRAS_BOLID_DEVICE_TO_CAMERA.camera],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async [ACTION_LOAD_BOLID_DEVICES](context, {serverId = null,cancelTokenKey = ""}) {

      try {
        const response = await this.getters.privateAjax.post("/v0/integrations/bolid/devices/", {
          server_id : serverId,
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        throw  Object.values(_.pick(error.response.data, 'error'));
      }
    },
    async [ACTION_LOAD_INFO_BOLID_DEVICES_TO_CAMERAS]() {
      const response = await this.getters.privateAjax.post("/v0/integrations/bolid/devices/cameras/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка камер прикрепленных к устройствам СКУД.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS](context, {page, pageSize, orderBy, fields, filters, search}) {

      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_DEVICE_TO_CAMERA, fields))),
        response = await this.getters.privateAjax.post("/v0/integrations/bolid/devices/cameras/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          extra,
          filters,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка камер для редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} deviceId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS_FOR_EDIT]({dispatch}, {deviceId = null , search = "", page = 1}) {
      let filter = makeFilterApi( FILTERS_DEVICE_BOLID_TO_CAMERA.device_ids,"=", deviceId);

      return dispatch(ACTION_LOAD_BOLID_DEVICES_TO_CAMERAS, {
        page,
        pageSize: 10,
        orderBy: [],
        fields: [
          FIELDS_BOLID_DEVICE_TO_CAMERA.camera_number,
          FIELDS_BOLID_DEVICE_TO_CAMERA.device_ids,
        ],
        filters: [filter],
        search
      });
    },
    /**
     * Добавление новой камеры к устройству
     *
     * @param {Object} context
     * @param {Array} deviceId
     * @param {Array} rawCamerasDirectionInfo [[camera_direction, camera_number], ...]
     * @return {Promise}
     */
    async [ACTION_ADD_BOLID_DEVICES_TO_CAMERAS_BY_FORM](context, {deviceId= null, cameraNumber}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/integrations/bolid/devices/cameras/add/", {
          device_ids: [deviceId],
          camera_number: cameraNumber
        });
        return response.data;
      } catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
    /**
     * Удаление камеры с устройства.
     *
     * @param {Object} context
     * @param {Number} deviceId
     * @param {Boolean} isAuto
     * @param {Array} cameraNumbers
     * @return {Promise}
     */
    async [ACTION_DELETE_BOLID_DEVICES_TO_CAMERAS](context, {deviceId, cameraNumbers}) {
      try {const response = await this.getters.privateAjax.post("/v0/integrations/bolid/devices/cameras/delete/", {
        device_id: deviceId,
        camera_number: cameraNumbers
      });
      return response.data;
      }  catch (error) {
        throw Object.values(error.response.data.fields).join(", ");
      }
    },
  }
};
