<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.name]"
        :caption="titlesFields[fieldsEntity.name]"
        :error="errorsFormEdit[fieldsEntity.name]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.title]"
        :caption="titlesFields[fieldsEntity.title]"
        :error="errorsFormEdit[fieldsEntity.title]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.about]"
        :caption="titlesFields[fieldsEntity.about]"
        :error="errorsFormEdit[fieldsEntity.about]"
        class="col"
        input-type="area"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.camera_group_type_id]"
        :caption="titlesFields[fieldsEntity.camera_group_type_id]"
        :error="errorsFormEdit[fieldsEntity.camera_group_type_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.camera_group_type_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.layout]"
        :caption="titlesFields[fieldsEntity.layout]"
        :error="errorsFormEdit[fieldsEntity.layout]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ACTION_CREATE_CAMERA_GROUP, FIELDS_CAMERA_GROUP, TITLES_FIELDS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {ROUTE_EDIT_CAMERA_GROUP} from "@/router/names.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT, FIELDS_CAMERA_GROUP_TYPE} from "@/store/cameraGroupTypes/index.js";

/**
 * Компонент диалога создания группы камер.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `cameraGroups/${ACTION_CREATE_CAMERA_GROUP}`,
      fieldsEntity: FIELDS_CAMERA_GROUP,
      titlesFields: TITLES_FIELDS_CAMERA_GROUP,
      formEdit: {
        [FIELDS_CAMERA_GROUP.name]: "",
        [FIELDS_CAMERA_GROUP.title]: "",
        [FIELDS_CAMERA_GROUP.about]: "",
        [FIELDS_CAMERA_GROUP.camera_group_type_id]: null,
        [FIELDS_CAMERA_GROUP.layout]: "",
      },
      settingsSelects: {
        [FIELDS_CAMERA_GROUP.camera_group_type_id]: {
          action: `cameraGroupTypes/${ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP_TYPE.id,
          labelField: FIELDS_CAMERA_GROUP_TYPE.name
        },
      }
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование группы камер.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_CAMERA_GROUP, params: {cameraGroupId: newEntity[FIELDS_CAMERA_GROUP.id]}};
    },
  },
};
</script>
