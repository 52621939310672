/**
 * Этот файл - главная точка подключения всех своих и сторонних js библиотек и стилей.
 * В статическом public/index.html размещен базовый HTML шаблон, в котором подключается приложение.
 * Здесь не описывается поведение компонентов, а просиходит только их загрузка.
 * Поведение главного компонента размещено в components/App.vue - начиная с него стартует вся логика.
 */
import Vue from "vue";

import {default as Vuedals} from "vuedals";
import Paginate from "vuejs-paginate";
import "flatpickr/dist/flatpickr.css";
import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue.js";
import {abilitiesPlugin} from "@casl/vue";
import {ABILITIES_ACTIONS, ABILITIES_SUBJECTS, ability} from "@/store/ability.js";
import store from "@/store/index.js";
import router from "@/router/index.js";
import "camsng-frontend-shared";

import {CONFIG_RAVEN_ENVIRONMENT, CONFIG_RAVEN_SENTRY_DSN} from "@/utils/consts.js";
import App from "@/components/App.vue";
import SmartInputAddress from "@/components/smartInputs/SmartInputAddress.vue";
import Notifications from "vue-notification";
import FloatingVue from 'floating-vue';
import "@/assets/sass/style.scss";
import 'floating-vue/dist/style.css';
// См подключение https://korigan.github.io/Vue2Leaflet/#/quickstart?id=in-a-webpack-rollup-build-system
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import iconRetinaUrl from "@/assets/img/icons/map-pointer.svg";
import iconUrl from "@/assets/img/icons/map-pointer.svg";
import {Icon} from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
  iconSize: [37, 52],
  iconAnchor: [18, 51],
  popupAnchor: [0, -47],
  shadowAnchor: [12, 39],
});

// Настройка Raven для отправки в Sentry ошибок по текущей версии в указанном окружении.
// В случае возникновения ошибки, перед ее отправкой, из текущего состояния возьмется имя пользователя
// и примешается к остальным данным по ошибке для идентификации на стороне Sentry.
if (CONFIG_RAVEN_SENTRY_DSN) {
  Raven
    .config(CONFIG_RAVEN_SENTRY_DSN, {environment: CONFIG_RAVEN_ENVIRONMENT})
    .addPlugin(RavenVue, Vue)
    .install();

  Raven.setDataCallback((error) => {
    error.user = {
      username: store.state.username
    };
  });
}
Vue.use(FloatingVue);
// Плагин с набором функций для организации контроля доступа.
Vue.use(Notifications);
Vue.use(abilitiesPlugin, ability);
// Добавление в глобальный объект Vue параметров для контроля доступа - или так или в каждый компонент надо пробрасывать эти константы.
// Используются в функциях и шаблонах - везде где надо проверить наличие прав.
Vue.prototype.$abilitiesActions = ABILITIES_ACTIONS;
Vue.prototype.$abilitiesSubjects = ABILITIES_SUBJECTS;

// Компонент постраничной навигации.
Vue.component("Paginate", Paginate);
// Компонент создания модальных окон.
Vue.use(Vuedals);
Vue.component("SmartInputAddress", SmartInputAddress);

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App),
});
