<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS}" class="tabs__item">
          Серверы аналитики
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CLUSTERS}" class="tabs__item">
          Кластеры серверов аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS_LOGS}" class="tabs__item">
          Логи серверов
        </router-link>
        <router-link :to="{name: routes.ROUTE_ROOT_ANALYTICS_SETTINGS}" class="tabs__item tabs__item_active">
          Настройка аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CAR_NUMBER_REPORT}" class="tabs__item">
          Отчеты
        </router-link>
      </div>
    </div>
    <div class="tabs_analytics">
      <router-link :to="{name: routes.ROUTE_CAR_NUMBERS}" class="tabs__item">
        Автомобили
      </router-link>
      <router-link :to="{name: routes.ROUTE_HELMETS}" class="tabs__item">
        Каски
      </router-link>
      <router-link :to="{name: routes.ROUTE_MOTION_ALARMS}" class="tabs__item">
        Движения
      </router-link>
      <router-link :to="{name: routes.ROUTE_MASKS}" class="tabs__item">
        Маски
      </router-link>
      <router-link :to="{name: routes.ROUTE_CROWDS}" class="tabs__item">
        Скопление людей
      </router-link>
      <router-link :to="{name: routes.ROUTE_PERIMETER_SECURITYS}" class="tabs__item">
        Вторжение в зону
      </router-link>
      <router-link :to="{name: routes.ROUTE_PEOPLE_COUNTS}" class="tabs__item">
        Подсчет посетителей
      </router-link>
      <router-link :to="{name: currentRoute}" class="tabs__item tabs__item_active">
        Тепловизоры
      </router-link>
      <router-link :to="{name: routes.ROUTE_FACE_RECOGNITIONS}" class="tabs__item">
        Распознавание лиц
      </router-link>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateThermalVision"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :default-filters="defaultFilters"
      :deleted-field="rawFields.is_deleted"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :key-field="rawFields.camera_number"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import CreateThermalVisionDialog from "@/components/analytics/thermalVisions/CreateThermalVisionDialog.vue";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_ANALYTIC_SERVERS_LOGS,
  ROUTE_CAR_NUMBERS,
  ROUTE_EDIT_THERMAL_VISION,
  ROUTE_FACE_RECOGNITIONS,
  ROUTE_HELMETS,
  ROUTE_MOTION_ALARMS,
  ROUTE_THERMAL_VISIONS,
  ROUTE_MASKS,
  ROUTE_CROWDS,
  ROUTE_PERIMETER_SECURITYS,
  ROUTE_PEOPLE_COUNTS,
  ROUTE_ANALYTIC_CLUSTERS,
  ROUTE_ANALYTIC_CAR_NUMBER_REPORT
} from "@/router/names.js";
import {
  ACTION_LOAD_INFO_THERMAL_VISIONS,
  ACTION_LOAD_THERMAL_VISIONS_FOR_TABLE,
  FIELDS_THERMAL_VISION,
  FILTERS_THERMAL_VISION,
  TITLES_FIELDS_THERMAL_VISION,
  TYPES_FIELDS_THERMAL_VISION
} from "@/store/analytics/thermalVisions/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import {FilterData} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по настройкам аналитик тепловизоров.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_ANALYTIC_SERVERS,
        ROUTE_CAR_NUMBERS,
        ROUTE_FACE_RECOGNITIONS,
        ROUTE_HELMETS,
        ROUTE_MOTION_ALARMS,
        ROUTE_ANALYTIC_SERVERS_LOGS,
        ROUTE_MASKS,
        ROUTE_CROWDS,
        ROUTE_PERIMETER_SECURITYS,
        ROUTE_PEOPLE_COUNTS,
        ROUTE_ANALYTIC_CLUSTERS,
        ROUTE_ANALYTIC_CAR_NUMBER_REPORT
      },
      currentRoute: ROUTE_THERMAL_VISIONS,
      rawFields: FIELDS_THERMAL_VISION,
      columnCaptions: TITLES_FIELDS_THERMAL_VISION,
      nameActionLoadDataForTable: `analytics/thermalVisions/${ACTION_LOAD_THERMAL_VISIONS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `analytics/thermalVisions/${ACTION_LOAD_INFO_THERMAL_VISIONS}`,
      defaultFilters: [
        new FilterData(FILTERS_THERMAL_VISION.is_deleted, "=", [false])
      ],
      defaultFieldsTableForView: [
        FIELDS_THERMAL_VISION.camera_number,
        FIELDS_THERMAL_VISION.push_domain,
        FIELDS_THERMAL_VISION.is_active,
        FIELDS_THERMAL_VISION.face_recognition,
        FIELDS_THERMAL_VISION.is_deleted,
      ],
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_THERMAL_VISION.camera_number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_THERMAL_VISION, params: {cameraNumber: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_THERMAL_VISION[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания настройки аналитики тепловизора.
     */
    openDialogCreateThermalVision() {
      this.$camsdals.open(
        CreateThermalVisionDialog,
        {},
        {dialogTitle: "Создание настройки аналитики тепловизора"},
        {},
      );
    },
  },
};
</script>
