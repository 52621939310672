<template>
  <div class="content__settings__body">
    <div class="content__table cams-table-wrapper">
      <table class="cams-table">
        <tr>
          <th class="cams-table__cell">
            {{ columnCaptions.camera_number }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.username }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.token_type }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.date }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.ip }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.user_agent }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.label }}
          </th>
        </tr>

        <tr v-if="isLoadingPage" class="cams-table__row-spinner">
          <td :colspan="7" class="cams-table__cell">
            <SpinnerLoading size="s" />
          </td>
        </tr>
        <tr v-for="(rowDataTable, rowDataTableIndex) in listDataTable" :key="rowDataTableIndex">
          <td class="cams-table__cell">
            <router-link :to="{name: routes.ROUTE_EDIT_CAMERA, params: {cameraNumber: rowDataTable[fieldsTable.camera_number]}}">
              {{ rowDataTable[fieldsTable.camera_number] }}
            </router-link>
          </td>
          <td class="cams-table__cell">
            <!--   todo пока непонятно куда редиректить при клике на пользователя - админы или клиенты         -->
            <router-link :to="{name: routes.ROUTE_EDIT_ADMIN, params: {adminId: rowDataTable[fieldsTable.user_id]}}">
              {{ rowDataTable[fieldsTable.username] }}
            </router-link>
            <router-link :to="{name: routes.ROUTE_EDIT_CLIENT, params: {clientId: rowDataTable[fieldsTable.user_id]}}">
              {{ rowDataTable[fieldsTable.username] }}
            </router-link>
          </td>
          <td class="cams-table__cell">
            {{ captionToken(rowDataTable) }}
          </td>
          <td :title="rowDataTable[fieldsTable.date] | localDateTimeZone" class="cams-table__cell">
            {{ rowDataTable[fieldsTable.date] | localDateTime }}
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.ip] }}
          </td>
          <td :title="rowDataTable[fieldsTable.user_agent]" class="cams-table__cell">
            <template v-if="rowDataTable[fieldsTable.user_agent]">
              {{ rowDataTable[fieldsTable.user_agent].replace(/^(.{20}[^\s]*).*/, "$1...") }}
            </template>
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.label] }}
          </td>
        </tr>
      </table>
    </div>

    <nav class="pagination">
      <paginate
        v-model="currentPage"
        :active-class="'pagination__list__item_active'"
        :break-view-class="'pagination__list__item_collapse'"
        :click-handler="selectPage"
        :container-class="'pagination__list'"
        :hide-prev-next="true"
        :page-class="'pagination__list__item'"
        :page-count="pageInfo.numPages"
        :page-range="5"
        next-text=""
        prev-text=""
      />
    </nav>
  </div>
</template>

<script>
import {logByCriteriaMixin} from "@/components/log/mixin.js";
import {TOKEN_TYPES} from "@/store/cameras/index.js";
import {FIELDS_LOG_VIEWS, TITLES_FIELDS_LOG_VIEWS} from "@/store/log/index.js";

/**
 * Несамостоятельный компонент для страниц отображения таблицы быстрого доступа к логам просмотров видео с камеры.
 * Необходимо передать критерий фильтрации по логам.
 */
export default {
  mixins: [logByCriteriaMixin],
  data() {
    return {
      fieldsTable: FIELDS_LOG_VIEWS,
      columnCaptions: TITLES_FIELDS_LOG_VIEWS,
    };
  },
  methods: {
    /**
     * Подпись к типу токена.
     *
     * @param {Object} rowDataTable
     * @return {String}
     */
    captionToken(rowDataTable) {
      const tokenType = rowDataTable[FIELDS_LOG_VIEWS.token_type],
            textToken = {
              [TOKEN_TYPES.L]: "Прямая трансляция",
              [TOKEN_TYPES.R]: "Архив",
              [TOKEN_TYPES.D]: "Скачивание",
            }[tokenType];
      let textAfter = "";

      if ((tokenType === TOKEN_TYPES.L) && rowDataTable[FIELDS_LOG_VIEWS.start]) {
        textAfter = ` ${this.$options.filters.localDateTime(rowDataTable[FIELDS_LOG_VIEWS.start])} (${rowDataTable[FIELDS_LOG_VIEWS.duration]} сек.)`;
      }

      return `${textToken}${textAfter}`;
    }
  },
};
</script>
