/**
 * Отдельный vuex модуль для взаимодействия по servers-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";

// actions для servers. Вызов действия начинать с "servers/"
export const ACTION_LOAD_INFO_SERVERS = "LOAD_INFO_SERVERS";
export const ACTION_LOAD_SERVERS = "LOAD_SERVERS";
export const ACTION_LOAD_SERVERS_FOR_TABLE = "LOAD_SERVERS_FOR_TABLE";
export const ACTION_LOAD_SERVERS_FOR_SELECT = "LOAD_SERVERS_FOR_SELECT";
export const ACTION_LOAD_SERVER_FOR_EDIT = "LOAD_SERVER_FOR_EDIT";
export const ACTION_LOAD_INFO_SERVER_HISTORY = "LOAD_INFO_SERVER_HISTORY";
export const ACTION_LOAD_SERVERS_HISTORY = "LOAD_SERVERS_HISTORY";
export const ACTION_LOAD_SERVER_HISTORY_FOR_TABLE = "LOAD_SERVERS_HISTORY_FOR_TABLE";
export const ACTION_CREATE_SERVER = "CREATE_SERVER";
export const ACTION_CREATE_SERVER_WITH_ALL = "CREATE_SERVER_WITH_ALL";
export const ACTION_EDIT_SERVER = "EDIT_SERVER";
export const ACTION_ADD_CLUSTER_SERVER = "ADD_CLUSTER_SERVER";
export const ACTION_DELETE_CLUSTER_SERVER = "DELETE_CLUSTER_SERVER";
export const ACTION_SET_CLUSTER_SERVER = "SET_CLUSTER_SERVER";
export const ACTION_DELETE_SERVERS = "DELETE_SERVERS";
export const ACTION_EDIT_SERVER_WITH_ALL = "EDIT_SERVER_WITH_ALL";
export const ACTION_DELETE_TARIFF_SERVER = "DELETE_TARIFF_SERVER";
export const ACTION_ADD_TARIFF_SERVER = "ADD_TARIFF_SERVER";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_SERVER = Object.freeze({
  id: "id",
  domain: "domain",
  screenshot_domain: "screenshot_domain",
  proxy_domain: "proxy_domain",
  max_cameras: "max_cameras",
  is_auto_allocation: "is_auto_allocation",
  is_managed: "is_managed",
  storage: "storage",
  disk_limit: "disk_limit",
  api_allowed_from: "api_allowed_from",
  api_allowed_from_ips_count: "api_allowed_from_ips_count",
  cluster_key: "cluster_key",
  migrate_limit: "migrate_limit",
  login: "login",
  password: "password",
  cluster_ids: "cluster_ids",
  cluster_count: "cluster_count",
  is_deleted: "is_deleted",
  sync_success: "sync_success",
  sync_last_success: "sync_last_success",
  sync_error_stage: "sync_error_stage",
  sync_error: "sync_error",
  cameras_count: "cameras_count",
  tariff_count: "tariff_count",
  tariff_ids: "tariff_ids",
  stats_version: "stats_version",
  stats_total_clients: "stats_total_clients",
  stats_memory_usage: "stats_memory_usage",
  stats_cpu_usage: "stats_cpu_usage",
  stats_output_kbit: "stats_output_kbit",
  stats_input_kbit: "stats_input_kbit",
  description: "description",
  vendor_name: "vendor_name",
  is_multistorage_enabled: "is_multistorage_enabled",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_SERVER = Object.freeze({
  id: "id",
  cluster_id: "cluster_id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей сервера.
 */
export const TITLES_FIELDS_SERVER = {
  [FIELDS_SERVER.id]: "ID",
  [FIELDS_SERVER.domain]: "Доменное имя",
  [FIELDS_SERVER.screenshot_domain]: "Домен для скриншотов",
  [FIELDS_SERVER.proxy_domain]: "Прокси домен",
  [FIELDS_SERVER.max_cameras]: "Максимум камер",
  [FIELDS_SERVER.is_auto_allocation]: "Автоматическое распределение камер",
  [FIELDS_SERVER.is_managed]: "Контролируемый",
  [FIELDS_SERVER.storage]: "Путь к архиву",
  [FIELDS_SERVER.disk_limit]: "Макс. использование диска (%)",
  [FIELDS_SERVER.api_allowed_from]: "Разрешенные IP адреса",
  [FIELDS_SERVER.api_allowed_from_ips_count]: "Кол-во разрешенных IP",
  [FIELDS_SERVER.cluster_key]: "Ключ кластера",
  [FIELDS_SERVER.migrate_limit]: "Ограничение активных задач миграции",
  [FIELDS_SERVER.login]: "Логин",
  [FIELDS_SERVER.password]: "Пароль",
  [FIELDS_SERVER.cluster_ids]: "Кластеры",
  [FIELDS_SERVER.cluster_count]: "Количество кластеров",
  [FIELDS_SERVER.is_deleted]: "Удален",
  [FIELDS_SERVER.sync_success]: "Успешность последней синхронизации",
  [FIELDS_SERVER.sync_last_success]: "Дата последней успешной синхронизации",
  [FIELDS_SERVER.sync_error_stage]: "Этап на котором произошла ошибка",
  [FIELDS_SERVER.sync_error]: "Ошибка",
  [FIELDS_SERVER.cameras_count]: "Количество камер",
  [FIELDS_SERVER.tariff_count]: "Количество тарифов",
  [FIELDS_SERVER.tariff_ids]: "Тарифы",
  [FIELDS_SERVER.stats_version]: "Версия",
  [FIELDS_SERVER.stats_total_clients]: "Число клиентов",
  [FIELDS_SERVER.stats_memory_usage]: "Использовано памяти %",
  [FIELDS_SERVER.stats_cpu_usage]: "Использовано CPU %",
  [FIELDS_SERVER.stats_output_kbit]: "Исходящая скорость kbps",
  [FIELDS_SERVER.stats_input_kbit]: "Входящая скорость kbps",
  [FIELDS_SERVER.description]: "Описание",
  [FIELDS_SERVER.vendor_name]: "Тип сервера",
  [FIELDS_SERVER.is_multistorage_enabled]: "Мультихранилище",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_SERVER = {
  [FIELDS_SERVER.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_SERVER.domain]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_SERVER.is_auto_allocation]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_SERVER.is_managed]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_SERVER.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_SERVER.cluster_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_SERVER.tariff_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_SERVER.sync_success]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_SERVER.sync_last_success]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_SERVER.is_multistorage_enabled]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};
export const VENDOR_NAME = ["Flussonic", "UMS"];

/**
 * Перечисления опций для загрузки дополнительной информации вместе с серверами.
 */
export const EXTRAS_SERVER = Object.freeze({
  cluster: "cluster",
  tariff: "tariff",
});

const EXTRAS_BY_FIELDS_SERVER = Object.freeze({
  [FIELDS_SERVER.cluster_ids]: [EXTRAS_SERVER.cluster],
  [FIELDS_SERVER.cluster_count]: [EXTRAS_SERVER.cluster],
  [FIELDS_SERVER.tariff_ids]: [EXTRAS_SERVER.tariff],
});
// Поля для использования истории по серверам

export const FIELDS_SERVER_HISTORY = Object.freeze({
  cluster_key : "cluster_key",
  description : "description",
  disk_limit : "disk_limit",
  domain : "domain",
  extra_id : "extra_id",
  hpk : "hpk",
  id : "id",
  is_auto_allocation : "is_auto_allocation",
  is_deleted : "is_deleted",
  is_managed : "is_managed",
  is_multistorage_enabled : "is_multistorage_enabled",
  login : "login",
  max_cameras : "max_cameras",
  migrate_limit : "migrate_limit",
  password : "password",
  proxy_domain : "proxy_domain",
  screenshot_domain : "screenshot_domain",
  storage : "storage",
  event_id : "event_id",
  history_create_date : "history_create_date",
  data_change_event_action : "data_change_event_action",
  data_change_event_comment : "data_change_event_comment",
  data_change_event_date : "data_change_event_date",
  data_change_event_front_id : "data_change_event_front_id",
  data_change_event_http_host : "data_change_event_http_host",
  data_change_event_id : "data_change_event_id",
  data_change_event_ip : "data_change_event_ip",
  data_change_event_path : "data_change_event_path",
  data_change_event_port : "data_change_event_port",
  data_change_event_user_agent : "data_change_event_user_agent",
  data_change_event_user_id : "data_change_event_user_id",
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_SERVER_HISTORY = Object.freeze({
  is_deleted: "is_deleted",
  data_change_event_date: "data_change_event_date",
  data_change_event_user_id: "data_change_event_user_id",
});


export const TITLES_FIELDS_SERVER_HISTORY = {
  [FIELDS_SERVER_HISTORY.cluster_key]: "Ключ кластера",
  [FIELDS_SERVER_HISTORY.description]: "Описание",
  [FIELDS_SERVER_HISTORY.disk_limit]: "Лимит диска",
  [FIELDS_SERVER_HISTORY.domain]: "Домен/IP",
  [FIELDS_SERVER_HISTORY.event_id]: "ID события",
  [FIELDS_SERVER_HISTORY.extra_id]:"Extra ID",
  [FIELDS_SERVER_HISTORY.hpk]: "ID Записи в истории",
  [FIELDS_SERVER_HISTORY.id]: "ID",
  [FIELDS_SERVER_HISTORY.is_auto_allocation]: "Авто-распределение активно?",
  [FIELDS_SERVER_HISTORY.is_deleted]: "Запись удалена?",
  [FIELDS_SERVER_HISTORY.is_managed]: "Управляемая?",
  [FIELDS_SERVER_HISTORY.is_multistorage_enabled]: "Мультиранилище",
  [FIELDS_SERVER_HISTORY.login]: "Имя пользователя",
  [FIELDS_SERVER_HISTORY.max_cameras]: "Максимальное количество камер",
  [FIELDS_SERVER_HISTORY.migrate_limit]: "Ограничение активных задач миграции",
  [FIELDS_SERVER_HISTORY.password]: "Пароль",
  [FIELDS_SERVER_HISTORY.proxy_domain]: "Прокси домен/IP",
  [FIELDS_SERVER_HISTORY.screenshot_domain]: "Домен/IP для скриншотов",
  [FIELDS_SERVER_HISTORY.storage]:"Путь к архиву",
  [FIELDS_SERVER_HISTORY.history_create_date]: "Дата создания истории",
  [FIELDS_SERVER_HISTORY.data_change_event_action]: "Действие",
  [FIELDS_SERVER_HISTORY.data_change_event_comment]: "Комментарий",
  [FIELDS_SERVER_HISTORY.data_change_event_date]: "Время события",
  [FIELDS_SERVER_HISTORY.data_change_event_front_id]: "Front ID, с которого пришло изменение",
  [FIELDS_SERVER_HISTORY.data_change_event_http_host]: "HTTP-host",
  [FIELDS_SERVER_HISTORY.data_change_event_id]: "ID события",
  [FIELDS_SERVER_HISTORY.data_change_event_ip]: "IP-адрес, с которого пришли изменения",
  [FIELDS_SERVER_HISTORY.data_change_event_path]: "Путь к запрашиваемому ресурсу",
  [FIELDS_SERVER_HISTORY.data_change_event_port]: "Порт, с которого пришли изменения",
  [FIELDS_SERVER_HISTORY.data_change_event_user_agent]: "User-agent",
  [FIELDS_SERVER_HISTORY.data_change_event_user_id]: "ID пользователя",
};
export const EXTRAS_SERVER_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_SERVER_HISTORY = Object.freeze({
  [FIELDS_SERVER_HISTORY.data_change_event_user_id]: [EXTRAS_SERVER_HISTORY.user],

});
export const TYPES_FIELDS_SERVER_HISTORY = {
  [FIELDS_SERVER_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_SERVER_HISTORY.is_managed]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_SERVER_HISTORY.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для серверов.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_SERVERS]() {
      const response = await this.getters.privateAjax.post("/v0/servers/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка серверов.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_SERVERS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_SERVER, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/servers/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка серверов для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_SERVERS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_SERVERS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_SERVERS_FOR_TABLE});
    },
    /**
     * Загрузка списка серверов для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных серверов.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_SERVERS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_SERVER.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_SERVERS, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_SERVERS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного сервера для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} serverId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_SERVER_FOR_EDIT]({dispatch}, [serverId, fields]) {
      const filter = makeFilterApi(FILTERS_SERVER.id, "=", serverId),
        responseData = await dispatch(ACTION_LOAD_SERVERS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового сервера.
     *
     * @param {Object} context
     * @param {Object} serverInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_SERVER](context, serverInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/create/", serverInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Создание нового сервера с добавлением тарифов.
     *
     * @param {Function} dispatch
     * @param {Object} serverInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_SERVER_WITH_ALL]({dispatch}, serverInfo) {
      const newEntity = await dispatch(ACTION_CREATE_SERVER, serverInfo);

      if (!_.isEmpty(serverInfo[FIELDS_SERVER.tariff_ids])) {
        try {
          await dispatch(ACTION_ADD_TARIFF_SERVER, {
            serverId: newEntity[FIELDS_SERVER.id],
            tariffIds: serverInfo[FIELDS_SERVER.tariff_ids],
          });
        } catch (error) {
          // todo сервер создался но при сохранении тарифов что-то пошло не так
        }
      }
      return newEntity;
    },
    /**
     * Редактирование существующего сервера.
     *
     * @param {Object} context
     * @param {Object} serverInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_SERVER](context, serverInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/edit/", serverInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего сервера с редактированием привязанных тарифов и кластеров.
     * В рамках редактирования допускается изменение тарифов, но это изменение по API должно проходить отдельными запросами,
     * поэтому в случае успешного удаления и добавления тарифов происходит сохранение основных параметров,
     * и возвращается уже промис последнего запроса - общего редактирования.
     *
     * @param {Function} dispatch
     * @param {Object} serverInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_SERVER_WITH_ALL]({dispatch}, serverInfo) {
      const [tariffsForDelete, tariffsForAdd] = serverInfo[FIELDS_SERVER.tariff_ids];
      if (!_.isEmpty(tariffsForDelete)) {
        await dispatch(ACTION_DELETE_TARIFF_SERVER, {
          serverId: serverInfo[FIELDS_SERVER.id],
          tariffIds: tariffsForDelete,
        });
      }
      if (!_.isEmpty(tariffsForAdd)) {
        await dispatch(ACTION_ADD_TARIFF_SERVER, {
          serverId: serverInfo[FIELDS_SERVER.id],
          tariffIds: tariffsForAdd,
        });
      }
      await dispatch(ACTION_SET_CLUSTER_SERVER, {
        serverId: serverInfo[FIELDS_SERVER.id],
        clusterIds: serverInfo[FIELDS_SERVER.cluster_ids],
      });
      return dispatch(ACTION_EDIT_SERVER, serverInfo);
    },
    /**
     * Добавление тарифов к серверу.
     *
     * @param {Object} context
     * @param {Number} serverId
     * @param {Array<Number>} tariffIds
     * @return {Promise}
     */
    async [ACTION_ADD_TARIFF_SERVER](context, {serverId, tariffIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/tariffs/add/", {
          server_id: serverId,
          tariff_ids: tariffIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление тарифов из сервера.
     *
     * @param {Object} context
     * @param {Number} serverId
     * @param {Array<Number>} tariffIds
     * @return {Promise}
     */
    async [ACTION_DELETE_TARIFF_SERVER](context, {serverId, tariffIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/tariffs/delete/", {
          server_id: serverId,
          tariff_ids: tariffIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Добавление кластеров к серверу.
     *
     * @param {Object} context
     * @param {Number} serverId
     * @param {Array<Number>} clusterIds
     * @return {Promise}
     */
    async [ACTION_ADD_CLUSTER_SERVER](context, {serverId, clusterIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/clusters/add/", {
          server_id: serverId,
          cluster_ids: clusterIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление кластеров из сервера.
     *
     * @param {Object} context
     * @param {Number} serverId
     * @param {Array<Number>} clusterIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CLUSTER_SERVER](context, {serverId, clusterIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/clusters/delete/", {
          server_id: serverId,
          cluster_ids: clusterIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Изменение кластеров у сервера.
     *
     * @param {Object} context
     * @param {Number} serverId
     * @param {Array<Number>} clusterIds
     * @return {Promise}
     */
    async [ACTION_SET_CLUSTER_SERVER](context, {serverId, clusterIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/clusters/set/", {
          server_id: serverId,
          cluster_ids: clusterIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление серверов.
     *
     * @param {Object} context
     * @param {Array<Number>} serverIds
     * @return {Promise}
     */
    async [ACTION_DELETE_SERVERS](context, {serverIds}) {
      return this.getters.privateAjax.post("/v0/servers/delete/", {ids: serverIds});
    },
    /**
     * Загрузка служебной информации для истории изменения сервера.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_SERVER_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/servers/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории изменения камер.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_SERVERS_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_SERVER_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;

        });
      try {
        const response = await this.getters.privateAjax.post("/v0/servers/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка камер для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_SERVER_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_SERVERS_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_SERVER_HISTORY_FOR_TABLE});
    },
  },
};
