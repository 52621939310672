/**
 * Константы для использования во всем проекте.
 */

/**
 * Браузерный заголовок приложения.
 */
export const APPLICATION_TITLE = process.env.VUE_APP_TITLE;
/**
 * URL для тайлов карты.
 */
export const MAP_TILES_URL = process.env.VUE_APP_MAP_TILES_URL;
/**
 * Тип данных для ячеек в таблице, чтобы применять для нужных полей сущностей определенный формат их представления.
 */
export const SPECIAL_TYPES_DATA_IN_CELLS = Object.freeze({
  BOOLEAN: "BOOLEAN",
  TEXT: "TEXT",
  DATE_TIME: "DATE_TIME",
  DATE: "DATE",
  ROUTE: "ROUTE",
  ARRAY: "ARRAY",
  IMG: "IMG",
});
/**
 * Базовый URL передается в собранное приложение через переменную окружения VUE_APP_AUTH_TOKEN.
 */
export const CONFIG_BASE_URL = process.env.VUE_APP_API_BASE_PATH;
/**
 * Возможность авторизации через токен опциональная, может определяться при сборке.
 */
export const CONFIG_IS_AUTH_VIA_TOKEN = process.env.NODE_ENV === "development";
export const CONFIG_AJAX_TIMEOUT = 30000;
export const CONFIG_AJAX_PREFIX_HEADER_AUTH = "Bearer ";
/**
 * Настройка соединения с Sentry.
 */
export const CONFIG_RAVEN_SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
/**
 * Настройка обозначения окружения для передачи запросов в Sentry.
 */
export const CONFIG_RAVEN_ENVIRONMENT = process.env.NODE_ENV;
/**
 * Параметры сортировки.
 */
export const SORT_DIRECTIONS = Object.freeze({
  ASC: "asc",
  DESC: "desc"
});
/**
 * Стандартный размер списка загружаемых элементов для таблиц.
 */
export const DEFAULT_PAGE_SIZE_FOR_TABLE = 20;
/**
 * Стандартный размер списка загружаемых элементов для элементов выбора.
 */
export const DEFAULT_PAGE_SIZE_FOR_SELECT = 5;
/**
 * Стандартный размер списка загружаемых элементов для карты.
 */
export const DEFAULT_PAGE_SIZE_FOR_MAP = 1000;
/**
 * Имя GET параметра (ключа) в URL строке.
 * Используется в случаях доступа к таблицам данных разных сущностей,
 * в котором размещаются все настройки для фильтрации данных и их отображения.
 *
 * Доступ к таблице идет по общему компоненту для всех сущностей, поэтому для унификации использования параметр вынесен в константы
 * и везде отслеживается, где нужно организовать переход к какой-либо таблице.
 * А в самой таблице параметры считываются и перекрывают собой дефолтные настройки.
 */
export const NAME_QUERY_PARAM_FOR_TABLE = "p";
/**
 * Минимальное количество секунд для скачивания видео в плеере. См. API запросов токена на скачивание.
 */
export const MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER = 20;
/**
 * Максимальное количество секунд для скачивания видео в плеере. См. API запросов токена на скачивание.
 */
export const MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER = 28800; // 8 часов.
/**
 * Максимальное количество строк в таблице над которыми допускается производить экспорт в CSV.
 */
export const MAX_ENTITIES_FOR_EXPORT_TO_CSV = 100000;

// todo shared
/**
 * Перечисление доступных размеров для скриншотов.
 *
 * @type {Readonly<{"200": number, "400": number, "600": number}>}
 */
export const CAMERA_SCREENSHOT_SIZES = Object.freeze({
  s200: "200",
  s400: "400",
  s600: "600",
});
