<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Группа камер
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.name)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.name]"
                    :caption="titlesFields[fieldsEntity.name]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.name)"
                    :error="errorsFormEdit[fieldsEntity.name]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.title)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.title]"
                    :caption="titlesFields[fieldsEntity.title]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.title)"
                    :error="errorsFormEdit[fieldsEntity.title]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.about)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.about]"
                    :caption="titlesFields[fieldsEntity.about]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.about)"
                    :error="errorsFormEdit[fieldsEntity.about]"
                    class="col"
                    input-type="area"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.layout)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.layout]"
                    :caption="titlesFields[fieldsEntity.layout]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.layout)"
                    :error="errorsFormEdit[fieldsEntity.layout]"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA_GROUP)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA_GROUP)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.CAMERA_GROUP)"
                        type="button"
                        @click="deleteCameraGroup()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA_GROUP)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)"
                        type="button"
                        @click="togglePublicCameraGroup()"
                      >
                        {{ isPublic ? 'Убрать из публичных' : 'Сделать публичной' }}
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA) && $can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsCamera.camera_group_ids)"
                        type="button"
                        @click="openDialogAddCameraToCameraGroup()"
                      >
                        Добавить камеру в группу
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP_PERMISSION)"
                        type="button"
                        @click="openDialogSetPermissions()"
                      >
                        Изменить права пользователей
                      </CamsButton>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.camera_group_type_id)">
                  {{ titlesFields[fieldsEntity.camera_group_type_id] }}:
                  <strong>
                    <router-link
                      :to="{name: routes.ROUTE_CAMERA_GROUP_TYPE, params: {cameraGroupTypeId: sourceData.entityInfo[fieldsEntity.camera_group_type_id]}}"
                    >
                      {{ cameraGroupType }}
                    </router-link>
                  </strong>
                </p>

                <br>
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP, fieldsEntity.cameras_count)">
                  <strong>
                    <router-link :to="routeCamerasInGroup">
                      Посмотреть все камеры в этой группе ({{ sourceData.entityInfo[fieldsEntity.cameras_count] }})
                    </router-link>
                  </strong>
                </p>

                <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP_PERMISSION)">
                  Количество записей прав для группы камер:
                  <strong>{{ countPermissionsByUsers }}</strong>
                </p>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ACTION_DELETE_CAMERA_GROUPS,
  ACTION_EDIT_CAMERA_GROUP,
  ACTION_LOAD_CAMERA_GROUP_FOR_EDIT,
  ACTION_SET_PUBLIC_CAMERA_GROUPS,
  EXTRAS_CAMERA_GROUP,
  FIELDS_CAMERA_GROUP,
  TITLES_FIELDS_CAMERA_GROUP
} from "@/store/cameraGroups/index.js";
import {ROUTE_EDIT_CAMERA_GROUP_TYPE, ROUTE_CAMERA_GROUPS, ROUTE_CAMERAS} from "@/router/names.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import SetPermissionsForCameraGroupsDialog from "@/components/permissions/SetPermissionsForCameraGroupsDialog.vue";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {FIELDS_CAMERA, FILTERS_CAMERA} from "@/store/cameras/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import AddCameraToCameraGroupDialog from "@/components/cameraGroups/AddCameraToCameraGroupDialog.vue";
import {ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP} from "@/store/permissions/index.js";

/**
 * Компонент страницы редактирования группы камер.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const cameraGroupId = Number(this.$route.params.cameraGroupId),
          paramsRouteCamerasInGroup = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA.camera_group_id, "=", [cameraGroupId])]}),
          routeCamerasInGroup = {name: ROUTE_CAMERAS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCamerasInGroup.stringify()}};

    return {
      routeBack: {name: ROUTE_CAMERA_GROUPS},
      fieldsEntity: FIELDS_CAMERA_GROUP,
      titlesFields: TITLES_FIELDS_CAMERA_GROUP,
      entityId: cameraGroupId,
      nameActionLoadDataForEdit: `cameraGroups/${ACTION_LOAD_CAMERA_GROUP_FOR_EDIT}`,
      nameActionEdit: `cameraGroups/${ACTION_EDIT_CAMERA_GROUP}`,
      deletedField: FIELDS_CAMERA_GROUP.is_deleted,

      routes: {
        ROUTE_CAMERA_GROUP_TYPE: ROUTE_EDIT_CAMERA_GROUP_TYPE,
      },
      routeCamerasInGroup: routeCamerasInGroup,
      fieldsCamera: FIELDS_CAMERA,
      countPermissionsByUsers: 0,
    };
  },
  computed: {
    /**
     * Вернет текущее состояние публичности группы камер.
     *
     * @return {Boolean}
     */
    isPublic() {
      return this.sourceData.entityInfo[FIELDS_CAMERA_GROUP.is_public];
    },
    /**
     * Вернет название типа группы камер.
     *
     * @return {String}
     */
    cameraGroupType() {
      return _.head(this.sourceData.extraInfo[EXTRAS_CAMERA_GROUP.camera_group_type]).name;
    },
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.CAMERA_GROUP_PERMISSION)) {
        this.countPermissionsByUsers = await this.$store.dispatch(`permissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA_GROUP}`, {cameraGroupId: this.entityId});
      }
    },
    /**
     * Переключение публичности группы камер.
     */
    async togglePublicCameraGroup() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameraGroups/${ACTION_SET_PUBLIC_CAMERA_GROUPS}`, {cameraGroupId: this.entityId, isPublic: !this.isPublic});
      } catch {
        this.$camsdals.alert("Ошибка изменения публичности");
      }
      this.isLoading = false;
      this.loadSourceData();
    },
    /**
     * Отправка запроса на удаление текущей группы камер.
     */
    deleteCameraGroup() {
      this.$camsdals.confirm("Хотите удалить эту группу камер?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`cameraGroups/${ACTION_DELETE_CAMERA_GROUPS}`, {cameraGroupIds: [this.entityId]});
          this.$camsdals.alert("Группа камер удалена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении группы камер");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открытие формы для изменения прав клиентов на группу камер.
     */
    openDialogSetPermissions() {
      this.$camsdals.open(
        SetPermissionsForCameraGroupsDialog,
        {cameraGroupId: this.entityId},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"},
      );
    },
    /**
     * Открытие диалогового окна для добавления камеры в текущую группу.
     */
    openDialogAddCameraToCameraGroup() {
      this.$camsdals.open(
        AddCameraToCameraGroupDialog,
        {cameraGroupId: this.entityId},
        {dialogTitle: "Добавление камеры в группу камер"},
        {
          onClose: (needReload) => needReload ? this.loadSourceData() : null
        },
      );
    },
  },
};
</script>
