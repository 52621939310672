<template>
  <div class="content__settings__body">
    <div class="content__table cams-table-wrapper">
      <table class="cams-table">
        <tr>
          <th class="cams-table__cell">
            {{ columnCaptions.camera_number }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.username }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.token_type }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.date }}
          </th>
          <th class="cams-table__cell">
            {{ columnCaptions.reason }}
          </th>
        </tr>

        <tr v-if="isLoadingPage" class="cams-table__row-spinner">
          <td :colspan="5" class="cams-table__cell">
            <SpinnerLoading size="s" />
          </td>
        </tr>
        <tr v-for="(rowDataTable, rowDataTableIndex) in listDataTable" :key="rowDataTableIndex">
          <td class="cams-table__cell">
            <router-link :to="{name: routes.ROUTE_EDIT_CAMERA, params: {cameraNumber: rowDataTable[fieldsTable.camera_number]}}">
              {{ rowDataTable[fieldsTable.camera_number] }}
            </router-link>
          </td>
          <td class="cams-table__cell">
            <router-link :to="{name: routes.ROUTE_EDIT_ADMIN, params: {adminId: rowDataTable[fieldsTable.user_id]}}">
              {{ rowDataTable[fieldsTable.username] }}
            </router-link>
          </td>
          <td class="cams-table__cell">
            {{ captionToken(rowDataTable[fieldsTable.token_type]) }}
          </td>
          <td :title="rowDataTable[fieldsTable.date] | localDateTimeZone" class="cams-table__cell">
            {{ rowDataTable[fieldsTable.date] | localDateTime }}
          </td>
          <td class="cams-table__cell">
            {{ rowDataTable[fieldsTable.reason] }}
          </td>
        </tr>
      </table>
    </div>

    <nav class="pagination">
      <paginate
        v-model="currentPage"
        :active-class="'pagination__list__item_active'"
        :break-view-class="'pagination__list__item_collapse'"
        :click-handler="selectPage"
        :container-class="'pagination__list'"
        :hide-prev-next="true"
        :page-class="'pagination__list__item'"
        :page-count="pageInfo.numPages"
        :page-range="5"
        next-text=""
        prev-text=""
      />
    </nav>
  </div>
</template>

<script>
import {logByCriteriaMixin} from "@/components/log/mixin.js";
import {FIELDS_LOG_CAMERA_WATCH_REASONS, TITLES_FIELDS_LOG_CAMERA_WATCH_REASONS} from "@/store/log/index.js";

/**
 * Несамостоятельный компонент для страниц отображения таблицы быстрого доступа к логам запросов доступа к камере.
 * Необходимо передать критерий фильтрации по логам.
 */
export default {
  mixins: [logByCriteriaMixin],
  data() {
    return {
      fieldsTable: FIELDS_LOG_CAMERA_WATCH_REASONS,
      columnCaptions: TITLES_FIELDS_LOG_CAMERA_WATCH_REASONS,
    };
  },
};
</script>
