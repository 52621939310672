<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Тип группы камер
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
            <a class="tabs__item" href="#">История изменений</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP_TYPE, fieldsEntity.name)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.name]"
                    :caption="titlesFields[fieldsEntity.name]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA_GROUP_TYPE, fieldsEntity.name)"
                    :error="errorsFormEdit[fieldsEntity.name]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP_TYPE, fieldsEntity.is_unique)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_unique]"
                    :caption="titlesFields[fieldsEntity.is_unique]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA_GROUP_TYPE, fieldsEntity.is_unique)"
                    :error="errorsFormEdit[fieldsEntity.is_unique]"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA_GROUP_TYPE)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA_GROUP_TYPE)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.CAMERA_GROUP_TYPE)"
                        type="button"
                        @click="deleteCameraGroupType()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA_GROUP_TYPE)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA_GROUP_TYPE, fieldsEntity.camera_groups_count)">
                  <strong>
                    <router-link :to="routeCameraGroupsInType">
                      Посмотреть все группы по данному типу ({{ sourceData.entityInfo[fieldsEntity.camera_groups_count] }})
                    </router-link>
                  </strong>
                </p>
              </div>
            </div>
          </form>
          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {
  ACTION_DELETE_CAMERA_GROUP_TYPES,
  ACTION_EDIT_CAMERA_GROUP_TYPE,
  ACTION_LOAD_CAMERA_GROUP_TYPE_FOR_EDIT,
  FIELDS_CAMERA_GROUP_TYPE,
  TITLES_FIELDS_CAMERA_GROUP_TYPE
} from "@/store/cameraGroupTypes/index.js";
import {ROUTE_CAMERA_GROUP_TYPES, ROUTE_CAMERA_GROUPS} from "@/router/names.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {FILTERS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";

/**
 * Компонент страницы редактирования типа группы камер.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const cameraGroupTypeId = Number(this.$route.params.cameraGroupTypeId),
          paramsRouteCameraGroupsInType = new TableQueryParams({filters: [new FilterData(FILTERS_CAMERA_GROUP.camera_group_type_id, "=", [cameraGroupTypeId])]}),
          routeCameraGroupsInType = {name: ROUTE_CAMERA_GROUPS, query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCameraGroupsInType.stringify()}};

    return {
      routeBack: {name: ROUTE_CAMERA_GROUP_TYPES},
      fieldsEntity: FIELDS_CAMERA_GROUP_TYPE,
      titlesFields: TITLES_FIELDS_CAMERA_GROUP_TYPE,
      entityId: cameraGroupTypeId,
      nameActionLoadDataForEdit: `cameraGroupTypes/${ACTION_LOAD_CAMERA_GROUP_TYPE_FOR_EDIT}`,
      nameActionEdit: `cameraGroupTypes/${ACTION_EDIT_CAMERA_GROUP_TYPE}`,
      deletedField: FIELDS_CAMERA_GROUP_TYPE.is_deleted,

      routeCameraGroupsInType: routeCameraGroupsInType,
    };
  },
  methods: {
    /**
     * Отправка запроса на удаление текущего типа группы камер.
     */
    deleteCameraGroupType() {
      this.$camsdals.confirm("Хотите удалить этот тип группы камер?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`cameraGroupTypes/${ACTION_DELETE_CAMERA_GROUP_TYPES}`, {cameraGroupTypeIds: [this.entityId]});
          this.$camsdals.alert("Тип группы камер удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении типа группы камеры");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
