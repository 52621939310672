<template>
  <div class="user-links-setter">
    <SmartVSelect
      v-model="newClientId"
      :settings-remote-search="settingsSelectClient"
      placeholder="Введите логин для новой привязки"
    />
    <CamsButton
      :disabled="!newClientId"
      priority="primary"
      type="button"
      @click="addUserLink()"
    >
      Добавить
    </CamsButton>

    <SmartInputText
      v-model="searchByUsername"
      class=" user-links-setter__search"
      default-if-empty=""
      placeholder="Введите логин для поиска связей с другими клиентами"
      @input="debouncedLoadUserLinks()"
    />

    <SpinnerLoading v-if="isLoading" size="s" />

    <div v-else-if="dataRows.length > 0">
      <div class="smart-list">
        <div class="smart-list__row smart-list__row_header">
          <div>Клиент</div>
        </div>
        <div
          v-for="(dataRow, rowIndex) in dataRows"
          :key="`row-${rowIndex}`"
          :class="{'smart-list__row_pale': (dataRow.clientId === parentUserId) || (dataRow.clientId === childUserId)}"
          class="smart-list__row"
        >
          <router-link :to="{name: routes.ROUTE_EDIT_CLIENT, params: {clientId: dataRow.clientId}}" target="_blank">
            {{ dataRow.username }}
          </router-link>
          <CamsButton
            v-show="!((dataRow.clientId === parentUserId) || (dataRow.clientId === childUserId))"
            class="smart-list__button-icon"
            icon-type="only"
            title="Удалить"
            type="button"
            @click="deleteUserLink(dataRow.clientId)"
          >
            <svg>
              <use xlink:href="../../../../assets/img/icons.svg#close" />
            </svg>
          </CamsButton>
        </div>
      </div>


      <nav class="pagination user-links-setter__pagination">
        <paginate
          v-model="currentPage"
          :active-class="'pagination__list__item_active'"
          :break-view-class="'pagination__list__item_collapse'"
          :click-handler="loadUserLinks"
          :container-class="'pagination__list'"
          :hide-prev-next="true"
          :page-class="'pagination__list__item'"
          :page-count="pageInfo.numPages"
          :page-range="5"
          next-text=""
          prev-text=""
        />
      </nav>

      <span>Найдено: {{ pageInfo.count }}</span>
    </div>

    <div v-else>
      <p>Пользователей не найдено.</p>
    </div>
  </div>
</template>

<script>
import {
  ACTION_ADD_USER_LINKS,
  ACTION_DELETE_USER_LINKS,
  ACTION_LOAD_CLIENTS_FOR_SELECT,
  ACTION_LOAD_USER_LINK_FOR_EDIT,
  FIELDS_CLIENT,
  FIELDS_USER_LINK
} from "@/store/users/clients/index.js";
import {ROUTE_EDIT_CLIENT} from "@/router/names.js";

/**
 * Компонент установки связи между пользователем, указанным в parentUserId, либо в childUserId и другими пользователями.
 */
export default {
  props: {
    /**
     * Идентификатор пользователя, для которого будут отображаться привязки, в которых он является основным.
     */
    parentUserId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор пользователя, для которого будут отображаться привязки, в которых он является подчиненным.
     */
    childUserId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      routes: {
        ROUTE_EDIT_CLIENT,
      },
      isLoading: false,
      dataRows: [],
      searchByUsername: "",
      currentPage: 1,
      pageInfo: {
        numPages: 0,
        count: 0,
      },
      newClientId: null,
      settingsSelectClient: {
        action: `clients/${ACTION_LOAD_CLIENTS_FOR_SELECT}`,
        valueField: FIELDS_CLIENT.id,
        labelField: FIELDS_CLIENT.username
      },
    };
  },
  computed: {
    /**
     * Вернет true для режима, в котором устанавливаются подчиненные связи для переданного пользователя-родителя.
     *
     * @return {Boolean}
     */
    modeForParent() {
      return this.parentUserId !== null;
    },
  },
  created() {
    this.debouncedLoadUserLinks = _.debounce(this.loadUserLinks, 350);
    this.loadUserLinks();
  },
  methods: {
    /**
     * Загрузит информацию по привязкам.
     *
     * @param {Number} currentPage
     */
    async loadUserLinks(currentPage = 1) {
      this.currentPage = this.currentPage === currentPage ? this.currentPage : currentPage;

      this.isLoading = true;
      try {
        const responseData = await this.$store.dispatch(`clients/${ACTION_LOAD_USER_LINK_FOR_EDIT}`, {
          // Основной фильтр зависит от режима работы компонента.
          ...(this.modeForParent ? {parentUserId: this.parentUserId} : {childUserId: this.childUserId}),
          searchByUsername: this.searchByUsername,
          page: currentPage
        });

        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.dataRows = responseData.results.map((rawUserLinkInfo) => {
          return {
            clientId: this.modeForParent ? rawUserLinkInfo[FIELDS_USER_LINK.child_user_id] : rawUserLinkInfo[FIELDS_USER_LINK.parent_user_id],
            username: this.modeForParent ? rawUserLinkInfo[FIELDS_USER_LINK.child_username] : rawUserLinkInfo[FIELDS_USER_LINK.parent_username],
            date: rawUserLinkInfo[FIELDS_USER_LINK.date],
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Отправка запроса на добавление связи между пользователями.
     */
    async addUserLink() {
      let parentUserId = this.newClientId,
          childUserId = this.childUserId;
      if (this.modeForParent) {
        parentUserId = this.parentUserId;
        childUserId = this.newClientId;
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(`clients/${ACTION_ADD_USER_LINKS}`, {parentUserId, childUserId});
        this.newClientId = null;
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.loadUserLinks();
      this.isLoading = false;
    },
    /**
     * Отправка запроса на удаление связи между пользователями.
     *
     * @param {Number} clientId
     */
    async deleteUserLink(clientId) {
      let parentUserId = clientId,
          childUserId = this.childUserId;
      if (this.modeForParent) {
        parentUserId = this.parentUserId;
        childUserId = clientId;
      }

      this.$camsdals.confirm("Хотите удалить эту связь между пользователями?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`clients/${ACTION_DELETE_USER_LINKS}`, {parentUserId, childUserId});
        } catch (error) {
          this.$camsdals.alert(`Произошла ошибка: ${error}`);
        }
        this.loadUserLinks();
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "camsng-frontend-shared/styles/vars.scss";

.user-links-setter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1vh;

  &__search {
    margin-top: 2vh;
  }

  &__pagination {
    margin: 1vh;
  }
}

// todo постраничный список инфы вынести в шару, заменить то что было access-list
.smart-list {
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;
    border-bottom: 1px solid $cams-color-alto-dark;
    font: 14px $cams-font-roboto-regular;

    // Заголовки списка пожирнее.
    &_header {
      font: 14px $cams-font-roboto-medium;
    }

    // Бледный модификатор для выделения некоторых строк на фоне остальной массы.
    &_pale {
      font-family: $cams-font-roboto-light;
      color: $cams-color-emperor;
    }
  }

  &__button-icon {
    width: 12px;
    height: 12px;
    border: none !important;
    margin-right: 12px;
    overflow: hidden;
  }
}
</style>
