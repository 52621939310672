import { render, staticRenderFns } from "./TabsEditorAdmin.vue?vue&type=template&id=87b4be66"
import script from "./TabsEditorAdmin.vue?vue&type=script&lang=js"
export * from "./TabsEditorAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports