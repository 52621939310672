<template>
  <div class="page-inner">
    <aside class="sidebar">
      <nav class="sidebar__nav sidebar-nav">
        <router-link class="sidebar-nav__logo" to="/">
          <svg class="sidebar-nav__logo-icon">
            <use xlink:href="../assets/img/icons.svg#logo" />
          </svg>
          <span class="sidebar-nav__logo-text">{{ logoText }}</span>
        </router-link>
        <ul class="sidebar-nav__menu">
          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_CAMERAS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#video" />
              </svg>
              Камеры
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP) || $can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP_TYPE)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_ROOT_CAMERA_GROUPS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#folder" />
              </svg>
              Группы камер
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.USER_CLIENT) || $can($abilitiesActions.READ_COMMON, $abilitiesSubjects.USER_ADMIN)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_ROOT_USERS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#user" />
              </svg>
              Пользователи
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.SERVER) || $can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CLUSTER)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_ROOT_HEAVY_METAL}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#server" />
              </svg>
              Серверы
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.TARIFF)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_TARIFFS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#tag" />
              </svg>
              Тарифы
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.SUPER_SUBJECT)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_DOMAIN_SETTINGS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#settings" />
              </svg>
              Доменные настройки
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.RIGHT_GROUP)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_RIGHT_GROUPS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#right-groups" />
              </svg>
              Группы прав
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_AUTH) || $can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_VIEW) || $can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_CAMERA_WATCH_REASON)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_ROOT_LOGS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#logs" />
              </svg>
              Логи
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.ANALYTIC)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_ROOT_ANALYTICS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#analytic" />
              </svg>
              Аналитика
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_ON_MAP}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#map" />
              </svg>
              Карта
            </router-link>
          </li>
          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.EMPLOYEE)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_DEVICES}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#pacs" />
              </svg>
              Скуд
            </router-link>
          </li>

          <li
            v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.GANG)"
            class="sidebar-nav__menu__item"
          >
            <router-link :to="{name: routes.ROUTE_GANGS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#company" />
              </svg>
              Компании
            </router-link>
            <router-link :to="{name: routes.ROUTE_BOLID_SERVERS}">
              <svg class="sidebar__icon">
                <use xlink:href="../assets/img/icons.svg#integrations" />
              </svg>
              Интеграции
            </router-link>
          </li>
        </ul>
      </nav>

      <MenuProfile />
    </aside>

    <main class="main">
      <router-view />
    </main>
  </div>
</template>

<script>
import MenuProfile from "@/components/MenuProfile.vue";
import {
  ROUTE_CAMERAS,
  ROUTE_CARS,
  ROUTE_DOMAIN_SETTINGS,
  ROUTE_DEVICES,
  ROUTE_GANGS,
  ROUTE_ON_MAP,
  ROUTE_RIGHT_GROUPS,
  ROUTE_ROOT,
  ROUTE_ROOT_ANALYTICS,
  ROUTE_ROOT_CAMERA_GROUPS,
  ROUTE_ROOT_HEAVY_METAL,
  ROUTE_ROOT_LOGS,
  ROUTE_ROOT_USERS,
  ROUTE_TARIFFS,
  ROUTE_BOLID_SERVERS
} from "@/router/names.js";
import {APPLICATION_TITLE} from "@/utils/consts.js";

/**
 * Общий компонент с главным меню и блоком контента, который заполняется в зависимости от работы роутера.
 *
 * Не самым чистым способом обеспечивается переход на первую доступную ссылку в меню,
 * при условии что пользователь целенаправленно попал на этот компонент.
 */
export default {
  components: {
    MenuProfile,
  },
  data() {
    return {
      logoText: APPLICATION_TITLE,
      routes: {
        ROUTE_CAMERAS,
        ROUTE_ROOT_CAMERA_GROUPS,
        ROUTE_ON_MAP,
        ROUTE_ROOT_HEAVY_METAL,
        ROUTE_TARIFFS,
        ROUTE_ROOT_USERS,
        ROUTE_DOMAIN_SETTINGS,
        ROUTE_RIGHT_GROUPS,
        ROUTE_ROOT_LOGS,
        ROUTE_ROOT_ANALYTICS,
        ROUTE_DEVICES,
        ROUTE_GANGS,
        ROUTE_CARS,
        ROUTE_BOLID_SERVERS
      },
    };
  },
  watch: {
    $route(to) {
      const elementFirstLink = document.querySelectorAll(".sidebar-nav__menu a")[0];
      (to.name === ROUTE_ROOT) && elementFirstLink && elementFirstLink.click();
    },
  },
  mounted() {
    const elementFirstLink = document.querySelectorAll(".sidebar-nav__menu a")[0];
    (this.$route.name === ROUTE_ROOT) && elementFirstLink && elementFirstLink.click();
  }
};
</script>
