/**
 * Настройка для библиотеки регулирования правами доступа `ability`.
 *
 * https://github.com/stalniy/casl
 */

import {FIELDS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";
import {FIELDS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {FIELDS_CAMERA} from "@/store/cameras/index.js";
import {MUTATION_RESET_STATE, MUTATION_SET_CONTEXT} from "@/store/mutations.js";
import {FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {Ability} from "@casl/ability";
import {FIELDS_CAR_NUMBER} from "@/store/analytics/carNumbers/index.js";
import {FIELDS_FACE_RECOGNITION} from "@/store/analytics/faceRecognitions/index.js";
import {FIELDS_ANALYTIC_SERVER_LOGS} from "@/store/analytics/serverLogs/index.js";
import {FIELDS_DEVICE, FIELDS_MODULE} from "@/store/pacs/devices/index.js";

/**
 * Перечисление всех возможных операций, требующих регуляции.
 */
export const ABILITIES_ACTIONS = Object.freeze({
  // Операция создания и удаления сущности.
  CREATE: "CREATE",
  // Операции чтения удаления по полям - используются при фильтрации перечня полей для корректной передачи их списка в API.
  READ_FIELD: "READ_FIELD",
  UPDATE_FIELD: "UPDATE_FIELD",
  // Операция общего чтения/обновления - определяет возможность чтения общей информации по сущности и доступ к страницам для чтения.
  // Специальный маркер нужен чтобы не опираться на доступность чтений определенных полей, но косвенно с связан с этой доступностью,
  // Поэтому ниже при расшифровке серверных прав необходимо назначить это право в тех местах где есть чтение/редактирование
  // "в общем" (без привязки к полям) и в местах где используются права на ключевые поля.
  READ_COMMON: "READ_COMMON",
  UPDATE_COMMON: "UPDATE_COMMON",
  // Специальные действия.
  RESET_SALT: "RESET_SALT",
  ACTIVATE: "ACTIVATE",
  LIVE_REASON: "LIVE_REASON",
  DVR_REASON: "DVR_REASON",
  BLOCKING: "BLOCKING",
  RESTART_STREAM: "RESTART_STREAM",
  UPDATE_DEVICE_GANG: "UPDATE_DEVICE_GANG",
  // Только для случаев использования суперами, потому что по действию отдельные регуляции не предусмотрены.
  SUPER_ACTION: "SUPER_ACTION",
});

/**
 * Перечисление всех возможных типов объектов, по которым регулируется доступ.
 */
export const ABILITIES_SUBJECTS = Object.freeze({
  CAMERA: "CAMERA",
  CAMERA_PERMISSION: "CAMERA_PERMISSION",
  CAMERA_GROUP: "CAMERA_GROUP",
  CAMERA_GROUP_PERMISSION: "CAMERA_GROUP_PERMISSION",
  CAMERA_GROUP_TYPE: "CAMERA_GROUP_TYPE",
  USER_CLIENT: "USER_CLIENT",
  USER_ADMIN: "USER_ADMIN",
  TARIFF: "TARIFF",
  SERVER: "SERVER",
  CLUSTER: "CLUSTER",
  RIGHT_GROUP: "RIGHT_GROUP",
  LOG_CAMERA_WATCH_REASON: "LOG_CAMERA_WATCH_REASON",
  LOG_VIEW: "LOG_VIEW",
  LOG_AUTH: "LOG_AUTH",
  // Аналитика.
  ANALYTIC_SERVER: "ANALYTIC_SERVER",
  ANALYTIC_SERVER_LOG: "ANALYTIC_SERVER_LOG",
  ANALYTIC:"ANALYTIC",
  // СКУД - PACS.
  GANG: "GANG",
  EMPLOYEE: "EMPLOYEE",
  DEVICE: "DEVICE",
  DEVICE_PERMISSION: "DEVICE_PERMISSION",
  DEVICE_ACCESS_GROUP: "DEVICE_ACCESS_GROUP",
  DEVICE_ACCESS_GROUP_PERMISSION: "DEVICE_ACCESS_GROUP_PERMISSION",
  // Только для случаев использования суперами, потому что по сущности отдельные регуляции не предусмотрены.
  SUPER_SUBJECT: "SUPER_SUBJECT",
  BOLID_SERVER: "BOLID_SERVER",
  BOLID_DEVICE: "BOLID_DEVICE",
  BOLID_EVENTS: "BOLID_EVENTS",
  MULTI_DOWNLOAD_BUTTON: "MULTI_DOWNLOAD_BUTTON",
  MULTI_EDIT_BUTTON: "MULTI_EDIT_BUTTON",
  DOWNLOAD_CSV_BUTTON: "DOWNLOAD_CSV_BUTTON",
  LOG_UCAMSGO_VIEW: "LOG_UCAMSGO_VIEW",
  CAMERA_SETUP: "CAMERA_SETUP",
});

/**
 * Перечисление прав на действия в служебном интерфейсе для назначения их персоналу.
 *
 * Замечание по камерам - для этой сущности регулируется доступ к просмотру и редактированию определенных полей,
 * и ограничение по ряду полей заставляет на стороне клиента определять "все остальные" поля по которым доступ разрешен по умолчанию.
 * Это нужно в первую очередь для того чтобы при работе с таблицами формировать корректный перечень доступных полей,
 * т.к. если запросить запрещенные поля сущности - вернется ошибка даже без разрешенных данных.
 * Да - в отдельных сценариях можно было бы просто проверять наличие определенного права, но таблица - исключительный случай
 * и поэтому хочется систематизировать все знания о правах и использовать для проверки общую абстракцию.
 *
 * Нужно иметь в виду, что "все остальные" поля надо разделять на те что можно в принципе редактировать
 * и те что вообще-то являются вычисляемыми - и их редактировать в принципе нельзя.
 * Часть полей сгруппирована, а доступ к обновлению некоторых полей подразумевает доступ к определенным действиям.
 *
 * Плюс ко всему наличие права смотреть информацию по одной таблице является основанием смотреть информацию,
 * связанную с этой сущностью, в другой таблице. И наоборот - если права на просмотр таблицы нет,
 * то и в другой таблице нельзя увидеть связанную с этим информацию.
 *
 * Замечание по серверам - доступ к ряду полей определяется на основе наличия права обновления, поэтому права объединены в массив,
 * а на этапе выдачи прав в плагине они разворачиваются в плоский набор объектов.
 *
 * Замечание по обновлению сущностей - фактически первичные ключи не обновляются, но наличие разрешения на обновление такого поля
 * может послужить ключевым разрешением на доступность проведения обновления.
 * Это актуально для сущностей для которых есть разграничение доступа по полям.
 */
export const ABILITIES_ADMINS = Object.freeze({
  "camera:create": [
    {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.CAMERA},
    {
      action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
        FIELDS_CAMERA.delete_date,
      ]
    }
  ],
  "camera:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CAMERA},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
        FIELDS_CAMERA.number,
        FIELDS_CAMERA.address,
        FIELDS_CAMERA.title,
        FIELDS_CAMERA.timezone,
        FIELDS_CAMERA.longitude,
        FIELDS_CAMERA.latitude,
        FIELDS_CAMERA.delete_date,
        FIELDS_CAMERA.is_sounding,
        FIELDS_CAMERA.create_date,
        FIELDS_CAMERA.is_deleted,
        FIELDS_CAMERA.tariff_id,
        FIELDS_CAMERA.inactivity_period_id,
        FIELDS_CAMERA.is_public,
        FIELDS_CAMERA.dadata_qc,
        FIELDS_CAMERA.dadata_qc_geo,
        FIELDS_CAMERA.dadata_success,
        FIELDS_CAMERA.dadata_error,
        FIELDS_CAMERA.blocking_lvl,
        FIELDS_CAMERA.blocking_end_date,
        FIELDS_CAMERA.ip,
        FIELDS_CAMERA.analytics,
        FIELDS_CAMERA.analytics_count,
        FIELDS_CAMERA.stats_alive,
        FIELDS_CAMERA.stats_last_alive,
        FIELDS_CAMERA.stats_date,
        FIELDS_CAMERA.stats_bitrate,
        FIELDS_CAMERA.stats_client_count,
        FIELDS_CAMERA.stats_source_error,
        FIELDS_CAMERA.gang_id,
        FIELDS_CAMERA.marker_id,
      ]
    },
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA_GROUP, fields: [
        FIELDS_CAMERA_GROUP.camera_numbers,
      ]
    },
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC, fields: [
        FIELDS_CAR_NUMBER.camera_number,
        FIELDS_CAR_NUMBER.push_domain,
        FIELDS_CAR_NUMBER.is_active,
        FIELDS_CAR_NUMBER.is_deleted,
        FIELDS_CAR_NUMBER.analytic_server_id,
        FIELDS_CAR_NUMBER.min_confidence,
        FIELDS_CAR_NUMBER.image_height,
        FIELDS_CAR_NUMBER.image_width,
        FIELDS_CAR_NUMBER.zones,
      ]
    },
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC, fields: [
        FIELDS_FACE_RECOGNITION.camera_number,
        FIELDS_FACE_RECOGNITION.push_domain,
        FIELDS_FACE_RECOGNITION.is_active,
        FIELDS_FACE_RECOGNITION.is_deleted,
        FIELDS_FACE_RECOGNITION.analytic_server_id,
        FIELDS_FACE_RECOGNITION.image_height,
        FIELDS_FACE_RECOGNITION.image_width,
        FIELDS_FACE_RECOGNITION.zones,
      ]
    },
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC},
  ],
  "camera:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.CAMERA},
    {
      action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
        FIELDS_CAMERA.number,
      ]
    },
  ],
  "camera.embed:read": {
    action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.is_embed, FIELDS_CAMERA.embed_hosts, FIELDS_CAMERA.embed_hosts_count
    ]
  },
  "camera.embed:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.is_embed, FIELDS_CAMERA.embed_hosts
    ]
  },
  "camera.is_managed:read": {
    action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.is_managed
    ]
  },
  "camera.is_managed:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.is_managed
    ]
  },
  "camera.server_id:read": {
    action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.server_id,
      FIELDS_CAMERA.camera_migration_task_id,
      FIELDS_CAMERA.camera_migration_task_status,
      FIELDS_CAMERA.camera_migration_task_server_id,
    ]
  },
  "camera.server_id:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.server_id
    ]
  },
  "camera.inventory:read": {
    action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.inventory_model,
      FIELDS_CAMERA.inventory_serial_number,
      FIELDS_CAMERA.inventory_mac,
      FIELDS_CAMERA.inventory_address_full,
      FIELDS_CAMERA.inventory_address_region,
      FIELDS_CAMERA.inventory_address_area,
      FIELDS_CAMERA.inventory_address_city,
      FIELDS_CAMERA.inventory_address_city_area,
      FIELDS_CAMERA.inventory_address_city_district,
      FIELDS_CAMERA.inventory_address_settlement,
      FIELDS_CAMERA.inventory_address_street,
      FIELDS_CAMERA.inventory_address_house_type,
      FIELDS_CAMERA.inventory_address_house,
    ]
  },
  "camera.inventory:update": { // При чтении используются поля камеры - при обновлении нужно использовать отдельное действие.
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.inventory_model,
      FIELDS_CAMERA.inventory_serial_number,
      FIELDS_CAMERA.inventory_mac,
      FIELDS_CAMERA.inventory_address_full,
      FIELDS_CAMERA.inventory_address_region,
      FIELDS_CAMERA.inventory_address_area,
      FIELDS_CAMERA.inventory_address_city,
      FIELDS_CAMERA.inventory_address_city_area,
      FIELDS_CAMERA.inventory_address_city_district,
      FIELDS_CAMERA.inventory_address_settlement,
      FIELDS_CAMERA.inventory_address_street,
      FIELDS_CAMERA.inventory_address_house_type,
      FIELDS_CAMERA.inventory_address_house,

    ]
  },
  "camera.streams:read": {
    action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.streams, FIELDS_CAMERA.streams_count, FIELDS_CAMERA.screenshot_url,
    ]
  },
  "camera.streams:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.streams, FIELDS_CAMERA.screenshot_url, FIELDS_CAMERA.ip,
    ]
  },
  "camera.group:read": {
    action: ABILITIES_ACTIONS.READ_FIELD,
    subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.camera_group_ids, FIELDS_CAMERA.camera_groups_count
    ]
  },
  "camera.gang_id:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.gang_id
    ]
  },
  "camera.title:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.title
    ]
  },
  "camera.tariff:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.tariff_id
    ]
  },
  "camera.marker:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.marker_id
    ]
  },
  "camera.timezone:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.timezone
    ]
  },
  "camera.sound:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA,
    fields: [
      FIELDS_CAMERA.is_sounding
    ]
  },
  "camera:restart_stream": {
    action: ABILITIES_ACTIONS.RESTART_STREAM, subject: ABILITIES_SUBJECTS.CAMERA,
  },
  "camera.group:update": { // По факту это поле нельзя редактировать - но можно изменить его через отдельные действия.
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.camera_group_ids
    ]
  },
  "camera.location:update": {
    action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA, fields: [
      FIELDS_CAMERA.address, FIELDS_CAMERA.longitude, FIELDS_CAMERA.latitude
    ]
  },
  "camera:reset_salt": {action: ABILITIES_ACTIONS.RESET_SALT, subject: ABILITIES_SUBJECTS.CAMERA},
  "camera:activate": {action: ABILITIES_ACTIONS.ACTIVATE, subject: ABILITIES_SUBJECTS.CAMERA},
  "camera.live_reason": {action: ABILITIES_ACTIONS.LIVE_REASON, subject: ABILITIES_SUBJECTS.CAMERA},
  "camera.dvr_reason": {action: ABILITIES_ACTIONS.DVR_REASON, subject: ABILITIES_SUBJECTS.CAMERA},
  "camera:blocking": {action: ABILITIES_ACTIONS.BLOCKING, subject: ABILITIES_SUBJECTS.CAMERA},

  "camera_permission:read": {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_PERMISSION},

  "camera_group:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.CAMERA_GROUP},
  "camera_group:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_GROUP},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA_GROUP, fields: [
        FIELDS_CAMERA_GROUP.id,
        FIELDS_CAMERA_GROUP.name,
        FIELDS_CAMERA_GROUP.title,
        FIELDS_CAMERA_GROUP.about,
        FIELDS_CAMERA_GROUP.is_deleted,
        FIELDS_CAMERA_GROUP.is_public,
        FIELDS_CAMERA_GROUP.camera_group_type_id,
        FIELDS_CAMERA_GROUP.layout,
        FIELDS_CAMERA_GROUP.cameras_count,
      ]
    }
  ],
  "camera_group:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_GROUP},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA_GROUP}
  ],

  "camera_group_permission:read": {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_GROUP_PERMISSION},

  "camera_group_type:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE},
  "camera_group_type:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE}
  ],
  "camera_group_type:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA_GROUP_TYPE}
  ],

  "user_client:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.USER_CLIENT},
  "user_client:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.USER_CLIENT},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.USER_CLIENT}
  ],
  "user_client:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.USER_CLIENT},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.USER_CLIENT}
  ],
  "user_client:reset_salt": {action: ABILITIES_ACTIONS.RESET_SALT, subject: ABILITIES_SUBJECTS.USER_CLIENT},

  "user_admin:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.USER_ADMIN},
  "user_admin:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.USER_ADMIN},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.USER_ADMIN}
  ],
  "user_admin:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.USER_ADMIN},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.USER_ADMIN}
  ],
  "user_admin:reset_salt": {action: ABILITIES_ACTIONS.RESET_SALT, subject: ABILITIES_SUBJECTS.USER_ADMIN},

  "tariff:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.TARIFF},
  "tariff:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.TARIFF},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.TARIFF}
  ],
  "tariff:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.TARIFF},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.TARIFF}
  ],

  "server:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.SERVER},
  "server:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.SERVER},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.SERVER, fields: [
        FIELDS_SERVER.id,
        FIELDS_SERVER.domain,
        FIELDS_SERVER.screenshot_domain,
        FIELDS_SERVER.proxy_domain,
        FIELDS_SERVER.max_cameras,
        FIELDS_SERVER.is_auto_allocation,
        FIELDS_SERVER.is_managed,
        FIELDS_SERVER.storage,
        FIELDS_SERVER.disk_limit,
        FIELDS_SERVER.cluster_ids,
        FIELDS_SERVER.is_deleted,
        FIELDS_SERVER.cameras_count,
        FIELDS_SERVER.tariff_count,
        FIELDS_SERVER.tariff_ids,
      ]
    }
  ],
  "server:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.SERVER},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.SERVER},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.SERVER, fields: [
        FIELDS_SERVER.api_allowed_from,
        FIELDS_SERVER.api_allowed_from_ips_count,
        FIELDS_SERVER.cluster_key,
        FIELDS_SERVER.migrate_limit,
        FIELDS_SERVER.login,
        FIELDS_SERVER.password,
        FIELDS_SERVER.sync_success,
        FIELDS_SERVER.sync_last_success,
        FIELDS_SERVER.sync_error_stage,
        FIELDS_SERVER.sync_error,
        FIELDS_SERVER.stats_version,
        FIELDS_SERVER.stats_total_clients,
        FIELDS_SERVER.stats_memory_usage,
        FIELDS_SERVER.stats_cpu_usage,
        FIELDS_SERVER.stats_output_kbit,
        FIELDS_SERVER.stats_input_kbit,
      ]
    }
  ],

  "cluster:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.CLUSTER},
  "cluster:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CLUSTER},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CLUSTER}
  ],
  "cluster:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.CLUSTER},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CLUSTER}
  ],
  "analytic_cluster:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.CLUSTER},
  "analytic_cluster:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CLUSTER},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.CLUSTER}
  ],
  "analytic_cluster:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.CLUSTER},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CLUSTER}
  ],

  "right_group:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.RIGHT_GROUP},
  "right_group:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.RIGHT_GROUP},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.RIGHT_GROUP}
  ],
  "right_group:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.RIGHT_GROUP},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.RIGHT_GROUP}
  ],

  "log_camera_watch_reason:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.LOG_CAMERA_WATCH_REASON},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.LOG_CAMERA_WATCH_REASON}
  ],
  "log_auth:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.LOG_AUTH},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.LOG_AUTH}
  ],
  "log_view:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.LOG_VIEW},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.LOG_VIEW}
  ],

  "analytic_server:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER},
  "analytic_server:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER, fields: [
        FIELDS_ANALYTIC_SERVER.id,
        FIELDS_ANALYTIC_SERVER.front_id,
        FIELDS_ANALYTIC_SERVER.ips,
        FIELDS_ANALYTIC_SERVER.ips_count,
        FIELDS_ANALYTIC_SERVER.car_number_is_active,
        FIELDS_ANALYTIC_SERVER.helmet_is_active,
        FIELDS_ANALYTIC_SERVER.thermal_vision_is_active,
        FIELDS_ANALYTIC_SERVER.thermal_vision_workers,
        FIELDS_ANALYTIC_SERVER.face_recognition_is_active,
        FIELDS_ANALYTIC_SERVER.face_recognition_workers,
        FIELDS_ANALYTIC_SERVER.feature_computation_is_active,
        FIELDS_ANALYTIC_SERVER.feature_computation_workers,
        FIELDS_ANALYTIC_SERVER.is_deleted,
        FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers,
        FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers_count,
        FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers,
        FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers_count,
        FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers,
        FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers_count,
        FIELDS_ANALYTIC_SERVER.sync_data,
      ]
    },
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER_LOG},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER_LOG, fields: [
        FIELDS_ANALYTIC_SERVER_LOGS.id,
        FIELDS_ANALYTIC_SERVER_LOGS.date,
        FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_id,
        FIELDS_ANALYTIC_SERVER_LOGS.analytic_server_front_id,
        FIELDS_ANALYTIC_SERVER_LOGS.camera_number,
        FIELDS_ANALYTIC_SERVER_LOGS.text,
        FIELDS_ANALYTIC_SERVER_LOGS.analytic_type,
        FIELDS_ANALYTIC_SERVER_LOGS.lvl,
      ]
    }
  ],
  "analytic_server:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER},
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER, fields: [
        FIELDS_ANALYTIC_SERVER.sync_success,
        FIELDS_ANALYTIC_SERVER.sync_last_success,
        FIELDS_ANALYTIC_SERVER.sync_error_stage,
        FIELDS_ANALYTIC_SERVER.sync_face_recognition_is_alive,
        FIELDS_ANALYTIC_SERVER.sync_error,
        FIELDS_ANALYTIC_SERVER.sync_data,
      ]
    },
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER, fields: [
        FIELDS_FACE_RECOGNITION.sync_success,
        FIELDS_FACE_RECOGNITION.sync_date,
        FIELDS_FACE_RECOGNITION.sync_last_success,
        FIELDS_FACE_RECOGNITION.sync_error,
        FIELDS_FACE_RECOGNITION.sync_data,
      ]
    },
    {
      action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC_SERVER, fields: [
        FIELDS_CAR_NUMBER.sync_success,
        FIELDS_CAR_NUMBER.sync_date,
        FIELDS_CAR_NUMBER.sync_last_success,
        FIELDS_CAR_NUMBER.sync_error,
        FIELDS_CAR_NUMBER.sync_data,
      ]
    },
  ],

  "gang:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.GANG},
  "gang:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.GANG},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.GANG}
  ],
  "gang:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.GANG},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.GANG},
  ],

  "employee:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.EMPLOYEE},
  "employee:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.EMPLOYEE},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.EMPLOYEE}
  ],
  "employee:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.EMPLOYEE},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.EMPLOYEE},
  ],

  "device:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.DEVICE},
  "device:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.DEVICE},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.DEVICE}
  ],
  "device:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.DEVICE},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.DEVICE, fields: [
      FIELDS_DEVICE.id,
      FIELDS_DEVICE.title,
      FIELDS_DEVICE.mac,
      FIELDS_DEVICE.model,
      FIELDS_DEVICE.ident,
      FIELDS_DEVICE.ban_period,
      FIELDS_MODULE.open,
      FIELDS_MODULE.adjust,
      FIELDS_MODULE.loop0_camera_number,
      FIELDS_MODULE.loop1_camera_number,
      FIELDS_MODULE.loop0_mode,
      FIELDS_MODULE.loop1_mode,


    ]},
  ],
  "device_gang:update": [
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.DEVICE},
    {action: ABILITIES_ACTIONS.UPDATE_DEVICE_GANG, subject: ABILITIES_SUBJECTS.DEVICE},
  ],
  "device_permission:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.DEVICE_PERMISSION},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.DEVICE_PERMISSION}
  ],
  "device_access_group:create": {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP},
  "device_access_group:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP}
  ],
  "device_access_group:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP},
  ],
  "device_access_group_permission:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP_PERMISSION},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.DEVICE_ACCESS_GROUP_PERMISSION}
  ],
  "bolid_device:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.BOLID_DEVICE},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.BOLID_DEVICE}
  ],
  "bolid_device:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.BOLID_DEVICE},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.BOLID_DEVICE},
  ],
  "bolid_device:create": [
    {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.BOLID_DEVICE},
  ],
  "bolid_events:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.BOLID_EVENTS},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.BOLID_EVENTS}
  ],
  "bolid_server:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.BOLID_SERVER},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.BOLID_SERVER}
  ],
  "bolid_server:create": [
    {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.BOLID_SERVER},
  ],
  "bolid_server:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.BOLID_SERVER},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.BOLID_SERVER}
  ],
  "ucamsgo_logs:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.LOG_UCAMSGO_VIEW},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.LOG_UCAMSGO_VIEW}
  ],
  "analytic:create": [
    {action: ABILITIES_ACTIONS.CREATE, subject: ABILITIES_SUBJECTS.ANALYTIC},
  ],
  "analytic:update": [
    {action: ABILITIES_ACTIONS.UPDATE_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC}
  ],
  "analytic:read": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.ANALYTIC},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.ANALYTIC}
  ],
  "camera:multi_download": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.MULTI_DOWNLOAD_BUTTON},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.MULTI_DOWNLOAD_BUTTON}
  ],
  "camera:multi_edit": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.MULTI_EDIT_BUTTON},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.MULTI_EDIT_BUTTON}
  ],
  "camera:download_csv": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.DOWNLOAD_CSV_BUTTON},
    {action: ABILITIES_ACTIONS.READ_FIELD, subject: ABILITIES_SUBJECTS.DOWNLOAD_CSV_BUTTON}
  ],
  "camera_setup:setup": [
    {action: ABILITIES_ACTIONS.READ_COMMON, subject: ABILITIES_SUBJECTS.CAMERA_SETUP},
    {action: ABILITIES_ACTIONS.UPDATE_FIELD, subject: ABILITIES_SUBJECTS.CAMERA_SETUP}
  ],
});

/**
 * Перечисление прав для супер администраторов.
 */
const ABILITIES_SUPER_ADMIN = [{action: "manage", subject: "all"}];

/**
 * Экземпляр Ability с кастомными изменениями.
 *
 * @type {Ability}
 */
export const ability = new Ability();

/**
 * В плагине для vuex настраивается поведение при котором права определяются в момент получения таковой информации из контекста,
 * а сбрасываются в момент сброса состояния.
 */
export const abilityPlugin = (store) => {
  ability.update(store.state.context.isSuperuser ? ABILITIES_SUPER_ADMIN : _.flatten(Object.values(_.pick(ABILITIES_ADMINS, store.state.context.permissions))));

  return store.subscribe((mutation) => {
    switch (mutation.type) {
    case MUTATION_SET_CONTEXT:
      ability.update(mutation.payload.is_superuser ? ABILITIES_SUPER_ADMIN : _.flatten(Object.values(_.pick(ABILITIES_ADMINS, mutation.payload.permissions))));
      break;
    case MUTATION_RESET_STATE:
      ability.update([]);
      break;
    }
  });
};
