<template>
  <td class="cams-table__cell">
    <template v-if="typesData.BOOLEAN === currentDataCell.type">
      <div :class="[currentDataCell.value ? 'indicator_available': 'indicator_inaccessible']" class="indicator" />
    </template>

    <template v-else-if="typesData.DATE_TIME === currentDataCell.type">
      <span :title="currentDataCell.value | localDateTimeZone">{{ currentDataCell.value | localDateTime }}</span>
    </template>

    <template v-else-if="typesData.DATE === currentDataCell.type">
      {{ currentDataCell.value | localDate }}
    </template>
    <template v-else-if="typesData.IMG === currentDataCell.type && currentDataCell.value">
      <a @click="handleImageClick">
        <svg class="icon icon-screen-preview">
          <use xlink:href="@/assets/img/icons.svg#icon-screen-preview" />
        </svg>
      </a>
    </template>

    <template v-else-if="typesData.ROUTE === currentDataCell.type">
      <router-link :to="currentDataCell.params">
        {{ currentDataCell.value }}
      </router-link>
    </template>

    <template v-else-if="(typesData.ARRAY === currentDataCell.type) && dataArray">
      <div v-html="dataArray.elementsForShow" />
      <router-link v-if="dataArray.link" :to="dataArray.link.to">
        {{ dataArray.link.text }}
      </router-link>
    </template>

    <template v-else>
      {{ currentDataCell.value }}
    </template>
  </td>
</template>

<script>
import {SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {ACTION_REQUEST_TOKEN} from "@/store/cameras/index.js";

/**
 * Компонент для отрисовки данных в ячейках таблицы.
 *
 * Данные могут быть сложными и для отображения некоторых (например ссылок) необходимо использовать компоненты.
 * Для принимаемых данных необходимо придерживаться структуры для параметра currentDataCell.
 */
export default {
  props: {
    /**
     * Стандартная структура для входных данных определяет их тип, значение и дополнительные параметры.
     *
     * @return {{type: null, params: {}, value: string}}
     */
    currentDataCell: {
      type: Object,
      default() {
        return {
          type: null,
          value: "",
          params: {},
        };
      }
    },
  },
  data() {
    return {
      token: null,
      typesData: SPECIAL_TYPES_DATA_IN_CELLS
    };
  },
  methods: {
    //костыль для отображения скриншота в отчетах по аналике распознавания номеров.
    async handleImageClick() {
      if (this.currentDataCell.type === this.typesData.IMG && this.currentDataCell.value) {
        const url = this.currentDataCell.value;
        const regex = /\/\d{4}-\d{2}-\d{2}\/([\w]+)(?=\/)/;
        const match = url.match(regex);
        const cameraNumber = match[1];
        try {
          const result = await this.$store.dispatch(`cameras/${ACTION_REQUEST_TOKEN}`, {
            camerasNumbers: [cameraNumber],
            tokenType: "L",
            reason: "Скриншот для событий распознавания автономеров",
          });
          // Обновляем значение token в локальном состоянии компонента
          this.token = result[0].token;
          // Перерисовываем компонент, чтобы использовать новое значение token
          this.$forceUpdate();
          // Открываем новое окно с подставленным токеном
          window.open(this.getImageUrl(this.currentDataCell.value), '_blank');
        } catch (error) {
          console.error(error);
        }
      }
    },
    getImageUrl(url) {
      return `${url}?token=${this.token}`;
    },
  },
  computed: {
    /**
     * Приведение значения типа {@link SPECIAL_TYPES_DATA_IN_CELLS.ARRAY} для представления в ячейке таблицы.
     *
     * Среди списка делается срез первых элементов для показа.
     *
     * Допускается передавать в качестве опциональных параметров значения, которые расцениваются как маршрут для навигации,
     * в таком случае будет отображаться ссылка.
     *
     * @return {Null|Object}
     */
    dataArray() {
      if (!Array.isArray(this.currentDataCell.value)) {
        return null;
      }

      let elementsForShow = this.currentDataCell.value.slice(0, 3).join(", ");
      if (this.currentDataCell.value.length > 3) {
        elementsForShow += " ...";
      }

      return {
        elementsForShow: elementsForShow,
        link: _.isEmpty(this.currentDataCell.params) ? null : {
          text: `посмотреть все (${this.currentDataCell.value.length})`,
          to: this.currentDataCell.params,
        }
      };
    }
  },
};
</script>
