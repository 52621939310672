<template>
  <div>
    <div class="embed-config">
      <SmartInputText
        v-model="embedHost"
        caption="Хост клиентского интерфейса"
        input-type="string"
        make-focus="true"
        placeholder="например https://ucams..."
      />
      <SmartSelect
        v-model="paramDurationAsHours"
        caption="Время без перезагрузки"
        input-type="number"
        :options="hourForSelect"
        width="fill"
      />
      <SmartInputText
        v-model="paramWidth"
        caption="Ширина"
        input-type="number"
        min="100"
        @input="changeWidth"
      />
      <SmartInputText
        v-model="paramHeight"
        caption="Высота"
        input-type="number"
        @input="changeHeight"
      />
      <SmartSwitch
        v-model="paramFullscreen"
        caption="Разрешить разворачивать в полный экран"
        class="embed-config__one-row"
      />
      <SmartSwitch
        v-model="paramAutoplay"
        caption="Автоматический запуск трансляции"
        class="embed-config__one-row"
      />
      <SmartSwitch
        v-model="paramSound"
        caption="Звук"
        class="embed-config__one-row"
      />
      <div class="embed-config__one-row">
        <SmartSwitch
          v-model="paramLowLatencyMode"
          caption="Прямой эфир без задержки"
        />

        <p v-show="!paramLowLatencyMode" class="embed-config__one-row">
          Стандартный способ воспроизведения.<br>Видео отдаётся с небольшой задержкой.
        </p>
        <p v-show="paramLowLatencyMode" class="embed-config__one-row">
          Видео транслируется без задержек.<br>Не поддерживается в некоторых браузерах.
        </p>

        <textarea
          :value="embedCode"
          class="embed-code js-clipboard"
        />
      </div>

      <CamsButton
        :disabled="!embedHost"
        class="embed-config__one-row"
        type="button"
        priority="primary"
        @click="copyToClipboard()"
      >
        Скопировать код
      </CamsButton>
    </div>
  </div>
</template>

<script>

/**
 * Компонент для настроек встраивания плеера на другие сайты.
 */
export default {
  name: "EmbedScreen",
  props: {
    /**
     * Номер камеры.
     */
    cameraNumber: {
      type: String,
      default: null,
    },
    /**
     * Хост клиентского интерфейса.
     */
  },
  data() {
    return {
      hourForSelect: [1,2,3,4,5,6,7,8,9,10,11,12],
      paramWidth: 640,
      paramHeight: 380,
      paramFullscreen: true,
      paramAutoplay: true,
      paramSound: false,
      paramHttps: false,
      paramDurationAsHours: 1,
      paramLowLatencyMode: false,
      embedHost: "",
    };
  },
  computed: {
    /**
     * @return {Number} Вернет количество секунд для настройки продолжительности воспроизведения без перезагрузки.
     */
    paramDurationAsSeconds() {
      return this.paramDurationAsHours * 3600;
    },
    /**
     * @return {String} Html с iframe для вставки плеера на другие сайты.
     */
    embedCode() {
      const realtime = this.paramLowLatencyMode ? "realtime=true&" : "",
            embedPlayerUrl = `${this.embedHost}/api/internal/embed/${this.cameraNumber}/?${realtime}ttl=${this.paramDurationAsSeconds}&autoplay=${this.paramAutoplay}&mute=${!this.paramSound}`,
            fullscreen = this.paramFullscreen ? `allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"` : "";

      return `<iframe ${fullscreen} width="${this.paramWidth}" height="${this.paramHeight}" src="${embedPlayerUrl}" allow="autoplay"></iframe>`;
    },
  },
  methods: {
    /**
     * Пропорциональное изменение ширины и высоты фрейма.
     *
     * @param {Number|String} newValue
     */
    changeWidth(newValue) {
      const newWidth = parseInt(newValue) || this.paramWidth;
      this.paramWidth = newWidth > 100 ? newWidth : 100;
      this.paramHeight = Math.floor(newWidth / 16 * 9);
    },
    /**
     * Пропорциональное изменение ширины и высоты фрейма.
     *
     * @param {Number|String} newValue
     */
    changeHeight(newValue) {
      const newHeight = parseInt(newValue) || this.paramHeight;
      this.paramWidth = Math.floor(newHeight / 9 * 16);
      this.paramHeight = newHeight;
    },
    /**
     * Скопирует содержимое лога в буфер обмена.
     */
    copyToClipboard() {
      const elementClipboard = document.querySelector(".js-clipboard");

      /**
       * Проверка на поддержку браузером Clipboard API.
       * В случае если версия браузера устаревшая,
       * то для копирования будет использоваться метод execCommand.
       */
      if (navigator.clipboard) {
        navigator.clipboard.writeText(elementClipboard.value);
      } else {
        const range = document.createRange();
        range.selectNode(elementClipboard);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
      }
    },
  },
};
</script>

<style lang="scss">
.embed-config {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1vw;
  grid-row-gap: 1vw;
  padding: 11px 16px 8px 0;

  &__one-row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.embed-code {
  width: 100%;
  height: 82px;
}
</style>
