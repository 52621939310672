<template>
  <div>
    <div>
      <p>Процесс экспорта данных в CSV. Не закрывайте это окно.</p>
      <br>
    </div>
    <div class="progress">
      <div class="progress__status" v-text="status" />
      <div class="progress__track">
        <div :style="`width: ${percentageProgress}%`" class="progress__line" />
      </div>

      <CamsButton
        :disabled="this.markStop || !this.processing"
        type="button"
        @click.once="markStop = true"
      >
        Прервать
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {MAX_ENTITIES_FOR_EXPORT_TO_CSV} from "@/utils/consts.js";
import {methodsForDialogMixin} from "@/utils/mixins.js";

/**
 * Компонент для организации скачивания данных из таблиц в файлы CSV.
 * Запуск обработки происходит сразу при включении компонента в работу.
 *
 * В части получения данных, много заимствований из SmartTable, поэтому за деталями использования ряда функций лучше смотреть туда.
 */
export default {
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Полное название action vuex в модуле, который предназначен для загрузки данных в таблицы.
     */
    actionForLoadData: {
      type: String,
      required: true,
    },
    /**
     * Данные payload для этого action.
     */
    dataForAction: {
      type: Object,
      required: true,
    },
    /**
     * Актуальный перечень полей таблицы.
     */
    columnNames: {
      type: Array,
      required: true,
    },
    /**
     * Объект со всеми заголовками полей для таблицы.
     */
    columnCaptions: {
      type: Object,
      required: true,
    },
    /**
     * @see SmartTable.props.parseExtra
     */
    parseExtra: {
      type: Function,
      required: true,
    },
    /**
     * @see SmartTable.props.calcDataForCell
     */
    calcDataForCell: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      status: "",
      processing: false,
      markStop: false,
      commonRowsCount: 0,
      currentRowsCount: 0,
      extraInfo: {},
    };
  },
  computed: {
    /**
     * Процентный показатель выполненной обработки.
     *
     * @return {Number}
     */
    percentageProgress() {
      return Math.round(this.currentRowsCount / this.commonRowsCount * 100);
    }
  },
  /**
   * Автоматический запуск экспорта, при запуске компонента.
   */
  mounted() {
    this.toCSV();
  },
  methods: {
    /**
     * Основной процесс в котором обрабатываются данные и совершается их преобразование в CSV формат,
     * и после успешного скачивания окно закрывается.
     */
    async toCSV() {
      let dataForExport = [];
      this.processing = true;
      this.status = "Начало загрузки данных...";
      try {
        dataForExport = await this.loadData();
        this.status = "Загрузка завершена. Формирование CSV...";
      } catch (error) {
        this.status = error;
        this.processing = false;
        return;
      }

      let csvText = "";
      const filename = `Выгрузка ${this.$route.name}.csv`,
            headers = this.columnNames.map((columnName) => this.columnCaptions[columnName]);
      csvText = `${headers.join(',')}\r\n`;
      dataForExport.forEach((dataRow) => {
        csvText += this.columnNames.map((columnName) => `"${this.calcDataForCell(columnName, dataRow, this.extraInfo).value}"`).join(",") + "\r\n";
      });
      this.downloadCSV(filename, csvText);
      this.processing = false;
      this.closeDialog();
    },
    /**
     * Действие для загрузки данных по конкретному типу сущности чтобы представить их в виде CSV файла.
     * Применять только для API которые предоставляют данные для таблиц, т.к. у них однообразная структура в передаваемых параметрах.
     *
     * Поскольку API ограничивает максимальное количество сущностей 1000 то для получения полного списка данных необходимо
     * постранично получать и объединять все ключи.
     * Тем не менее установим некоторое искусственное ограничение чтобы не упасть в бесконечный цикл.
     */
    async loadData() {
      const dataForExport = [],
            mergeCustomizer = (objValue, srcValue) => Array.isArray(objValue) ? [...objValue, ...srcValue] : undefined;
      let nextPage = 1,
          rawExtraInfo = {};

      while (nextPage !== null) {
        if (this.markStop) {
          throw "Операция прервана";
        }
        let responseData;
        try {
          responseData = await this.$store.dispatch(this.actionForLoadData, {...this.dataForAction, page: nextPage, pageSize: 1000});
        } catch (error) {
          devLog("[ProcessingExportToCSVDialog loadData]", error);
          throw "Ошибка при загрузке данных для CSV";
        }
        dataForExport.push(...responseData.results);

        rawExtraInfo = _.mergeWith(responseData.extra, rawExtraInfo, mergeCustomizer);
        this.commonRowsCount = responseData.count;
        this.currentRowsCount = dataForExport.length;
        this.status = `Идет обработка, завершено ${this.currentRowsCount} из ${this.commonRowsCount} строк (${this.percentageProgress}%)`;
        if ((this.commonRowsCount >= MAX_ENTITIES_FOR_EXPORT_TO_CSV) || (this.currentRowsCount >= MAX_ENTITIES_FOR_EXPORT_TO_CSV)) {
          throw `Количество строк ${Math.max(this.commonRowsCount, this.currentRowsCount)} для экспорта слишком велико. Максимум можно выгрузить ${MAX_ENTITIES_FOR_EXPORT_TO_CSV} строк`;
        }
        nextPage = responseData.page.next;
      }
      this.parseExtra(rawExtraInfo, this.extraInfo);
      return dataForExport;
    },
    /**
     * Скачивание текста CSV в виде файла.
     *
     * @param {String} filename
     * @param {String} csvText
     */
    downloadCSV(filename, csvText) {
      const blob = new Blob([`\uFEFF${csvText}`], {type: "text/csv;charset=utf-8;"});
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement("a");
        if (downloadLink.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          downloadLink.setAttribute("href", URL.createObjectURL(blob));
          downloadLink.setAttribute("download", filename);
          downloadLink.style.visibility = "hidden";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    },
  },
};
</script>
