<template>
  <div>
    <div class="content__table cams-table-wrapper">
      <SmartTable
        :calc-data-for-cell="calcDataForCell"
        :column-captions="columnCaptions"
        :current-route="currentRoute"
        :default-filters="defaultFilters"
        :key-field="rawFields.id"
        :name-action-load-data-for-table="nameActionLoadDataForTable"
        :name-action-load-info-for-table="nameActionLoadInfoForTable"
        :parse-extra="parseExtra"
        :raw-fields="rawFields"
        :initial-default-fields-table-for-view="defaultFieldsTableForView"
      />
    </div>
  </div>
</template>

<script>
import {
  ROUTE_DEVICES,
  ROUTE_EDIT_DEVICE,
  ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY,
} from "@/router/names.js";
import {
  FIELDS_USER_PERMISSIONS_GROUP_HISTORY,
  TITLES_FIELDS_USER_PERMISSIONS_GROUP_HISTORY,
  FIELDS_ADMIN,
  ACTION_LOAD_INFO_USER_PERMISSIONS_GROUP_HISTORY,
  ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY_FOR_TABLE,
  TYPES_FIELDS_USER_PERMISSIONS_HISTORY,
  EXTRAS_USER_PERMISSIONS_HISTORY
} from "@/store/users/admins/index.js";
import {FilterData} from "@/utils/helpers.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {FIELDS_HISTORY, TITLES_HISTORY} from "@/store/history/index.js";

/**
 * Таблица с отображением логов по изменению групп.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      entityId: this.$route.params.clientId,
      routeEditDevice: {name: ROUTE_EDIT_DEVICE, params: {clientId: this.$route.params.clientId}},
      routeBack: {name: ROUTE_DEVICES},
      currentRoute: ROUTE_VIEW_USER_PERMISSIONS_GROUP_HISTORY,
      rawFields:  {...FIELDS_USER_PERMISSIONS_GROUP_HISTORY, ...FIELDS_HISTORY},
      columnCaptions: {...TITLES_FIELDS_USER_PERMISSIONS_GROUP_HISTORY, ...TITLES_HISTORY},
      nameActionLoadDataForTable: `admins/${ACTION_LOAD_USER_PERMISSIONS_GROUP_HISTORY_FOR_TABLE}`,
      nameActionLoadInfoForTable: `admins/${ACTION_LOAD_INFO_USER_PERMISSIONS_GROUP_HISTORY}`,
      defaultFieldsTableForView: [
        FIELDS_USER_PERMISSIONS_GROUP_HISTORY.id,
        FIELDS_USER_PERMISSIONS_GROUP_HISTORY.permission,
        FIELDS_USER_PERMISSIONS_GROUP_HISTORY.is_auto,
        FIELDS_USER_PERMISSIONS_GROUP_HISTORY.camera_group_name,
        FIELDS_USER_PERMISSIONS_GROUP_HISTORY.username,
        FIELDS_HISTORY.data_change_event_date,
        FIELDS_HISTORY.data_close_event_date,
        FIELDS_HISTORY.data_change_event_user_id,
        FIELDS_HISTORY.data_change_event_action,
      ],
      defaultFilters: [
        new FilterData(FIELDS_USER_PERMISSIONS_GROUP_HISTORY.user_id, "=", [this.$route.params.clientId] ),

      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_USER_PERMISSIONS_HISTORY.user]) {
        storedExtraInfo.admins = {};
        sourceExtraInfo[EXTRAS_USER_PERMISSIONS_HISTORY.user].forEach((adminInfo) => {
          storedExtraInfo.admins[adminInfo[FIELDS_ADMIN.id]] = {
            id: adminInfo[FIELDS_ADMIN.id],
            name: adminInfo[FIELDS_ADMIN.username],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {

      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};
      switch (nameField) {
      case FIELDS_HISTORY.data_change_event_user_id:
        readyTranslation = _.get(storedExtraInfo.admins[rawTranslation], "name", "-");
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_USER_PERMISSIONS_HISTORY[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
