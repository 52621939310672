/**
 * Отдельный vuex модуль для взаимодействия по integrations/bolid/events-API
 */

import {DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для servers/logs. Вызов действия начинать с "/bolidEvents"
export const ACTION_LOAD_BOLID_EVENTS_FOR_TABLE = "ACTION_LOAD_BOLID_EVENTS_FOR_TABLE";
export const ACTION_LOAD_BOLID_EVENTS = "ACTION_LOAD__BOLID_EVENTS";
export const ACTION_LOAD_INFO_BOLID_EVENTS = "ACTION_LOAD_INFO_BOLID_EVENTS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_BOLID_EVENTS = Object.freeze( {
  id: "id",
  event_id: "event_id",
  event_type_id: "event_type_id",
  event_date: "event_date",
  event_description: "event_description",
  device_id: "device_id",
  device_type: "device_type",
  char_id: "char_id",
  type_description: "type_description",
  category: "category",
  hex_color: "hex_color",
  is_alarm: "is_alarm",
  comments: "comments",
});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_BOLID_EVENTS = Object.freeze({
  id: "id",
  event_id: "event_id",
  event_date: "event_date",
  event_description: "event_description",
  category: "category",
});
/**
 * Стандартные названия для полей групп прав.
 */
export const TITLES_FIELDS_BOLID_EVENTS = {
  [FIELDS_BOLID_EVENTS.id]: "ID",
  [FIELDS_BOLID_EVENTS.device_id]: "ID устройства",
  [FIELDS_BOLID_EVENTS.event_date]: "Дата",
  [FIELDS_BOLID_EVENTS.event_description]: "Описание события",
  [FIELDS_BOLID_EVENTS.category]: "Категория",
  [FIELDS_BOLID_EVENTS.device_type]: "Тип Устройства",
  [FIELDS_BOLID_EVENTS.comments]: "Комментарий",

};
/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_BY_FIELDS_BOLID_EVENTS = {
  [FIELDS_BOLID_EVENTS.event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};
/**
 * Перечисления опций для загрузки дополнительной информации.
 */
// export const EXTRAS_BOLID_EVENTS = Object.freeze({
//   analytic_server: "analytic_server",
//   camera: "camera"
// });
// export const EXTRAS_BY_FIELDS_BOLID_EVENTS = Object.freeze({
//   [FIELDS_BOLID_EVENTS.analytic_server_front_id]: [EXTRAS_BOLID_EVENTS.analytic_server],
//   [FIELDS_BOLID_EVENTS.camera_number]: [EXTRAS_BOLID_EVENTS.camera],
// });

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации по логам серверов аналитики.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_BOLID_EVENTS]() {
      const response = await this.getters.privateAjax.post("/v0/integrations/bolid/events/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка логов серверов аналитики.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_EVENTS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {

      try {
        const response = await this.getters.privateAjax.post("/v0/integrations/bolid/events/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка логов серверов аналитики для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_BOLID_EVENTS_FOR_TABLE]({dispatch}, {
      page = 1,
      pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE,
      orderBy = [],
      fields = [],
      filters = [],
      search = ""
    }) {
      return dispatch(ACTION_LOAD_BOLID_EVENTS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_BOLID_EVENTS_FOR_TABLE});
    },
  }
};