<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{ name: routes.ROUTE_CAMERAS }" class="tabs__item">
          Камеры
        </router-link>
        <router-link :to="{ name: routes.ROUTE_MARKERS }" class="tabs__item">
          Маркеры камер
        </router-link>
        <router-link :to="{ name: routes.ROUTE_HISTORY }" class="tabs__item">
          История по камерам
        </router-link>
        <router-link :to="{ name: currentRoute }" class="tabs__item tabs__item_active">
          Прошивки
        </router-link>
        <router-link :to="{ name: routes.ROUTE_MIGRATION }" class="tabs__item">
          Миграция камер
        </router-link>
      </div>
    </div>
    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :click-button-create="openDialogCreateFirmware"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :key-field="keyField"
      :default-filters="defaultFilters"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
    />
  </div>
</template>

<script>
import {
  ACTION_LOAD_FIRMWARES_FOR_TABLE,
  ACTION_LOAD_INFO_FIRMWARES,
  FIELDS_FIRMWARE,
  FILTERS_FIRMWARE,
  TITLES_FIELDS_FIRMWARE,
  TYPES_FIELDS_FIRMWARE,
} from "@/store/firmwares/index.js";
import {
  ROUTE_CAMERAS,
  ROUTE_EDIT_FIRMWARE,
  ROUTE_HISTORY,
  ROUTE_FIRMWARES,
  ROUTE_MARKERS,
  ROUTE_MIGRATION
} from "@/router/names.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";
import CreateFirmwareDialog from "@/components/firmwares/CreateFirmwareDialog.vue";
import {FilterData} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по маркерам.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      routes: {
        ROUTE_CAMERAS,
        ROUTE_HISTORY,
        ROUTE_MARKERS,
        ROUTE_MIGRATION
      },
      currentRoute: ROUTE_FIRMWARES,
      rawFields: FIELDS_FIRMWARE,
      defaultFieldsTableForView: [
        FIELDS_FIRMWARE.id,
        FIELDS_FIRMWARE.model,
        FIELDS_FIRMWARE.version,
        FIELDS_FIRMWARE.type,
        FIELDS_FIRMWARE.is_deleted
      ],
      defaultFilters: [
        new FilterData(FILTERS_FIRMWARE.is_deleted, "=", [false])
      ],
      columnCaptions: TITLES_FIELDS_FIRMWARE,
      nameActionLoadDataForTable: `firmwares/${ACTION_LOAD_FIRMWARES_FOR_TABLE}`,
      nameActionLoadInfoForTable: `firmwares/${ACTION_LOAD_INFO_FIRMWARES}`,
      keyField: FIELDS_FIRMWARE.id,
    };
  },
  methods: {
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_FIRMWARE.id:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_FIRMWARE, params: {firmwareId: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_FIRMWARE.model:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_FIRMWARE, params: {firmwareId: rowData[FIELDS_FIRMWARE.id]}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_FIRMWARE[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
    /**
     * Открытие диалогового окна для создания маркера.
     */
    openDialogCreateFirmware() {
      this.$camsdals.open(CreateFirmwareDialog, {}, {dialogTitle: "Создание прошивки"});
    },
  },
};
</script>
