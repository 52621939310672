<template>
  <div>
    <div class="row-2">
      <SmartSwitch
        v-model="autoUpdate"
        caption="Обновить прошивку на выбранных камерах"
        :disabled="manualUpdate"
        @input="AutoUpdate()"
      />
    </div>
    <div class="row-2">
      <SmartSwitch
        v-model="manualUpdate"
        :disabled="autoUpdate"
        caption="Обновить прошивку с помощью файла"
        @input="ManualUpdate()"
      />
      <SmartVSelect
        v-if="manualUpdate"
        v-model="firmwareId"
        caption="Выберите файл"
        :error="errorsFormEdit[firmwareId]"
        :settings-remote-search="firmwareSettings"
      />
    </div>
  </div>
</template>

<script>
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";
import {editDataEntityMixin, functionMultiEditMixin} from "@/utils/mixins.js";
import {ACTION_EDIT_CAMERA_SETUP_UPGRADE, ACTION_EDIT_CAMERA_SETUP_UPGRADE_MANUAL} from "@/store/camerasSetup/index.js";
import {ACTION_LOAD_FIRMWARES_FOR_SELECT, FIELDS_FIRMWARE} from "@/store/firmwares/index.js";

/**
 * Компонент функции мультиредактора камер для изменения звука.
 */
export default {
  mixins: [editDataEntityMixin,functionMultiEditMixin],
  data(){
    return  {
      firmwareId: null,
      manualUpdate: false,
      newValue: this.value,
      autoUpdate: false,
      firmwareSettings: {
        action: `firmwares/${ACTION_LOAD_FIRMWARES_FOR_SELECT}`,
        valueField: FIELDS_FIRMWARE.id,
        labelField: [FIELDS_FIRMWARE.model,FIELDS_FIRMWARE.type, FIELDS_FIRMWARE.version]
      }
    };
  },
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     * Массовое обновление прошивок через api.
     */
    AutoUpdate() {
      this.$emit("input", this.newValue);
      this.$emit("change-promise", async (entityKey) => {
        try {
          {
            await this.$store.dispatch(`camerasSetup/${ACTION_EDIT_CAMERA_SETUP_UPGRADE}`, entityKey);
          }
          return ResultProcessingEntityMultiEdit.success(entityKey);
        } catch (error) {
          return ResultProcessingEntityMultiEdit.errorRequest(entityKey, error);
        }
      });
    },
    /**
     * @link functionMultiEditMixin.methods.changePromise
     * Массовое обновление прошивок через api с использованием файла.
     */
    ManualUpdate() {
      this.$emit("input", this.newValue);
      this.$emit("change-promise", async (entityKey) => {
        try {
          {
            await this.$store.dispatch(`camerasSetup/${ACTION_EDIT_CAMERA_SETUP_UPGRADE_MANUAL}`, {cameraNumber: entityKey,firmwareId: this.firmwareId});
          }
          return ResultProcessingEntityMultiEdit.success(entityKey);
        } catch (error) {
          return ResultProcessingEntityMultiEdit.errorRequest(entityKey, error);
        }
      });
    },
  },
};
</script>
