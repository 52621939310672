<template>
  <div>
    <template v-if="isAvailableDaData">
      <SmartVSelect
        v-model="newValue"
        :caption="caption"
        :disabled="disabled"
        :error="error"
        :placeholder="placeholder"
        :settings-remote-search="settingsRemoteSearchAddresses"
        class="col"
        @input="selectAddress()"
        @search:focus="setUpLocationsBoost()"
      />
    </template>
    <template v-else>
      <SmartInputText
        v-model="newValue"
        :caption="caption"
        :disabled="disabled"
        :error="error"
        :placeholder="placeholder"
        class="col"
        @input="$emit('input', $event)"
      />
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ACTION_LOAD_HINTS_ADDRESSES_FOR_SELECT, ACTION_LOAD_KLADR_IDS_BY_LAT_LON, ACTION_LOAD_LAT_LON_BY_ADDRESS} from "@/store/dadata/index.js";

/**
 * Компонент поиска/ввода адреса.
 *
 * Поиск адреса тесно связан с работой сервиса dadata.ru и является оберткой вокруг SmartVSelect.
 * В таком режиме выбор адреса из подсказок позволит узнать координаты этого адреса
 * и пробросить в родительский компонент для дальнейшего использования.
 *
 * Подсказки используют логику работы dadata - так для ранжирования подсказок определяются ближайшие
 * к заданным в компоненте координатам географические объекты, из них извлекаются идентификаторы которые далее используются
 * в самом процессе поиска подсказок. Если координаты не используются, тогда приоритеты не будут применяться.
 *
 * Если сервер не поддерживает работу dadata тогда компонент будет представлять обертку вокруг SmartInputText
 * и позволит просто вводить адрес.
 *
 * По событию `@input` изменяется основная модель с текстом адреса, а `@extract-coordinates` передаст массив координат.
 *
 * todo нельзя в селект ввести свой адрес - которого нет в дадате
 */
export default {
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Заголовок поля.
     */
    caption: {
      type: String,
      default: ""
    },
    /**
     * Содержимое для placeholder в input-элемент.
     */
    placeholder: {
      type: String,
      default: ""
    },
    /**
     * Текст ошибки для поля.
     */
    error: {
      type: String,
      default: ""
    },
    /**
     * Опция для отключения возможности ввода в поле.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Широта для поиска ближайшего населенного пункта для подсказок.
     */
    initialLatitude: {
      type: [String, Number],
      default: null,
    },
    /**
     * Долгота для поиска ближайшего населенного пункта для подсказок.
     */
    initialLongitude: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      newValue: this.value,
      settingsRemoteSearchAddresses: {
        action: `dadata/${ACTION_LOAD_HINTS_ADDRESSES_FOR_SELECT}`,
        valueField: "value",
        labelField: "value",
        searchParams: {},
      },
      detectedLatitude: null,
      detectedLongitude: null,
      needReloadLocationsBoost: true,
    };
  },
  computed: {
    ...mapGetters(["isAvailableDaData"]),
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события `@change`.
     *
     * @param {String} val
     */
    value(val) {
      this.newValue = val;
      this.$emit("change", val);
    },
    /**
     * Наблюдение за передаваемой широтой для актуализации данных для подсказок.
     */
    initialLatitude() {
      this.needReloadLocationsBoost = true;
    },
    /**
     * Наблюдение за передаваемой долготой для актуализации данных для подсказок.
     */
    initialLongitude() {
      this.needReloadLocationsBoost = true;
    },
  },
  methods: {
    /**
     * Определение требуемой информации для работы подсказок при поиске адреса.
     *
     * Должно срабатывать каждый раз при изменении переданных в компонент координат.
     * Но чтобы не создавать множество лишних запросов в момент изменения координат (например при перемещении центра карты),
     * актуализация списка приоритета подсказок будет производится при получении фокуса на поле поиска
     * и при наличии маркера свидетельствующего об измении координат (читай наличие необходимости обновления приоритетов).
     * При положительном срабатывании метода - маркер изменится чтобы при повторных фокусах и неизменности координат
     * не происходило изменении приоритетов подсказок.
     *
     * Если координаты не были переданы, тогда приоритеты не будут применяться вообще.
     */
    async setUpLocationsBoost() {
      if (this.isAvailableDaData && this.needReloadLocationsBoost && this.initialLatitude && this.initialLongitude) {
        this.needReloadLocationsBoost = false;
        const kladrIds = await this.$store.dispatch(`dadata/${ACTION_LOAD_KLADR_IDS_BY_LAT_LON}`, {
          latitude: this.initialLatitude,
          longitude: this.initialLongitude
        });
        this.settingsRemoteSearchAddresses.searchParams = {locationsBoost: kladrIds};
      }
    },
    /**
     * Выбор адреса из подсказок позволит узнать его координаты, после чего они будут проброшены событием `@extract-coordinates`.
     * Так же пробрасывается значение адреса из подсказок.
     */
    async selectAddress() {
      if (!this.isAvailableDaData) {
        return;
      }
      this.$emit("input", this.newValue);
      const [detectedLatitude, detectedLongitude] = await this.$store.dispatch(`dadata/${ACTION_LOAD_LAT_LON_BY_ADDRESS}`, this.newValue);
      this.$emit("extract-coordinates", [detectedLatitude, detectedLongitude]);
    },
  },
};
</script>
