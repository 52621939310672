<template>
  <div class="content__settings__body">
    <div class="row">
      <SmartInputImage
        v-model="image"
        :delete-after-load="true"
        @input="addPhotoEmployee"
      />
    </div>
    <nav class="pagination">
      <paginate
        v-model="currentPage"
        :active-class="'pagination__list__item_active'"
        :break-view-class="'pagination__list__item_collapse'"
        :click-handler="loadPhotos"
        :container-class="'pagination__list'"
        :hide-prev-next="true"
        :page-class="'pagination__list__item'"
        :page-count="pageInfo.numPages"
        :page-range="5"
        next-text=""
        prev-text=""
      />
      <span>Фотографий: {{ pageInfo.count }}</span>
    </nav>

    <div class="employee-photos">
      <div
        v-for="photoInfo in photosInfo "
        :key="photoInfo.id"
        class="employee-photo-preview"
      >
        <img
          class="employee-photo-preview__img"
          :src="photoInfo.image"
          @click="showPhoto(photoInfo)"
        >
        <CamsButton
          class="employee-photo-preview__delete-button"
          size="s"
          type="button"
          @click="deletePhoto(photoInfo.id)"
        >
          Удалить
        </CamsButton>
        Статус фотографии: {{ photoInfo.status }}
      </div>
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import {
  ACTION_LOAD_PHOTOS_FOR_EMPLOYEE,
  ACTION_CREATE_PHOTO,
  FIELDS_PHOTO,
  ACTION_DELETE_PHOTO
} from "@/store/pacs/employeesPhoto/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import EmployeePhotoDialog from "@/components/pacs/employees/EmployeePhotoDialog.vue";

/**
 * Компонент управления фотографиями сотрудников.
 */
export default {
  props: {
    /**
     * ID сотрудника по которому будут загружены фото.
     */
    employeeId: {
      type: Number,
      required: true,
    },
  },
  mixins: [createEntityMixin],
  data() {
    return {
      isLoading: false,
      fieldsEntity: FIELDS_PHOTO,
      photosInfo: [],
      selectedFile: null,
      image: null,
      formEdit: {
        [FIELDS_PHOTO.image]: "",
        [FIELDS_PHOTO.employee_id]: this.employeeId,
      },
      currentPage: 1,
      pageInfo: {
        numPages: 0,
        count: 0,
      },
    };
  },
  created() {
    this.loadPhotos(1);
  },
  methods: {
    /**
     * Выбор файла с фотографией и его загрузка.
     *
     * @param {String} photo
     */
    async addPhotoEmployee(photo) {

      this.formEdit[FIELDS_PHOTO.image] = photo;
      this.isLoading = true;
      try {
        await this.$store.dispatch(`employeesPhoto/${ACTION_CREATE_PHOTO}`, this.formEdit);
      } catch {
        this.$camsdals.alert("Ошибка при загрузке фото");
      }
      this.loadPhotos(1);
      this.isLoading = false;
      this.image = null;
    },
    /**
     * Загрузка фотографий по сотруднику.
     *
     * @param {Number} selectedPage
     */
    async loadPhotos(selectedPage) {
      this.isLoading = true;
      this.currentPage = selectedPage;
      const responseData = await this.$store.dispatch(`employeesPhoto/${ACTION_LOAD_PHOTOS_FOR_EMPLOYEE}`, {employeeId: this.employeeId, page: selectedPage});
      this.photosInfo = responseData.results;
      this.pageInfo.count = responseData.count;
      this.pageInfo.numPages = responseData.page.all;
      this.isLoading = false;
    },
    /**
     * Отправка запроса на удаление фотографии.
     *
     * @param {Number} photoId
     */
    deletePhoto(photoId) {
      this.$camsdals.confirm("Хотите удалить фотографию?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`employeesPhoto/${ACTION_DELETE_PHOTO}`, [photoId]);
          this.$camsdals.alert("Фотография удалена");
        } catch {
          this.$camsdals.alert("Ошибка при удалении фото");
        }
        this.loadPhotos();
        this.isLoading = false;
      });
    },
    /**
     * Показ оригинала фотографии в диалоговом окне.
     *
     * @param {Object} photoInfo
     */
    showPhoto(photoInfo) {
      this.$camsdals.open(EmployeePhotoDialog, {photoInfo}, {dialogTitle: "Фото сотрудника"});
    }
  },
};
</script>

<style lang="scss">
.employee-photos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.employee-photo-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px 10px 0 0;
  border: 1px #4f4f4f solid;
  background-color: #fff;

  &__img {
    max-width: 200px;
    height: 200px;

    &:hover {
      cursor: pointer;
    }
  }

  &__delete-button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>