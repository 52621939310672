<template>
  <div class="content__settings__body">
    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />

    <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
      <div class="row">
        <div class="col-2">
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.title)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.title]"
              :caption="titlesFields[fieldsEntity.title]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.title)"
              :error="errorsFormEdit[fieldsEntity.title]"
              :make-focus="true"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.streams)" class="row">
            <SmartInputArray
              v-model="formEdit[fieldsEntity.streams]"
              :caption="titlesFields[fieldsEntity.streams]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.streams)"
              :error="errorsFormEdit[fieldsEntity.streams]"
              class="col"
            />
          </div>

          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.screenshot_url)"
            class="row"
          >
            <SmartInputText
              v-model="formEdit[fieldsEntity.screenshot_url]"
              :caption="titlesFields[fieldsEntity.screenshot_url]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.screenshot_url)"
              :error="errorsFormEdit[fieldsEntity.screenshot_url]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.ip)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.ip]"
              :caption="titlesFields[fieldsEntity.ip]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.ip)"
              :error="errorsFormEdit[fieldsEntity.ip]"
              class="col"
            />
          </div>

          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.camera_group_ids)"
            class="row"
          >
            <SmartVSelect
              v-model="formEdit[fieldsEntity.camera_group_ids]"
              :caption="titlesFields[fieldsEntity.camera_group_ids]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.camera_group_ids)"
              :error="errorsFormEdit[fieldsEntity.camera_group_ids]"
              :initial-options="initialOptionsCameraGroups"
              :multiple="true"
              :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.server_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.server_id]"
              :caption="titlesFields[fieldsEntity.server_id]"
              :disabled="true"
              :error="errorsFormEdit[fieldsEntity.server_id]"
              :initial-options="initialOptionsServers"
              :settings-remote-search="settingsSelects[fieldsEntity.server_id]"
              class="col"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.tariff_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.tariff_id]"
              :caption="titlesFields[fieldsEntity.tariff_id]"
              :error="errorsFormEdit[fieldsEntity.tariff_id]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.tariff_id)"
              :initial-options="initialOptionsTariffs"
              :settings-remote-search="settingsSelects[fieldsEntity.tariff_id]"
              class="col"
            />
          </div>
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.gang_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.gang_id]"
              :caption="titlesFields[fieldsEntity.gang_id]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.gang_id)"
              :error="errorsFormEdit[fieldsEntity.gang_id]"
              :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
              :initial-options="initialOptionsGangs"
              class="col"
            />
          </div>
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.storage)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.storage]"
              :caption="titlesFields[fieldsEntity.storage]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.storage)"
              :error="errorsFormEdit[fieldsEntity.storage]"
              class="col"
            />
          </div>
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.marker_id)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.marker_id]"
              :caption="titlesFields[fieldsEntity.marker_id]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.marker_id)"
              :error="errorsFormEdit[fieldsEntity.marker_id]"
              :settings-remote-search="settingsSelects[fieldsEntity.marker_id]"
              :initial-options="initialOptionsMarkers"
              class="col"
            />
          </div>
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)" class="row">
            <SmartInputAddress
              v-model="formEdit[fieldsEntity.address]"
              :caption="titlesFields[fieldsEntity.address]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)"
              :error="errorsFormEdit[fieldsEntity.address]"
              :initial-latitude="centerMap[0] || formEdit[fieldsEntity.latitude]"
              :initial-longitude="centerMap[1] || formEdit[fieldsEntity.longitude]"
              class="col"
              @extract-coordinates="[formEdit[fieldsEntity.latitude], formEdit[fieldsEntity.longitude]] = $event"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)" class="row">
            <SmartInputText
              v-model="formEdit[fieldsEntity.latitude]"
              :caption="titlesFields[fieldsEntity.latitude]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.latitude)"
              :error="errorsFormEdit[fieldsEntity.latitude]"
              class="col-2"
            />

            <SmartInputText
              v-model="formEdit[fieldsEntity.longitude]"
              :caption="titlesFields[fieldsEntity.longitude]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.longitude)"
              :error="errorsFormEdit[fieldsEntity.longitude]"
              class="col-2"
            />
          </div>

          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.timezone)" class="row">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.timezone]"
              :caption="titlesFields[fieldsEntity.timezone] + ' ' + timezoneOffset"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.timezone)"
              :error="errorsFormEdit[fieldsEntity.timezone]"
              :initial-options="initialOptionsTimezones"
              class="col"
            />
          </div>

          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.embed_hosts)"
            class="row"
          >
            <SmartInputArray
              v-model="formEdit[fieldsEntity.embed_hosts]"
              :caption="titlesFields[fieldsEntity.embed_hosts]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.embed_hosts)"
              :error="errorsFormEdit[fieldsEntity.embed_hosts]"
              class="col"
            />
          </div>

          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_sounding)"
            class="row"
          >
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_sounding]"
              :caption="titlesFields[fieldsEntity.is_sounding]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_sounding)"
              :error="errorsFormEdit[fieldsEntity.is_sounding]"
              class="col"
            />
          </div>

          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_managed)"
            class="row"
          >
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_managed]"
              :caption="titlesFields[fieldsEntity.is_managed]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_managed)"
              :error="errorsFormEdit[fieldsEntity.is_managed]"
              class="col"
            />
          </div>
          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_llhls_enabled)"
            class="row"
          >
            <SmartSwitch
              v-model="formEdit[fieldsEntity.is_llhls_enabled]"
              :caption="titlesFields[fieldsEntity.is_llhls_enabled]"
              :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_llhls_enabled)"
              :error="errorsFormEdit[fieldsEntity.is_llhls_enabled]"
              class="col"
            />
          </div>


          <SmartSwitch
            v-model="formEdit[fieldsEntity.is_embed]"
            :caption="titlesFields[fieldsEntity.is_embed]"
            :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_embed)"
            :error="errorsFormEdit[fieldsEntity.is_embed]"
            class="row"
          />
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_embed)" class="row">
            <CamsButton
              v-if="formEdit[fieldsEntity.is_embed]"
              priority="primary"
              type="button"

              @click="openDialogIframe()"
            >
              Код iframe
            </CamsButton>
          </div>
          <template v-if="!isDeleted">
            <div class="row">
              <div class="buttons-group">
                <CamsButton
                  v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                  priority="primary"
                  type="button"
                  @click="saveData()"
                >
                  Сохранить и продолжить редактирование
                </CamsButton>
                <CamsButton
                  v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                  priority="primary"
                  type="button"
                  @click="saveDataAndRedirect()"
                >
                  Сохранить
                </CamsButton>
              </div>
            </div>

            <div class="row">
              <div class="buttons-group">
                <CamsButton
                  v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.CAMERA)"
                  type="button"
                  @click="deleteCamera()"
                >
                  Удалить
                </CamsButton>
                <router-link
                  v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.CAMERA)"
                  :to="routeBack"
                  class="button button_btn button_medium button_btn-default"
                >
                  Отменить
                </router-link>
              </div>
            </div>

            <div class="row">
              <div class="buttons-group">
                <CamsButton
                  v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_PERMISSION)"
                  type="button"
                  @click="openDialogSetPermissions()"
                >
                  Изменить права пользователей
                </CamsButton>
                <CamsButton
                  v-if="$can($abilitiesActions.RESTART_STREAM, $abilitiesSubjects.CAMERA)"
                  type="button"
                  @click="restartStream()"
                >
                  Перезапуск потока
                </CamsButton>
              </div>
            </div>
          </template>
        </div>

        <div class="col-2">
          <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)" class="row">
            <div class="col">
              <MapEditCamera
                :marker-id="formEdit[fieldsEntity.marker_id]"
                :disabled-move-marker="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)"
                :initial-marker-latitude="formEdit[fieldsEntity.latitude]"
                :initial-marker-longitude="formEdit[fieldsEntity.longitude]"
                class="map"
                @change-center-map="centerMap = $event"
                @change-marker-position="[formEdit[fieldsEntity.latitude], formEdit[fieldsEntity.longitude]] = $event"
              />
            </div>
          </div>

          <div v-if="!isDeleted" class="row">
            <div class="col col-valign">
              <div v-show="isDeactivated">
                <CamsButton
                  v-if="$can($abilitiesActions.ACTIVATE, $abilitiesSubjects.CAMERA)"
                  priority="primary"
                  type="button"
                  @click="activateCamera()"
                >
                  Включить камеру
                </CamsButton>
              </div>
              <div v-if="$can($abilitiesActions.ACTIVATE, $abilitiesSubjects.CAMERA)" v-show="!isDeactivated">
                <CamsButton priority="primary" type="button" @click="deactivateCamera()">
                  Выключить камеру
                </CamsButton>
              </div>
            </div>
            <div class="buttons-group">
              <CamsButton
                v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)"
                icon-type="only"
                style="border: none;background:none;"
                title="Просмотр трансляции и архива"
                type="button"
                @click="routeCarNumber ? openDialogShowZones() : openDialogRequestTokenCamera([tokenTypes.L, tokenTypes.R])"
              >
                <svg class="icon-play-panel">
                  <use xlink:href="@/assets/img/icons.svg#icon-super-play" />
                </svg>
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.LIVE_REASON, $abilitiesSubjects.CAMERA)"
                icon-type="only"
                style="border: none;background:none;"
                title="Просмотр прямой трансляции"
                type="button"
                @click="routeCarNumber ? openDialogShowZones() : openDialogRequestTokenCamera([tokenTypes.L])"
              >
                <svg class="icon-play-panel">
                  <use xlink:href="@/assets/img/icons.svg#icon-play" />
                </svg>
              </CamsButton>
              <CamsButton
                v-if="$can($abilitiesActions.DVR_REASON, $abilitiesSubjects.CAMERA)"
                icon-type="only"
                style="border: none;background:none;"
                title="Просмотр архива"
                type="button"
                @click="routeCarNumber ? openDialogShowZones() : openDialogRequestTokenCamera([tokenTypes.R])"
              >
                <svg class="icon-play-panel">
                  <use xlink:href="@/assets/img/icons.svg#icon-archive" />
                </svg>
              </CamsButton>
            </div>
          </div>
          <div v-if="isDisabledRecord">
            <CamsButton
              v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)"
              priority="primary"
              type="button"
              @click="enableRecord()"
            >
              Возобновить запись в архив
            </CamsButton>
            <p :title="this.disabledRecordPeriodInfo.start | localDateTime">
              Дата отключения архива: <strong>{{ this.disabledRecordPeriodInfo.start | localDateTime }}</strong>
            </p>
          </div>
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" v-show="!isDisabledRecord">
            <CamsButton priority="primary" type="button" @click="disableRecord()">
              Отключить запись в архив
            </CamsButton>
          </div>
          <div v-if="isDeactivated">
            <p class="error">
              <strong>Информация по периоду неактивности</strong>
            </p>
            <p>
              ID периода неактивности: <strong>{{ inactivityPeriodInfo[inactivityPeriodField.id] }}</strong>
            </p>
            <p :title="inactivityPeriodInfo[inactivityPeriodField.start] | localDateTimeZone">
              Дата отключения: <strong>{{ inactivityPeriodInfo[inactivityPeriodField.start] | localDate }}</strong>
            </p>
            <p>
              Комментарий: <strong>{{ inactivityPeriodInfo[inactivityPeriodField.comment] }}</strong>
            </p>
            <p>
              Причина: <strong>{{ inactivityPeriodInfo[inactivityPeriodField.reason] }}</strong>
            </p>
            <p>
              Задача: <strong>{{ inactivityPeriodInfo[inactivityPeriodField.todo] }}</strong>
            </p>
          </div>

          <br>
          <div v-if="!isDeleted" class="row">
            <div v-if="$can($abilitiesActions.BLOCKING, $abilitiesSubjects.CAMERA)" v-show="!isBlocked">
              <CamsButton priority="primary" type="button" @click="openDialogBlockingCamera()">
                Заблокировать
              </CamsButton>
            </div>
            <div v-if="$can($abilitiesActions.BLOCKING, $abilitiesSubjects.CAMERA)" v-show="isBlocked">
              <CamsButton priority="primary" type="button" @click="unblockingCamera()">
                Снять блокировку
              </CamsButton>
            </div>
          </div>
          <template
            v-if="isBlocked && $can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.blocking_lvl)"
          >
            <p>
              Блокировка уровня <strong>{{ levelBlocking }}</strong> до
              <strong>{{ sourceData.entityInfo[fieldsEntity.blocking_end_date] | localDateTime }}</strong>
            </p>
            <br>
          </template>

          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)">
            {{ titlesFields[fieldsEntity.is_public] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.is_public] | bool }}</strong>
          </p>

          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)"
            v-show="sourceData.entityInfo[fieldsEntity.create_date]"
            :title="sourceData.entityInfo[fieldsEntity.create_date] | localDateTimeZone"
          >
            {{ titlesFields[fieldsEntity.create_date] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.create_date] | localDate }}</strong>
          </p>
          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.address)"
            v-show="sourceData.entityInfo[fieldsEntity.delete_date]"
            :title="sourceData.entityInfo[fieldsEntity.delete_date] | localDateTimeZone"
          >
            {{ titlesFields[fieldsEntity.delete_date] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.delete_date] | localDate }}</strong>
          </p>

          <br>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.dadata_qc)">
            {{ titlesFields[fieldsEntity.dadata_qc] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.dadata_qc] }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.dadata_qc_geo)">
            {{ titlesFields[fieldsEntity.dadata_qc_geo] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.dadata_qc_geo] }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.dadata_success)">
            {{ titlesFields[fieldsEntity.dadata_success] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.dadata_success] | bool }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.dadata_error)">
            {{ titlesFields[fieldsEntity.dadata_error] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.dadata_error] }}</strong>
          </p>

          <br>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.stats_alive)">
            {{ titlesFields[fieldsEntity.stats_alive] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.stats_alive] | bool }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.stats_last_alive)">
            {{ titlesFields[fieldsEntity.stats_last_alive] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.stats_last_alive] | localDateTime }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.stats_date)">
            {{ titlesFields[fieldsEntity.stats_date] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.stats_date] | localDateTime }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.stats_bitrate)">
            {{ titlesFields[fieldsEntity.stats_bitrate] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.stats_bitrate] }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.stats_client_count)">
            {{ titlesFields[fieldsEntity.stats_client_count] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.stats_client_count] }}</strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.stats_source_error)">
            {{ titlesFields[fieldsEntity.stats_source_error] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.stats_source_error] }}</strong>
          </p>

          <br>
          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.camera_migration_task_id)"
          >
            {{ titlesFields[fieldsEntity.camera_migration_task_id] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.camera_migration_task_id] }}</strong>
          </p>
          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.camera_migration_task_status)"
          >
            {{ titlesFields[fieldsEntity.camera_migration_task_status] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.camera_migration_task_status] }}</strong>
          </p>
          <p
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.camera_migration_task_server_id)"
          >
            {{ titlesFields[fieldsEntity.camera_migration_task_server_id] }}:
            <strong>{{ sourceData.entityInfo[fieldsEntity.camera_migration_task_server_id] }}</strong>
          </p>


          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_PERMISSION)">
            Количество записей прав для камеры:
            <strong>{{ countPermissionsByUsers }}</strong>
          </p>
          <div
            v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.last_direct_screen_success)"
          >
            {{ titlesFields[fieldsEntity.last_direct_screen_success] }}:  {{ sourceData.entityInfo[fieldsEntity.last_direct_screen_success_date] | localDateTime }}
            <strong> {{ lastScreenStatus ? ' Да ' :lastScreenStatus === null ? ' Не указан URL ' : 'Ошибка ' }}</strong>
            <div
              :class="
                lastScreenStatus ? 'indicator indicator_available_for_edit'
                :lastScreenStatus === null ? 'indicator indicator_null_for_edit' :'indicator indicator_inaccessible_for_edit'"
            />
          </div>
          <br>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.CAMERA_GROUP)">
            <strong>
              <router-link :to="routeCameraGroups">Посмотреть список групп у камеры</router-link>
            </strong>
          </p>
          <p v-if="routeServer">
            <strong>
              <router-link :to="routeServer">Посмотреть информацию по серверу</router-link>
            </strong>
          </p>
          <p v-if="routeTariff">
            <strong>
              <router-link :to="routeTariff">Посмотреть информацию по тарифу</router-link>
            </strong>
          </p>
          <p v-if="routeThermalVision">
            <strong>
              <router-link :to="routeThermalVision">Посмотреть информацию по настройкам аналитики тепловизора
              </router-link>
            </strong>
          </p>
          <p v-if="routeCarNumber">
            <strong>
              <router-link :to="routeCarNumber">Посмотреть информацию по настройкам аналитики автомобилей</router-link>
            </strong>
          </p>
          <p v-if="routeFaceRecognition">
            <strong>
              <router-link :to="routeFaceRecognition">Посмотреть информацию по настройкам аналитики лиц</router-link>
            </strong>
          </p>
          <p v-if="routeHelmet">
            <strong>
              <router-link :to="routeHelmet">Посмотреть информацию по настройкам аналитики определения касок
              </router-link>
            </strong>
          </p>
          <p v-if="routeMotionAlarm">
            <strong>
              <router-link :to="routeMotionAlarm">Посмотреть информацию по настройкам аналитики детекции движения
              </router-link>
            </strong>
          </p>
          <p v-if="routeMask">
            <strong>
              <router-link :to="routeMask">Посмотреть информацию по настройкам аналитики детекции отсутствия масок
              </router-link>
            </strong>
          </p>
          <p v-if="routePerimeter">
            <strong>
              <router-link :to="routePerimeter">Посмотреть информацию по настройкам аналитики вторжения в зону
              </router-link>
            </strong>
          </p>
          <p v-if="routePeopleCounter">
            <strong>
              <router-link :to="routePeopleCounter">Посмотреть информацию по настройкам аналитики подсчета посетителей
              </router-link>
            </strong>
          </p>
          <p v-if="routeCrowd">
            <strong>
              <router-link :to="routeCrowd">Посмотреть информацию по настройкам аналитики детекции скопления людей
              </router-link>
            </strong>
          </p>
          <br>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_VIEW)">
            <strong>
              <router-link :to="routeLogViewsByCamera">Логи просмотров</router-link>
            </strong>
          </p>
          <p v-if="$can($abilitiesActions.READ_COMMON, $abilitiesSubjects.LOG_CAMERA_WATCH_REASON)">
            <strong>
              <router-link :to="routeLogCameraWatchReasonsByCamera">Логи запросов доступа к камере</router-link>
            </strong>
          </p>
        </div>
      </div>
    </form>

    <div v-else class="row">
      <p>Данные не удалось загрузить.</p>
    </div>
  </div>
</template>

<script>
import BlockingCameraDialog from "@/components/cameras/blocking/BlockingCameraDialog.vue";
import MapEditCamera from "@/components/cameras/MapEditCamera.vue";
import RequestTokenCameraDialog from "@/components/cameras/RequestTokenCameraDialog.vue";
import SetPermissionsForCamerasDialog from "@/components/permissions/SetPermissionsForCamerasDialog.vue";
import {
  ROUTE_CAMERA_GROUPS,
  ROUTE_CAMERAS,
  ROUTE_EDIT_CAR_NUMBER, ROUTE_EDIT_CROWD,
  ROUTE_EDIT_FACE_RECOGNITION, ROUTE_EDIT_HELMET, ROUTE_EDIT_MASK,
  ROUTE_EDIT_MOTION_ALARM, ROUTE_EDIT_PEOPLE_COUNT, ROUTE_EDIT_PERIMETER_SECURITY,
  ROUTE_EDIT_SERVER,
  ROUTE_EDIT_TARIFF,
  ROUTE_EDIT_THERMAL_VISION,
  ROUTE_LOG_CAMERA_WATCH_REASONS,
  ROUTE_LOG_VIEWS
} from "@/router/names.js";
import {ANALYTICS} from "@/store/analytics/helpers.js";
import {
  ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT,
  FIELDS_CAMERA_GROUP,
  FILTERS_CAMERA_GROUP
} from "@/store/cameraGroups/index.js";
import {
  ACTION_ACTIVATE_CAMERAS,
  ACTION_BLOCKING_CAMERAS,
  ACTION_DEACTIVATE_CAMERAS,
  ACTION_DELETE_CAMERAS, ACTION_DISABLE_RECORD,
  ACTION_EDIT_CAMERA,
  ACTION_EDIT_CAMERA_WITH_GROUPS, ACTION_ENABLE_RECORD,
  ACTION_LOAD_CAMERA_FOR_EDIT, ACTION_RESTART_STREAM,
  EXTRAS_CAMERA,
  FIELDS_CAMERA,
  FIELDS_PERIOD_INACTIVITY,
  TITLES_FIELDS_CAMERA,
  TITLES_TOKEN_TYPES,
  TOKEN_TYPES
} from "@/store/cameras/index.js";
import {FILTERS_LOG_CAMERA_WATCH_REASONS, FILTERS_LOG_VIEWS} from "@/store/log/index.js";
import {ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA} from "@/store/permissions/index.js";
import {ACTION_LOAD_SERVERS_FOR_SELECT, FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {ACTION_LOAD_TARIFFS_FOR_SELECT, FIELDS_TARIFF} from "@/store/tariffs/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE, SORT_DIRECTIONS} from "@/utils/consts.js";
import {FilterData, TableQueryParams} from "@/utils/helpers.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import ACTUAL_ZONES from "@/utils/tz.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";
import {ACTION_LOAD_MARKERS_FOR_SELECT, FIELDS_MARKER} from "@/store/markers/index.js";
import CameraEmbedScreen from "@/components/cameras/CameraEmbedScreen.vue";
import {AnalyticZone, AnalyticZonesConfig} from "camsng-frontend-shared/components/smartPlayer/mixins";
import {ACTION_LOAD_CAR_NUMBER_FOR_EDIT, FIELDS_CAR_NUMBER} from "@/store/analytics/carNumbers/index.js";

/**
 * Компонент страницы редактирования камеры.
 *
 * Этот компонент является общим родителем и посредником между компонентами карты (для отображения маркера)
 * и установки адреса (с возможностью поиска адреса).
 *  - Карта может изменять позицию маркера, тогда необходимо актуализировать координаты в соответсвующих полях.
 *  - Координаты можно ввести вручную, тогда маркер следует передвинуть на карте.
 *  - Адрес можно найти из поиска, а из найденного адреса можно извлечь его координаты,
 *  которые подставятся в поля ввода и тем самым передвинут маркер.
 *  - По центру карты можно определить ближайший населенный пункт (или регион) и это обеспечит выдачу актуальных подсказок в поиске.
 * Это разные компоненты потому что они используются и в других местах но в разных верстках.
 * Поэтому нужно обеспечить передачу данных через родительский компонент.
 * Vuex не вариант - так как работа с отдельно взятой камерой, в отдельном экземпляре приложения (в другом табе браузера),
 * не должна афектить на работу другого экземпляра приложения.
 */
export default {
  components: {
    MapEditCamera,
  },
  mixins: [
    editDataEntityMixin
  ],
  data() {
    const nameActionEdit = this.$can(this.$abilitiesActions.UPDATE_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.camera_group_ids) ?
            ACTION_EDIT_CAMERA_WITH_GROUPS : ACTION_EDIT_CAMERA,
          defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    const cameraNumber = this.$route.params.cameraNumber,
          paramsRouteLogViewsByCamera = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_VIEWS.camera_number, "=", [cameraNumber]),
              new FilterData(FILTERS_LOG_VIEWS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_VIEWS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogViewsByCamera = {
            name: ROUTE_LOG_VIEWS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogViewsByCamera.stringify()}
          },
          paramsRouteLogCameraWatchReasonsByCamera = new TableQueryParams({
            filters: [
              new FilterData(FILTERS_LOG_CAMERA_WATCH_REASONS.camera_number, "=", [cameraNumber]),
              new FilterData(FILTERS_LOG_CAMERA_WATCH_REASONS.date, ">=", [defaultFilterByDate])
            ],
            order: [{field: FILTERS_LOG_CAMERA_WATCH_REASONS.date, direction: SORT_DIRECTIONS.DESC}]
          }),
          routeLogCameraWatchReasonsByCamera = {
            name: ROUTE_LOG_CAMERA_WATCH_REASONS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteLogCameraWatchReasonsByCamera.stringify()}
          },
          paramsRouteCameraGroups = new TableQueryParams({
            filters: [new FilterData(FILTERS_CAMERA_GROUP.camera_number, "=", [cameraNumber])],
          }),
          routeCameraGroups = {
            name: ROUTE_CAMERA_GROUPS,
            query: {[NAME_QUERY_PARAM_FOR_TABLE]: paramsRouteCameraGroups.stringify()}
          };
    return {
      routeBack: {name: ROUTE_CAMERAS},
      fieldsEntity: FIELDS_CAMERA,
      titlesFields: TITLES_FIELDS_CAMERA,
      entityId: cameraNumber,
      formEdit: {
        [FIELDS_CAMERA.storage]: "",
      },
      nameActionLoadDataForEdit: `cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`,
      nameActionEdit: `cameras/${nameActionEdit}`,
      deletedField: FIELDS_CAMERA.is_deleted,
      inactivityPeriodField: FIELDS_PERIOD_INACTIVITY,
      initialOptionsGangs: [],
      initialOptionsCameraGroups: [],
      initialOptionsTariffs: [],
      initialOptionsServers: [],
      initialOptionsMarkers: [],
      initialOptionsTimezones: ACTUAL_ZONES,
      settingsSelects: {
        [FIELDS_CAMERA.camera_group_ids]: {
          action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP.id,
          labelField: FIELDS_CAMERA_GROUP.name
        },
        [FIELDS_CAMERA.tariff_id]: {
          action: `tariffs/${ACTION_LOAD_TARIFFS_FOR_SELECT}`,
          valueField: FIELDS_TARIFF.id,
          labelField: FIELDS_TARIFF.name
        },
        [FIELDS_CAMERA.server_id]: {
          action: `servers/${ACTION_LOAD_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_SERVER.id,
          labelField: FIELDS_SERVER.domain
        },
        [FIELDS_CAMERA.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
        [FIELDS_CAMERA.marker_id]: {
          action: `markers/${ACTION_LOAD_MARKERS_FOR_SELECT}`,
          valueField: FIELDS_MARKER.id,
          labelField: FIELDS_MARKER.name,
        }
      },
      centerMap: [null, null],
      tokenTypes: TOKEN_TYPES,
      countPermissionsByUsers: 0,
      routeLogViewsByCamera: routeLogViewsByCamera,
      routeLogCameraWatchReasonsByCamera: routeLogCameraWatchReasonsByCamera,
      // Следующие маршруты можно построить после загрузки информации.
      routeCameraGroups: routeCameraGroups,
      routeServer: null,
      routeTariff: null,
      routeThermalVision: null,
      routeCarNumber: null,
      routeFaceRecognition: null,
      routeHelmet: null,
      routeMotionAlarm: null,
      routeMask: null,
      routeCrowd: null,
      routePerimeter: null,
      routePeopleCounter: null,
    };
  },
  computed: {
    /**
     * Вернет начало текущего периода неактивности, если он есть.
     *
     * @return {Object}
     */
    inactivityPeriodInfo() {
      return _.head(this.sourceData.extraInfo[EXTRAS_CAMERA.inactivity_period]);
    },
    /**
     * Вернет начало текущего периода неактивности, если он есть.
     *
     * @return {Object}
     */
    lastScreenStatus() {
      return this.formEdit[FIELDS_CAMERA.last_direct_screen_success];
    },
    /**
     * Вернет текущее состояние активности камеры.
     *
     * @return {Boolean}
     */
    isDeactivated() {
      return Boolean(this.inactivityPeriodInfo);
    },
    /**
     * Вернет начало текущего периода неактивности, если он есть.
     *
     * @return {Object}
     */
    disabledRecordPeriodInfo() {
      return _.head(this.sourceData.extraInfo[EXTRAS_CAMERA.record_disable_period]);
    },
    /**
     * Вернет текущее состояние активности камеры.
     *
     * @return {Boolean}
     */
    isDisabledRecord() {
      return Boolean(this.disabledRecordPeriodInfo);
    },
    /**
     * Вернет текущее состояние блокировки камеры.
     *
     * @return {Boolean}
     */
    isBlocked() {
      return Boolean(this.formEdit[FIELDS_CAMERA.blocking_lvl]);
    },
    /**
     * Вернет человекопонятный уровень блокировки.
     *
     * @return {String}
     */
    levelBlocking() {
      return _.get(TITLES_TOKEN_TYPES, this.formEdit[FIELDS_CAMERA.blocking_lvl], "");
    },
    /**
     * Вернет человекопонятное смещение (в формате ISO это часть Z) выбранного часового пояса относительно текущего времени пользователя.
     *
     * @return {String}
     */
    timezoneOffset() {
      return this.formEdit[FIELDS_CAMERA.timezone] && moment().tz(this.formEdit[FIELDS_CAMERA.timezone]).format("Z");
    },
  },
  methods: {
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Подготавливаются начальные опции для выпадающих списков.
      this.initialOptionsCameraGroups = this.sourceData.extraInfo[EXTRAS_CAMERA.camera_group];
      this.initialOptionsTariffs = this.sourceData.extraInfo[EXTRAS_CAMERA.tariff];
      this.initialOptionsServers = this.sourceData.extraInfo[EXTRAS_CAMERA.server];
      this.initialOptionsGangs = this.sourceData.extraInfo[EXTRAS_CAMERA.gang];
      this.initialOptionsMarkers = this.sourceData.extraInfo[EXTRAS_CAMERA.marker];
      // Подготовка ссылок на связанные сущности.
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.SERVER) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.server_id)) {
        const serverId = this.formEdit[FIELDS_CAMERA.server_id];
        this.routeServer = serverId ? {name: ROUTE_EDIT_SERVER, params: {serverId}} : null;
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.TARIFF) && this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.tariff_id)) {
        const tariffId = this.formEdit[FIELDS_CAMERA.tariff_id];
        this.routeTariff = tariffId ? {name: ROUTE_EDIT_TARIFF, params: {tariffId}} : null;
      }
      if (this.$can(this.$abilitiesActions.READ_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.analytics)) {
        // todo распределить ссылки на разные аналитики
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.thermal_vision))) {
          this.routeThermalVision = {name: ROUTE_EDIT_THERMAL_VISION, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.car_number))) {
          this.routeCarNumber = {name: ROUTE_EDIT_CAR_NUMBER, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.face_recognition))) {
          this.routeFaceRecognition = {name: ROUTE_EDIT_FACE_RECOGNITION, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.helmet))) {
          this.routeHelmet = {name: ROUTE_EDIT_HELMET, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.mask))) {
          this.routeMask = {name: ROUTE_EDIT_MASK, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.perimeter_security))) {
          this.routePerimeter = {name: ROUTE_EDIT_PERIMETER_SECURITY, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.people_counter))) {
          this.routePeopleCounter = {name: ROUTE_EDIT_PEOPLE_COUNT, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.crowd))) {
          this.routeCrowd = {name: ROUTE_EDIT_CROWD, params: {cameraNumber: this.entityId}};
        }
        if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.motion_alarm))) {
          this.routeMotionAlarm = {name: ROUTE_EDIT_MOTION_ALARM, params: {cameraNumber: this.entityId}};
        }
      }

      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.CAMERA_PERMISSION)) {
        this.countPermissionsByUsers = await this.$store.dispatch(`permissions/${ACTION_LOAD_COUNT_PERMISSIONS_FOR_USER_AND_CAMERA}`, {cameraNumber: this.entityId});
      }
      if (this.$can(this.$abilitiesActions.READ_COMMON, this.$abilitiesSubjects.ANALYTIC) && (this.formEdit[FIELDS_CAMERA.analytics].includes(ANALYTICS.car_number))) {
        this.showAnalyticInfo = await this.$store.dispatch(`analytics/carNumbers/${ACTION_LOAD_CAR_NUMBER_FOR_EDIT}`, [
          this.entityId,
          [FIELDS_CAR_NUMBER.zones, FIELDS_CAR_NUMBER.image_height, FIELDS_CAR_NUMBER.image_width],
        ]);
      }
    },

    /**
     * Коррекции подвергаются следующие поля:
     *
     * - longitude, latitude - округлить до 9 знаков,
     * - camera_group_ids - особые указания в зависимости от прав.
     *
     * @link editDataEntityMixin.methods.getDataForSave
     */
    getDataForSave() {
      const cameraInfo = Object.assign({}, this.formEdit);
      cameraInfo[FIELDS_CAMERA.storage] = cameraInfo[FIELDS_CAMERA.storage] ? cameraInfo[FIELDS_CAMERA.storage] : "";
      cameraInfo[FIELDS_CAMERA.number] = this.entityId;
      cameraInfo[FIELDS_CAMERA.longitude] = Number.isFinite(cameraInfo[FIELDS_CAMERA.longitude]) ? _.round(cameraInfo[FIELDS_CAMERA.longitude], 9) : null;
      cameraInfo[FIELDS_CAMERA.latitude] = Number.isFinite(cameraInfo[FIELDS_CAMERA.latitude]) ? _.round(cameraInfo[FIELDS_CAMERA.latitude], 9) : null;

      if (this.$can(this.$abilitiesActions.UPDATE_FIELD, this.$abilitiesSubjects.CAMERA, FIELDS_CAMERA.camera_group_ids)) {
        // Поскольку API установки конечного набора групп доступна только суперам, то для редактирования групп
        // используется две другие API - добавления и удаления. На основе исходной информации формируются два массива
        // с группами которые следует удалить и которые добавить.
        const newSetCameraGroups = cameraInfo[FIELDS_CAMERA.camera_group_ids],
              cameraGroupsForDelete = _.difference(this.sourceData.entityInfo[FIELDS_CAMERA.camera_group_ids], newSetCameraGroups),
              cameraGroupsForAdd = _.difference(newSetCameraGroups, this.sourceData.entityInfo[FIELDS_CAMERA.camera_group_ids]);
        cameraInfo[FIELDS_CAMERA.camera_group_ids] = [cameraGroupsForDelete, cameraGroupsForAdd];
      }
      return cameraInfo;
    },
    /**
     * Отправка запроса на активацию текущей камеры.
     */
    async activateCamera() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_ACTIVATE_CAMERAS}`, {camerasNumbers: [this.entityId]});
      } catch {
        this.$camsdals.alert("Ошибка при включении камеры");
      }
      this.isLoading = false;
      this.loadSourceData();
    },
    /**
     * Отправка запроса на деактивацию текущей камеры.
     */
    async deactivateCamera() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_DEACTIVATE_CAMERAS}`, {camerasNumbers: [this.entityId]});
      } catch {
        this.$camsdals.alert("Ошибка при отключении камеры");
      }
      this.isLoading = false;
      this.loadSourceData();
    },
    /**
     * Отправка запроса на удаление текущей камеры.
     */
    deleteCamera() {
      this.$camsdals.confirm("Хотите удалить эту камеру?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`cameras/${ACTION_DELETE_CAMERAS}`, {camerasNumbers: [this.entityId]});
          this.$camsdals.alert("Камера удалена");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении камеры");
        }
        this.isLoading = false;
      });
    },
    /**
     * Открытие формы для изменения прав клиентов на группу камер.
     */
    openDialogSetPermissions() {
      this.$camsdals.open(
        SetPermissionsForCamerasDialog,
        {cameraNumber: this.entityId},
        {dialogTitle: "Настройки доступа"},
        {name: "js-click-outside"}
      );
    },
    /**
     * Открытие формы для запроса видео с камеры.
     *
     * @param {Array<String>} tokenTypes
     */
    openDialogRequestTokenCamera(tokenTypes) {
      this.$camsdals.open(
        RequestTokenCameraDialog,
        {
          cameraNumber: this.entityId,
          title: this.sourceData.entityInfo[FIELDS_CAMERA.title],
          tokenTypes,
          domain: _.get(this.sourceData.extraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.domain], null),
          vendorName: _.get(this.sourceData.extraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.vendor_name], null),
          streamCount: this.sourceData.entityInfo[FIELDS_CAMERA.streams_count],
          hasSound: this.sourceData.entityInfo[FIELDS_CAMERA.is_sounding],
          hasPtz: this.sourceData.entityInfo[FIELDS_CAMERA.inventory_is_ptz],
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${this.sourceData.entityInfo[FIELDS_CAMERA.title]}`},
        {
          name: "js-click-outside",
          size: "lg",
        }
      );
    },
    /**
     * Открытие модального окна с кодом iframe для встраивания на сайт.
     */
    async openDialogShowZones() {
      this.zonesInfo = this.showAnalyticInfo.entityInfo.zones;
      this.widthInfo = this.showAnalyticInfo.entityInfo.image_width;
      this.heightInfo = this.showAnalyticInfo.entityInfo.image_height;
      let cameraInfo, extraCameraInfo;
      this.isLoading = true;
      try {
        const {entityInfo, extraInfo} = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_EDIT}`, [
          this.entityId,
          [FIELDS_CAMERA.title, FIELDS_CAMERA.streams_count, FIELDS_CAMERA.is_sounding, FIELDS_CAMERA.inventory_is_ptz, FIELDS_CAMERA.server_id],
        ]);
        cameraInfo = entityInfo;
        extraCameraInfo = extraInfo;
      } catch (error) {
        this.$camsdals.alert(error);
        return;
      } finally {
        this.isLoading = false;
      }
      const zonesInfoForEdit = this.zonesInfo || [],
            analyticZonesConfigForEdit = new AnalyticZonesConfig(
              this.widthInfo,
              this.heightInfo,
              3,
              100,
              zonesInfoForEdit.map((rawAnalyticZone) => new AnalyticZone(rawAnalyticZone.coordinates, rawAnalyticZone.sensitivity)));
      this.$camsdals.open(
        RequestTokenCameraDialog,
        {
          cameraNumber: this.entityId,
          title: cameraInfo[FIELDS_CAMERA.title],
          tokenTypes: [TOKEN_TYPES.L, TOKEN_TYPES.R],
          domain: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.domain], null),
          vendorName: _.get(extraCameraInfo, [EXTRAS_CAMERA.server, 0, FIELDS_SERVER.vendor_name], null),
          streamCount: cameraInfo[FIELDS_CAMERA.streams_count],
          hasSound: cameraInfo[FIELDS_CAMERA.is_sounding],
          hasPtz: cameraInfo[FIELDS_CAMERA.inventory_is_ptz],
          analyticZonesConfig: analyticZonesConfigForEdit,
          showZonesOptionsFlag: false,
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${cameraInfo[FIELDS_CAMERA.title]}`},
        {
          name: "js-click-outside",
          size: "lg",
          onClose: (needReload) => needReload ? this.loadSourceData() : null
        }
      );
    },
    openDialogIframe() {
      this.$camsdals.open(
        CameraEmbedScreen,
        {cameraNumber: this.entityId},
        {dialogTitle: "Встраивание на сайт"},
        {
          name: "js-click-outside",
          onClose: (needReload) => needReload ? this.loadSourceData() : null
        },
      );
    },
    /**
     * Открытие формы блокировки камеры.
     */

    openDialogBlockingCamera() {
      this.$camsdals.open(
        BlockingCameraDialog,
        {cameraNumber: this.entityId},
        {dialogTitle: "Блокировка камеры"},
        {
          name: "js-click-outside",
          onClose: (needReload) => needReload ? this.loadSourceData() : null
        },
      );
    },
    /**
     * Отправка запроса для снятия блокировки камеры.
     */
    async unblockingCamera() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_BLOCKING_CAMERAS}`, {camerasNumbers: [this.entityId]});
      } catch {
        this.$camsdals.alert("Ошибка при снятии блокировки камеры");
      }
      this.isLoading = false;
      this.loadSourceData();
    },
    /**
     * Отправка запроса для рестарта потока камеры.
     */
    async restartStream() {
      this.$camsdals.confirm("Хотите перезапустить поток для этой камеры?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`cameras/${ACTION_RESTART_STREAM}`, this.entityId);
        } catch (error){
          this.$camsdals.alert(`Произошла ошибка: ${error}`);
        }
        this.isLoading = false;
      });
    },
    /**
     * Отправка запроса на активацию текущей камеры.
     */
    async enableRecord() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_ENABLE_RECORD}`, this.entityId);
      } catch (error){
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.isLoading = false;
      this.loadSourceData();
    },
    /**
     * Отправка запроса на деактивацию текущей камеры.
     */
    async disableRecord() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`cameras/${ACTION_DISABLE_RECORD}`, this.entityId);
      } catch (error ){
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.isLoading = false;
      this.loadSourceData();
    },
  },
};
</script>
