/**
 * Отдельный vuex модуль для взаимодействия по employee-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для employee. Вызов действия начинать с "employees/"
export const ACTION_LOAD_INFO_EMPLOYEES = "LOAD_INFO_EMPLOYEES";
export const ACTION_LOAD_EMPLOYEES = "LOAD_EMPLOYEES";
export const ACTION_LOAD_EMPLOYEES_FOR_TABLE = "LOAD_EMPLOYEES_FOR_TABLE";
export const ACTION_LOAD_EMPLOYEES_FOR_SELECT = "LOAD_EMPLOYEES_FOR_SELECT";
export const ACTION_LOAD_EMPLOYEE_FOR_EDIT = "LOAD_EMPLOYEE_FOR_EDIT";
export const ACTION_CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const ACTION_EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const ACTION_DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const ACTION_ADD_EMPLOYEE_GROUPS = "ADD_EMPLOYEE_GROUPS";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_EMPLOYEE = Object.freeze({
  id: "id",
  title: "title",
  attached_usernames: "attached_usernames",
  gang_id: "gang_id",
  device_access_group_count: "device_access_group_count",
  device_access_group_ids: "device_access_group_ids",
  car_ids: "car_ids",
  car_count: "car_count",
  is_deleted: "is_deleted",
  is_active: "is_active",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_EMPLOYEE = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  gang_id: "gang_id",
  title: "title",
  device_access_group_id: "device_access_group_id",
});

/**
 * Стандартные названия для полей сотрудников.
 */
export const TITLES_FIELDS_EMPLOYEE = {
  [FIELDS_EMPLOYEE.id]: "ID",
  [FIELDS_EMPLOYEE.title]: "Имя",
  [FIELDS_EMPLOYEE.is_deleted]: "Удален",
  [FIELDS_EMPLOYEE.is_active]: "Активен",
  [FIELDS_EMPLOYEE.gang_id]: "Компания",
  [FIELDS_EMPLOYEE.attached_usernames]: "Имя учетной записи",
  [FIELDS_EMPLOYEE.car_ids]: "Автомобили",
  [FIELDS_EMPLOYEE.car_count]: "Количество автомобилей",
  [FIELDS_EMPLOYEE.device_access_group_count]: "Количество групп доступа к устройствам",
  [FIELDS_EMPLOYEE.device_access_group_ids]: "Группы доступа",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_EMPLOYEE = {
  [FIELDS_EMPLOYEE.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_EMPLOYEE.title]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_EMPLOYEE.is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_EMPLOYEE.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_EMPLOYEE.gang_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_EMPLOYEE.device_access_group_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_EMPLOYEE.car_ids]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с сотрудников.
 */
export const EXTRAS_EMPLOYEE = Object.freeze({
  gang: "gang",
  device_access_group: "device_access_group",
  car: "car",
});

const EXTRAS_BY_FIELDS_EXTRAS_EMPLOYEE = Object.freeze({
  [FIELDS_EMPLOYEE.gang_id]: [EXTRAS_EMPLOYEE.gang],
  [FIELDS_EMPLOYEE.device_access_group_ids]: [EXTRAS_EMPLOYEE.device_access_group],
  [FIELDS_EMPLOYEE.car_ids]: [EXTRAS_EMPLOYEE.car],
  [FIELDS_EMPLOYEE.car_count]: [EXTRAS_EMPLOYEE.car],
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для сотрудников.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_EMPLOYEES]() {
      const response = await this.getters.privateAjax.post("/v0/employees/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка сотрудников.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_EXTRAS_EMPLOYEE, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/employees/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка сотрудников для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_EMPLOYEES, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_EMPLOYEES_FOR_TABLE});
    },
    /**
     * Загрузка списка сотрудников для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных сотрудников.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @param {Number} gangId
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search, gangId = null}) {
      const defaultFiltersForSelect = gangId
          ? [
            makeFilterApi(FILTERS_EMPLOYEE.is_deleted, "=", false),
            makeFilterApi(FILTERS_EMPLOYEE.gang_id, "=", gangId)
          ]
          : [makeFilterApi(FILTERS_EMPLOYEE.is_deleted, "=", false)],
        responseData = await dispatch(ACTION_LOAD_EMPLOYEES, {
          pageSize,
          fields,
          filters: defaultFiltersForSelect,
          search,
          cancelTokenKey: ACTION_LOAD_EMPLOYEES_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного сотрудника для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} employeeId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEE_FOR_EDIT]({dispatch}, [employeeId, fields]) {
      const filter = makeFilterApi(FILTERS_EMPLOYEE.id, "=", employeeId),
        responseData = await dispatch(ACTION_LOAD_EMPLOYEES, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание сотрудника.
     *
     * @param {Object} context
     * @param {Object} employeeInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_EMPLOYEE](context, employeeInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/employees/create/", employeeInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование сотрудника.
     *
     * @param {Object} context
     * @param {Object} employeeInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_EMPLOYEE](context, employeeInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/employees/edit/", employeeInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление сотрудника.
     *
     * @param {Object} context
     * @param {Array} employeeIds
     * @return {Promise}
     */
    async [ACTION_DELETE_EMPLOYEE](context, {employeeIds}) {
      return this.getters.privateAjax.post("/v0/employees/delete/", {ids: employeeIds});
    },
    /**
     * Добавление сотрудника к необходимой группе.
     *
     * @param {Object} context
     * @param {String} employeeId
     * @param {Array} deviceAccessGroupId
     * @return {Promise}
     */
    async [ACTION_ADD_EMPLOYEE_GROUPS](context, {employeeIds, deviceAccessGroupId}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/device_access_groups/employees/add/", {
          employee_ids: employeeIds,
          device_access_group_id: deviceAccessGroupId
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
  },
};
