<template>
  <div v-if="isAvailableFilters" class="settings-filters filters-list">
    <div v-for="(actualFilterData, indexActualFilterData) in filters" :key="indexActualFilterData" class="filters-list__item">
      <div class="checkbox">
        <input v-model="actualFilterData.isActive" class="checkbox__input" type="checkbox">
        <span class="checkbox__img" />
      </div>

      <SmartFilterSelect
        v-model="actualFilterData.name"
        :options="sourceListFiltersInfo"
        field-label="title"
        field-sort="title"
        field-value="name"
        placeholder="Изменить фильтр"
        @input="changeFilter(indexActualFilterData)"
      />

      <button
        :class="classForNegativeButton(indexActualFilterData)"
        :disabled="!isAvailableNegativeButton(indexActualFilterData)"
        class="button button_medium button_icon button_icon-border"
        type="button"
        @click="actualFilterData.isExclude = !actualFilterData.isExclude"
      >
        <svg
          class="icon"
          style="margin: 8px 0 0 12px"
        >
          <use xlink:href="@/assets/img/icons.svg#icon-warning" />
        </svg>
      </button>
      <SmartFilterSelect
        v-model="actualFilterData.lookup"
        :options="sourceListFiltersInfo[actualFilterData.name].lookups"
        class="select_conditions"
        field-label="title"
        field-value="lookup"
        placeholder="Выбрать условие"
        @input="changeLookup(indexActualFilterData)"
      />

      <template v-if="actualFilterData.lookup">
        <template
          v-if="typesValuesForLookups.STR === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <div class="input input_small">
            <input v-model="actualFilterData.value[0]" type="text" @keyup.enter="$emit('submit-filters')">
          </div>
        </template>
        <template
          v-else-if="typesValuesForLookups.INT === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <div class="input input_small">
            <input v-model="actualFilterData.value[0]" type="text" @keyup.enter="$emit('submit-filters')">
          </div>
        </template>
        <template
          v-else-if="typesValuesForLookups.DECIMAL === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <div class="input input_small">
            <input v-model="actualFilterData.value[0]" type="text" @keyup.enter="$emit('submit-filters')">
          </div>
        </template>
        <template
          v-else-if="typesValuesForLookups.DATE_TIME === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <div class="input input_btn input_small input_date">
            <flat-pickr
              :ref="'dateTime'+indexActualFilterData"
              v-model="actualFilterData.value[0]"
              :config="configFlatPickr"
            />
            <button class="button" data-toggle type="button" @click="openRelatedFlatPickr('dateTime'+indexActualFilterData)">
              <svg class="icon icon-calendar">
                <use xlink:href="@/assets/img/icons.svg#icon-calendar" />
              </svg>
            </button>
          </div>
        </template>
        <template
          v-else-if="typesValuesForLookups.DATE_TIME_BETWEEN === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <div class="input input_btn input_small input_date">
            <flat-pickr
              :ref="'dateTimeStart'+indexActualFilterData"
              v-model="actualFilterData.value[0]"
              :config="configFlatPickr"
            />
            <button class="button" data-toggle type="button" @click="openRelatedFlatPickr('dateTimeStart'+indexActualFilterData)">
              <svg class="icon icon-calendar">
                <use xlink:href="@/assets/img/icons.svg#icon-calendar" />
              </svg>
            </button>
          </div>
          <div class="input input_btn input_small input_date">
            <flat-pickr
              :ref="'dateTimeEnd'+indexActualFilterData"
              v-model="actualFilterData.value[1]"
              :config="configFlatPickr"
            />
            <button class="button" data-toggle type="button" @click="openRelatedFlatPickr('dateTimeEnd'+indexActualFilterData)">
              <svg class="icon icon-calendar">
                <use xlink:href="@/assets/img/icons.svg#icon-calendar" />
              </svg>
            </button>
          </div>
        </template>
        <template
          v-else-if="typesValuesForLookups.BOOL === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <label class="switch">
            <input v-model="actualFilterData.value[0]" class="switch__input" type="checkbox">
            <span class="switch__round" />
          </label>
        </template>
        <template
          v-else-if="typesValuesForLookups.ARRAY === sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type"
        >
          <div class="input input_small">
            <input v-model="actualFilterData.value[0]" type="text" @keyup.enter="$emit('submit-filters')">
          </div>
        </template>
        <template v-else>
          <span>#</span>
        </template>
      </template>

      <button class="button button_icon button_xsmall" type="button" @click="deleteFilter(indexActualFilterData)">
        <svg class="icon">
          <use xlink:href="@/assets/img/icons.svg#icon-delete" />
        </svg>
      </button>
    </div>

    <div class="filters-list__item">
      <SmartFilterSelect
        v-model="newFilter"
        :options="sourceListFiltersInfo"
        :show-finder="true"
        field-label="title"
        field-sort="title"
        field-value="name"
        placeholder="Добавить фильтр"
        @change="newFilter = ''"
        @input="addNewFilter()"
      />
      <button class="button button_small button_btn-icon" type="button" @click="$emit('submit-filters')">
        Применить фильтры
        <svg
          class="icon sidebar__icon"
          style="padding-top: 2px"
        >
          <use xlink:href="@/assets/img/icons.svg#icon-apply" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import SmartFilterSelect from "@/components/smart/table/SmartFilterSelect.vue";
import {FilterData, TYPES_VALUES_FOR_LOOKUPS} from "@/utils/helpers.js";
import {Russian} from "flatpickr/dist/l10n/ru.js";

/**
 * Компонент блока выбора и установки фильтров для таблицы.
 * Строит интерфейс отдельных фильтров на основании исходных данных всех возможных фильтров для таблицы
 * и на основе уже переданных фильтров.
 */
export default {
  components: {
    SmartFilterSelect,
  },
  props: {
    sourceListFiltersInfo: {
      type: Object,
      default: () => ({}),
    },
    initialFilters: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      filters: this.initialFilters,
      newFilter: "",
      configFlatPickr: {
        altFormat: "d.m.Y H:i:S",
        altInput: true,
        locale: Russian,
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
        dateFormat: "Z",
      },
      typesValuesForLookups: TYPES_VALUES_FOR_LOOKUPS
    };
  },
  computed: {
    /**
     * Вернет True если есть возможность выбирать фильтры.
     *
     * @return {Boolean}
     */
    isAvailableFilters() {
      return !_.isEmpty(this.sourceListFiltersInfo);
    },
  },
  watch: {
    /**
     * Отслеживание изменений в массиве и в свойствах объектов, из которых этот массив состоит.
     */
    filters: {
      handler(val) {
        this.$emit("change-filters", val);
      },
      deep: true
    },
    /**
     * Синхронизация начальных значений фильтров и значений внутри компонента необходима
     * в случаях внешнего их изменения, например в случае сброса формы.
     */
    initialFilters() {
      this.filters = this.initialFilters;
    }
  },
  methods: {
    /**
     * Выбор фильтра.
     *
     * Поскольку в реактивные переменные помещаются специальные объекты (FilterData), хочется чтобы и они были реактивными,
     * поэтому для манипулирования реактивным массивом лучше применять методы, а не простые операции вставки/удаления.
     *
     * В компоненте отдельно прописана функция для обнуления значение модели
     * чтобы сохранять в выпающем списке состояние не выбранного фильтра.
     */
    addNewFilter() {
      this.filters.push(new FilterData(this.newFilter));
    },
    /**
     * Изменение существующего фильтра.
     * Спровоцирует создание нового фильтра без данных, но на том же месте где и размещается изменяемый.
     *
     * @param {Number} indexActualFilterData
     */
    changeFilter(indexActualFilterData) {
      const newFilter = this.filters[indexActualFilterData].name;
      this.filters.splice(indexActualFilterData, 1, new FilterData(newFilter));
    },
    /**
     * Изменение метода существующего фильтра изменит его значение на по-умолчанию в зависимости от типа принимаемых значений методом.
     *
     * @param {Number} indexActualFilterData
     */
    changeLookup(indexActualFilterData) {
      const actualFilterData = this.filters[indexActualFilterData],
            actualLookupType = this.sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].type;
      actualFilterData.value = {
        [TYPES_VALUES_FOR_LOOKUPS.INT]: [""],
        [TYPES_VALUES_FOR_LOOKUPS.STR]: [""],
        [TYPES_VALUES_FOR_LOOKUPS.BOOL]: [false],
        [TYPES_VALUES_FOR_LOOKUPS.DECIMAL]: [""],
        [TYPES_VALUES_FOR_LOOKUPS.ARRAY]: [[]],
        [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME]: [new Date()],
        [TYPES_VALUES_FOR_LOOKUPS.DATE_TIME_BETWEEN]: [new Date(), new Date()],
      }[actualLookupType] || [];
    },
    /**
     * Удаление существующего фильтра.
     *
     * @param {Number} indexActualFilterData
     */
    deleteFilter(indexActualFilterData) {
      this.filters.splice(indexActualFilterData, 1);
    },
    /**
     * Вернет класс для кнопки исключения значения фильтра. Выведен в отдельный метод из-за громоздкости проверки в шаблоне.
     *
     * @param {Number} indexActualFilterData
     * @return {Boolean}
     */
    classForNegativeButton(indexActualFilterData) {
      if (!this.isAvailableNegativeButton(indexActualFilterData)) {
        return "button_negative_disabled";
      }
      if (this.filters[indexActualFilterData].isExclude) {
        return "button_negative_active";
      }
      return "button_negative";
    },
    /**
     * Проверяет доступность кнопки исключения значения фильтра.
     *
     * @param {Number} indexActualFilterData
     * @return {Boolean}
     */
    isAvailableNegativeButton(indexActualFilterData) {
      const actualFilterData = this.filters[indexActualFilterData];
      return actualFilterData.lookup && this.sourceListFiltersInfo[actualFilterData.name].lookups[actualFilterData.lookup].isAvailableExclude;
    },
    /**
     * Метод вызовет открытие календаря у указанного ref-элемента.
     * Применяется для кнопок, стоящих рядом с компонентами-календарями,
     * которым необходимо обеспечить открытие через клик этими соседними кнопками.
     *
     * При вызове метода надо быть уверенным что ref ссылается на правильный элемент - в имени нужен порядковый индекс,
     * в противном случае все клики будут идти на первый элемент.
     */
    openRelatedFlatPickr(ref) {
      this.$refs[ref][0].fp.open();
    }
  },
};
</script>
