<template>
  <div>
    <div class="row">
      <p>Миграция камер</p>
    </div>

    <div class="row">
      <div class="col">
        <div class="param">
          <div class="param__name">
            Выберите тип
          </div>
          <div class="radiobuttons-group">
            <div class="radiobutton">
              <input
                id="chooseCluster"
                v-model="chooseCluster"
                :value="true"
                class="radiobutton__input"
                name="type"
                type="radio"
                @click="choiceClusterServer"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseCluster">Кластер</label>
            </div>
            <div class="radiobutton">
              <input
                id="chooseServer"
                v-model="chooseCluster"
                :value="false"
                class="radiobutton__input"
                name="type"
                type="radio"
                @click="choiceClusterServer"
              >
              <span class="radiobutton__img" />
              <label class="radiobutton__label" for="chooseServer">Сервер</label>
            </div>
          </div>
          <div v-show="errorChoiceClusterServer" class="error" v-html="errorChoiceClusterServer" />
        </div>
      </div>
    </div>

    <div v-show="chooseCluster" class="row">
      <SmartVSelect
        v-model="formEdit.clusterId"
        :settings-remote-search="settingsSelects.clusterId"
        caption="Новый кластер"
        class="col"
        @input="(errorChoiceClusterServer = '') || changePromise()"
      />
    </div>

    <div v-show="!chooseCluster" class="row">
      <SmartVSelect
        v-model="formEdit.serverId"
        :settings-remote-search="settingsSelects.serverId"
        caption="Новый сервер"
        class="col"
        @input="(errorChoiceClusterServer = '') || changePromise()"
      />
    </div>

    <div class="col">
      <SmartSwitch v-model="formEdit.force" caption="Форсированный режим" @input="changePromise()" />
      <SmartSwitch v-model="formEdit.confirmable" caption="Требует подтверждения миграции" @input="changePromise()" />
    </div>
  </div>
</template>

<script>
import {functionMultiEditMixin} from "@/utils/mixins.js";
import {ACTION_CREATE_MIGRATION_CAMERAS} from "@/store/cameras/index.js";
import {ACTION_LOAD_SERVERS_FOR_SELECT, FIELDS_SERVER} from "@/store/heavyMetal/servers/index.js";
import {ACTION_LOAD_CLUSTERS_FOR_SELECT, FIELDS_CLUSTER} from "@/store/heavyMetal/clusters/index.js";
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";

/**
 * Компонент функции мультиредактора камер для постановки задач на миграцию камер на другой сервер/кластер.
 */
export default {
  mixins: [functionMultiEditMixin],
  data() {
    return {
      chooseCluster: true,
      errorChoiceClusterServer: "",
      settingsSelects: {
        serverId: {
          action: `servers/${ACTION_LOAD_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_SERVER.id,
          labelField: FIELDS_SERVER.domain
        },
        clusterId: {
          action: `clusters/${ACTION_LOAD_CLUSTERS_FOR_SELECT}`,
          valueField: FIELDS_CLUSTER.id,
          labelField: FIELDS_CLUSTER.name
        },
      },
      formEdit: {
        serverId: null,
        clusterId: null,
        force: false,
        confirmable: false,
      },
    };
  },
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     */
    changePromise() {
      this.$emit("change-promise", (entityKey) => {
        this.chooseCluster ? (this.formEdit.serverId = null) : (this.formEdit.clusterId = null);
        return this.$store
          .dispatch(`cameras/${ACTION_CREATE_MIGRATION_CAMERAS}`, {cameraNumber: entityKey, ...this.formEdit})
          .then(() => ResultProcessingEntityMultiEdit.success(entityKey))
          .catch((error) => ResultProcessingEntityMultiEdit.errorRequest(entityKey, error));
      });
    },
    /**
     * Отслеживание события клика по переключателю отображения полей кластер/сервер.
     *
     * Необходимо для корректного отображения ошибок о невозможности одновременного выбора кластера и сервера.
     * В случае заполненного ранее выбранного поля, прервется выполнение события клика по переключателю.
     *
     * @param {Event} eventClick
     */
    choiceClusterServer(eventClick) {
      this.errorChoiceClusterServer = "";
      if (this.formEdit.clusterId || this.formEdit.serverId) {
        const titleFieldForEscape = this.formEdit.clusterId ? "Новый кластер" : "Новый сервер";
        this.errorChoiceClusterServer = `Для изменения типа очистите поле "<b>${titleFieldForEscape}</b>"`;
        eventClick.preventDefault();
      }
    },
  },
};
</script>
