/**
 * Отдельный vuex модуль для взаимодействия по gangs-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для gangs. Вызов действия начинать с "gangs/"
export const ACTION_LOAD_INFO_GANGS = "LOAD_INFO_GANGS";
export const ACTION_LOAD_GANGS = "LOAD_GANGS";
export const ACTION_LOAD_GANGS_FOR_TABLE = "LOAD_GANGS_FOR_TABLE";
export const ACTION_LOAD_GANGS_FOR_SELECT = "LOAD_GANGS_FOR_SELECT";
export const ACTION_LOAD_GANG_FOR_EDIT = "LOAD_GANG_FOR_EDIT";
export const ACTION_CREATE_GANG = "CREATE_GANG";
export const ACTION_EDIT_GANG = "EDIT_GANG";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_GANG = Object.freeze({
  id: "id",
  name: "name",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_GANG = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
});

/**
 * Стандартные названия для полей группы камер.
 */
export const TITLES_FIELDS_GANG = {
  [FIELDS_GANG.id]: "ID",
  [FIELDS_GANG.name]: "Название компании",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_GANG = {
  [FIELDS_GANG.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_GANG.name]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
};

/**
 * Перечисления опций для загрузки дополнительной информации.
 */
export const EXTRAS_GANG = Object.freeze({
  name: "name",
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для компаний.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_GANGS]() {
      const response = await this.getters.privateAjax.post("/v0/gangs/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка компаний.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_GANGS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/gangs/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка компаний для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_GANGS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_GANGS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_GANGS_FOR_TABLE});
    },
    /**
     * Загрузка списка компаний для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_GANGS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const responseData = await dispatch(ACTION_LOAD_GANGS, {
        pageSize,
        fields,
        filters: [],
        search,
      });
      return responseData.results;
    },
    /**
     * Загрузка одной компании для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} gangId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_GANG_FOR_EDIT]({dispatch}, [gangId, fields]) {
      const filter = makeFilterApi(FILTERS_GANG.id, "=", gangId),
        responseData = await dispatch(ACTION_LOAD_GANGS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание компании.
     *
     * @param {Object} context
     * @param {Object} gangInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_GANG](context, gangInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/gangs/create/", gangInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование компании.
     *
     * @param {Object} context
     * @param {Object} gangInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_GANG](context, gangInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/gangs/edit/", gangInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
  },
};
