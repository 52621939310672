<template>
  <div>
    <ProcessingMultiEditDialog
      v-if="goProcessing"
      :promise-save-data="promiseSaveData"
      :selected-entities="selectedEntities"
    />
    <div v-else>
      <p v-if="countSelectedOriginally !== selectedEntities.length">
        Внимание: количество строк в фильтрации и количество для множественного редактирования - не совпадают!
      </p>

      <div class="row">
        <div class="col-2">
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.EDIT_CAR_NUMBER ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.EDIT_CAR_NUMBER"
            >
              Редактирование камер с аналитикой а/м
            </CamsButton>
          </div>
          <div v-if="$can($abilitiesActions.SUPER_ACTION, $abilitiesSubjects.SUPER_SUBJECT)" class="row">
            <CamsButton
              :class="[selectedComponentMultiEdit === componentsMultiEdit.EDIT_HELMET ? 'button_btn-blue' : 'button_btn-default']"
              type="button"
              @click="selectedComponentMultiEdit = componentsMultiEdit.EDIT_HELMET"
            >
              Редактирование камер с аналитикой касок
            </CamsButton>
          </div>
        </div>

        <div class="col-2">
          <div v-show="!selectedComponentMultiEdit" class="row">
            <p>Выберите функцию для массового действия.</p>
          </div>

          <div class="row">
            <component
              :is="selectedComponentMultiEdit"
              v-if="selectedComponentMultiEdit"
              class="col"
              @change-promise="promiseSaveData = $event"
              @ready-for-processing="isReadyForProcessing = $event"
            />
          </div>

          <div v-show="selectedComponentMultiEdit" class="dialog-actions">
            <CamsButton
              type="button"
              @click="closeDialog()"
            >
              Отменить
            </CamsButton>
            <CamsButton
              :disabled="!isReadyForProcessing"
              priority="primary"
              type="button"
              @click="goProcessing = true"
            >
              Применить
            </CamsButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditCarNumber from "@/components/analytics/carNumbers/functionsMultiEdit/EditCarNumber.vue";
import EditHelmet from "@/components/analytics/helmets/functionsMultiEdit/EditHelmet.vue";
import ProcessingMultiEditDialog from "@/components/dialogs/ProcessingMultiEditDialog.vue";
import {methodsForDialogMixin, multiEditEntityMixin} from "@/utils/mixins.js";

const COMPONENTS_MULTI_EDIT = Object.freeze({
  EDIT_CAR_NUMBER: EditCarNumber,
  EDIT_HELMET: EditHelmet,
});

/**
 * Компонент для организации множественного редактирования камер.
 */
export default {
  components: {
    ProcessingMultiEditDialog,
    EditCarNumber,
    EditHelmet,
  },
  mixins: [
    methodsForDialogMixin,
    multiEditEntityMixin
  ],
  data() {
    return {
      componentsMultiEdit: COMPONENTS_MULTI_EDIT,
    };
  },
};
</script>
