/**
 * Отдельный vuex модуль для взаимодействия по analytics/servers-API
 */

import {DEFAULT_PAGE_SIZE_FOR_SELECT, DEFAULT_PAGE_SIZE_FOR_TABLE, SPECIAL_TYPES_DATA_IN_CELLS} from "@/utils/consts.js";
import {FilterInfo, makeFilterApi} from "@/utils/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";
import {REPLACE_SORT_CAMERA} from "@/store/cameras/index.js";
import {FIELDS_HISTORY} from "@/store/history/index.js";
// actions для servers. Вызов действия начинать с "analytics/servers/"
export const ACTION_LOAD_INFO_ANALYTIC_SERVERS = "LOAD_INFO_ANALYTIC_SERVERS";
export const ACTION_LOAD_ANALYTIC_SERVERS = "LOAD_ANALYTIC_SERVERS";
export const ACTION_LOAD_ANALYTIC_SERVERS_FOR_TABLE = "LOAD_ANALYTIC_SERVERS_FOR_TABLE";
export const ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT = "LOAD_ANALYTIC_SERVERS_FOR_SELECT";
export const ACTION_LOAD_ANALYTIC_SERVER_FOR_EDIT = "LOAD_ANALYTIC_SERVER_FOR_EDIT";
export const ACTION_CREATE_ANALYTIC_SERVER = "CREATE_ANALYTIC_SERVER";
export const ACTION_EDIT_ANALYTIC_SERVER = "EDIT_ANALYTIC_SERVER";
export const ACTION_DELETE_ANALYTIC_SERVERS = "DELETE_ANALYTIC_SERVERS";
export const ACTION_LOAD_ANALYTIC_SERVERS_HISTORY = "LOAD_ANALYTIC_SERVERS_HISTORY";
export const ACTION_LOAD_INFO_ANALYTIC_SERVER_HISTORY = "LOAD_INFO_ANALYTIC_SERVER_HISTORY";
export const ACTION_LOAD_ANALYTIC_SERVERS_HISTORY_FOR_TABLE = "LOAD_ANALYTIC_SERVERS_HISTORY_FOR_TABLE";
export const ACTION_ADD_ANALYTIC_CLUSTER_SERVER = "ADD_ANALYTIC_CLUSTER_SERVER";
export const ACTION_SET_ANALYTIC_CLUSTER_SERVER = "SET_CLUSTER_SERVER";
export const ACTION_EDIT_ANALYTIC_SERVER_WITH_ALL = "EDIT_ANALYTIC_SERVER_WITH_ALL";
/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_ANALYTIC_SERVER = Object.freeze({
  id: "id",
  front_id: "front_id",
  ips: "ips",
  ips_count: "ips_count",
  log_lvl: "log_lvl",
  car_number_is_active: "car_number_is_active",
  helmet_is_active: "helmet_is_active",
  mask_is_active: "mask_is_active",
  crowd_is_active: "crowd_is_active",
  thermal_vision_is_active: "thermal_vision_is_active",
  perimeter_security_is_active: "perimeter_security_is_active",
  people_counter_is_active: "people_counter_is_active",
  thermal_vision_workers: "thermal_vision_workers",
  face_recognition_is_active: "face_recognition_is_active",
  face_recognition_workers: "face_recognition_workers",
  feature_computation_is_active: "feature_computation_is_active",
  feature_computation_workers: "feature_computation_workers",
  car_number_event_is_active: "car_number_event_is_active",
  car_number_event_workers: "car_number_event_workers",
  sync_success: "sync_success",
  sync_last_success: "sync_last_success",
  sync_error_stage: "sync_error_stage",
  sync_face_recognition_is_alive: "sync_face_recognition_is_alive",
  sync_error: "sync_error",
  sync_data: "sync_data",
  is_deleted: "is_deleted",
  analytic_car_number_camera_numbers: "analytic_car_number_camera_numbers",
  analytic_car_number_camera_numbers_count: "analytic_car_number_camera_numbers_count",
  analytic_helmet_camera_numbers: "analytic_helmet_camera_numbers",
  analytic_helmet_camera_numbers_count: "analytic_helmet_camera_numbers_count",
  analytic_mask_camera_numbers: "analytic_mask_camera_numbers",
  analytic_mask_camera_numbers_count: "analytic_mask_camera_numbers_count",
  analytic_crowd_camera_numbers: "analytic_crowd_camera_numbers",
  analytic_crowd_camera_numbers_count: "analytic_crowd_camera_numbers_count",
  analytic_face_recognition_camera_numbers: "analytic_face_recognition_camera_numbers",
  analytic_face_recognition_camera_numbers_count: "analytic_face_recognition_camera_numbers_count",
  analytic_perimeter_security_camera_numbers: "analytic_perimeter_security_camera_numbers",
  analytic_perimeter_security_camera_numbers_count: "analytic_perimeter_security_camera_numbers_count",
  analytic_people_counter_camera_numbers: "analytic_people_counter_camera_numbers",
  analytic_people_counter_camera_numbers_count: "analytic_people_counter_camera_numbers_count",
  description: "description",
  video_card_count: "video_card_count",
  max_free_memory: "max_free_memory",
  analytic_cluster_ids: "analytic_cluster_ids",
});

/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_ANALYTIC_SERVER = Object.freeze({
  id: "id",
  is_deleted: "is_deleted",
  analytic_cluster_id: "analytic_cluster_id",
});

/**
 * Стандартные названия для полей сервера аналитики.
 */
export const TITLES_FIELDS_ANALYTIC_SERVER = {
  [FIELDS_ANALYTIC_SERVER.id]: "ID",
  [FIELDS_ANALYTIC_SERVER.front_id]: "Front ID",
  [FIELDS_ANALYTIC_SERVER.ips]: "IP-адреса",
  [FIELDS_ANALYTIC_SERVER.ips_count]: "Кол-во IP",
  [FIELDS_ANALYTIC_SERVER.log_lvl]: "log_lvl",
  [FIELDS_ANALYTIC_SERVER.analytic_cluster_ids]: "Кластер аналатики",
  [FIELDS_ANALYTIC_SERVER.car_number_is_active]: "Аналитика а/м номеров активна?",
  [FIELDS_ANALYTIC_SERVER.helmet_is_active]: "Аналитика по каскам активна?",
  [FIELDS_ANALYTIC_SERVER.mask_is_active]: "Аналитика по маскам активна?",
  [FIELDS_ANALYTIC_SERVER.perimeter_security_is_active]: "Аналитика по пересечению переиметра активна?",
  [FIELDS_ANALYTIC_SERVER.crowd_is_active]: "Аналитика по детекции толпы активна?",
  [FIELDS_ANALYTIC_SERVER.thermal_vision_is_active]: "Аналитика тепловизоров активна?",
  [FIELDS_ANALYTIC_SERVER.thermal_vision_workers]: "Аналитика тепловизоров, кол-во процессов",
  [FIELDS_ANALYTIC_SERVER.face_recognition_is_active]: "Аналитика по лицам активна?",
  [FIELDS_ANALYTIC_SERVER.face_recognition_workers]: "Аналитика по лицам, кол-во процессов",
  [FIELDS_ANALYTIC_SERVER.feature_computation_is_active]: "Аналитика вычисление признаков активна?",
  [FIELDS_ANALYTIC_SERVER.people_counter_is_active]: "Аналитика подсчета посетителей активна?",
  [FIELDS_ANALYTIC_SERVER.feature_computation_workers]: "Аналитика вычисление признаков, кол-во процессов",
  [FIELDS_ANALYTIC_SERVER.car_number_event_is_active]: "Событийная аналитика распознавания а/м, активна?",
  [FIELDS_ANALYTIC_SERVER.car_number_event_workers]: "Событийная аналитика распознавания а/м, кол-во процессов",
  [FIELDS_ANALYTIC_SERVER.sync_success]: "Успешность последней синхронизации",
  [FIELDS_ANALYTIC_SERVER.sync_last_success]: "Дата последней успешной синхронизации",
  [FIELDS_ANALYTIC_SERVER.sync_error_stage]: "Этап на котором произошла ошибка",
  [FIELDS_ANALYTIC_SERVER.sync_face_recognition_is_alive]: "Аналитика распознавания лиц жива?",
  [FIELDS_ANALYTIC_SERVER.sync_error]: "Ошибка",
  [FIELDS_ANALYTIC_SERVER.sync_data]: "Ответ от сервера аналитики",
  [FIELDS_ANALYTIC_SERVER.is_deleted]: "Удален",
  [FIELDS_ANALYTIC_SERVER.description]: "Описание",
  [FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers]: "Аналитики а/м номеров",
  [FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers_count]: "Кол-во аналитик а/м номеров",
  [FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers]: "Аналитики по каскам",
  [FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers_count]: "Кол-во аналитик по каскам",
  [FIELDS_ANALYTIC_SERVER.analytic_mask_camera_numbers]: "Аналитики по маскам",
  [FIELDS_ANALYTIC_SERVER.analytic_mask_camera_numbers_count]: "Кол-во аналитик по маскам",
  [FIELDS_ANALYTIC_SERVER.analytic_crowd_camera_numbers]: "Аналитики детекции толпы",
  [FIELDS_ANALYTIC_SERVER.analytic_crowd_camera_numbers_count]: "Кол-во аналитик по детекции толпы",
  [FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers]: "Аналитики по лицам",
  [FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers_count]: "Кол-во аналитик по лицам",
  [FIELDS_ANALYTIC_SERVER.analytic_people_counter_camera_numbers]: "Аналитики подсчета посетителей",
  [FIELDS_ANALYTIC_SERVER.analytic_people_counter_camera_numbers_count]: "Кол-во аналитик по подсчету посетителей",
  [FIELDS_ANALYTIC_SERVER.analytic_perimeter_security_camera_numbers]: "Аналитики пересечения периметра",
  [FIELDS_ANALYTIC_SERVER.analytic_perimeter_security_camera_numbers_count]: "Кол-во аналитик по пересечению",
  [FIELDS_ANALYTIC_SERVER.video_card_count]: "Количество видеокарт",
  [FIELDS_ANALYTIC_SERVER.max_free_memory]: "Свободная память",
};

/**
 * Связь между названиями и специальными типами полей.
 */
export const TYPES_FIELDS_ANALYTIC_SERVER = {
  [FIELDS_ANALYTIC_SERVER.id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_SERVER.front_id]: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
  [FIELDS_ANALYTIC_SERVER.car_number_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.helmet_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.mask_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.crowd_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.thermal_vision_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.face_recognition_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.people_counter_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.feature_computation_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ANALYTIC_SERVER.analytic_mask_camera_numbers]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ANALYTIC_SERVER.analytic_crowd_camera_numbers]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ANALYTIC_SERVER.ips]: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
  [FIELDS_ANALYTIC_SERVER.sync_success]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.sync_last_success]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
  [FIELDS_ANALYTIC_SERVER.sync_face_recognition_is_alive]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
};

/**
 * Перечисления опций для загрузки дополнительной информации вместе с серверами аналитики.
 */
export const EXTRAS_ANALYTIC_SERVER = Object.freeze({
  analytic_car_number: "analytic_car_number",
  analytic_helmet: "analytic_helmet",
  analytic_mask: "analytic_mask",
  analytic_face_recognition: "analytic_face_recognition",
  analytic_crowd: "analytic_crowd",
  analytic_cluster: "analytic_cluster",
});

const EXTRAS_BY_FIELDS_ANALYTIC_SERVER = Object.freeze({
  [FIELDS_ANALYTIC_SERVER.analytic_car_number_camera_numbers]: [EXTRAS_ANALYTIC_SERVER.analytic_car_number],
  [FIELDS_ANALYTIC_SERVER.analytic_helmet_camera_numbers]: [EXTRAS_ANALYTIC_SERVER.analytic_helmet],
  [FIELDS_ANALYTIC_SERVER.analytic_mask_camera_numbers]: [EXTRAS_ANALYTIC_SERVER.analytic_mask],
  [FIELDS_ANALYTIC_SERVER.analytic_crowd_camera_numbers]: [EXTRAS_ANALYTIC_SERVER.analytic_crowd],
  [FIELDS_ANALYTIC_SERVER.analytic_face_recognition_camera_numbers]: [EXTRAS_ANALYTIC_SERVER.analytic_helmet],
  [FIELDS_ANALYTIC_SERVER.analytic_cluster_ids]: [EXTRAS_ANALYTIC_SERVER.analytic_cluster],
});
/**
 * Массив значений для выбора уровня логгирования сервера аналитики.
 */
export const LOGGING_LEVEL = [
  "CRITICAL",
  "ERROR",
  "WARNING",
  "INFO",
  "DEBUG"
];

// Поля для отображения истории по серверам аналитик
export const FIELDS_ANALYTIC_SERVER_HISTORY = Object.freeze({
  helmet_is_active: "helmet_is_active",
  hpk: "hpk",
  id: "id",
  ips: "ips",
  is_deleted: "is_deleted",
  log_lvl: "log_lvl",
  mask_is_active: "mask_is_active",
  people_counter_is_active: "people_counter_is_active",
  perimeter_security_is_active: "perimeter_security_is_active",
  thermal_vision_is_active: "thermal_vision_is_active",
  thermal_vision_workers: "thermal_vision_workers",
  video_card_count: "video_card_count",
  max_free_memory: "max_free_memory",
  car_number_event_is_active: "car_number_event_is_active",
  car_number_event_workers: "car_number_event_workers",
  car_number_is_active: "car_number_is_active",
  crowd_is_active: "crowd_is_active",
  description: "description",
  extra_id: "extra_id",
  face_recognition_is_active: "face_recognition_is_active",
  face_recognition_workers: "face_recognition_workers",
  feature_computation_is_active: "feature_computation_is_active",
  feature_computation_workers: "feature_computation_workers",
  front_id: "front_id",
  history_create_date: "history_create_date",

});
/**
 * Набор заготовленных фильтров для использования в конкретных случаях.
 */
export const FILTERS_ANALYTIC_SERVER_HISTORY = Object.freeze({
  is_deleted: "is_deleted",
  data_change_event_date: "data_change_event_date",
  data_change_event_user_id: "data_change_event_user_id",
});
export const TITLES_FIELDS_ANALYTIC_SERVER_HISTORY = {
  [FIELDS_ANALYTIC_SERVER_HISTORY.car_number_event_is_active]: "Аналитика распознования автомобильных номеров активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.car_number_event_workers]:"АСобытие номер автомобиля рабочая",
  [FIELDS_ANALYTIC_SERVER_HISTORY.car_number_is_active]: "Аналитика car_number активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.crowd_is_active]:"Аналитика топлы активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.description]: "Описание",
  [FIELDS_ANALYTIC_SERVER_HISTORY.extra_id]: "Дополнительный ID",
  [FIELDS_ANALYTIC_SERVER_HISTORY.face_recognition_is_active]: "Аналитика Распознавания лица активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.face_recognition_workers]: "Аналитика распознования лица рабочая активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.feature_computation_is_active]: "Аналитика feature_computation активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.feature_computation_workers]: "Аналитика feature_computation_workers активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.front_id]: "Внешний ID",
  [FIELDS_ANALYTIC_SERVER_HISTORY.helmet_is_active]: "Аналитика helmet_is_active активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.hpk]: "ID Записи в истории",
  [FIELDS_ANALYTIC_SERVER_HISTORY.id]: "ID",
  [FIELDS_ANALYTIC_SERVER_HISTORY.ips]: "IP адреса с которых разрешён доступ",
  [FIELDS_ANALYTIC_SERVER_HISTORY.is_deleted]: "Удален?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.log_lvl]: "Аналитика log_lvl",
  [FIELDS_ANALYTIC_SERVER_HISTORY.mask_is_active]: "Аналитика mask_is_active активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.max_free_memory]: "Свободная память",
  [FIELDS_ANALYTIC_SERVER_HISTORY.people_counter_is_active]: "Аналитика подсчета людей активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.perimeter_security_is_active]: "Аналитика охраны периметра активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.thermal_vision_is_active]: "Аналитика thermal_vision активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.thermal_vision_workers]: "Аналитика thermal_vision_workers активна?",
  [FIELDS_ANALYTIC_SERVER_HISTORY.video_card_count]: "Количество видеокарт",
};



export const EXTRAS_ANALYTIC_SERVER_HISTORY = Object.freeze({
  user: "user",
});

const EXTRAS_BY_FIELDS_ANALYTIC_SERVER_HISTORY = Object.freeze({
  [FIELDS_HISTORY.data_change_event_user_id]: [EXTRAS_ANALYTIC_SERVER_HISTORY.user],

});
export const TYPES_FIELDS_ANALYTIC_SERVER_HISTORY = {
  [FIELDS_ANALYTIC_SERVER_HISTORY.mask_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.helmet_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.face_recognition_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.people_counter_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.thermal_vision_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.car_number_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.feature_computation_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.is_deleted]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_ANALYTIC_SERVER_HISTORY.perimeter_security_is_active]: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
  [FIELDS_HISTORY.data_change_event_date]: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка служебной информации для серверов аналитики.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_ANALYTIC_SERVERS]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/servers/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка серверов аналитики.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_ANALYTIC_SERVER, fields)));

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка списка серверов аналитики для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_ANALYTIC_SERVERS, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_ANALYTIC_SERVERS_FOR_TABLE});
    },
    /**
     * Загрузка списка серверов аналитики для отображения в селекте.
     * Зафиксирован фильтр для исключения удаленных серверов.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {Array} fields
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, fields, search}) {
      const defaultFilterForSelect = makeFilterApi(FILTERS_ANALYTIC_SERVER.is_deleted, "=", false),
        responseData = await dispatch(ACTION_LOAD_ANALYTIC_SERVERS, {
          pageSize,
          fields,
          filters: [defaultFilterForSelect],
          search,
          cancelTokenKey: ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT
        });
      return responseData.results;
    },
    /**
     * Загрузка одного сервера аналитики для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} analyticServerId
     * @param {Array} fields
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVER_FOR_EDIT]({dispatch}, [analyticServerId, fields]) {
      const filter = makeFilterApi(FILTERS_ANALYTIC_SERVER.id, "=", analyticServerId),
        responseData = await dispatch(ACTION_LOAD_ANALYTIC_SERVERS, {page: 1, pageSize: 1, fields, filters: [filter]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Создание нового сервера аналитики.
     *
     * @param {Object} context
     * @param {Object} analyticServerInfo
     * @return {Promise}
     */
    async [ACTION_CREATE_ANALYTIC_SERVER](context, analyticServerInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/create/", analyticServerInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Редактирование существующего сервера аналитики.
     *
     * @param {Object} context
     * @param {Object} analyticServerInfo
     * @return {Promise}
     */
    async [ACTION_EDIT_ANALYTIC_SERVER](context, analyticServerInfo) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/edit/", analyticServerInfo);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление серверов аналитики.
     *
     * @param {Object} context
     * @param {Array<Number>} analyticServerIds
     * @return {Promise}
     */
    async [ACTION_DELETE_ANALYTIC_SERVERS](context, {analyticServerIds}) {
      return this.getters.privateAjax.post("/v0/analytics/servers/delete/", {ids: analyticServerIds});
    },
    /**
     * Загрузка служебной информации для истории изменения сервера.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_INFO_ANALYTIC_SERVER_HISTORY]() {
      const response = await this.getters.privateAjax.post("/v0/analytics/servers/history/info/");
      return [FilterInfo.createFromDataApi(response.data.filters), response.data.orders];
    },
    /**
     * Загрузка списка истории изменения камер.
     * Реализуется подмена сортировки по полям {@link REPLACE_SORT_CAMERA}.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @param {String} cancelTokenKey
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS_HISTORY](context, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey = ""}) {
      const extra = _.flatten(_.values(_.pick(EXTRAS_BY_FIELDS_ANALYTIC_SERVER_HISTORY, fields))),
        fixedOrderBy = orderBy && orderBy.map((itemOrder) => {
          itemOrder.field = _.get(REPLACE_SORT_CAMERA, itemOrder.field, itemOrder.field);
          return itemOrder;

        });

      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/history", {
          page,
          page_size: pageSize,
          order_by: fixedOrderBy,
          fields,
          filters,
          extra,
          search
        }, {cancelToken: cancelRequestOnReentry(cancelTokenKey)});
        return response.data;
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Добавление сервера к кластеру.
     *
     * @param {Object} context
     * @param {Number} analyticServerId
     * @param {Array<Number>} clusterIds
     * @return {Promise}
     */
    async [ACTION_ADD_ANALYTIC_CLUSTER_SERVER](context, {analyticServerId, clusterIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/clusters/add/", {
          analytic_server_id: analyticServerId,
          analytic_cluster_ids: clusterIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Изменение кластеров у сервера.
     *
     * @param {Object} context
     * @param {Number} analyticServerId
     * @param {Array<Number>} clusterIds
     * @return {Promise}
     */
    async [ACTION_SET_ANALYTIC_CLUSTER_SERVER](context, {analyticServerId, clusterIds}) {
      try {
        const response = await this.getters.privateAjax.post("/v0/analytics/servers/clusters/set/", {
          analytic_server_id: analyticServerId,
          analytic_cluster_ids: clusterIds
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Сохранение сервера аналитики вместе с кластерами.
     *
     * @param {Object} context
     * @param {Number} analyticServerId
     * @param {Array<Number>} clusterIds
     * @return {Promise}
     */
    async [ACTION_EDIT_ANALYTIC_SERVER_WITH_ALL]({dispatch}, analyticServerInfo) {
      await dispatch(ACTION_SET_ANALYTIC_CLUSTER_SERVER, {
        analyticServerId: analyticServerInfo[FIELDS_ANALYTIC_SERVER.id],
        clusterIds: analyticServerInfo[FIELDS_ANALYTIC_SERVER.analytic_cluster_ids],
      });
      return dispatch(ACTION_EDIT_ANALYTIC_SERVER, analyticServerInfo);
    },
    /**
     * Загрузка списка для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Object} orderBy
     * @param {Array} fields
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_ANALYTIC_SERVERS_HISTORY_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], fields = [], filters = [], search = ""})
    {
      return dispatch(ACTION_LOAD_ANALYTIC_SERVERS_HISTORY, {page, pageSize, orderBy, fields, filters, search, cancelTokenKey: ACTION_LOAD_ANALYTIC_SERVERS_HISTORY_FOR_TABLE});
    },
  },
};
