<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.username]"
        :caption="titlesFields[fieldsEntity.username]"
        :error="errorsFormEdit[fieldsEntity.username]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit.password"
        :error="errorsFormEdit['password']"
        caption="Пароль"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.about]"
        :caption="titlesFields[fieldsEntity.about]"
        :error="errorsFormEdit[fieldsEntity.about]"
        class="col"
        input-type="area"
      />
    </div>

    <div class="row">
      <SmartInputArray
        v-model="formEdit[fieldsEntity.labels]"
        :caption="titlesFields[fieldsEntity.labels]"
        :error="errorsFormEdit[fieldsEntity.labels]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_active]"
        :caption="titlesFields[fieldsEntity.is_active]"
        :error="errorsFormEdit[fieldsEntity.is_active]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputDate
        v-model="formEdit[fieldsEntity.date_block]"
        :caption="titlesFields[fieldsEntity.date_block]"
        :config-flat-pickr="configFlatPickr"
        :error="errorsFormEdit[fieldsEntity.date_block]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.gang_id]"
        :caption="titlesFields[fieldsEntity.gang_id]"
        :error="errorsFormEdit[fieldsEntity.gang_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.gang_admin_id]"
        :caption="titlesFields[fieldsEntity.gang_admin_id]"
        :error="errorsFormEdit[fieldsEntity.gang_admin_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
        class="col"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_CLIENT} from "@/router/names.js";
import {ACTION_CREATE_CLIENT, FIELDS_CLIENT, TITLES_FIELDS_CLIENT} from "@/store/users/clients/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент диалога создания клиента.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `clients/${ACTION_CREATE_CLIENT}`,
      fieldsEntity: FIELDS_CLIENT,
      titlesFields: TITLES_FIELDS_CLIENT,
      formEdit: {
        [FIELDS_CLIENT.username]: "",
        [FIELDS_CLIENT.about]: "",
        [FIELDS_CLIENT.labels]: [],
        [FIELDS_CLIENT.is_active]: true,
        [FIELDS_CLIENT.date_block]: null,
        [FIELDS_CLIENT.gang_id]: null,
        [FIELDS_CLIENT.gang_admin_id]: null,
        password: "",
      },
      settingsSelects: {
        [FIELDS_CLIENT.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      },
      configFlatPickr: {
        minDate: "today",
        minTime: Date.now(),
      },
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование клиента.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_CLIENT, params: {clientId: newEntity[FIELDS_CLIENT.id]}};
    },
  },
};
</script>
