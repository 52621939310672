import {icon} from "leaflet";
import iconUrl from "@/assets/img/icons/map-pointer-gray.svg";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
/**
 * Сводная информация для отображения маркера на карте
 */
export class MarkerInfo {
  /**
   * @param {String} cameraNumber
   * @param {String} title
   * @param {Boolean} isInactive
   * @param {Array<Number, Number>} coordinates
   * @param {String} domain
   * @param {Number} streamCount
   * @param {Boolean} hasSound
   * @param {Number} markerId
   */
  constructor(cameraNumber, title, isInactive, coordinates, domain, streamCount, hasSound, markerId, hasPtz) {
    this.cameraNumber = cameraNumber;
    this.title = title;
    this.isInactive = isInactive;
    this.coordinates = coordinates;
    this.domain = domain;
    this.streamCount = streamCount;
    this.hasSound = hasSound;
    this.markerId = markerId;
    this.hasPtz = hasPtz;

    this.displayPopup = false;
  }

  /**
   * @returns {Object|*} Вернет иконку для камеры.
   */
  get cameraIcon() {
    if (this.markerId != null) {
      return icon({
        iconRetinaUrl: `${window.location.origin}/admin/api/v0/markers/${this.markerId}-retina.img`,
        iconUrl: `${window.location.origin}/admin/api/v0/markers/${this.markerId}-main.img`,
        shadowUrl: `${window.location.origin}/admin/api/v0/markers/${this.markerId}-shadow.img`,
        iconSize: [55, 57],
        iconAnchor: [29, 43],
        popupAnchor: [0, -47],
        shadowAnchor: [12, 39],
      });
    } else if (this.isInactive) {
      return icon({
        iconRetinaUrl: iconUrl,
        iconUrl: iconUrl,
        shadowUrl: shadowUrl,
        iconSize: [37, 52],
        iconAnchor: [18, 51],
        popupAnchor: [0, -47],
        shadowAnchor: [12, 39],
      });
    }
    return null;
  }

  /**
   * Установка флага для отображения popup-окна.
   */
  showPopup() {
    this.displayPopup = true;
  }

  /**
   * Установка флага для скрытия popup-окна.
   */
  hidePopup() {
    this.displayPopup = false;
  }
}
