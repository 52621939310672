<template>
  <div>
    <div class="tabs">
      <router-link :to="{}" class="tabs__item tabs__item_active">
        Интеграция с BOLID
      </router-link>
    </div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              Сервер
              <svg class="icon icon-arrow-breadcrumb">
                <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
              </svg>
            </router-link>
          </li>
          <li class="breadcrumbs__item breadcrumbs__item_active">
            {{ entityId }} {{ isDeleted ? "[Удалено]" : "" }}
            <svg class="icon icon-arrow-breadcrumb">
              <use xlink:href="@/assets/img/icons.svg#icon-arrow-breadcrumb" />
            </svg>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__content content">
      <div class="content__settings entity-info" :class="{'entity-info_deleted': isDeleted}">
        <div class="content__settings__header">
          <div class="tabs">
            <a class="tabs__item tabs__item_active" href="#">Редактирование</a>
          </div>
        </div>

        <div class="content__settings__body">
          <form v-if="!isEditFormLoading && formEdit" @keyup.enter="saveData()" @submit.prevent="saveData()">
            <div class="row">
              <div class="col-2">
                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.host)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.host]"
                    :caption="titlesFields[fieldsEntity.host]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.host)"
                    :error="errorsFormEdit[fieldsEntity.host]"
                    :make-focus="true"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.port)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.port]"
                    :caption="titlesFields[fieldsEntity.port]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.port)"
                    :error="errorsFormEdit[fieldsEntity.port]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.soap_url)" class="row">
                  <SmartInputText
                    v-model="formEdit[fieldsEntity.soap_url]"
                    :caption="titlesFields[fieldsEntity.soap_url]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.soap_url)"
                    :error="errorsFormEdit[fieldsEntity.soap_url]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.is_auth_enabled)" class="row">
                  <SmartSwitch
                    v-model="formEdit[fieldsEntity.is_auth_enabled]"
                    :caption="titlesFields[fieldsEntity.is_auth_enabled]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.is_auth_enabled)"
                    :error="errorsFormEdit[fieldsEntity.is_auth_enabled]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.is_token_required)" class="row">
                  <SmartSwitch
                    v-if="formEdit[fieldsEntity.is_auth_enabled]"
                    v-model="formEdit[fieldsEntity.is_token_required]"
                    :caption="titlesFields[fieldsEntity.is_token_required]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.is_token_required)"
                    :error="errorsFormEdit[fieldsEntity.is_token_required]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.login)" class="row">
                  <SmartInputText
                    v-if="formEdit[fieldsEntity.is_auth_enabled]"
                    v-model="formEdit[fieldsEntity.login]"
                    :caption="titlesFields[fieldsEntity.login]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.login)"
                    :error="errorsFormEdit[fieldsEntity.login]"
                    class="col"
                  />
                </div>

                <div v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.password)" class="row">
                  <SmartInputText
                    v-if="formEdit[fieldsEntity.is_auth_enabled]"
                    v-model="formEdit[fieldsEntity.password]"
                    :caption="titlesFields[fieldsEntity.password]"
                    :disabled="!$can($abilitiesActions.UPDATE_FIELD, $abilitiesSubjects.BOLID_SERVER, fieldsEntity.password)"
                    :error="errorsFormEdit[fieldsEntity.password]"
                    class="col"
                  />
                </div>

                <template v-if="!isDeleted">
                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.BOLID_SERVER)"
                        priority="primary"
                        type="button"
                        @click="saveData()"
                      >
                        Сохранить и продолжить редактирование
                      </CamsButton>
                      <CamsButton
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.BOLID_SERVER)"
                        priority="primary"
                        type="button"
                        @click="saveDataAndRedirect()"
                      >
                        Сохранить
                      </CamsButton>
                    </div>
                  </div>

                  <div class="row">
                    <div class="buttons-group">
                      <CamsButton
                        v-if="$can($abilitiesActions.CREATE, $abilitiesSubjects.BOLID_SERVER)"
                        type="button"
                        @click="deleteServer()"
                      >
                        Удалить
                      </CamsButton>
                      <router-link
                        v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.BOLID_SERVER)"
                        :to="routeBack"
                        class="button button_btn button_medium button_btn-default"
                      >
                        Отменить
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-2">
                <div
                  v-if="$can($abilitiesActions.READ_FIELD, $abilitiesSubjects.CAMERA, fieldsEntity.is_connection_active)"
                >
                  {{ titlesFields[fieldsEntity.status_id] }} :
                  <strong> {{ lastScreenStatus.is_connection_active ? ' Подключен ' : lastScreenStatus.error + " " }}</strong>

                  <div
                    :class="
                      lastScreenStatus.is_connection_active ? 'indicator indicator_available_for_edit'
                      :lastScreenStatus.is_connection_active === null ? 'indicator indicator_null_for_edit' :'indicator indicator_inaccessible_for_edit'"
                  />
                </div>
                <br>
              </div>
            </div>
          </form>

          <div v-else class="row">
            <p>Данные не удалось загрузить.</p>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading || isEditFormLoading" />
  </div>
</template>

<script>
import {ROUTE_BOLID_SERVERS} from "@/router/names.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import {
  ACTION_DELETE_BOLID_SERVER, ACTION_EDIT_BOLID_SERVER,
  ACTION_LOAD_BOLID_SERVER_FOR_EDIT, EXTRAS_BOLID_SERVER,
  FIELDS_BOLID_SERVER, TITLES_FIELDS_BOLID_SERVER
} from "@/store/integrations/bolid/servers/index.js";

/**
 * Компонент страницы редактирования сервера.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const serverId = Number(this.$route.params.serverId);

    return {
      routeBack: {name: ROUTE_BOLID_SERVERS},
      fieldsEntity: FIELDS_BOLID_SERVER,
      titlesFields: TITLES_FIELDS_BOLID_SERVER,
      entityId: serverId,
      nameActionLoadDataForEdit: `bolidServers/${ACTION_LOAD_BOLID_SERVER_FOR_EDIT}`,
      nameActionEdit: `bolidServers/${ACTION_EDIT_BOLID_SERVER}`,
      deletedField: FIELDS_BOLID_SERVER.is_deleted,
    };
  },computed:{
    lastScreenStatus() {
      return _.head(this.sourceData.extraInfo[EXTRAS_BOLID_SERVER.bolid_server_status]);
    },
  },
  methods: {
    async afterLoadSourceData() {},
    getDataForSave() {
      const serverInfo = Object.assign({}, this.formEdit);
      return serverInfo;
    },
    /**
     * Отправка запроса на удаление текущего сервера.
     */
    deleteServer() {
      this.$camsdals.confirm("Хотите удалить этот сервер?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`servers/${ACTION_DELETE_BOLID_SERVER}`, {serverIds: [this.entityId]});
          this.$camsdals.alert("Сервер BOLID удален");
          this.loadSourceData();
        } catch {
          this.$camsdals.alert("Ошибка при удалении сервера");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
