<template>
  <div>
    <div class="main__header">
      <div class="tabs">
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS}" class="tabs__item">
          Серверы аналитики
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_CLUSTERS}" class="tabs__item">
          Кластеры серверов аналитик
        </router-link>
        <router-link :to="{name: routes.ROUTE_ANALYTIC_SERVERS_LOGS}" class="tabs__item">
          Логи серверов
        </router-link>
        <router-link :to="{name: routes.ROUTE_ROOT_ANALYTICS_SETTINGS}" class="tabs__item">
          Настройка аналитик
        </router-link>
        <router-link :to="{name: routes.currentRoute}" class="tabs__item tabs__item_active">
          Отчеты
        </router-link>
      </div>
    </div>

    <SmartTable
      :calc-data-for-cell="calcDataForCell"
      :column-captions="columnCaptions"
      :current-route="currentRoute"
      :key-field="rawFields.id"
      :name-action-load-data-for-table="nameActionLoadDataForTable"
      :name-action-load-info-for-table="nameActionLoadInfoForTable"
      :raw-fields="rawFields"
      :parse-extra="parseExtra"
      :initial-default-fields-table-for-view="defaultFieldsTableForView"
    />
  </div>
</template>

<script>
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {
  ROUTE_ROOT_ANALYTICS_SETTINGS,
  ROUTE_ANALYTIC_CLUSTERS,
  ROUTE_ANALYTIC_CAR_NUMBER_REPORT,
  ROUTE_ANALYTIC_SERVERS,
  ROUTE_ANALYTIC_SERVERS_LOGS, ROUTE_EDIT_CAR_NUMBER, ROUTE_EDIT_DEVICE,
} from "@/router/names.js";
import {
  FIELDS_ANALYTIC_CAR_NUMBER_REPORT,
  TITLES_FIELDS_ANALYTIC_CAR_NUMBER_REPORT,
  TYPES_FIELDS_ANALYTIC_CAR_NUMBER_REPORT
} from "@/store/analytics/reports/index.js";
import {
  ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS_FOR_TABLE,
  ACTION_LOAD_INFO_ANALYTIC_CAR_NUMBER_REPORTS,
} from "@/store/analytics/reports/index.js";
import {EXTRAS_ANALYTIC_CAR_NUMBER_REPORT} from "@/store/analytics/reports/index.js";
import {FIELDS_GANG} from "@/store/gangs/index.js";
import {FIELDS_DEVICE} from "@/store/pacs/devices/index.js";
import {FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {NAME_QUERY_PARAM_FOR_TABLE} from "@/utils/consts.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по отчетам аналитики расопзнавания номеров.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    return {
      token: null,
      routes: {
        ROUTE_ANALYTIC_SERVERS,
        ROUTE_ANALYTIC_SERVERS_LOGS,
        ROUTE_ROOT_ANALYTICS_SETTINGS,
        ROUTE_ANALYTIC_CLUSTERS,
        ROUTE_ANALYTIC_CAR_NUMBER_REPORT
      },
      currentRoute: ROUTE_ANALYTIC_CAR_NUMBER_REPORT,
      rawFields: FIELDS_ANALYTIC_CAR_NUMBER_REPORT,
      columnCaptions: TITLES_FIELDS_ANALYTIC_CAR_NUMBER_REPORT,
      nameActionLoadDataForTable: `analytics/reports/${ACTION_LOAD_ANALYTIC_CAR_NUMBER_REPORTS_FOR_TABLE}`,
      nameActionLoadInfoForTable: `analytics/reports/${ACTION_LOAD_INFO_ANALYTIC_CAR_NUMBER_REPORTS}`,
      defaultFieldsTableForView: [
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.text,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.plate_number,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.access,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.date,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.camera_number,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.device_id,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.gang_id,
        FIELDS_ANALYTIC_CAR_NUMBER_REPORT.full_screenshot_url,
      ],
    };
  },
  methods: {
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.gang]) {
        storedExtraInfo.gangs = {};
        sourceExtraInfo[EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.gang].forEach((gangInfo) => {
          storedExtraInfo.gangs[gangInfo[FIELDS_GANG.id]] = {
            id: gangInfo[FIELDS_GANG.id],
            name: gangInfo[FIELDS_GANG.name],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.device]) {
        storedExtraInfo.devices = {};
        sourceExtraInfo[EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.device].forEach((deviceInfo) => {
          storedExtraInfo.devices[deviceInfo[FIELDS_DEVICE.id]] = {
            id: deviceInfo[FIELDS_DEVICE.id],
            name: deviceInfo[FIELDS_DEVICE.title],
          };
        });
      }
      if (sourceExtraInfo[EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.employee]) {
        storedExtraInfo.employees = {};
        sourceExtraInfo[EXTRAS_ANALYTIC_CAR_NUMBER_REPORT.employee].forEach((employeeInfo) => {
          storedExtraInfo.employees[employeeInfo[FIELDS_EMPLOYEE.id]] = {
            id: employeeInfo[FIELDS_EMPLOYEE.id],
            name: employeeInfo[FIELDS_EMPLOYEE.title],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @returns {Object}
     */

    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};

      switch (nameField) {
      case FIELDS_ANALYTIC_CAR_NUMBER_REPORT.gang_id:
        readyTranslation = _.get(storedExtraInfo.gangs[rawTranslation], "name", "-");
        break;
      case FIELDS_ANALYTIC_CAR_NUMBER_REPORT.device_id:
        readyTranslation = _.get(storedExtraInfo.devices[rawTranslation], "name", "-");
        readyParams = {name: ROUTE_EDIT_DEVICE, params: {deviceId: rawTranslation || '-'}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      case FIELDS_ANALYTIC_CAR_NUMBER_REPORT.employee_id:
        readyTranslation = _.get(storedExtraInfo.employees[rawTranslation], "name", "-");
        break;
      case FIELDS_ANALYTIC_CAR_NUMBER_REPORT.camera_number:
        readyTranslation = rawTranslation;
        readyParams = {name: ROUTE_EDIT_CAR_NUMBER, params: {cameraNumber: rawTranslation}};
        if (this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]) {
          readyParams.query = {[NAME_QUERY_PARAM_FOR_TABLE]: this.$route.query[NAME_QUERY_PARAM_FOR_TABLE]};
        }
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_ANALYTIC_CAR_NUMBER_REPORT[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    }

  },
};
</script>
