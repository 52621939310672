<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.name]"
        :caption="titlesFields[fieldsEntity.name]"
        :error="errorsFormEdit[fieldsEntity.name]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartSwitch
        v-model="formEdit[fieldsEntity.is_auto]"
        :caption="titlesFields[fieldsEntity.is_auto]"
        :error="errorsFormEdit[fieldsEntity.is_auto]"
        class="col"
      />
    </div>

    <div class="row">
      <div class="accordion">
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInCamerasTab) || !!formEdit[fieldsEntity.camera_group_ids].length}"
            class="accordion__header"
            @click="$set(showAccordionItem, 0, !showAccordionItem[0])"
          >
            Камеры
          </div>
          <div v-show="showAccordionItem[0]" class="accordion__content">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.camera_group_ids]"
              :caption="titlesFields[fieldsEntity.camera_group_ids]"
              :error="errorsFormEdit[fieldsEntity.camera_group_ids]"
              :multiple="true"
              :settings-remote-search="settingsSelects[fieldsEntity.camera_group_ids]"
              class="accordion__ability-actions"
            />
            <div class="accordion__title">
              Камеры
            </div>
            <div class="accordion__little-description">
              Настройки прав для управления камерами.
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:create" class="" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:read" class="" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:update" class="" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_permission:read" class="accordion__ability-actions" caption="Просмотр прав у камер" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="log_camera_watch_reason:read" class="accordion__ability-actions" caption="Чтение логов просмотров" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.location:update" class="accordion__ability-actions" caption="Изменение координат" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:reset_salt" class="accordion__ability-actions" caption="Сброс соли" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:activate" class="accordion__ability-actions" caption="Включение/выключение камеры" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.live_reason" class="accordion__ability-actions" caption="Разрешение на просмотр трансляции" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.dvr_reason" class="accordion__ability-actions" caption="Разрешение на просмотр архива" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:blocking" class="accordion__ability-actions" caption="Блокировка камеры" />
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Параметры для встраивания
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.embed:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.embed:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Параметры для контролируемых камер
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.is_managed:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.is_managed:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Параметры для привязки к серверу
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.server_id:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.server_id:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Параметры инвентарных данных
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.inventory:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.inventory:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Параметры для настройки потоков
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.streams:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.streams:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Параметры для управления группами камер
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.group:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera.group:update" caption="Изменение" />
            </div>
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInCameraGroupsTab) || !!formEdit[fieldsEntity.camera_group_type_ids].length}"
            class="accordion__header"
            @click="$set(showAccordionItem, 1, !showAccordionItem[1])"
          >
            Группы камер
          </div>
          <div v-show="showAccordionItem[1]" class="accordion__content">
            <SmartVSelect
              v-model="formEdit[fieldsEntity.camera_group_type_ids]"
              :caption="titlesFields[fieldsEntity.camera_group_type_ids]"
              :error="errorsFormEdit[fieldsEntity.camera_group_type_ids]"
              :multiple="true"
              :settings-remote-search="settingsSelects[fieldsEntity.camera_group_type_ids]"
              class="accordion__ability-actions"
            />
            <div class="accordion__title">
              Группы камер
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_permission:read" class="accordion__ability-actions" caption="Просмотр прав у групп камер" />
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Типы групп камер
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_type:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_type:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera_group_type:update" caption="Изменение" />
            </div>
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInUsersTab)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 2, !showAccordionItem[2])"
          >
            Пользователи
          </div>
          <div v-show="showAccordionItem[2]" class="accordion__content">
            <div class="accordion__title">
              Клиенты
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Администраторы
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Группы прав администраторов
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="right_group:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="right_group:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="right_group:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_client:reset_salt" class="accordion__ability-actions" caption="Сброс соли у клиента" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="user_admin:reset_salt" class="accordion__ability-actions" caption="Сброс соли у администратора" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="log_auth:read" class="accordion__ability-actions" caption="Чтение логов авторизации" />
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="log_view:read" class="accordion__ability-actions" caption="Чтение логов просмотра страниц" />
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInTariffTab)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 3, !showAccordionItem[3])"
          >
            Тарифы
          </div>
          <div v-show="showAccordionItem[3]" class="accordion__content">
            <div class="accordion__title">
              Тарифы
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="tariff:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="tariff:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="tariff:update" caption="Изменение" />
            </div>
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInServersTab)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 4, !showAccordionItem[4])"
          >
            Серверы и кластеры
          </div>
          <div v-show="showAccordionItem[4]" class="accordion__content">
            <div class="accordion__title">
              Серверы
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="server:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="server:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="server:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Кластеры
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="cluster:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="cluster:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="cluster:update" caption="Изменение" />
            </div>
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInAnalyticsTab)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 5, !showAccordionItem[5])"
          >
            Аналитика
          </div>
          <div v-show="showAccordionItem[5]" class="accordion__content">
            <div class="accordion__title">
              Серверы видеоаналитики
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_server:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_server:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_server:update" caption="Изменение" />
            </div>
            <div class="accordion__title">
              Кластеры видеоаналитики
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_cluster:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_cluster:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic_cluster:update" caption="Изменение" />
            </div>
            <div class="accordion__title">
              Настройка аналитики
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="analytic:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInGangTab)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 6, !showAccordionItem[6])"
          >
            Компании
          </div>
          <div v-show="showAccordionItem[6]" class="accordion__content">
            <div class="accordion__title">
              Компании
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="gang:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="gang:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="gang:update" caption="Изменение" />
            </div>
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsInPacsTab)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 7, !showAccordionItem[7])"
          >
            СКУД
          </div>
          <div v-show="showAccordionItem[7]" class="accordion__content">
            <div class="accordion__title">
              Сотрудники
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="employee:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="employee:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="employee:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" class="accordion__ability-actions" checkbox-value="device_permission:read" caption="Просмотр прав у сотрудников" />
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Группы доступа
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_access_group:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_access_group:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device_access_group:update" caption="Изменение" />
            </div>
            <hr class="accordion__content-divider">
            <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" class="accordion__ability-actions" checkbox-value="device_access_group_permission:read" caption="Просмотр прав у групп доступа" />
            <hr class="accordion__content-divider">
            <div class="accordion__title">
              Устройства доступа
            </div>
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device:create" caption="Создание" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device:read" caption="Чтение" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="device:update" caption="Изменение" />
            </div>
          </div>
        </div>
        <div class="accordion__item">
          <div
            :class="{'accordion__header_marked': checkPermissionsInTab(permissionsForButtons)}"
            class="accordion__header"
            @click="$set(showAccordionItem, 8, !showAccordionItem[8])"
          >
            Множественные действия
          </div>
          <div v-show="showAccordionItem[8]" class="accordion__content">
            <div class="accordion__title" />
            <div class="accordion__ability-actions accordion__ability-actions_one-row">
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:multi_edit" caption="Множественное редактирование" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:download_csv" caption="Скачивание CSV" />
              <SmartSwitch v-model="formEdit[fieldsEntity.permissions]" checkbox-value="camera:multi_download" caption="Множественное скачивание" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="errorsFormEdit[fieldsEntity.permissions]" class="row error">
      {{ errorsFormEdit[fieldsEntity.permissions] }}
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {ROUTE_EDIT_RIGHT_GROUP} from "@/router/names.js";
import {ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT, FIELDS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT, FIELDS_CAMERA_GROUP_TYPE} from "@/store/cameraGroupTypes/index.js";
import {ACTION_CREATE_RIGHT_GROUP, FIELDS_RIGHT_GROUP, TITLES_FIELDS_RIGHT_GROUP} from "@/store/rightGroups/index.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {rightGroupTabCheckMixin} from "@/components/rightGroups/mixin.js";

/**
 * Компонент диалога создания группы камер.
 */
export default {
  mixins: [createEntityMixin, rightGroupTabCheckMixin],
  data() {
    return {
      nameActionCreate: `rightGroups/${ACTION_CREATE_RIGHT_GROUP}`,
      fieldsEntity: FIELDS_RIGHT_GROUP,
      titlesFields: TITLES_FIELDS_RIGHT_GROUP,
      formEdit: {
        [FIELDS_RIGHT_GROUP.name]: "",
        [FIELDS_RIGHT_GROUP.is_auto]: false,
        [FIELDS_RIGHT_GROUP.permissions]: [],
        [FIELDS_RIGHT_GROUP.camera_group_ids]: [],
        [FIELDS_RIGHT_GROUP.camera_group_type_ids]: [],
      },
      settingsSelects: {
        [FIELDS_RIGHT_GROUP.camera_group_ids]: {
          action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP.id,
          labelField: FIELDS_CAMERA_GROUP.name
        },
        [FIELDS_RIGHT_GROUP.camera_group_type_ids]: {
          action: `cameraGroupTypes/${ACTION_LOAD_CAMERA_GROUP_TYPES_FOR_SELECT}`,
          valueField: FIELDS_CAMERA_GROUP_TYPE.id,
          labelField: FIELDS_CAMERA_GROUP_TYPE.name
        },
      },
      showAccordionItem: [],
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование группы прав.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_RIGHT_GROUP, params: {rightGroupId: newEntity[FIELDS_RIGHT_GROUP.id]}};
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
