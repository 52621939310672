<template>
  <div class="settings_access">
    <template v-if="modeAddPermission">
      <div v-for="(itemField, indexField) in formAddPermission" :key="`fieldAddPermission${indexField}`" class="row">
        <SmartVSelect
          v-if="modeForEmployee"
          v-model="itemField.entityId"
          :settings-remote-search="settingsSelectDevice"
          class="settings_access__select"
          placeholder="Выберите устройство"
        />
        <SmartVSelect
          v-else
          v-model="itemField.entityId"
          :settings-remote-search="settingsSelectEmployee"
          class="settings_access__select"
          placeholder="Введите сотрудника"
        />

        <div class="buttons-group">
          <SmartSelect
            v-model="itemField.permission"
            :options="permissionsForSelect"
          />
          <CamsButton
            v-if="lengthFormAddPermission > 1"
            class="settings_access__delete-row"
            icon-type="only"
            type="button"
            @click="deleteFieldFormAddPermission(indexField)"
          >
            <svg style="width: 20px; height: 20px">
              <use xlink:href="@/assets/img/icons.svg#close" />
            </svg>
          </CamsButton>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="buttons-group buttons-group_stretch">
            <CamsButton
              v-if="!modeForEmployee"
              :disabled="lengthFormAddPermission >= 3"
              priority="primary"
              @click="addNewFieldFormAddPermission()"
            >
              Ещё
            </CamsButton>
            <CamsButton priority="primary" type="button" @click="addPermissions()">
              Сохранить
            </CamsButton>
            <CamsButton type="button" @click="switchOffModeAddPermission()">
              Отмена
            </CamsButton>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!modeAddPermission">
      <div class="row">
        <div class="col">
          <CamsButton
            v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)"
            priority="primary"
            type="button"
            @click="switchOnModeAddPermission()"
          >
            {{ modeForEmployee ? "Добавить устройство" : "Добавить сотрудника" }}
          </CamsButton>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="input input_medium input_stretch">
            <input
              v-model="search"
              :placeholder="modeForEmployee ? 'Фильтр по устройствам' : 'Фильтр по сотрудникам'"
              class="input"
              type="text"
              @input="debouncedLoadPermissions()"
            >
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col">
        <SpinnerLoading v-if="isLoading" size="s" />

        <div v-else-if="isAvailablePermissions" class="access-container">
          <nav class="pagination">
            <paginate
              v-model="currentPage"
              :active-class="'pagination__list__item_active'"
              :break-view-class="'pagination__list__item_collapse'"
              :click-handler="loadPermissions"
              :container-class="'pagination__list'"
              :hide-prev-next="true"
              :page-class="'pagination__list__item'"
              :page-count="pageInfo.numPages"
              :page-range="5"
              next-text=""
              prev-text=""
            />
          </nav>

          <div class="access-container__header">
            <div>{{ modeForEmployee ? "Устройство" : "Сотрудник" }}</div>
            <div>Уровень доступа</div>
          </div>

          <div class="access-container__content access-list">
            <div v-for="(permissionInfo, permissionInfoIndex) in permissionsInfo" :key="permissionInfoIndex" class="access-list__item">
              <div>{{ permissionInfo.title }}</div>

              <div v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.EMPLOYEE)" class="buttons-group">
                <SmartSelect
                  v-model="permissionInfo.permission"
                  :options="permissionsForSelect"
                  size="s"
                  @input="updatePermission(permissionInfo.deviceId, permissionInfo.employeeId, permissionInfo.isAuto, $event)"
                />
                <CamsButton
                  icon-type="only"
                  type="button"
                  class="settings_access__delete-row"
                  @click="deletePermission(permissionInfo.deviceId, permissionInfo.employeeId, permissionInfo.isAuto)"
                >
                  <svg style="width: 20px; height: 20px">
                    <use xlink:href="@/assets/img/icons.svg#close" />
                  </svg>
                </CamsButton>
              </div>
            </div>
          </div>

          <nav class="pagination">
            <paginate
              v-model="currentPage"
              :active-class="'pagination__list__item_active'"
              :break-view-class="'pagination__list__item_collapse'"
              :click-handler="loadPermissions"
              :container-class="'pagination__list'"
              :hide-prev-next="true"
              :page-class="'pagination__list__item'"
              :page-count="pageInfo.numPages"
              :page-range="5"
              next-text=""
              prev-text=""
            />
          </nav>

          <span>Найдено: {{ pageInfo.count }}</span>
        </div>

        <div v-else>
          <p>Прав не найдено.</p>
        </div>
      </div>
    </div>

    <div class="dialog-actions">
      <CamsButton priority="primary" type="button" @click="closeDialog()">
        Готово
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {PERMISSIONS_FOR_SELECT, setPermissionsMixin} from "@/components/pacs/pacsPermissons/mixin.js";
import {ACTION_LOAD_DEVICES_FOR_SELECT, FIELDS_DEVICE} from "@/store/pacs/devices/index.js";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {
  ACTION_ADD_PERMISSIONS_EMPLOYEES_BY_FORM,
  ACTION_DELETE_PERMISSIONS_EMPLOYEES,
  ACTION_LOAD_PERMISSIONS_EMPLOYEE_FOR_EDIT,
  EXTRAS_PERMISSION_EMPLOYEE,
  FIELDS_PERMISSION_EMPLOYEE,
} from "@/store/pacs/pacsPermissions/index.js";
import {methodsForDialogMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога установки прав сотрудников для устройств.
 *
 * Предусматривает два режима:
 *  - для управления правами многих устройств для указанного сотрудника;
 *  - для управления правами многих сотрудников для указанного устройства;
 *
 * Режим зависит от переданного идентификатора в конкретный параметр (ID устройства или сотрудника - приоритет для сотрудника).
 * Возможность множественного добавления прав предназначена для одного устройства.
 */
export default {
  mixins: [setPermissionsMixin, methodsForDialogMixin],
  props: {
    /**
     * Идентификатор сотрудника - позволяет управлять правами разных пользователей для этого устройства.
     */
    employeeId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор устройства - позволяет управлять правами разных сотрудников для этого устройства.
     */
    deviceId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор компании - предназначен для фильтрации по компании.
     */
    gangId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      permissionsForSelect: PERMISSIONS_FOR_SELECT,
      settingsSelectDevice: {
        action: `devices/${ACTION_LOAD_DEVICES_FOR_SELECT}`,
        valueField: FIELDS_DEVICE.id,
        labelField: FIELDS_DEVICE.title,
        searchParams: {gangId: this.gangId}
      },
      settingsSelectEmployee: {
        action: `employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: FIELDS_EMPLOYEE.id,
        labelField: FIELDS_EMPLOYEE.title,
        searchParams: {gangId: this.gangId}
      },
    };
  },
  computed: {
    /**
     * Вернет true для режима, в котором права устанавливаются для конкретного сотрудника, а настраиваются устройства.
     *
     * @return {Boolean}
     */
    modeForEmployee() {
      return this.employeeId !== null;
    },
  },
  created() {
    this.debouncedLoadPermissions = _.debounce(this.loadPermissions, 350);
    this.loadPermissions();
  },
  methods: {
    /**
     * Загрузит информацию по правам для редактирования.
     *
     * @param {Number} currentPage
     */
    async loadPermissions(currentPage = 1) {
      this.currentPage = this.currentPage === currentPage ? this.currentPage : currentPage;
      // Основной фильтр зависит от режима работы компонента.
      const mainFilter = this.modeForEmployee ? {employeeId: this.employeeId} : {deviceId: this.deviceId};

      this.isLoading = true;
      try {
        const responseData = await this.$store.dispatch(`pacsPermissions/${ACTION_LOAD_PERMISSIONS_EMPLOYEE_FOR_EDIT}`, {
                ...mainFilter,
                search: this.search,
                page: currentPage
              }),
              titlesForPermissions = this.modeForEmployee
                ? _.chain(responseData.extra[EXTRAS_PERMISSION_EMPLOYEE.device])
                  .keyBy(FIELDS_DEVICE.id)
                  .mapValues(FIELDS_DEVICE.title)
                  .value()
                : _.chain(responseData.extra[EXTRAS_PERMISSION_EMPLOYEE.employee])
                  .keyBy(FIELDS_EMPLOYEE.id)
                  .mapValues(FIELDS_EMPLOYEE.title)
                  .value();

        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.permissionsInfo = responseData.results.map((rawPermissionInfo) => {
          return {
            employeeId: rawPermissionInfo[FIELDS_PERMISSION_EMPLOYEE.employee_id],
            permission: rawPermissionInfo[FIELDS_PERMISSION_EMPLOYEE.access_type],
            deviceId: rawPermissionInfo[FIELDS_PERMISSION_EMPLOYEE.device_id],
            isAuto: rawPermissionInfo[FIELDS_PERMISSION_EMPLOYEE.is_auto],
            title: titlesForPermissions[rawPermissionInfo[this.modeForEmployee ? FIELDS_PERMISSION_EMPLOYEE.device_id : FIELDS_PERMISSION_EMPLOYEE.employee_id]],
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Обработка формы для добавления прав и оформление запроса по заполненным данным.
     */
    async addPermissions() {
      const dataForSave = {},
            valuesFormAddPermission = _.values(this.formAddPermission);

      if (this.modeForEmployee) {
        // Если задан сотрудник, то в списке прав заданы устройства (1) и их права.
        dataForSave.employeeId = this.employeeId;
        dataForSave.rawPermissionsInfo = valuesFormAddPermission.map((rawPermissionInfo) => {
          return (rawPermissionInfo.entityId && rawPermissionInfo.permission) ? [rawPermissionInfo.entityId, rawPermissionInfo.permission] : null;
        });
        dataForSave.rawPermissionsInfo = _.filter(dataForSave.rawPermissionsInfo);

        if (_.isEmpty(dataForSave.rawPermissionsInfo)) {
          this.$camsdals.alert("Ошибка при добавлении прав");
          return;
        }
      } else {
        // Если задано устройство, то в списке прав заданы сотрудники и их права.
        const rawPermissionInfo = _.head(valuesFormAddPermission);
        if (!rawPermissionInfo.entityId) {
          this.$camsdals.alert("Ошибка при добавлении прав");
          return;
        }

        dataForSave.employeeId = rawPermissionInfo.entityId;
        dataForSave.rawPermissionsInfo = [
          [this.deviceId, rawPermissionInfo.permission]
        ];
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacsPermissions/${ACTION_ADD_PERMISSIONS_EMPLOYEES_BY_FORM}`, dataForSave);
        this.switchOffModeAddPermission();
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.isLoading = false;
    },
    /**
     * Редактирование (установка) прав для найденных.
     *
     * @param {Number} deviceId
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Number} permission
     */
    async updatePermission(deviceId, employeeId, isAuto, permission) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacsPermissions/${ACTION_ADD_PERMISSIONS_EMPLOYEES_BY_FORM}`, {
          employeeId,
          isAuto,
          rawPermissionsInfo: [[deviceId, permission]],
          mergePermissions: false,
        });
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.loadPermissions(this.currentPage);
      this.isLoading = false;
    },
    /**
     * Удаление конкретных прав из списка найденных.
     *
     * @param {Number} deviceId
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     */
    deletePermission(deviceId, employeeId, isAuto) {
      this.$camsdals.confirm("Хотите удалить эти права?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacsPermissions/${ACTION_DELETE_PERMISSIONS_EMPLOYEES}`, {employeeId, isAuto, deviceIds: [deviceId]});
        } catch (error) {
          this.$camsdals.alert(`Произошла ошибка: ${error}`);
        }
        this.loadPermissions();
        this.isLoading = false;
      });
    },
  },
};
</script>
