<template>
  <div>
    <div class="main__content content">
      <div class="content__settings__body">
        <form v-if="!isLoading && formEdit" @submit.prevent="saveData()" @keyup.enter="saveData()">
          <div class="row">
            <div class="col-2">
              <fieldset
                style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding: 17px 10px;"
                class="col"
              >
                <legend
                  style="font: 16px 'Roboto-Medium'; text-align: right"
                >
                  Общие настройки
                </legend>
                <SmartInputText
                  v-model="formEdit.ntp_server"
                  :error="errorsFormEdit[fieldsEntity.ntp_server]"
                  caption="NTP сервер"
                  size="s"
                  maxlength="255"
                />

                <SmartSelect
                  v-model="formEdit.video_bitrate"
                  width="fill"
                  :options="this.videoBitrateOptions"
                  caption="Битрейт"
                  size="s"
                />
                <SmartVSelect
                  v-model="formEdit.timezone"
                  :error="errorsFormEdit[fieldsEntity.timezone]"
                  caption="Time Zone"
                  maxlength="255"
                  class="setup-fields-item"
                  :settings-remote-search="settingsRemoteSearchTimezone"
                  size="s"
                />
                <SmartSwitch
                  v-model="formEdit.audio_enable"
                  style="margin-top: 10px"
                  caption="Звук"
                />
              </fieldset>
              <fieldset
                style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding: 17px 10px;"
                class="col"
              >
                <legend
                  style="font: 16px 'Roboto-Medium'; text-align: right"
                >
                  Изображение
                </legend>
                <SmartSelect
                  v-model="formEdit.video_resolution"
                  width="fill"
                  :options="this.videoResolutionOptions"
                  caption="Разрешение"
                  size="s"
                />
                <SmartSelect
                  v-model="formEdit.night_mode_enable"
                  width="fill"
                  :options="this.nightModeOptions"
                  caption="Ночной режим"
                  class="setup-fields-item"
                  size="s"
                />
                <SmartSelect
                  v-model="formEdit.motion_video_in_wdr"
                  :error="errorsFormEdit[fieldsEntity.motion_video_in_wdr]"
                  width="fill"
                  :options="wdrOptions"
                  caption="Уровень подсветки WDR"
                  size="s"
                />
                <SmartSwitch
                  v-model="formEdit.video_enable"
                  caption="Включить видеопоток"
                  style="margin-top: 10px"
                />
                <SmartSwitch
                  v-model="formEdit.motion_video_in_contour_light"
                  caption="Компенсация засветки"
                />
              </fieldset>

              <div
                style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding:   10px;
                margin-top: 20px"
              >
                <SmartSwitch
                  v-model="formEdit.motion_detect_enable"
                  caption="Детекция движения"
                  style="margin-top: 5px;"
                  size="s"
                />
                <div v-if="formEdit.motion_detect_enable">
                  <SmartInputText
                    v-model="formEdit.motion_detect_dejitter"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_dejitter]"
                    caption="Dejitter детекции движения"
                    size="s"
                    maxlength="255"
                  />
                  <SmartInputText
                    v-model="formEdit.motion_detect_sensitive"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_sensitive]"
                    caption="Чувствительность детекции движения"
                    size="s"

                    maxlength="255"
                  />
                  <SmartInputText
                    v-model="formEdit.motion_detect_server_host"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_server_host]"
                    caption="Хост сервера детекции движения"
                    size="s"

                    maxlength="255"
                  />
                  <SmartInputText
                    v-model="formEdit.motion_detect_server_port"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_server_port]"
                    caption="Порт сервера детекции движения"
                    size="s"

                    maxlength="255"
                  />
                  <SmartInputText
                    v-model="formEdit.motion_detect_server_path"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_server_path]"
                    caption="Раздел сервера детекции движения"
                    size="s"
                    maxlength="255"
                  />
                </div>
              </div>
              <div
                style="border: 5px solid #dee2e6;
                  border-radius: 10px;
                  padding:   10px;
                margin-top: 20px"
              >
                <SmartSwitch
                  v-model="formEdit.osd_front_color"
                  caption="OSD"
                  size="s"
                  style="margin-top: 5px;"
                />
                <div v-if="formEdit.osd_front_color">
                  <SmartInputText
                    v-model="formEdit.osd_time_title_text"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_sensitive]"
                    caption="Текст OSD"
                    size="s"
                    maxlength="255"
                  />
                  <SmartInputText
                    v-model="formEdit.osd_time_title_x"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_sensitive]"
                    caption="Положение OSD (X-координата)"
                    size="s"

                    maxlength="255"
                  />
                  <SmartInputText
                    v-model="formEdit.osd_time_title_y"
                    :error="errorsFormEdit[fieldsEntity.motion_detect_sensitive]"
                    caption="Положение OSD (Y-координата)"
                    size="s"
                    maxlength="255"
                  />
                </div>
              </div>
              <CamsButton
                priority="primary"
                type="button"
                style="margin-top: 5px;"
                @click="saveData()"
              >
                Сохранить
              </CamsButton>
            </div>
            <div class="row-2">
              <div><strong>Модель камеры:</strong> {{ formEdit.model }}</div>
              <div><strong>MAC-адрес:</strong>{{ formEdit.mac }}</div>
              <div><strong>Номер камеры:</strong> {{ formEdit.camera_number }}</div>
              <div><strong>Серийный номер:</strong> {{ formEdit.sn }}</div>
              <div><strong>Версия ПО:</strong> {{ formEdit.firmware_version }}</div>
              <div><strong>Версия ядра:</strong> {{ formEdit.kernel_version }}</div>
              <div><strong>Попыток обновления:</strong> {{ formEdit.count_of_attempts_to_update }}</div>
              <div style="display:flex;">
                <strong>Статус камеры:</strong> {{ formEdit.status }}
                <SpinnerLoading v-if="this.formEdit.status !== 'ready'" size="l" />
              </div>

              <strong
                v-if="!this.sourceData.entityInfo.new_firmware_available"
              >
                Установлена актуальная версия ПО
              </strong>
              <strong
                v-else
              >
                Доступно обновление: {{ this.sourceData.entityInfo.new_firmware_available }}
              </strong>
              <CamsButton
                v-if="this.sourceData.entityInfo.new_firmware_available && this.formEdit.status === 'ready'"
                type="button"
                style="margin-top: 10px"
                @click="cameraUpgrade()"
              >
                Обновить прошивку
              </CamsButton>
              <div class="col-2" style="margin-top: 10px">
                <CamsButton
                  type="button"
                  style="margin-top: 10px"
                  @click="openDialogManualUpdate()"
                >
                  Ручное обновление
                </CamsButton>
                <CamsButton
                  type="button"
                  style="margin-top: 10px"
                  @click="cameraReset()"
                >
                  Сброс к заводским
                </CamsButton>
                <CamsButton
                  type="button"
                  style="margin-top: 10px"
                  @click="cameraReboot()"
                >
                  Перезагрузка
                </CamsButton>
              </div>
            </div>
          </div>
        </form>

        <div v-else v-show="!isLoading" class="row">
          <p>
            Данные не удалось загрузить.
          </p>
        </div>
      </div>
      <notifications style="z-index: 0" classes="customized-notification-style" group="main" position="top right" />
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import {
  ACTION_EDIT_CAMERA_SETUP,
  ACTION_EDIT_CAMERA_SETUP_REBOOT,
  ACTION_EDIT_CAMERA_SETUP_RESET,
  ACTION_EDIT_CAMERA_SETUP_UPGRADE,
  ACTION_LOAD_CAMERA_SETUP_FOR_EDIT,
  ACTION_LOAD_TIME_ZONE_FOR_SELECT,
  FIELDS_CAMERA_SETUP, NIGHT_MODE_OPTIONS,
  VIDEO_BITRATE_OPTIONS,
  VIDEO_RESOLUTION_OPTIONS,
  WDR_OPTIONS,
} from "@/store/camerasSetup/index.js";
import {editDataEntityMixin} from "@/utils/mixins.js";
import ManualFirmwareUpgradeDialog from "@/components/cameras/ManualFirmwareUpgradeDialog.vue";
/**
 * Компонент страницы управления камерой.
 */
export default {
  mixins: [editDataEntityMixin],
  data() {
    const cameraNumber = this.$route.params.cameraNumber;
    return {
      fieldsEntity: FIELDS_CAMERA_SETUP,
      entityId: cameraNumber,
      nameActionLoadDataForEdit: `camerasSetup/${ACTION_LOAD_CAMERA_SETUP_FOR_EDIT}`,
      nameActionEdit: `camerasSetup/${ACTION_EDIT_CAMERA_SETUP}`,
      file: null,
      videoResolutionOptions: VIDEO_RESOLUTION_OPTIONS,
      videoBitrateOptions: VIDEO_BITRATE_OPTIONS,
      nightModeOptions: NIGHT_MODE_OPTIONS,
      wdrOptions: WDR_OPTIONS,
      settingsRemoteSearchTimezone: {
        action: `camerasSetup/${ACTION_LOAD_TIME_ZONE_FOR_SELECT}`,
        valueField: "value",
        labelField: "value",
        searchParams: "search",
      },
    };
  },
  methods: {
    /**
     * Сохранение перекрыто с использованием всплывающего окна.
     */
    async saveData() {
      this.isLoading = true;
      try {
        await this.$_editDataEntityMixin_saveData();
        this.$notify({
          group: "main",
          text: "Настройки сохранены",
          duration: 5000,
          type: "success"
        });
      } catch (error) {
        this.$notify({
          group: "main",
          text: this.errorsFormEdit,
          duration: 5000,
          type: "error"
        });
      }
      await this.loadSourceData();
      this.isLoading = false;
    },
    /**
     * @link editDataEntityMixin.methods.afterLoadSourceData
     * Если поля пустые данные о настройках сервера детекции движения не сохранятся в общий объект.
     */
    async afterLoadSourceData() {
      this.serverDetectionInfo = this.sourceData.entityInfo.motion_detect_server;
      this.nightModeDetectionInfo = this.sourceData.entityInfo.night_mode_enable;
      this.serverDetectionFalse =  this.serverDetectionInfo.host && this.serverDetectionInfo.port && this.serverDetectionInfo.path;
      this.serverDetectionFalse ? this.formEdit = Object.assign(this.formEdit,{
        camera_number: this.entityId,
        motion_detect_server_host: this.serverDetectionInfo.host,
        motion_detect_server_port: this.serverDetectionInfo.port,
        motion_detect_server_path: this.serverDetectionInfo.path
      }) : this.formEdit = Object.assign(this.formEdit,{
        camera_number: this.entityId,
      });
      this.nightModeDetectionInfo ? this.formEdit = Object.assign(this.formEdit,{
      }) : this.formEdit = Object.assign(this.formEdit,{
        night_mode_enable: "off"
      });
      if (this.formEdit.status !== "ready") {
        setTimeout(()=>{
          this.loadSourceData();
        }, 15000);
      }
    },
    /**
     * Загрузка файла прошивки.
     */
    cameraUpgrade() {
      this.$camsdals.confirm(`Будет установлена прошивка версии: ${this.sourceData.entityInfo.new_firmware_available}
      Во время обновления прошивки не отключайте камеру от питания и интернета. Отключение может привести к повреждению работы камеры.`, async () => {
        try {
          this.isLoading = true;
          await this.$store.dispatch(`camerasSetup/${ACTION_EDIT_CAMERA_SETUP_UPGRADE}`, this.entityId);
          // this.$notify({
          //   group: "main",
          //   text: "ПО камеры обновляется в течение 40-50 сек. Камера будет перезагружена",
          //   duration: 10000,
          //   type: "success"
          // });
          this.$camsdals.alert("ПО камеры обновляется в течение 40-50 сек. Камера будет перезагружена");

        } catch {
          this.$camsdals.alert("Произошла ошибка во время обновления. Попробуйте еще раз.");
        }
        this.isLoading = false;
        this.loadSourceData();
      });
    },

    /**
     * Отправка запроса на сброс к заводским настроек камеры.
     */
    async cameraReset() {
      this.$camsdals.confirm("Хотите сбросить камеру к заводским настройкам?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`camerasSetup/${ACTION_EDIT_CAMERA_SETUP_RESET}`, this.entityId);
          this.loadSourceData();
          this.$camsdals.alert("Камера сброшена к заводским настройкам");
        } catch (error) {
          this.$camsdals.alert("Ошибка при конфигурации");
        }
        this.isLoading = false;
      });
    },
    /**
     * Отправка запроса на перезагрузку камеры.
     */
    async cameraReboot() {
      this.$camsdals.confirm("Хотите перезагрузить камеру?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`camerasSetup/${ACTION_EDIT_CAMERA_SETUP_REBOOT}`, this.entityId);
          this.loadSourceData();
          this.$camsdals.alert("Камера перезагружена");
        } catch (error) {
          this.$camsdals.alert("Ошибка при конфигурации");
        }
        this.isLoading = false;
      });
    },
    openDialogManualUpdate() {
      this.$camsdals.open(
        ManualFirmwareUpgradeDialog,
        {cameraNumber: this.entityId, cameraModel: this.sourceData.entityInfo.model},
        {dialogTitle: `Ручное обновление камеры ${this.entityId}`},
        {}
      );
    },
  },
};
</script>
