<template>
  <div class="settings_access">
    <template v-if="modeAddPermission">
      <div v-for="(itemField, indexField) in formAddPermission" :key="`fieldAddPermission${indexField}`" class="row">
        <SmartVSelect
          v-if="modeForCameraGroup"
          v-model="itemField.entityId"
          :settings-remote-search="settingsSelectClient"
          class="settings_access__select"
          placeholder="Введите логин пользователя"
        />
        <SmartVSelect
          v-else
          v-model="itemField.entityId"
          :settings-remote-search="settingsSelectCameraGroup"
          class="settings_access__select"
          placeholder="Введите название группы камер"
        />

        <div class="buttons-group" style="margin-left: 16px;">
          <PermissionSelect v-model="itemField.permission" />
          <CamsButton
            v-if="lengthFormAddPermission > 1"
            type="button"
            icon-type="only"
            class="settings_access__delete-row"
            @click="deleteFieldFormAddPermission(indexField)"
          >
            <svg style="width: 20px; height: 20px">
              <use xlink:href="@/assets/img/icons.svg#close" />
            </svg>
          </CamsButton>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="buttons-group buttons-group_stretch">
            <CamsButton
              v-if="!modeForCameraGroup"
              :disabled="lengthFormAddPermission >= 3"
              priority="primary"
              @click="addNewFieldFormAddPermission()"
            >
              Ещё
            </CamsButton>
            <CamsButton priority="primary" type="button" @click="addPermissions()">
              Сохранить
            </CamsButton>
            <CamsButton type="button" @click="switchOffModeAddPermission()">
              Отмена
            </CamsButton>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!modeAddPermission">
      <div class="row">
        <div class="col">
          <CamsButton
            v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_CLIENT)"
            priority="primary"
            type="button"
            @click="switchOnModeAddPermission()"
          >
            {{ modeForCameraGroup ? "Добавить пользователя" : "Добавить группу камер" }}
          </CamsButton>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="input input_medium input_stretch">
            <input
              v-model="search"
              :placeholder="modeForCameraGroup ? 'Фильтр по имени пользователя' : 'Фильтр по группе камер'"
              class="input"
              type="text"
              @input="debouncedLoadPermissions()"
            >
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col">
        <SpinnerLoading v-if="isLoading" size="s" />

        <div v-else-if="isAvailablePermissions" class="access-container">
          <nav class="pagination">
            <paginate
              v-model="currentPage"
              :active-class="'pagination__list__item_active'"
              :break-view-class="'pagination__list__item_collapse'"
              :click-handler="loadPermissions"
              :container-class="'pagination__list'"
              :hide-prev-next="true"
              :page-class="'pagination__list__item'"
              :page-count="pageInfo.numPages"
              :page-range="5"
              next-text=""
              prev-text=""
            />
          </nav>

          <div class="access-container__header">
            <div>{{ modeForCameraGroup ? "Пользователь" : "Группа камер" }}</div>
            <div>Уровень доступа</div>
          </div>

          <div class="access-container__content access-list">
            <div v-for="(permissionInfo, permissionInfoIndex) in permissionsInfo" :key="permissionInfoIndex" class="access-list__item">
              <div>{{ modeForCameraGroup ? permissionInfo.username : permissionInfo.cameraGroupName }}</div>

              <div v-if="$can($abilitiesActions.UPDATE_COMMON, $abilitiesSubjects.USER_CLIENT)" class="buttons-group">
                <PermissionSelect
                  v-model="permissionInfo.permission"
                  @input="updatePermission(permissionInfo.clientId, permissionInfo.cameraGroupId, permissionInfo.isAuto, $event)"
                />
                <CamsButton
                  icon-type="only"
                  class="settings_access__delete-row"
                  type="button"
                  @click="deletePermission(permissionInfo.clientId, permissionInfo.cameraGroupId, permissionInfo.isAuto)"
                >
                  <svg style="width: 20px; height: 20px">
                    <use xlink:href="@/assets/img/icons.svg#close" />
                  </svg>
                </CamsButton>
              </div>
            </div>
          </div>

          <nav class="pagination">
            <paginate
              v-model="currentPage"
              :active-class="'pagination__list__item_active'"
              :break-view-class="'pagination__list__item_collapse'"
              :click-handler="loadPermissions"
              :container-class="'pagination__list'"
              :hide-prev-next="true"
              :page-class="'pagination__list__item'"
              :page-count="pageInfo.numPages"
              :page-range="5"
              next-text=""
              prev-text=""
            />
          </nav>

          <span>Найдено: {{ pageInfo.count }}</span>
        </div>

        <div v-else>
          <p>Прав не найдено.</p>
        </div>
      </div>
    </div>

    <div class="dialog-actions">
      <CamsButton priority="primary" type="button" @click="closeDialog()">
        Готово
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {setPermissionsMixin} from "@/components/permissions/mixin.js";
import PermissionSelect from "@/components/permissions/PermissionSelect.vue";
import {ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT, FIELDS_CAMERA_GROUP} from "@/store/cameraGroups/index.js";
import {ACTION_LOAD_CLIENTS_FOR_SELECT, FIELDS_CLIENT} from "@/store/users/clients/index.js";
import {
  ACTION_ADD_PERMISSIONS_CAMERA_GROUPS_BY_FORM,
  ACTION_DELETE_PERMISSIONS_CAMERA_GROUPS,
  ACTION_LOAD_PERMISSIONS_CAMERA_GROUP_FOR_EDIT,
  FIELDS_PERMISSION_CAMERA_GROUP,
} from "@/store/permissions/index.js";
import {methodsForDialogMixin} from "@/utils/mixins.js";

/**
 * Компонент диалога установки прав на группу камер для пользователей.
 *
 * Предусматривает два режима:
 *  - для управления правами многих пользователей для указанной группы;
 *  - для управления правами многих групп для указанного пользователя;
 *
 * Режим зависит от переданного идентификатора в конкретный параметр (ID пользователя или группы - приоритет для группы).
 * Возможность множественного добавления прав предназначена для одного пользователя.
 */
export default {
  components: {
    PermissionSelect,
  },
  mixins: [
    methodsForDialogMixin,
    setPermissionsMixin
  ],
  props: {
    /**
     * Идентификатор группы камер - позволяет управлять правами разных пользователей для этой группы.
     */
    cameraGroupId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор пользователя - позволяет управлять правами разных групп камер для этого пользователя.
     */
    clientId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      settingsSelectClient: {
        action: `clients/${ACTION_LOAD_CLIENTS_FOR_SELECT}`,
        valueField: FIELDS_CLIENT.id,
        labelField: FIELDS_CLIENT.username
      },
      settingsSelectCameraGroup: {
        action: `cameraGroups/${ACTION_LOAD_CAMERA_GROUPS_FOR_SELECT}`,
        valueField: FIELDS_CAMERA_GROUP.id,
        labelField: FIELDS_CAMERA_GROUP.name
      },
    };
  },
  computed: {
    /**
     * Вернет true для режима, в котором права устанавливаются для конкретной группы камер, а настраиваются пользователи.
     *
     * @return {Boolean}
     */
    modeForCameraGroup() {
      return this.cameraGroupId !== null;
    },
  },
  created() {
    this.debouncedLoadPermissions = _.debounce(this.loadPermissions, 350);
    this.loadPermissions();
  },
  methods: {
    /**
     * Загрузит информацию по правам для редактирования.
     *
     * @param {Number} currentPage
     */
    async loadPermissions(currentPage = 1) {
      this.currentPage = this.currentPage === currentPage ? this.currentPage : currentPage;
      // Основной фильтр зависит от режима работы компонента.
      const mainFilter = this.modeForCameraGroup ? {cameraGroupId: this.cameraGroupId} : {clientId: this.clientId};

      this.isLoading = true;
      try {
        const responseData = await this.$store.dispatch(`permissions/${ACTION_LOAD_PERMISSIONS_CAMERA_GROUP_FOR_EDIT}`, {
          ...mainFilter,
          search: this.search,
          page: currentPage
        });

        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.permissionsInfo = responseData.results.map((rawPermissionInfo) => {
          return {
            clientId: rawPermissionInfo[FIELDS_PERMISSION_CAMERA_GROUP.user_id],
            username: rawPermissionInfo[FIELDS_PERMISSION_CAMERA_GROUP.username],
            cameraGroupId: rawPermissionInfo[FIELDS_PERMISSION_CAMERA_GROUP.camera_group_id],
            cameraGroupName: rawPermissionInfo[FIELDS_PERMISSION_CAMERA_GROUP.camera_group_name],
            isAuto: rawPermissionInfo[FIELDS_PERMISSION_CAMERA_GROUP.is_auto],
            permission: rawPermissionInfo[FIELDS_PERMISSION_CAMERA_GROUP.permission]
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Обработка формы для добавления прав и оформление запроса по заполненным данным.
     */
    async addPermissions() {
      const dataForSave = {},
            valuesFormAddPermission = _.values(this.formAddPermission);

      if (this.modeForCameraGroup) {
        // Если задана группа камер, то в списке прав заданы пользователи (1) и их права.
        const rawPermissionInfo = _.head(valuesFormAddPermission);
        if (!rawPermissionInfo.entityId || !rawPermissionInfo.permission) {
          this.$camsdals.alert("Ошибка при добавлении прав");
          return;
        }

        dataForSave.clientId = rawPermissionInfo.entityId;
        dataForSave.rawPermissionsInfo = [
          [this.cameraGroupId, rawPermissionInfo.permission]
        ];
      } else {
        // Если задан пользователь, то в списке прав заданы группы камер и их права.
        dataForSave.clientId = this.clientId;
        dataForSave.rawPermissionsInfo = valuesFormAddPermission.map((rawPermissionInfo) => {
          return (rawPermissionInfo.entityId && rawPermissionInfo.permission) ? [rawPermissionInfo.entityId, rawPermissionInfo.permission] : null;
        });
        dataForSave.rawPermissionsInfo = _.filter(dataForSave.rawPermissionsInfo);

        if (_.isEmpty(dataForSave.rawPermissionsInfo)) {
          this.$camsdals.alert("Ошибка при добавлении прав");
          return;
        }
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(`permissions/${ACTION_ADD_PERMISSIONS_CAMERA_GROUPS_BY_FORM}`, dataForSave);
        this.switchOffModeAddPermission();
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.isLoading = false;
    },
    /**
     * Редактирование (установка) прав для найденных.
     *
     * @param {Number} clientId
     * @param {Number} cameraGroupId
     * @param {Boolean} isAuto
     * @param {Number} permission
     */
    async updatePermission(clientId, cameraGroupId, isAuto, permission) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`permissions/${ACTION_ADD_PERMISSIONS_CAMERA_GROUPS_BY_FORM}`, {
          clientId,
          isAuto,
          rawPermissionsInfo: [[cameraGroupId, permission]],
          mergePermissions: false,
        });
      } catch (error) {
        this.$camsdals.alert(`Произошла ошибка: ${error}`);
      }
      this.loadPermissions(this.currentPage);
      this.isLoading = false;
    },
    /**
     * Удаление конкретных прав из списка найденных.
     *
     * @param {Number} clientId
     * @param {Number} cameraGroupId
     * @param {Boolean} isAuto
     */
    deletePermission(clientId, cameraGroupId, isAuto) {
      this.$camsdals.confirm("Хотите удалить эти права?", async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`permissions/${ACTION_DELETE_PERMISSIONS_CAMERA_GROUPS}`, {
            clientId,
            isAuto,
            cameraGroupIds: [cameraGroupId]
          });
        } catch (error) {
          this.$camsdals.alert(`Произошла ошибка: ${error}`);
        }
        this.loadPermissions();
        this.isLoading = false;
      });
    },
  },
};
</script>
