/**
 * Общие данные и методы для компонентов отображения логов.
 */

import {TOKEN_TYPES} from "@/store/cameras/index.js";
import {ROUTE_EDIT_ADMIN, ROUTE_EDIT_CLIENT, ROUTE_EDIT_CAMERA} from "@/router/names.js";

export const logByCriteriaMixin = {
  props: {
    /**
     * Критерий фильтрации по которому будут загружены логи.
     */
    criteria: {
      type: Object,
      required: true,
    },
    /**
     * Начальное значение номер страницы в таблице логов.
     */
    initialCurrentPage: {
      type: Number,
      default: 1,
    },
    /**
     * Название action в vuex, которое предназначено для получения списка логов.
     */
    nameActionLoadLog: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.initialCurrentPage,
      listDataTable: [],
      isLoadingPage: false,
      pageInfo: {
        numPages: 0,
        count: 0,
      },
      routes: {
        ROUTE_EDIT_ADMIN,
        ROUTE_EDIT_CLIENT,
        ROUTE_EDIT_CAMERA,
      },

      // Требует перекрытия в компонентах.
      fieldsTable: null,
      columnCaptions: null,
    };
  },
  watch: {
    /**
     * Перехват параметризованного URL - извлечение номера новой страницы и ее загрузка.
     */
    $route() {
      this.loadPage();
    },
  },
  /**
   * При создании компонента сразу загружаются логи.
   */
  created() {
    this.loadPage();
  },
  methods: {
    /**
     * Переход к новой странице, который отражается в истории браузера.
     */
    selectPage(selectedPage) {
      this.$router.push({name: this.$route.name, params: {...this.criteria, currentPage: selectedPage}});
    },
    /**
     * Загрузка списка информации, по заданным настройкам.
     */
    async loadPage() {
      this.listDataTable = [];
      this.isLoadingPage = true;
      try {
        const responseData = await this.$store.dispatch(`log/${this.nameActionLoadLog}`, {...this.criteria, page: this.currentPage});
        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.listDataTable = responseData.results;
      } finally {
        this.isLoadingPage = false;
      }
    },
    /**
     * Подпись к типу токена.
     *
     * @param {String} tokenType
     * @return {String}
     */
    captionToken(tokenType) {
      return {
        [TOKEN_TYPES.L]: "Прямая трансляция",
        [TOKEN_TYPES.R]: "Архив",
        [TOKEN_TYPES.D]: "Скачивание",
      }[tokenType];
    }
  }
};