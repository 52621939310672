<template>
  <div>
    <div class="row">
      <p>Миграция аналитики</p>
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.analytic_server_id]"
        :caption="titlesFields[fieldsEntity.analytic_server_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.analytic_server_id]"
        class="col"
        @input="changePromise()"
      />
    </div>
  </div>
</template>

<script>
import {functionMultiEditMixin} from "@/utils/mixins.js";
import {ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT, FIELDS_ANALYTIC_SERVER} from "@/store/analytics/servers/index.js";
import {ResultProcessingEntityMultiEdit} from "@/utils/helpers.js";
import {FIELDS_HELMET, TITLES_FIELDS_HELMET, ACTION_EDIT_HELMET} from "@/store/analytics/helmets/index.js";

/**
 * Компонент функции мультиредактора камер для постановки задач на миграцию камер на другой сервер/кластер.
 */
export default {
  mixins: [functionMultiEditMixin],
  data() {
    return {
      fieldsEntity: FIELDS_HELMET,
      titlesFields: TITLES_FIELDS_HELMET,
      settingsSelects: {
        [FIELDS_HELMET.analytic_server_id]: {
          action: `analytics/servers/${ACTION_LOAD_ANALYTIC_SERVERS_FOR_SELECT}`,
          valueField: FIELDS_ANALYTIC_SERVER.id,
          labelField: FIELDS_ANALYTIC_SERVER.front_id,
        },
      },
      formEdit: {
        [FIELDS_HELMET.analytic_server_id]: null,
      },
    };
  },
  methods: {
    /**
     * @link functionMultiEditMixin.methods.changePromise
     */
    changePromise() {
      this.$emit("change-promise", async (entityKey) => {
        try {
          await this.$store.dispatch(`analytics/helmets/${ACTION_EDIT_HELMET}`, {
            [FIELDS_HELMET.camera_number]: entityKey,
            ...this.formEdit,
          });

          return ResultProcessingEntityMultiEdit.success(entityKey);
        } catch (error) {
          return ResultProcessingEntityMultiEdit.errorRequest(entityKey, error);
        }
      });
    },
  },
};
</script>
