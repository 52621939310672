<template>
  <div>
    <div class="main__header">
      <div class="breadcrumbs">
        <ul class="breadcrumbs__list">
          <li class="breadcrumbs__item">
            <router-link :to="routeBack">
              <strong>
                Вернуться к редактированию устройства
              </strong>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main__content content">
      <SmartTable
        :calc-data-for-cell="calcDataForCell"
        :column-captions="columnCaptions"
        :current-route="currentRoute"
        :default-filters="defaultFilters"
        :key-field="rawFields.data_change_event_date"
        :name-action-load-data-for-table="nameActionLoadDataForTable"
        :name-action-load-info-for-table="nameActionLoadInfoForTable"
        :parse-extra="parseExtra"
        :raw-fields="rawFields"
        :initial-default-fields-table-for-view="defaultFieldsTableForView"
      />
    </div>
  </div>
</template>

<script>
import {
  ROUTE_EDIT_DEVICE,
  ROUTE_VIEW_DEVICE_MODULE_HISTORY
} from "@/router/names.js";
import {
  ACTION_LOAD_DEVICE_MODULE_HISTORY_FOR_TABLE,
  ACTION_LOAD_INFO_DEVICE_MODULE_HISTORY,
  EXTRAS_DEVICE_MODULE_HISTORY,
  FIELDS_DEVICE_MODULE_HISTORY,
  FILTERS_DEVICE_MODULE_HISTORY,
  TITLES_FIELDS_DEVICE_MODULE_HISTORY, TYPES_FIELDS_DEVICE_MODULE_HISTORY
} from "@/store/pacs/devices/index.js";
import {FilterData} from "@/utils/helpers.js";
import SmartTable from "@/components/smart/table/SmartTable.vue";
import {FIELDS_ADMIN} from "@/store/users/admins/index.js";

/**
 * Таблица с отображением информации истории изменения модуля.
 */
export default {
  components: {
    SmartTable,
  },
  data() {
    const defaultFilterByDate = new Date();
    defaultFilterByDate.setDate(defaultFilterByDate.getDate() - 7);

    return {
      deviceId: this.$route.params.deviceId,
      routeBack: {name: ROUTE_EDIT_DEVICE, params: {deviceId: this.$route.params.deviceId}},
      currentRoute: ROUTE_VIEW_DEVICE_MODULE_HISTORY,
      rawFields: FIELDS_DEVICE_MODULE_HISTORY,
      columnCaptions: TITLES_FIELDS_DEVICE_MODULE_HISTORY,
      nameActionLoadDataForTable: `devices/${ACTION_LOAD_DEVICE_MODULE_HISTORY_FOR_TABLE}`,
      nameActionLoadInfoForTable: `devices/${ACTION_LOAD_INFO_DEVICE_MODULE_HISTORY}`,
      defaultFieldsTableForView: [
        FIELDS_DEVICE_MODULE_HISTORY.data_change_event_date,
        FIELDS_DEVICE_MODULE_HISTORY.id,
        FIELDS_DEVICE_MODULE_HISTORY.loop0_mode,
        FIELDS_DEVICE_MODULE_HISTORY.loop1_mode,
        FIELDS_DEVICE_MODULE_HISTORY.mode,
        FIELDS_DEVICE_MODULE_HISTORY.open,
        FIELDS_DEVICE_MODULE_HISTORY.adjust,
        FIELDS_DEVICE_MODULE_HISTORY.loop0_camera_number,
        FIELDS_DEVICE_MODULE_HISTORY.loop1_camera_number,
        FIELDS_DEVICE_MODULE_HISTORY.data_change_event_user_id,
        FIELDS_DEVICE_MODULE_HISTORY.data_change_event_ip,
        FIELDS_DEVICE_MODULE_HISTORY.data_change_event_action,
      ],
      defaultFilters: [
        new FilterData(FILTERS_DEVICE_MODULE_HISTORY.data_change_event_date, ">=", [defaultFilterByDate]),
      ],
    };
  },
  methods: {
    /**
     * Обрабатывает дополнительную информацию в актуальном наборе данных для таблицы.
     *
     * @param {Object} sourceExtraInfo
     * @param {Object} storedExtraInfo
     */
    parseExtra(sourceExtraInfo, storedExtraInfo) {
      if (sourceExtraInfo[EXTRAS_DEVICE_MODULE_HISTORY.user]) {
        storedExtraInfo.admins = {};
        sourceExtraInfo[EXTRAS_DEVICE_MODULE_HISTORY.user].forEach((adminInfo) => {
          storedExtraInfo.admins[adminInfo[FIELDS_ADMIN.id]] = {
            id: adminInfo[FIELDS_ADMIN.id],
            name: adminInfo[FIELDS_ADMIN.username],
          };
        });
      }
    },
    /**
     * Вернет структурированный объект для компонента отображения данных в ячейке.
     *
     * @param {String} nameField
     * @param {Object} rowData
     * @param {Object} storedExtraInfo
     * @returns {Object}
     */
    calcDataForCell(nameField, rowData, storedExtraInfo) {
      let readyTranslation = "",
          rawTranslation = rowData[nameField],
          readyParams = {};
      switch (nameField) {
      case FIELDS_DEVICE_MODULE_HISTORY.data_change_event_user_id:
        readyTranslation = _.get(storedExtraInfo.admins[rawTranslation], "name", "-");
        break;
      default:
        readyTranslation = rawTranslation;
      }
      return {
        type: TYPES_FIELDS_DEVICE_MODULE_HISTORY[nameField],
        value: readyTranslation,
        params: readyParams,
      };
    },
  },
};
</script>
