<template>
  <form @keyup.enter="createEntityAndRedirect()" @submit.prevent="createEntityAndRedirect()">
    <SpinnerLoadingModal v-if="isLoading" />
    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.title]"
        :caption="titlesFields[fieldsEntity.title]"
        :error="errorsFormEdit[fieldsEntity.title]"
        :make-focus="true"
        class="col"
      />
    </div>

    <div class="row">
      <SmartVSelect
        v-model="formEdit[fieldsEntity.gang_id]"
        :caption="titlesFields[fieldsEntity.gang_id]"
        :error="errorsFormEdit[fieldsEntity.gang_id]"
        :settings-remote-search="settingsSelects[fieldsEntity.gang_id]"
        class="col"
      />
    </div>

    <div class="row">
      <SmartInputText
        v-model="formEdit[fieldsEntity.about]"
        :caption="titlesFields[fieldsEntity.about]"
        :error="errorsFormEdit[fieldsEntity.about]"
        class="col"
        input-type="area"
        maxlength="200"
      />
    </div>

    <div class="dialog-actions">
      <CamsButton type="button" @click="closeDialog()">
        Отменить
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        Сохранить
      </CamsButton>
    </div>
  </form>
</template>

<script>
import {
  ACTION_CREATE_DEVICE_ACCESS_GROUP,
  FIELDS_DEVICE_ACCESS_GROUP,
  TITLES_FIELDS_DEVICE_ACCESS_GROUP
} from "@/store/pacs/deviceAccessGroups/index.js";
import {ROUTE_EDIT_DEVICE_ACCESS_GROUP} from "@/router/names.js";
import {createEntityMixin} from "@/utils/mixins.js";
import {FIELDS_EMPLOYEE} from "@/store/pacs/employees/index.js";
import {ACTION_LOAD_GANGS_FOR_SELECT, FIELDS_GANG} from "@/store/gangs/index.js";

/**
 * Компонент диалога создания группы доступа к устройствам.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      nameActionCreate: `deviceAccessGroups/${ACTION_CREATE_DEVICE_ACCESS_GROUP}`,
      fieldsEntity: FIELDS_DEVICE_ACCESS_GROUP,
      titlesFields: TITLES_FIELDS_DEVICE_ACCESS_GROUP,
      formEdit: {
        [FIELDS_DEVICE_ACCESS_GROUP.title]: "",
        [FIELDS_DEVICE_ACCESS_GROUP.gang_id]: null,
        [FIELDS_DEVICE_ACCESS_GROUP.about]: "",
      },
      settingsSelects: {
        [FIELDS_EMPLOYEE.gang_id]: {
          action: `gangs/${ACTION_LOAD_GANGS_FOR_SELECT}`,
          valueField: FIELDS_GANG.id,
          labelField: FIELDS_GANG.name,
        },
      }
    };
  },
  methods: {
    /**
     * Перенаправление на редактирование группы камер.
     *
     * @link createEntityMixin.methods.getRouteRedirectAfterCreate
     */
    getRouteRedirectAfterCreate(newEntity) {
      return {name: ROUTE_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId: newEntity[FIELDS_DEVICE_ACCESS_GROUP.id]}};
    },
  },
};
</script>
