<template>
  <main class="page-auth">
    <p class="page-auth__title">
      Авторизуйтесь через свой аккаунт
    </p>
    <div class="page-auth__content">
      <div class="page-auth__content__form">
        <template v-if="isAuthViaToken">
          <form @keyup.enter="signIn()" @submit.prevent="signIn()">
            <div class="input input_medium">
              <input ref="elementForFocus" v-model="username" placeholder="Логин" type="text">
            </div>
            <div class="input input_medium">
              <input v-model="password" placeholder="Пароль" type="password">
            </div>
            <CamsButton priority="primary" type="submit" style="width: 100%; margin-top: 30px;">
              Войти
            </CamsButton>
            <div v-show="showError" class="error">
              Неверный логин или пароль
            </div>
          </form>
        </template>
        <template v-else>
          <form :action="urlSignInWithQueryParams" method="post">
            <div class="input input_medium">
              <input
                ref="elementForFocus"
                v-model="username"
                name="username"
                placeholder="Логин"
                type="text"
              >
            </div>
            <div class="input input_medium">
              <input v-model="password" name="password" placeholder="Пароль" type="password">
            </div>
            <CamsButton priority="primary" type="submit" style="width: 100%; margin-top: 30px;">
              Войти
            </CamsButton>
            <div v-show="showError" class="error">
              Неверный логин или пароль
            </div>
          </form>
        </template>
      </div>
      <button class="button" type="button" @click="restorePassword()">
        Забыли пароль?
      </button>
    </div>

    <SpinnerLoadingModal v-show="isLoading" />
  </main>
</template>

<script>
import {ACTION_SIGN_IN_VIA_TOKEN} from "@/store/actions.js";
import {CONFIG_IS_AUTH_VIA_TOKEN} from "@/utils/consts.js";
import {mapGetters} from "vuex";

/**
 * Компонент входа в систему.
 *
 * В зависимости от настроек приложения, поведение может идти как для авторизации через токен
 * или через сессии, с классической отправкой честной формы POST запросом.
 */
export default {
  data() {
    return {
      isLoading: false,
      username: "",
      password: "",
      showError: false,
      isAuthViaToken: CONFIG_IS_AUTH_VIA_TOKEN,
    };
  },
  computed: {
    ...mapGetters(["urlSignIn"]),
    /**
     * Строка с URL для авторизации с параметром next для редиректа после авторизации.
     * Параметр имеет смысл для авторизации по сессии т.к. по токену редирект после авторизации происходит автоматически
     * в соответствии с настройками роутера.
     * Не обязательно чтобы ссылка с редиректом была в URL браузера - тут она будет только в
     * action у формы.
     *
     * @return {String}
     */
    urlSignInWithQueryParams() {
      const paramNext = this.$route.query["next"] || this.$route.fullPath;
      if (!paramNext) {
        return this.urlSignIn;
      }
      return `${this.urlSignIn}?next=${paramNext}`;
    }
  },
  /**
   * Фокус на поле логина при показе окна авторизации.
   *
   * Если через GET параметры была передана ошибка (нормально это возможно если авторизация идет по сессиям),
   * то она отобразится на форме сразу.
   */
  mounted() {
    this.$refs.elementForFocus.focus();

    if (this.$route.query.error) {
      this.showError = true;
    }
  },
  methods: {
    /**
     * Авторизация через токен.
     */
    async signIn() {
      this.isLoading = true;
      this.showError = false;
      try {
        const resultSuccess = await this.$store.dispatch(ACTION_SIGN_IN_VIA_TOKEN, {username: this.username, password: this.password});
        this.showError = !resultSuccess;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Механизм восстановление пароля.
     */
    restorePassword() {
      //todo
      alert("Обратитесь к администратору");
    }
  },
};
</script>
